import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Badge, Divider, Image} from 'antd'
import chatKitty from '../../core_units/chat-system/chatkitty';
// import { useLocation } from 'react-router';
import { useSearchParams,useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';

const EmptyChannelBlock = ({ channels, isFetchChannelProcessed, }) => {
  if (_.isEmpty(channels) && isFetchChannelProcessed) {
    return (
      <div style={{ height: 270, alignItems: 'center' }}>
        <div style={{ marginTop: 100 }}>No messgaes yet.</div>
      </div>
    );
  } else {
    return (<React.Fragment></React.Fragment>);
  }
};

const BrowseChannelsScreen = (props) => {
  const [channels, setChannels] = useState([]);
  const [isFetchChannelProcessed, setIsFetchChannelProcessed] = useState(false);
  const [lastNotificationChannelId, setLastNotificationChannelId] = useState(null);
  const [lastReadBadge, setLastReadBadge] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const [unread, setUnread] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [allMessagesRead, setAllMessagesRead] = useState(false);
  const state_location  = useLocation();

  useEffect(() => {
    if(_.get(state_location,'state.data')){
      changeBadge(_.get(state_location,'state.data'))
    }
    props.onsetLoading(true)
   if(_.get(props,'chatKittyCurrentUserData.id')){
    if(searchParams.get('channel_id')){
      openChat(searchParams.get('channel_id'))
    }
      chatKitty.instance.listChannels({filter:{joined:true}}).then((result) => {
        let direct = filterList(_.get(result, 'paginator.items', []),"DIRECT")
        let PRIVATE = filterList(_.get(result, 'paginator.items', []),"PRIVATE")
       processChannelData(_.get(result, 'paginator.items', []));
      });
      return initialyzeNotificationReceiveEvent();
    }

   
    
    
  }, []);

  useEffect(() => {
    if(_.get(props,'chatKittyCurrentUserData.id') &&props.sortDesc === true){
      // if(searchParams.get('channel_id')){
      //   openChat(searchParams.get('channel_id'))
      // }

      chatKitty.instance.listChannels({filter:{joined:true}}).then((result) => {
        
         let direct = filterList(_.get(result, 'paginator.items', []),"DIRECT")
        let PRIVATE = filterList(_.get(result, 'paginator.items', []),"PRIVATE")
        processChannelData(_.get(result, 'paginator.items', []));
       
      });

    }
   
  }, [])

  useEffect(() => {
    chatKitty.instance.listChannels({filter:{joined:true}}).then((result) => {
      processChannelData(_.get(result, 'paginator.items', []));
      const filter_channel = _.get(result, 'paginator.items').filter((item) => item.id === _.get(props, 'reCallChatList'));
      if(_.size(filter_channel) > 0){
        props.onChangeChennel(filter_channel[0]);
      }
     
    });
  }, [_.get(props, 'reCallChatList')]);
  useEffect(() => {
    if(props?.stateData?.id){
      chatKitty.instance.listChannels({filter:{joined:true}}).then((result) => {
        processChannelData(_.get(result, 'paginator.items', []));
        const filter_channel = _.get(result, 'paginator.items').filter((item) => item.id === props?.stateData?.id);
        if(_.size(filter_channel) > 0){
          props.onChangeChennel(filter_channel[0]);
        }
       
      });
    }
    
  }, [_.get(props, 'onlineTigger')]);

  useEffect(() => {
    chatKitty.instance.listChannels({filter:{joined:true}}).then((result) => {
      processChannelData(_.get(result, 'paginator.items', []));
      props.onChangeChennel()
    });
  }, [_.get(props, 'deletedChat')])

  const openChat =async(id)=>{
    if(id){
        const result = await chatKitty.instance.retrieveChannel(id);
        if (result.succeeded) {
            const channel = result.channel; // Handle channel
            
            navigate('/communiti/chats-notification', {state:{
              data: channel
            }})
        }
    }
  }
    
  useEffect(() => {

    if (!_.chain(lastNotificationChannelId).toString().isEmpty().value()) {
      updateAthleteUnreadMessagesCount(lastNotificationChannelId);
    }
  }, [lastNotificationChannelId]);

  const initialyzeNotificationReceiveEvent = () => {
    return chatKitty.instance.onNotificationReceived((notification) => {
      const condition1 = _.overEvery([
        notification => _.chain(notification).get('type', null).eq('USER:SENT:MESSAGE').value(),
        notification => !_.chain(notification).get('message.user.name', null).eq(_.get(props, 'chatKittyCurrentUserData.name')).value(),
        () => _.eq('Actions.currentScene', 'DashboardChatChannelList')
      ]);
      const condition2 = _.overEvery([
        notification => _.chain(notification).get('data.type', null).eq('USER:SENT:MESSAGE').value(),
        notification => !_.chain(notification).get('data.message.user.name', null).eq(_.get(props, 'chatKittyCurrentUserData.name')).value(),
        () => _.eq('Actions.currentScene', 'DashboardChatChannelList')
      ]);
      if (condition1(notification)) {
        setLastNotificationChannelId(_.get(notification, 'channelId', null));
      } else if (condition2(notification)) {
        setLastNotificationChannelId(_.get(notification, 'data.channelId', null));
      }
    });
  };
  const updateAthleteUnreadMessagesCount = channelId => {
    const items = _.clone(channels);
    
    const channelIndex = _.findIndex(items, { 'id': channelId });
    if (_.gt(channelIndex, -1)) {
      items[channelIndex].athleteUnreadMessagesCount = _.add(items[channelIndex].athleteUnreadMessagesCount, 1);
      props.onsetLoading(false)
      setChannels(items)
    }
  };

  const processChannelData = async (channelItems, currentItemIndex = 0) => {
    if (_.chain(currentItemIndex).add(1).lte(_.size(channelItems)).value()) {
      const result = await chatKitty.instance.countUnreadMessages({ channel: channelItems[currentItemIndex] });
      channelItems[currentItemIndex].athleteUnreadMessagesCount = _.get(result, 'count');
      processChannelData(channelItems, _.add(currentItemIndex, 1));
    } else {
      setIsFetchChannelProcessed(true);
      let final_messages = []
      _.map(channelItems, (value, index) => {

      })
props.onsetLoading(false)
      setChannels(channelItems);
    }
  };

  const changeBadge = (value) => {
    
    setLastReadBadge(false);
    setMessageId(_.get(value, 'id'));
    checkIfUnread(_.get(props, 'stateData'));
    getUnreadCount(_.get(props, 'stateData'));
    readAllMessages(value)
    // updateAthleteUnreadMessagesCount(value)
    if(_.get(value,'athleteUnreadMessagesCount')>0){
      chatKitty.instance.listChannels({filter:{joined:true}}).then((result) => {
    
        processChannelData(_.get(result, 'paginator.items', []));
      });
    }
   
  }

  const checkIfUnread = async channel => {
    if(channel){
      const unreadResults = await chatKitty.instance.checkChannelUnread({
        channel: channel,
      });
      if (unreadResults.succeeded) {
        setUnread(unreadResults.unread);
      }
    }
   
  };

  const getUnreadCount = async channel => {
    if(channel){
      const countResults = await chatKitty.instance.countUnreadMessages({channel: channel});

      if (countResults.succeeded) {
        setUnreadCount(countResults.count);
      }
    }
   
  };

  useEffect(() => {
    checkIfUnread(_.get(props, 'stateData'));
    getUnreadCount(_.get(props, 'stateData'));
    if(allMessagesRead === true){
      readAllMessages(_.get(props, 'stateData'))
    }
    
  }, [allMessagesRead]);

  const readAllMessages = async(channel) => {
    const countResults = await chatKitty.instance.readChannel({channel: channel});
    setAllMessagesRead(_.get(countResults, 'succeeded'))

  }
  const getProfileImage=(channel, chatKittyCurrentUserData)=>{
  
    if (channel?.type === 'DIRECT') {
      let filterImage = _.filter(_.get(channel,"members"),(v)=>v?.id!=chatKittyCurrentUserData?.id)


      if(_.size(filterImage)>0 && _.get(_.head(filterImage),"displayPictureUrl") && !`${_.get(_.head(filterImage),"displayPictureUrl")}`.includes("default.png") && !`${_.get(_.head(filterImage),"displayPictureUrl")}`.includes("gravatar.com")){
        return  {image:_.get(_.head(filterImage),"displayPictureUrl"),type:"image"}
      } else {
        let filterImage = _.head(_.filter(_.get(channel,"members"),(v)=>v?.id!=chatKittyCurrentUserData?.id))
        let split = `${filterImage?.displayName}`.split(' ')
      if(_.size(split)==1){
        return {name:`${split[0][0]}`.toUpperCase(),type:"name"}
      }else{
        return {name:`${split[0][0]}${split[1][0]}`.toUpperCase(),type:"name"} 
      }
    
      }}

      
}

useEffect(() => {
  chatKitty.instance.listChannels({filter:{joined:true}}).then((result) => {
    processChannelData(_.get(result, 'paginator.items', []));
    const filter_channel = _.get(result, 'paginator.items').filter((item) => item.id === _.get(props, 'stateData'));
    if(_.size(filter_channel) > 0){
      props.onChangeChennel(filter_channel[0]);
    }
  });
 
}, [_.size(props?.messageLoader)]);
const getUserActiveStatus =(channel, chatKittyCurrentUserData)=>{
  let filterUser = _.filter(_.get(channel,"members"),(v)=>v?.id!=chatKittyCurrentUserData?.id)


  return _.get(_.head(filterUser),'presence.online')
}

function getTimeAgo(date) {
  const currentDate = moment();
  const providedDate = moment(date);

  const difference = currentDate.diff(providedDate, 'seconds');
  const minutes = Math.floor(difference / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 7) {
    return providedDate.format("DD MMM YYYY");
}
  if (days > 0) {
      if (days <= 7) {
          return  providedDate.format("ddd h:mm a");
      } else {
          return providedDate.format("ddd h:mm a");
      }
  } else if(hours>2 && moment(date).format("DD.MM.YYYY")==moment().format("DD.MM.YYYY")){
    return `Today ${providedDate.format("h:mm a")}`;
  }
  else if (hours > 0) {
      if (hours === 1 && minutes <= 0) {
          return minutes === 1 ? "1 min ago" : `${minutes} mins ago`;
      } else if (hours === 1 && minutes > 0) {
          return `Today ${providedDate.format("h:mm a")}`;
      } else {
          return hours === 1 ? "1 hr ago" : `${hours} hrs ago`;
      }
  } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} mins ago`;
  } else {
      return difference <= 10 ? "Just now" : `${difference} sec ago`;
  }
}

const filterList =(result,type)=>{
return  _.filter(result,(v)=>v?.type==type)
}
// const loadDescription =(lastText)=>{
//   if(lastText){
//     let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
//     let isValid = regexForHTML.test(lastText);
//     if(isValid){
//       return lastText.replace( /(<([^>]+)>)/ig, ' ').replace(/&nbsp;/g, ' ').replace(/\s+/g, ' ').replace(/<br\s*\/?>/gi, '').replace(/&nbsp;/gi, '').trim()
//     }else{
//       return lastText.replace(/<br\s*\/?>/gi, '').replace(/&nbsp;/gi, '')
//     }
//   }
//  }

const loadDescription = (lastText) => {
  if (lastText) {
    let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    let isValid = regexForHTML.test(lastText);

    if (isValid) {
      return lastText
        .replace(/(<([^>]+)>)/ig, ' ')
        .replace(/&nbsp;/g, ' ')
        .replace(/\s+/g, ' ')
        .trim();
    } else {
      return lastText
        .replace(/<br\s*\/?>/gi, '')
        .replace(/&nbsp;/gi, '');
    }
  }
  return '';
};
  const render = () => {
  return (
    <div style={{overflowY:"scroll"}}>
      {/* <Scrollbars style={{ width: "100%", height: 600 }}> */}
   {_.size(channels)>0 && 
   <div className="chatBoxCardContainer">
      
      {_.map(filterList(channels,props?.chat_type==1?"DIRECT":"PRIVATE"), (value, index) => {
       
        let lastText =loadDescription(_.get(value, 'lastReceivedMessage.body'))
      
       
       
        return <> <div 
        onClick={(e) => {props.onChangeChennel(value); changeBadge(value)}} className={messageId === _.get(value, 'id')  ? "Chat-list-items-active" : "Chat-list-items"} key={index} style={{paddingTop:index==0?"16px":"16px"}}>
         
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',
          justifyContent:'space-between'
        }}>
          
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',
          // justifyContent:'space-between'
          }}>
          {_.get(value, 'athleteUnreadMessagesCount') > 0  ? <div className='more-count-chat-user' style={{marginRight:'12px'}} />:<div style={{width:'8px',height:'8px',marginRight:'12px'}} />}
          <div>
          {props?.chat_type==1?(getProfileImage(value, props.chatKittyCurrentUserData)?.type=="name"?
          <div style={{}} 
          className='chat-image-name'>{getProfileImage(value, props.chatKittyCurrentUserData)?.name}</div>:<div style={{width:'40px',height:'40px',borderRadius:'100%'}}><Image preview={false} style={{width:'40px',height:'40px',borderRadius:'100%'}}
           src={getProfileImage(value, props.chatKittyCurrentUserData).image} /></div>):
          <div className='chat-group-image-container'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          </div>
          }
          {props?.chat_type==1 && getUserActiveStatus(value, props.chatKittyCurrentUserData)==true &&<div className='active-user-chat' />}
   </div>
   <div className="chatUserTitle" style={{marginLeft:'12px',width:'70%'}}>{props?.chat_type==1? _.startCase(global.chatSystem.tools.getChannelDisplayName(value, props.chatKittyCurrentUserData)):global.chatSystem.tools.getChannelDisplayName(value, props.chatKittyCurrentUserData)}</div>
          </div>
          {_.get(value, 'lastReceivedMessage.user.id') &&<div className='chat-user-time' 
          // style={{width:'38%'}}
          >{getTimeAgo(_.get(value, 'lastReceivedMessage.createdTime'))}</div>}
          </div>
          
          {
            <div style={{display:'flex',alignItems:'flex-start',marginTop:'8px',marginLeft:'20px',overflow:"hidden"}}>
              {_.get(value, 'lastReceivedMessage.user.id')== _.get(props.chatKittyCurrentUserData,"id")?<div className='chat-user-description' style={{marginRight:'4px',fontWeight:500}}>You:</div>:null}
            {lastText ? <div className='chat-user-description chat-user-description-wrap'
            //  style={{whiteSpace:"nowrap",overflow:'hidden',textOverflow:"ellipsis",

            // }} 
            // dangerouslySetInnerHTML={{__html: _.size(_.get(value, 'lastReceivedMessage.body'))> 85 ? _.get(value, 'lastReceivedMessage.body').substring(0, 85) : _.get(value, 'lastReceivedMessage.body')}}
            >{lastText} </div>:
            <div className='chat-user-description'>sent an attachment</div>
            }
          
          </div>
      } 
        </div>
       <hr style={{margin:'0px'}}/></>
       
      })}
      <div>

      </div>
      {/* <div style={{marginLeft: "70px"}}>End of the list</div> */}
    </div>}
    {/* </Scrollbars> */}
    </div>
  );

}
return render();
}

const mapStateToProps = state => {
  return {
    chatKittyCurrentUserData: _.get(state, `app.${global.redux.actionTypes.settings.name}.chatKittyCurrentUserData`, {})
  }
 
}


export default connect(mapStateToProps)(BrowseChannelsScreen);