import React, {useEffect, useState} from 'react';
import { Form, Input, Button, notification } from "antd";
import { connect } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';

import "./style.css";
import globalConfig from '../../core_units/config/env-variables'

const TaxBanking = (props) => {
    const [form] = Form.useForm();
    const [selectedCard, setSelectedCard] = useState('yes');
    const [taxData, setTaxData] = useState({})

    useEffect(() => {
      hostProfileTax();
    }, []);

    const hostProfileTax = () => {
      props.hostProfileData({
        callback:(response, data) => {
          
          if(!_.isEmpty(_.get(data, 'data.details'))){
            setTaxData(_.get(data, 'data.details'));
            form.setFieldsValue({
              tax_number: _.get(data, 'data.details.meta_details.tax_number')
            })
          }
          
        if(!_.get(data, 'data.details.is_taxes_collected')){
          setSelectedCard('no')
        } 
        if(_.get(data, 'data.details.is_taxes_collected')){
          setSelectedCard('yes')
        } 
          
        }
      })
    }

    const handleCardClick = (card) => {
        setSelectedCard(card);
      };
      const onTaxBankFinish = (values) => {
        
        props.hostMetaDetailsUpdateAPI({
          body:
          {
            "key": "tax_number",
            "value": _.get(values, 'tax_number')
        },
        callback: (res, data) => {
          
          if(_.get(data, 'success')){
            notification.success({
              message: "Success",
              description: "Tax details updated successfully."
            })
          }
        }
        })
        props.hostUpdateProfile({
          body:{
            is_taxes_collected: selectedCard === 'yes' ? true : false
          },
         
        callback: (res, data) => {
          
        }
        });
      };
      const onFinishTaxBankFailed = (errorInfo) => {
        
      };

      const validatePositiveInteger = (rule, value, callback) => {
        const intValue = parseInt(value, 10);
    
        if (isNaN(intValue) || intValue < 0 || value.indexOf('.') !== -1) {
          callback('Please enter a positive integer');
        } else {
          callback();
        }
      };

      const updateStripeAccountInfo = ()=>{
        if(_.get(taxData,'stripe_user_id')){
          axios.post(`https://api.stripe.com/v1/accounts/${_.get(taxData,'stripe_user_id')}/login_links`,{},{
            headers:{
              'Authorization':`Bearer ${globalConfig.STRIPE.SECRET_KEY}`
            }
          }).then((res)=>{
            if(_.get(res,'data.url')){
              window.open(_.get(res,'data.url'),'_blank')
            }
          }).catch((error)=>{
          })
              }
      };

      const render = () => {
        return (
            <div>
                <Form 
                                layout='vertical'
                                name = 'profile'
                                form={form}
                                onFinish={onTaxBankFinish}
                                onFinishFailed={onFinishTaxBankFailed}
                                >
                                <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                                <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                                  <div className='container-titles'>Taxes</div>
                                  <div className='container-titles-desc'>Select whether you will charge tax on the Communiti.</div>
                                  </div>
                                  <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                                  <div
                                        className={`card ${selectedCard === 'yes' ? 'cardselected' : ''}`}
                                        style={{ borderRadius: '20px', marginBottom: '10px' }}
                                        // onClick={() => handleCardClick('yes')}
                                    >
                                        <div className='card-body'>
                                        <p className={`card-title ${selectedCard === 'yes' ? 'titleselected' : ''}`}>Collect Taxes</p>
                                        <p className={`card-text ${selectedCard === 'yes' ? 'textselected' : ''}`}>
                                        Communiti will automatically charge and collect taxes based on the address you’ve inputted in your Contact Information.
                                        </p>
                                        </div>
                                    </div>
        
                                    <div
                                        className={`card ${selectedCard === 'no' ? 'cardselected' : ''}`}
                                        style={{ borderRadius: '20px' }}
                                        // onClick={() => handleCardClick('no')}
                                    >
                                        <div className='card-body'>
                                        <p className={`card-title ${selectedCard === 'no' ? 'titleselected' : ''}`}>Do Not Collect Taxes</p>
                                        <p className={`card-text ${selectedCard === 'no' ? 'textselected' : ''}`}>
                                        Communiti will not automtically charge and collect taxes. However, Communiti will automatically add its % of tax based on the Communiti fee.
                                        </p>
                                        </div>
                                    </div>
                                  </div>
                                  </div>
                                  <hr/>
                                  <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                                <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                                  <div className='container-titles'>Tax Number</div>
                                  <div className='container-titles-desc'>Enter your tax number if you wish to have it listed on receipts users receive.</div>
                                  </div>
                                  <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                                    <Form.Item 
                                    label="Tax Number"
                                    name="tax_number" 
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input tax number"
                                      },
                                    ]}
                                    >
                                    <Input type="text" style={{ width: "100%", height: "40px"}}/>
                                    </Form.Item>
                                  </div>
                                  </div>
                                  <hr/>
                                  <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                                <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                                  <div className='container-titles'>Banking Information</div>
                                  <div className='container-titles-desc'>You can update your banking information here. Note: you will be redirected to Stripe.</div>
                                  </div>
                                  <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                                    <Form.Item>
                                    <Button onClick={()=>updateStripeAccountInfo()} style={{marginTop: "10px", width: "100%"}}>Update Banking Information</Button>
                                    </Form.Item>
                                    <Form.Item>
                              <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end"}}>
                              <Button style={{marginRight: "15px"}}>Cancel</Button>
                              <Button htmlType="submit" style={{backgroundColor: "#EF6820", color: "#fff"}}>Save changes</Button>
                              </div>
                            </Form.Item>
                                    
                                  </div>
                                  </div>
                                </Form>
            </div>
          )
      };

      return render();
  
}

const mapStateToProps = state => {
  return {
     
  }
}

const mapDispatchToProps = dispatch => ({
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
  hostMetaDetailsUpdateAPI: details => dispatch(global.redux.action.host.metaDetailsUpdate(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(TaxBanking)