import { EditFilled, DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, ArrowLeftOutlined, SaveFilled, ClockCircleFilled, CalendarFilled, MailFilled, ArrowRightOutlined, DownloadOutlined, DollarCircleFilled } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React, { useState, useEffect } from 'react'
import { Button, Collapse, Form, Select, Spin, Upload, notification, InputNumber, Modal, Table, Space, Checkbox, Tag } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { set } from 'lodash';
import { connect } from 'react-redux'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import '../onboarding-screen/style.css'
import './style.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment';

import chatKittyInstance from '../././../core_units/chat-system/chatkitty/instance';

const { Panel } = Collapse;
const { confirm } = Modal
const { Option } = Select;

const TemplateList = (props) => {
  const [form] = Form.useForm();
  let history = useNavigate();
  const [AllTemplateListData, setAllTemplateListData] = useState([])
  const [AllTemplateListMeta, setAllTemplateListMeta] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [usersList, setUsersList] = useState([])
  const [scheduleSelectId, setScheduleSelectId] = useState('')
  const [viewModal, setViewModal] = useState(true)
  const [showPreviewRefundModal, setShowPreviewRefundModal] = useState(_.get(props, 'viewModal'))
  const [showPreviewRefundModal1, setShowPreviewRefundModal1] = useState(false)
  const [removeAllowLate, setRemoveAllowLate] = useState('1')
  const [refundAmount, setRefundAmount] = useState(_.get(props, 'extraDetails.total'))
  const [refundModal, setRefundModal] = useState(false)
  const [singleOrderDetails, setSingleOrderDetails] = useState(_.get(props, 'extraDetails', {}))
  const [amountType, setAmountType] = useState('1');

  const [isNewRefundModal, setIsNewRefundModal] = useState(false);
  const [getNewModalTitleName, setNewModalTitleName] = useState({});
  const [confirmCancelRefunfIsModalOpen, setConfirmCancelRefunfIsModalOpen] = useState(false);
  const [confirmRefundIsModalOpen, setConfirmRefundIsModalOpen] = useState(false);
  const [getUserTranscationTable, setUserTranscationTable] = useState([]);
  const[getComputedRefundData, setComputedRefundData] = useState({});
  const [getRefundableAmount, setRefundableAmount] = useState()
  const[amountValue, setValue] = useState('');
  const[cancelAndRefund, setCancelAndRefund] = useState(false);
  const [successRefundIsModalOpen, setSuccessRefundIsModalOpen] = useState(false);
  const [successCancelIsModalOpen, setSuccessCancelIsModalOpen] = useState(false);
  let column = [
    {
      title: 'First Name',
      key: 'first_name',
      dataIndex: 'first_name',
    },
    {
      title: 'Last Name',
      key: 'last_name',
      dataIndex: 'last_name',
    },
    {
      title: 'Date Of Birth',
      key: 'date_of_birth',
      dataIndex: 'date_of_birth',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'Action',
      width: '40%',
      key: 'action',
      dataIndex: 'action',
      render: (text, item) => (
        <div className="agent-list-action-btns" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}><MailFilled color='#a7a7a7' style={{ marginRight: '12px' }} /><span>Message</span></div>
          <div onClick={() => setViewModal(true)} style={{ alignItems: 'center', marginRight: '20px', cursor: _.get(item, 'refund') == 0 ? 'pointer' : 'not-allowed' }}><DeleteFilled color='#a7a7a7' style={{ marginRight: '12px' }} /><span>Remove / Refund</span></div>
          <div onClick={() => history(`/communiti/template-create?id=${_.get(item, 'id')}`)}><EditFilled style={{ marginRight: '12px' }} /><span><span style={{ cursor: 'pointer' }}>View</span> / <span style={{ cursor: 'pointer' }}>Manage</span></span></div>

        </div>
      ),

    },
  ]



  useEffect(() => {

    if (_.get(props, 'exp_id')) {
      orderList(_.get(props, 'exp_id'))
    };
    
    
  }, [])



  const orderList = (id) => {
    props.orderList({
      params: { experience: id, user_type: '1', expand: 'guest', paid: 1 },
      callback: (res, data) => {
        if (res == true) {
          setRegisterList(_.get(data, 'data.items', []))

        }
        setPageLoading(false)
      }
    })
  }

  const messageToHost = async() => {

    

    let guest_chat_id = _.get(props, 'usersDetails.chatkitty_user_id');
    
  
    if(_.isEmpty(guest_chat_id)){
      notification.error({
        message: "The guest has not yet registered for the chat or else could not find the guest chat ID."
      })
    } else {
      notification.success({
        message: "Redirecting to one to one conversation"
      });
      const result = await chatKittyInstance.createChannel({
        type: "DIRECT",
        members: [{ id: guest_chat_id }],
    });
      
    if (result.succeeded) {
        const channel = result.channel; // Handle channel
        
        history('/communiti/chats-notification', {state:{
          data: channel
        }})
    }
      
    }
  
  }

  const setRegisterList = (data) => {
    let a = []
    _.map(data, (value, index) => {

      a.push({
        key: _.get(value, 'id'),
        first_name: _.startCase(_.get(value, 'guest.first_name')),
        last_name: _.startCase(_.get(value, 'guest.last_name')),
        date_of_birth: _.get(value, 'guest.date_of_birth') ? moment(_.get(value, 'guest.date_of_birth')).format('MMMM D, YYYY') : '--',
        status: _.get(value, 'status') === 1 ? <span className='table-paid-status'>Active</span> : _.get(value, 'status') === 4 ? <span className='table-status-cancelled'>Cancelled</span> : '--',
        refund: _.get(value, 'refund')

      })
    })
    setUsersList(a)
  }

  const loadTemplateList = () => {
    setPageLoading(true)
    props.templateList({
      params: { sort: 'created_at.desc' },
      callback: (res, data) => {

        if (res == true && _.get(data, 'data.items[0]')) {
          setAllTemplateListData(_.get(data, 'data.items'))
          setAllTemplateListMeta(_.get(data, 'data.meta'))
        }
        setPageLoading(false)
      }
    })
  }


  const onContinue = () => {
    if (scheduleSelectId) {
      history(`/communiti/schedule?id=${scheduleSelectId}`)
    }
  }

  const refundsubmit = () => {
    let details = {
      refund: true,
      refund_amount: refundAmount,
      id: _.get(singleOrderDetails, 'id')
    }
    if (removeAllowLate == 1) {
      details.status = 4
      details.cancellation_glossary = 'Canncelled by host'

    }

    props.refund({
      body: details,
      callback: (res, data) => {

        setShowPreviewRefundModal(false)
      }
    })
  };

  const newRefundShowModal = (value) => {
    
    props.computeRefundAPI({
      body:{
        order: _.get(value, 'id')
      },
      callback: (res, val) => {
        
        
        if(res){
          setComputedRefundData(val)
        }
      }
    })
    setNewModalTitleName(value)
    setIsNewRefundModal(true);
    props.getOrderTranscationList({
      params:{
        order: _.get(value, 'id'),
        user_type: 1
      },
       callback: (response, data) => {
        
        
        if(response){
          userTransactionTable(_.get(data, 'data.items'))
        }
       }
    })
  
  };

  const userTransactionTable = (data) => {
    
  let a = [];
  let b = [];
  _.map(data,(value, index) => {
    
    a.push({
      key: _.get(value, 'id'),
      date: moment(_.get(value, 'created_at')).format("MMMM DD, YYYY"),
      description: _.get(value, 'label'),
      amount: _.get(value, 'amount')
    })
    if(!_.isEmpty(_.get(value, 'refund_payment_objects'))){
      _.map(_.get(value, 'refund_payment_objects'), (val, ind) => {
        b.push({
          key: _.get(val, 'id'),
          date: moment.unix(_.get(val, 'created')).format("MMMM DD, YYYY"),
          description: 'Refunded Amount',
          amount: _.get(val, 'amount')/100
        })
      })
    }
  })
  if(!_.isEmpty(_.get(data, '[0].refund_payment_objects'))){
    setUserTranscationTable(a.concat(b))
  } else{
    setUserTranscationTable(a)
  }
  
  }

  const handleRefundCancel = () => {
    setIsNewRefundModal(false);
   
  };

  const confirmCancelRefundShow = () => {
    setConfirmCancelRefunfIsModalOpen(true);
  };
  const confirmRefundShowModal = () => {
    setConfirmRefundIsModalOpen(true);
  };

  const mainRefundCancelModal = () => {
    confirmCancelRefundShow()
   };

   const enterRefundAmount = (e, amount) => {
    
    const newValue = e.target.value;
      if (!isNaN(newValue) && newValue <= amount) {
        setValue(newValue);
        setRefundableAmount(newValue)
      }
    
  };

  const onChangeRefundCheck = (e) => {
    setCancelAndRefund(e.target.checked)
    
  };

  const successRefundShowModal = () => {
    setSuccessRefundIsModalOpen(true);
  };
  const hideCancelRefund = () => {
    setConfirmRefundIsModalOpen(false);
  };

  const confirmProceedRefund = () => {
    // 
    if(!cancelAndRefund){
      props.proceedRefundAPI({
        body:{
          refund: true,
          id: _.get(getNewModalTitleName, 'id'),
          refund_amount: getRefundableAmount
        },
        callback: (response, data) => {
          
          
          if(response){
            setConfirmRefundIsModalOpen(false)
            successRefundShowModal();
          }
        }
      })
    } else {
      props.proceedRefundAPI({
        body:{
          refund: true,
          id: _.get(getNewModalTitleName, 'id'),
          refund_amount: getRefundableAmount,
          status: 4,
          cancellation_glossary: 'refunded and cancelled'
        },
        callback: (response, data) => {
          
          
          if(response){
            successRefundShowModal();
          }
        }
      })
    }
    
    // setConfirmRefundIsModalOpen(false);
  };

  const cancelConfirmRefundOk = () => {
    //getNewModalTitleName
    props.proceedRefundAPI({
      body:{
        id: _.get(getNewModalTitleName, 'id'),
        refund: false,
        status: 4,
        cancellation_glossary: 'cancelled.'
      },
      callback: (res, data) => {
        
        
        showSuccessCancelModal();
      }
    })
    setConfirmCancelRefunfIsModalOpen(false);
    
  };

  const showSuccessCancelModal = () => {
    setSuccessCancelIsModalOpen(true);
  };

  const successRefundHandleOk = () => {
    setSuccessRefundIsModalOpen(false);
    setIsNewRefundModal(false)
  };

  const successRefundHandleCancel = () => {
    setSuccessRefundIsModalOpen(false);
  };

  const cancelModalRefund = () => {
    setConfirmCancelRefunfIsModalOpen(false);
  };

  const successCancelOk = () => {
    setSuccessCancelIsModalOpen(false);
  };

  const handleSuccessCancel = () => {
    setSuccessCancelIsModalOpen(false);
  };

  const render = () => {
    let newRefundColumn = [
      {
        title: "Date",
        key: "date",
        dataIndex: "date"
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description"
      },
      {
        title: "Amount",
        key: "amount",
        dataIndex: "amount"
      },
    ];
    
    let showParentDetails = moment().diff(moment(_.get(props, 'extraDetails.profile.date_of_birth')), 'years')
    
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Modal centered={true} maskClosable
          onCancel={() => setRefundModal(false)} className='success-save-modal' style={{ padding: '68px 18px', width: '836px' }} footer={null} visible={refundModal}>
          <div style={{
            display: 'flex',
            flexDirection: 'column', marginBottom: '4%',
            alignItems: 'center',
          }}>
            <span style={{ fontSize: '29px', fontWeight: '700', marginBottom: '2%', }}>Please confirm the amount you wish to refund</span>
            <InputNumber max={parseFloat(_.get(singleOrderDetails, 'total'))} disabled={amountType == 1 ? true : false} value={refundAmount} onChange={(e) => { setRefundAmount(e) }} className='refund-modal-input' placeholder='Enter Amount' />

          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center', marginBottom: '2%',
            marginTop: '32px'
          }}>
            <Button onClick={() => { setAmountType('1'); setRefundAmount(_.get(singleOrderDetails, 'total')) }} className='unsaved-btn-cancel' style={{ backgroundColor: amountType == "1" ? '#e85f25' : "#a7a7a7", marginRight: 23, width: '23%' }}
            >Full Amount</Button>
            {_.get(props, 'is_start') == true && <Button onClick={() => {
              setAmountType('2');
              setRefundAmount(parseFloat(_.get(singleOrderDetails, 'total')) - parseFloat(_.get(singleOrderDetails, 'refund')))
            }} className='unsaved-btn' style={{ backgroundColor: amountType == "2" ? '#e85f25' : "#a7a7a7", width: '23% !important' }}
            >Custom</Button>}</div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: '700', fontSize: '25px' }}>Do you also wish to remove their registration?</span>

          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px'
          }}>
            <Button onClick={() => setRemoveAllowLate('1')} className='unsaved-btn-cancel' style={{ backgroundColor: removeAllowLate == "1" ? '#e85f25' : "#a7a7a7", marginRight: 23, width: '23%' }}
            >Yes</Button>
            <Button onClick={() => setRemoveAllowLate('2')} className='unsaved-btn' style={{ backgroundColor: removeAllowLate == "2" ? '#e85f25' : "#a7a7a7", width: '23% !important' }}
            >No</Button></div>


          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px'
          }}>
            <Button onClick={() => setRefundModal(false)} className='unsaved-btn-cancel' style={{ backgroundColor: '#a7a7a7', marginRight: 23, width: '23%' }}
            ><ArrowLeftOutlined />Back</Button>
            <Button onClick={() => { setRefundModal(false); setShowPreviewRefundModal1(true) }} className='form-btn-4 unsaved-btn' style={{ backgroundColor: '#e85f25', width: '23% !important' }}
            >Continue<ArrowRightOutlined /> </Button></div>
        </Modal>
        <Modal centered={true} maskClosable
          onCancel={() => setShowPreviewRefundModal1(false)} className='success-save-modal' style={{ padding: '68px 18px', width: '836px' }} footer={null} visible={showPreviewRefundModal1}>
          <div style={{
            display: 'flex',
            flexDirection: 'column', marginBottom: '4%',
            alignItems: 'center',
          }}>
            <span style={{ fontSize: '29px', fontWeight: '700', marginBottom: '2%', }}>Please confirm the amount you wish to refund</span>
            <div className='preview-selected-date-refund'><span className='preview-selected-date-name-refund'>${refundAmount}</span></div>

          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ fontWeight: '700', fontSize: '25px' }}>Do you also wish to remove their registration?</span>

          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px'
          }}>
            <Button className='unsaved-btn-cancel-refund' style={{ backgroundColor: removeAllowLate == "1" ? "#e85f25" : "#a7a7a7", marginRight: 23, width: '23%', color: removeAllowLate == "1" ? "white" : '#a7a7a7' }}
            >Yes</Button>
            <Button className='unsaved-btn' style={{
              backgroundColor: removeAllowLate == "2" ? "#e85f25" : "#a7a7a7"
              , width: '23% !important', color: removeAllowLate == "2" ? "white" : '#a7a7a7'
            }}
            >No</Button></div>


          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '32px'
          }}>
            <Button onClick={() => setShowPreviewRefundModal1(false)} className='unsaved-btn-cancel' style={{ backgroundColor: '#a7a7a7', marginRight: 23, width: '23%' }}
            ><ArrowLeftOutlined />Back</Button>
            <Button onClick={() => { refundsubmit() }} className='form-btn-4 unsaved-btn' style={{ backgroundColor: '#e85f25', width: '23% !important' }}
            >Refund<ArrowRightOutlined /> </Button></div>
        </Modal>
        <Modal centered={true} maskClosable
          onCancel={() => props.onStateChange(false)}
          className='success-save-modal'
          style={{ padding: '68px 18px', width: '836px' }} footer={null} visible={_.get(props, 'viewModal')}>
          <div style={{
            display: 'flex',
            flexDirection: 'column', marginBottom: '4%',
            alignItems: 'center',
          }}>
            <span style={{ fontSize: '29px', fontWeight: '700', marginBottom: '2%', }}>View Information</span>
          </div>
          
          <div className='col-12' style={{ display: 'flex' }}>
            <div className='col-6' >
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>First Name</span>
                <span>{_.get(props, 'regis_pers.regestration_person.first_name') ? _.startCase(_.get(props, 'regis_pers.regestration_person.first_name')) : _.startCase(_.get(props, 'usersDetails.relations.first_name'))}</span>

              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Date Of Birth</span>
                <span>{_.get(props, 'regis_pers.regestration_person.date_of_birth') ? _.get(props, 'regis_pers.regestration_person.date_of_birth')!="Invalid date"?moment(_.get(props, 'regis_pers.regestration_person.date_of_birth')).format('MMMM D, YYYY'):'--' : _.get(props, 'usersDetails.relations.date_of_birth') !="Invalid date" ? moment(_.get(props, 'usersDetails.relations.date_of_birth')).format("MMMM DD, YYYY") : "---"}
               </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Gender</span>
                <span>{_.get(props, 'regis_pers.regestration_person.gender') ? _.get(props, 'regis_pers.regestration_person.gender') == "male" || _.get(props, 'regis_pers.regestration_person.gender') == "female" ? _.startCase(_.get(props, 'regis_pers.regestration_person.gender')) : `Self Identified (${_.startCase(_.get(props, 'regis_pers.regestration_person.gender'))})` : _.get(props, 'usersDetails.relations.gender') === 'male' || _.get(props, 'usersDetails.relations.gender') === 'female' ? _.startCase(_.get(props, 'usersDetails.relations.gender')) : `Self Identified (${_.startCase(_.get(props, 'usersDetails.relations.gender'))})`}</span>

              </div>
              
              {_.get(props, 'usersDetails.relations.phone_number') && <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Phone Number</span>
                <span>{_.get(props, 'usersDetails.relations.phone_number', '--')}</span>

              </div>}
             
              {_.get(props, 'usersDetails.relations.email') && <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Email</span>
                <span>{_.get(props, 'usersDetails.relations.email', '--')}</span>

              </div>}
              {_.get(props, 'usersDetails.relations.parent_or_guardian_first_name') && <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Guardian's First Name</span>
                <span>{_.get(props, 'usersDetails.relations.parent_or_guardian_first_name', '--')}</span>

              </div>}
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                {/* <span className='user-title-style'>Question</span>
                <span>{_.get(props, 'regn_det[0].label')}</span> */}
                <Collapse>
                <Panel header="Questions & Answers" key="1">
                {
                 _.size(_.get(props, 'regn_det')) > 0 ? _.get(props, 'regn_det').map((val, index) => {
                    return(
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                      <span style={{ marginTop: "10px"}} key={index}><span style={{fontWeight: "bold"}}>{`${index+1}. Question: `}</span> {_.get(val, 'label')}</span>
                      <span key={index}><span style={{fontWeight: "bold", marginTop: "20px"}}>{`Answer: `}</span> {_.get(val, 'answer')}</span>
                      </div>
                    )
                  })
                  : ""
                }
                </Panel>
               </Collapse>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <Collapse>
                <Panel header="Clauses" key="1">
                {
                  _.get(props, 'extraDetails.clauses') && _.get(props, 'extraDetails.clauses').map((val, index) => {
                    return(
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                      <span><span style={{fontWeight: "bold"}}>Name:</span> {_.get(val, 'name')}</span>
                      <span style={{marginTop: "10px", display: !_.isEmpty(_.get(val, 'link')) ? "block": "none" }}><span style={{fontWeight: "bold"}}>Link:</span> {!_.isEmpty(_.get(val, 'link')) ? _.get(val, 'link') : "-"}</span>
                      <span style={{marginTop: "10px", display: !_.isEmpty(_.get(val, 'description')) ? "block": "none"}}><span style={{fontWeight: "bold"}}>Description:</span> {!_.isEmpty(_.get(val, 'description')) ? _.get(val, 'description') : "-"}</span>
                      <span style={{marginTop: "10px"}}><span style={{fontWeight: "bold"}}>Accepted Time:</span> {moment(_.get(props, 'extraDetails.created_at')).format("LLLL")}</span>
                      </div>
                    )
                  })
                }
                </Panel>
               </Collapse>
              </div>
              {/* {
                showParentDetails < 18 ? 
             
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Parent Info</span>

                      <div style={{display: 'flex', flexDirection: 'column'}}>
                      <span><span style={{fontWeight: "bold"}}>First Name:</span> {_.startCase(_.get(props, 'extraDetails.guest.first_name'))}</span>
                      <span style={{marginTop: "10px"}}><span style={{fontWeight: "bold"}}>Last Name:</span> {_.startCase(_.get(props, 'extraDetails.guest.last_name'))}</span>
                      <span style={{marginTop: "10px"}}><span style={{fontWeight: "bold"}}>Address:</span> {_.isEmpty(_.get(props, 'extraDetails.guest.address')) ? "--" : _.startCase(_.get(props, 'extraDetails.guest.address'))}</span>
                      <span style={{marginTop: "10px"}}><span style={{fontWeight: "bold"}}>Zipcode:</span> {_.isEmpty(_.get(props, 'extraDetails.guest.zipcode')) ? "--" : _.startCase(_.get(props, 'extraDetails.guest.zipcode'))}</span>
                      </div>

              </div>
              :""
              } */}
              
              {/* <div style={{display:'flex',alignItems:'flex-start',flexDirection:'column',marginBottom:'10%'}}>
<span className='user-title-style'>Address</span>
    <span>{_.get(props,'usersDetails.relations.address','--')?_.get(props,'usersDetails.relations.address','--'):'--'}</span>

</div> */}
              {/* <div style={{display:'flex',alignItems:'flex-start',flexDirection:'column',marginBottom:'10%'}}>
<span className='user-title-style'>Skate Canada Number</span>
<span>{_.get(props,'usersDetails.relations.skate_canada_number','--')}</span>

</div> */}
              {/* <div style={{display:'flex',alignItems:'flex-start',flexDirection:'column',marginBottom:'10%'}}>
<span className='user-title-style'>Phone Number</span>
    <span>Test</span>

</div> */}
              {/* <div style={{display:'flex',alignItems:'flex-start',flexDirection:'column',marginBottom:'10%'}}>
<span className='user-title-style'>Home Club</span>
<span>{_.get(props,'usersDetails.relations.home_club','--')}</span>


</div> */}


            </div>
            <div className='col-6'>
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Last Name</span>
                <span>{_.get(props, 'regis_pers.regestration_person.last_name') ? _.startCase(_.get(props, 'regis_pers.regestration_person.last_name')) : _.startCase(_.get(props, 'usersDetails.relations.last_name'))}</span>
              </div>

              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Contact Information</span>
                <div style={{display: "flex", flexDirection: "row"}}>
                  <span style={{fontWeight: "bold"}}>First Name:</span>
                <span style={{marginLeft: "10px"}}>{_.startCase(_.get(props, 'extraDetails.guest.first_name'))}</span>
                </div>
                <div style={{display: "flex", flexDirection: "row"}}>
                  <span style={{fontWeight: "bold"}}>Last Name:</span>
                <span style={{marginLeft: "10px"}}>{_.startCase(_.get(props, 'extraDetails.guest.last_name'))}</span>
                </div>
                <div style={{display: "flex", flexDirection: "row"}}>
                  <span style={{fontWeight: "bold"}}>Email:</span>
                <span style={{marginLeft: "10px"}}>{_.get(props, 'extraDetails.guest.contact_details.email')}</span>
                </div>

                <div style={{display: "flex", flexDirection: "row"}}>
                  <span style={{fontWeight: "bold"}}>Address:</span>
                <span style={{marginLeft: "10px"}}>{ !_.isEmpty(_.get(props, 'extraDetails.guest.address')) ? _.startCase(_.get(props, 'extraDetails.guest.address')) : "-"}</span>
                </div>

              </div>
              
              {/* <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Email</span>
                <span>{_.get(props, 'usersDetails.email')}</span>


              </div> */}

              {_.get(props, 'usersDetails.relations.parent_or_guardian_last_name') && <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Guardian's Last Name</span>
                <span>{_.get(props, 'usersDetails.relations.parent_or_guardian_last_name', '--')}</span>

              </div>}
              {_.get(props, 'usersDetails.relations.parent_or_guardian_email') && <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Guardian's Email</span>
                <span>{_.get(props, 'usersDetails.relations.parent_or_guardian_email', '--')}</span>

              </div>}
              {_.get(props, 'usersDetails.relations.parent_or_guardian_phone_number') && <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '10%' }}>
                <span className='user-title-style'>Guardian's Phone Number</span>
                <span>{_.get(props, 'usersDetails.relations.parent_or_guardian_phone_number', '--')}</span>

              </div>}
            
              {/* <div style={{display:'flex',alignItems:'flex-start',flexDirection:'column',marginBottom:'10%'}}>
<span className='user-title-style'>Primary Coach</span>
<span>{_.get(props,'usersDetails.relations.primary_coach','--')}</span>


</div>
<div style={{display:'flex',alignItems:'flex-start',flexDirection:'column',marginBottom:'10%'}}>
<span className='user-title-style'>Last Test Passed</span>
<span>{_.get(props,'usersDetails.relations.last_test_passed','--')}</span>


</div> */}

            </div>

          </div>



          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: '12px'
          }}>
            <div style={{ textAlign: 'center', fontSize: '17px', fontWeight: 'bolder', marginBottom: '3%' }} >Actions</div>
            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={() => {props.onStateChange(false); messageToHost() }} className='unsaved-btn-cancel' style={{ backgroundColor: '#a7a7a7', }}
              ><MailFilled />Message</Button>
              <Button onClick={() => { props.onStateChange(false); newRefundShowModal(_.get(props, 'extraDetails')) }} className='form-btn-4 unsaved-btn' style={{ backgroundColor: '#e85f25' }}
              ><DollarCircleFilled />Refund </Button>

              <Button onClick={() => props.onStateChange(false)} className='unsaved-btn-cancel' style={{ backgroundColor: '#a7a7a7' }}
              ><ArrowLeftOutlined />Back</Button>
            </div>
          </div>

        </Modal>

        <Modal centered={true} 
      className='purchase-modal' 
      width="50%" 
      title={`Cancel Registration & Refund for ${_.startCase(_.get(getNewModalTitleName, 'first_name'))}`}
      open={isNewRefundModal} 
      // onOk={proceedRefund} 
      onCancel={handleRefundCancel}
      footer={[
        <Button onClick={() => mainRefundCancelModal()} style={{borderRadius:"6px"}}>Cancel Registration Only</Button>,
        //  <Button className='download-invoice' onClick={print}>Download Purchase Invoice</Button>,
        <Button onClick={() => confirmRefundShowModal()} style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}}>Refund</Button>
      ]}
      >
        <p>Below is a history of what the registrant paid, and any refunds you have already </p>
        <p style={{marginTop:"-11px"}}>issued. If you wish to cancel a person’s registration without a refund, press the</p>
        <p style={{marginTop:"-11px"}}>“Cancel Registration Only” button.</p>
        <p>Registration history</p>
        <div className='template-list-table' >
            <Table columns={newRefundColumn} pagination={false} dataSource={getUserTranscationTable} />
          </div>
          <p style={{marginBottom: "1px", marginTop:"2px", fontSize:"12px", marginLeft:"436px"}}>Minimum Refund Amount: $0.01</p>
          <p style={{fontSize:"12px", marginLeft:"436px"}}>Maximum Refund Amount: ${_.get(getComputedRefundData, 'details.max_refund')}</p>
          <p>Enter a refund amount</p>
          <p style={{marginLeft:"254px", marginBottom:"5px"}}>Refund amount</p>
          <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
            <Input onChange={(e) => enterRefundAmount(e, parseFloat(_.get(getComputedRefundData, 'details.max_refund')))} type="number" value={amountValue} placeholder='Enter a $ amount' style={{width:"150px"}}/>
          </div>
          <div style={{marginTop:"25px"}}>
          <Checkbox onChange={onChangeRefundCheck}><p style={{fontSize:"12px"}}>Also set this person’s registration to <Tag style={{borderRadius:"12px", marginLeft:"6px"}} color="default">Cancelled</Tag></p></Checkbox>
          </div>
      </Modal> 


      <Modal centered={true} 
       className='purchase-modal' 
      title={`Refund $${getRefundableAmount}?` }
      open={confirmRefundIsModalOpen} 
      onOk={confirmProceedRefund} 
      onCancel={hideCancelRefund}
      footer={[
        <Button style={{borderRadius:"6px"}} onClick={() => hideCancelRefund()}>Cancel</Button>,
        <Button style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}} onClick={() => confirmProceedRefund()}>Confirm Refund</Button>
      ]}
      >
        <p>Once you have confirmed the refund, it</p>
        <p style={{marginTop:"-11px"}}>cannot be reversed.</p>
      </Modal>

      <Modal centered={true} 
       className='purchase-modal' 
      title="Success" 
      open={successRefundIsModalOpen} 
      onOk={successRefundHandleOk} 
      onCancel={successRefundHandleCancel}
      footer={[
        <Button style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}} onClick={() => successRefundHandleOk()}>Continue</Button>,
      ]}
      >
        <p>The refund was processed sucessfully.</p>
        <p style={{marginTop:"-11px"}}>cannot be reversed.</p>
      </Modal>

      <Modal centered={true} className='purchase-modal' 
       title="Are you sure?" 
       open={confirmCancelRefunfIsModalOpen} 
       onOk={cancelConfirmRefundOk} 
       onCancel={cancelModalRefund}
       footer={[
        <Button onClick={() => cancelModalRefund()} style={{borderRadius:"6px"}}>Cancel</Button>,
        <Button onClick={() => cancelConfirmRefundOk()} style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}}>Cancel Registration</Button>
       ]}
       >
        <p>Once you have cancel this person’s registration, it cannot be reversed.</p>
        <p style={{marginTop:"-11px"}}>registration, it cannot be reversed.</p>
      </Modal>

      <Modal centered={true} 
       className='purchase-modal' 
      title="Success" 
      open={successCancelIsModalOpen} 
      onOk={successCancelOk} 
      onCancel={handleSuccessCancel}
      footer={[
        // <Button onClick={() => handleSuccessCancel()}>Cancel</Button>,
        <Button style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}} onClick={() => successCancelOk()}>Continue</Button>,
      ]}
      >
        <p>The registration was cancelled</p>
        <p style={{marginTop:"-11px"}}>successfully.</p>
      </Modal>

      </div>)
  }

  return render()
}
const mapStateToProps = state => {
  return {
    checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
    isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
    islogindata: _.get(state, 'app.account.isLogin'),
    userProfile: _.get(state, 'app.host.profile.data.details'),

  }
}

const mapDispatchToProps = dispatch => ({
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  templateList: details => dispatch(global.redux.action.template.list(details)),
  list: details => dispatch(global.redux.action.experience.list(details)),
  orderList: details => dispatch(global.redux.action.experience.orderList(details)),
  refund: details => dispatch(global.redux.action.experience.refund(details)),
  getOrderTranscationList: details => dispatch(global.redux.action.experience.transList(details)),
  computeRefundAPI: details => dispatch(global.redux.action.experience.computeRefund(details)),
  proceedRefundAPI: details => dispatch(global.redux.action.experience.newRefund(details)),


});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateList)