import React from 'react'
import "./style.css"

const coordinatorErrorMessage = () => {
  return (
    
        <div className='error-title'>
            <h3>Oops... it seems you are not assigned a parent club.</h3>
            <h3>Please contact support@communiti.app for assistance.</h3>
                <div className="image">
                    <img src={require("../../assets/img/contact-coordinator.png")} alt="Image" style={{ maxWidth: '500px', maxHeight: '500px' }} />
                </div>
        </div>
    
   
    
  )
}

export default coordinatorErrorMessage