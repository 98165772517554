import { CheckCircleFilled, CheckCircleOutlined, UpOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React from 'react'
import {Collapse} from 'antd'

import './style.css'
import { useNavigate } from 'react-router-dom'
const { Panel } = Collapse;

const CoachInfoLanding =(props)=>{
	let history = useNavigate();


    const render =()=>{
        return (
        <div className="coach-full-conatiner">
            <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
            <CheckCircleFilled style={{fontSize:23,color:'#307c00'}}/>
                <span style={{fontSize:'33px',fontWeight:'500',marginBottom:'13px'}}>You're all set.</span>
                
                <div style={{marginBottom:'6%'}}>
                <div className='info-container'>
                 </div><span>You’re now ready to create and schedule templates and receive payments.</span></div>
                
                <div  
            className="btn-inner-container" onClick={()=>history('/communiti/main-menu')} style={{backgroundColor:"#e85f24",marginLeft:'60%',cursor:'pointer',justifyContent:'center'}}> <div style={{    padding:"6px 59px",color: "#fff"}}>Continue</div></div>
   </div>
            

            
        </div>)
    }

    return render()
}

export default CoachInfoLanding