import React, {useEffect, useState} from 'react';
import { Space, Table, Tag } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom'

import '../../onboarding-screen/style.css';
import MainMenu from '../../main-menu'

const IndividualOnsiteFacilitation = (props) => {
    let history = useNavigate();

    const render = () => {

        const columns = [
            {
              title: 'Date',
              dataIndex: 'date',
              key: 'date',
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: 'Action',
              key: 'action',
              render: () => (
                <Space size="middle">
                  <a>View QR Code</a>
                  <a onClick={() => history(`/communiti/facilitate-session`)} >Manage Onsi</a>
                </Space>
              ),
            },
          ];

          const data = [
            {
              key: '1',
              date: 'May 02, 2023',
              status: 'Not Started',
            },
            {
              key: '2',
              date: 'May 02, 2023',
              status: 'Not Started',
            },
            {
              key: '3',
              date: 'May 02, 2023',
              status: 'Not Started',
            },
          ];

        return(
            <div>
                <MainMenu {...props} manageTab={true}/>
            <div className="coach-full-conatiner">
                <Table columns={columns} dataSource={data} pagination={false} />
            </div>
            </div>
        )
    };

    return render();
    
};
export default IndividualOnsiteFacilitation;