import { EditFilled, DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, PlusSquareOutlined, ClockCircleFilled, DownloadOutlined, MessageFilled, MailFilled, WarningOutlined, EyeOutlined, CloseCircleOutlined } from '@ant-design/icons'
// import Input from 'antd/lib/input/Input'
import React, { useState, useEffect, useRef } from 'react'
import { Button, Collapse, Form, Select, notification, Modal, Table, Switch, Input, Skeleton, Tooltip, Tag, Image, Dropdown, Space, Popconfirm, Popover, Card, Radio, Tabs, Pagination, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { set } from 'lodash';
import { connect } from 'react-redux'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import momentTimezone from 'moment-timezone';
import moment from 'moment'
import  ExportJsonCsv  from '../../export-csv/intex';
import { Scrollbars } from 'react-custom-scrollbars';
import { AiOutlineCopy, AiOutlineMessage } from "react-icons/ai"
import { SiMinutemailer } from "react-icons/si";
import ReactQuill from 'react-quill';
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import chatKittyInstance from '../../../core_units/chat-system/chatkitty/instance';


import '../../onboarding-screen/style.css'
import '../style.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios';
import { IoIosMore, IoMdAdd, IoMdClose } from 'react-icons/io';
import { FiSearch } from 'react-icons/fi';
import Cancel from './cancel';
import Past from './past';
import Chat from './chat';
const { Panel } = Collapse;
const { confirm } = Modal
const { Option } = Select;
const TemplateList = (props) => {
  const [form] = Form.useForm();
  const listInnerRef = useRef();
  const myRef = useRef(null)
  const { Search } = Input;
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedTab, setselectedTab] = useState('Active')
  let history = useNavigate();
  const [searchExpName, setsearchExpName] = useState('')
  const [AllTemplateListData, setAllTemplateListData] = useState([])
  const [AllTemplateListMeta, setAllTemplateListMeta] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [page, setpage] = useState(1)
  const [experiencesList, setExperiencesList] = useState([])
  const [experiencesListMeta, setExperiencesListMeta] = useState({})
  const [scheduleModal, setScheduleModal] = useState(false)
  const [scheduleSelectId, setScheduleSelectId] = useState('')
  const [timeZone, setTimeZone] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getModalDetails, setModalDetails] = useState({});
  const [activateIsModalOpen, setActivateIsModalOpen] = useState(false);
  const [activateGetModalDetails, activateSetModalDetails] = useState({});
  const [extraStoreExpId, setextraStoreExpId] = useState('')
  const [isWarninngModalOpen, setIsWarninngModalOpen] = useState(false);
  const [getWaningData, setWaningData] = useState({});
  const [dateFilterValue, setDateFilterValue] = useState();
  const [getSearchByFilter, setSearchByFilter] = useState('');
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isUrlModalOpen, setIsURLModalOpen] = useState(false);
  const [getSelectOptions, setSelectOptions] = useState([]);
  const [getExpId, setExpId] = useState();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("")
  const [commonSearchTagWord, setCommonSearchTagWord] = useState('')
  const [searchTagWord, setsearchTagWord] = useState('')
  const [tags, settags] = useState([])
  const [commonTags, setCommonTags] = useState([])

  const [isTagOpen, setisTagOpen] = useState('')
  const [isCommonTagOpen, setisCommonTagOpen] = useState('')
  const [commonFilterTags, setCommonFilterTags] = useState([])
  const [filterTags, setfilterTags] = useState([])
  const [singleExperieceTag, setsingleExperieceTag] = useState([])
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(true)
  const [chennelData, setchennelData] = useState('');
  const [csvItems, setCsvItems] = useState([]);

  const [isPauseOrCancelModalOpen, setIsPauseOrCancelModalOpen] = useState(false);
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
  const [isUnPauseModalOpen, setUnIsPauseModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [experienceViewData, setExperienceViewData] = useState({})

// set the page size
const [pagination, setPagination] = useState({
  current: 1,
  pageSize: 25,
  total: 0,
});

useEffect(() => {
  exportCSVList();
}, [])
  useEffect(() => {

    if (_.size(props?.userProfile?.meta_details?.tags) > 0) {
      tagFunctionHandler()
    }
  }, [props?.userProfile?.meta_details?.tags])
  useEffect(() => {
    getLocationsData()
  }, [props?.filter_data_value])

  useEffect(() => {
    getLocationsData()
  }, [props?.searchExpName])


  useEffect(() => {

    if (_.size(props?.userProfile?.meta_details?.tags) > 0) {
      filterTagFunctionHandler(commonFilterTags)
    }
  }, [props?.userProfile?.meta_details?.tags])

  useEffect(() => {

    
      getLocationsData()
    
  }, [commonFilterTags])
  const updateHostTags = (tags) => {
    
    props.updateHostTag({
      body: {
        key: 'tags',
        value: commonSearchTagWord &&_.size(tags)>0 ? [...tags, commonSearchTagWord] :commonSearchTagWord &&_.size(tags)==0?[commonSearchTagWord]: [...tags]
      },
      callback: (res, data) => {
        if (res == true && _.get(data, 'details.id')) {
          
          setCommonSearchTagWord('')
          filterTagFunctionHandler(_.get(data, 'details.meta_details.tags'))
          props.hostProfile()
        }


      }
    })
  }
  const commonItems = [
    {
      label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><div>
        <Image style={{ width: '20px', height: '20px' }} preview={false} src={require('../../../assets/img/manage-tag.png')} /> Tags</div><div>
          <CloseCircleOutlined onClick={() => { setisCommonTagOpen(false);  }} size={40} style={{ fontSize: '20px', color: '#4f5b6d' }} />
        </div></div>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <Input
        value={commonSearchTagWord}
        onKeyDown={(e) => {
          if (e.code == 'Enter') {
            
            let a = _.filter(props?.userProfile?.meta_details?.tags, (v) => _.lowerCase(v) == _.lowerCase(searchTagWord))
            
            if (_.size(a) == 0) {
              updateHostTags(props?.userProfile?.meta_details?.tags)
            }
          }


        }}

        onChange={(e) => searchCommonFilterTag(e.target.value)} className='drop-input-menu' style={{ border: 'none', borderWidth: '0px', height: '44px', marginLeft: '-11px' }} prefix={<Image preview={false} style={{ width: '20px', height: '20px', }} src={require('../../../assets/img/manage-add.png')} />} placeholder='Search or Create' />,
      key: '1',
    },
    {
      type: 'divider',
    },
    ...commonTags
  ];
  const filterTagFunctionHandler = (filterTags) => {
    let a = []
    _.map(props?.userProfile?.meta_details?.tags, (v, i) => {
      a = [...a, {
        key: v,
        label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><div 
        
        onClick={() => {
          let isCheckTag = _.includes(filterTags, v)
          
          if (isCheckTag == true) {
            let filter_tag = _.filter(filterTags, (tag) => tag != v)
            
            setCommonFilterTags(filter_tag)
            filterTagFunctionHandler(filter_tag)
          } else {
            setCommonFilterTags(_.size(filterTags) > 0 ? [...filterTags, v] : [v])
            filterTagFunctionHandler(_.size(filterTags) > 0 ? [...filterTags, v] : [v])
          }

        }}
        style={{ display: 'flex', alignItems: 'center' }}>
          {_.includes(filterTags, v) == true ? <Image style={{ width: '20px', height: '20px' }} preview={false} src={require('../../../assets/img/Checkbox_base.png')} /> : <Image preview={false}
            style={{ width: '20px', height: '20px' }}
            src={require('../../../assets/img/Checkbox.png')} />}
          {/* <Checkbox 
          value={_.includes(filterTags,v)?true:false} 
          defaultChecked={_.includes(filterTags,v)?true:false} /> */}
          <div  style={{ marginLeft: '12px', color: '#344054', fontSize: '14px', fontWeight: 500 }}>{v}</div></div>
          <div style={{ display: 'flex' }}>
            <Dropdown
              openClassName='drop-tag-menu'
              overlayClassName='drop-tag-menu'
              rootClassName='drop-tag-menu'
              key={'test-menu'} menu={{
                items: [
                  {
                    label: <div>Delete</div>,
                    key: '0',
                  }], onClick: () => {

                    let tags = _.filter(props?.userProfile?.meta_details?.tags, (t) => t != v ? t : null)
                    setCommonFilterTags(_.compact(tags))
                    filterTagFunctionHandler(_.compact(tags))
                    updateHostTags(_.compact(tags))

                  }
              }} trigger={['click']}>
              <IoIosMore size={26} color='#475467' />
            </Dropdown>

          </div></div>,
      }, {
        type: 'divider',
      },]

    })
    setCommonTags(a)
  }

  const searchCommonFilterTag = (value) => {
    setCommonSearchTagWord(value)
    if (value) {
      let tagsData = [...commonTags]

      let filter_tag = _.filter(tagsData, (v) => {
        return `${_.lowerCase(v?.key)}`.includes(_.lowerCase(value))
      })

      setCommonTags(filter_tag)
    } else {
      filterTagFunctionHandler()
    }

  }


  const tagFunctionHandler = (filterTags, id,isSize=false) => {
    let a = []
    _.map(props?.userProfile?.meta_details?.tags, (v, i) => {
      
      let tagsValue = isSize==true?[]:_.size(filterTags) > 0 ? filterTags : singleExperieceTag
      a = [...a, {
        key: v,
        label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><div
        onClick={() => {
          let isCheckTag = _.includes(tagsValue, v)
          if (isCheckTag == true) {
            let filter_tag = _.filter(tagsValue, (tag) => tag != v)
            updateExperienceTag(filter_tag, id)
          } else {

            updateExperienceTag(_.size(tagsValue) > 0 ? [...tagsValue, v] : [v], id)
          }
        }
        }
        style={{ display: 'flex', alignItems: 'center' }}>
          {_.includes(tagsValue, v) == true ? <Image style={{ width: '20px', height: '20px' }} preview={false} src={require('../../../assets/img/Checkbox_base.png')} /> : <Image preview={false}
            style={{ width: '20px', height: '20px' }}
            src={require('../../../assets/img/Checkbox.png')} />}

          <div 
            style={{ marginLeft: '12px', color: '#344054', fontSize: '14px', fontWeight: 500 }}>{v}</div></div>
        </div>,
      }, {
        type: 'divider',
      },]

    })
    settags(a)
  }
  const searchFilterTag = (value) => {
    setsearchTagWord(value)
    if (value) {
      let tagsData = [...tags]

      let filter_tag = _.filter(tagsData, (v) => {
        return `${_.lowerCase(v?.key)}`.includes(_.lowerCase(value))
      })

      settags(filter_tag)
    } else {
      tagFunctionHandler()
    }

  }

  const updateExperienceTag = (tags, id) => {
    
    props.updateExperienceTag({
      body: {
        tags: searchTagWord ? [...tags, searchTagWord] : [...tags],
        id: id != "" ? id : isTagOpen
      },
      callback: (res, data) => {
        if (res == true && _.get(data, 'details.id')) {
          setsearchTagWord('')
          if(_.size(props?.userProfile?.meta_details?.tags)==_.size(_.get(data, 'details.tags'))){
            setsingleExperieceTag([])
            tagFunctionHandler([], _.get(data, 'details.id'))

          }else{
            setsingleExperieceTag(_.get(data, 'details.tags'))
            tagFunctionHandler(_.get(data, 'details.tags'), _.get(data, 'details.id'))
          }
          
         
         
          getLocationsData()
        }


      }
    })
  }

  const items = [
    {
      label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><div>
        <Image style={{ width: '20px', height: '20px' }} preview={false} src={require('../../../assets/img/manage-tag.png')} /> Tags</div><div>
          <CloseCircleOutlined onClick={() => {
            setisTagOpen(''); searchFilterTag('');
            setextraStoreExpId('')
          }} size={40} style={{ fontSize: '20px', color: '#4f5b6d' }} />
        </div></div>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <Input
        value={searchTagWord}


        onChange={(e) => searchFilterTag(e.target.value)} className='drop-input-menu' style={{ border: 'none', borderWidth: '0px', height: '44px', marginLeft: '-11px' }} prefix={<Image preview={false} style={{ width: '20px', height: '20px', }} src={require('../../../assets/img/manage-add.png')} />} placeholder='Search' />,
      key: '1',
    },
    {
      type: 'divider',
    },
    ...tags
  ];

  const renderToolTipTag = (tags, id) => {
    return _.map(tags, (v, i) => {
      return <Tag key={`${v}${id}`} icon={<Image style={{ width: '12px', height: '12px' }} preview={false} src={require('../../../assets/img/manage-tag.png')} />} style={{
        backgroundColor: '#F2F4F7', borderRadius: '16px', height: '25px',
        fontSize: '12px', fontWeight: 500, color: '#344054',
      }} closeIcon={<IoMdClose size={16} />} onClose={() => {
        removeExperienceTag(v, tags, id)

      }}>
        {v}
      </Tag>
    })

  }

  const renderAddTag = (id, tags) => {
    
    return <Dropdown
      key={id}
      className='drop-tag-menu'
      open={isTagOpen == id ? true : false}

      menu={{
        items: items,
        onClick: (e) => {
          

        },
      }}
      trigger={['click']}
      openClassName='drop-tag-menu'
      overlayClassName='drop-tag-menu'
      rootClassName='drop-tag-menu'
    >
      <a >
        <Space>
          <Tag icon={<IoMdAdd />} style={{
            backgroundColor: '#F2F4F7', borderRadius: '16px', height: '25px',
            fontSize: '12px', fontWeight: 500, color: '#344054',
          }} onClick={() => {
            
            setextraStoreExpId(id)
            setsingleExperieceTag(tags);
            tagFunctionHandler(tags, id,tags?.length>0?false:true)
            setTimeout(() => {
              setisTagOpen(id);
            }, 800);

          }}>
            Add Tag
          </Tag>
          {/* <Button onClick={() => setisTagOpen(true)} className='tag-image' style={{ height: '44px' }}><Image style={{ width: '20px', height: '20px' }} preview={false} src={require('../../../assets/img/manage-tag.png')} /> Tags</Button> */}
        </Space>
      </a>
    </Dropdown>
  }

  const removeExperienceTag = (item, tags, id) => {
    let filter = _.filter(tags, (t) => t != item)
    
    updateExperienceTag(filter, id)
  }
  let column = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: '10%',
      render: (text, item) => {
return <div>
  <div style={{textAlign:'center'}}>{text}</div>
  {item?.exp_status==0 && <Tag color='#FEC84B' style={{borderRadius:'16px',textAlign:'center'}}>Paused</Tag>}
  {item?.no_of_available_slots==0 && <Tag color='#FEC84B' style={{borderRadius:'16px',textAlign:'center', marginTop: "4px"}}>Sold out</Tag>}
</div>
      }
    },
    {
      title: 'Session Title',
      key: 'title',
      dataIndex: 'title',
      width: '15%'
    },
    {
      title: 'Date, Time & Location',
      key: 'date_time_location',
      dataIndex: 'date_time_location',
     
        render: (text, item) => {
          
          return _.get(item, 'temp_type') == "open" ?<div className='tool-tip-manage'>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{moment(_.get(item, 'start_date'),'ddd. MMM D, YYYY').format('MMM D, YYYY') }</p>
          {/* <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'experience_start_time')}</p> */}
         
        </div>: <div className='tool-tip-manage'>
            <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'experience_start_date')}</p>
            <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'experience_start_time')}</p>
            {`${_.get(item, 'location')}`.length > 23 ? <Tooltip title={<p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px', width: '140px' }}>{_.get(item, 'location')}</p>} className='tool-tip-manage' color={'#fff'}  >
              <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px', }}>{`${_.get(item, 'location')}`.substring(0, 20)}...</p>
            </Tooltip> : <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'location')}</p>}
  
          </div>
  
      
        
      },
      width: '25%'
    },

    {
      title: 'Session Info',
      key: 'session_info',
      dataIndex: 'session_info',
      render: (text, item) => {
        
        return <div>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'temp_type') == "league" ? "League Session" : _.get(item, 'temp_type') == "open" ? "Open Session" : "Scheduled Session"}</p>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>${_.get(item, 'exp_price')} {_.get(item, 'currency_type')}</p>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'no_of_bookings')}/{_.get(item, 'max_no_of_openings')} Registrations</p>
        </div>

      },
      width: '20%'
    },
    {
      title: 'Quick Actions',
      key: 'action',
      dataIndex: 'action',
      width: '30%',
      render: (text, item) => {
        // /experience-list/4933?template=4929&handle=iyyappan&id=4933
                
        return <div>
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <p onClick={() => editAction(item)} style={{ lineHeight: '20px', fontWeight: 600, fontSize: '14px', color: '#475467', marginBottom: '0px', marginRight: '15px',cursor:'pointer' }}>View</p>
            <p style={{ lineHeight: '20px', fontWeight: 600, fontSize: '14px', color: '#475467', marginBottom: '0px', marginRight: '15px',cursor:'pointer' }} onClick={()=>{
              setIsEmailModalOpen(true)
              showEmailModal(item)
            }}>Message</p>
            <p style={{ lineHeight: '20px', fontWeight: 600, fontSize: '14px', color: '#475467', marginBottom: '0px', marginRight: '15px',cursor:'pointer' }} onClick={()=>{
              setIsURLModalOpen(true);
              setExpId({...item,exp_id:item?.id,
                tempalate_id:item?.tempalate_id,
                handle:props?.userProfile?.handle})
            }}>URL</p>
            <p style={{ lineHeight: '20px', fontWeight: 600, fontSize: '14px', color: '#475467', marginBottom: '0px' ,cursor:'pointer'}}
            onClick={()=>{
              setIsPauseOrCancelModalOpen(true);
              setExpId(item);
              viewExperience(_.get(item, 'id'));
            }}
            >Pause/Cancel</p>
          </div>
          <div>
            <div style={{ display: 'flex', marginTop: '8px' }}>
              <p style={{ lineHeight: '20px', fontWeight: 600, fontSize: '14px', color: '#475467', marginBottom: '0px', marginRight: '15px' }}>Tags:</p>


              {
                _.size(_.get(item, 'tags')) > 1 ?
                  <div className='row' >
                    <Tag id={_.get(item, 'tags[0]')} icon={<Image style={{ width: '12px', height: '12px' }} preview={false} src={require('../../../assets/img/manage-tag.png')} />} style={{
                      backgroundColor: '#F2F4F7', borderRadius: '16px', height: '25px',
                      fontSize: '12px', fontWeight: 500, color: '#344054',
                      marginLeft: '1px'
                    }} closeIcon={<IoMdClose size={16} />} onClose={() => {

                      removeExperienceTag(_.get(item, 'tags[0]'), _.get(item, 'tags'), _.get(item, 'id'),)

                    }}>
                      {_.get(item, 'tags[0]')}
                    </Tag>
                    <Tag id={_.get(item, 'tags[1]')} icon={<Image style={{ width: '12px', height: '12px' }} preview={false} src={require('../../../assets/img/manage-tag.png')} />} style={{
                      backgroundColor: '#F2F4F7', borderRadius: '16px', height: '25px',
                      fontSize: '12px', fontWeight: 500, color: '#344054',
                    }} closeIcon={<IoMdClose size={16} />} onClose={() => {

                      removeExperienceTag(_.get(item, 'tags[0]'), _.get(item, 'tags'), _.get(item, 'id'),)

                    }}>
                      {_.get(item, 'tags[1]')}
                    </Tag>

                  </div> : _.size(_.get(item, 'tags')) > 0 ? <Tag id={_.get(item, 'tags[0]')} icon={<Image style={{ width: '12px', height: '12px' }} preview={false} src={require('../../../assets/img/manage-tag.png')} />} style={{
                    backgroundColor: '#F2F4F7', borderRadius: '16px', height: '25px',
                    fontSize: '12px', fontWeight: 500, color: '#344054',
                  }} closeIcon={<IoMdClose size={16} />} onClose={() => {

                    removeExperienceTag(_.get(item, 'tags[0]'), _.get(item, 'tags'), _.get(item, 'id'),)

                  }}>
                    {_.get(item, 'tags[0]')}
                  </Tag> : renderAddTag(item?.id, item?.tags)
              }
             

            </div>
            <div style={{ display: 'flex', marginTop: '10px' }}>
              {_.size(_.get(item, 'tags')) > 2 &&
                <Popover content={renderToolTipTag(_.get(item, 'tags'), _.get(item, 'id'))} title="" trigger="click">
                  <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '13px', fontStyle: 'italic', color: '#475467', marginBottom: '0px', marginRight: '15px', }}>And <span style={{ textDecorationLine: 'underline', fontWeight: 400, fontSize: '13px', fontStyle: 'italic', color: '#475467' }}>{_.size(_.get(item, 'tags')) - 2} more tags</span></p>
                </Popover>


              }
              {_.size(_.get(item, 'tags')) > 0 && _.size(_.get(item, 'tags')) < _.size(props?.userProfile?.meta_details?.tags) && renderAddTag(item?.id, item?.tags)}
            </div>
          </div>
        </div>

      },
    },

  ]
  const openChat = async (id) => {
    // 
    if (id) {
      const result = await chatKittyInstance.retrieveChannel(id);
      
      if (result.succeeded) {
        const channel = result.channel; // Handle channel
        setchennelData(channel)

        
    }

  };

}


  const showEmailModal = (value) => {
    
    setExpId(value)
    getEmailList(_.get(value, 'exp_id'))
    setIsEmailModalOpen(true);
    openChat(_.get(value, 'chatkitty_channel_id'))
  };

  const handleEmailOk = () => {
    setIsEmailModalOpen(false);
    setSelectOptions([]);
    setLoading(true);
    setErrorMessage('')
    form.setFieldsValue({
      title: '',
      message: ''
    })
  };

  const handleEmailCancel = () => {
    setIsEmailModalOpen(false);
    setSelectOptions([]);
    setLoading(true);
    setErrorMessage('');
    form.setFieldsValue({
      title: '',
      message: ''
    })
  };

  const showWarningModal = (value) => {
    
    setWaningData(value);
    setIsWarninngModalOpen(true);
  };

  const warningHandleOk = () => {
    setIsWarninngModalOpen(false);
    history(`/communiti/schedule?id=${_.get(getWaningData, 'template.id')}&exp_id=${_.get(getWaningData, 'id')}`)
  };

  const warningHandleCancel = () => {
    setIsWarninngModalOpen(false);
  };

  const showModal = (value) => {
    
    setModalDetails(value);

    setIsModalOpen(true);
  };

  const activateShowModal = (value) => {
    
    activateSetModalDetails(value);

    setActivateIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    props.changeStatusOfExperience({
      body: {
        id: _.get(getModalDetails, 'exp_id'),
        status: 0,
        status_glossary: "Deactivated"
      },
      callback: (response, data) => {
        if (response) {
          getLocationsData();
          notification.success({
            message: "Success",
            description: "The session was deactivated successfully"
          })
        }
      }
    })
  };

  const activateExp = () => {
    setActivateIsModalOpen(false);
    props.changeStatusOfExperience({
      body: {
        id: _.get(activateGetModalDetails, 'exp_id'),
        status: 1,
        status_glossary: "Activated"
      },
      callback: (response, data) => {
        if (response) {
          getLocationsData();
          notification.success({
            message: "Success",
            description: "The session was activated successfully"
          })
        }
      }
    })
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleActiveCancel = () => {
    setActivateIsModalOpen(false);
  };

  const rquestProcess = (id) => {
    if (id) {
      setPageLoading(true)
      props.experiencesRequestCancel({
        body: { cancellation_status_glossary: 'Not Interest', id: id },
        callback: (response, data) => {
          setPageLoading(false)
          if (response) {
            loadExperienceList(timeZone)
            notification.success({
              message: "Success",
              description: 'Session has been cancelled successfully.'
            })

          } else {
            if (_.get(data, 'errors[0].rules[0].message')) {
              notification.error({
                message: "Error",
                description: _.get(data, 'errors[0].rules[0].message')
              })

            } else if (_.get(data, 'message')) {
              notification.error({
                message: "Error",
                description: _.get(data, 'message')
              })

            }
          }
        }
      }
      );
    }

  }
  const requestCancel = (id) => {
    confirm({
      icon: null,
      okText: 'Continue',

      cancelText: 'Cancel',
      content: <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

      }}><span style={{ fontSize: '29px', fontWeight: '600' }}>Cancel Session</span><span>Are you sure you want to cancel this session?</span></div>,

      onOk() {
        rquestProcess(id)
      },

      onCancel() {


      },
    });
  }
  const headers = [
    {
      key: 'title',
      name: 'Title',
    },
    {
      key: 'status',
      name: 'Status',
    },
    {
      key: 'start_date',
      name: 'Time',
    },
    {
      key: 'start_time',
      name: 'Start Time',
    },
    {
      key: 'currency_type',
      name: 'Currency',
    },

  ]



  useEffect(() => {
    getLocationsData()
  }, []);

 
  const getLocationsData = async () => {
    const { data, status } = await axios.get('https://ipapi.co/json')
    // setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
    await setTimeZone(_.get(data, 'timezone'))
    loadTemplateList();
    const urlParams = new URLSearchParams(window.location.search);
    const page = parseInt(urlParams.get('page')) || 1;
    const pageSize = parseInt(urlParams.get('pageSize')) || 25;

    setPagination((prev) => ({ ...prev, current: page, pageSize }));
    // fetchData(page, pageSize); actual experience list have to be come
    // if(!_.isEmpty(commonFilterTags)){
      // loadExperienceList(_.get(data, 'timezone'), 1, 100)
    // } 
    // else {
      loadExperienceList(_.get(data, 'timezone'))
    // }
    
  }
  const loadExperienceList = (timezone, pageSize = 25, filter_data_value, search) => {
    
    // 
    var time = moment("12:00 am", ["hh:mm a"]).format("HH:mm");
    const date2 = momentTimezone().tz(timezone).format("YYYY-MM-DD");
    let end_date = momentTimezone(`${date2}T${time}`).tz(timezone).toISOString()
    let params = {
      expand: 'template,template_photos,completed_orders,registration_info_sets',
      is_completed: '0',
      sort: 'created_at.desc',
      host: props.userProfile.id,
      limit: pageSize,
      is_open_for_host: 1,
    };
    if (_.size(commonFilterTags) > 0) {
      params.tags = _.join(commonFilterTags)
    }
    if (!_.isEmpty(search)) {
      params.search = search
      params.search_type = 1
    }


    if (_.get(props, 'userProfile.business_type') == 'coordinator') {
      params.host = _.get(props, 'userProfile.parent_host')
    }
    
    setPageLoading(true)
    props.list({
      params: params,
      callback: async (response, data) => {
        
        if (response) {

          await setTableData(_.get(data, 'data.items', []), timezone)
          setExperiencesListMeta(_.get(data, 'data.meta', {}));
          setPagination((prev) => ({ ...prev, total: _.get(data, 'data.meta.total', {}) }));

        }
        setPageLoading(false)
      }
    }
    );
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({ ...prev, current: page }));
    // fetchData(page, pageSize); the experience load list have to be comes here
    loadExperienceList(timeZone, page, pageSize)
    window.history.pushState({ page, pageSize }, "Page Title", `?page=${page}&pageSize=${pageSize}`);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return 
    }
    if (type === 'next') {
      return 
    }
    return originalElement;
  };

  const cancelationCheck = (value) => {
    if (_.get(value, 'experience_date') && _.get(value, 'experience_start_time') && timeZone) {


      const time = _.get(value, 'experience_start_time', "")
      let dateNow = moment(new Date()).format('YYYY-MM-DD')
      let nowDate = moment(_.get(value, 'experience_date')).tz(timeZone).format("YYYY-MM-DD")
      const date = nowDate
      let todayDate = momentTimezone().tz(timeZone).format('YYYY-MM-DD HH:mm')
      let checkDate = momentTimezone(`${date}T${time}`).tz(timeZone).format('HH:mm')
      checkDate = `${nowDate} ${checkDate}`
      if (moment(todayDate).isSameOrBefore(checkDate)) {
        return true;
      }
      return false;
    } else {
      return false
    }
  }

  const setTableData = async (data, timezone) => {
    let timeZones = Intl.DateTimeFormat().resolvedOptions().timeZone

    let a = []
    _.map(data, (value, index) => {
      // 
      // 
      let calculations = _.get(value, 'no_of_bookings') - parseInt(_.get(value, 'completed_orders'));
      // 
      // 
      // 
      // 
      // 
      a.push({
        key: index,
        id: _.get(value, 'id'),
        title: <div style={{display:"flex", flexDirection:'column', marginTop:"4px"}}>{_.get(value, 'template.type') == 'open' && calculations > 0 && _.get(value, 'status') === 0 ? <div><WarningOutlined className='warning-open-session' onClick={() => showWarningModal(value)} />   {_.get(value, 'template.title')}</div> : _.get(value, 'template.title')}
        {_.get(value, 'meta_details.registration_info_set_id') && <div className='question-set-name-experience'><img style={{width: "10px", height:"10px"}} src={require('../../../assets/img/template/puzzle-piece-02.png')}/> {_.get(value, 'registration_info_sets.label')}</div>}
        </div>,
        template_type: _.startCase(_.get(value, 'template.type')),
        status: _.get(value, 'template.status'),
        start_date: moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('ddd. MMM D, YYYY'), //moment.tz(experience.start_date, user_time_zone).format(); // moment(_.get(value, 'experience_date')).tz(timeZones).format('MMM DD, YYYY')
        is_cancel: cancelationCheck(value),
        start_time: _.get(value, 'template.type') == 'open' ? "null" : _.get(value, 'experience_start_time') ? moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--',  //moment(_.get(value,'experience_start_time')).tz(timeZones).format("hh:mm A")
        tempalate_id: _.get(value, 'template.id'),
        template_name: _.get(value, 'template.title'),
        exp_id: _.get(value, 'id'),
        chatkitty_channel_id: _.get(value, 'chatkitty_channel_id', ''),
        temp_type: _.get(value, 'type'),
        exp_status: _.get(value, 'status'),
        currency_type: _.get(value, 'meta_details.currency'),
        location: _.get(value, 'address'),
        exp_price: _.get(value, 'price'),
        tags: _.get(value, 'tags'),
        no_of_available_slots: _.get(value, 'no_of_available_slots'),
        max_no_of_openings: _.get(value, 'max_no_of_openings'),
        no_of_bookings: _.get(value, 'no_of_bookings'),
        experience_start_date: `${moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')}`, //moment.tz(experience.start_date, user_time_zone).format(); // moment(_.get(value, 'experience_date')).tz(timeZones).format('MMM DD, YYYY'),
        // experience_details : value,
        experience_start_time: _.get(value, 'template.type') == 'open' ? "null" :
          `${_.get(value, 'experience_start_time') ?
            moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm") : '--'}-${_.get(value, 'experience_end_time') ? moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`,

      })
    })

    setExperiencesList(a)

  };

  // list have to be here

  const exportCSVList = () => {
    props.list({
      params: {
        expand: 'template,template_photos,completed_orders,registration_info_sets',
        is_completed: '0',
        sort: 'created_at.desc',
        host: props.userProfile.id,
        limit: 500,
        is_open_for_host: 1,
        is_open_for_host: 1
      },
      callback: (csv_res, csv_data) => {
        
        if(_.size(_.get(csv_data, 'data.items')) > 0) {
          let a = []
          _.map(_.get(csv_data, 'data.items'), (value, index) => {
            a.push({
              key: index,
              id: _.get(value, 'id'),
              template_type: _.startCase(_.get(value, 'template.type')),
              template_name: _.get(value, 'template.title'),
              currency_type: _.get(value, 'meta_details.currency'),
              location: _.get(value, 'address'),
              exp_price: `$${_.get(value, 'price')}`,
              no_of_available_slots: _.get(value, 'no_of_available_slots'),
              no_of_bookings: _.get(value, 'no_of_bookings'),
              experience_start_date: `${moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')}`, //moment.tz(experience.start_date, user_time_zone).format(); // moment(_.get(value, 'experience_date')).tz(timeZones).format('MMM DD, YYYY'),
              // experience_details : value,
              experience_start_time: _.get(value, 'template.type') == 'open' ? "null" :
                `${_.get(value, 'experience_start_time') ?
                  moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm") : '--'}-${_.get(value, 'experience_end_time') ? moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`,
      
            })
          })
          setCsvItems(a);
        }
        
      }
    })
  }

  const getStartTime = (time, timezone) => {

    let date = momentTimezone().tz(timezone).format("YYYY-MM-DD");

    let start_time = momentTimezone(`${date}T${time}`).tz(timezone).format('hh:mm A')

    return start_time
  }

  const loadTemplateList = () => {
    setPageLoading(true)
    let params = {
      approved_status: 1,
      expand: 'photos',
      sort: 'created_at.desc',
      limit: 1000,
      status: 1

    }
    if (_.get(props, 'userProfile.business_type') == 'coordinator') {
      params.host = _.get(props, 'userProfile.parent_host')
    }
    props.templateList({
      params: params,
      callback: (res, data) => {

        if (res == true && _.get(data, 'data.items[0]')) {
          setAllTemplateListData(_.get(data, 'data.items'))
          setAllTemplateListMeta(_.get(data, 'data.meta'))
        }
        setPageLoading(false)
      }
    })
  }


  const onContinue = () => {
    if (scheduleSelectId) {
      history(`/communiti/schedule?id=${scheduleSelectId}`)
    }
  }


  const handleScroll = (data, type) => {
    if (pageLoading == false && data && data.top > 0.9 && _.get(experiencesListMeta, 'total') > 25) {
      if (_.size(experiencesList) < _.get(experiencesListMeta, 'total')) {
        loadExperienceList(timeZone, _.get(experiencesListMeta, 'limit') + 25, dateFilterValue, getSearchByFilter)
      }


    }

  }

  const sortByDate = (value) => {
    
    let timezone = "America/Toronto";
    let pageSize = 10;
    let sorting = `experience_date.${value}`
    setDateFilterValue(sorting);
    loadExperienceList(timezone, pageSize, sorting)


  };

  const searchByTitleFilter = (e) => {
    
    let timezone = "America/Toronto";
    let pageSize = 25;
    let sorting = dateFilterValue
    let search = _.lowerCase(e.target.value)
    setSearchByFilter(search)
    loadExperienceList(timezone, pageSize, sorting, search)
  };

  const onFinish = (values) => {
    
    const ids = _.get(values, 'emails')
    const commaSeparatedString = ids.join(',');
    props.sendEmailAPI({
      body: {
        experience: _.get(getExpId, 'exp_id'),
        subject: values.title,
        message: values.message,
        guest_ids: commaSeparatedString
      },
      callback: (res, val) => {
        
        if (_.get(val, 'success')) {
          notification.success({
            message: "Success",
            description: "Email has been sent to all registrants in this session"
          })
          setIsEmailModalOpen(false);
          form.setFieldsValue({
            title: '',
            message: ''
          })
        }
      }
    })
  };
  const onFinishFailed = (errorInfo) => {
    
  };

  const getEmailList = (id) => {
    props.orderList({
      params: { experience: id, user_type: '1', expand: 'guest,experience,experience_template,profile', paid: 1, is_completed: 0, limit: 300 },
      callback: (res, data) => {
        
        if (res) {
          setLoading(false)
          if (_.isEmpty(_.get(data, 'data.items'))) {
            setErrorMessage("No registrants in this Sesson")
          }
          if (_.size(_.get(data, 'data.items')) > 0) {
            const uniqueGuests = {}; // To keep track of unique guest IDs and emails

            const selectOptions = _.get(data, 'data.items').reduce((options, item) => {
              
              const guestId = item.guest.id;
              const guestEmail = item.guest.contact_details.email;

              // Check if the guest ID is not already in uniqueGuests
              if (!uniqueGuests[guestId]) {
                uniqueGuests[guestId] = guestEmail;
                options.push({ value: guestId, label: guestEmail });
              }

              return options;
            }, []);
            
            setSelectOptions(selectOptions);
            form.setFieldsValue({
              emails: selectOptions.map(option => option.value),
            })
            // selectOptions.map(option => option.value)
          }

        }
      }
    })
  }


const modalCommonTable =(item,title,des)=>{
  let data_source = [item]
  
  let column = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: '10%',
      render: (text, item) => {
        return <div>
          <div>{text}</div>
          {item?.exp_status==0 && <Tag color='#FEC84B' style={{borderRadius:'16px',textAlign:'center',marginLeft:'-11px'}}>Paused</Tag>}
          {item?.no_of_available_slots==0 && <Tag color='#FEC84B' style={{borderRadius:'16px',textAlign:'center',marginLeft:'-11px', marginTop: "4px"}}>Sold out</Tag>}
        </div>
              }
    },
    {
      title: 'Session Title',
      key: 'title',
      dataIndex: 'title',
      width: '15%'
    },
    {
      title: 'Date, Time & Location',
      key: 'date_time_location',
      dataIndex: 'date_time_location',
      render: (text, item) => {
        // 
        return _.get(item, 'temp_type') == "open" ?<div className='tool-tip-manage'>
         <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{moment(_.get(item, 'start_date'),'ddd. MMM D, YYYY').format('MMM D, YYYY') }</p>
        {/* <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'experience_start_time')}</p> */}
       
      </div>: <div className='tool-tip-manage'>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'experience_start_date')}</p>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'experience_start_time')}</p>
          {`${_.get(item, 'location')}`.length > 23 ? <Tooltip title={<p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px', width: '140px' }}>{_.get(item, 'location')}</p>} className='tool-tip-manage' color={'#fff'}  >
            <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px', }}>{`${_.get(item, 'location')}`.substring(0, 20)}...</p>
          </Tooltip> : <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'location')}</p>}

        </div>

      },
      width: '25%'
    },

    {
      title: 'Session Info',
      key: 'session_info',
      dataIndex: 'session_info',
      render: (text, item) => {
        // 
        return <div>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'temp_type') == "league" ? "League Session" : _.get(item, 'temp_type') == "open" ? "Open Session" : "Scheduled Session"}</p>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>${_.get(item, 'exp_price')} {_.get(item, 'currency_type')}</p>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'no_of_bookings')}/{_.get(item, 'max_no_of_openings')} Registrations</p>
        </div>

      },
      width: '20%'
    },
   
  

  ]
  return <>
  <p style={{color:'#101828',fontWeight:600,fontSize:'18px',marginBottom:'0px'}}>{title}</p>
  <p style={{color:'#475467',fontWeight:400,fontSize:'16px'}}>{des}</p>
               <div style={{ backgroundColor: '#fff' }} className='manage-active-table'>
                <Table sticky className='manage-active-table'  style={{ backgroundColor: '#fff' }} columns={column} dataSource={data_source} pagination={false} />
              </div></>
}
const onCopyURL = (value) => {
  
  navigator.clipboard.writeText(value)
  .then(() => {
    
    messageApi.open({
      type: 'success',
      content: 'Copied',
    });
  })
  .catch(err => {
    console.error('Error copying value:', err);
  });
}

const active_headers = [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'Session Title',
      key: 'template_name',
  },
  {
    name: 'Start Date & End Date',
      key: 'experience_start_date',
  },
  {
    name: 'Start Time & End Time',
      key: 'experience_start_time',
  },
  {
    name: 'Location',
      key: 'location',
  },
  {
    name: 'Session Type',
      key: 'template_type',
  },
  {
    name: 'No. of slots available',
      key: 'no_of_available_slots',
  },
  {
    name: 'No. of slots booked',
      key: 'no_of_bookings',
  },
  {
    name: 'Session Price',
      key: 'exp_price',
  },

  {
    name: 'Currency Type',
      key: 'currency_type',
  },
  
];

const showPauseModal = () => {
  setIsPauseModalOpen(true);
};

const viewExperience = (id) => {
  props.experienceViewAPI({
    params:{
      id: id
    },
    callback: (view_res, view_data) => {
      
      if(view_data){
        setExperienceViewData(_.get(view_data, 'data.details'));
      }
    }
  })
}

const handlePauseOk = (value) => {
  
    props.changeStatusOfExperience({
      body: {
        id: _.get(getExpId, 'id'),
        status: 0,
        status_glossary: "Paused"
      },
      callback: (response, data) => {
        if (response) {
          setIsPauseModalOpen(false);
          getLocationsData();
          setExpId({...getExpId,exp_status:0})
          viewExperience(_.get(getExpId, 'id'));
          notification.success({
            message: "Session paused",
            description: "You have successfully paused this session."
          })
        }
      }
    })
  
  
};
const handlePauseCancel = () => {
  setIsPauseModalOpen(false);
};

const showUnPauseModal = () => {
  setUnIsPauseModalOpen(true);
};

const handleUnPauseOk = () => {
    props.changeStatusOfExperience({
      body: {
        id: _.get(getExpId, 'id'),
        status: 1,
        status_glossary: "Unpaused"
      },
      callback: (response, data) => {
        if (response) {
          setUnIsPauseModalOpen(false);
          getLocationsData();
          setExpId({...getExpId,exp_status:1})
          viewExperience(_.get(getExpId, 'id'));
          notification.success({
            message: "Session resumed",
            description: "You have successfully resumed this session."
          })
        }
      }
    })
  
  
};
const handleUnPauseCancel = () => {
  setUnIsPauseModalOpen(false);
};

const showCancelModal = () => {
  setIsCancelModalOpen(true)
}

const handleOkCancelModal = () => {
  props.experiencesRequestCancel({
    body: { cancellation_status_glossary: 'Not Interest', id: _.get(getExpId, 'id') },
    callback: (response, data) => {
      if (response) {
        setExpId();
        getLocationsData();
        // setExperienceViewData(_.get(data, 'data.details'));
        setIsCancelModalOpen(false);
        notification.success({
          message: "Session cancelled ",
          description: 'You have sucessfully cancelled this session. All registrants have been refunded. If you cancelled this session before the start date the Communiti fee will also be refunded.'
        })
      }
    }
  }
  );
};

const handleCloseCancelModal = () => {
  setIsCancelModalOpen(false)
};

const editAction = (value) => {
  
  history(`/edit-experience?id=${_.get(value, 'id')}&menu=manage&type=${_.get(value, 'temp_type')}`);
}



  const render = () => {

    return (selectedTab=="Active"?
      <div style={{ width: '100%', marginBottom: "32px" }}>
        {contextHolder}
        <Card
          size="small"
          title={
            <div className='header-class-card' style={{ display: 'flex',justifyContent:'space-between' }}>
              <div>
              <Radio.Group defaultValue="Active" buttonStyle="solid"
                // style={{paddingTop:'10px',paddingBottom:'10px'}}

                onChange={(e) => {
                  setselectedTab(e.target.value)
                  
                }}
              >
                <Radio.Button style={{
                  paddingTop: '6px', paddingBottom: '7px',
                  height: '44px'
                }} value="Active" >Active</Radio.Button>
                <Radio.Button style={{
                  paddingTop: '6px', paddingBottom: '7px',
                  height: '44px'
                }} value="Cancelled">Cancelled</Radio.Button>
                <Radio.Button style={{
                  paddingTop: '6px', paddingBottom: '7px',
                  height: '44px'
                }}

                  value="Past">Past</Radio.Button>
              </Radio.Group>
              </div>
<div style={{display:'flex'}}>
              <div style={{marginRight:'12px'}}>
                <Input onChange={(e) => searchByTitleFilter(e)} placeholder="Search" prefix={<FiSearch size={20} color='#667085' />} style={{ paddingTop: '10px', paddingBottom: '10px', paddingLeft: '14px', paddingRight: '14px' }} />
              </div>
              <div style={{marginRight:'12px'}}>

                <Dropdown
                  className='drop-tag-menu'
                  open={isCommonTagOpen}

                  menu={{
                    items: commonItems,
                    onSelect: (value) => {
                      

                    },
                    onClick: (e) => {
                      

                    },

                    onDeselect: (e) => {
                      
                    },
                    onChange: (e) => {
                      
                    }
                  }}
                  trigger={['click']}



                  openClassName='drop-tag-menu'
                  overlayClassName='drop-tag-menu'
                  rootClassName='drop-tag-menu'
                >
                  <a >
                    <Space>
                      <Button onClick={() => setisCommonTagOpen(true)} className='tag-image' style={{ height: '44px' }}><Image style={{ width: '20px', height: '20px' }} preview={false} src={require('../../../assets/img/manage-tag.png')} /> Tags</Button>
                    </Space>
                  </a>
                </Dropdown>
              </div>
              <div >
                <ExportJsonCsv fileTitle="Active CSV Data" style={{borderRadius: "6px", backgroundColor: "#fff", borderColor: "#D0D5DD", cursor: "pointer", height: "44px", 
                  border: '1px solid #d9d9d9'
              }} headers={active_headers} items={csvItems}><DownloadOutlined /> Export to .csv</ExportJsonCsv>
              </div>
              </div>
            </div>
          }
          //       extra={<a href="#">More</a>}
          style={{
            width: '100%',
            padding: '0px'
          }}
          className='manage-active-table'
        >
          <div className="coach-full-conatiner coach-full-conatiner-active">

            <Scrollbars 
            className="hidden-scroll-x"
            onScrollFrame={(e) => handleScroll(e)}
            style={{ height: 'calc(100vh - 125px)' }}
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            >
              <div style={{ backgroundColor: '#fff' }} className='manage-active-table'>
                <Table sticky className='manage-active-table' loading={pageLoading} style={{ backgroundColor: '#fff' }} columns={column} dataSource={experiencesList} pagination={false} />
              </div>
            </Scrollbars>
            <div>
                <Modal centered={true}
                className='appropriateTemplateModal'
                title={_.get(getModalDetails, 'title')}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                  <Button style={{ borderRadius: "20px", backgroundColor: "#e85f25", color: "#fff" }} key="back" onClick={handleCancel} >Cancel</Button>,
                  <Button style={{ borderRadius: "20px", backgroundColor: "#e85f25" }} key="submit" type="primary" onClick={handleOk}>
                    Deactivate this session
                  </Button>
                ]}
              >
                <p>You are deactivating the listing “{_.get(getModalDetails, 'title')}”. Once deactivated, users will no longer be able to see or purchase this session on the Communiti mobile app.</p>
                <p>If you have registrations that have not yet been marked as complete, it will remain in you Active Schedule tab until you fulfill those sessions and mark them as complete.</p>
              </Modal>
            </div>

            <div>
                <Modal centered={true}
                className='appropriateTemplateModal'
                title={_.get(activateGetModalDetails, 'title')}
                open={activateIsModalOpen}
                onOk={activateExp}
                onCancel={handleActiveCancel}
                footer={[
                  <Button style={{ borderRadius: "20px", backgroundColor: "#e85f25", color: "#fff" }} key="back" onClick={handleActiveCancel} >Cancel</Button>,
                  <Button style={{ borderRadius: "20px", backgroundColor: "#e85f25" }} key="submit" type="primary" onClick={activateExp}>
                    Reactivate
                  </Button>
                ]}
              >
                <p>You are reactivating the listing “{_.get(activateGetModalDetails, 'title')}”. Once reactivated, users will be to see and purchase this session if there are available registration slots.</p>

              </Modal>
            </div>

            <div>
                <Modal centered={true} title="You still have registrations that have not been fulfilled / marked as complete"
                open={isWarninngModalOpen}
                onOk={warningHandleOk}
                onCancel={warningHandleCancel}
                className='appropriateTemplateModal'
                footer={[
                  <Button style={{ borderRadius: "20px", backgroundColor: "#e85f25", color: "#fff" }} key="back" onClick={warningHandleCancel} >Cancel</Button>,
                  <Button style={{ borderRadius: "20px", backgroundColor: "#e85f25" }} key="submit" type="primary" onClick={warningHandleOk}>
                    View Registration
                  </Button>
                ]}

              >
                <p>This session is still in your active tab as you still have registrations that need to be fulfilled / marked as complete. Please fulfill and mark these sessions as complete.</p>
              </Modal>
            </div>


            <div>
                <Modal centered={true} className='common-modal-ctn' title="" footer={null} open={isUrlModalOpen}  onCancel={()=>{
                setIsURLModalOpen(false)
              }}>
               {modalCommonTable(getExpId,'URL',"You are viewing the Communiti WebApp URL for the following session.")}
               <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '12px',marginTop:'12px' }}>Url</p>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '12px' }}>The following is the URL for this specific session. You can share this URL with your customers if you want to direct them to this specific session. </p>
          <Search
        style={{width: "85%"}}
        readOnly
        enterButton={<AiOutlineCopy/>}
        
        
        value={`https://book.communiti.app/experience-list/${getExpId?.exp_id}?template=${getExpId?.tempalate_id}&handle=${getExpId?.handle}&id=${getExpId?.exp_id}`}
        defaultValue={`https://book.communiti.app/experience-list/${getExpId?.exp_id}?template=${getExpId?.tempalate_id}&handle=${getExpId?.handle}&id=${getExpId?.exp_id}}`}
        onSearch={onCopyURL}
      />
              </Modal>
            </div>
            <div>
                <Modal centered={true} className='common-modal-ctn' title="" footer={null} open={isPauseOrCancelModalOpen}  onCancel={()=>{
                setIsPauseOrCancelModalOpen(false);setExperienceViewData({})
              }}>
               {modalCommonTable(getExpId,'Pause / Cancel',"You are managing the pause / cancellation status of the following session:")}
              <div>
                <div className='pause-title'>Pause</div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <div className='pause-description'>
                Pausing a session temporarily hides the session from the Communiti Mobile and Web App so<br/> that users cannot see or register for a session. 
                </div>
                <div>
                  {
                   !_.isEmpty(experienceViewData) && _.get(experienceViewData, 'status') === 1 ? <Button onClick={() => showPauseModal()}>Pause Session</Button> : <Button style={{backgroundColor: "#FEC84B", color: "#FFFFFF"}} onClick={() => showUnPauseModal()}>Resume Session</Button>
                  }
                
                </div>
                
                </div>
                <div className='pause-bld-description'>It does not impact anyone who has already registered for this session.</div>
              </div>
                <hr/>
              <div>
                <div className='pause-title'>Cancel</div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <div className='pause-description'>
                Cancellation a session removes a session from the Communiti Mobile and WebApp so that<br/> users cannot see or register for a session.
                </div>
                
                <Button 
                disabled={ !_.isEmpty(experienceViewData) &&_.get(experienceViewData, 'status') === 1 ? true : false}
                onClick={() => showCancelModal()}
                >
                  Cancel
                  </Button>
                 
                </div>
                <div className='pause-bld-description'>Cancellation will automatically refund everyone who has registered for this session. Note:<br/> Communiti fee will only be refunded if a session is cancelled before the start date.</div>
              </div>
              </Modal>
            </div>
            <div>
                <Modal centered={true} className='common-modal-ctn' title="" footer={null} open={isEmailModalOpen} onOk={handleEmailOk} onCancel={handleEmailCancel}>
               {modalCommonTable(getExpId,'Message','You are sending a message / chatting with registrants from the following session:')}
               <Tabs
               className='tab-email-ctn'
               style={{marginTop:'20px'}}
        defaultActiveKey="1"
        type="card"
        size={'middle'}
        items={[
          {
            key: '1',
            label: <div style={{color:'#fff',fontSize:'14px',fontWeight:600}} className='title-tab-email'>Email</div>,
            children: 
            <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            layout='vertical'
          >
            {/* <div style={{ marginBottom: "10px" }}>{_.get(getExpId, 'template_name')}</div> */}
            <Form.Item
              label="Email(s)"
              name="emails"
              rules={[
                {
                  required: true,
                  message: 'Please input atleast one email!',
                },
              ]}
            >
             
              {
                loading ? <Skeleton active paragraph={{ rows: 1 }} /> : errorMessage ? errorMessage : <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  
                  placeholder="Select guests"
                >
                  {getSelectOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              }

            </Form.Item>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please input your title!',
                },
              ]}
            >
              <Input disabled={errorMessage ? true : false} />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: 'Please input your message!',
                },
              ]}
            >
              
              <ReactQuill style={{height:'100px'}} theme="snow" />
            </Form.Item>
            <Form.Item
            >
              <Button disabled={errorMessage ? true : false} style={{ width: "100%", backgroundColor: "#e85d04", color: "#fff", marginTop: "50px" }} type="primary" htmlType="submit">
                Send
              </Button>
            </Form.Item>
          </Form>,
          },
          {
            key: '2',
            label: <div style={{color:'#fff',fontSize:'14px',fontWeight:600}} className='title-tab-email'>Chat</div>,
            children: chennelData !='' ?<Chat channelData={chennelData} />:null,
          },
        ]}
      />
               
                {/*  */}
              </Modal>
            </div>
            <div>
              <Modal centered={true} 
            title="Are you sure you wish to pause this session?" 
            open={isPauseModalOpen} 
            onCancel={handlePauseCancel}
            footer={[
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
                  <Button onClick={() => handlePauseOk()}>Pause Session</Button>
              </div>
              
            ]}
            >
              <p className='pause-description'>Pausing this session will mean users will not be able to register or see this session on the Communiti Mobile or WebApp.</p>
             
           </Modal>
            </div>
            <div>
              <Modal centered={true} 
            title="Are you sure you wish to resume this session?" 
            open={isUnPauseModalOpen} 
            onCancel={handleUnPauseCancel}
            footer={[
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
                  <Button onClick={() => handleUnPauseOk()}>Resume Session</Button>
              </div>
              
            ]}
            >
              <p className='pause-description'>Resuming this session will mean users will be able to see and register for this session on the Communiti Mobile or WebApp.</p>
             
           </Modal>
            </div>
            {/* This is cancel modal */}
            <div>
              <Modal centered={true} 
            title="Are you sure you wish to cancel this session?" 
            open={isCancelModalOpen} 
            onCancel={handleCloseCancelModal}
            footer={[
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
                  <Button onClick={() => handleOkCancelModal()}>Yes, Cancel Session</Button>
              </div>
              
            ]}
            >
              <p className='pause-description'>Cancelling this session will remove this from the Communiti Mobile and WebApp so that users cannot see or register for a session.</p>
              <p className='pause-description' style={{marginTop: "12px"}}>Cancelling will also automatically refund everyone who has registered for this session. The Communiti fee will only be refunded if a session is cancelled before the start date.</p>
              <p className='pause-bld-description'>This action cannot be undone. Are you sure you wish to proceed?</p>
             
           </Modal>
            </div>
            
          </div></Card>
          {/* <div style={{ display:"flex", justifyContent: "space-between", marginTop: '16px', marginBottom: "10px"}}>
        <Button
          onClick={() => handlePageChange(pagination.current - 1, pagination.pageSize)}
          disabled={pagination.current === 1}
        >
        <FaArrowLeftLong/>  Previous
        </Button>
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          itemRender={itemRender}
          onChange={handlePageChange}
          onShowSizeChange={handlePageChange}
        />
        <Button
          onClick={() => handlePageChange(pagination.current + 1, pagination.pageSize)}
          disabled={pagination.current * pagination.pageSize >= pagination.total}
        >
          Next <FaArrowRightLong />
        </Button>
      </div> */}
          </div>:selectedTab=="Cancelled"?<Cancel  selectedTab={selectedTab} onChangeTab={(e)=>setselectedTab(e)} />:<Past selectedTab={selectedTab} onChangeTab={(e)=>setselectedTab(e)} />)
  }

  return render()
}
const mapStateToProps = state => {

  return {
    checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
    isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
    islogindata: _.get(state, 'app.account.isLogin'),
    userProfile: _.get(state, 'app.host.profile.data.details'),

  }
}

const mapDispatchToProps = dispatch => ({
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  templateList: details => dispatch(global.redux.action.template.list(details)),
  list: details => dispatch(global.redux.action.experience.list(details)),
  experienceViewAPI: details => dispatch(global.redux.action.experience.view(details)),
  updateExperienceTag: details => dispatch(global.redux.action.experience.updateExperienceTag(details)),
  experiencesRequestCancel: details => dispatch(global.redux.action.experience.requestCancel(details)),
  changeStatusOfExperience: details => dispatch(global.redux.action.experience.changeStatus(details)),
  orderList: details => dispatch(global.redux.action.experience.orderList(details)),
  sendEmailAPI: details => dispatch(global.redux.action.sendEmail.postEmails(details)),
  updateHostTag: details => dispatch(global.redux.action.host.updateHostTag(details)),
  hostProfile: details => dispatch(global.redux.action.host.profile(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateList)