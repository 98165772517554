import React, {useState, useEffect} from 'react';
import { Form, Input, Button, message, notification } from "antd";
import { connect } from 'react-redux';
import _ from 'lodash';
import {AiOutlineCopy} from 'react-icons/ai';
import { useNavigate, useSearchParams } from 'react-router-dom';

import "./style.css";

const { Search } = Input;

const Profile = (props) => {
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedCard, setSelectedCard] = useState('club');
    const [hostData, setHostData] = useState({});
    const [user_handle, set_userHandle] = useState();
    const [isUpdateHandle, setIsUpdateHandle] = useState(false)

    useEffect(() => {
      profileData();
    }, []);

    const profileData = () => {
      props.hostProfileData({
        callback: (res, data) => {
          
          if(!_.isEmpty(_.get(data, 'data.details'))){
            setHostData(_.get(data, 'data.details'));
            setSelectedCard(_.get(data, 'data.details.business_type'))
            set_userHandle(_.get(data, 'data.details.handle'))
            form.setFieldsValue({
              display_name: _.startCase(_.get(data, 'data.details.display_name')),
              bio: _.get(data, 'data.details.bio'),
              refund_policy: _.get(data, 'data.details.meta_details.refund_policy'),
              handle: _.get(data, 'data.details.handle'),
            })
        }
        }
      })
    }
 
    const handleCardClick = (card) => {
      
        setSelectedCard(card);
      };
     

      const onCopyURL = (value) => {
        
        navigator.clipboard.writeText(value)
        .then(() => {
          
          messageApi.open({
            type: 'success',
            content: 'Copied',
          });
        })
        .catch(err => {
          console.error('Error copying value:', err);
        });
      }

      const changeHandle = (value) => {
        const regex =/[^a-zA-Z0-9]/g
 // Replace special characters with an empty string
        const sanitizedValue = value.replace(regex, '');
       
          set_userHandle(sanitizedValue)
          form.setFieldsValue({
            
            handle: sanitizedValue,
          })
            }

        const chackUserHandleAvailablity = () => {
            props.checkUserHandleAvailablityAPI({
              params:{
                handle: user_handle
              },
              callback: (response, data) => {
                
                if(!_.get(data, 'data.availability')){
                  setIsUpdateHandle(false)
                  messageApi.open({
                    type: "error",
                    content: 'Handle already taken',
                  })
                }
                if(_.get(data, 'data.availability')){
                  setIsUpdateHandle(true)
                  messageApi.open({
                    type: "success",
                    content: 'Handle available',
                  })
                }
              }
            })
        };

        const onProfileFinish = (values) => {
          
          props.hostUpdateProfile({
            body:{
              business_type: selectedCard,
              display_name: _.get(values, 'display_name'),
              bio: _.get(values, 'bio'),
            },
            callback: (res, data) => {
              
              if(_.get(data, 'success')){
                notification.success({
                  message: "Success",
                  description: "Profile details updated successfully"
                })
                profileData();
              }
              
            }
          });
          props.hostMetaDetailsUpdateAPI({
            body:
              {
                "key": "refund_policy",
                "value": _.get(values, 'refund_policy')
            }
            ,
            callback: (res, data) => {
              
              if(_.get(data, 'success')){
                profileData();
              }
              
            }
          });

          if(isUpdateHandle){
            props.updateUserHandleAPI({
              body:{
                handle: user_handle
              },
              callback : (updatedResponse, updatedValue) =>{
                
                if(updatedResponse){
                  profileData();
                  setIsUpdateHandle(false);
                }
              }
            })
          }
        };
        const onFinishProfileFailed = (errorInfo) => {
          
        };
const checkNumber =(user_handle)=>{
  let number  = parseFloat(user_handle)
  var containsNumber = /\d/.test(user_handle);
    var containsString = /[a-zA-Z]/.test(user_handle);

    
  return containsString && containsNumber==false?true: containsNumber && containsString;
}
        
  return (
        <div>
          {contextHolder}
                    <Form 
                        layout='vertical'
                        form={form}
                        onFinish={onProfileFinish}
                        onFinishFailed={onFinishProfileFailed}
                        >
                        <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Business Type</div>
                          <div className='container-titles-desc'>Select your business type.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                          <div
                                className={`card ${selectedCard === 'club' ? 'cardselected' : ''}`}
                                style={{ borderRadius: '20px', marginBottom: '10px' }}
                                // onClick={() => handleCardClick('club')}
                            >
                                <div className='card-body'>
                                <p className={`card-title ${selectedCard === 'club' ? 'titleselected' : ''}`}>Club</p>
                                <p className={`card-text ${selectedCard === 'club' ? 'textselected' : ''}`}>
                                    I am a Club who offers programs and lessons to athletes. I may employ multiple Coaches and staff.
                                </p>
                                </div>
                            </div>

                            <div
                                className={`card ${selectedCard === 'coach' ? 'cardselected' : ''}`}
                                style={{ borderRadius: '20px' }}
                                // onClick={() => handleCardClick('coach')}
                            >
                                <div className='card-body'>
                                <p className={`card-title ${selectedCard === 'coach' ? 'titleselected' : ''}`}>Coach</p>
                                <p className={`card-text ${selectedCard === 'coach' ? 'textselected' : ''}`}>
                                    I am a Coach who offers programs and lessons to athletes. I do not employ other staff and work individually.
                                </p>
                                </div>
                            </div>
                          </div>
                          </div>
                          <hr/>
                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Display Name</div>
                          <div className='container-titles-desc'>Enter the display name you want users to see on the Communiti Mobile & WebApp.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item 
                            label="Display Name"
                            name="display_name" 
                            >
                            <Input style={{ width: "100%", height: "40px"}}/>
                            </Form.Item>
                          </div>
                          </div>
                          <hr/>
                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Handle</div>
                          <div className='container-titles-desc'>Enter the handle you want to use for your listings. They must:</div>
                          <div style={{marginTop:'8px'}}>
                          <ul class="a">
                <li className='handle-support-text'>be made up of alphanumeric characters (a–z, 0–9)</li>
                <li className='handle-support-text'>be made up of lowercase characters</li>
                <li className='handle-support-text'>not be URL-like or phone number-like</li>
            </ul>
                          </div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column"}}>
                              <Form.Item
                              name="handle"
                            className='handle-form-input-copy'
                              > <Input
                              className='handle-input-container'
                                value={user_handle}
                              style={{width: "100%", height: "44px"}}
                              onChange={(e) => changeHandle(e.target.value)}
                              addonBefore="https://book.communiti.app/"
                              addonAfter={
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => onCopyURL(`https://book.communiti.app/${_.get(hostData, 'handle')}`)}>
                                <path d="M10.5 2.0028C9.82495 2.01194 9.4197 2.05103 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8.05103 3.4197 8.01194 3.82495 8.0028 4.5M19.5 2.0028C20.1751 2.01194 20.5803 2.05103 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C21.949 3.4197 21.9881 3.82494 21.9972 4.49999M21.9972 13.5C21.9881 14.175 21.949 14.5803 21.782 14.908C21.5903 15.2843 21.2843 15.5903 20.908 15.782C20.5803 15.949 20.1751 15.9881 19.5 15.9972M22 7.99999V9.99999M14.0001 2H16M5.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V11.2C16 10.0799 16 9.51984 15.782 9.09202C15.5903 8.71569 15.2843 8.40973 14.908 8.21799C14.4802 8 13.9201 8 12.8 8H5.2C4.0799 8 3.51984 8 3.09202 8.21799C2.71569 8.40973 2.40973 8.71569 2.21799 9.09202C2 9.51984 2 10.0799 2 11.2V18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                                  // <AiOutlineCopy onClick={() => onCopyURL(`https://devbooking.communitiapi.com/${_.get(hostData, 'handle')}`)} />
                                
                              }
                             
                            />
                            </Form.Item>
                           
                            <Form.Item>
                            <Button 
                            className='handle-copy-button-container'
                            style={{height:'44px'}}
                            disabled={checkNumber(user_handle)==true?false:true}
                             onClick={() => chackUserHandleAvailablity()}>Confirm Availablity</Button>
                            </Form.Item>
                            
                          </div>
                          </div>
                          <hr/>
                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>About</div>
                          <div className='container-titles-desc'>Enter information about your club or yourself. Users will be able to see this on the Communiti Mobile & WebApp.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item 
                            label="About"
                            name="bio" 
                            >
                            <Input.TextArea rows={8}/>
                            </Form.Item>
                          </div>
                          </div>
                          <hr/>
                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Refund Policy</div>
                          <div className='container-titles-desc'>Enter information about your refund policy here. Users will be able to see this on the Communiti Mobile & WebApp.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item 
                            label="Refund Policy"
                            name="refund_policy" 
                            >
                            <Input.TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item>
                              <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end"}}>
                              <Button style={{marginRight: "15px"}}>Cancel</Button>
                              <Button htmlType="submit" style={{backgroundColor: "#EF6820", color: "#fff"}}>Save changes</Button>
                              </div>
                            </Form.Item>
                          </div>
                          </div>
                        </Form>
        </div>
  )
}

const mapStateToProps = state => {
  return {
     
  }
}

const mapDispatchToProps = dispatch => ({
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
  checkUserHandleAvailablityAPI: details => dispatch(global.redux.action.userHandle.checkUserHandleAvailablity(details)),
  updateUserHandleAPI: details => dispatch(global.redux.action.userHandle.createOrUpdateUserHandle(details)),
  hostMetaDetailsUpdateAPI: details => dispatch(global.redux.action.host.metaDetailsUpdate(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile)