import React from 'react';
import { Image } from "antd";

import "./header.css";

const LoginHeader = () => {
  return (
    <div>
         <div className="PageHeader">
                <div className="PageHeaderLogoContainer">
                    <div className="container" style={{maxWidth:'100%'}}>
                        <div className="PageHeaderLogoContainerInner">
                            <div className="PageHeaderLogoContainerInnerLeft">
                                <div className="LogoSet">
                                   <div style={{display:'flex', flexDirection:'row', cursor: "pointer"}}>
                                    <Image height={35} width={35} preview={false} src={require('../../assets/img/push-and-app-icon.png')}/>
                                   <div style={{  marginLeft:'2px',  marginTop: '4px',fontSize: '19px',color: '#e85f2f'}}>communiti</div>
                                   </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default LoginHeader