import loginPage from '../../containers/login/login';
import CoachInfoLanding from '../../containers/coach-information-application/info-landing-page';
import CoachInfoApplication from '../../containers/coach-information-application';
import ApplicationSuccess from '../../containers/application-success';
import Onboarding from '../../containers/onboarding-screen';
import TermsService from '../../containers/onboarding';
import Profile from '../../containers/profile';
import coachOnboardLanding from '../../containers/onboarding-screen/onboard-landing-page';
import coachOnboardComplete from '../../containers/onboarding-screen/onboarding-complete';
import TemplateCreate from '../../containers/template/create-edit';
import TemplateList from '../../containers/template/list';
import TemplateView from '../../containers/template/view';
import Manage from '../../containers/new-manage/';
// import Manage from '../../containers/manage';
import Schedule from '../../containers/manage/session/schedule';

import FinancesList from '../../containers/finances/list';
import RatingReviews from '../../containers/reviews/reviews-list';
import ChatsAndNotification from '../../containers/chats/index';
import MainMenu from '../../containers/main-menu';
import OpenSessionCreate from '../../containers/template/open-session-create';
import TestSocket from '../../containers/test-socket';

import IntegratedTab from '../../containers/manage/session/integratedTabs';
import FacilitateSession from '../../containers/manage/session/facilitateSession';
import IndividualOnsiteFacilitation from '../../containers/manage/session/individualOnsiteFacilitation';
import coordinatorErrorMessage from '../../containers/coordinator-message';
import TemplatesTab from '../../containers/template/template-tabs';
import NotFoundPage from '../../containers/userHandleView/notFoundPage';
import CustomersList from '../../containers/customers';
import ViewProfile from '../../containers/customers/view-profile';
import ViewAccount from '../../containers/customers/view-account';
import Menu from '../../containers/menu';
import HostProfile from '../../containers/upgraded-profile';
import EditExperiences from '../../containers/edit-experience/edit-experiences';
import TemplateFlow from '../../containers/new-template';
import ViewArchivedTemplate from '../../containers/template/template-tabs/view-archived-template';
export default {
    loginPage,
    TermsService,
    CoachInfoLanding,
    CoachInfoApplication,
    ApplicationSuccess,
    coachOnboardLanding,
    Onboarding,
    coachOnboardComplete,
    Profile,
    TemplateCreate,
    TemplateList,
    TemplateView,
    Manage,
    Schedule,
    FinancesList,
    RatingReviews,
    ChatsAndNotification,
    MainMenu,
    OpenSessionCreate,
    TestSocket,
    IntegratedTab,
    FacilitateSession,
    IndividualOnsiteFacilitation,
    coordinatorErrorMessage,
    TemplatesTab,
    NotFoundPage,
    CustomersList,
    ViewProfile,
    ViewAccount,
    Menu,
    HostProfile,
    EditExperiences,
    TemplateFlow,
    ViewArchivedTemplate
};
