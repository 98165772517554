import React, {useEffect, useState} from 'react';
import { Radio, Button, Table, Modal, notification, Input, Form } from 'antd';
import { connect } from 'react-redux';
import _ from "lodash";
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import  ExportJsonCsv  from '../export-csv/intex';
import ReactQuill from 'react-quill';

import "./style.css";
import Loader from './loader';
import Menus from '../menu';

const ViewProfile = (props) => {
    const [form] = Form.useForm();
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderData, setOrderData] = useState({});
  const [accountOrderData, setAccountOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [orderViewLoader, setOrderViewLoader]  = useState(true)
  const [getExpId, setExpId] = useState();
  const [errorMessage, setErrorMessage] = useState("")

  const columns = [
    {
      title: 'Session Name',
      dataIndex: 'session_name',
      key: 'session_name',
    },
    {
        title: 'Start Date',
        dataIndex: 'start_date',
        key: 'start_date',
    },
    {
        title: 'Start Time',
        dataIndex: 'start_time',
        key: 'start_time',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render:(text, item) => {
        return <div style={{display:"flex", flexDirection: "row"}}>
          <span onClick={() => viewSession(item)} className='action-buttons' style={{cursor: "pointer"}}>View Session</span>
          <span onClick={() => viewRegistration(item)} className='action-buttons' style={{cursor: "pointer",marginLeft: "12px"}}>View Registration</span>
          </div>
      }
  },
  ];

  const headers = [
    {
      name: 'Session Name',
      key: 'session_name',
    },
    {
      name: 'Start Date',
        key: 'start_date',
    },
    {
      name: 'Start Time',
        key: 'start_time',
    }
  ]

  useEffect(() => {
    viewOrderDetails();
    profileOrderListData();
  }, []);

  const viewOrderDetails = () => {
    props.financesViewApi({
        params:{
            id: searchParams.get("order_id"),
            expand: 'experience,experience_template,experience,experience_template_photos,host,guest'
        },
        callback: (res, data) => {
            
            if(!_.isEmpty(_.get(data, 'data.details'))){
              setOrderViewLoader(false)
              setOrderData(_.get(data, 'data.details'))
            }
        }
    })
  };

  const profileOrderListData = () => {
    props.financesListApi({
        params: {
            paid:1,
          sort: 'created_at.desc',
          expand: 'experience,experience_template,experience,experience_template_photos,host,guest',
          profile:searchParams.get("profile_id"),
          user_type: 1,
          limit: 300
          },
          callback:(response, data) => {
            
            let dataItems = [];
            if(data){
              setLoading(false)
            }
            if(_.size(_.get(data, 'data.items')) > 0){
                
                dataItems =   _.get(data, 'data.items').map((value, index) => {
                    
                    return{
                        key: index,
                        session_name: _.get(value, 'experience.template.title'),
                        start_date: moment(_.get(value, 'experience.experience_date')).format('MMMM DD, YYYY'),
                        start_time: moment.tz(_.get(value, 'experience.experience_date'), _.get(value, 'experience.meta_details.timezone')).format("hh:mm A"),
                        // profile: `${_.startCase(_.get(value, 'profile_info.first_name'))} ${_.startCase(_.get(value, 'profile_info.last_name'))}`,
                        order_id: _.get(value, 'id'),
                        guest_id: _.get(value, 'guest.id'),
                        experience:_.get(value, 'experience'),
                        host: _.get(value, 'host')
                    }
                })

                setAccountOrderData(dataItems);
            }
          }
    })
  };
  
  const backToCustomers = () => {
    navigate("/communiti/finances-list?menu=reports");
  };

  const viewAccount = (id) => {
    navigate(`/communiti/view-account?order_id=${searchParams.get("order_id")}&guest_id=${id}`);
  };

  const viewSession = (value) => {
    
    navigate(`/edit-experience?id=${_.get(value, 'experience.id')}&menu=manage&type=${_.get(value, 'experience.type')}`)
    // navigate(`/communiti/integrated-tabs?id=${_.get(value, 'experience.template.id')}&exp_id=${_.get(value, 'experience.id')}&handle=${_.get(props, 'hostProfile.handle')}&template_type=${_.get(value, 'experience.type')}&name_of_exp=${_.get(value, 'session_name')}`)
  }

  const viewRegistration = (value) => {
    navigate(`/edit-experience?id=${_.get(value, 'experience.id')}&menu=manage&type=${_.get(value, 'experience.type')}&from=customers`)
    // navigate(`/communiti/integrated-tabs?id=${_.get(value, 'experience.template.id')}&exp_id=${_.get(value, 'experience.id')}&handle=${_.get(props, 'hostProfile.handle')}&template_type=${_.get(value, 'experience.type')}&name_of_exp=${_.get(value, 'session_name')}&from=view_profile`)
  };

  const showEmailModal = (value) => {
    // getEmailList(value)
    setIsEmailModalOpen(true);
    form.setFieldsValue({
      emails: _.get(orderData, 'guest.contact_details.email')
    })
  };
  
  const handleEmailOk = () => {
    setIsEmailModalOpen(false);
    setErrorMessage('')
    form.setFieldsValue({
        title:'',
        message:'',
        emails:''
    })
  };
  
  const handleEmailCancel = () => {
    setIsEmailModalOpen(false);
    setErrorMessage('');
    form.setFieldsValue({
        title:'',
        message:'',
        emails:''
    })
  };

  const onFinish = (values) => {
    props.sendEmailAPI({
      body:{
          experience: _.get(orderData, 'experience.id'),
          subject: values.title,
          message: values.message,
          guest_ids: _.get(orderData, 'guest.id')
      },
      callback: (res, val) => {
          
          if(_.get(val, 'success')){
            notification.success({
              message: "Success",
              description: "Email has been sent."
            })
              setIsEmailModalOpen(false);
              form.setFieldsValue({
                  title:'',
                  message:'',
                  emails:''
              })
          }
      }
    })
    };
    const onFinishFailed = (errorInfo) => {
      
    };

    const render = () => {
      
        return (
            <div>
              <div className='container' style={{maxWidth: '100%' }}>
                <div>
                <Menus/>
                <div className='col-12' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <Button className='back-customers' style={{marginTop: "12px"}} onClick={() => backToCustomers()}>Back to Customers Page</Button>
        
      </div>
      <div>
        {/* <Button>Export .csv</Button> */}
      </div>
      
    </div>
    <div>
    <div className='col-12'>
            <div className='profile-text'>Profile</div>
    </div>
    <div className='col-12' style={{marginTop:"12px"}}>
      {
        orderViewLoader ? <Loader/> : <div className='card' style={{marginBottom: "12px", borderColor: "#1D2939", backgroundColor: '#F9FAFB'}}>
        <div className="card-body">
            <div style={{display: "flex", flexDirection: "row"}}>
              <p className="card-text profile-cards-text">Name:</p> <p style={{marginLeft: "6px"}}>{_.startCase(_.get(orderData, 'profile_info.first_name'))}</p>
              </div>
              <div style={{display: "flex", flexDirection: "row"}}>
              <p className="card-text profile-cards-text">Gender:</p> <p style={{marginLeft: "6px"}}>{_.startCase(_.get(orderData, 'profile_info.gender'))}</p>
              </div>
              <div style={{display: "flex", flexDirection: "row"}}>
              <p className="card-text profile-cards-text">Date of Birth:</p> <p style={{marginLeft: "6px"}}>{moment(_.get(orderData, 'profile_info.date_of_birth')).format('MMMM DD, YYYY')}</p>
              </div>
            </div>
                </div>
      }
    
            </div>
            </div>
            <div>
    <div className='col-12'>
            <div className='profile-text'>Associated Account</div>
            <p>The above profile is associated with the following account:</p>
    </div>
    <div className='col-12' style={{marginTop:"12px"}}>
      {
        orderViewLoader ? <Loader/> : <div className='card' style={{marginBottom: "12px", borderColor: "#1D2939", backgroundColor: '#F9FAFB'}}>
        <div className="card-body">
            <div style={{display: "flex", flexDirection: "row"}}>
              <p className="card-text profile-cards-text">Name:</p> <p style={{marginLeft: "6px"}}>{`${_.startCase(_.get(orderData, 'guest.first_name'))} ${_.startCase(_.get(orderData, 'guest.last_name'))}`}</p>
              </div>
             { _.get(orderData, 'guest.contact_details.email') && <div style={{display: "flex", flexDirection: "row"}}>
              <p className="card-text profile-cards-text">Email:</p> <p style={{marginLeft: "6px"}}>{_.get(orderData, 'guest.contact_details.email')}</p>
              </div>}
              <div style={{display: "flex", flexDirection: "row"}}>
              <p className="card-text profile-cards-text">Address:</p> <p style={{marginLeft: "6px"}}>{_.get(orderData, 'guest.address')}</p>
              </div>
              <div className="card-text" style={{display: "flex", flexDirection: "row"}}>
                <Button onClick={() => viewAccount(_.get(orderData, 'guest.id'))} style={{height: "40px", borderColor: "#1D2939"}}>View associated account</Button>
                {_.get(orderData, 'guest.contact_details.email') && <Button onClick={() => showEmailModal()} style={{height: "40px", borderColor: "#1D2939", marginLeft: "16px"}}>Email</Button>}
              </div>
            </div>
                </div>
      }
    
            </div>
            </div>
            <div>
    <div className='col-12'>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{display: "flex", flexDirection: "column"}}>
            <div className='profile-text'>Registered sessions</div>
            <p>This profile is registered for the following sessions:</p>
            </div>
            <ExportJsonCsv style={{borderRadius: "6px", backgroundColor: "#fff", borderColor: "#D0D5DD", cursor: "pointer", height: "40px"}} headers={headers} items={accountOrderData}>Export to .csv</ExportJsonCsv>
            </div>
    </div>
    <div className='col-12' style={{marginBottom:"12px"}}>
    <Table dataSource={accountOrderData} columns={columns} loading={loading} pagination={false} />
            </div>
            </div>
            <div>
<Modal centered={true} title="Email" footer={null} open={isEmailModalOpen} onOk={handleEmailOk} onCancel={handleEmailCancel}>
<Form
    name="basic"
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    form={form}
    layout='vertical'
  >
    <div style={{marginBottom: "10px"}}>{_.get(orderData, 'experience.template.title')}</div>
    <Form.Item
      label="Email"
      name="emails"
    >
     <Input disabled/>
      
    </Form.Item>
    
    <Form.Item
      label="Title"
      name="title"
      rules={[
        {
          required: true,
          message: 'Please input your title!',
        },
      ]}
    >
      <Input/>
    </Form.Item>

    <Form.Item
      label="Message"
      name="message"
      rules={[
        {
          required: true,
          message: 'Please input your message!',
        },
      ]}
    >
      {/* <Input.TextArea rows={7} disabled={errorMessage ? true : false} /> */}
      <ReactQuill theme="snow"/>
    </Form.Item>
    <Form.Item
    >
      <Button style={{width: "100%", backgroundColor: "#e85d04", color: "#fff", marginTop: "50px"}} type="primary" htmlType="submit">
    Send
      </Button>
    </Form.Item>
  </Form>
      </Modal>
</div>
                </div>
              </div>
              
            </div>
          )
    };
    return render();
}

const mapStateToProps = state => {
    
    return {
        hostProfile: _.get(state, 'app.host.profile.data.details'),
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    financesViewApi: details => dispatch(global.redux.action.finances.view(details)),
    financesListApi: details => dispatch(global.redux.action.finances.list(details)),
    sendEmailAPI: details => dispatch(global.redux.action.sendEmail.postEmails(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile)