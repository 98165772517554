import { EditFilled,DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, ArrowLeftOutlined, SaveFilled, ClockCircleFilled, CalendarFilled, MailFilled, ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React,{useState,useEffect} from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, InputNumber,Modal,Image, TimePicker, DatePicker} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { set } from 'lodash';
import { connect } from 'react-redux'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import GoogleMapReact from 'google-map-react';
import ViewTemplate from '../../template/view/index';
import RegisteredUsers from './registered-users';
import CompletedUsers from './completed-users';
import momentTimezone from 'moment-timezone';
import '../../onboarding-screen/style.css'
import DatePickerModal from "./date-picker-modal";
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment, { tz } from 'moment'
import { DateObject } from 'react-multi-date-picker'
import MainMenu from '../../main-menu'
import PlacesAutocomplete from 'react-places-autocomplete';
import axios from 'axios';
import * as dayjs from 'dayjs'
const { Option } = Select;
const {confirm} =Modal
const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
]
const currencyList =[{name:'CAD',value:'CAD'},
{name:'USD',value:'USD'}
]
const TemplateTab =(props)=>{
    const [form] = Form.useForm();
    let history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [address_results, setaddress_results] = useState([]);
    const [address_fetching, setaddress_fetching] = useState(false);
    const [clauseTitle, setclauseTitle] = useState('')
    const [clauseDes, setclauseDes] = useState('')
    const [clauseObject, setclauseObject] = useState({})
    const [clauseArray, setclauseArray] = useState([])
    const [pageLoading, setPageLoading] = useState(false);
    const [clauseInputShow, setClauseInputShow] = useState(true);
    const [fileList, setFileList] = useState([])
    const [buttonLoading,setButtonLoading] =useState(false)
    const [ModalVisable,setModalVisable] =useState(false)
    const [BackModalVisable,setBackModalVisable] =useState(false)
    const [TaxListData, setTaxListData] = useState([])
    const [categoriesList,setCategoriesList] =useState([])
    const [LocationData, setLocationData] = useState()
    const [addressData, setAddressData] = useState({})
const [TemplateViewDetails, setTemplateViewDetails] = useState({})
const [TemplateViewDetailsMeta, setTemplateViewDetailsMeta] = useState({})
const [templateViewType, settemplateViewType] = useState('view')
const [startDate, setStartdate] = useState('')
const [startTime, setStartTime] = useState('')
const [endTime, setEndTime] = useState('')
const [timeZone, setTimeZone] = useState('')
const [selectedDate,setSelectedDate] =useState([])
const [allowLate, setAllowLate] = useState('1')
const [allowLateDate, setAllowLateDate] = useState('')
const [saveEdit, setSaveEdit] = useState('save')
const [refundModal, setRefundModal] = useState(false)
const [showPreviewRefundModal, setShowPreviewRefundModal] = useState(false)
const [removeAllowLate, setRemoveAllowLate] = useState('1')
const [refundAmount, setRefundAmount] = useState('')
const [allButtonDisable, setAllButtonDisable] = useState(false)
const [experienceDetails, setExperienceDetails] = useState({})
const [registeredUsers,setRegisteredUsers] =useState([])
const [timeDisableToday, setTimeDisableToday] =useState(false);
const[getMaxiConValue, setMaxiConValue] = useState();

const [isModalOpen, setIsModalOpen] = useState(false);
const [getModalDetails, setModalDetails] = useState({});
const [activateIsModalOpen, setActivateIsModalOpen] = useState(false);
const [activateGetModalDetails, activateSetModalDetails] = useState({});

const [isConcurrentModalOpen, setIsConcurrentModalOpen] = useState(false);
const [selectedValues, setSelectedValues] = useState();
const [timeZonesByCountry, setTimeZonesByCountry] = useState([]);
const [address, setAddress] = useState('');
const [getTaxesValue, setTaxesValue] = useState('')

const [country, setcountry] = useState(null)
const [currency, setcurrency] = useState('')
const [zip_code, setzip_code] = useState('')
const [tax_state, settax_state] = useState('')
const [tax_statename, settax_statename] = useState('')
const dhfgsdfgy = dayjs(startDate).toDate()
const defaultMonth = dayjs(startDate, 'YYYY/MM/DD')

    const defaultProps = {
      center: {
        lat: 11.937871, lng: 79.819953
      },
      zoom: 15
    };

    useEffect(() => {
      getLocationsData()
      getCategoriesList()
      
    loadTaxList()
    if(searchParams.get('id')){
      templateView(searchParams.get('id'))
    }
    if(searchParams.get('exp_id')){
      templateExperienceView(searchParams.get('exp_id'))
      
    }
    
    }, [searchParams.get('id')]);

    const showModal = (value) => {
      
      setModalDetails(value);
     
      setIsModalOpen(true);
    };

    const activateShowModal = (value) => {
      
      activateSetModalDetails(value);
     
      setActivateIsModalOpen(true);
    };

    useEffect(() => {
      if(_.get(props,'userProfile')){
        if(_.get(props,'userProfile.country')){
          setcountry(_.get(props,'userProfile.country')=='us'?'USD':'CAD')
          setcurrency(_.get(props,'userProfile.country')=='us'?'USD':'CAD')
          
   setSelectedValues(_.get(props, 'userProfile.meta_details.timezones[0]'))
          // _.isArray(_.get(props, 'userProfile.meta_details.timezones')) ? _.get(props, 'userProfile.meta_details.timezones[0]'): _.get(props, 'userProfile.meta_details.timezones')
        }
      }
    }, [props.userProfile])
    

    const handleOk = () => {
      setIsModalOpen(false);
      props.changeStatusOfExperience({
          body:{
              id: (searchParams.get('exp_id')),
              status: 0,
              status_glossary:"Deactivated"
          }, 
          callback: (response, data) => {
              if(response){
                templateExperienceView(searchParams.get('exp_id'))
                  notification.success({
                      message:"Success",
                      description:"InActivated successfully"
                  })
              }
          }
      })
    };

    const activateExp = () => {
      setActivateIsModalOpen(false);
      props.changeStatusOfExperience({
          body:{
              id: (searchParams.get('exp_id')),
              status: 1,
              status_glossary:"Activated"
          }, 
          callback: (response, data) => {
              if(response){
                templateExperienceView(searchParams.get('exp_id'))
                  notification.success({
                      message:"Success",
                      description:"Activated successfully"
                  })
              }
          }
      })
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleActiveCancel = () => {
      setActivateIsModalOpen(false);
    };

    const templateView =(id)=>{
      props.viewTemplate({
        params:{id:id,expand:'photos'},
        callback:(res,data)=>{
          if(res==true){
            
            setTemplateViewDetails(_.get(data,'data.details'))
            setTemplateViewDetailsMeta(_.get(data,'data.meta'))
            setFieldValue(data)
          }
        }
      })
    }
    const cancelationCheck =(value)=>{
      if(_.get(value, 'experience_date') &&_.get(value, 'experience_start_time') &&timeZone){
  
      
      const time = _.get(value, 'experience_start_time', "")
      let dateNow = moment(new Date()).format('YYYY-MM-DD')
      let nowDate =moment(_.get(value, 'experience_date')).tz(timeZone).format("YYYY-MM-DD")
      const date = nowDate
  let todayDate = momentTimezone().tz(timeZone).format('YYYY-MM-DD HH:mm')  
  let checkDate = momentTimezone(`${date}T${time}`).tz(timeZone).format('HH:mm')
  checkDate = `${nowDate} ${checkDate}`
  if (moment(todayDate).isSameOrBefore(checkDate)) {
  return true;
  }
  return false;
  }else{
      return false
  }
  }

    const templateExperienceView =(id)=>{
      
      props.viewExperience({
        params:{id:id,expand:'photos,template'},
        callback: async(res,data)=>{

          
          
          
          if(res==true){
           
            setAllButtonDisable(true)
            
            setExperienceDetails(_.get(data,'data.details'))
            
            setAllowLate(_.get(data,'data.details.can_late_register')==true?"1":"2");

            form.setFieldsValue({
              max_no_of_openings: _.get(data,'data.details.max_no_of_openings')
            })


            // setStartdate(momentTimezone(_.get(data,'details.experience_date')).format('YYYY-MM-DD'))
            // setStartTime()
            // setAddressData()
            // setSelectedDate()
            // setAllowLate()
            // allowLateDate()


            // setTemplateViewDetails(_.get(data,'data.details'))
            // setTemplateViewDetailsMeta(_.get(data,'data.meta'))
            // setFieldValue(data)
          }
        }
      })
    }

    useEffect(() => {
      let datas =[] 
      if(timeZone && _.get(experienceDetails,'id')){
        _.map(_.get(experienceDetails,'schedule_dates',[]),(date) =>{
         datas.push(momentTimezone(date).tz(timeZone).format('MMM DD, YYYY'))
       } )
       setSelectedDate(datas)
      }
       
      
    
    }, [experienceDetails])
    useEffect(() => {
      let datas =[] 
      if(timeZone && _.get(experienceDetails,'id')){
        _.map(_.get(experienceDetails,'schedule_dates',[]),(date) =>{
         datas.push(momentTimezone(date).tz(timeZone).format('MMM DD, YYYY'))
       } )
       setSelectedDate(datas)
      }
       
      
    
    }, [timeZone])
    

    const dateTimezone = (date,time) => {
      
      const timeConvert = momentTimezone(`${date}T${time}`).tz(timeZone).format('hh:mm a')
      var dt = moment(timeConvert, ["hh:mm a"]).format("HH:mm");
      let date1 = momentTimezone(`${date}T${dt}`).tz(timeZone).toISOString()
      return date1
  }

  const addTimeZone = (time) => {
    
      var dt = moment(time, ["hh:mm a"]).format("HH:mm");
      // var b = moment.utc("2013-11-18 11:55").tz("America/Toronto");

      const date = momentTimezone().tz(timeZone).format("YYYY-MM-DD");
      let date1 = moment.tz(`${date} ${dt}`,timeZone).toISOString()
      let final_time = _.chain(date1).split('T').last().value();
      return final_time
  }

  const addExperienceDateTimeZone = (time) => {
    var time = moment("12:00 am", ["hh:mm a"]).format("HH:mm");

      var dt = moment(time, ["hh:mm a"]).format("HH:mm");
      // var b = moment.utc("2013-11-18 11:55").tz("America/Toronto");

      const date = momentTimezone().tz(timeZone).format("YYYY-MM-DD");
      let date1 = moment.tz(`${date} ${time}`,timeZone).toISOString()
      let final_time = _.chain(date1).split('T').last().value();
      return final_time
  }

    const setFieldValue =(values)=>{
let details = _.pick(_.get(values,'data.details'), [
  'address', 'id', 'category', 'clauses', 'description',
  ,'min_no_of_openings','host_price','tax_state','zip_code','photos','title'

]);

if (_.get(values,'data.details.address') && _.get(values,'data.details.location')) {
                    
  const address_results = [{
      text: _.get(values,'data.details.address') ,
      value: JSON.stringify({ text:  _.get(values,'data.details.address') , lat: _.get(values,'data.details.location.x'), lng:  _.get(values,'data.details.location.y'), postal_code:''}),
    }]
    form.setFieldsValue({
        address: JSON.stringify({ text:  _.get(values,'data.details.address') , lat: _.get(values,'data.details.location.x'), lng:  _.get(values,'data.details.location.y'), postal_code:''}),
                           })
    setaddress_results(address_results)
}

form.setFieldsValue({...details})
if(_.get(details,'clauses[0]')){
  setclauseArray(_.get(details,'clauses'))
  setClauseInputShow(false)
}
if(_.get(details,'photos[0]')){
  let set_photos =[]
_.map(_.get(details,'photos'),(value,index)=>{
  let make_url =`${_.get(values,'data.meta.photo.path')}/${_.get(values,'data.meta.photo.folder')}/${_.get(values,'data.meta.photo.sizes.medium')}/${_.get(value,'photo')}`

  set_photos.push( {
    uid: _.get(value,'id'),
    id: _.get(value,'id'),
    name: _.get(value,'photo'),
    status: 'done',
    url: make_url,
  },)
})
form.setFieldsValue({
  photo:set_photos
})
setFileList(set_photos)
}



    }
    const getLocationsData =async()=>{
      const { data, status } = await axios.get('https://ipapi.co/json')
      setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
      setTimeZone(_.get(data,'timezone'))

      someValue(moment.tz.zonesForCountry(country=="USD"?"US": "CA"))
      // 
    };

    const someValue = (data) => {
      let a = [];
       _.map(data, (value, index) => {
         a.push({
           key: index,
           timezones: value
         })
       })
      return a
    }
      
    const getCategoriesList  =()=>{
      props.list({
        callback:(res,data)=>{
          if(_.get(data,'data.items')){
            setCategoriesList(_.get(data,'data.items'))
          }
        }
      })
    }
    const loadTaxList =()=>{
props.taxList({
  params:{limit:100},
  callback:(res,data)=>{
if(res==true && _.get(data,'data.items[0]'))
    setTaxListData(_.get(data,'data.items'))
  }
})
    }
    
    

    const fetchPostalCode = address_components => {
        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        return postal_code;
    };
    
    
    
       //Address search
       const fetchAddress = value => {
        setaddress_results([])
        setaddress_fetching(true);
        geocodeByAddress(value).then(body => {
            
            const address_results = _.map(body, location => ({
                text: location.formatted_address,
                value: { text: location.formatted_address, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) },
            }));
    
            setaddress_results(address_results)
            setaddress_fetching(false);
    
        }).catch(error => {
            setaddress_results([])
            setaddress_fetching(true);
        });
    };
    //Initializing debounce for address
    const addressDelayedQuery = _.debounce(q => fetchAddress(q), 1200);
    

    const setClauseDetails =()=>{
        let details =[...clauseArray]

        if(clauseTitle && clauseDes){
          let duplicate = _.some(details,{name:clauseTitle,description:clauseDes})      
          if(duplicate==false) {
            details.push({name:clauseTitle,description:clauseDes})
          }else{
            notification.error({
              message:'Error',
              description:'This clauses title and description is already taken.'
            })
          }
            
          }
            
setclauseObject({})
            setclauseDes('')
            setclauseTitle('')
            setclauseArray(details)
            setClauseInputShow(false)
        }
       
    

    const onEditClasuess =async(value, type,index)=>{

if(type=="edit"){
  setclauseObject(value)
  setclauseTitle(_.get(value,'name'))
  setclauseDes(_.get(value,'description'))
let remove = _.remove(clauseArray,(e,i)=>i==index)

  setclauseArray(clauseArray)
  
  setClauseInputShow(true)
}else{
  let details =[...clauseArray]
  let remove = _.remove(details,(e,i)=>i==index)
  setclauseArray(details)   

  setTimeout(() => {
    setclauseArray(details)   
  }, 200);
 

}


    }
    const getDisabledHours = () => {
      var hours = [];
      for(var i =0; i < moment().hour(); i++){
          hours.push(i);
      }
      return hours;
  }
  
  const   getDisabledMinutes = (selectedHour) => {
      var minutes= [];
      if (selectedHour === moment().hour()){
          for(var i =0; i < moment().minute(); i++){
              minutes.push(i);
          }
      }
      return minutes;
  }
    const loadClausesList =(data)=>{
      
      return  _.map(data,(value,index)=>{
return <div key={index}>
   
   <div className="name-details">
       <span className="name-details-title">Title</span>
       <span>{_.get(value,'name')}</span>
   </div>
   <div className="name-details">
   <span className="name-details-title">Description
</span>
       <span>{_.get(value,'description')}</span>
   </div>
   <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
  <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>onEditClasuess(value,'edit',index)}>
    <EditFilled size={23}  style={{marginRight:7,fontSize:20}}/> Edit</div> 
   <div onClick={()=>onEditClasuess(value,'delete',index)}  style={{display:'flex',alignItems:'center',cursor:'pointer'}}><DeleteFilled 
    size={23}   style={{marginLeft:23,marginRight:7,fontSize:20}} /> Delete</div> 
   </div>
   
   </div>
        })
    }
    const onChangeForm =(value,type)=>{
let josn_data = JSON.parse(value)
defaultProps.center.lat= _.get(josn_data,'lat')
defaultProps.center.lng= _.get(josn_data,'lng')
setLocationData(value)
setAddressData(value)
      
    }

    const onChangeBack =()=>{
    history('/communiti/manage')

    
    }
    const onChange = (date, dateString) => {
      
      let todays_date = moment().format("YYYY-MM-DD")
      if(_.isEqual(dateString, todays_date)){
        setTimeDisableToday(true)
      } else {
        setTimeDisableToday(false)
      }
      setStartdate(dateString);
      
     
        setSelectedDate([]);
        
    };

    const onchangeTime = (date, dateString,type) => {
      
      if(type=='start'){
        setStartTime(dateString)
      }else{
        setEndTime(dateString)
      }
      
      
    };
    

const convertDate =(dates) =>{
  
  let final_array =[]
  _.map(dates,(v)=>{
    let date1 =new DateObject(v).format('YYYY-MM-DD')
    let date2= moment().format('YYYY-MM-DD')
    
    if (moment(date2).isSameOrBefore(date1)) {
      final_array.push(new DateObject(v).format('MMM DD, YYYY'))  
    }
    
  })

  setSelectedDate(final_array)
  
}

const onChangeLateDate =(date,dateSting)=>{
  setAllowLateDate(dateSting)
}
const commonNotification =(des)=>{
notification.error({
  message:"Error",
  description:des
})
}

const checkSaveCondition =()=>{
  if(!startDate){
commonNotification('Please Select the start date')
  }else if(!startTime){
    commonNotification('Please Select the start time')
  }
 else if(_.isEmpty(LocationData)){
 commonNotification('Please Select the your location')
 }
 else if(!country){
  commonNotification('Please Select the Country')
    }else if(!selectedValues){
      commonNotification('Please Select the Timezone')
        }else if(!address){
          commonNotification('Please Select the Address')
            }else if(!zip_code){
              commonNotification('Please Select the Postal Code')
                }
    else{
      setSaveEdit('edit')
    }
}

const buildCancellationTimeLimit=(experience_start_time, experience_date)=>{
  return moment(experience_start_time, "hh:mm a").format("HH:mm:ss");
};
const onSubmitSchedule =()=>{
  if(_.get(TemplateViewDetails, 'type') == 'scheduled'){
    const isJson=(str)=> {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }
  validateCondition((res)=>{
   
    
    let address = _.get(_.head(address_results),'value')
    var details = {
      price: _.get(TemplateViewDetails, 'price', null),
      mode: _.get(TemplateViewDetails, 'mode', null),
      zip_code:  _.get(address, 'postal_code') ? _.get(address, 'postal_code') : zip_code,
      min_no_of_openings: _.get(TemplateViewDetails, 'min_no_of_openings', 0),
      max_no_of_openings: _.get(TemplateViewDetails, 'max_no_of_openings', 0),
      location: `${_.get(address,'lat')},${_.get(address,'lng')}`,
      address: _.get(address,'text'),
      experience_start_time:  moment(startTime, "hh:mm a").format("HH:mm:ss"),
      experience_end_time: moment(endTime, "hh:mm a").format("HH:mm:ss"),
      experience_date: startDate,
      experience_end_date:_.isEmpty(_.size(selectedDate)>0?
       _.last(_.map(selectedDate, date => (moment(date,'MMM DD, YYYY').format("YYYY-MM-DD")))):
       startDate) ? startDate: _.size(selectedDate)>0?
       _.last(_.map(selectedDate, date => (moment(date,'MMM DD, YYYY').format("YYYY-MM-DD")))):
       startDate,
      id: searchParams.get('id'),
      tax_state: tax_statename ? parseInt(tax_statename) : parseInt(getTaxesValue)  ,
      can_late_register:allowLate==1?true:false,
      late_registration_time_limit: allowLate==1 ? allowLateDate :null,
      cancellation_time_limit: buildCancellationTimeLimit(startTime,_.size(selectedDate)>0? _.head(selectedDate):startDate),
      type: _.get(TemplateViewDetails, 'type', null),
      meta_details: {timezone: !_.isEmpty(selectedValues) ? _.isArray(selectedValues)?_.head(selectedValues):selectedValues : _.isArray(_.get(props, 'userProfile.meta_details.timezones')) ? _.get(props, 'userProfile.meta_details.timezones[0]'): _.get(props, 'userProfile.meta_details.timezones'),
    currency:currency
    },
    // country:country=="USD"?'us':'ca'
  };
  
  if(_.size(selectedDate)>0){
   details.schedule_dates=_.map(selectedDate, date => moment(date,'MMM DD, YYYY').format("YYYY-MM-DD"))
  }
  
props.create({
body:details,
callback:(res,data)=>{
if(res==true){
  notification.success({
    message:"Success",
    description:_.get(data,'message')
  })
  history('/communiti/manage')
}
  
}
})
  })
} else if(_.get(TemplateViewDetails, 'type') == 'open') {
  var details = {
    price: _.get(TemplateViewDetails, 'price', null),
    mode: _.get(TemplateViewDetails, 'mode', null),
    zip_code:  _.get(TemplateViewDetails, 'zip_code', null),
    min_no_of_openings: _.get(TemplateViewDetails, 'min_no_of_openings', 0),
    max_no_of_openings: getMaxiConValue ? getMaxiConValue : _.get(TemplateViewDetails, 'max_no_of_openings', 0),
    id: searchParams.get('id'),
    tax_state: _.get(TemplateViewDetails, 'tax_state', null),
    type: _.get(TemplateViewDetails, 'type', null),
    // cancellation_time_limit: moment().format("HH:mm:ss"),
    location: `${_.get(TemplateViewDetails,'location.x')},${_.get(TemplateViewDetails,'location.y')}`,
    address: _.get(TemplateViewDetails,'address'),
    meta_details: {
    currency:_.get(TemplateViewDetails,'meta_details.currency')
    },
};

props.create({
  body:details,
  callback:(res,data)=>{
  if(res==true){
    notification.success({
      message:"Success",
      description:_.get(data,'message')
    })
    history('/communiti/manage')
  }
    
  }
  })
} else if(_.get(TemplateViewDetails, 'type') == 'league') {
  validateCondition((res)=>{
    let address = _.get(_.head(address_results),'value')
    var details = {
      price: _.get(TemplateViewDetails, 'price', null),
      mode: _.get(TemplateViewDetails, 'mode', null),
      zip_code:  _.get(address, 'postal_code') ? _.get(address, 'postal_code') : zip_code,
      min_no_of_openings: _.get(TemplateViewDetails, 'min_no_of_openings', 0),
      max_no_of_openings: _.get(TemplateViewDetails, 'max_no_of_openings', 0),
      location: `${_.get(address,'lat')},${_.get(address,'lng')}`,
      address: _.get(address,'text'),
      experience_start_time:  moment(startTime, "hh:mm a").format("HH:mm:ss"),
      experience_end_time: moment(endTime, "hh:mm a").format("HH:mm:ss"),
      experience_date: startDate,
      experience_end_date:_.isEmpty(_.size(selectedDate)>0?
      _.last(_.map(selectedDate, date => (moment(date,'MMM DD, YYYY').format("YYYY-MM-DD")))):
      startDate) ? startDate: _.size(selectedDate)>0?
      _.last(_.map(selectedDate, date => (moment(date,'MMM DD, YYYY').format("YYYY-MM-DD")))):
      startDate,
      id: searchParams.get('id'),
      tax_state: tax_statename ? tax_statename : getTaxesValue,
      can_late_register:allowLate==1?true:false,
      late_registration_time_limit: allowLate==1 ? allowLateDate :null,
      cancellation_time_limit: buildCancellationTimeLimit(startTime,_.size(selectedDate)>0? _.head(selectedDate):startDate),
      type: _.get(TemplateViewDetails, 'type', null),
      
      meta_details: {timezone: !_.isEmpty(selectedValues) ? _.isArray(selectedValues)?_.head(selectedValues):selectedValues : _.isArray(_.get(props, 'userProfile.meta_details.timezones')) ? _.get(props, 'userProfile.meta_details.timezones[0]'): _.get(props, 'userProfile.meta_details.timezones'),
      currency:currency
    },
    // country:country=="USD"?'us':'ca'
  };
  if(_.size(selectedDate)>0){
   details.schedule_dates=_.map(selectedDate, date => moment(date,'MMM DD, YYYY').format("YYYY-MM-DD"))
  }
  
props.create({
body:details,
callback:(res,data)=>{
if(res==true){
  notification.success({
    message:"Success",
    description:_.get(data,'message')
  })
  history('/communiti/manage')
}
  
}
})
  })
}

};

const changeMaxiConcurrent = (value) => {
  
  setMaxiConValue(value)
}
 const range = (start, end) => {
  const result = []
  for (let i = start; i < end; i++) {
      result.push(i)
  }
  return result
}
const disabledTime = (current) => {
  const now = moment()
  // 
  if (current&& current.isSame(startDate, "day")) {
    let a = moment().format('a')
    let b = current.format('a')
      return {
          disabledHours: () => disabledHours(),
          disabledMinutes: (selectedHour) => disabledMinutes(selectedHour),
      }
  } else {
      return {
          disabledHours: () => [],
          disabledMinutes: () => [],
      }
  }
}
const disabledHours = () => {
  var hours = [];
    for(var i =0; i < moment().hour(); i++){
      hours.push(i);
  }
  return hours;
  
};
const disabledMinutes = (selectedHour) => {
  
  var minutes= [];
    if (selectedHour === moment().hour()){
        for(var i =0; i < moment().minute(); i++){
            minutes.push(i);
        }
    }
    return minutes;
};

const validateCondition =(callback)=>{
  if(!startDate){
commonNotification('Please Select the start date')
  }else if(!startTime){
    commonNotification('Please Select the start time')
  }
 else if(_.isEmpty(LocationData)){
 commonNotification('Please Select the your location')
 } if(!country){
  commonNotification('Please Select the Country')
    }else if(!selectedValues){
      commonNotification('Please Select the Timezone')
        }else if(!address){
          commonNotification('Please Select the Address')
            }else if(!zip_code){
              commonNotification('Please Select the Postal Code')
                }
//  else if(_.size(selectedDate)==0){ !_.isEmpty(selectedValues) ? selectedValues : _.get(props, 'userProfile.meta_details.timezones')
//   commonNotification('Please Select the session date')
//     }
    else{
      callback('done')
    }
}
    const render =()=>{
      
let set_start_date;
let set_start_time;
let set_end_time;
let late_register;
if(searchParams.get('exp_id') && _.get(experienceDetails,'id') && timeZone){
  const date = momentTimezone().tz(timeZone).format("YYYY-MM-DD");
  set_start_date =moment(momentTimezone(_.get(experienceDetails,'experience_date')).tz(timeZone).format('YYYY-MM-DD'),'YYYY-MM-DD')
  set_start_time =moment(momentTimezone(`${date}T${_.get(experienceDetails,'experience_start_time')}`).tz(timeZone).format('hh:mm A'),'hh:mm A')
  set_end_time =moment(momentTimezone(`${date}T${_.get(experienceDetails,'experience_end_time')}`).tz(timeZone).format('hh:mm A'),'hh:mm A')
  late_register = moment(momentTimezone(_.get(experienceDetails,'late_registration_time_limit')).tz(timeZone).format('YYYY-MM-DD'),'YYYY-MM-DD')
}

const  requestCancel = (id)=>{
  confirm({
      icon:null,
      okText: 'Continue',
    
    cancelText: 'Cancel',
      content: <div style={{
display:'flex',
flexDirection:'column',
alignItems:'center'

      }}><span style={{fontSize:'29px',fontWeight:'600'}}>Cancel Session</span><span>Are you sure you want to cancel this session?</span></div>,

      onOk() {
          rquestProcess(id)
      },

      onCancel() {
        
        
      },
    });
}
      
   const getFilterTax = (list,currency)=>{
      let country = currency=='CAD' ? 'CA' :'US'
          let a = _.filter(list,(v,i)=>_.get(v,'country')==country)
      return a
        }
const rquestProcess = (id) => {
  if (id) {
      setPageLoading(true)
      props.experiencesRequestCancel({
          body: { cancellation_status_glossary: 'Not Interest', id:id},
          callback: (response, data) => {
          setPageLoading(false)
              if (response) {
                  notification.success({
                      message:"Success",
                      description:'Session has been cancelled successfully.'
                  })
                  history('/communiti/manage')
              } else {
                  if (_.get(data, 'errors[0].rules[0].message')) {
                      notification.error({
                          message:"Error",
                          description:_.get(data, 'errors[0].rules[0].message')
                      })
                      
                  } else if (_.get(data, 'message')) {
                      notification.error({
                          message:"Error",
                          description:_.get(data, 'message')
                      })
                      
                  }
              }
          }
      }
      );
    }

}

const getProRateDetails =()=>{
if(saveEdit=='edit'){
  if(allowLate || _.get(experienceDetails,'late_registration_time_limit')){
return addProRateDate([startDate?startDate:_.get(experienceDetails,'experience_date'), ...selectedDate])
  }else{
    return [startDate?startDate:_.get(experienceDetails,'experience_date'), ...selectedDate]
  }
}else{
  if(allowLate || _.get(experienceDetails,'late_registration_time_limit')){
    return addProRateDate([startDate?startDate:_.get(experienceDetails,'experience_date'),..._.get(experienceDetails,'schedule_dates')])
      }else{
        return [startDate?startDate:_.get(experienceDetails,'experience_date'),..._.get(experienceDetails,'schedule_dates')]
      }
}


}

const addProRateDate =(data)=>{
  let a =[...data,allowLateDate ?allowLateDate:_.get(experienceDetails,'late_registration_time_limit')]

  const sortedArray = _.orderBy(a, function(o) { return new moment(o).format('YYYYMMDD'); }, ['asc']);

  
  return sortedArray
};

const showConcurrentModal = () => {
  setIsConcurrentModalOpen(true)
};

const concurrentOk = () => {
  setIsConcurrentModalOpen(false);
};

const concurrentCancel = () => {
  setIsConcurrentModalOpen(false);
};

const increaseConcurrentSlot = (value) => {
  
  props.updateConcurrentRegn({
    body:{
      id: _.get(experienceDetails, 'id'),
      max_no_of_openings: getMaxiConValue
    }, callback: (response, data) => {
      if(response){
        if(searchParams.get('id')){
          templateView(searchParams.get('id'))
        }
        if(searchParams.get('exp_id')){
          templateExperienceView(searchParams.get('exp_id'))
          
        }
        notification.success({
          message:"Success",
          description: "Maximum Number of Concurrent Registrants updated successfully"
        })
      }
    }
  });
  
};

const handleChange = (value) => {
  
  setSelectedValues(value);
  
};

        const uploadButton = (
            <div>
               <FileImageOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          );
          const handleChange1 = (value) => {
            setAddress(value);
          };
          
          const handleSelect = async (value) => {
          
            
            setaddress_results([])
              setaddress_fetching(true);
              geocodeByAddress(value).then(body => {
                  
                  const address_results = _.map(body, location => ({
                      text: location.formatted_address,
                      value: { text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) },
                  }));
          
                  setaddress_results(address_results)
                  setaddress_fetching(false);
                 
                setAddress(value)

                if(_.size(address_results)>0){
                  let a = address_results[0].value
                  setzip_code(_.get(a,'postal_code'))
                  
                }
                
                let state =extractStateName(body)
                let ada = _.filter(TaxListData,v=>_.lowerCase(v.state)==_.lowerCase(state) && v)
                // let sameState = _.filter(TaxListData, m => _.lowerCase(m.state) == _.lowerCase(state) && m) 
                // _.size(sameState)>0 && setProvince(_.get(_.head(sameState),'id'))
                
                if(_.size(ada)>0){
                  settax_state( _.startCase(_.get(ada[0],'state')))
                  settax_statename( _.get(ada[0],'id'))
                
                }
                
                
                setaddress_results(address_results)
              }).catch(error => {
                  setaddress_results([])
                  setaddress_fetching(true);
              });
          };
          const extractStateName = results => {
            for (let i = 0; i < results[0].address_components.length; i++) {
              const component = results[0].address_components[i];
              if (component.types.includes('administrative_area_level_1')) {
                return component.long_name; // Return the state name
              }
            }
            return '';
          };
          
          const searchOptions = {
            componentRestrictions: { country: country=="USD"?['us']:['ca'] }, // Restrict search to United States and Canada
          };
          
          const selectProvince = (value) => {
            
            setTaxesValue(value)
          }
        return (<>
        <div>
          <MainMenu {...props} manageTab={true}/>
        <div className="coach-full-conatiner">
        
            <div className="coach-info-conatiner-first">
            <div style={{display:'flex',flexDirection:'row',marginBottom:23,justifyContent:'space-between',width:'100%'}}>
            
            <Button onClick={()=>onChangeBack()} disabled={buttonLoading} className='form-btn-4-back'  style={{backgroundColor:'#a7a7a7'}}
             ><ArrowLeftOutlined />Back</Button>
            
             {searchParams.get('mode')==2?'': searchParams.get('id') &&searchParams.get('exp_id') && _.get(TemplateViewDetails, 'type') !== 'open'?<Button onClick={()=>requestCancel(searchParams.get('exp_id'))} className='form-btn-4-schedule-template'
            style={{backgroundColor:'#a7a7a7'}} >Cancel</Button>:
            searchParams.get('exp_id') && _.get(TemplateViewDetails, 'type') == 'open' && _.get(experienceDetails, 'status') == 1 ? <Button onClick={() => showModal(experienceDetails)} className='form-btn-4-schedule-template'
            style={{backgroundColor:'#a7a7a7'}} >Set to Inactive</Button> : searchParams.get('exp_id') && _.get(TemplateViewDetails, 'type') == 'open' && _.get(experienceDetails, 'status') == 0 ? <Button onClick={() => activateShowModal(experienceDetails)} className='form-btn-4-schedule-template'
            style={{backgroundColor:'#a7a7a7'}} >Set to Active</Button> :
             <Button disabled={buttonLoading || allButtonDisable} onClick={()=>onSubmitSchedule()}  className='form-btn-4-schedule-template'
             ><ClockCircleFilled color='#a7a7a7' />Schedule</Button>}
            
                  
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'900',fontSize:'27px'}}>Selected Template</span>
                   
                </div>
               
               <div> 
               <ViewTemplate template={{details:TemplateViewDetails,photos:fileList,clasuse:clauseArray}} yourExpDetails = {experienceDetails} />
            </div>
            <div className="title-style" style={{marginTop:"15px"}}>
            Update Your Open Session
            </div>
            <div className="first-container" style={{marginTop:'23px', display: _.get(TemplateViewDetails, 'type') == 'open' ? "block" : "none"}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Maximum # of Concurrent Registrants <InfoCircleOutlined className='open-session-info' onClick={showConcurrentModal}/></div>
<Form form={form}>
<Form.Item name='max_no_of_openings'   rules={[
          {
            required: true,
            message: 'Please input your Max no of openings!',
          },
        ]}><InputNumber min={_.get(experienceDetails, 'max_no_of_openings')} onChange={(e) => changeMaxiConcurrent(e)} /></Form.Item>
         <div   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"end",display:'flex',marginTop:'2%',marginTop:'10px'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button 
            className='form-btn-4' style={{marginTop:'-63px'}}
            onClick={() => increaseConcurrentSlot()}
            > 
            <SaveFilled />Save
            </Button>
            
            {/* </div> */}
            </div>
        </Form>

                    </div>
                </div>
                </div>
</div>
           
            <div style={{marginTop:'3%', paddingBottom:"1px", display: _.get(TemplateViewDetails, 'type') == 'open' ? "block" : "none"}} >
        <div className='set-schedule-container' style={{backgroundColor:'#e9e9e9'}}>
        <div className='set-schedule-inner-container'>
                  <div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                
                <div className="name-inner-conatiner-input">
<div className="title-style">
Managing Schedules in Open Session </div>
                    </div>
                </div>
</div>
                <div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Setting Open Sessions to Active
</div>
<div className='view-template-title'> Open sessions do not require you to set a schedule. By hitting the “Set to Active” button at the top of this page, it will make this listing visible for users on Communiti to register.</div>

<div className='view-template-title' style={{marginTop: "20px"}}>Once users register for a session with you, and you can coordinate with them via chat to determine a date and time to fulfill their</div>
<div className='view-template-title' >purchase.</div>
                    </div>
                </div>
                </div>
</div>

<div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Marking Open Session as Complete
</div>
<div className='view-template-title'>When you have completed a session with a user, you can press the “Mark as Complete” button beside their name in the Registration table below.</div>

                    </div>
                </div>
                </div>
</div>

<div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Deactivating Open Sessions
</div>
<div className='view-template-title'>If you wish to stop allowing users to register for sessions wih you via an Open Session listing, set the session to “Inactive” using the “Deactivate Open Session” button at the top of this page.</div>
<div className='view-template-title' style={{marginTop: "20px"}}><InfoCircleOutlined className='open-session-an-info'/> If you have registered users who are not yet marked as complete, even if you deactivate this session you, the session will stay in your active tab until you mark the sessions as complete.</div>
                    </div>
                </div>
                </div>
</div>
</div>
            </div>
           </div>
            
           <div style={{marginTop:'3%', display: _.get(TemplateViewDetails, 'type') == 'open' ? "none" : "block"}}>
           <div style={{display:'flex',flexDirection:'column',marginBottom:'23px'}}>
                    <span style={{fontWeight:'900',fontSize:'27px'}}>Set Your Schedule</span>
                   
                </div> 
                <div className='set-schedule-container' style={{backgroundColor:(saveEdit=="edit" ||searchParams.get('exp_id'))? "rgb(233, 233, 233)":'white'}}>
                  <div className='set-schedule-inner-container'>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'600',fontSize:'23px'}}>Starting Date and Time</span>
                   
                </div>
               {searchParams.get('exp_id') && _.get(experienceDetails,'id')? <div className='time-date-location-conatiner'>
                <div className='inner-pickers' style={{width:'25%'}}>
                 <span style={{fontWeight:'700'}}>Date</span> 
                 <DatePicker disabled={true} value={set_start_date} />
                </div>
                <div className='inner-pickers' style={{width:'25%',marginLeft:'16px'}}>
                 <span style={{fontWeight:'700'}}>Start Time</span> 
                 <TimePicker disabled={true} 
                 value={set_start_time}
                 changeOnScroll 
                  needConfirm={false}
                 
      format={"hh:mm a"} />
                </div>
                <div className='inner-pickers' style={{width:'25%',marginLeft:'16px'}}>
                 <span style={{fontWeight:'700'}}>End Time</span> 
                 <TimePicker disabled={true} 
                 value={set_end_time}
      format={"hh:mm a"}
      changeOnScroll 
                  needConfirm={false} />
                </div>
               
                
                </div>: <div className='time-date-location-conatiner'>
                <div className='inner-pickers' style={{width:'25%'}}>
                 <span style={{fontWeight:'700'}}>Date</span> 
                 <DatePicker disabled={saveEdit=="edit"} disabledDate={(current)=> {
  return current && current.valueOf() < moment().add(-1, 'days') ;
}} onChange={onChange} />
                </div>
                <div className='inner-pickers' style={{width:'25%',marginLeft:'16px'}}>
                 <span style={{fontWeight:'700'}}>Start Time</span> 
                 <TimePicker changeOnScroll needConfirm={false} disabled={saveEdit=="edit"} onChange={(e,data)=>{onchangeTime(e,data,'start'); }}  
                 disabledTime={disabledTime} 
                // disabledHours={disabledHours}
                // disabledMinutes={disabledMinutes}
      format={"hh:mm a"} />
                </div>
                <div className='inner-pickers' style={{width:'25%',marginLeft:'16px'}}>
                 <span style={{fontWeight:'700'}}>End Time</span> 
                 <TimePicker changeOnScroll needConfirm={false} disabled={saveEdit=="edit"} onChange={(e,data)=>onchangeTime(e,data,'end')}  
                 
                disabledTime={startTime?null:disabledTime} 
                disabledHours={timeDisableToday === true ? disabledHours : null}
                disabledMinutes={timeDisableToday === true ? disabledMinutes : null}
      format={"hh:mm a"} />
                </div>
               
                
                </div>}
               
               <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
               <div className='inner-pickers'style={{width:'45%',marginLeft:'0px',marginBottom:'3%'}}>
   
   <span style={{fontWeight:'700'}}>
Country
</span>

 <Select
 value={country}
 disabled={saveEdit=="edit"}
 onSelect={(value)=>{
   
   setTaxesValue('');
   settax_state('');
   setAddress('');
   setSelectedValues()
   setcountry(value);
   setcurrency(value)

   
 
 }}
 style={{width:'100%'}}
   optionFilterProp="children">
  {_.map(countryList,(v,i)=>{
    return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
  })}
 </Select>
 </div>
 <div className='inner-pickers'style={{width:'45%',marginLeft:'0px',marginBottom:'3%'}}>

<span style={{fontWeight:'700'}}>
Currency
</span>

<Select

style={{width:'100%'}}
value={country}
disabled
optionFilterProp="children">
{_.map(currencyList,(v,i)=>{
 return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
})}
</Select>
</div>
</div>


        
                           {searchParams.get('exp_id') && _.get(experienceDetails,'id')?
               <div className='inner-pickers'style={{width:'45%',marginLeft:'0px',marginBottom:'3%'}}>
               <span style={{fontWeight:'700'}}>Location</span> 
               <div className='preview-selected-date' style={{height:'auto'}}>
                 <span  className='preview-selected-date-name'>{_.get(experienceDetails,'address')}</span></div>
                 </div>
                 :
                 <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                 <div className='inner-pickers'style={{width:'45%',marginLeft:'0px',marginBottom:'3%'}}>
                <span style={{fontWeight:'700'}}>Timezone</span> 
                <Select
                disabled={saveEdit=="edit"}
   value={selectedValues}
   style={{
     width: '100%',
   }}
   placeholder="Select a timezone"
   defaultValue={_.get(props, 'userProfile.meta_details.timezones[0]')}
   onChange={handleChange}
   // optionLabelProp="label"
 >
    {(moment.tz.zonesForCountry(country=="USD"?"US": "CA")).map((item, index) => {
      
                                  return (
                                    <Option key={index} value={item}>{item}</Option>

                                  )
                                })}
 </Select>
               
               </div>
               <div className='inner-pickers'style={{width:'45%',marginLeft:'0px',marginBottom:'3%'}}>

<span style={{fontWeight:'700'}}>
Address
</span>
                           <PlacesAutocomplete
                           

     value={address}
     onChange={handleChange1}
     onSelect={handleSelect}
     searchOptions={searchOptions}
     
   >
     {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
       <div>
         <Input disabled={saveEdit=="edit"} style={{borderRadius:'50px',height:'50px'}} {...getInputProps({ placeholder: 'Search address' })}  />
         {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
         <div>
           {loading ? <div>Loading...</div> : null}
           {suggestions.map((suggestion) => {
             const style = {
               backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
               marginTop:10,
             };
             
             return (
               <div {...getSuggestionItemProps(suggestion, { style })}>
                 {suggestion.description}
               </div>
             );
           })}
         </div>
       </div>
     )}
   </PlacesAutocomplete>
                            

                         </div>
               </div>}

                   
                         

               <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
  <div className='inner-pickers'style={{width:'45%',marginLeft:'0px',marginBottom:'3%'}}>

  <span style={{fontWeight:'700'}}>
  Province / State</span>

          <Select value={getTaxesValue ? getTaxesValue : tax_state} onChange={selectProvince} disabled={saveEdit=="edit"} getPopupContainer={trigger => trigger.parentNode}
                                    placeholder={'Search Province / State...'}
                                >
                                    { _.map(getFilterTax(TaxListData,currency),(d,i) => {
                                      
                                        return(
                                          <Select.Option  key={_.startCase(_.get(d,'id'))}>{_.startCase(_.get(d,'state'))}</Select.Option>
                                        )
                                    }
                                        
                                    )}
                                </Select>
        

                    </div>
        
                   
                    <div className='inner-pickers'style={{width:'45%',marginLeft:'0px',marginBottom:'3%'}}>
              
               
                    <span style={{fontWeight:'700'}}>
                    Postal / Zip Code</span>
<Input disabled={saveEdit=="edit"} style={{borderRadius:'50px',height:'50px'}} value={zip_code} onChange={(e) => setzip_code(e.target.value)} />

                   
                
                </div></div>


                <div style={{display:'flex',flexDirection:'column',marginBottom:'2%'}}>
                <span style={{fontWeight:'600',fontSize:'23px'}}>Session Schedule (Excluding Start Date & Time)</span>
                   
                </div>
                
                <DatePickerModal startingDate = {startDate} disabled={saveEdit=="edit"||allButtonDisable} startDate={moment(startDate).format('YYYY-MM-DD')} onChangePickers={(value)=>convertDate(value)} />
              
              <div style={{display:'flex',flexDirection:'column'}}>
              <span style={{fontWeight:'600',fontSize:'23px'}}>Date</span>
                   
                </div>
                {_.map(selectedDate,(value,index)=>{
                  
                  
return <div key={value} className='preview-selected-date'><span  className='preview-selected-date-name'>{value}</span></div>
                })}
                {_.size(selectedDate)==0 && <div  className='preview-selected-date' style={{backgroundColor:'#fff'}}></div>}
                {/* <div className='preview-selected-date'></div> */}
                <div style={{display:'flex',flexDirection:'column',marginTop:'3%',marginBottom:'3%'}}>
                    <span style={{fontWeight:'700',fontSize:'23px',marginBottom:'2%'}}>Late Registrations</span>
                    <span style={{fontWeight:'900',fontSize:'15px',color:'#a7a7a7'}}>DO YOU WISH TO ALLOW LATE REGISTRATIONS?</span>
                   
                </div>
                <div> <Button disabled={saveEdit=="edit"||allButtonDisable} onClick={()=>setAllowLate('1')} style={{backgroundColor: allowLate=="1"?'#e85f25':"#a7a7a7"}} className='form-btn-4-schedule-template-late'
             >Yes</Button> <Button disabled={saveEdit=="edit"||allButtonDisable}  onClick={()=>setAllowLate('2')} style={{backgroundColor: allowLate=="2"?'#e85f25':"#a7a7a7",marginLeft:'2%'}} className='form-btn-4-schedule-template-late'
             >No</Button></div>
             <div style={{display:'flex',flexDirection:'column'}}>
             <span style={{fontWeight:'900',fontSize:'15px',color:'#a7a7a7',textTransform:'uppercase'}}>
                      Please select the last day you wish to accept Registrations</span>
                   
                </div>
                <div className='inner-pickers' style={{width:'25%',marginTop:'2%',marginBottom:'2%'}}>
               {searchParams.get('exp_id') && _.get(experienceDetails,'id')?  <DatePicker disabled={saveEdit=="edit"||allButtonDisable}  
 value={late_register}  />:<DatePicker defaultPickerValue={defaultMonth} disabled={saveEdit=="edit"||allButtonDisable || allowLate=='2' || _.isEmpty(startDate) || _.isEmpty(selectedDate)}  disabledDate={(current)=> {
  if(startDate ){
return current && current.valueOf() < moment(startDate) || _.size(selectedDate)>0 && current && current.valueOf() > moment(_.last(selectedDate))
  }  return current && current.valueOf() < moment().add(-1, 'days') ;
}} onChange={onChangeLateDate}  />}
                
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'900',fontSize:'18px',marginBottom:'1.5%'}}>Prorated Sessions</span>
                   {(searchParams.get('exp_id') ||saveEdit=="edit") ? <span style={{fontWeight:'500',fontSize:'14px'}}>
                      Based on the dates you selected, this will have <span style={{fontWeight:'900'}}>{_.size(selectedDate)==0?'1 session':_.size(selectedDate)+1 +' sessions'} </span>. The <span style={{fontWeight:'900'}}>cost per session is ${_.round( _.get(saveEdit=='edit'?TemplateViewDetails :experienceDetails,'price')/(_.size(selectedDate)+1),2)}</span> and the <span style={{fontWeight:'900'}}>total cost is ${_.get(saveEdit=='edit'?TemplateViewDetails :experienceDetails,'price') }</span>. When a user registers late, 
                      they will pay based on the remaining sessions left on your schedule up to the last date.
                    </span>:<span style={{fontWeight:'500',fontSize:'14px'}}>
                      Please input a Session Schedule and select a last registration date to see prorated session calculations
                    </span>}
                    {((searchParams.get('exp_id')&& 
                    _.get(experienceDetails,'can_late_register')==true && 
                    _.get(experienceDetails,'late_registration_time_limit')&&
                    _.size(_.get(experienceDetails,'schedule_dates'))>0 ) || (saveEdit=="edit" &&
                    _.size(selectedDate)>0  && allowLate==1 && allowLateDate))
                    
                    && <div style={{marginTop: "10px",width: "100%", float: "left"}}>
           <div style={{display:'flex',flexDirection:'column'}}>
              <span style={{fontWeight:'600',fontSize:'23px'}}>Prorated Session Pricing Based on Date</span>
             <div className='prorated-pricing' style={{marginTop: "10px"}}>
              
              <span style={{fontSize: "20px", fontWeight: "700"}}>Registration Date</span>
              <span style={{fontSize: "20px", fontWeight: "700"}}>Amount Payable</span>
              
             </div>
             {_.map(getProRateDetails(),(value,i)=>{
               let start =moment.tz(value,timeZone).format("YYYY-MM-DD")
               let end =  moment.tz(allowLateDate ?allowLateDate:_.get(experienceDetails,'late_registration_time_limit'),timeZone).format("YYYY-MM-DD")
             
               if(moment(end).isSameOrAfter(start)){
      return  i==0?       
 <div className='prorated-pricing' style={{marginTop: "10px"}}>             
 <span>Before {moment(value).format('MMMM D, YYYY')}</span>
 <span>${_.get(saveEdit=='edit'?TemplateViewDetails :experienceDetails,'price') }</span>
</div>:<div className='prorated-pricing' style={{marginTop: "10px"}}>            
 <span>Between {moment(saveEdit=='edit'?[startDate, ...selectedDate][i-1]:[_.get(experienceDetails,'experience_date'), ..._.get(experienceDetails,`schedule_dates`)][i-1]).format('MMMM D, YYYY')} and {moment(value).format('MMMM D, YYYY')}</span>
 <span>${_.round((_.get(saveEdit=='edit'?TemplateViewDetails :experienceDetails,'price'))-((_.get(saveEdit=='edit'?TemplateViewDetails :experienceDetails,'price')/(_.size(selectedDate)+1))*i),2)}</span>
</div>}
             })}
            
             
          </div>
          </div>}
         
                </div><div style={{display:'flex',justifyContent:'flex-end'}}>
              {!_.get(experienceDetails,'id') && (saveEdit=="save"?  <Button onClick={()=>checkSaveCondition()}  className='form-btn-4' style={{backgroundColor:'#e85f25'}}
             ><SaveFilled /> Save</Button>:<Button onClick={()=>setSaveEdit('save')}  className='form-btn-4' style={{backgroundColor:'#a7a7a7'}}
             ><EditFilled /> Edit</Button>)}</div>
                </div>
                </div>

           </div>

          
           
        </div>

        <div>
  <Modal centered={true} 
className='appropriateTemplateModal'
width="40%"
title={_.get(getModalDetails, 'template.title')} 
open={isModalOpen} 
onOk={handleOk}
onCancel={handleCancel}
footer={[
    <Button style={{borderRadius:"20px", backgroundColor:"#e85f25", color:"#fff"}} key="back" onClick={handleCancel} >Cancel</Button>,
    <Button style={{borderRadius:"20px", backgroundColor:"#e85f25"}}  key="submit" type="primary"onClick={handleOk}>
            Deactivate this session
          </Button>
]}
>
        <p>You are deactivating the listing "{_.get(getModalDetails, 'template.title')}". Once deactivated, users will no longer be able to see or purchase this session on the Communiti mobile app.</p>
        <p>If you have registrations that have not yet been marked as complete, it will remain in you Active Schedule tab until you fulfill those sessions and mark them as complete.</p>
      </Modal>
</div>

<div>
  <Modal centered={true} 
className='appropriateTemplateModal'
width="40%"
title={_.get(activateGetModalDetails, 'template.title')} 
open={activateIsModalOpen} 
onOk={activateExp}
onCancel={handleActiveCancel}
footer={[
    <Button style={{borderRadius:"20px", backgroundColor:"#e85f25", color:"#fff"}} key="back" onClick={handleActiveCancel} >Cancel</Button>,
    <Button style={{borderRadius:"20px", backgroundColor:"#e85f25"}} key="submit" type="primary"onClick={activateExp}>
            Reactivate
          </Button>
]}
>
<p>You are reactivating the listing “{_.get(activateGetModalDetails, 'template.title')}”. Once reactivated, users will be to see and purchase this session if there are available registration slots.</p>
        
      </Modal>
</div>

<div>
  <Modal centered={true} className='appropriateTemplateModal' width='40%' footer={null} title="What is maximum # of concurrent registrations?" open={isConcurrentModalOpen} onOk={concurrentOk} onCancel={concurrentCancel}>
        <p>The maximum number of current registrations is the number of oustanding registrations that you can have before users can no longer book this session.</p>
        <p>For example, if you set this number to 10, and you have 10 oustanding registrations, then users will no longer be able to book this session.</p>
        <p>If you complete one of the sessions, then that number reduces to 9 and a user will be able to book with you again.</p>
      </Modal>
</div>
      
        </div>
        {_.get(experienceDetails, 'id')&&<RegisteredUsers chatkitty_channel_id={_.get(experienceDetails,'chatkitty_channel_id')} is_start={cancelationCheck(experienceDetails)} exp_id={searchParams.get('exp_id')} temp_details={TemplateViewDetails} />}
        {/* {_.get(TemplateViewDetails, 'type') == 'open' ? _.get(experienceDetails, 'id')&&<CompletedUsers chatkitty_channel_id={_.get(experienceDetails,'chatkitty_channel_id')} is_start={cancelationCheck(experienceDetails)} exp_id={searchParams.get('exp_id')} temp_details={TemplateViewDetails} />:''} */}
        </div></>)
    }

    return render()
}
const mapStateToProps = state => {
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),
       guestUserProfile: _.get(state, 'app.guest.profile.data.details')
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
    list: details => dispatch(global.redux.action.categories.list(details)),
    hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
    createTemplate: details => dispatch(global.redux.action.template.create(details)),
    updateTemplate: details => dispatch(global.redux.action.template.update(details)),
    addPhotoTemplate: details => dispatch(global.redux.action.template.addPhoto(details)),
    taxList: details => dispatch(global.redux.action.tax.list(details)),
    getLocations: details => dispatch(global.redux.action.host.getLocation(details)),
    viewTemplate: details => dispatch(global.redux.action.template.view(details)),
    viewExperience: details => dispatch(global.redux.action.experience.view(details)),
    deletePhoto: details => dispatch(global.redux.action.template.deletePhoto(details)),
    create: details => dispatch(global.redux.action.experience.create(details)),
    experiencesRequestCancel: details => dispatch(global.redux.action.experience.requestCancel(details)),
    changeStatusOfExperience: details => dispatch(global.redux.action.experience.changeStatus(details)),
    updateConcurrentRegn: details => dispatch(global.redux.action.experience.increaseMaxSlot(details)),

    

   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(TemplateTab)