import { UpOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React, { useEffect, useState } from 'react'
import {Button, Collapse,Skeleton, Space, } from 'antd'
import { connect } from 'react-redux'
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import './style.css'
const { Panel } = Collapse;

// disableBrowserBackButton();
const CoachInfoApplication =(props)=>{
const [profileDetails,SetProfileDetails] =useState({})
let history = useNavigate();
const [size, setSize] = useState(15);
const [buttonShape, setButtonShape] = useState('square');
const [avatarShape, setAvatarShape] = useState('circle');
    useEffect(()=>{
props.hostProfileData({
    params:{expand:'categories,invite_code'},
    callback:(res,data)=>{
        if(_.get(data,'data.details')){
            SetProfileDetails(_.get(data,'data'))
        }
    }
})

    },[])

    const render =()=>{
        return (
        <div className="coach-full-conatiner">
           
            <div className="coach-info-conatiner-first">
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'900',fontSize:'27px'}}>{ _.get(profileDetails,'details.id') ? _.get(profileDetails,'details.approved_status')==false?  "Thank you. We have received your Application.":"Congratulations. Your Application has been approved.":''}</span>
                   
                </div>
                <div className="first-container" style={{marginTop:'23px'}}>
                <span style={{fontWeight:'700',fontSize:'20px'}}>Application Status</span>
                <div className="status-container">
                <div>
                    
                    <div style={{display:'flex',flexDirection:'row'}}><span style={{fontWeight:'700',fontSize:'18px'}}>Your application is currently</span>
                   {_.get(profileDetails,'details.id') ? _.get(profileDetails,'details.approved_status')==false?
                   <span className="review-status"> Under Review</span>:
                   _.get(profileDetails,'details.approved_status')==true?
                   <span className="review-status" style={{backgroundColor:'#307c00'}}>Approved</span>:
                   
                    <span className="review-status"  style={{backgroundColor:'#c72300'}}> Rejected</span>:''}
                    
                    </div>
                </div>
                <span style={{fontSize:14}}>{ _.get(profileDetails,'details.id') ? _.get(profileDetails,'details.approved_status')==false? 
                "You'll receive an e-mail with an update when we have completed our review.":`Press the Continue button below to proceed`:''}</span>
                </div>
</div>
                <div className="first-container-1" style={{marginTop:43}}>
                <span style={{fontWeight:'700',fontSize:'20px'}}>Application Details</span>
               <div className="second-container">
               <div>
                   <span style={{fontWeight:'800'}}>Club or Coach Information
                   </span>

                   <div className="name-details">
                       <span className="name-details-title">First Name</span>
                       <span>{_.startCase(_.get(profileDetails,'details.first_name'))}</span>
                   </div>
                   <div className="name-details">
                       <span className="name-details-title">Last Name</span>
                       <span>{_.startCase(_.get(profileDetails,'details.last_name'))}</span>
                   </div>
                   <div className="name-details">
                   <span className="name-details-title">Email</span>
                       <span>{_.get(profileDetails,'details.contact_details.email')}</span>
                   </div>
                   <div className="name-details">
                   <span className="name-details-title">Phone Number</span>
                       <span>{_.get(profileDetails,'details.contact_details.phone')}</span>
                   </div>
                   </div>
                   <div style={{marginTop:43}}>
                   <span style={{fontWeight:'800'}}>Club or Coach Details
                   </span>

                   <div className="name-details">
                   <span className="name-details-title">Confirm if you are a Club or Coach</span>
                       <span>{_.startCase(_.get(profileDetails,'details.business_type'))}</span>
                   </div>
                   <div className="name-details">
                   <span className="name-details-title">Confirm the sport you are listing on Communiti</span>
                       <span>{_.startCase(_.get(profileDetails,'details.categories[0].name'))}</span>
                   </div>
                   <div className="name-details">
                   <span className="name-details-title">Display Name</span>
                       <span>{_.startCase(_.get(profileDetails,'details.display_name'))}</span>
                   </div>
                   <div className="name-details">
                   <span className="name-details-title">Invite Code</span>
                       <span>{_.get(profileDetails,'details.invite_code')?"The invite code has been successfully added to your application":'--'}</span>
                   </div>
                   </div>
                   </div>
                </div>
               
            </div>
            <div   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"flex-end",display:'flex',marginTop:'5%'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button 
            disabled={_.get(profileDetails,'details.approved_status')==true?false:true}
            onClick={()=>{
                if( _.get(profileDetails,'details.approved_status')==true &&  _.get(profileDetails,'details.business_type')=="coordinator"){
                    history('/communiti/coachOnboard')
                }else{
                    _.get(profileDetails,'details.approved_status')==true && history('/communiti/coach-onboard-setup')
                }
            }} 
            style={{backgroundColor: _.get(profileDetails,'details.approved_status')==true?"#e85f25":'#a7a7a7'}}
            className='form-btn-continue'
            
            >Continue</Button>
            {/* </div> */}
            </div>
            {/* <div style={{    padding:"10px 69px",color: "#fff"}}>Apply</div></div> */}
            
            
           
        </div>)
    }

    return render()
}
const mapStateToProps = state => {
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),
       guestUserProfile: _.get(state, 'app.guest.profile.data.details')
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  
   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(CoachInfoApplication)
