import { EditFilled,DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React,{useState,useEffect} from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, InputNumber,Modal} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { set } from 'lodash';
import { connect } from 'react-redux'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import './style.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
const { Panel } = Collapse;
const {confirm} =Modal
const TemplateTab =(props)=>{
    const [form] = Form.useForm();
    let history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [address_results, setaddress_results] = useState([]);
    const [address_fetching, setaddress_fetching] = useState(false);
    const [photo_display, setphoto_display] = useState(null);
    const [photo_display_details, setphoto_display_details] = useState({});
    const [clauseTitle, setclauseTitle] = useState('')
    const [clauseDes, setclauseDes] = useState('')
    const [clauseObject, setclauseObject] = useState({})
    const [clauseArray, setclauseArray] = useState([])
    const [pageLoading, setPageLoading] = useState(false);
    const [clauseInputShow, setClauseInputShow] = useState(true);
    const [fileList, setFileList] = useState([])
    const [buttonLoading,setButtonLoading] =useState(false)
    const [templateUpdateDetails, setTemplateUpdateDetails] = useState({})

    const fetchPostalCode = address_components => {
        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        return postal_code;
    };
    
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 50);
    };
    const removeImage = info => {
      if(_.get(info,'id')){
        if(_.get(templateUpdateDetails,'id')){
          props.deletePhoto({
            params:{id:_.get(templateUpdateDetails,'id')},
            body:{ids:[_.get(info,'id')]}
            ,callback:(res,data)=>{
              if(res){
  
              }
            }
          })
        }
        
      }
      
    };

    useEffect(() => {
      loadTemplateList()
    }, [])
    
    const loadTemplateList=()=>{
        props.templateList({
            params:{ sort: 'created_at.desc',expand:'photos'},
            callback:(res,data)=>{
    
    if(res==true && _.get(data,'data.items[0]')){
    
    let a = _.pick(_.get(data,'data.items[0]'),['title','description','address','max_no_of_openings',
    'host_price','zip_code','clauses','mode','category','min_no_of_openings'])
    
      setTemplateUpdateDetails({..._.get(data,'data.items[0]')})
      const address_results = [{
        text: _.get(a, 'address'),
        value: JSON.stringify({ text: _.get(a, 'address'), lat: _.get(a, 'location.x'), lng: _.get(a, 'location.y'), postal_code: '' }),
    }]
    form.setFieldsValue({
        location: JSON.stringify({ text:  _.get(a, 'address'), lat:  _.get(a, 'location.x'), lng:  _.get(a, 'location.y'), postal_code: '' }),
    })

    setaddress_results(address_results)
      form.setFieldsValue({
        ...a
      })
      if(_.get(a,'clauses[0]')){
        setclauseArray(_.get(a,'clauses'))
        setClauseInputShow(false)
      }
      if(_.get(data,'data.items[0].photos[0]')){
        let set_photos =[]
      _.map(_.get(data,'data.items[0].photos'),(value,index)=>{
        let make_url =`${_.get(data,'data.meta.photo.path')}/${_.get(data,'data.meta.photo.folder')}/${_.get(data,'data.meta.photo.sizes.medium')}/${_.get(value,'photo')}`
      
        set_photos.push( {
          uid: _.get(value,'id'),
          id: _.get(value,'id'),
          name: _.get(value,'photo'),
          status: 'done',
          url: make_url,
        },)
      })
      
      form.setFieldsValue({
        photo:set_photos
      })
      setFileList(set_photos)
      }
        }    }
        })
    }
    
    const getBase64 = async (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const checkImageWidth = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
              resolve({width:image.width,height:image.height});
              return {width:image.width,height:image.height};
            };
            reader.onerror = (err) => reject(err);
          };
        });
      };
      const isJson=(str)=> {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      }
      const updatePhoto = (data) => {
        return new Promise(resolve => {
        if (_.get(photo_display_details, 'originFileObj')) {
            let formData = new FormData()
            formData.append('photo', _.get(photo_display_details, 'originFileObj'))
            props.UpdatePhotoData({
                body: formData,
                callback: (res, responseData) => {
                    
                    if (res == true) {
                        // notification.success(
                        //     { message: 'Success', description: _.get(responseData, 'message') }
                        // )  
                    }
                        resolve();
                }
            });
        }
        else{
            resolve();
        }
    })
    };
    const onChangePhoto = ({ fileList: newFileList })  => {
      setFileList(newFileList)
      form.setFieldsValue({
        photo:newFileList
      })
        // let details = await checkImageWidth(info.file.originFileObj);
        // if(_.chain(details).get('width').eq(1024).value() && _.chain(details).get('height').eq(1024).value()){
        //     getBase64(info.file.originFileObj, (imageUrl) => {
        //       setphoto_display_details(info.file)  
        //       setphoto_display(imageUrl);
        //     });
        // }else{
        //     notification.warning({
        //         message:"Team Featured Photo",
        //         description:'Please select the 512x512 dimensions image.'
        //     })
        // }
    };
    const onFinish =(e)=>{
        
        let request_details={...e}
    if (isJson(_.get(e,'address'))) {
      let location_parse = JSON.parse(_.get(e,'address'))
      request_details.location=`${_.get(location_parse,'lat')},${_.get(location_parse,'lng')}`
      request_details.address = _.get(location_parse,'text')
      request_details.zip_code = _.get(location_parse,'postal_code')
    }
    delete request_details['email']
    request_details['clauses']=clauseArray
    request_details['mode']=1
    request_details['category']=_.get(props,'userProfile.categories[0]')
    request_details.min_no_of_openings=1
    setButtonLoading(true)
    let url = _.get(templateUpdateDetails,'id')?'updateTemplate':'createTemplate'
    
    if(_.get(templateUpdateDetails,'id')){
      request_details.id=_.get(templateUpdateDetails,'id')
      request_details['price']=_.get(request_details,'host_price')
    }
    props[url]({
      body:{...request_details},
      callback:(res,data)=>{
    
    if (_.get(data,'details.id')) {
      setTemplateUpdateDetails({..._.get(data,'details')})
      if(_.get(e,'photo')){
        addPhotoTemplateAPI(_.get(e,'photo'),_.get(data,'details.id'))
      }else{
        notification.success({
          message:'Success',
          description: `Template has been ${_.get(templateUpdateDetails,'id')?'updated':'created'} successfully.`
       
         })
         props.onChangeTab('3')
      }
     
    
    }
    setButtonLoading(false)
      }
    })
    
      }

      // const addPhotoTemplateAPI =(photo,id)=>{

      //   _.map(photo,(value,index)=>{
      //     let formData = new FormData()
      //     // formData.append('id', id)
      //     formData.append('photo', _.get(value, 'originFileObj'))
      //     props.addPhotoTemplate({
      //       body:formData,
      //       params:{id:id},
      //       callback:(res,data)=>{
      //         if(res==true && _.size(photo)==index+1){
      //           notification.success({
      //             message:'Success',
      //             description:'Template has been created successfully.'
               
      //            })
      //            props.onChangeTab('3')
      //           //  history('/communiti/coach-onboard-complete')
      //         }
      //       }
      //     })
      //   })
        
      // }
      const addPhotoTemplateAPI =(photo,id)=>{
        let final_photos =[]
                if(_.get(templateUpdateDetails,'id')){
        _.map(photo,(value,i)=>{
          if(!_.get(value,'id')){
            final_photos.push(value)
          }
        })
        }else{
          _.map(photo,(value,i)=>{
            
              final_photos.push(value)
          })
        }
        if(_.size(final_photos)==0){
          notification.success({
            message:'Success',
            description: `Template has been ${_.get(templateUpdateDetails,'id')?'updated':'created'} successfully.`
         
           })
           props.onChangeTab('3')
         
        }else{
          updateImageAPI(final_photos,id)
                // _.map(final_photos,(value,index)=>{
                //   setTimeout(() => {
                //     let formData = new FormData()
                //   // formData.append('id', id)
                //   formData.append('photo', _.get(value, 'originFileObj'))
                //   props.addPhotoTemplate({
                //     body:formData,
                //     params:{id:id},
                //     callback:(res,data)=>{
                //       if(res==true && _.size(final_photos)==index+1){
                //         notification.success({
                //           message:'Success',
                //           description: `Template has been ${_.get(templateUpdateDetails,'id')?'updated':'created'} successfully.`
                       
                //          })
                //          props.onChangeTab('3')
                //         //  history('/communiti/coach-onboard-complete')
                //       }
                //     }
                //   })
                // }, 800);
                  
                // })
              }
              }
              const updateImageAPI =(photos,id)=>{
                
                if(_.isArray(photos)==true && _.size(photos)>0){
                  let a =_.head(photos)
                  updateImage(a,id,photos)
                }else{
                  notification.success({
                    message:'Success',
                    description: `Template has been ${_.get(templateUpdateDetails,'id')?'updated':'created'} successfully.`
                 
                   })
                   props.onChangeTab('3')
                }
              
              }
        
              const updateImage =(value,id,photos)=>{
               let p =[...photos]
               
                  let formData = new FormData()
                // formData.append('id', id)
                formData.append('photo', _.get(value, 'originFileObj'))
                props.addPhotoTemplate({
                  body:formData,
                  params:{id:id},
                  callback:(res,data)=>{
                    if(res==true){
                     
                      // var pulled =photos.shift()
                      let a =p.slice(1,_.size(p))
                      
        
                      updateImageAPI(_.size(p)>0?a:[],id)
                    }
                   
                  }
                })
               
              }
    
       //Address search
       const fetchAddress = value => {
        setaddress_results([])
        setaddress_fetching(true);
        geocodeByAddress(value).then(body => {
            
            const address_results = _.map(body, location => ({
                text: location.formatted_address,
                value: JSON.stringify({ text: location.formatted_address, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
            }));
    
            setaddress_results(address_results)
            setaddress_fetching(false);
    
        }).catch(error => {
            setaddress_results([])
            setaddress_fetching(true);
        });
    };
    //Initializing debounce for address
    const addressDelayedQuery = _.debounce(q => fetchAddress(q), 1200);
    

    const setClauseDetails =()=>{
        let details =[...clauseArray]

        if(clauseTitle && clauseDes){
          let duplicate = _.some(details,{name:clauseTitle,description:clauseDes})      
          if(duplicate==false) {
            details.push({name:clauseTitle,description:clauseDes})
          }else{
            notification.error({
              message:'Error',
              description:'This clauses title and description is already taken.'
            })
          }
            
          }
            
setclauseObject({})
            setclauseDes('')
            setclauseTitle('')
            setclauseArray(details)
            setClauseInputShow(false)
        }
       
    

    const onEditClasuess =async(value, type,index)=>{

if(type=="edit"){
  setclauseObject(value)
  setclauseTitle(_.get(value,'name'))
  setclauseDes(_.get(value,'description'))
let remove = _.remove(clauseArray,(e,i)=>i==index)

  setclauseArray(clauseArray)
  
  setClauseInputShow(true)
}else{
  let details =[...clauseArray]
  let remove = _.remove(details,(e,i)=>i==index)
  setclauseArray(details)   

  setTimeout(() => {
    setclauseArray(details)   
  }, 200);
 

}


    }

    const loadClausesList =(data)=>{
      
      return  _.map(data,(value,index)=>{
return <div key={index}>
   
   <div className="name-details">
       <span className="name-details-title">Clauses Title</span>
       <span>{_.get(value,'name')}</span>
   </div>
   <div className="name-details">
   <span className="name-details-title">Clause Description
</span>
       <span>{_.get(value,'description')}</span>
   </div>
   <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
  <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>onEditClasuess(value,'edit',index)}>
    <EditFilled size={23}  style={{marginRight:7,fontSize:20}}/> Edit</div> 
   <div onClick={()=>onEditClasuess(value,'delete',index)}  style={{display:'flex',alignItems:'center',cursor:'pointer'}}><DeleteFilled 
    size={23}   style={{marginLeft:23,marginRight:7,fontSize:20}} /> Delete</div> 
   </div>
   
   </div>
        })
    }

    const render =()=>{
        const uploadButton = (
            <div>
               <FileImageOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          );
        return (
        <div >
            <Form  form={form} onFinishFailed={(e)=>console.log('onFinishFailed',e)} onFinish={(e)=>onFinish(e)}>
            <div className="coach-info-conatiner-first">
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'900',fontSize:'27px'}}>Template</span>
                   
                </div>
                <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
   Session or program title
</div>
<Form.Item name='title'   rules={[
          {
            required: true,
            message: 'Please input your title',
          },
        ]}><Input onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          
          }
        }} /></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Session or program Description
</div><Form.Item name='description'   rules={[
          {
            required: true,
            message: 'Please input your description',
          },
        ]}><TextArea /></Form.Item>


                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Session or program Address
</div>
<Form.Item
                                name="address"
                                rules={[{ required: true, message: 'Please input your address' }]}
                            >
<Select getPopupContainer={trigger => trigger.parentNode}
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    onSearch={search => search ? addressDelayedQuery(search) : ''}
                                    //   onChange={(e) => onChangeForm(e, 'address')}
                                    placeholder={'Search address...'}
                                    notFoundContent={
                                        address_fetching ? (
                                            <Spin size="small" />
                                        ) : null
                                    }
                                >
                                    {address_results && address_results.length > 0 && address_results.map(d => (
                                        <Select.Option key={d.value}>{d.text}</Select.Option>
                                    ))}
                                </Select>
                                </Form.Item>

                    </div>
                </div>
                </div>
</div>
{/* <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Minimum Number of attendess per session or program</div>
<Form.Item name='min_no_of_openings'   rules={[
          {
            required: true,
            message: 'Please input your min no of openings',
          },
        ]}><InputNumber /></Form.Item>

                    </div>
                </div>
                </div>
</div> */}
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Maximum Number of attendess per session or program</div>
<Form.Item name='max_no_of_openings'   rules={[
          {
            required: true,
            message: 'Please input your Max no of openings',
          },
        ]}><InputNumber onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          
          }
        }} /></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Price per person</div>
<Form.Item name='host_price'   rules={[
          {
            required: true,
            message: 'Please input your price',
          },
        ]}><InputNumber onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          
          }
        }}/></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div style={{marginTop:'34px'}}>
<span style={{fontWeight:'700',fontSize:'20px'}}>Clause Details</span></div>
                {clauseArray &&_.size(clauseArray)>0 &&<div > <div className="first-container-1" style={{marginTop:43}}>
<div className="second-container">
<span style={{fontWeight:'800'}}>Agreement Clauses
   </span>

 {loadClausesList(clauseArray)}</div> </div>{<div onClick={()=>setClauseInputShow(true)} className='add-another-container'><PlusSquareFilled style={{fontSize:23,marginRight:7}}/><span>Add Another</span></div>}</div>}
 <div className="first-container-1" style={{marginTop:43}}>
                
               
               {(_.size(clauseArray)==0 || clauseInputShow) && <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Clause title
</div>
<div><Input onKeyPress={(e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    
    }
  }} value={clauseTitle} onChange={(e)=>setclauseTitle(_.get(e,'target.value'))} /></div>

                    </div>
                </div>
                </div>
</div>}
{(_.size(clauseArray)==0 || clauseInputShow) &&<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Clause Description
</div>
<div><TextArea value={clauseDes} onChange={(e)=>setclauseDes(_.get(e,'target.value'))} /></div>

                    </div>
                </div>
                </div>
                <div   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"end",display:'flex',marginTop:'5%'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button className='form-btn-4'
            //  onClick={()=>props.onChangeTab('3')}
             onClick={()=>setClauseDetails()}
            //  htmlType="submit"
             >{_.isEmpty(clauseObject)?"Add":'Update'} </Button>
              {!_.isEmpty(clauseObject) &&<Button className='form-btn-4'
              style={{backgroundColor:'#ababab',marginLeft:15}}
            //  onClick={()=>props.onChangeTab('3')}
             onClick={()=>setClauseDetails()}
            //  htmlType="submit"
             >Cancel</Button>}
            {/* </div> */}
            </div>
</div>
    }
                </div>
               
                <div className="first-container-1" style={{marginTop:43}}>
                <span style={{fontWeight:'700',fontSize:'20px'}}>Session Or Program Pictures</span>
               
                <Form.Item name='photo' className='upload-template-image'   rules={[
          {
            required: false,
            message: 'Please input your photo',
          },
        ]}> <Upload
      
        name="avatar"
        accept=".jpg,.jpeg"
        listType="picture-card"
        className="avatar-uploader"
        fileList={fileList}
        onRemove={removeImage}
        onChange={onChangePhoto}
        customRequest={dummyRequest}
    >
        {photo_display ? <img  src={photo_display} alt="avatar" style={{ width: '100%',height:'100%', borderRadius: '20px' }} /> : uploadButton}

    </Upload>
</Form.Item>
                  
                </div>
               
            </div>
            <Form.Item   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"end",display:'flex',marginTop:'5%'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
           <div>  <Button className='form-btn-4-back'
           style={{backgroundColor:'#a7a7a7'}}
            // loading={buttonLoading}
            disabled={buttonLoading}
            
             onClick={()=>props.onChangeTab('1')}
            //  htmlType="submit"
             >Back</Button>
            <Button className='form-btn-4'
            loading={buttonLoading}
            //  onClick={()=>props.onChangeTab('3')}
             htmlType="submit"
             >Continue</Button></div>
            {/* </div> */}
            </Form.Item>
            </Form>
        </div>)
    }

    return render()
}
const mapStateToProps = state => {
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),
       guestUserProfile: _.get(state, 'app.guest.profile.data.details')
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
    list: details => dispatch(global.redux.action.categories.list(details)),
    hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
    guestUpdateProfile: details => dispatch(global.redux.action.guest.update(details)),
    updatePhone: details => dispatch(global.redux.action.guest.updatePhone(details)),
    UpdatePhotoData: details => dispatch(global.redux.action.host.updatePhoto(details)),
    createTemplate: details => dispatch(global.redux.action.template.create(details)),
    addPhotoTemplate: details => dispatch(global.redux.action.template.addPhoto(details)),
    stripe_onboard_connect: details =>
    dispatch(global.redux.action.account.stripeOnboardWeb(details)),
    templateList: details => dispatch(global.redux.action.template.list(details)),
    updateTemplate: details => dispatch(global.redux.action.template.update(details)),
    deletePhoto: details => dispatch(global.redux.action.template.deletePhoto(details)),

   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(TemplateTab)