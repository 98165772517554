import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateOtpLoginState;
// guest actions

export default {
    checkProfile: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/accounts/check-profile`
                },
                actionType: actionType.otpLogin.checkProfile,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    sendOtp: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/oauth/otp`
                },
                actionType: actionType.otpLogin.sendOtp,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}