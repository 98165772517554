import React from 'react';
import { FallingLines, MutatingDots, ThreeDots } from 'react-loader-spinner'

import '../style.css';

const CommonLoader = () => {
  return (
    <div className="spinner-overlay">
    <div className="spinner-container">
    <ThreeDots
visible={true}
height="80"
width="80"
color="#EF6820"
radius="9"
ariaLabel="three-dots-loading"
wrapperStyle={{}}
wrapperClass=""
/>
    </div>
  </div>
  )
}

export default CommonLoader