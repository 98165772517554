import { EditFilled,DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, PlusSquareOutlined, ClockCircleFilled } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React,{useState,useEffect} from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, InputNumber,Modal, Table, Image} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { set } from 'lodash';
import { connect } from 'react-redux';
import {IoArchiveOutline} from 'react-icons/io5'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { CiSquarePlus } from "react-icons/ci";
import { LuCalendarPlus } from "react-icons/lu";
import { FiEdit } from "react-icons/fi";
import { LuArchive } from "react-icons/lu";
import { FiPlusCircle } from "react-icons/fi";

import '../../onboarding-screen/style.css'
import '../style.css'
import MainMenu from '../../menu'
import { useNavigate, useSearchParams } from 'react-router-dom'
const { Panel } = Collapse;
const TemplateList =(props)=>{
    const [form] = Form.useForm();
    let history = useNavigate();
    const [AllTemplateListData, setAllTemplateListData] = useState([])
    const [AllTemplateListMeta, setAllTemplateListMeta] = useState({})
    const [pageLoading, setPageLoading] = useState(true)
    const [page, setpage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getArchiveId, setArchiveId] = useState();
    const [searchedData, setSearchedData] = useState('');

    useEffect(() => {
            
            if(props.searchFilter.length > 0){
                setSearchedData(props.searchFilter);
                handleSearch();
            } else {
                loadTemplateList();
            }
            
            
    }, [props.searchFilter]);

    const showModal = (value) => {
        setArchiveId(value)
        setIsModalOpen(true);
      };

      const handleOk = () => {
        archiveTemplate(getArchiveId)
        
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

    const titleStyle = {
        color: 'var(--Gray-600, #475467)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px', /* 142.857% */
        cursor: "pointer"
      };
 
let column = [
    {
title:'Template name',
key:'title',
dataIndex:'title',
width:'40%'

},
{
    title:'Template type',
    key:'template_type',
    dataIndex:'template_type',
    width:'20%'
    
    },
{
    title:'Quick Actions',
    width:'40%',
    key:'action',
    dataIndex:'action',
    render: (text, item) => {
        
        return(
        <div className="agent-list-action-btns" style={{display:'flex',flexDirection:'row',alignItems:'center', justifyContent: "space-between"}}>
        <div  onClick={()=>history(`/template-list?menu=template&type=2`)}  style={{alignItems:'center',cursor:'pointer'}}><LuCalendarPlus color='#475467' style={{height: "16px", width: "16px", marginBottom: "4px", fontWeight: 600, marginRight: "4px"}} /><span style={titleStyle}>Schedule</span></div>
        <div onClick={()=>{history(`/template-list?menu=template&id=${_.get(item,'key')}&template_status=active&menu=template`);
                    props?.onClickButton()
            }}><FiEdit color='#475467' style={{height: "16px", width: "16px",  marginBottom: "4px", marginRight: "4px"}} /><span  style={titleStyle}>Edit</span></div>
                 <div style={titleStyle} onClick={() => showModal(_.get(item,'key'))} ><LuArchive style={{ marginRight: "4px", cursor: "pointer", height: "16px", width: "16px",  marginBottom: "3px"}} />Archive</div>
        
    </div>
        )
    }
       
    ,
    
    },
]

let dataSource =[{
    title:'Test 1'
},
{
    title:'Test 2'
},
{
    title:'Test 3'
},
{
    title:'Test 4'
}]
 
useEffect(() => {
  loadTemplateList()
  props.setTemplateActiveFunction({
    value: loadTemplateList
  })
}, []);

const handleSearch = () => {
    searchFilterTemplate();
};

const searchFilterTemplate = () => {
    setPageLoading(true);
    props.templateList({
        params:{
            search: searchedData,
            sort: 'created_at.desc',
            status: 1,
            limit: 500
        },
        callback: (res, data) => {
            setPageLoading(false)
            if(res){
                let a =[]

            _.map(_.get(data,'data.items'),(value)=>{
                
                a.push({
                    key:_.get(value,'id'),
                    title: _.get(value, 'title'),
                    template_type: _.startCase(_.get(value, 'type'))
                })
            })
            setAllTemplateListData(a)
            }
        }
    })
}

const loadTemplateList=(value)=>{
    setPageLoading(true);
    let request_params = {};
    request_params.sort =  'created_at.desc';
    request_params.status = 1;
    request_params.limit = 500;
    
    props.templateList({
        params:request_params,
        callback:(res,data)=>{

if(res==true){
    let a =[]

_.map(_.get(data,'data.items'),(value)=>{
    
    a.push({
        key:_.get(value,'id'),
        title: _.get(value, 'title'),
        template_type: _.startCase(_.get(value, 'type'))
    })
})
setAllTemplateListData(a)
setAllTemplateListMeta(_.get(data,'data.meta'))
}
setPageLoading(false)
        }
    })
};

 const archiveButtonStyle = {
    height: '44px',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    background: 'var(--Orange-500, #EF6820)',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: '20px',
    marginLeft: "12px",
    borderRadius: "8px"
  };

  const cancelBtnStyle = {
    height: '44px',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    background: "#FFFFFF",
    cursor: 'pointer',
    outline: 'none',
    color: "#475467",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: '20px',
    border: '1px solid var(--Gray-300, #D0D5DD)',
    borderRadius: "8px"
  };


const archiveTemplate = (id) => {
    if(id){
        props.archiveActiveTemplates({
            body:{
                id: parseInt(id),
                status: 0,
                status_glossary:"Archived"
            }, 
            callback: (res, data) => {
                if(res){
                    setIsModalOpen(false);
                    notification.success({
                        message: "Success",
                        description: "Archived successfully"
                    });
                    loadTemplateList();
                }
            }
        })
    }
};

let locale = {
    emptyText: (
     <div style={{marginTop:'32px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <div>
<Image preview={false} style={{height:'128px',width:'172px'}} src={require('../../../assets/img/template/Illustration.png')} />
      </div>
      <div className="empty-template-title" style={{marginTop:'16px',marginBottom:'8px'}}>No templates found</div>
      <div className="empty-template-title-des" style={{width:'45%'}}>Templates serve as a blueprint to quickly schedule sessions . Get started by clicking the button below and create your first template</div>
      <div>
      {/* <Button styles={{ icon: { marginRight: '-8px' } }} onClick={()=>{
      props?.onClickButton()
    }}
             icon={<FiPlusCircle style={{ height: '24px', width: '24px' }}
            />} style={{
              //    paddingTop: '6px', paddingBottom: '7px',
              height: '44px'
              ,marginTop:'32px',
              backgroundColor: '#EF6820' , color:  '#fff' , fontWeight: 600,
            }}>Create a new template</Button> */}
            <button onClick={()=>{
      props?.onClickButton()
    }} className='create_new_template_at_bottom_table'> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_10995_851)">
    <path d="M9.99996 6.6665V13.3332M6.66663 9.99984H13.3333M18.3333 9.99984C18.3333 14.6022 14.6023 18.3332 9.99996 18.3332C5.39759 18.3332 1.66663 14.6022 1.66663 9.99984C1.66663 5.39746 5.39759 1.6665 9.99996 1.6665C14.6023 1.6665 18.3333 5.39746 18.3333 9.99984Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10995_851">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>Create a new template</button>
      </div>
     </div>
    )
  };

    const render =()=>{
        
        
        return (<>
            <div>
                
        <div>
{/* <div className='template-list-title'>
    <span style={{fontSize:'22px',fontWeight:'600'}}>Active Templates</span>
    <Button style={{color: "#fff", backgroundColor: "#e85f2f", borderRadius: "40px", height: "50px"}} onClick={()=>history('/communiti/template-create?menu=template')}><CiSquarePlus style={{height: "20px", width: "20px"}}/>Create</Button>
</div> */}
<div>
<Table locale={locale} loading={pageLoading} columns={column} dataSource={AllTemplateListData} pagination={false} /></div>
        </div>
          <Modal centered={true} 
        closable = {false}
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        footer={[
            <div style={{padding: "24px 24px 0px 24px"}}>
            <button style={cancelBtnStyle} onClick={handleCancel}>Cancel</button>
            <button style={archiveButtonStyle} onClick={() => handleOk()} >Archive template</button>
            </div>
          ]}
        >
            <div style={{padding: "24px 24px 0px 24px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <img style={{height: "48px", width: "48px"}} src={require('../../../assets/img/archive_alert_icon.png')}/>
                    <img onClick={() => handleCancel()} style={{height: "44px", width: "44px", cursor: "pointer"}} src={require('../../../assets/img/template/modal_close_icon.png')}/>
                </div>
                <div style={{marginTop: "16px", color: "#101828", fontSize: "18px", fontWeight: 600, lineHeight: "28px"}}>
                Are you sure you want to archive this template?
                </div>
                <div style={{color: "#475467", fontSize: "14px", fontWeight: 400, lineHeight: "20px", marginTop: '4px'}}>This template will disappear from your active templates list once archived.</div>
            </div>
      </Modal>
        </div></>)
    }

    return render()
}
const mapStateToProps = state => {
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    list: details => dispatch(global.redux.action.categories.list(details)),
    templateList: details => dispatch(global.redux.action.template.list(details)),
    archiveActiveTemplates: details => dispatch(global.redux.action.template.changeStatusTemplate(details)),
    setTemplateActiveFunction: details =>
    dispatch(global.redux.action.settings.setTemplateFuncActive(details))
    
   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(TemplateList)