import React, { useState, useEffect } from 'react';
import { Table, Button, Image } from 'antd';
import { FiPlusCircle } from "react-icons/fi";
import { connect } from 'react-redux';
import _ from  'lodash';
import moment from 'moment';
import AssignQuestionSetList from './assign-question-set-list';

import './style.css';
import { useNavigate } from 'react-router-dom';

const QuestionList = (props) => {
  let history = useNavigate();
  const[questionSetList, setQuestionList] = useState([]);
  const[assignQuestion, setAssignQuestion] = useState(false);
  const[getQuestionSetId, setQuestionSetId] = useState();
  const [getQuestionName, setQuestionName] = useState('')

  useEffect(() => {
    getQuestionsSetList();
  }, []);

  
  const columns = [
    {
      title: <div className='questions-list-table-title'>Question set name</div>,
      dataIndex: 'question_set_name',
      key: 'question_set_name',
    },
    {
      title: <div className='questions-list-table-title'>Created on</div>,
      dataIndex: 'created_on',
      key: 'created_on',
    },
    {
      title: <div className='questions-list-table-title'>No of questions</div>,
      dataIndex: 'no_of_questions',
      key: 'no_of_questions',
    },
    {
      title: <div className='questions-list-table-title'>Quick Actions</div>,
      dataIndex: 'actions',
      key: 'actions',
      render: (text, item) => {
        
        return(
          <div style={{display: "flex", flexDirection: "row"}}>
            <div onClick={() => assignQuestionSet(item)} className='question-set-quick-actions'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8985_3742)">
    <path d="M8.00016 5.33337V10.6667M5.3335 8.00004H10.6668M14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8985_3742">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg> Assign</div>
            <div 
            onClick={()=>{
              history(`/template-list?menu=template&question_id=${item?.key}`)
              props?.onCreate()
            }}
            style={{marginLeft: "32px"}} className='question-set-quick-actions'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_8985_3749)">
              <path d="M7.3335 2.66666H4.5335C3.41339 2.66666 2.85334 2.66666 2.42552 2.88464C2.04919 3.07639 1.74323 3.38235 1.55148 3.75867C1.3335 4.1865 1.3335 4.74655 1.3335 5.86666V11.4667C1.3335 12.5868 1.3335 13.1468 1.55148 13.5746C1.74323 13.951 2.04919 14.2569 2.42552 14.4487C2.85334 14.6667 3.41339 14.6667 4.5335 14.6667H10.1335C11.2536 14.6667 11.8137 14.6667 12.2415 14.4487C12.6178 14.2569 12.9238 13.951 13.1155 13.5746C13.3335 13.1468 13.3335 12.5868 13.3335 11.4667V8.66666M5.33348 10.6667H6.44984C6.77596 10.6667 6.93902 10.6667 7.09247 10.6298C7.22852 10.5972 7.35858 10.5433 7.47788 10.4702C7.61243 10.3877 7.72773 10.2724 7.95833 10.0418L14.3335 3.66666C14.8858 3.11437 14.8858 2.21894 14.3335 1.66666C13.7812 1.11437 12.8858 1.11437 12.3335 1.66665L5.95832 8.04182C5.72772 8.27242 5.61241 8.38772 5.52996 8.52228C5.45685 8.64157 5.40298 8.77163 5.37032 8.90768C5.33348 9.06113 5.33348 9.22419 5.33348 9.55031V10.6667Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_8985_3749">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg> Edit</div>
            </div>
        )
      }
    },
  ];

  const CreateQuestionSetIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
  );

  const getQuestionsSetList = () => {
      props.questionsSetListAPI({
        params:{
          limit: 25
        },
        callback: (res, data) => {
          
          if(res){
            if(_.size(_.get(data, 'data.items')) > 0){
              // setQuestionList(_.get(data, 'data.items'));
              let a =[]

              _.map(_.get(data,'data.items'),(value)=>{
                  a.push({
                      key:_.get(value,'id'),
                      id:_.get(value,'id'),
                      question_name: _.get(value, 'label'),
                      question_set_name: <div className='questions-list-table-title'>{_.get(value, 'label')}</div>,
                      created_on: <div className='questions-list-table-title'>{ moment(_.get(value, 'created_at')).format('MMM Do, YYYY [at] h:mmA') }</div>,
                      no_of_questions: <div className='questions-list-table-title'>{_.get(value, 'no_of_questions')}</div>
                  })
              })
              setQuestionList(a)
            }
          }
        }
      })
  }

  let locale = {
    emptyText: (
     <div style={{marginTop:'32px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <div>
<Image preview={false} style={{height:'128px',width:'172px'}} src={require('../../assets/img/template/Illustration.png')} />
      </div>
      <div className="empty-template-title" style={{marginTop:'16px',marginBottom:'8px'}}>No question set found</div>
      <div className="empty-template-title-des" style={{width:'45%'}}>Question sets help you organize your listing with standard questions. Get started by clicking the button below</div>
      <div style={{marginTop: "32px"}}>
          <button onClick={()=>{
             history(`/template-list?menu=template`)
              props?.onCreate()
            }} className='create-question-set-btn'><CreateQuestionSetIcon/> Create a question set</button>
        </div>
     </div>
    )
  };

  const assignQuestionSet = (value) => {
    
    setQuestionSetId(_.get(value, 'id'))
    setAssignQuestion(true);
    setQuestionName(_.get(value, 'question_name'));
  };

  const render = () => {
    return (
      <div>
        { assignQuestion &&  <div className='header-class-card-new' style={{ display: 'flex',justifyContent:'space-between',paddingLeft:'-4px',paddingBottom:'16px' }}>
            <div className="create-template-container">
               <Image onClick={()=>{
               setAssignQuestion(false)
                  props?.onBack() 
           


                     }} preview={false} src={require('../../assets/img/template/back.png')} style={{width:'48px',height:'48px',cursor:'pointer'}}/>
               <div className="create-template-header-title" style={{marginLeft:'6px'}}>Question assign list</div>
            </div>
           
            </div>}
        <div className='card' style={{padding: "16px", marginTop: "16px", borderRadius: "12px", marginBottom: "40px"}}>
          { !assignQuestion && <div>
          <div>
            <button onClick={()=>{
              history(`/template-list?menu=template`)
              props?.onCreate()
            }} className='create-question-set-btn'><CreateQuestionSetIcon/> Create a question set</button>
          </div>
          <div style={{marginTop: "16px"}}>
                <Table locale={locale} pagination={false} dataSource={questionSetList} columns={columns} />
          </div>
          </div>}
          {
            assignQuestion && <AssignQuestionSetList OnBackFunction={()=>props?.onBack()} questionSetId={getQuestionSetId} questionName={getQuestionName}/>
          }
        </div>
      </div>
    )
  }
  return render();
}

const mapStateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => ({
  questionsSetListAPI: details => dispatch(global.redux.action.questions.questionSetsList(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(QuestionList)