import { EditFilled,DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, ArrowLeftOutlined, SaveFilled, ClockCircleFilled, CalendarFilled, MailFilled, ArrowRightOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React,{useState,useEffect} from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, InputNumber,Modal, Table} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { set } from 'lodash';
import { connect } from 'react-redux'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ViewRegisteredInfo from '../view-registered-screen'
import '../../onboarding-screen/style.css'
import '../style.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'

import chatKittyInstance from '../../../core_units/chat-system/chatkitty/instance';

const { Panel } = Collapse;
const {confirm} =Modal
const { Option } = Select;

const CompletedUsers =(props)=>{
    
  
    const [form] = Form.useForm();
    let history = useNavigate();
    const [AllTemplateListData, setAllTemplateListData] = useState([])
    const [AllTemplateListMeta, setAllTemplateListMeta] = useState({})
    const [pageLoading, setPageLoading] = useState(false)
    const [page, setpage] = useState(1)
    const [usersList, setUsersList] = useState([])
    const [UsersListMeta, setUsersListMeta] = useState({})
    const [scheduleModal, setScheduleModal] = useState(true)
    const [scheduleSelectId, setScheduleSelectId] = useState('')
    const [refundModal, setRefundModal] = useState(false)
const [showPreviewRefundModal, setShowPreviewRefundModal] = useState(false)
const [viewModal, setViewModal] = useState(false)
const [removeAllowLate, setRemoveAllowLate] = useState('1')
const [amountType, setAmountType] = useState('1')
const [refundAmount, setRefundAmount] = useState('')
const [UsersDetails, setUsersDetails] = useState({})
const [singleOrderDetails, setSingleOrderDetails] = useState({});

const [isModalOpen, setIsModalOpen] = useState(false);
const [getPurchaseDetails, setPurchaseDetails] = useState({});

let column = [
    {
title:'First Name',
key:'first_name',
dataIndex:'first_name',},
{
title:'Last Name',
    key:'last_name',
    dataIndex:'last_name',
},  
{
 title:'Date Of Birth',
key:'date_of_birth',
dataIndex:'date_of_birth',
},
// {
//     title:'Status',
//    key:'status',
//    dataIndex:'status',
//    },
{
    title:'Action',
    // width:'60%',
    key:'action',
    dataIndex:'action',
    render: (text, item) => {
      
      return(
        <div className="agent-list-action-btns" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <div   style={{alignItems:'center',marginRight:'20px',cursor:'pointer'}} onClick={() => messageToHost(item)}><MailFilled color='#a7a7a7' style={{marginRight:'12px'}} /><span>Message</span></div>
            {/* <div onClick={()=>{setRefundModal(true); setRefundAmount(_.get(item,'price_amount'));setSingleOrderDetails(_.get(item,'order_details'))}}  style={{alignItems:'center',marginRight:'20px',cursor:_.get(item,'refund')==0? 'pointer':'not-allowed'}}><DeleteFilled color='#a7a7a7' style={{marginRight:'12px'}} /><span>Remove / Refund</span></div> */}
            <div onClick={async()=>{await setUsersDetails({..._.get(item,'order_details.guest'),relations:_.get(item,'relations')});setSingleOrderDetails(_.get(item,'order_details'));setViewModal(true);}}><span><span style={{cursor:'pointer'}}><EyeOutlined style={{marginRight:"7px"}}/>View</span></span></div>
            {/* <div onClick={() => showModal(item)} style={{marginLeft: "20px"}}><span><span style={{cursor:'pointer'}}>Mark as Complete</span></span></div> */}
            
        </div>
      )
    },
    
    },
]


const headers = [
  {
name:'First Name',
key:'first_name',
},
{
name:'Last Name',
  key:'last_name',
},  
{
name:'Date Of Birth',
key:'date_of_birth',
},
// {
// name:'Status',
//  key:'status',
//  },

]


 
useEffect(() => {
if(_.get(props,'exp_id')){
    orderList(_.get(props,'exp_id'))
}
}, []);

const showModal = (value) => {
  setIsModalOpen(true);
  setPurchaseDetails(value)
};

const handleOk = () => {
  props.markAsComplete({
    body:{
        id: _.get(getPurchaseDetails, ''),
        is_completed:true,
    }, 
    callback: (response, data) => {
        if(response){
            notification.success({
                message:"Success",
                description:"Marked as Completed"
            })
        }
    }
})
  setIsModalOpen(false);

};

const handleCancel = () => {
  setIsModalOpen(false);
};

const messageToHost = async(value) => {

  

  let guest_chat_id = _.get(value, 'order_details.guest.chatkitty_user_id');

  if(_.isEmpty(guest_chat_id)){
    notification.error({
      message: "The guest has not yet registered for the chat or else could not find the guest chat ID."
    })
  } else {
    notification.success({
      message: "Redirecting to one to one conversation"
    });
    const result = await chatKittyInstance.createChannel({
      type: "DIRECT",
      members: [{ id: guest_chat_id }],
  });
    
  if (result.succeeded) {
      const channel = result.channel; // Handle channel
      
      history('/communiti/chats-notification', {state:{
        data: channel
      }})
  }
    
  }

}

const finalCallback=(response, data)=>{
  // callback(response, data);
  
  if(response){
      
  }
};



const orderList =(id)=>{
  setPageLoading(true)
    props.orderList({
      params:{experience:id,user_type:'1',expand:'guest,experience,experience_template',paid:1, is_completed:1},
      callback:(res,data)=>{
        if(res==true){
          setRegisterList(_.get(data,'data.items',[]))
          
        }
        setPageLoading(false)
      }
    })
  }

  const setRegisterList =(data)=>{
    let a =[]
    _.map(data,(value,index)=>{
      

      a.push({
        key:_.get(value,'id'),
        first_name:_.startCase(_.get(value,'relations[0].first_name')),
        last_name:_.startCase(_.get(value,'relations[0].last_name')),
        date_of_birth:_.get(value,'relations[0].dob')!="Invalid date"?moment(_.get(value,'relations[0].dob')).format('MMMM D, YYYY'):'--',
        status:_.get(value, 'status') === 1 ? <span className='table-paid-status'>Active</span> :  _.get(value, 'status') === 4 ? <span className='table-status-cancelled'>Cancelled</span> : '--',
        refund:_.get(value,'refund'),
        details:_.get(value,'total'),
        price_amount:_.get(value,'total'),
        refund_amount:_.get(value,'refund'),
        order_details:value,
        relations:_.size(_.get(value,'relations')) && _.isArray(_.get(value,'relations')) ?_.head(_.get(value,'relations')):{}

      })
    })
    setUsersList(a)
  }

const loadTemplateList=()=>{
    setPageLoading(true)
    props.templateList({
        params:{ sort: 'created_at.desc'},
        callback:(res,data)=>{

if(res==true && _.get(data,'data.items[0]')){
setAllTemplateListData(_.get(data,'data.items'))
setAllTemplateListMeta(_.get(data,'data.meta'))
}
setPageLoading(false)
        }
    })
}


const onContinue =()=>{
    if(scheduleSelectId){
history(`/communiti/schedule?id=${scheduleSelectId}`)
    }
}

const refundsubmit =()=>{
  let details ={refund:true,
    refund_amount:refundAmount,
    id:_.get(singleOrderDetails,'id')
  }
  if(removeAllowLate==1){
    details.status=4
    details.cancellation_glossary='Canncelled by host'

  }

  props.refund({
    body:details,
    callback:(res,data)=>{
      
      setShowPreviewRefundModal(false)
    }
  })
}
const openChat =async(id)=>{
  if(id){
      const result = await chatKittyInstance.getChannel(id);
      if (result.succeeded) {
          const channel = result.channel; // Handle channel
          
          history('/communiti/chats-notification', {state:{
            data: channel
          }})
      }
  }
  
}

    const render =()=>{
      let locale = {
        emptyText: 'You currently have no registrations for this session.',
      };
        return (
        <div className="coach-full-conatiner" style={{display:'flex',flexDirection:'column',marginTop:'-3%',padding:'28px 135px'}}>
            <ViewRegisteredInfo is_start={_.get(props,'is_start')} extraDetails={singleOrderDetails} usersDetails={UsersDetails}  viewModal={viewModal} onStateChange={(e)=>setViewModal(e)}/>
              <Modal centered={true} maskClosable
                 onCancel={()=>setRefundModal(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={refundModal}>
                  <div style={{    display: 'flex',
    flexDirection: 'column',marginBottom:'4%',
    alignItems: 'center',}}>
                  <span style={{    fontSize: '29px',fontWeight: '700',marginBottom:'2%',}}>Please confirm the amount you wish to refund</span>
                <InputNumber max={parseFloat(_.get(singleOrderDetails,'total'))} disabled={amountType==1?true:false} value={refundAmount} onChange={(e)=>{setRefundAmount(e)}} className='refund-modal-input' placeholder='Enter Amount' />
                  
                  </div>
                  <div style={{ display: 'flex',
    justifyContent: 'center',marginBottom:'2%',
    marginTop: '32px'}}>   
    <Button  onClick={()=>{setAmountType('1'); setRefundAmount(_.get(singleOrderDetails,'total'))}}  className='unsaved-btn-cancel' style={{backgroundColor:amountType=="1"?'#e85f25':"#a7a7a7",marginRight:23,width:'23%'}}
             >Full Amount</Button>
                  {_.get(props,'is_start')==true &&<Button onClick={()=>{setAmountType('2'); 
                  setRefundAmount(parseFloat(_.get(singleOrderDetails,'total')) - parseFloat(_.get(singleOrderDetails,'refund')))}}   className='unsaved-btn' style={{backgroundColor:amountType=="2"?'#e85f25':"#a7a7a7",width:'23% !important'}}
             >Custom</Button>}
             </div>
             <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'700',fontSize:'25px'}}>Do you also wish to remove their registration?</span>
                   
                </div>
                <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
    <Button onClick={()=>setRemoveAllowLate('1')}  className='unsaved-btn-cancel' style={{backgroundColor: removeAllowLate=="1"?'#e85f25':"#a7a7a7",marginRight:23,width:'23%'}}
             >Yes</Button>
                  <Button onClick={()=>setRemoveAllowLate('2')}  className='unsaved-btn' style={{backgroundColor:removeAllowLate=="2"?'#e85f25':"#a7a7a7",width:'23% !important'}}
             >No</Button></div>
                

<div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
    <Button onClick={()=>setRefundModal(false)} className='unsaved-btn-cancel' style={{backgroundColor:'#a7a7a7',marginRight:23,width:'23%'}}
             ><ArrowLeftOutlined />Back</Button>
                  <Button  onClick={()=>{setRefundModal(false); setShowPreviewRefundModal(true)}} className='form-btn-4 unsaved-btn' style={{backgroundColor:'#e85f25',width:'23% !important'}}
             >Continue<ArrowRightOutlined /> </Button></div>
                  </Modal>

                    <Modal centered={true} maskClosable
                 onCancel={()=>setShowPreviewRefundModal(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={showPreviewRefundModal}>
                  <div style={{    display: 'flex',
    flexDirection: 'column',marginBottom:'4%',
    alignItems: 'center',}}>
                  <span style={{    fontSize: '29px',fontWeight: '700',marginBottom:'2%',}}>Please confirm the amount you wish to refund</span>
                  <div  className='preview-selected-date-refund'><span  className='preview-selected-date-name-refund'>${refundAmount}</span></div>
                  
                  </div>
             <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <span style={{fontWeight:'700',fontSize:'25px'}}>Do you also wish to remove their registration?</span>
                   
                </div>
                <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
    <Button   className='unsaved-btn-cancel-refund' style={{backgroundColor: removeAllowLate=="1"?"#e85f25":"#a7a7a7",marginRight:23,width:'23%',color:removeAllowLate=="1"?"white":'#a7a7a7'}}
             >Yes</Button>
                  <Button   className='unsaved-btn' style={{backgroundColor:removeAllowLate=="2"?"#e85f25":"#a7a7a7",width:'23% !important',color:removeAllowLate=="2"?"white":'#a7a7a7'}}
             >No</Button></div>
                

<div style={{ display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '32px'}}>   
    <Button onClick={()=>setShowPreviewRefundModal(false)} className='unsaved-btn-cancel' style={{backgroundColor:'#a7a7a7',marginRight:23,width:'23%'}}
             ><ArrowLeftOutlined />Back</Button>
                  <Button  onClick={()=>{ refundsubmit()}} className='form-btn-4 unsaved-btn' style={{backgroundColor:'#e85f25',width:'23% !important'}}
             >Refund<ArrowRightOutlined /> </Button></div>
                  </Modal>
<div className='template-list-title' style={{width:'100%'}}>
    <span style={{fontSize:'22px',fontWeight:'600'}}>Completed Registrations</span>
    {/* {_.size(usersList)>0 &&<div className='manage-active-tab-btn'> */}
    {/* <Button 
    onClick={()=>openChat(_.get(props,'chatkitty_channel_id'))} 
    className='form-btn-4-csv'><MailFilled style={{marginTop:'-5px'}} /> Message All</Button> */}
    {/* <ExportJsonCsv style={{border:'none'}} className='form-btn-4-csv' headers={headers} items={usersList}><DownloadOutlined style={{marginTop:'-3px'}} /> Export to .csv</ExportJsonCsv> */}
    {/* </div>} */}
    
</div>
<div className='template-list-table' >
<Table locale={locale} loading={pageLoading} columns={column} dataSource={usersList} pagination={false} /></div>

<div>
  <Modal centered={true} title="Please confirm you wish to mark the registration as complete" 
open={isModalOpen}
 onOk={handleOk} 
 onCancel={handleCancel}
 footer={[
  <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Mark as Complete
          </Button>
 ]}
 >
        <p>You are marking the following registration as complete:</p>
            <div>
        <div className='purchase-modal' style={{backgroundColor:'#e9e9e9', paddingBottom:"70px", paddingTop:"10px"}}>
        <div style={{marginTop:"10px"}}>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>First Name:</p>
          <p style={{marginLeft:"10px"}}>{_.get(getPurchaseDetails, 'first_name')}</p>
         </div>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>Last Name:</p>
          <p style={{marginLeft:"10px"}}>{_.get(getPurchaseDetails, 'last_name')}</p>
         </div>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>Session Purchased:</p>
          <p style={{marginLeft:"10px"}}>{_.get(getPurchaseDetails, 'order_details.experience.template.title')}</p>
         </div>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>Date of Birth:</p>
          <p style={{marginLeft:"10px"}}>{_.get(getPurchaseDetails, 'date_of_birth')}</p>
         </div>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>Date of Purchase:</p>
          <p style={{marginLeft:"10px"}}>{moment(_.get(getPurchaseDetails, 'order_details.created_at')).format('LL')}</p>
         </div>
         </div>
        </div>
        </div>
      </Modal>
</div>
        </div>)
    }

    return render()
}
const mapStateToProps = state => {
    
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),

    }
  }
  
  const mapDispatchToProps = dispatch => ({
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    templateList: details => dispatch(global.redux.action.template.list(details)),
    list: details => dispatch(global.redux.action.experience.list(details)),
    orderList: details => dispatch(global.redux.action.experience.orderList(details)),
    refund: details => dispatch(global.redux.action.experience.refund(details)), 
    markAsComplete: details => dispatch(global.redux.action.experience.completeOrder(details)),
    
   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(CompletedUsers)