import React, { useState, useEffect } from 'react';
import { Button, Table, Tag, Modal, Input, Select, Tabs, Rate, Progress, Image } from 'antd';
import _ from 'lodash';
import { connect } from "react-redux";
import moment from 'moment';

import ProfileInfo from '../profile';
import MainMenu from '../menu';
import './style.css';

const ref = React.createRef();
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const RatingReviews = (props) => {

  const [ratingListDataItems, setRatingListDataItems] = useState([]);

  useEffect(() => {
    getRatingList();
  }, [])

  const getRatingList = () => {

    let requestParams = {
      sort: 'created_at.desc',
      expand: 'host,guest,top_tags,experience'
    }

    props.ratingListApi({
      params: {
        sort: 'created_at.desc',
        expand: 'host,guest,top_tags,experience',
        limit: 1000,
        host: _.get(props, 'host_details.details.id')
      },
      callback: (response, data) => {
        if (response) {

          setRatingListDataItems(data)
        }
      }
    });
  }

  const onChange = (key) => {

  };

  const render = () => {
    return (

      <div>
        {/* <MainMenu reviewTab={true} /> */}
        {/* <div className="coach-full-conatiner"> */}
        {/* <Tabs defaultActiveKey="1" onChange={onChange}> */}
        {/* <TabPane tab="Reviews" key="1"> */}
        <div className='reviews-list-title'>
          <span style={{ fontSize: '22px', fontWeight: '600' }}>Reviews</span>
        </div>
       {_.size(_.get(ratingListDataItems, 'data.items')) > 0 && <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div className='rating-container'>
            <div className='rating-left-container'>
              <div style={{ display: "flex" }}>
                <h1 className='average-rating'>{_.size(_.get(ratingListDataItems, 'data.items')) > 0 ? parseFloat(_.get(ratingListDataItems, 'data.items[0].host.average_ratings'), 0).toFixed(1) : "No data"}<sub className='average-rating-denominator'>/5</sub></h1>
              </div>
              <div className='average-rating-denominator full-rating'><Rate disabled={true} allowHalf value={parseFloat(_.get(ratingListDataItems, 'data.items[0].host.average_ratings'), 0).toFixed(1)} /></div>
              <div className='rating-left-container-right-progress'>
              </div>
            </div>
            {/* <div className='rating-progress-container'>
     <div className='rating-counts'>
      <span className='rating-numbers'>5</span>
      <span className='rating-progress-stars'><Rate count={1} value={1}/></span>
      <span className='rating-progress-bar' style={{width: "200px"}}><Progress value={10} status="active"/></span>
     </div>
     <div className='rating-counts'>
      <span className='rating-numbers'>4</span>
      <span className='rating-progress-stars'><Rate count={1} value={1}/></span>
      <span className='rating-progress-bar' style={{width: "200px"}}><Progress percent={30} status="active"/></span>
     </div>
     <div className='rating-counts'>
      <span className='rating-numbers'>3</span>
      <span className='rating-progress-stars'><Rate count={1} value={1}/></span>
      <span className='rating-progress-bar' style={{width: "200px"}}><Progress percent={30} status="active"/></span>
     </div>
     <div className='rating-counts'>
      <span className='rating-numbers'>2</span>
      <span className='rating-progress-stars'><Rate count={1} value={1}/></span>
      <span className='rating-progress-bar' style={{width: "200px"}}><Progress percent={30} status="active"/></span>
     </div>
     <div className='rating-counts'>
      <span className='rating-numbers'>1</span>
      <span className='rating-progress-stars'><Rate count={1} value={1}/></span>
      <span className='rating-progress-bar' style={{width: "200px"}}><Progress percent={30} status="active"/></span>
     </div>
    </div> */}
          </div>
          <div className='tags-rating-container'>
            <span style={{ fontWeight: 800 }}>Top Tags</span>
            <div className='rating-top-tags'>
              {_.size(_.get(ratingListDataItems, 'data.details.top_tags')) > 0 ? _.get(ratingListDataItems, 'data.details.top_tags').map((value, index) => {
                return (
                  <span className='top-tags-detail'>{_.get(value, 'tag')}</span>
                )
              }) : <span>No data</span>}
            </div>
          </div>
        </div>}
        {_.size(_.get(ratingListDataItems, 'data.items')) > 0 ? _.get(ratingListDataItems, 'data.items').map((value, index) => {
          let size_of = [];
          let size_of2 = []
          size_of.push(
            parseFloat(_.get(value, 'host.average_ratings'), 0).toFixed(1)
          )
          return (
            <div className='rating-list-container'>
              <div className='rating-list-left-container'>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Image src={_.get(value, 'guest.photo') === "default.png" ? require('../../assets/img/push-and-app-icon-1.png') : `${_.get(ratingListDataItems, 'data.meta.photo.path')}/${_.get(ratingListDataItems, 'data.meta.photo.folders.guest')}/${_.get(ratingListDataItems, 'data.meta.photo.sizes.medium')}/${_.get(value, 'guest.photo')}`} style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                  <div className='rating-user-container'>
                    <span style={{ fontWeight: 800, marginLeft: "10px" }}>{_.startCase(_.get(value, 'guest.first_name'))}</span>
                    <span style={{ marginLeft: "10px" }}><Rate disabled={true} value={_.get(value, 'star_rating')} /></span>
                  </div>
                </div>
                <div className='rating-message'>
                  {_.get(value, 'message')}
                </div>
                <div className='rating-message-tag'>
                  {_.get(value, 'tags[0]')}
                </div>
              </div>
              <div className='rating-list-right-container'>
                {moment(_.get(value, 'created_at')).format("MMM DD, YYYY")}
              </div>
            </div>
          )
        }) :<div className='no-data-review'> <span>No reviews yet</span></div>}
        {/* </TabPane> */}
        {/* </Tabs> */}
        {/* </div> */}
      </div>


    );
  }
  return render();
}

const mapStateToProps = state => {

  return {
    host_details: _.get(state, 'app.host.profile.data')
  }
}

const mapDispatchToProps = dispatch => ({
  ratingListApi: details => dispatch(global.redux.action.finances.ratingList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RatingReviews)