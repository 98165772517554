import { UpOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React from 'react'
import {Collapse} from 'antd'

import './style.css'
import { useNavigate } from 'react-router-dom'
const { Panel } = Collapse;

const CoachInfoLanding =(props)=>{
	let history = useNavigate();


    const render =()=>{
        return (
        <div className="coach-full-conatiner">
            <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                <span style={{fontSize:'33px',fontWeight:'500',marginBottom:'13px'}}>Complete your onboarding</span>
                <div>
                <div className='info-container'>
                <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">1</span> 
                 </div><span>Fill in your coaching or club profile.</span></div>
                 {/* <div className='info-container'>
                <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">2</span> 
                 </div><span>Fill in your first session template.</span></div> */}
                 <div className='info-container'>
                <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">2</span> 
                 </div><span>Fill in your bank information.</span></div>
                 </div>
                <div  
            className="btn-inner-container" onClick={()=>history('/communiti/coach-onboard')} style={{backgroundColor:"#e85f24",marginLeft:'60%',cursor:'pointer',justifyContent:'center'}}> <div style={{    padding:"6px 59px",color: "#fff"}}>Next</div></div>
   </div>
            

            
        </div>)
    }

    return render()
}

export default CoachInfoLanding