import appPages from './app-pages';

export default [

    {
        key: "termsCondition",
        element: <appPages.TermsService />,
        path: '/communiti/onboarding',
        exact: true
    },
    {
        key: "coachInfoLanding",
        element: <appPages.CoachInfoLanding />,
        path: '/communiti/coach-info-landing',
        exact: true
    },
    {
        key: "coachInformation",
        element: <appPages.CoachInfoApplication />,
        path: '/communiti/coach-information',
        exact: true
    },
    {
        key: "coachInformationSuccess",
        element: <appPages.ApplicationSuccess />,
        path: '/communiti/coach-information-success',
        exact: true
    },
    {
        key: "coachOnboardSetUp",
        element: <appPages.coachOnboardLanding />,
        path: '/communiti/coach-onboard-setup',
        exact: true
    },
    {
        
        key: "coachOnboard",
        element: <appPages.Onboarding />,
        path: '/communiti/coach-onboard',
        exact: true
    },
    {
        key: "coachOnboardComplete",
        element: <appPages.coachOnboardComplete />,
        path: '/communiti/coach-onboard-complete',
        exact: true
    },
    {
        key: "coachProfile",
        element: <appPages.Profile />,
        path: '/communiti/coach-profile',
        exact: true
    },
    {
        key: "/privacy-policy",
        element: <appPages.CoachInfoLanding />,
        path: '/communiti/coach-info-landing',
        exact: true
    },

    //Template
    {
        key: "TemplateList",
        element: <appPages.TemplateList />,
        path: '/communiti/template-list',
        exact: true
    },
    {
        key: "TemplateCreate",
        element: <appPages.TemplateCreate />,
        path: '/communiti/template-create',
        exact: true
    },
    {
        key: "TemplateView",
        element: <appPages.TemplateView />,
        path: '/communiti/template-view',
        exact: true
    },
    {
        key: "Manage",
        element: <appPages.Manage />,
        path: '/communiti/manage',
        exact: true
    },
    {
        key: "schedule",
        element: <appPages.Schedule />,
        path: '/communiti/schedule',
        exact: true
    },
    {
        key: "FinancesList",
        element: <appPages.FinancesList />,
        path: '/communiti/customer',
        exact: true
    },
    {
        key: "ReviewsList",
        element: <appPages.RatingReviews />,
        path: '/communiti/reviews',
        exact: true
    },
    {
        key: "ChatsAndNotification",
        element: <appPages.ChatsAndNotification />,
        path: '/communiti/chats-notification',
        exact: true
    },
    {
        key: "MainMenu",
        element: <appPages.MainMenu />,
        path: '/communiti/main-menu',
        exact: true
    },
    {
        key: "OpenSessionCreate",
        element: <appPages.OpenSessionCreate />,
        path: '/communiti/open-session/create',
        exact: true
    },
    {
        key: "TestSocket",
        element: <appPages.TestSocket />,
        path: '/communiti/test/socket',
        exact: true
    },
    {
        key: "IntegratedTab",
        element: <appPages.IntegratedTab />,
        path: '/communiti/integrated-tabs',
        exact: true
    },
    {
        key: "FacilitateSession",
        element: <appPages.FacilitateSession />,
        path: '/communiti/facilitate-session',
        exact: true
    },
    {
        key: "IndividualOnsiteFacilitation",
        element: <appPages.IndividualOnsiteFacilitation />,
        path: '/communiti/individual-onsite-facilitation',
        exact: true
    },
    {
        key: "coordinatorErrorMessage",
        element: <appPages.coordinatorErrorMessage />,
        path: '/communiti/info',
        exact: true
    },
    {
        key: "TemplatesTab",
        element: <appPages.TemplatesTab />,
        path: '/communiti/templates-tab',
        exact: true
    },
    {
        key: "CustomersList",
        element: <appPages.CustomersList />,
        path: '/communiti/finances-list',
        exact: true
    },
    {
        key: "ViewProfile",
        element: <appPages.ViewProfile />,
        path: '/communiti/view-profile',
        exact: true
    },
    {
        key: "ViewAccount",
        element: <appPages.ViewAccount />,
        path: '/communiti/view-account',
        exact: true
    },
    {
        key: "ComponentMenu",
        element: <appPages.Menu />,
        path: '/communiti/component-menu',
        exact: true
    },
    {
        key: "HostProfile",
        element: <appPages.HostProfile />,
        path: '/communiti/host',
        exact: true
    },
    {
        key: "TemplateFlow",
        element: <appPages.TemplateFlow />,
        path: '/template-list',

    },{
        key: "EditExperiences",
        element: <appPages.EditExperiences />,
        path: '/edit-experience',
        exact: true
    },
    {
        key: "ViewArchivedTemplate",
        element: <appPages.ViewArchivedTemplate />,
        path: '/view-template',
        exact: true
    },
    
    
];