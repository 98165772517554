import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LeftContainer from './container/left'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RightContainer from './container/right';
import { useEffect, useState } from 'react';
import StepperFlow from './container/step-flow';
import PersonalInfo from './container/personal-info';
import ClubCoachInfo from './container/club-coach-info';
import AccountReviewApproved from './container/account-review-approved';
import ConnectStripe from './container/connect-stripe';
import AccountSuccess from './container/account-success';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import CommonLoader from './container/common-loader';
import "./style.css";
const OnboardingFlow =(props)=>{
  const [searchParams, setSearchParams] = useSearchParams();
  const [screenFlow, setScreenFlow] = useState(1);
  const [loading, setLoading] = useState(false);

const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
const [formDetails, setformDetails] = useState({});
const [userDetails, setuserDetails] = useState({});
  useEffect(() => {
    
    
    if(_.get(props,"hostProfile.id")){
      
      callHostProfile()
    }else if(_.size(_.get(props,"islogindata.types"))>1 && _.includes(_.get(props,"islogindata.types"),1)){
     
      callHostProfile()
    }
  
    
  }, [])

  const callHostProfile=()=>{
    setLoading(true)
    props?.hostProfileData({
      params:{
        expand:"categories",
      },
      callback:(status,response)=>{
        
        setLoading(false)
        if(_.get(response,"data.details.stripe_user_id")){
          setScreenFlow(3)
        }else{
          if(status==true && _.get(response,"data.details.id")){
            // if(_.get(response,"data.details.approved_status")==false?4:1)
            
            setuserDetails(_.get(response,"data.details"))
            
            setScreenFlow(4)
          
            
          }
        }

      }
      
     })
    }
  useEffect(() => {
    if(searchParams.get('code')){
    ConnecttoStripe(searchParams.get('code'))
    }
  }, []);

  const ConnecttoStripe =(code) =>{
    setLoading(true)
 
    props.stripe_onboard_connect({
      body:{code:code},
      callback:(res,data)=>{
        if(res==true){
          setScreenFlow(6)
          // props.hostProfileData({
            
          //   callback:(res,data)=>{
          //     setLoading(false);
          //    if(_.get(data,'data.details')){
          // // history('/communiti/coach-onboard-complete')
          // props?.onChangeFlow(6);

          //    }}})
          
        }else{
          setScreenFlow(6)
        }
      }
    })
  }
  
       const Item = styled(Paper)(({ theme,backgroundColor }) => ({
              backgroundColor: backgroundColor,
              ...theme.typography.body2,
              padding: theme.spacing(1),
              textAlign: 'center',
              height: "calc(100vh - 2px)",
              color: theme.palette.text.secondary,
              boxShadow:"none",
            }));
            
       return <Box sx={{ width: '100%' }}>
       <Grid container >

        {loading==true &&
            <CommonLoader/>
        }

         {!isMobile &&<Grid  xs={6} >
          <Item xs={6}  backgroundColor="var(--Gray-50, #F9FAFB)">
            {screenFlow==1?<LeftContainer />:<StepperFlow screenFlow={screenFlow} />}
             </Item>
         </Grid>}
         <Grid xs={isMobile?12: 6}>
           <Item backgroundColor="var(--Base-White, #FFF);">
{screenFlow==1?<RightContainer onChangeFlow={(value)=>{
  
setScreenFlow(value)
}}/>: screenFlow==2?<PersonalInfo onChangeFlow={(value,details)=>{
  
  setformDetails(details)
  setScreenFlow(value)
}}  />:screenFlow==3?<ClubCoachInfo personalInfo={formDetails} onChangeFlow={(value,details)=>{
  setuserDetails(details);
setScreenFlow(value)
}} />:screenFlow==4?<AccountReviewApproved userDetails={userDetails} onChangeFlow={(value)=>{
  
setScreenFlow(value)
}} />:screenFlow==5?<ConnectStripe onChangeFlow={(value)=>{
  
setScreenFlow(value)
}} />:<AccountSuccess onChangeFlow={(value)=>{

setScreenFlow(value)
}} />}
           </Item>
         </Grid>
         
       </Grid>
     </Box>
}


const mapStateToProps = state => {
  
  return {
     checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
     isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
     islogindata: _.get(state, 'app.account.isLogin'),
     hostProfile: _.get(state, 'app.host.profile.data.details')?_.get(state, 'app.host.profile.data.details'):_.get(state, 'app.host.profile.details'),
     guestProfile: _.get(state, 'app.guest.profile.data.details')

  }
}

const mapDispatchToProps = dispatch => ({
  stripe_onboard_connect: details => dispatch(global.redux.action.account.stripeOnboardWeb(details)),
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
  updatePhone: details => dispatch(global.redux.action.guest.updatePhone(details)),
  hostCreate: details => dispatch(global.redux.action.host.hostCreate(details)),


 
});
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingFlow)
