import React, {useState, useEffect} from 'react';
import { Table, Image, Modal } from 'antd';
import { connect } from 'react-redux';
import _ from  'lodash';
import moment from 'moment';

import "./promo.css";
import AssignPromoCode from './assign-promocode';

const AssignList = (props) => {

    const  [dataSource, setDataSource] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [experiencesForAssign, setExperiencesForAssign] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        experienceListByPromotions();
        handleListByGroupForAssign();
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
        handleListByGroupForAssign();
      };
      const handleOk = () => {
        setIsModalOpen(false);
        experienceListByPromotions();
        handleListByGroupForAssign();
      };
      const handleCancel = () => {
        setIsModalOpen(false);
        experienceListByPromotions();
        handleListByGroupForAssign();
      };



    const columns = [
        {
          title: <div className='assign_table_txt'>Name</div>,
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: <div className='assign_table_txt'>Type</div>,
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: <div className='assign_table_txt'>Start date/end date</div>,
          dataIndex: 'start_end_date',
          key: 'start_end_date',
        },
        {
            title: <div className='assign_table_txt'>Actions</div>,
            dataIndex: 'actions',
            key: 'actions',
            render: (text, item) => {
                
              return(
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <div onClick={() => handleUnAssign(_.get(item, 'id'))} className='remove_assigned_coupon'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3333 5.99984L7.33333 9.99984M7.33333 5.99984L11.3333 9.99984M1.81333 8.63984L4.69333 12.4798C4.928 12.7927 5.04533 12.9492 5.19403 13.062C5.32574 13.1619 5.47488 13.2365 5.63385 13.2819C5.81334 13.3332 6.00889 13.3332 6.4 13.3332H11.4667C12.5868 13.3332 13.1468 13.3332 13.5746 13.1152C13.951 12.9234 14.2569 12.6175 14.4487 12.2412C14.6667 11.8133 14.6667 11.2533 14.6667 10.1332V5.8665C14.6667 4.7464 14.6667 4.18635 14.4487 3.75852C14.2569 3.3822 13.951 3.07624 13.5746 2.88449C13.1468 2.6665 12.5868 2.6665 11.4667 2.6665H6.4C6.00889 2.6665 5.81334 2.6665 5.63385 2.71776C5.47488 2.76317 5.32574 2.83774 5.19403 2.93767C5.04533 3.05051 4.928 3.20695 4.69333 3.51984L1.81333 7.35984C1.6412 7.58934 1.55514 7.7041 1.52196 7.83013C1.49268 7.94137 1.49268 8.0583 1.52196 8.16955C1.55514 8.29558 1.6412 8.41033 1.81333 8.63984Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg> Remove</div>
                </div>
              )
            }
          },
      ];

      let locale = {
        emptyText: (
         <div style={{marginTop:'32px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <div>
    <Image preview={false} style={{height:'128px',width:'172px'}} src={require('../../assets/img/template/Illustration.png')} />
          </div>
          <div className='empty_state_images_text'>No sessions assigned</div>
          <div className='empty_state_images_supporting_text'>Assign sessions to this promotion by clicking the<br/> button below and selecting the sessions you want<br/> assigned to this promotion</div>
          <div style={{marginTop: "32px"}}>
          <button onClick={() => showModal()}  className='assign_promotion_btn'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> Assign sessions</button>
          </div>
         </div>
        )
      };

      const handleListByGroupForAssign = () => {
        props.promotionsListByGroupAPI({
          params:{
            group_by: 'coupon',
            sort: 'created_at.desc',
            host: _.get(props, 'userProfile.id'),
            status: 1,
            limit: 500,
            expand: 'coupons'
        },
        callback: (res, data) => {
          
          if(res){
            if(_.size(_.get(data, 'data.items')) > 0){
              setExperiencesForAssign(_.get(data, 'data.items'))
            }
          }
        }
        })
      }

      const experienceListByPromotions = () => {
        setLoading(true)
            props.promotionsListByGroupAPI({
                params:{
                    coupon_id: _.get(props, 'coupon_id'),
                    group_by: 'coupon',
                    sort: 'created_at.desc',
                    host: _.get(props, 'userProfile.id'),
                    status: 1,
                    limit: 500,
                    expand: 'coupons'
                },
                callback: (res, data) => {
                  setLoading(false)
                    if(res){
                        if(_.size(_.get(data, 'data.items')) > 0){
                            const experiences = _.get(data, 'data.items').reduce((acc, currentItem) => {
                                // If experiences array exists and is not null, concatenate it
                                if (currentItem.experiences && currentItem.experiences.length > 0) {
                                    // Add title to each experience object
                                    const experiencesWithTitle = currentItem.experiences.map(experience => ({
                                        ...experience,
                                        title: currentItem.title
                                    }));
                                    return acc.concat(experiencesWithTitle);
                                }
                                return acc;
                            }, []);
                            let a =[]

                            _.map(experiences,(value)=>{
                                a.push({
                                    key:_.get(value,'id'),
                                    id: _.get(value,'id'),
                                    name: <div className='assign_table_txt'>{_.get(value, 'title')}</div>,
                                    type: <div className='assign_table_txt'>{_.startCase(_.get(value, 'type'))}</div>,
                                    start_end_date: <div style={{display:"flex", flexDirection:'column'}}><div className='assign_table_txt'>{moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(props, 'userDetails.meta_details.timezone')) ? _.get(props, 'userDetails.meta_details.timezone') : "America/Toronto").format('MMM Do, YYYY')} {_.get(value, 'type') !== 'open' && "-"}  {_.get(value, 'type') !== 'open' && moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(props, 'userDetails.meta_details.timezone')) ? _.get(props, 'userDetails.meta_details.timezone') : "America/Toronto").format('MMM Do, YYYY')}</div>{ _.get(value, 'type') !== "open" && <div className='assign_table_txt'>{moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(props, 'userDetails.meta_details.timezone')) ? _.get(props, 'userDetails.meta_details.timezone') : "America/Toronto").format('hh:mm A')} - {moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(props, 'userDetails.meta_details.timezone')) ? _.get(props, 'userDetails.meta_details.timezone') : "America/Toronto").format('hh:mm A')}</div>}</div>
                                })
                            })
                            setDataSource(a)
                        } else {
                          setDataSource([])
                        }
                    }
                }
            })
      };

      const handleUnAssign = (value) => {
        props.promotionsUnassignAPI({
            params:{
                id: _.get(props, 'coupon_id'),
            },
            body:{
                experience_ids: [value],
                action: 'unassign'
            },
            callback: (res, data) => {
                
                if(res){
                    if(_.get(data, 'success') === true){
                        experienceListByPromotions();
                        handleListByGroupForAssign();
                    }
                    
                }
            }
        })
      };

      const handleBack = () => {
        props.clickBackBtn()
      }

    const render = () => {
        return (
            <div>
                <div className='assign_list_card'>
        
                        <div>
                          <div style={{display: 'flex', flexDirection: "row"}}>
                            <div onClick={() => handleBack()} className='promotion_back_btn'><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M15.8332 10H4.1665M4.1665 10L9.99984 15.8334M4.1665 10L9.99984 4.16669" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg></div>
                            <div style={{marginTop: "10px", marginLeft: "8px"}} className='assign_promo_name'>{_.get(props, 'coupon_name')}</div>
                            </div>
                            <button onClick={() => showModal()} className='assign_promotion_btn'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> Assign sessions</button>

                            <div style={{marginTop: "16px"}}>
                                <Table loading={loading} pagination={false} locale={locale} columns={columns} dataSource={dataSource}/>
                            </div>
                            <div>
                            <Modal
                             width={1072} 
                             closable = {false} 
                             style={{borderRadius: "12px"}}
                             footer={null}
                             open={isModalOpen} 
                             onOk={handleOk} 
                             onCancel={handleCancel}
                             >
                                <div style={{padding: "32px"}}>
                                <div style={{display: "flex", justifyContent:"space-between"}}>
                                <div className='promotion_assign_title'>Assign sessions to this promotion</div>
                                <img onClick={() => handleCancel()} style={{height: "24px", width: "24px", cursor: "pointer"}} src={require('../../assets/img/template/x-close.png')}/>
                                </div>
                                <div style={{marginTop: "16px"}}>
                                <AssignPromoCode data={experiencesForAssign} userDetails={props.userProfile} props={props} closeModal={() => handleCancel()} promo_id={_.get(props, 'coupon_id')}/>
                                </div>
                                </div>
                              
                            </Modal>
                            </div>
                        </div>
                </div>
            </div>
          )
    };

    return render();
  
}

const mapStateToProps = state => {
    return {
        userProfile: _.get(state, 'app.host.profile.data.details'),
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    promotionsListByGroupAPI: details => dispatch(global.redux.action.promotion.promotionsListByGroup(details)),
    promotionsUnassignAPI: details => dispatch(global.redux.action.promotion.promotionsUnassign(details)),
    promotionsAssignAPI: details => dispatch(global.redux.action.promotion.promotionsAssign(details)),
    
  });
  export default connect(mapStateToProps, mapDispatchToProps)(AssignList)