import { Tabs, Button, Radio, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import './style.css';
import MainMenu from '../../main-menu';
import TemplateList from '../list'
import ArchivedTemplateList from './archive-list'
 import { connect } from 'react-redux';
 import  ExportJsonCsv  from '../../export-csv/intex';

 import Menus from '../../menu';
 import { FiSearch } from "react-icons/fi"; 
import { RiDownloadLine } from "react-icons/ri";
import {DownloadOutlined} from '@ant-design/icons'
const { TabPane } = Tabs;

const TemplateTabs =(props)=>{

  

  const [activeKey, setActiveKey] = useState('1');
  const [selectedTab, setSelectedTab] = useState('active');
  const [exportData, setExportData] = useState([]);
  const [exportDataArchive, setExportDataArchive] = useState([]);
  const [getSearchFilter, setSearchFilter] = useState('');
  

  const inputContainerStyle = {
    position: 'relative',
    display: 'flex',
    padding: '10px 14px',
    alignItems: 'center',
    gap: 8,
    borderRadius: 8,
    border: '1px solid var(--Gray-300, #D0D5DD)',
    background: 'var(--Base-White, #FFF)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    marginLeft: "48px",
    width: "600px",
    height: "40px",
    width: "100%"
  };
  
  const iconContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: 10,
    transform: 'translateY(-50%)',
  };
  
  const iconStyle = {
    height: "20px",
    width: "20px",
    color: "#667085"
  };
  
  const inputStyle = {
    flex: 1,
    border: 'none',
    outline: 'none',
    background: 'transparent',
    paddingLeft: "20px", // Adding left padding to create space for the placeholder text
    color: "#667085",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  };

  useEffect(() => {
      templateList();
      templateListArchive();
  }, []);

  const handleRadioChange = (e) => {
    
    setSelectedTab(e.target.value);
  };

  const templateList = () => {
    props.templateListAPI({
      params:{
        sort: 'created_at.desc',
         status: 1,
        limit: 1000
      },
      callback: (res, data) => {
        if(res){
          
          let a =[]

          _.map(_.get(data,'data.items'),(value)=>{
              
              a.push({
                  key:_.get(value,'id'),
                  id: _.get(value,'id'),
                  title: _.startCase(_.get(value, 'title')),
                  type: _.startCase(_.get(value, 'type')),
                  status: _.get(value, 'status') === 0 ? "Archived" : "Active"
              })
          })
          setExportData(a)
        }
      }
    })
  };

  const templateListArchive = (value) => {
    props.templateListAPI({
      params:{
        sort: 'created_at.desc',
         status: 0,
        limit: 1000
      },
      callback: (res, data) => {
        if(res){
          
          let a =[]

          _.map(_.get(data,'data.items'),(value)=>{
              
              a.push({
                  key:_.get(value,'id'),
                  id: _.get(value,'id'),
                  title: _.startCase(_.get(value, 'title')),
                  type: _.startCase(_.get(value, 'type')),
                  status: _.get(value, 'status') === 1 ? "Active" : "Archived"
              })
          })
          setExportDataArchive(a);
        }
      }
    })
  }

  const export_csv_data = [
    
      {
        name: 'ID',
        key: 'id',
      },
      {
        name: 'Template Name',
        key: 'title',
      },
      {
        name: 'Template Type',
        key: 'type',
      },
      {
        name: 'Status',
        key: 'status',
      },
    
  ];

  const export_csv_archieve_data = [
    
    {
      name: 'ID',
      key: 'id',
    },
    {
      name: 'Template Name',
      key: 'title',
    },
    {
      name: 'Template Type',
      key: 'type',
    },
    {
      name: 'Status',
      key: 'status',
    },
  
];

const handleSearchTemplate = (e) => {
    
    let lower_case = _.lowerCase(e.target.value)
    
    setSearchFilter(lower_case);
}
    const render =()=>{
        return (
          <div>
            {/* <div className='container'>
            <Menus/>
            <hr/>
            </div> */}
            
            
        {/* <div  className='manage-full-conatiner'>
          <Tabs onChange={handleTabChange}  defaultActiveKey="1" centered  >
        <TabPane tab="Active Templates" key="1" >
           <TemplateList/>
        </TabPane>
        <TabPane tab="Archived Templates" key="2">
            <ArchivedTemplateList />
        </TabPane>
      </Tabs>
      </div> */}
      
      <div>
  <div className='card' style={{padding: "16px", marginTop: "16px", borderRadius: "12px", marginBottom: "40px"}}>
    <div className='col-12' style={{display: "flex", flexDirection: "row"}}>
      <Radio.Group value={selectedTab} onChange={handleRadioChange} defaultValue="active" buttonStyle="solid" >
        <Radio.Button className='custom-radio-button' value="active">Active</Radio.Button>
        <Radio.Button className='custom-radio-button' value="archived">Archived</Radio.Button>
      </Radio.Group>
      <Input className='search_filter_input' prefix={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>} 
              onChange={(e) => handleSearchTemplate(e)} placeholder="Search" 
            />

{ selectedTab === 'active' && <ExportJsonCsv style={{borderRadius: "6px", backgroundColor: "#fff", borderColor: "#D0D5DD", cursor: "pointer",
                  border: '1px solid #d9d9d9', marginLeft: "12px", fontSize: "14px"
              }} fileTitle="Active Template"  headers={export_csv_data} items={exportData}><DownloadOutlined /> Export to .csv</ExportJsonCsv>}
              { selectedTab === 'archived' && <ExportJsonCsv fileTitle="Archived Template" style={{borderRadius: "6px", backgroundColor: "#fff", borderColor: "#D0D5DD", cursor: "pointer",
                  border: '1px solid #d9d9d9', marginLeft: "12px", fontSize: "14px"
              }} headers={export_csv_archieve_data} items={exportDataArchive}><DownloadOutlined /> Export to .csv</ExportJsonCsv>}
    {/* <Button style={{marginLeft: "12px", height: "40px", borderRadius: "8px", color: "#344054", fontSize: "14px", fontWeight: 600, lineHeight: "20px"}}><RiDownloadLine style={{width: "20px", height: "20px"}}/> Export .csv</Button> */}
    </div>
    <hr/> {/* Reset margin and padding */}
    <div style={{marginTop: "8px"}}>
    <button onClick={()=>{
      props?.onClickButton();
    }} className='create-template-btn'><img style={{width: "24px", height: "24px"}} src={require('../../../assets/img/selected_template.png')}/>Create a new template</button>
    </div>
    <div>
      {
        selectedTab === 'active' && (
          <div style={{marginTop: "16px"}}>
          <TemplateList searchFilter={getSearchFilter} onClickButton={()=>props?.onClickButton()}/>
          </div>
        )
      }

      {
          selectedTab === 'archived' && (
            <div style={{marginTop: "16px"}}>
            <ArchivedTemplateList searchFilter={getSearchFilter} onClickButton={()=>props?.onClickButton()}/>
            </div>
          )
      }
    </div>
  </div>
</div>
      
      </div>
      )
    }

    return render();
}
const mapStateToProps = state => {
    
  return {
     userProfile: _.get(state, 'app.host.profile.data.details'),
     hitActiveTemplateFunction: _.get(state, 'app.settings.setTemplateFuncActive'),
     hitArchiveTemplateFunction: _.get(state, 'app.settings.setTemplateFuncArchive'),
  }
}

const mapDispatchToProps = dispatch => ({
  templateListAPI: details => dispatch(global.redux.action.template.list(details)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateTabs)