import { Button, Card, Divider, Image, Modal, Radio, Select, Space, 
        Input, InputNumber, message, Form, Segmented, notification, Popconfirm } from "antd"
    import _, { sortBy } from 'lodash';
    import { connect } from 'react-redux';
    import { FallingLines, MutatingDots, ThreeDots } from 'react-loader-spinner'

    import {  useEffect, useRef, useState } from "react";
    import "react-multi-date-picker/styles/layouts/mobile.css"

    import './style.css'
    import { LuPlusCircle } from "react-icons/lu";
    import { TiEdit } from "react-icons/ti";
    import { RiDeleteBinLine } from "react-icons/ri";
    import { IoMdClose } from "react-icons/io";
    import OutsideClickHandler from 'react-outside-click-handler';

    import { FaRegQuestionCircle } from "react-icons/fa";
import ReactQuill, { Quill } from "react-quill";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import Dragger from "antd/es/upload/Dragger";
import {  DeleteRowOutlined, SaveOutlined } from "@ant-design/icons";


import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
    const { Option } = Select;
    const TemplateCreateStep1 = (props) => {
      let navigate = useNavigate();
      const [questionSetList, setquestionSetList] = useState([]);
      const [CouponList, setCouponList] = useState([]);
      const [questionSetId, setquestionSetId] = useState('');
      const [couponId, setcouponId] = useState([]);

      const [spinnerLoading, setspinnerLoading] = useState(false);
      const [messageApi, contextHolder] = message.useMessage();
    const [templateTypeModal, settemplateTypeModal] = useState({visable:false,type:1})
      const [form] = Form.useForm();
      const [value, setValue] = useState('');
      const [valueAdditionalInfo, setValuAdditionalInfo] = useState('');
      const [valueClasuses, setValueClasuses] = useState('');
      const [country, setcountry] = useState(null)
      const [address_results, setaddress_results] = useState([]);
      const reactQuillRef = useRef();
      const reactQuillAditioninfoRef = useRef();
      const reactQuillClasusesRef = useRef();
      const [address, setAddress] = useState('');
      const [address_fetching, setaddress_fetching] = useState(false);
      const [TaxListData, setTaxListData] = useState([])
      const [LocationData, setLocationData] = useState({lat: 11.937871, lng: 79.819953})
      const[getClauseOptions, setClauseOptions] = useState('description');
      const [searchParams, setSearchParams] = useSearchParams();
      const [photo_display, setphoto_display] = useState(null);
      const [photo_display_details, setphoto_display_details] = useState({});
      const [clauseTitle, setclauseTitle] = useState('')
      const [clauseDes, setclauseDes] = useState('')
      const [clauseObject, setclauseObject] = useState({})
      const [clauseArray, setclauseArray] = useState([])
      const [pageLoading, setPageLoading] = useState(false);
      const [clauseInputShow, setClauseInputShow] = useState(true);
      const [fileList, setFileList] = useState([])
      const [buttonLoading,setButtonLoading] =useState(false)
      const [ModalVisable,setModalVisable] =useState(false)
      const [BackModalVisable,setBackModalVisable] =useState(false)
      const [categoriesList,setCategoriesList] =useState([])
  const [TemplateViewDetails, setTemplateViewDetails] = useState({})
  const [TemplateViewDetailsMeta, setTemplateViewDetailsMeta] = useState({})
  const [templateViewType, settemplateViewType] = useState('view')
  
  const [getScheduledValue, setScheduledValue] = useState(false);
  const [getOpenValue, setOpenValue] = useState(false);
  const [getLeagueValue, setLeagueValue] = useState(false);
  const[getSelectedTemplateType, setSelectedTemplateType] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isConcurrentModalOpen, setIsConcurrentModalOpen] = useState(false);
  const [getYourProfileDetails, setYourProfileDetails] = useState({});
  
  const [clauseLink, setClauseLink] = useState('')
  const [isValidUrl, setIsValidUrl] = useState('');
  
  const [moreAboutArray, setMoreAboutArray] = useState([]);
  const [moreAboutInputShow, setMoreAboutInputShow] = useState(true);
  const [moreAboutObject, setMoreAboutObject] = useState({});
  const [moreAboutTitle, setMoreAboutTitle] = useState('');
  const [moreAboutDes, setMoreAboutDes] = useState('');
  const [moreAboutLink, setMoreAboutLink] = useState('');
  const [moreAboutUrl, setMoreAboutUrl] = useState('');
  const[changesHappened, setChangesHappened] = useState(false);
  const [setNoOfBookings, setsetNoOfBookings] = useState('');
  const [createTemplateDetails, setcreateTemplateDetails] = useState({
    location:"",
    address:"",
    category:"",
    clauses:[],
    extra_info:[],
    mode:1,
    country:'',
    tax_state:'',
    host_price:'',
    type:'',
    title:'',
    description:'',
    currency:'',
    zip_code:'',
    max_no_of_openings:'',
    team_size:''
  }); 
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);  
  const toggle = () => setIsOpen(!isOpen);   
  const handleChange = (value) => setValue(value);


  
      const checkCharacterCount = (event) => {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        
        if (unprivilegedEditor.getLength() > 2999 && event.key !== 'Backspace')
          event.preventDefault();
      };
      const countryList =[{name:'Canada',value:'CAD'},
      {name:'United States',value:'USD'}
      ]
      const currencyList =[{name:'CAD',value:'CAD'},
      {name:'USD',value:'USD'}
      ]
      var Link = Quill.import('formats/link');
    
    
      class MyLink extends Link {
        static create(value) {
          let node = super.create(value);
          value = this.sanitize(value);
          if(!value.startsWith("http")) {
            value = "http://" + value;
          }
          node.setAttribute('href', value);
          return node;
        }
      }
      
      Quill.register(MyLink);

      const formats = [
        'size',
        'bold', 'italic',
        'list', 'bullet',
        'link'
      ];
      
      const modules = {
        toolbar: [
          ['bold', 'italic'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          ['link']
        ]
      };
      const fetchPostalCode = address_components => {
        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        
        if(postal_code){
          form.setFieldsValue({
            zip_code:postal_code
          })
        }
        
        return postal_code;
    };
    useEffect(() => {
      getCategoriesList()
      if(!searchParams.get('id')){
        getLocationsData()
      }
      // getLocationsData()
    loadTaxList();
    setspinnerLoading(true)
    getUserProfile();
    questionSetsList();
    getCouponList()
    if(searchParams.get('id')){
      setspinnerLoading(true)
      templateView(searchParams.get('id'))
    }
    
    }, []);

    const questionSetsList = () => {
      props.questionSetsList({
        params: { limit: 1000 },
        callback: (res, data) => {
          if (res == true && _.get(data, 'data.items[0]'))
            setquestionSetList(_.get(data, 'data.items'))
        }
      })
    }

    const getCouponList = () => {
      props.couponList({
        params: { limit: 1000,host:_.get(props,"userProfile.id"),sort:"created_at.desc" },
        callback: (res, data) => {
          if (res == true && _.get(data, 'data.items[0]'))
            setCouponList(_.get(data, 'data.items'))
        }
      })
    }

    useEffect(() => {
      
      if(searchParams.get('id')){
       
          setspinnerLoading(true)
          templateView(searchParams.get('id'))
        
      }
    }, [searchParams.get('id')])
    

  ;
  const getUserProfile = () => {

    props.hostProfileData({
      params:{
        expand: "categories"
      },
      callback: (response, data) => {
        setspinnerLoading(false)
        
        if(response){
          setYourProfileDetails(_.get(data, 'data.details'));
          
        }
      }
    })
  }

    const templateView =(id)=>{
      setspinnerLoading(true)
      props.viewTemplate({
        params:{id:id,expand:'photos'},
        callback:(res,data)=>{
          setspinnerLoading(false)
          
          if(res){
            let dataValue = _.get(data,'data.details')
            setLocationData({lat:_.get(data,'data.details.location.x'), lng:_.get(data,'data.details.location.y')})
            setTemplateViewDetails(_.get(data,'data.details'))
            setcountry(_.get(data,'data.details.meta_details.currency'))
            setTemplateViewDetailsMeta(_.get(data,'data.meta'))
            form.setFieldsValue({currency: _.get(data,'data.details.meta_details.currency')})
            form.setFieldsValue({...dataValue, country: _.get(data,'data.details.meta_details.currency')})
            setFieldValue(data);
            form.setFieldsValue({
              team_size: _.get(data,'data.details.meta_details.team_size')
            })
            if(_.get(data,'data.details.meta_details.registration_info_set_id')){
              setquestionSetId(_.get(data,'data.details.meta_details.registration_info_set_id'))
            }
            if(_.size(_.get(data,'data.details.coupon_ids'))>0){
              setcouponId(_.get(data,'data.details.coupon_ids'))
            }
            
          }
        }
      })
    }
    const setFieldValue =(values)=>{
      
let details = _.pick(_.get(values,'data.details'), [
  'address', 'id', 'clauses', 'description',
  'max_no_of_openings','min_no_of_openings','host_price','tax_state','zip_code','photos','title', 'type','extra_info','team_size'

]);

if (_.get(values,'data.details.address') && _.get(values,'data.details.location')) {
                    
  const address_results = [{
      text: _.get(values,'data.details.address') ,
      value: JSON.stringify({ text:  _.get(values,'data.details.address') , lat: _.get(values,'data.details.location.x'), lng:  _.get(values,'data.details.location.y'), postal_code:''}),
    }]
    form.setFieldsValue({
        location:  _.get(values,'data.details.address') 
                           })
    setaddress_results(address_results)
}

// form.setFieldsValue({...details,country:country})

if(_.get(details,'clauses[0]')){
  setclauseArray(_.get(details,'clauses'))
  setClauseInputShow(false)

  
}

if(_.get(details,'extra_info[0]')){
  setMoreAboutArray(_.get(details,'extra_info'))
  setMoreAboutInputShow(false)
}

if(_.get(details,'photos[0]')){
  let set_photos =[]
_.map(_.get(details,'photos'),(value,index)=>{
  let make_url =`${_.get(values,'data.meta.photo.path')}/${_.get(values,'data.meta.photo.folder')}/${_.get(values,'data.meta.photo.sizes.medium')}/${_.get(value,'photo')}`

  set_photos.push( {
    uid: _.get(value,'id'),
    id: _.get(value,'id'),
    name: _.get(value,'photo'),
    status: 'done',
    url: make_url,
  },)
})
form.setFieldsValue({
  photo:set_photos
})
setFileList(set_photos)
}



    }
    const getLocationsData =async()=>{
      const { data, status } = await axios.get('https://ipapi.co/json');
      if(_.get(TemplateViewDetails, "location")){
        setLocationData({lat:_.get(TemplateViewDetails, "location.x"),lng:_.get(TemplateViewDetails, "location.y")})
      } else {
        setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
      }
      
      // defaultProps.center.lat=_.get(data,'latitude')
      // defaultProps.center.lng=_.get(data,'longitude')
     
    }
      
    const getCategoriesList  =()=>{
      props.list({
        callback:(res,data)=>{
          if(_.get(data,'data.items')){
            setCategoriesList(_.get(data,'data.items'))
          }
        }
      })
    }
    const loadTaxList =()=>{
props.taxList({
  params:{limit:100},
  callback:(res,data)=>{
if(res==true && _.get(data,'data.items[0]'))
    setTaxListData(_.get(data,'data.items'))
  }
})
    }
    const findLocationLocality = (addressComponentsArray, levelArray) => {
      
      for (let i = 0; i < addressComponentsArray.length; ++i) {
          for (let j = 0; j < levelArray.length; j++) {
              if (addressComponentsArray[i].types.indexOf(levelArray[j]) > -1) {
                  return {
                      locality_level: levelArray[j],
                      value: addressComponentsArray[i].long_name
                  }
              }
          }
      }
  }
      const onChangeCountry = (value) =>{
        setcountry(value);
  setcreateTemplateDetails({
    ...createTemplateDetails,
    currency:value,country:value
  })
        form.setFieldsValue({currency:value})
      };

      const handleChange1 = (value) => {
        setAddress(value);
      };
      
      const handleSelect = async (value) => {
      
        
        setaddress_results([])
          setaddress_fetching(true);
          geocodeByAddress(value).then(body => {
              
              const address_results = _.map(body, location => ({
                  text: location.formatted_address,
                  value: JSON.stringify({ text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
              }));
      
              setaddress_results(address_results)
              setaddress_fetching(false);
              
            form.setFieldsValue({
                location: value,
            })
            if(_.size(address_results)>0){
              
              let a = JSON.parse(address_results[0].value)
              setcreateTemplateDetails({
                ...createTemplateDetails,address:address_results[0].text,location:address_results[0].value,
                zip_code:_.get(a,'postal_code')
              })
              form.setFieldsValue({
                zip_code: _.get(a,'postal_code'),
            })
            }
            
            let state =extractStateName(body)
            let ada = _.filter(TaxListData,(v)=>_.lowerCase(v.state)==_.lowerCase(state))
            if(_.size(ada)>0){
              setcreateTemplateDetails({
                ...createTemplateDetails,
                tax_state:_.get(ada[0],'id')
              })
              form.setFieldsValue({
                tax_state: _.get(ada[0],'id')
            })
            }
            
            
            setaddress_results(address_results)
            let a = JSON.parse(address_results[0].value)
            setLocationData({lat:a.lat,lng:a.lng})
          }).catch(error => {
              setaddress_results([])
              setaddress_fetching(true);
          });
      };
      const extractStateName = results => {
        for (let i = 0; i < results[0].address_components.length; i++) {
          const component = results[0].address_components[i];
          if (component.types.includes('administrative_area_level_1')) {
            return component.long_name; // Return the state name
          }
        }
        return '';
      };
      
      const searchOptions = {
        componentRestrictions: { country: form.getFieldValue('country')=="USD"?['us']:['ca'] }, // Restrict search to United States and Canada
      };
      const getFilterTax = (list,currency)=>{
        let country = currency=='CAD' ? 'CA' :'US'
            let a = _.filter(list,(v,i)=>_.get(v,'country')==country)
        return a
          }

          const onChangeClauseOptions = (e) => {
            
            setClauseOptions(e.target.value)
      
          }

          const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 50);
        };
        const requiredRender =<span>*</span>

        const hasErrors =(fieldsError)=> {
          return Object.keys(fieldsError).some(field => fieldsError[field]);
        }
        
        // ['type','title','description','country','location','tax_state','zip_code','currency','host_price']
          

           const checkValidation =()=>{
           



             if(!form.getFieldValue('type') ||  !form.getFieldValue('title') || !form.getFieldValue('description') ||
              !form.getFieldValue('country') || !form.getFieldValue('location') || !form.getFieldValue('tax_state') ||
              !form.getFieldValue('zip_code') || !form.getFieldValue('currency') || !form.getFieldValue('host_price') || !form.getFieldValue('max_no_of_openings') || fileList.length==0
              ){
              return true
             }else if(form.getFieldValue('type')=="league") {
              
              if(form.getFieldValue('type')=="league" && !form.getFieldValue('team_size')){
                return true
              }else{
                return false
              }
              
             }else{
              return false
             }

           }

           const onEditMoreAbout =async(value, type,index, clauseOpt)=>{
            
      if(type=="edit"){
        setMoreAboutObject(value)
        setMoreAboutTitle(_.get(value,'name'))
        setMoreAboutDes(_.get(value,'description'))
        setMoreAboutLink(_.get(value,'link'))
      let remove = _.remove(moreAboutArray,(e,i)=>i==index)
      
      setMoreAboutArray(moreAboutArray)
      
      setMoreAboutInputShow(true)
      }else{
      let details =[...moreAboutArray]
      let remove = _.remove(details,(e,i)=>i==index)
      setMoreAboutArray(details)   
      
      setTimeout(() => {
        setMoreAboutArray(details)   
      }, 200);
      
      
      }
      
      
        }
           const renderAdditionalInfo =(moreAboutArray)=>{
return _.map(moreAboutArray,(value,index)=>{

  return <div key={index} className="create-template-more-about-ctn" style={{width:'80%',marginBottom:'16px'}}>
    <div style={{padding:'24px'}}>
      <div style={{display:'flex',flexDirection:'column'}}>
        <span className="title">Title</span>
        <span className="title-des">{_.get(value,'name')}</span>
      </div>
      <div style={{display:'flex',flexDirection:'column',marginTop:'16px'}}>
        <span className="title">Description</span>
        <div className="title-des" dangerouslySetInnerHTML={{ __html: _.get(value,'description') }} />
      </div>
      {!_.isEmpty(_.get(value,'link')) &&<div style={{display:'flex',flexDirection:'column',marginTop:'16px'}}>
        <span className="title">Link</span>
        <span className="title-des">{_.get(value,'link')}</span>
      </div>}
    </div>
    <Divider style={{margin:'0px',marginBottom:'16px'}} />
    <div style={{display:'flex',flexDirection:'row',marginBottom:'16px',marginLeft:'24px'}}>
      <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>onEditMoreAbout(value,'edit',index)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g clip-path="url(#clip0_10637_17543)">
    <path d="M7.33325 2.66666H4.53325C3.41315 2.66666 2.85309 2.66666 2.42527 2.88464C2.04895 3.07639 1.74299 3.38235 1.55124 3.75867C1.33325 4.1865 1.33325 4.74655 1.33325 5.86666V11.4667C1.33325 12.5868 1.33325 13.1468 1.55124 13.5746C1.74299 13.951 2.04895 14.2569 2.42527 14.4487C2.85309 14.6667 3.41315 14.6667 4.53325 14.6667H10.1333C11.2534 14.6667 11.8134 14.6667 12.2412 14.4487C12.6176 14.2569 12.9235 13.951 13.1153 13.5746C13.3333 13.1468 13.3333 12.5868 13.3333 11.4667V8.66666M5.33323 10.6667H6.4496C6.77572 10.6667 6.93878 10.6667 7.09223 10.6298C7.22828 10.5972 7.35834 10.5433 7.47763 10.4702C7.61219 10.3877 7.72749 10.2724 7.95809 10.0418L14.3333 3.66666C14.8855 3.11437 14.8855 2.21894 14.3333 1.66666C13.781 1.11437 12.8855 1.11437 12.3333 1.66665L5.95808 8.04182C5.72747 8.27242 5.61217 8.38772 5.52971 8.52228C5.45661 8.64157 5.40274 8.77163 5.37007 8.90768C5.33323 9.06113 5.33323 9.22419 5.33323 9.55031V10.6667Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10637_17543">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
<div className="delete-btn" style={{marginLeft:'4px'}}>Edit</div>
        {/* <Button className="delete-btn" styles={{icon:{
        width: '16px',height: '16px',
        marginRight:'4px'
      }}} style={{border:'none',boxShadow:'none'}} icon={<TiEdit size={16} color="#475467"/>}>Edit</Button> */}
</div>
<Popconfirm
    style={{display:'flex',alignItems:'center',cursor:'pointer'}}
    title="Are you sure to delete?"
    onConfirm={() => onEditMoreAbout(value,'delete',index)}
    // onCancel={cancel}
    okText="Continue"
    cancelText="Cancel"
  >
<div style={{display:'flex',alignItems:'center',marginLeft:'16px' ,cursor:'pointer'}}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4M6.66667 7V10.3333M9.33333 7V10.3333" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<div className="delete-btn" style={{marginLeft:'4px'}}>Delete</div>
       
</div></Popconfirm>
    </div>


  </div>
  
})
           }
           const setClauseDetails =()=>{
            let details =[...clauseArray]
          
    if(getClauseOptions === 'description'){
      
      if(_.isEmpty(clauseDes)){
        notification.error({
          message: "Error",
          description: "Please provide clause description"
        })
      }
      if(clauseTitle && clauseDes){
        let duplicate = _.some(details,{name:clauseTitle,description:clauseDes})      
        if(duplicate==false) {
          details.push({name:clauseTitle,description:clauseDes})
        }else{
          notification.error({
            message:'Error',
            description:'This clauses title and description is already taken.'
          })
        }
          
        }
    } else if(getClauseOptions === 'link'){
     
      if(_.isEmpty(clauseLink)){
        notification.error({
          message: "Error",
          description: "Please provide clause link"
        })
      }
      if(clauseTitle && clauseLink){
        let duplicate = _.some(details,{name:clauseTitle,link:clauseLink})      
        if(duplicate==false) {
          details.push({name:clauseTitle,link:clauseLink})
        }else{
          notification.error({
            message:'Error',
            description:'This clauses title and description is already taken.'
          })
        }
          
        }
    }
           
    if(!_.isEmpty(clauseTitle) && !_.isEmpty(clauseLink) || !_.isEmpty(clauseTitle) && !_.isEmpty(clauseDes)){       
    setclauseObject({})
                setclauseDes('')
                setclauseTitle('')
                setclauseArray(details)
                setClauseInputShow(false)
    }
            };

           const onEditClasuess =async(value, type,index, clauseOpt)=>{
            
    if(type=="edit"){
      if(!_.isEmpty(_.get(value, 'link'))){
        setClauseOptions('link')
      }
      if(!_.isEmpty(_.get(value, 'description'))){
        setClauseOptions('description')
      }
      setclauseObject(value)
      setclauseTitle(_.get(value,'name'))
      setclauseDes(_.get(value,'description'))
      setClauseLink(_.get(value,'link'))
    let remove = _.remove(clauseArray,(e,i)=>i==index)
    
      setclauseArray(clauseArray)
      
      setClauseInputShow(true)
    }else{
      let details =[...clauseArray]
      let remove = _.remove(details,(e,i)=>i==index)
      setclauseArray(details)   
    
      setTimeout(() => {
        setclauseArray(details)   
      }, 200);
     
    
    }
    
    
        }

           const loadClausesList =(clauseArray)=>{
            return _.map(clauseArray,(value,index)=>{
            
              return <div key={index} className="create-template-more-about-ctn" style={{width:'80%',marginBottom:'16px'}}>
                <div style={{padding:'24px'}}>
                  <div style={{display:'flex',flexDirection:'column'}}>
                    <span className="title">Title</span>
                    <span className="title-des">{_.get(value,'name')}</span>
                  </div>
                  {_.get(value,'description') &&<div style={{display:'flex',flexDirection:'column',marginTop:'16px'}}>
                    <span className="title">Description</span>
                    <div className="title-des" dangerouslySetInnerHTML={{ __html: _.get(value,'description') }} />
                  </div>}
                  {_.get(value,'link') &&<div style={{display:'flex',flexDirection:'column',marginTop:'16px'}}>
                    <span className="title">Link</span>
                    <span className="title-des">{_.get(value,'link')}</span>
                  </div>}
                </div>
                <Divider style={{margin:'0px',marginBottom:'16px'}} />
                <div style={{display:'flex',flexDirection:'row',marginBottom:'16px',marginLeft:'24px'}}>
                  <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>onEditClasuess(value,'edit',index, getClauseOptions)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_10637_17543)">
                <path d="M7.33325 2.66666H4.53325C3.41315 2.66666 2.85309 2.66666 2.42527 2.88464C2.04895 3.07639 1.74299 3.38235 1.55124 3.75867C1.33325 4.1865 1.33325 4.74655 1.33325 5.86666V11.4667C1.33325 12.5868 1.33325 13.1468 1.55124 13.5746C1.74299 13.951 2.04895 14.2569 2.42527 14.4487C2.85309 14.6667 3.41315 14.6667 4.53325 14.6667H10.1333C11.2534 14.6667 11.8134 14.6667 12.2412 14.4487C12.6176 14.2569 12.9235 13.951 13.1153 13.5746C13.3333 13.1468 13.3333 12.5868 13.3333 11.4667V8.66666M5.33323 10.6667H6.4496C6.77572 10.6667 6.93878 10.6667 7.09223 10.6298C7.22828 10.5972 7.35834 10.5433 7.47763 10.4702C7.61219 10.3877 7.72749 10.2724 7.95809 10.0418L14.3333 3.66666C14.8855 3.11437 14.8855 2.21894 14.3333 1.66666C13.781 1.11437 12.8855 1.11437 12.3333 1.66665L5.95808 8.04182C5.72747 8.27242 5.61217 8.38772 5.52971 8.52228C5.45661 8.64157 5.40274 8.77163 5.37007 8.90768C5.33323 9.06113 5.33323 9.22419 5.33323 9.55031V10.6667Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_10637_17543">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div className="delete-btn" style={{marginLeft:'4px'}}>Edit</div>
                    {/* <Button className="delete-btn" styles={{icon:{
                    width: '16px',height: '16px',
                    marginRight:'4px'
                  }}} style={{border:'none',boxShadow:'none'}} icon={<TiEdit size={16} color="#475467"/>}>Edit</Button> */}
            </div>
            <Popconfirm
    style={{display:'flex',alignItems:'center',cursor:'pointer'}}
    title="Are you sure to delete this clause?"
    onConfirm={() => onEditClasuess(value,'delete',index)}
    // onCancel={cancel}
    okText="Continue"
    cancelText="Cancel"
  >
            <div style={{display:'flex',alignItems:'center',marginLeft:'16px' ,cursor:'pointer'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4M6.66667 7V10.3333M9.33333 7V10.3333" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div className="delete-btn" style={{marginLeft:'4px'}}>Delete</div>
                   
            </div></Popconfirm>
                </div>
            
            
              </div>
              
            })
                       }

           const setMoreAboutDetails =()=>{
            let details =[...moreAboutArray];
  
            if(_.isEmpty(moreAboutTitle)){
              notification.error({
                message: "Error",
                description: "Please provide title"
              })
            }
  
            if(_.isEmpty(moreAboutDes)){
              
              notification.error({
                message: "Error",
                description: "Please provide description"
              })
            }
  
            if(!_.isEmpty(moreAboutTitle) && !_.isEmpty(moreAboutDes)){
              if(moreAboutTitle && moreAboutDes && moreAboutLink){
                let duplicate = _.some(details,{name:moreAboutTitle,description:moreAboutDes, link: moreAboutLink})      
                if(duplicate==false) {
                  details.push({name:moreAboutTitle,description:moreAboutDes, link: moreAboutLink})
                }
                  
                }
            }
      
            if(!_.isEmpty(moreAboutTitle) && !_.isEmpty(moreAboutDes)){
              if(moreAboutTitle && moreAboutDes){
                let duplicate = _.some(details,{name:moreAboutTitle,description:moreAboutDes})      
              if(duplicate==false) {
                details.push({name:moreAboutTitle,description:moreAboutDes})
              }
              }
            }
        
    
           if(!_.isEmpty(moreAboutTitle) && !_.isEmpty(moreAboutDes)){
            setMoreAboutObject({})
            setMoreAboutDes('')
            setMoreAboutTitle('')
            setMoreAboutLink('')
            setMoreAboutArray(details)
            setMoreAboutInputShow(false)
           }
                
       
            }

            const cancelAddtionalInfo =()=>{
              setMoreAboutObject({})
              setMoreAboutDes('')
              setMoreAboutTitle('')
              setMoreAboutLink('')
              setMoreAboutInputShow(false) 
            }

            const onChangeClauseUrl = (e) => {
              setClauseLink(e.target.value)
              const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
             if(regex.test(e.target.value)){
              setIsValidUrl('')
             } else {
              setIsValidUrl('Please enter valid URL')
             }
            };

            const getLogoBase64 = async (img, callback) => {
              const reader = new FileReader();
              reader.addEventListener('load', () => callback(reader.result));
              reader.readAsDataURL(img);
          };
    const onChangePhoto = (info)  => {
      getLogoBase64(info.file.originFileObj, (imageUrl) => {
        setFileList(_.uniqBy([...fileList,{...info.file,imageUrl}],'uid'))
      });
      
      // 
      // 
      // setFileList(newFileList)
      // form.setFieldsValue({
      //   photo:newFileList
      // })
        
    };
    const isJson=(str)=> {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }
    const onFinish =(e)=>{
        
        let request_details={...e};
        
    if (isJson(_.get(_.head(address_results),'value'))) {
      let location_parse =JSON.parse(_.get(_.head(address_results),'value'))
      request_details.location=`${_.get(location_parse,'lat')},${_.get(location_parse,'lng')}`
      request_details.address = _.get(location_parse,'text')
    }
    if(_.get(getYourProfileDetails, 'categories[0].id')){
      request_details.category = _.get(getYourProfileDetails, 'categories[0].id')
    }

      request_details.meta_details = {"team_size":_.get(request_details, 'team_size')?_.get(request_details, 'team_size'):_.get(TemplateViewDetails, 'meta_details.team_size'),currency:_.get(request_details,'currency') ? _.get(request_details,'currency') : _.get(TemplateViewDetails, 'meta_details.currency'),
      
    }
    if(request_details?.type=="league"){
      request_details.meta_details["team_size"] =_.get(request_details, 'team_size')?_.get(request_details, 'team_size'):_.get(TemplateViewDetails, 'meta_details.team_size')

    }else{
      request_details.meta_details["team_size"]=''
    }
    if(questionSetId){
      request_details.meta_details["registration_info_set_id"]= questionSetId
    }
    if(couponId.length>0){
      request_details.coupon_ids= couponId
    }
    delete request_details['team_size']
    delete request_details['email']
    request_details['clauses']=clauseArray
    request_details['extra_info']=moreAboutArray
    request_details['min_no_of_openings']=1
    request_details['mode']=1
    request_details.country =_.get(request_details, 'country')=="USD"?"US":"CA"
    request_details.tax_state=_.get(request_details, 'tax_state')
    
    setButtonLoading(true)
    let url = searchParams.get('id')?'updateTemplate':'createTemplate'
    if(searchParams.get('id')){
      request_details.id=searchParams.get('id')
      request_details['price']=_.get(request_details,'host_price')
    }
    
    setspinnerLoading(true)
    props[url]({
      body:{...request_details},
      callback:(res,data)=>{
        
    if(url=="createTemplate" && res === true){
      setquestionSetId("")
      setcouponId([])
      // history('/template-list')
    }
    
    
    if (_.get(data,'details.id')) {
      setquestionSetId("")
      setcouponId([])
      if(fileList.length>0){
        addPhotoTemplateAPI(fileList,_.get(data,'details.id'))
      }else{
        setspinnerLoading(false)
        onBackTemplate()  
        setButtonLoading(false)
        addPhotoTemplateAPI([],_.get(data,'details.id'))
      }
    }else{

      if(res==false){
        notification.error({
          message:'Error',
          description: _.get(data,'error.message',"Something went wrong. Please try again")
       
         })
      }
     
    }
    
      }
    })
    
      }

      const addPhotoTemplateAPI =(photo,id)=>{
let final_photos =[]
        if(searchParams.get('id')){
_.map(photo,(value,i)=>{
  if(!_.get(value,'id')){
    final_photos.push(value)
  }
})
}else{
  _.map(photo,(value,i)=>{
    
      final_photos.push(value)
  })
}
if(_.size(final_photos)==0){
  onBackTemplate()  
  setspinnerLoading(false)
  notification.success({
    message:'Success',
    description: `Template has been ${searchParams.get('id')?'updated':'created'} successfully.`
 
   })
   settemplateViewType('view')
   templateView(id)
   setModalVisable(true)
   setTimeout(() => {
    // setModalVisable(false)
    if(!searchParams.get('id')){
      // history(`/template-list`)
     }
     
     
   }, 1400);
   
   setButtonLoading(false)
}else{
  
  
  updateImageAPI(final_photos,id)
       
      }
      }
      const updateImageAPI =(photos,id)=>{
        
        if(_.isArray(photos)==true && _.size(photos)>0){
          let a =_.head(photos)
          updateImage(a,id,photos)
        }else{
          setspinnerLoading(false)
          onBackTemplate()  
             notification.success({
                message:'Success',
                description: `Template has been ${searchParams.get('id')?'updated':'created'} successfully.`
             
               })
               resetDataFields()
               setModalVisable(true)
 form.resetFields()
               settemplateViewType('view')
               templateView(id)
               setTimeout(() => {
                setModalVisable(false)
                if(!searchParams.get('id')){
                  onBackTemplate()  
                  // history(`/template-list`)
                 }
                 
                 
               }, 1400);
               setButtonLoading(false)
        }
      
      }
      const removeImage = info => {
      
        if(_.get(info,'id')){
          props.deletePhoto({
            params:{id:searchParams.get('id')},
            body:{ids:[_.get(info,'id')]}
            ,callback:(res,data)=>{
              if(res){
  
              }
            }
          })
        }
      };
      const updateImage =(value,id,photos)=>{
       let p =[...photos]
       
          let formData = new FormData()
        // formData.append('id', id)
        formData.append('photo', _.get(value, 'originFileObj'))
        props.addPhotoTemplate({
          body:formData,
          params:{id:id},
          callback:(res,data)=>{
            if(res==true){
             
              // var pulled =photos.shift()
              let a =p.slice(1,_.size(p))
              

              updateImageAPI(_.size(p)>0?a:[],id)
            }
           
          }
        })
       
      }

      const commonSetFields =(name,value)=>{
        
        let details ={...createTemplateDetails}
        details[name]=value

        setcreateTemplateDetails(details)
      }
    

      const resetDataFields =()=>{
        form.resetFields()
      setcountry('')
      setclauseArray([])
      setaddress_results([])
      setMoreAboutArray([])
      setFileList([]);
      setClauseLink('')
      }
      
      const modalTitle = (
        <div style={{ display: "flex", flexDirection: "column", paddingLeft: '24px', paddingRight: '24px' }}>
               <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="alert-circle">
<path id="Icon" d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>

                      <IoMdClose style={{ height: '24px', width: '24px', cursor: 'pointer' }} onClick={() => {
                           props?.onOutSideClick(false)
                           setIsOpen(false)
                      }} color='#667085' />

               </div>
               <div className='email-modal-title' >You may have unsaved changes!</div>

        </div>
 );
 useEffect(() => {
  if(changesHappened==true){
    props?.setoutsideDisabled(true)
  }
}, [changesHappened]);

const onBackTemplate =()=>{
  resetDataFields();
          props?.onBack()
}

      return   <div ref={menuRef}  style={{ width: '100%' }} className="create-template-full-container">

  <Modal centered={true}
              closeIcon={null}
              title={modalTitle}
              open={isOpen ? isOpen : props?.outsideClick}
              onOk={() => {
                     setIsOpen(false)
                     props?.onOutSideClick(false)

              }}
              onCancel={() => {
                setIsOpen(false)
              }}
              footer={null}
              rootClassName='email-modal-content'
       >
       <div style={{paddingLeft:'24px',paddingRight:'24px',marginTop:'4px'}}>
       <div className="email-modal-title-des">Any changes that haven’t been saved will be lost. Are you sure you want to leave this page?</div>
       <div style={{display:'flex',justifyContent:'flex-end',marginTop:'32px'}}>
        <Button style={{height:'44px',marginRight:'12px'}}
        onClick={()=>{
          props?.onOutSideClick(false);
          setIsOpen(false)
        }}
        className="create-template-modal-button-1">No, take me back</Button>
        <Button
        onClick={()=>{
          onBackTemplate();
          navigate(`/template-list?menu=template`)  
        }}
        style={{height:'44px'}} className="create-template-modal-button-2">Yes, leave page</Button>
       </div>
       </div>

        </Modal>

        {spinnerLoading==true &&<div className="spinner-overlay">
      <div className="spinner-container">
      <ThreeDots
  visible={true}
  height="80"
  width="80"
  color="#EF6820"
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
      </div>
    </div>}
        {contextHolder}
        <Card
        bordered={false}
       
          size="small"
          title={
              
            <div className='header-class-card-new' style={{ display: 'flex',justifyContent:'space-between',paddingLeft:'-4px',paddingBottom:'16px' }}>
           <div className="create-template-container">
              <Image onClick={()=>{
                if(changesHappened===false){
                  // props?.onClickButton()
                  onBackTemplate();
                  navigate('/template-list?menu=template')
                }else if(changesHappened===true){
                  setIsOpen(true);
                }
              }} preview={false} src={require('../../../assets/img/template/back.png')} style={{width:'48px',height:'48px',cursor:'pointer'}}/>
              <div className="create-template-header-title" style={{marginLeft:'6px'}}>{searchParams.get('id')?"Edit":"Create"} a new template</div>
           </div>
          
           </div>
           
          }
          //       extra={<a href="#">More</a>}
          style={{
            width: '100%',
            padding: '0px'
          }}
          className='manage-active-table'
        >
          <div className="coach-full-conatiner coach-full-conatiner-active" style={{marginTop:'20px'}}>
          <Form scrollToFirstError={true} form={form} onFinishFailed={(e)=>console.log('onFinishFailed',e)} onFinish={(e)=>{
             onFinish(e)
          }}>
    <div className="create-template-field-container">
       <div style={{width:'50%'}}>
              <div><span className="create-template-field-title">Template type {requiredRender}</span> <span style={{marginLeft:'6px'}} onClick={()=>{
                     settemplateTypeModal({visable:true,type:1})
              }}><FaRegQuestionCircle color="#475467" style={{width:'16px',height:'16px'}} /></span></div>
<div className="create-template-field-des" style={{width:'80%'}}>Choose between scheduled, open and league templates</div>
       </div>
       <div  style={{width:'100%',height:'44px'}}>
       <Form.Item name='type'   style={{width:'100%',height:'44px'}} rules={[
          {
            required: true,
            message: 'Please input your Template Type!',
          },
        ]}><Select
        
        disabled={searchParams.get('id')?true:false}
        style={{width:'80%',height:'44px'}}
        optionFilterProp="children"
        onChange={() => setChangesHappened(true)}
        onSelect={(value) =>{
commonSetFields('type',value)
        } }
        >
       <Option  key={"sheduled_type"} value={"scheduled"}><div>
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M17.5 9.58317V7.33317C17.5 5.93304 17.5 5.23297 17.2275 4.69819C16.9878 4.22779 16.6054 3.84534 16.135 3.60565C15.6002 3.33317 14.9001 3.33317 13.5 3.33317H6.5C5.09987 3.33317 4.3998 3.33317 3.86502 3.60565C3.39462 3.84534 3.01217 4.22779 2.77248 4.69819C2.5 5.23297 2.5 5.93304 2.5 7.33317V14.3332C2.5 15.7333 2.5 16.4334 2.77248 16.9681C3.01217 17.4386 3.39462 17.821 3.86502 18.0607C4.3998 18.3332 5.09987 18.3332 6.5 18.3332H10.4167M17.5 8.33317H2.5M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M15 17.4998V12.4998M12.5 14.9998H17.5" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
              <span style={{marginLeft:'8px'}}>Scheduled session</span>
              </div></Option>
       <Option key={"open_session_type"} value={"league"}> <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_10689_454)">
    <path d="M9.99999 12.4998C7.23857 12.4998 4.99999 10.2613 4.99999 7.49984V2.87021C4.99999 2.52533 4.99999 2.3529 5.05025 2.21482C5.1345 1.98335 5.31683 1.80101 5.54831 1.71676C5.68638 1.6665 5.85882 1.6665 6.20369 1.6665H13.7963C14.1412 1.6665 14.3136 1.6665 14.4517 1.71676C14.6831 1.80101 14.8655 1.98335 14.9497 2.21482C15 2.3529 15 2.52533 15 2.87021V7.49984C15 10.2613 12.7614 12.4998 9.99999 12.4998ZM9.99999 12.4998V14.9998M15 3.33317H17.0833C17.4716 3.33317 17.6658 3.33317 17.8189 3.3966C18.0231 3.48118 18.1853 3.64341 18.2699 3.8476C18.3333 4.00074 18.3333 4.19489 18.3333 4.58317V4.99984C18.3333 5.77481 18.3333 6.1623 18.2481 6.48022C18.017 7.34295 17.3431 8.01682 16.4804 8.24799C16.1625 8.33317 15.775 8.33317 15 8.33317M4.99999 3.33317H2.91666C2.52837 3.33317 2.33423 3.33317 2.18109 3.3966C1.9769 3.48118 1.81467 3.64341 1.73009 3.8476C1.66666 4.00074 1.66666 4.19489 1.66666 4.58317V4.99984C1.66666 5.77481 1.66666 6.1623 1.75184 6.48022C1.98301 7.34295 2.65688 8.01682 3.51961 8.24799C3.83753 8.33317 4.22501 8.33317 4.99999 8.33317M6.20369 18.3332H13.7963C14.0008 18.3332 14.1667 18.1674 14.1667 17.9628C14.1667 16.3264 12.8401 14.9998 11.2037 14.9998H8.79629C7.15989 14.9998 5.83332 16.3264 5.83332 17.9628C5.83332 18.1674 5.99914 18.3332 6.20369 18.3332Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10689_454">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg></span>
              <span style={{marginLeft:'8px'}}>League session</span></Option>
       <Option key={"league_session_type"} value={"open"}><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M6.55722 12.8831L5.83333 18.3332L9.657 16.039C9.78171 15.9641 9.84406 15.9267 9.91063 15.9121C9.96951 15.8992 10.0305 15.8992 10.0894 15.9121C10.1559 15.9267 10.2183 15.9641 10.343 16.039L14.1667 18.3332L13.4433 12.8808M13.6882 3.54057C13.8169 3.85184 14.064 4.09925 14.375 4.22842L15.4658 4.68024C15.7771 4.80918 16.0244 5.0565 16.1533 5.36779C16.2822 5.67908 16.2822 6.02884 16.1533 6.34013L15.7018 7.43013C15.5728 7.74156 15.5726 8.09168 15.7022 8.40295L16.1529 9.49263C16.2168 9.64681 16.2498 9.81207 16.2498 9.97897C16.2498 10.1459 16.2169 10.3111 16.1531 10.4653C16.0892 10.6195 15.9956 10.7596 15.8775 10.8776C15.7595 10.9956 15.6194 11.0892 15.4652 11.1531L14.3752 11.6045C14.064 11.7332 13.8166 11.9803 13.6874 12.2914L13.2356 13.3821C13.1067 13.6934 12.8593 13.9408 12.5481 14.0697C12.2368 14.1986 11.887 14.1986 11.5758 14.0697L10.4858 13.6182C10.1745 13.4896 9.82489 13.4898 9.5138 13.6189L8.42306 14.0701C8.11195 14.1987 7.76251 14.1986 7.45148 14.0698C7.14045 13.941 6.89328 13.6939 6.76426 13.383L6.31232 12.2919C6.18363 11.9806 5.93659 11.7332 5.62553 11.604L4.53479 11.1522C4.22365 11.0233 3.97641 10.7762 3.84743 10.465C3.71844 10.1539 3.71827 9.80434 3.84694 9.49309L4.29841 8.4031C4.42703 8.0918 4.42677 7.74216 4.29768 7.43106L3.84686 6.33949C3.78294 6.18532 3.75003 6.02005 3.75 5.85315C3.74997 5.68625 3.78283 5.52097 3.8467 5.36678C3.91057 5.21258 4.0042 5.07247 4.12223 4.95447C4.24026 4.83647 4.38039 4.74289 4.5346 4.67907L5.62456 4.22758C5.93554 4.09899 6.1828 3.85225 6.31206 3.54154L6.76386 2.45076C6.8928 2.13947 7.14011 1.89215 7.45139 1.76321C7.76267 1.63427 8.11242 1.63427 8.4237 1.76321L9.51365 2.2147C9.82494 2.34332 10.1746 2.34306 10.4857 2.21397L11.5769 1.76391C11.8881 1.63504 12.2378 1.63507 12.549 1.76398C12.8602 1.8929 13.1075 2.14015 13.2364 2.45136L13.6884 3.54246L13.6882 3.54057Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
              <span style={{marginLeft:'8px'}}>Open session</span></Option>
       {/* <Option key={"league_type"} value={"league"}>League</Option> */}
      </Select>

              </Form.Item>


       </div>
    </div>

  {/* template name */}
  <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Template name {requiredRender}</span> 
<div className="create-template-field-des" style={{width:'80%'}}>Give your template a name</div>
       </div>
      
    </div>
    <div  style={{width:'80%',height:'44px'}}>
       <Form.Item name='title'   rules={[
          {
            required: true,
            message: 'Please input your title!',
          },
        ]}><Input onChange={(e) => {setChangesHappened(true);commonSetFields('title',e.target.value)}} placeholder="Give your template a name"  style={{width:'80%',height:'44px'}} /></Form.Item>


       </div>
    </div>

    <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Sport {requiredRender}</span> 
<div className="create-template-field-des" style={{width:'80%'}}>Choose your sport</div>
       </div>
      
    </div>
    <div  style={{width:'80%',height:'44px'}}>
       <Input  style={{width:'80%',height:'44px'}}  defaultValue={_.startCase(_.get(getYourProfileDetails, 'categories[0].name'))} 
value={_.startCase(_.get(getYourProfileDetails, 'categories[0].name'))} readOnly={true}/>


       </div>
    </div>
   {_.size(questionSetList)>0 && <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Default question set</span> 
<div className="create-template-field-des" style={{width:'80%'}}>Choose a default question set that will be applied to all sessions scheduled with this template</div>
       </div>
      
    </div>
    <div style={{width:'80%'}}>
    <Select
    mode="tags"
 maxTagCount={1}
 onDeselect={(e)=>{
  setquestionSetId("");
  setChangesHappened(true)
 }}
                  value={questionSetId|| undefined}
                  placeholder="Choose a question set (optional)"
                  className='schedule-template-select-manage1'
                  optionFilterProp="children" onSelect={(e) => {setquestionSetId(e);setChangesHappened(true)}}>
                  {_.map(questionSetList, (v, i) => {
                    return <Option key={i} value={_.get(v, 'id')}>{_.get(v, 'label')}</Option>
                  })}
                </Select>
    </div>
    </div>}
    {_.size(CouponList)>0 && <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Promotion</span> 
<div className="create-template-field-des" style={{width:'80%'}}>Choose if you want any promotions to apply to this session</div>
       </div>
      
    </div>
    <div style={{width:'80%'}}>
    <Select
    mode="tags"
//  maxTagCount={}
//  onDeselect={(e)=>{
//   setcouponId("")
//  }}
                  value={couponId|| undefined}
                  placeholder="Choose a promotion"
                  className='schedule-template-select-manage1'
                  optionFilterProp="children" onChange={(e) => {
                   { setcouponId(e);setChangesHappened(true)}
                  }}>
                  {_.map(CouponList, (v, i) => {
                    return <Option key={i} value={_.get(v, 'id')}>{_.get(v, 'label')}</Option>
                  })}
                </Select>
    </div>
    </div>}
    <Divider style={{marginTop:'20px'}}/>
    
    <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">About this session {requiredRender}</span> 
<div className="create-template-field-des" style={{width:'80%'}}>Tell us more about this session including details like why users should buy it</div>
       </div>
      
    </div>
    <div  style={{width:'80%'}}>
    <Form.Item name='description' style={{marginBottom:'0px',paddingBottom:'6px'}}   rules={[
          {
            required: true,
            message: 'Please input your description!',
          },
        ]}>
          <ReactQuill 
          placeholder="Tell us about this session"
          className="reactquill-container"
          style={{width:'80%'}}
          theme="snow" 
          ref={reactQuillRef}

          value={value}
           onChange={(e)=>{ setChangesHappened(true);commonSetFields('description',e);
           const value = e.replace(/<\/?[^>]+(>|$)/g, '');
           setValue( value);
          }}
           modules={modules}
           formats={formats}
          //  onKeyPress={checkCharacterCount}
          //  onKeyDown={checkCharacterCount}
          //  onKeyUp={checkCharacterCount}
           
           />
        </Form.Item>
{/* <div className="react-quill-des">3000 characters limit</div> */}

       </div>
    </div>
    
    
    <Divider style={{marginTop:'20px'}}/>
    
    
    <div className="create-template-field-container">
       <div style={{width:'50%'}}>
              <div><span className="create-template-field-title">Country {requiredRender}</span> </div>
<div className="create-template-field-des" style={{width:'80%'}}>Select a country</div>
       </div>
       <div  style={{width:'100%',height:'44px'}}>
       <Form.Item name='country'   rules={[
          {
            required: true,
            message: 'Please input your Country!',
          },
        ]}>
  <Select
  onChange={(value)=>{onChangeCountry(value);setChangesHappened(true); setAddress('');setaddress_results([]);
form.setFieldValue('location','')
form.setFieldValue('tax_state','')
form.setFieldValue('zip_code','')
form.setFieldValue('currency',value)
}}
  defaultValue={country}
  value={country}
  style={{width:'80%',height:'44px'}}
    optionFilterProp="children">
   {_.map(countryList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select>
  </Form.Item>

       </div>
    </div>

          <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'50%'}}>
              <div><span className="create-template-field-title">Address {requiredRender}</span> </div>
<div className="create-template-field-des" style={{width:'80%'}}>Enter the location of your session</div>
       </div>
       <div  style={{width:'100%',height:'44px'}}>
      
       <Form.Item
                                name="location"
                                rules={[{ required: true, message: 'Please input your location!' }]}
                            >
                              <PlacesAutocomplete
                              

        value={address}
        onChange={handleChange1}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input {...getInputProps({ placeholder: 'Search address' })} style={{width:'80%',height:'44px'}}  />
            {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
            <div className={_.size(suggestions)>0?"create-template-location-dropdown":""} >
              {loading ? <div>Loading...</div> : null}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
                  marginTop:10,
                };
                
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })} style={{cursor:'pointer',width:'100%',paddingTop:'6px',paddingLeft:'6px'}} className="create-template-location-search-list">
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                               
                            </Form.Item>
       </div>
    </div>
    <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'50%'}}>
              <div><span className="create-template-field-title">Province {requiredRender}</span> </div>
<div className="create-template-field-des" style={{width:'80%'}}>Select a province</div>
       </div>
       <div  style={{width:'100%',height:'44px'}}>
       <Form.Item name='tax_state'   rules={[
          {
            required: true,
            message: 'Please input your province!',
          },
        ]}>
          <Select onChange={() => setChangesHappened(true)}
          onSelect={(e)=>{
            commonSetFields('tax_state',e)
          }}
          style={{width:'80%',height:'44px'}} disabled={form.getFieldValue('currency')?false:true} getPopupContainer={trigger => trigger.parentNode}
                                    placeholder={'Select a province'}
                                >
                                    { _.map(getFilterTax(TaxListData,form.getFieldValue('currency')),(d,i) => {
                                      
                                      return(
                                        <Select.Option  key={_.get(d,'id')}>{_.startCase(_.get(d,'state'))}</Select.Option>
                                      )
                                      })}
                                </Select>
        </Form.Item>

       </div>
    </div>
    <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Postal Code {requiredRender}</span> 
<div className="create-template-field-des" style={{width:'80%'}}>Confirm or edit the postal code</div>
       </div>
      
    </div>
    <div  style={{width:'80%',height:'44px'}}>
    <Form.Item name='zip_code'   rules={[
          {
            required: true,
            message: 'Please input your Postal / Zip Code!',
          },
        ]}>
       <Input onChange={(e) => {setChangesHappened(true);commonSetFields('zip_code',e.target.value)}} style={{width:'80%',height:'44px'}} />
</Form.Item>

       </div>
    </div>
    <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'50%'}}>
              <div><span className="create-template-field-title">Currency {requiredRender}</span> </div>
<div className="create-template-field-des" style={{width:'80%'}}>This is not editable and will be based on the location of the session</div>
       </div>
       <div  style={{width:'100%',height:'44px'}}>
       <Form.Item name='currency'   rules={[{
            required: true,
            message: 'Please input your Currency!',
          },
        ]}>
  <Select
  style={{width:'80%',height:'44px'}}
  value={country}
  onChange={() => setChangesHappened(true)}
  disabled
    optionFilterProp="children">
   {_.map(currencyList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select></Form.Item>

       </div>
    </div>

    <Divider />
    <div className="create-template-field-container" >
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Price per registration {requiredRender}</span> 
<div className="create-template-field-des" style={{width:'80%'}}>Set your price before any applicable taxes or platform fees</div>
       </div>
      
    </div>
    <div  style={{width:'80%',height:'44px'}}>
    <Form.Item name='host_price'   rules={[
          {
            required: true,
            message: 'Please input your price!',
          },
        ]}>
    <InputNumber type="number" min={1} onChange={(e) => {setChangesHappened(true);commonSetFields('host_price',e);}}
     prefix={form.getFieldValue('currency')? `${form.getFieldValue('currency')} $`:'$'} style={{width:'80%',height:'44px'}} className="create-template-price" />

</Form.Item>
       </div>
    </div>
    <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Spaces {requiredRender}</span> 
<div className="create-template-field-des" style={{width:'80%'}}>How many slots are available for this program - this can be changed later at any time</div>
       </div>
      
    </div>
    <div  style={{width:'80%'}}>
    {/* <Space.Compact block> */}
    <Form.Item name='max_no_of_openings'   rules={[
          {
            required: true,
            message: 'Please input your Max no of openings!',
          },
        ]}>
      <Input min={1} type="number"
      onChange={(e) => {setChangesHappened(true);setsetNoOfBookings(e.target.value);commonSetFields('max_no_of_openings',e.target.value)}}
        style={{
          width: '80%',
          height:'44px'
        }}
       
      />
      </Form.Item>
      {/* <Button type="primary" style={{height:'44px',backgroundColor:'#fff'}} className="create-template-space-text">Spaces</Button> */}
    {/* </Space.Compact> */}


       </div>
    </div>

    {form.getFieldValue("type")=="league" && <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Team size {requiredRender}</span> 
<div className="create-template-field-des" style={{width:'80%'}}>Choose the number of players in each team</div>
       </div>
      
    </div>
    <div  style={{width:'80%'}}>
    {/* <Space.Compact block> */}
    <Form.Item name='team_size'   rules={[
          {
            required: true,
            message: 'Please input your team size!',
          },
        ]}>
      <Input min={1} type="number"
      onChange={(e) => {setChangesHappened(true);commonSetFields('max_no_of_openings',e.target.value)}} defaultValue={_.get(TemplateViewDetails, 'meta_details.team_size')}
      
        style={{
          width: '80%',
          height:'44px'
        }}
       
      />
      </Form.Item>
      {/* <Button type="primary" style={{height:'44px',backgroundColor:'#fff'}} className="create-template-space-text">Spaces</Button> */}
    {/* </Space.Compact> */}


       </div>
    </div>}
    <Divider />
    <div className="create-template-field-container" >
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Additional info</span> 
<div className="create-template-field-des" style={{width:'80%'}}>In this section you can provide users more information, and links to material you have about this session. For example, you may have rule books, parking information, policies or FAQs that you want to provide users information.</div>
       </div>
      
    </div>
    <div  style={{width:'80%',display:'flex',flexDirection:'column'}}>

      {moreAboutArray.length>0 && renderAdditionalInfo(moreAboutArray)}
      {moreAboutArray.length>0 && <div style={{marginBottom:'16px'}} onClick={()=>{cancelAddtionalInfo();setMoreAboutInputShow(true);setMoreAboutObject({})}}>
        <Button className="more_about_add_another_btn" style={{height:'44px'}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_10780_659)">
    <path d="M10.0001 6.6665V13.3332M6.66675 9.99984H13.3334M18.3334 9.99984C18.3334 14.6022 14.6025 18.3332 10.0001 18.3332C5.39771 18.3332 1.66675 14.6022 1.66675 9.99984C1.66675 5.39746 5.39771 1.6665 10.0001 1.6665C14.6025 1.6665 18.3334 5.39746 18.3334 9.99984Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10780_659">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
          <div style={{marginLeft: "8px"}}>Add another</div>
        </Button>
        
         
        
        </div>}
        {(_.size(moreAboutArray)==0 || moreAboutInputShow) &&<>
<span className="create-template-field-title" >Title</span> 
<Input placeholder="FAQ" value={moreAboutTitle} onChange={(e)=>{setMoreAboutTitle(_.get(e,'target.value')); setChangesHappened(true)}} style={{width:'80%',height:'44px',marginTop:'6px',marginBottom:'16px'}} />
<div  >
<ReactQuill 
          placeholder="Tell us more about this session"
          className="reactquill-container"
          style={{width:'80%'}}
          theme="snow" 
          ref={reactQuillAditioninfoRef}
          value={moreAboutDes}
          onChange={(e)=>{setMoreAboutDes(e); setChangesHappened(true)}}
           modules={modules}
           formats={formats}
          //  onKeyDown={checkCharacterCount}
           />
           </div>
           {/* <div className="react-quill-des" style={{marginTop:'6px',marginBottom:'16px'}}>3000 characters limit</div> */}
           <span className="create-template-field-title" style={{marginTop: "16px"}}>Link (optional)</span> 
           <Input placeholder="www.mywebsite.com/faq" style={{width:'80%',height:'44px',marginTop:'6px',marginBottom:'16px'}}
           value={moreAboutLink} onChange={(e) =>{setMoreAboutLink(_.get(e, 'target.value')); setChangesHappened(true)}}
           />
           <div style={{display:'flex'}}>
           <Button styles={{icon:{marginRight:'-6px',marginInlineEnd:'0px'}}} 
           onClick={()=>{
            setMoreAboutDetails()
           }}
           icon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M12.5 6.66667H7.16667C6.69996 6.66667 6.4666 6.66667 6.28834 6.57584C6.13154 6.49594 6.00406 6.36846 5.92416 6.21166C5.83333 6.0334 5.83333 5.80004 5.83333 5.33333V2.5M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>} className="create-template-add-info" style={{marginRight:'15px'}}>{_.isEmpty(moreAboutObject)?"Add":"Update"}</Button>
          {!_.isEmpty(moreAboutObject)&& <Button  
           onClick={()=>{
            setMoreAboutDetails()
           }}
            className="create-template-add-info-cancel" style={{marginLeft:'16px !important'}}>Cancel</Button>}
           </div>
           </>}
       </div>
    </div>
    <Divider />
    <div className="create-template-field-container" >
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Clauses</span> 
<div className="create-template-field-des" style={{width:'80%'}}>In this section you can provide clauses users need to consent to before booking your session</div>
       </div>
      
    </div>
    <div  style={{width:'80%',display:'flex',flexDirection:'column'}}>
      {clauseArray &&_.size(clauseArray)>0 &&loadClausesList(clauseArray)}
      {clauseArray.length>0 && <div style={{marginBottom:'16px'}} onClick={()=>{setClauseInputShow(true);setclauseObject({})}}>
        <Button className="more_about_add_another_btn" style={{height:'44px'}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_10780_659)">
    <path d="M10.0001 6.6665V13.3332M6.66675 9.99984H13.3334M18.3334 9.99984C18.3334 14.6022 14.6025 18.3332 10.0001 18.3332C5.39771 18.3332 1.66675 14.6022 1.66675 9.99984C1.66675 5.39746 5.39771 1.6665 10.0001 1.6665C14.6025 1.6665 18.3334 5.39746 18.3334 9.99984Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10780_659">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
          <div style={{marginLeft: "8px"}}>Add another</div>
        </Button>
        </div>}
      {(_.size(clauseArray)==0 || clauseInputShow) &&<>
<span className="create-template-field-title" >Clause title</span> 
<Input placeholder="Rowans law" style={{width:'80%',height:'44px',marginTop:'6px',marginBottom:'16px'}}
value={clauseTitle} onChange={(e)=>{setclauseTitle(_.get(e,'target.value')); setChangesHappened(true)}}
/>
<div style={{marginBottom:'16px'}}>
<Segmented className="create-template-segmented"
style={{height:'44px'}}
    options={[{label:'Description',value:"description"},{label:"Link",value:"link"}]}
    onChange={(value) => {
       // string
      setClauseOptions(value)
    }}
  />
</div>
{getClauseOptions=="link"?<div style={{marginBottom:'16px',display:'flex',flexDirection:'column'}}>
<Input type='url' value={clauseLink} onChange={(e)=>{onChangeClauseUrl(e); setChangesHappened(true)}}  style={{width:'80%',height:'44px'}} />
{isValidUrl}
</div>:<>
<div  >
<ReactQuill 
          placeholder="Tell us more about the conditions of the clauses"
          className="reactquill-container"
          style={{width:'80%'}}
          theme="snow" 
          ref={reactQuillClasusesRef}

          value={clauseDes}
          onChange={(e)=>{setclauseDes(e); setChangesHappened(true)}}
           modules={modules}
           formats={formats}
          //  onKeyDown={checkCharacterCount}
           />
           </div>
           {/* <div className="react-quill-des" style={{marginTop:'6px',marginBottom:'16px'}}>3000 characters limit</div> */}
           </>}
           <div style={{display:'flex', marginTop: "16px"}}>
           <Button styles={{icon:{marginRight:'-6px',marginInlineEnd:'-6px'}}} 
           onClick={()=>{
            setClauseDetails()
           }}
           icon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M12.5 6.66667H7.16667C6.69996 6.66667 6.4666 6.66667 6.28834 6.57584C6.13154 6.49594 6.00406 6.36846 5.92416 6.21166C5.83333 6.0334 5.83333 5.80004 5.83333 5.33333V2.5M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>} 
           className="create-template-add-info" style={{marginRight:'15px'}}>{_.isEmpty(clauseObject)?"Add":"Update"}</Button>
          {!_.isEmpty(clauseObject)&& <Button  
           onClick={()=>{
            setClauseDetails()
           }}
            className="create-template-add-info-cancel" style={{marginLeft:'16px !important'}}>Cancel</Button>}
           </div>
           {/* <Button styles={{icon:{marginRight:'-6px',marginInlineEnd:'0px'}}} icon={<LuPlusCircle color="#344054" style={{height:'20px',width:'20px'}} />} className="create-template-add-info">Add</Button> */}

           </>}
       </div>
    </div>
    <Divider />
    <div className="create-template-field-container" style={{marginTop:'20px'}}>
       <div style={{width:'40%'}}>
              <div><span className="create-template-field-title">Pictures {requiredRender}</span> 
<div className="create-template-field-des" style={{width:'80%'}}>Add pictures to showcase your listing, users tend to respond more positively to listing with many images</div>
       </div>
      
    </div>
    <div  style={{width:'80%',height:'145px'}} >
    <Dragger  onChange={onChangePhoto} accept=".jpg,.jpeg" 
    multiple={true}
    style={{width:'80%'}}
showUploadList={false}
    className="create-template-dragger"
                                    customRequest={dummyRequest}>
   <div>
    <Image style={{width:'50px',height:'50px'}} preview={false} src={require('../../../assets/img/template/upload-icon.png')} />
    

  
    <div className="create-template-upload-text" style={{marginTop:'16px',marginBottom:'4px'}}><span style={{color:"#EF6820",fontWeight:600}}>Click to upload</span> <span>or drag and drop</span></div>
    <div className="create-template-upload-text-des" style={{marginBottom:'16px'}}>PNG, JPG or JPEG (max 5MB/file)</div>
    
    </div>
  </Dragger>


       </div>
    </div>

    {fileList.length>0 && <div className="create-template-field-container" style={{marginTop:'20px'}}>
<div style={{width:'40%'}}></div>
<div style={{width:'80%',display:'flex',flexWrap:'wrap'}}>
      {_.map(fileList, (v,i)=>{

        
        return <div key={i} style={{width:'160px',height:'160px',marginLeft:'16px',marginBottom:'16px'}}>
<div>
  <div style={{position:'absolute',display:'flex',justifyContent:'flex-end',alignItems:'flex-end',width:'158px',marginTop:'4px',marginRight:'12px'}} >
  <svg onClick={()=>{

let data =_.filter(fileList,(d,i)=>d?.uid!=v.uid)
setFileList(data)
if(v?.id){
  removeImage(v)
}

  }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{cursor:'pointer'}}>
  <path d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  </div>
<img  src={v?.url?v.url: v?.imageUrl} alt="Logo" style={{maxWidth:'160px',maxHeight:'160px', width: 'auto', height: 'auto'}} />
</div>

        </div>
      })}</div>
      </div>}
    <Divider />
    <div className="create-template-field-container"  onClick={()=>{
     
      if(changesHappened==true && checkValidation()==false){
        form.submit()
      }
    }}  style={{marginTop:'20px',justifyContent:'center',cursor:'pointer',marginBottom:'20px'}}>

      <div className={(checkValidation()==false &&changesHappened==true)?"create-template-save-button-active":"create-template-save-button"}  style={{display:'flex',alignItems:'center',paddingLeft:'20px',paddingRight:'20px'}}>
      <SaveOutlined size={24} color="#fff" style={{marginRight:'8px',color:'#fff',width:'24px',height:'24px'}} />  
      <div>{searchParams.get('id')?"Update":"Save"} template</div>
      </div>
      {/* <Button className="create-template-save-button" styles={{icon:{marginRight:'8px',color:'#fff',width:'24px',height:'24px'}}} 
      icon={<SaveOutlined size={24} color="#fff" />}>Save template</Button> */}
    </div>
    </Form>
          </div>
          
          </Card>
            <Modal centered={true} width="45%" className='appropriateTemplateModal'  footer={null} title="Selecting the appropriate Template type" open={templateTypeModal.visable} onCancel={() => settemplateTypeModal({visable:false,type:1})}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Radio.Group  defaultValue="a" size="large">
            <Radio.Button className='radio-scheduled-template' onClick={() => settemplateTypeModal({visable:true,type:1})} value="a"><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M17.5 9.58317V7.33317C17.5 5.93304 17.5 5.23297 17.2275 4.69819C16.9878 4.22779 16.6054 3.84534 16.135 3.60565C15.6002 3.33317 14.9001 3.33317 13.5 3.33317H6.5C5.09987 3.33317 4.3998 3.33317 3.86502 3.60565C3.39462 3.84534 3.01217 4.22779 2.77248 4.69819C2.5 5.23297 2.5 5.93304 2.5 7.33317V14.3332C2.5 15.7333 2.5 16.4334 2.77248 16.9681C3.01217 17.4386 3.39462 17.821 3.86502 18.0607C4.3998 18.3332 5.09987 18.3332 6.5 18.3332H10.4167M17.5 8.33317H2.5M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M15 17.4998V12.4998M12.5 14.9998H17.5" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
              <span style={{marginLeft:'8px'}} className="modal-radio-title">Scheduled</span></Radio.Button>
            
            <Radio.Button className='radio-scheduled-template' onClick={() => settemplateTypeModal({visable:true,type:2})} value="c"><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_10689_454)">
    <path d="M9.99999 12.4998C7.23857 12.4998 4.99999 10.2613 4.99999 7.49984V2.87021C4.99999 2.52533 4.99999 2.3529 5.05025 2.21482C5.1345 1.98335 5.31683 1.80101 5.54831 1.71676C5.68638 1.6665 5.85882 1.6665 6.20369 1.6665H13.7963C14.1412 1.6665 14.3136 1.6665 14.4517 1.71676C14.6831 1.80101 14.8655 1.98335 14.9497 2.21482C15 2.3529 15 2.52533 15 2.87021V7.49984C15 10.2613 12.7614 12.4998 9.99999 12.4998ZM9.99999 12.4998V14.9998M15 3.33317H17.0833C17.4716 3.33317 17.6658 3.33317 17.8189 3.3966C18.0231 3.48118 18.1853 3.64341 18.2699 3.8476C18.3333 4.00074 18.3333 4.19489 18.3333 4.58317V4.99984C18.3333 5.77481 18.3333 6.1623 18.2481 6.48022C18.017 7.34295 17.3431 8.01682 16.4804 8.24799C16.1625 8.33317 15.775 8.33317 15 8.33317M4.99999 3.33317H2.91666C2.52837 3.33317 2.33423 3.33317 2.18109 3.3966C1.9769 3.48118 1.81467 3.64341 1.73009 3.8476C1.66666 4.00074 1.66666 4.19489 1.66666 4.58317V4.99984C1.66666 5.77481 1.66666 6.1623 1.75184 6.48022C1.98301 7.34295 2.65688 8.01682 3.51961 8.24799C3.83753 8.33317 4.22501 8.33317 4.99999 8.33317M6.20369 18.3332H13.7963C14.0008 18.3332 14.1667 18.1674 14.1667 17.9628C14.1667 16.3264 12.8401 14.9998 11.2037 14.9998H8.79629C7.15989 14.9998 5.83332 16.3264 5.83332 17.9628C5.83332 18.1674 5.99914 18.3332 6.20369 18.3332Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10689_454">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg></span>
              <span style={{marginLeft:'8px'}} className="modal-radio-title">League</span></Radio.Button>
            <Radio.Button className='radio-scheduled-template' onClick={() => settemplateTypeModal({visable:true,type:3})} value="b"><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M6.55722 12.8831L5.83333 18.3332L9.657 16.039C9.78171 15.9641 9.84406 15.9267 9.91063 15.9121C9.96951 15.8992 10.0305 15.8992 10.0894 15.9121C10.1559 15.9267 10.2183 15.9641 10.343 16.039L14.1667 18.3332L13.4433 12.8808M13.6882 3.54057C13.8169 3.85184 14.064 4.09925 14.375 4.22842L15.4658 4.68024C15.7771 4.80918 16.0244 5.0565 16.1533 5.36779C16.2822 5.67908 16.2822 6.02884 16.1533 6.34013L15.7018 7.43013C15.5728 7.74156 15.5726 8.09168 15.7022 8.40295L16.1529 9.49263C16.2168 9.64681 16.2498 9.81207 16.2498 9.97897C16.2498 10.1459 16.2169 10.3111 16.1531 10.4653C16.0892 10.6195 15.9956 10.7596 15.8775 10.8776C15.7595 10.9956 15.6194 11.0892 15.4652 11.1531L14.3752 11.6045C14.064 11.7332 13.8166 11.9803 13.6874 12.2914L13.2356 13.3821C13.1067 13.6934 12.8593 13.9408 12.5481 14.0697C12.2368 14.1986 11.887 14.1986 11.5758 14.0697L10.4858 13.6182C10.1745 13.4896 9.82489 13.4898 9.5138 13.6189L8.42306 14.0701C8.11195 14.1987 7.76251 14.1986 7.45148 14.0698C7.14045 13.941 6.89328 13.6939 6.76426 13.383L6.31232 12.2919C6.18363 11.9806 5.93659 11.7332 5.62553 11.604L4.53479 11.1522C4.22365 11.0233 3.97641 10.7762 3.84743 10.465C3.71844 10.1539 3.71827 9.80434 3.84694 9.49309L4.29841 8.4031C4.42703 8.0918 4.42677 7.74216 4.29768 7.43106L3.84686 6.33949C3.78294 6.18532 3.75003 6.02005 3.75 5.85315C3.74997 5.68625 3.78283 5.52097 3.8467 5.36678C3.91057 5.21258 4.0042 5.07247 4.12223 4.95447C4.24026 4.83647 4.38039 4.74289 4.5346 4.67907L5.62456 4.22758C5.93554 4.09899 6.1828 3.85225 6.31206 3.54154L6.76386 2.45076C6.8928 2.13947 7.14011 1.89215 7.45139 1.76321C7.76267 1.63427 8.11242 1.63427 8.4237 1.76321L9.51365 2.2147C9.82494 2.34332 10.1746 2.34306 10.4857 2.21397L11.5769 1.76391C11.8881 1.63504 12.2378 1.63507 12.549 1.76398C12.8602 1.8929 13.1075 2.14015 13.2364 2.45136L13.6884 3.54246L13.6882 3.54057Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span><span style={{marginLeft:'8px'}} className="modal-radio-title">Open</span></Radio.Button>
            </Radio.Group>
             </div>
             
             {
               templateTypeModal.type ==1 ? 
                <div className='session-type-modal' style={{marginTop:"40px"}}>
             <div className="create-template-modal-description">Use a scheduled template when you have the exact date(s) and time(s) that users can book a session with you.</div>
             <div className="create-template-modal-description" style={{marginTop:'13px'}}>This template type is best for programs, clinics or when you have booked a space and know definitively that you will be available at that time.</div>
             </div> : templateTypeModal.type ==2 ? <div className='session-type-modal' style={{marginTop:"35px"}}>
             <div className="create-template-modal-description" >Use a league template when you are setting up specific tournament style sessions. For instance, you’ve booked a court, field or rink for a specific day and want to allow for people to sign up to play.</div>
             <div className="create-template-modal-description" style={{marginTop:'13px'}}>League templates are best suited towards one-day tournaments, or pick-up style games for teams where scores are recorded</div>
             </div> :templateTypeModal.type ==3 ? <div className='session-type-modal' style={{marginTop:"35px"}}>
             <div className="create-template-modal-description">Use an open template when you want to leave a session schedule open ended. For instance, you offer private coaching lessons, but want to coordinate with the user to settle on a date and time.</div>
             <div className="create-template-modal-description" style={{marginTop:'13px'}}>This template type is best for private lessons where you work with your client to find days and times that work best for the two of you.</div>
             </div> : null
             }
             
      </Modal>
      </div>
    }
    
    const mapStateToProps = state => {
    
      return {
        checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
        isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
        islogindata: _.get(state, 'app.account.isLogin'),
        userProfile: _.get(state, 'app.host.profile.data.details'),
    
      }
    }
    
    const mapDispatchToProps = dispatch => ({
      hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
    list: details => dispatch(global.redux.action.categories.list(details)),
    hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
    createTemplate: details => dispatch(global.redux.action.template.create(details)),
    updateTemplate: details => dispatch(global.redux.action.template.update(details)),
    addPhotoTemplate: details => dispatch(global.redux.action.template.addPhoto(details)),
    taxList: details => dispatch(global.redux.action.tax.list(details)),
    getLocations: details => dispatch(global.redux.action.host.getLocation(details)),
    viewTemplate: details => dispatch(global.redux.action.template.view(details)),
    deletePhoto: details => dispatch(global.redux.action.template.deletePhoto(details)),
    archiveActiveTemplates: details => dispatch(global.redux.action.template.changeStatusTemplate(details)),
    questionSetsList: details => dispatch(global.redux.action.questions.questionSetsList(details)),
    couponList: details => dispatch(global.redux.action.promotion.couponList(details)),

    });
    export default connect(mapStateToProps, mapDispatchToProps)(TemplateCreateStep1)