const { Box, Typography, useTheme, useMediaQuery } = require('@mui/material')

const RightFooter =()=>{
       const theme = useTheme();
       const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
       return isMobile ?  <Box 
       sx={{
         width: "100%",
         justifyContent: "space-between",
         alignItems: "center",
         display: "flex",
         flexDirection:{
           sx:"column",
           sm: "column", // tablet
           md: "column", // small desktop
           lg: "column", // large desktop
         },
         paddingLeft: {
           xs: "16px", // mobile
           sm: "22px", // tablet
           md: "28px", // small desktop
           lg: "32px", // large desktop
         },
         paddingRight: {
           xs: "16px", // mobile
           sm: "22px", // tablet
           md: "28px", // small desktop
           lg: "32px", // large desktop
         },
       
       }}
 
       >
       <Typography
           sx={{color:"#475467",fontSize:'13px',fontWeight:'400'}}
           >
             © 2024 Communiti Inc.
           </Typography>
           <Box sx={{
             display:'flex',
             alignItems:'center'
           }}>
           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M1.33334 4.66699L6.77662 8.47729C7.2174 8.78583 7.43779 8.94011 7.67752 8.99986C7.88927 9.05265 8.11075 9.05265 8.3225 8.99986C8.56223 8.94011 8.78262 8.78583 9.2234 8.47729L14.6667 4.66699M4.53334 13.3337H11.4667C12.5868 13.3337 13.1468 13.3337 13.5747 13.1157C13.951 12.9239 14.2569 12.618 14.4487 12.2416C14.6667 11.8138 14.6667 11.2538 14.6667 10.1337V5.86699C14.6667 4.74689 14.6667 4.18683 14.4487 3.75901C14.2569 3.38269 13.951 3.07673 13.5747 2.88498C13.1468 2.66699 12.5868 2.66699 11.4667 2.66699H4.53334C3.41324 2.66699 2.85319 2.66699 2.42536 2.88498C2.04904 3.07673 1.74308 3.38269 1.55133 3.75901C1.33334 4.18683 1.33334 4.74689 1.33334 5.86699V10.1337C1.33334 11.2538 1.33334 11.8138 1.55133 12.2416C1.74308 12.618 2.04904 12.9239 2.42536 13.1157C2.85319 13.3337 3.41324 13.3337 4.53334 13.3337Z" stroke="#667085" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
 
             
 
           <Typography
           sx={{marginLeft:'8px',color:"#475467",fontSize:'13px',fontWeight:'400'}}
             >
            support@communiti.app
           </Typography>
           </Box>
       </Box>:<></>
}

export default RightFooter;