import _ from 'lodash';

import chatKittyAPI from '../../chatkitty/api';

const sendMessageToMultipleRecipient = (criteria, processedRecipientCount=0)=>{
    const recipientDetailsList = _.get(criteria, 'recipientDetailsList', []);
    if(_.chain(processedRecipientCount).add(1).lte(_.size(recipientDetailsList)).value()){
        const recipientId = _.chain(recipientDetailsList).nth(processedRecipientCount).get('chatkitty_user_id', null).toString().value();
        if(!_.isEmpty(recipientId)){
            const message = _.get(criteria, 'message', '');
            const currentChatKittyUserId = _.get(criteria, 'currentChatKittyUserId', null);
            criteria.updateOverlayTextContent(`Sending Message to athlete ${_.add(processedRecipientCount, 1)} of ${_.size(recipientDetailsList)}`);
            sendMessageToSpecificRecipient(currentChatKittyUserId, _.parseInt(recipientId), message, (response, data)=>{
                if(response){
                    recipientDetailsList[processedRecipientCount].chatKitty = {
                        messageSent: true,
                        ...data
                    }
                }else{
                    recipientDetailsList[processedRecipientCount].chatKitty = {
                        messageSent: false,
                        ...data
                    } 
                }
                sendMessageToMultipleRecipient(criteria, _.add(processedRecipientCount, 1));
            });
        }else{
            recipientDetailsList[processedRecipientCount].chatKitty = {
                messageSent: false,
                message: 'chatKitty id is empty for the user, since sending message to him it is not possible'
            };
            sendMessageToMultipleRecipient(criteria, _.add(processedRecipientCount, 1));
        }
    }else{
        if(_.chain(criteria).get('callback', null).isFunction().value()){
            const recipientDetailsList = _.get(criteria, 'recipientDetailsList', []);
            _.get(criteria, 'callback')(recipientDetailsList);
        }
    }
};

const createChannel = async(authorizationData, currentChatKittyUserId, recipientId, failureCallback, successCallback)=>{
    chatKittyAPI.createChannel({
        body: {
            "type": "DIRECT",
            "creator": {
              "id": currentChatKittyUserId
            },
            members: [{ id: recipientId }]
        },
        ..._.pick(authorizationData, ['access_token']) 
        }, (response, data)=>{
        if (response) {
            successCallback(data);
        }else{
            const error = {
                message: 'Error in createChannel.',
                data
            };
            failureCallback(response, error);
        }
    });
};

const getAccessToken = (failureCallback, successCallback)=>{
    chatKittyAPI.getAccessToken((response, data)=>{
        if (response) {
            successCallback(data);
        }else{
            const error = {
                message: 'Error in getting access token from chatKitty API.',
                data
            };
            failureCallback(response, error);
        }
    });
};

const createChannelMessage = async(authorizationData, currentChatKittyUserId, message, channel, failureCallback, successCallback)=>{
    chatKittyAPI.createChannelMessage({
        body: {
            "type": "TEXT",
            "body": message,
            "user": {"id": currentChatKittyUserId}
        },
        ..._.pick(authorizationData, ['access_token']),
        channel_id: _.get(channel, 'id', null)
        }, (response, data)=>{
        if (response) {
            successCallback(response, {
                message: 'Message sent to the user successfully.'
            });
        }else{
            const error = {
                message: 'Error in createChannelMessage.',
                data
            };
            failureCallback(response, error);
        }
    });
};

const sendMessageToSpecificRecipient=(currentChatKittyUserId, recipientId, message, callback)=>{
    try{
        getAccessToken(callback, authorizationData=>{
            createChannel(authorizationData, currentChatKittyUserId, recipientId, callback, channel=>{
                createChannelMessage(authorizationData, currentChatKittyUserId, message, channel, callback, callback);
            });
        });
    }catch(e){
        
    }
};

export default sendMessageToMultipleRecipient;