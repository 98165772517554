import React, {useState, useEffect} from 'react';
import { Button, Table, Tag, Modal, Input, Select, Menu, Space,Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, ArrowLeftOutlined, FileTextOutlined, MailOutlined, MoreOutlined, SettingOutlined, AppstoreOutlined, SmileOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from "react-redux";
import _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import '../onboarding-screen/style.css';
import './style.css';
import axios from 'axios';


const ref = React.createRef();

const {confirm} =Modal

const { Option } = Select;
const userColumns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (text, item) => {

return <div style={{display:'flex',flexDirection:'row'}}><span className={_.get(item,'status')=='2'?"dot-circle":'test-dot'}></span> <Button   style={{backgroundColor:_.get(item,'color_code'),
fontWeight:_.get(item,'status')=='2'?'bolder':'normal',borderRadius:'23px',color:'white'}}
    >{text}</Button></div>},
  },
  {
    title: 'Date/Time',
    dataIndex: 'date_time',
    key: 'date_time',
    render: (text, item) => {

        return <div style={ _.get(item,'status')=='2'?{fontWeight:'bolder'}:{}}>{text}</div>
          }
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (text, item) => {

        return <div style={ _.get(item,'status')=='2'?{fontWeight:'bolder'}:{}}>{text}</div>
          }
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (text, item) => {

        return <div style={ _.get(item,'status')=='2'?{fontWeight:'bolder'}:{}}>{text}</div>
          }
  },
 
];


const NotificationsList =(props)=>{
    let navigate = useNavigate();

    const[notificationsListData, setNotificationsListData] = useState([]);
    const[notificationsListMetaData, setNotificationsListMetaData] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [notificationsDetails, setNotificationsDetails] = useState({});
    const [count, setCount] = useState(1);
    const [pageNumber, setPageNumber] = useState(0)
    const [filterValue, setFilterValue] = useState('');
    const [oneMonthFilter, setOneMonthFilter] = useState('');
    const [twoMonthFilter, setTwoMonthFilter] = useState('');
    const [threeMonthFilter, setThreeMonthFilter] = useState('');
    const [pageLoading, setPageLoading] = useState(true)

    const[loadMoreValues, setLoadMoreValues] = useState([])
    const [viewModal, setViewModal] = useState(false)
    const [typeofFilter, setTypeofFilter] = useState('all')
    const [dateofFilter, setDateofFilter] = useState('all');
    const [dateofFilterDetails, setDateofFilterDetails] = useState({})
    const [timeZone, setTimeZone] = useState('')
    const[notitificationIds, setNotificationsIds] = useState([])

    const filter_types =[
    // {id:'booking',color:'#f04732'},
    {id:'cancellation',color:'#f04732'},
    {id:'registration',color:'#58b14d'},
    {id:'system',color:'#2754b1'},
    {id:'chat',color:'#f68a31'}
]

    useEffect(() => {
        notificationsList();
    }, []);

    const notificationsList = (pageSize=10,filter={}) => {
      setPageLoading(true)
      _.get(filter,'type')=='all' && delete filter.type
            props.notificationsListApi({
                params: {
                sort: 'created_at.desc',
                limit: pageSize,
                user_type:1,
                ...filter
                },
                callback: (response, data) => {
                    if(response){
                        setNotificationsListData(data)
                        setNotificationsListMetaData(data)
                        setPageNumber(_.get(data, 'data.meta.page'))
                    }
                    setPageLoading(false)
                }});
        
    }

    const handleScroll = (data, type) => {
      if (pageLoading==false && data && data.top > 0.9 && _.get(notificationsListMetaData,'data.meta.total') > 10) {
          if( _.size(_.get(notificationsListData, 'data.items'))<_.get(notificationsListMetaData,'data.meta.total')){
            notificationsList(_.get(notificationsListMetaData,'data.meta.limit')+10)
          }
              
          
      } 
  
  }
  const filter_types_changes =(value)=>{
      setTypeofFilter(value)
      if(value=='all'){
        notificationsList(10,{...dateofFilterDetails})
      }else{
        notificationsList(10,{...dateofFilterDetails,type:value})
      }
     

  }
  useEffect(() => {
    getLocationsData()
 
  
}, [])
const getLocationsData =async()=>{
    const { data, status } = await axios.get('https://ipapi.co/json')
    // setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
  await  setTimeZone(_.get(data,'timezone'))
  
  }
    const dateFilter = (value) => {
        let filterDate1 = moment(new Date()).format("YYYY-MM-DD")
        var time = moment("12:00 am", ["hh:mm a"]).format("HH:mm");
        var time1 = moment().format("HH:mm");
        let validate_date = value=='30_days'?1:value=='60_days'?2:value=='90_days'?3:1
        const a = momentTimezone().subtract(validate_date,'months').tz(timeZone).format("YYYY-MM-DD");
        const b = momentTimezone().tz(timeZone).format("YYYY-MM-DD")
        let min_date = momentTimezone(`${a}T${time}`).tz(timeZone).toISOString()
        let max_date = momentTimezone(`${b}T${time1}`).tz(timeZone).toISOString()
      setFilterValue(value)
     setDateofFilter(value)
      if(value === '30_days'){
        setDateofFilterDetails({ min_date: min_date,
            max_date: max_date,
            sort: 'created_at.desc',
            user_type: 1,
            type:typeofFilter
            })
        notificationsList(10,{ min_date: min_date,
            max_date: max_date,
            sort: 'created_at.desc',
            user_type: 1,
            type:typeofFilter
            })
        
      };

      if(value === '60_days'){
        setDateofFilterDetails({
            min_date: min_date,
            max_date: max_date,
            sort: 'created_at.desc',
            user_type: 1,
            type:typeofFilter
            })
        notificationsList(10,{
            min_date: min_date,
          max_date: max_date,
          sort: 'created_at.desc',
          user_type: 1,
          type:typeofFilter
          });
      };
      
      if(value === '90_days') {
        setDateofFilterDetails({
            min_date: min_date,
            max_date: max_date,
            sort: 'created_at.desc',
             user_type: 1,
             type:typeofFilter
            })
        notificationsList(10,{
            min_date: min_date,
          max_date: max_date,
          sort: 'created_at.desc',
           user_type: 1,
           type:typeofFilter
          });
      };

      if(value === 'all') {
        setDateofFilterDetails({
            sort: 'created_at.desc',
            user_type: 1,
            type:typeofFilter
            })
        notificationsList(10, {
          sort: 'created_at.desc',
          user_type: 1,
          type:typeofFilter
          });
      };
    };

    

   

   

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setNotificationsIds(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
          disabled: record.name === 'Disabled User',
          // Column configuration not to be checked
          name: record.name,
        }),
      };
      const capitalizeFirstLetter=(string)=> {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const notificationSetting =(type)=>{


        if(type=='delete' && _.size(notitificationIds)>0){
            requestDelete(notitificationIds)
        }else if(type=='read' && _.size(notitificationIds)>0){
            setPageLoading(true)
                props.notificationsReadApi({
                    params:{ids:_.size(_.get(notificationsListData, 'data.items'))==_.size(notitificationIds)?['read_all']: notitificationIds,user_type:1},
                    body:{ids:notitificationIds,user_type:1},
                    callback:(res,data)=>{
                        setPageLoading(false)
                        if(res==true){
                            notificationsList()
                        }
                    }
                })
        }
      }
      const getOrderDetails =(value)=>{

        props.viewOrder({
           params:{id:_.get(value,'type_id'), expand:'experience,experience_template,experience,experience_template_photos,guest'},
           callback:(response,data)=>{
              
             if(response === true){
              props.notificationsReadApi({
                 params:{ids:[_.get(value,'id')],user_type:1},
                 body:{ids:[_.get(value,'id')],user_type:1},
                 callback:(res,data1)=>{
                    
                     if(res==true){
                       navigate(`/communiti/integrated-tabs?id=${_.get(data,'data.details.experience.template.id')}&exp_id=${_.get(data,'data.details.experience.id')}&template_type=${_.get(data,'data.details.experience.template.type')}&name_of_exp=${_.get(data,'data.details.experience.template.title')}&regTab=${'register_tab'}`)
                     }
                 }
             })
              
             }
           }
         })
     
       }
      const  requestDelete = (id)=>{
        confirm({
            icon:null,
            okText: 'Continue',
          
          cancelText: 'Cancel',
            content: <div style={{
      display:'flex',
      flexDirection:'column',
      alignItems:'center'
      
            }}><span style={{fontSize:'29px',fontWeight:'600'}}>Delete</span><span>Are you sure you want to delete this notification?</span></div>,
      
            onOk() {
                setPageLoading(true)
                props.notificationsDeleteApi({
                    params:{ids:notitificationIds,user_type:1},
                    body:{ids:notitificationIds,user_type:1},
                    callback:(res,data)=>{
                        setPageLoading(false)
                        if(res==true){
                            notificationsList()
                        }
                    }
                })
            },
      
            onCancel() {
              
              
            },
          });
    }
      const menu = (
        <Menu 
        className="menu-setting-style"
        // className="filter-notification-select"
          items={[
            {
              key: '1',
              label: <div onClick={()=>notificationSetting('read')}>Mark as Read</div>,
            },
            // {
            //   key: '2',
            //   label: <div onClick={()=>notificationSetting('unread')}>Mark as Unread</div>,
            // },
            {
                key: '3',
                label: <div onClick={()=>notificationSetting('delete')}>Delete</div>,
              },
            
          ]}
        />
      );
  const render =()=>{


  let tableSourceData = [];
  if(_.size(_.get(notificationsListData, 'data.items')) > 0){
    tableSourceData = _.get(notificationsListData, 'data.items').map((value, index) => {
        
        return{
            key: _.get(value, 'id'),
            title: capitalizeFirstLetter(_.get(value, 'label')),
            type: capitalizeFirstLetter(_.get(value, 'type')),
            date_time: moment(_.get(value, 'created_at')).format("MM/DD/YYYY | HH:mm"),
            description: _.get(value, 'description'),
            color_code:_.chain(filter_types).filter(['id',_.get(value, 'type')]).head().get('color').value(),
            status:_.get(value,'status'),
            details:value
            
        }
    })
  }


    return (
      <div>
        <div className='template-list-title'>
            <div>
    <span style={{fontSize:'22px',fontWeight:'600'}}>Notifications
    
    </span>
    <span> <Dropdown   overlay={menu}>
      <Space>
        <MoreOutlined style={{color:'#e75e2f',fontWeight:'bolder',fontSize:'23px'}} color={'#e75e2f'} />
      </Space>
    
  </Dropdown></span>
    </div>
   
    <div className='filter-by-text'>
      Filter By: 
      <span style={{marginLeft:'13px'}}>Type:</span>
    <Select  

    width="250px"
    className='filter-by-days'
    onChange={filter_types_changes}
    placeholder="Select Type"
    // open={true}
    value={typeofFilter}

    popupClassName="filter-notification-select"
    getPopupContainer={trigger => trigger.parentNode}
    >
    <Option value="cancellation">Cancellation</Option>
    <Option value="registration">Registration</Option>
    <Option value="system">System</Option>
    {/* <Option value="chat">Chat</Option> */}
    <Option value="all">All</Option>
    </Select>

    <span>Date:</span>
    <Select  

width="250px"
className='filter-by-days'
onChange={dateFilter}
placeholder="Select days"
value={dateofFilter}
popupClassName="filter-notification-select"
getPopupContainer={trigger => trigger.parentNode}
>
<Option value="30_days">Last 30 Days</Option>
<Option value="60_days">Last 60 Days</Option>
<Option value="90_days">Last 90 Days</Option>
<Option value="all">All</Option>
</Select>

    </div>
</div>
            <div className="template-list-table">
              <Scrollbars className="hidden-scroll-x"
              onScrollFrame={(e) => handleScroll(e)}
              style={{ height: 'calc(100vh - 125px)' }}
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
              >
              <Table 
             onRow={(record, rowIndex) => {
              return {
                onClick: event => {getOrderDetails(_.get(record,'details'))}, // click row
              };
            }}
                rowClassName={(record, index) => {
                  return  _.get(record,'status') == 2 ? 'table-row-light' :  'table-row-dark'}
                }
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              loading={pageLoading} dataSource={tableSourceData}
               columns={userColumns}
               className="notification-table"
                  
                  pagination={false}
              />
              </Scrollbars>
            </div>
            
      </div>
      
    );
  }
  return render();
}

const mapStateToProps = state => {
    return {
      notifications_order_list: _.get(state, 'app.notifications.list.data')
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    notificationsListApi: details => dispatch(global.redux.action.notifications.list(details)),
    notificationsDeleteApi: details => dispatch(global.redux.action.notifications.delete(details)),
    notificationsReadApi: details => dispatch(global.redux.action.notifications.read(details)),
    viewFinance: details => dispatch(global.redux.action.notifications.view(details)),   
    viewOrder: details => dispatch(global.redux.action.finances.view(details)),   

  });
  export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList)