import React, {useEffect, useState} from 'react';
import { Space, Table, Tag, Input, Select, Button, Modal, InputNumber, Image, Popconfirm, notification, message } from 'antd';
import {MinusCircleOutlined} from "@ant-design/icons";
import _, { parseInt } from 'lodash';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import socketIOClient from "socket.io-client";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import '../../../onboarding-screen/style.css';
import './build-team-style.css';

const { Option } = Select;


  const dynamicDataSource = [
    {
      key: 1,
      dyn_first_name: 'Kerish',
      dyn_last_name: 'Maharaj',
      dyn_gender: 'Male',
      dyn_skill_level: "Beginner",
      dyn_check_in_status: <Select 
      defaultValue='checked_in'
      options={[
        {
          value: 'checked_in',
          label: 'Checked-In',
        },
        {
          value: 'not_checked_in',
          label: 'Not Checked-In',
        },
        {
          value: 'coming_late',
          label: 'Coming Late',
        },
      ]}
      />,
      dyn_action: <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm>
    },
    {
        key: 2,
        dyn_first_name: 'Shashank',
        dyn_last_name: 'BK',
        dyn_gender: 'Male',
        dyn_skill_level: "Intermediate",
        dyn_check_in_status: <Select 
        defaultValue='checked_in'
        options={[
          {
            value: 'checked_in',
            label: 'Checked-In',
          },
          {
            value: 'not_checked_in',
            label: 'Not Checked-In',
          },
          {
            value: 'coming_late',
            label: 'Coming Late',
          },
        ]}
        />,
        dyn_action: <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm>
      },
      {
        key: 3,
        dyn_first_name: 'Suzanne',
        dyn_last_name: 'Wallace',
        dyn_gender: 'Female',
        dyn_skill_level: "Expert",
        dyn_check_in_status: <Select 
        defaultValue='checked_in'
        options={[
          {
            value: 'checked_in',
            label: 'Checked-In',
          },
          {
            value: 'not_checked_in',
            label: 'Not Checked-In',
          },
          {
            value: 'coming_late',
            label: 'Coming Late',
          },
        ]}
        />,
        dyn_action: <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm>
      },
      {
        key: 4,
        dyn_first_name: 'Shashank',
        dyn_last_name: 'BK',
        dyn_gender: 'Male',
        dyn_skill_level: "Beginner",
        dyn_check_in_status: <Select 
        defaultValue='checked_in'
        options={[
          {
            value: 'checked_in',
            label: 'Checked-In',
          },
          {
            value: 'not_checked_in',
            label: 'Not Checked-In',
          },
          {
            value: 'coming_late',
            label: 'Coming Late',
          },
        ]}
        />,
        dyn_action:  <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm>
        
      },
  ];

  

const BuildTeams = (props) => {

  const columns = [
    {
      title: <div className='build_teams_unassigned_table_column_title'>First Name</div>,
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: <div className='build_teams_unassigned_table_column_title'>Last Name</div>,
      dataIndex: 'last_name',
      key: 'last_name',
    },
    // {
    //   title: 'Skill level',
    //   dataIndex: 'skill_level',
    //   key: 'skill_level',
    // },
    // {
    //   title: 'Gender',
    //   dataIndex: 'gender',
    //   key: 'gender',
    // },
    {
      title: <div className='build_teams_unassigned_table_column_title'>Status</div>,
      dataIndex: 'status',
      key: 'status',
      // width: "20%"
    },
    {
      title: <div className='build_teams_unassigned_table_column_title'>Action</div>,
      width:'50%',
      key:'action',
      dataIndex:'action',
      render: (text, item) => {
          
          return(
          <div style={{display: "flex", flexDirection: "row"}}>
            {/* <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm> */}
            <button className='move_to_new_team_btn' onClick={() => triggerRemoveFromTeam(item)}> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.1667 7.50004L9.16667 12.5M9.16667 7.50004L14.1667 12.5M2.26667 10.8L5.86667 15.6C6.16 15.9912 6.30667 16.1867 6.49254 16.3277C6.65717 16.4527 6.8436 16.5459 7.04231 16.6026C7.26667 16.6667 7.51111 16.6667 8 16.6667H14.3333C15.7335 16.6667 16.4335 16.6667 16.9683 16.3942C17.4387 16.1545 17.8212 15.7721 18.0609 15.3017C18.3333 14.7669 18.3333 14.0668 18.3333 12.6667V7.33337C18.3333 5.93324 18.3333 5.23318 18.0609 4.6984C17.8212 4.22799 17.4387 3.84554 16.9683 3.60586C16.4335 3.33337 15.7335 3.33337 14.3333 3.33337H8C7.51111 3.33337 7.26667 3.33337 7.04231 3.39745C6.8436 3.4542 6.65717 3.54741 6.49254 3.67233C6.30667 3.81338 6.16 4.00893 5.86667 4.40004L2.26667 9.20004C2.05151 9.48692 1.94392 9.63036 1.90245 9.7879C1.86585 9.92696 1.86585 10.0731 1.90245 10.2122C1.94392 10.3697 2.05151 10.5132 2.26667 10.8Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>Remove from Team</button>
            <button className='move_to_new_team_btn' style={{marginLeft: "10px"}} onClick={() => showNewTeamModal(item)}> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_10824_1162)">
    <path d="M4.16699 7.50002L1.66699 10M1.66699 10L4.16699 12.5M1.66699 10H18.3337M7.50033 4.16669L10.0003 1.66669M10.0003 1.66669L12.5003 4.16669M10.0003 1.66669V18.3334M12.5003 15.8334L10.0003 18.3334M10.0003 18.3334L7.50033 15.8334M15.8337 7.50002L18.3337 10M18.3337 10L15.8337 12.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10824_1162">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>Move to New Team</button>
          </div>
          )
      },
      
      }
  ];

  const unAssignedColumns = [
    {
      title: <div className='build_teams_unassigned_table_column_title'>First Name</div>,
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: <div className='build_teams_unassigned_table_column_title'>Last Name</div>,
      dataIndex: 'last_name',
      key: 'last_name',
    },
    // {
    //   title: 'Skill level',
    //   dataIndex: 'skill_level',
    //   key: 'skill_level',
    // },
    // {
    //   title: 'Gender',
    //   dataIndex: 'gender',
    //   key: 'gender',
    // },
    {
      title: <div className='build_teams_unassigned_table_column_title'>Status</div>,
      dataIndex: 'unassigned_status',
      key: 'unassigned_status',
    },
    {
      title: <div className='build_teams_unassigned_table_column_title'>Action</div>,
      width:'35%',
      key:'action',
      dataIndex:'action',
      render: (text, item) => {
        
        return(
          <div>
            <button className='move_to_new_team_btn' onClick={() => showNewTeamModal(item)}> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_10824_1162)">
    <path d="M4.16699 7.50002L1.66699 10M1.66699 10L4.16699 12.5M1.66699 10H18.3337M7.50033 4.16669L10.0003 1.66669M10.0003 1.66669L12.5003 4.16669M10.0003 1.66669V18.3334M12.5003 15.8334L10.0003 18.3334M10.0003 18.3334L7.50033 15.8334M15.8337 7.50002L18.3337 10M18.3337 10L15.8337 12.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10824_1162">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>Move to New Team</button>
          </div>
        )
      }
      }
  ];

    const[getGenerateTeamButton, setGenerateTeamButton] = useState(false);
    const[getAddAnotherTeamButton, setAddAnotherTeamButton] = useState(false);

    const [dynamicAdding, setDynamicAdding] = useState(dynamicDataSource);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [generateTeamsResponse, setGenerateTeamsResponse] = useState(false)
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [resetResponse, setResetResponse] = useState(false);
    const [getTeamsDataStats, setTeamsDataStats] = useState({});
    const [getTeamSizeValue, setTeamSizeValue] = useState('');
    const [getLateValue,setLateValue] = useState(1);
    const [getSocketData, setSocketData] = useState(true);
    const [getTeamItems, setTeamItems] = useState([]);
    const [getDynTableList, setDynTableList] = useState([]);
    const [getExpData, setExpData] = useState();
    const [getEventData, setEventData] = useState();
    const [viewDetails, setViewDetails] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [responseHasTrue, setResponseHasTrue] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isRemoveTeamModalOpen, setIsRemoveTeamModalOpen] = useState(false);
  const [getUnassignedList, setUnassignedList] = useState([]);
  const [getRemovingTeamDetails, setRemovingTeamDetails] = useState({});
  const [isAddFromUnassignedOpen, setIsAddFromUnassignedOpen] = useState(false);
  const [getPlayersArrayId, setPlayersArrayId] = useState([]);
  const [getTeamDetails, setTeamDetails] = useState({});
  const [isMoveToNewTeamModalOpen, setIsMoveToNewTeamModalOpen] = useState(false);
  const [getCurrentTeamValue, setCurrentTeamValue]  = useState({});
  const [getSelectedTeamDetails, setSelectedTeamDetails] = useState({});
  const [getConArray, setConArray] = useState([])
  const [getNewTeamSelectedValue, setNewTeamSelectedValue] = useState();
  const [getSelectedIdUnassigned, setSelectedIdUnassigned] = useState();
  const [selectedTeamBoolean, setSelectedBoolean] = useState(false);
  const [selectedPlayerBoolean, setSelectedPlayerBoolean] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Refreshed successfully',
    });
  };
    useEffect(() => {
      getLeaguesTeamsList();
      viewExperience();
      props.setTeamTableListFunction({
        value: getLeaguesTeamsList
      })
      // viewEvent();
    }, []);

    useEffect(() => {
      getLeaguesTeamsList();
      // viewExperience();
      // viewEvent();
    }, [getSocketData]);

    useEffect(() => {
      const socket = socketIOClient("", {
  reconnection: false,
  transports: ['websocket']
});
  socket.open();
  socket.on('utilitiesClient', data => {
    
      setSocketData(_.get(data, 'data.event_data.loading'))
  })
  }, []);

  const showNewTeamModal = (value) => {
    
    setCurrentTeamValue(value)
    setIsMoveToNewTeamModalOpen(true);
  };

  
  const handleNewTeamCancel = () => {
    setIsMoveToNewTeamModalOpen(false);
  };

let startFiltering = getTeamItems.length > 0 && getTeamItems.filter((item) => item.id !== _.get(getCurrentTeamValue, 'details.id') && item.type !=="unassigned")
  const newTeamOptions = startFiltering.length > 0 && startFiltering.filter((teams) => _.size(_.get(teams, 'players')) > 0).map((teams) => ({
    value: teams.id,
    label: `${teams.name}`,
  }));

  const handleNewTeamOk = () => {
    setSelectedBoolean(false)
    let id = getNewTeamSelectedValue
    
setNewTeamSelectedValue(id)
props.viewTeamAPI({
  params:{
    id: id
  },
  callback: (res, data) => {
    
    
    if(res){
      setSelectedTeamDetails(_.get(data, 'data.details'));
      let array1 =_.get(data, 'data.details.players').map(obj => obj.id);
let array2 = [getCurrentTeamValue.p_id]
let con = array1.concat(array2);

props.updateTeamAPI({
  body:{
    id: _.get(props, 'experience_league_data.leage_id'),
    team_data:[
      {
        id: id,
        name: _.get(getSelectedTeamDetails, 'name'),
        players: con
      }
    ]
  },
  callback: (r, v) => {
   if(r){
    getLeaguesTeamsList();
   }
  }
})
    }
  }
});


let removedIdUpdating = _.get(getCurrentTeamValue, 'details.players').filter((item) => item.id !== getCurrentTeamValue.p_id);
// 
props.updateTeamAPI({
  body:{
    id: _.get(props, 'experience_league_data.leage_id'),
    team_data:[
      {
        id: _.get(getCurrentTeamValue, 'details.id'),
        name: _.get(getCurrentTeamValue, 'details.name'),
        players: removedIdUpdating.map(obj => obj.id)
      }
    ]
  },
  callback: (r, v) => {
    if(r){
      getLeaguesTeamsList();
      notification.success({
        message: "Success",
        description: "Team updated successfully"
      })
      setIsMoveToNewTeamModalOpen(false)
    }
  }
})
  };

  const addTeamPlayerModal = (value) => {
    
    let teamDetails = value;
    setTeamDetails(teamDetails);
    let playersArray = _.get(value, 'players').map(obj => obj.id);
    setPlayersArrayId(playersArray)
    setIsAddFromUnassignedOpen(true);
  };

  const confirmSelectPalyers = (value) => {
    setSelectedPlayerBoolean(false)
    let id = getSelectedIdUnassigned;
    let array_of_players_id = getPlayersArrayId.concat(id);
    // 

    //updating the team with extra members from the unassigned table
    props.updateTeamAPI({
      body:{
        id: _.get(props, 'experience_league_data.leage_id'),
        team_data:[
          {
            id: _.get(getTeamDetails, 'id'),
            name: _.get(getTeamDetails, 'name'),
            players: array_of_players_id
          }
        ]
      },
      callback: (res, value) => {
        if(res){
          getLeaguesTeamsList()
          notification.success({
            message: "Success",
            description: "Team updated successfully"
          })
        }
      }
    }) 

     //removing the team members from the unassigned table.
     let unassigned_team_list = getUnassignedList[0].players.map(obj => obj.id);
     
     let uniqueArray = unassigned_team_list.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    let filteredArray = uniqueArray.filter((values) => {
      return values !== id;
    });
    
     props.updateTeamAPI({
      body:{
        id: _.get(props, 'experience_league_data.leage_id'),
        team_data:[
          {
            id: getUnassignedList[0].id,
            name: getUnassignedList[0].name,
            players: filteredArray
          }
        ]
      },
      callback: (res, value) => {
        if(res){
          getLeaguesTeamsList();
          setIsAddFromUnassignedOpen(false);
        }
      }
    }) 
    setIsAddFromUnassignedOpen(false);
  };

  const cancelSelect = () => {
    setIsAddFromUnassignedOpen(false);
  };

  const playerOptions = getUnassignedList.length > 0 && !_.isEmpty(getUnassignedList[0].players) &&getUnassignedList[0].players.map((player) => ({
    value: player.id,
    label: `${player.first_name} ${player.last_name}`,
  }));

  const handleNewTeamSelect = (value) => {
// 
setSelectedBoolean(true)
setNewTeamSelectedValue(value)

  }

  const handleSelect = (value) => {
    
    setSelectedPlayerBoolean(true)
    setSelectedIdUnassigned(value)
   
  };

  const viewEvent = () => {
    let parameters = {
      id:_.get(props, 'experience_league_data.leage_id')
    }
    props.viewLeagueEventsAPI({
      params: parameters,
      callback: (response, data) => {
        if(response){
          setViewDetails(_.get(data, 'data.details'))
        }
      }
    })
  }

  const viewExperience = () => {
    props.viewExperienceAPI({
      params:{
        id: searchParams.get('id'),
        expand: "template"
      },
      callback: (res, val) => {
        
        if(res){
          setExpData(_.get(val, 'data.details.meta_details.team_size'))
        }
      }
    })
  }

    const getLeaguesTeamsList = (value) => {
      setIsLoading(true);
      props.leagueTeamsListAPI({
        params: {
          id: _.get(props, 'experience_league_data.leage_id'),
          expand: "captain",
          sort:'name.asc'
        },
        callback: async(res, data) => {
          
          setIsLoading(false);
          if(res){
            if(_.get(data, 'data.details.stats')){
              setTeamsDataStats(_.get(data, 'data.details.stats'));
            }
            if(value === 'refresh'){
              success();
            }
            // 
            setResponseHasTrue(true);
            if(_.size(_.get(data, 'data.items')) > 0){
              setTeamItems(_.get(data, 'data.items'));
              setEventData(_.get(data, 'data.details.event'))
              await setTableData(_.get(data, 'data.items'));
              await setUnassignedList(_.get(data, 'data.items').filter(obj => obj.type === "unassigned"));
              await unassignedTableList(_.get(data, 'data.items').filter(obj => obj.type === "unassigned"));
            }
             
          
          }
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressPercentage(percentCompleted);
        },
      })
    };

    const TeamPlayersUnassignedTable = ({ players, itemDetails }) => {
      let a = [];
      let manualArray = _.get(getEventData, 'players_unavailable');
      manualArray.forEach(obj => {
        obj.check_in_status = 0 ;
      }); 
      
      _.size(_.get(itemDetails, 'players')) > 0 && _.get(itemDetails, 'players').map((val, index) => {
        a.push({
          key: index,
          p_id: _.get(val, 'id'),
          first_name: <div className='build_teams_unassigned_table_column_title'>{_.startCase(_.get(val, 'first_name'))}</div>,
          last_name: <div className='build_teams_unassigned_table_column_title'>{_.startCase(_.get(val, 'last_name'))}</div>,
          gender: <div className='build_teams_unassigned_table_column_title'>{_.get(val, 'gender')}</div>,
          skill_level: "",
          unassigned_status: _.size(_.get(getEventData, 'players_check_in').concat(manualArray)) > 0  ? _.get(getEventData, 'players_check_in').concat(manualArray).map((v, i) =>{
            
            if(_.isEqual(parseInt(_.get(v, 'id')), parseInt(_.get(val, 'id')))){
              return(<div>
                {_.get(v, 'check_in_status') === 1 ? <div className='build_teams_checked_in_status'>Checked-In</div>: _.get(v, 'check_in_status') === 2 ? <div className='build_teams_coming_late_status'>Coming Late</div> : _.get(v, 'check_in_status') === 3 ? <div className='build_teams_not_coming_status'>Not Coming</div> : _.get(v, 'check_in_status') === 0 ? <div className='build_teams_not_checked_in_status'>Not Checked-In</div> : ""}
              </div>)
            }
            
          }) : "Not Checked-In",
          details: itemDetails
        })
      })
      return (
        <div style={{marginTop: "20px"}}>
        <Table 
        dataSource={a} 
        columns={unAssignedColumns} 
        pagination={false}
         />
         </div>
      );
    };

    const TeamPlayersTable = ({ players, itemDetails }) => {
      
      let a = [];
      _.size(_.get(itemDetails, 'players')) > 0 && _.get(itemDetails, 'players').map((val, index) => {
        a.push({
          key: index,
          p_id: _.get(val, 'id'),
          first_name: <div className='build_teams_unassigned_table_column_title'>{_.startCase(_.get(val, 'first_name'))}</div>,
          last_name: <div className='build_teams_unassigned_table_column_title'>{_.startCase(_.get(val, 'last_name'))}</div>,
          gender: <div className='build_teams_unassigned_table_column_title'>{_.get(val, 'gender')}</div>,
          skill_level: "",
          status: _.size(_.get(getEventData, 'players_check_in')) > 0 && _.get(getEventData, 'players_check_in').map((v, i) =>{
            if(_.isEqual(_.get(v, 'id'), _.get(val, 'id'))){
              return(<div>
                {_.get(v, 'check_in_status') === 1 ? <div className='build_teams_checked_in_status'>Checked-In</div>: _.get(v, 'check_in_status') === 2 ? <div className='build_teams_coming_late_status'>Coming Late</div> : _.get(v, 'check_in_status') === 3 ? <div className='build_teams_not_coming_status'>Not Coming</div> : _.get(v, 'check_in_status') === 0 ? <div className='build_teams_not_checked_in_status'>Not Checked-In</div> : ""}
              </div>)
            }
            
          }),
          details: itemDetails
        })
      })
      return (
        <div style={{marginTop: "20px"}}>
        <Table 
        dataSource={a} 
        columns={columns} 
        pagination={false}
                footer={() => (
                    <>
                      <button className='add_participant_btn' onClick={() => addTeamPlayerModal(itemDetails)}><Image style={{width: "20px", height: "20px", marginLeft: "20px"}} preview={false} src={require('../../../../assets/img/plus-circle.png')}/>Add participant to team</button>
                    </>
                  )}
         />
         </div>
      );
    };

    const unassignedTableList =async(data)=>{
      let a =[]
      _.map(data,(value,index)=>{
          
          _.size(_.get(value, 'players')) > 0 && _.get(value, 'players').map((tVal, tIndex) => {
            a.push({
              key:index,
              un_first_name:_.get(tVal,'first_name'),
              un_last_name:_.get(tVal,'last_name'),
          })
          })
          
      })
        setDynTableList(a);
      
      }

    const setTableData =async(data)=>{
      let a =[]
      _.map(data,(value,index)=>{
          
          _.size(_.get(value, 'players')) > 0 && _.get(value, 'players').map((tVal, tIndex) => {
            a.push({
              key:index,
              dyn_first_name:_.get(tVal,'first_name'),
              dyn_last_name:_.get(tVal,'last_name'),
          })
          })
          
      })
        setDynTableList(a);
      
      }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const showResetModal = () => {
      setIsResetModalOpen(true)
    };

    const confirmReset = () => {
      props.changeLeagueEventStatusAPI({
        body:{
          id:_.get(props, 'experience_league_data.leage_id'),
          retain_data: 0,
          event_status: 1
        },
        callback: (response, data) => {
          if(response){
            getLeaguesTeamsList();
            setSocketData(true)
            getLeaguesTeamsList();
            setIsResetModalOpen(false);
            setResetResponse(response);
            setGenerateTeamsResponse(false)
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
          }
        }
      })
    };

    const cancelReset = () => {
      setIsResetModalOpen(false);
    }

    const handleOk = () => {
      let paarms = {
        id:_.get(props, 'experience_league_data.leage_id'),
        event_status: 2,
        include_late: getLateValue,
        team_size: getExpData
      }
      
      props.changeLeagueEventStatusAPI({
        body:{
          id:_.get(props, 'experience_league_data.leage_id'),
          event_status: 2,
          include_late: getLateValue,
          team_size: getExpData,
          retain_data: 0
        },
        callback: (response, data) => {
         
          if(response){
          //   setTimeout(() => {
              getLeaguesTeamsList();
            // }, 3000);
            setGenerateTeamsResponse(response)
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            });
            setIsModalOpen(false);
          }
        }
      })
      
    };

    const triggerRemoveFromTeam = (value) => {
      
      setRemovingTeamDetails(value)
      setIsRemoveTeamModalOpen(true);
    }

    const handleRemoveFromTeam = () => {
      const player_id =  _.get(getRemovingTeamDetails, 'p_id')
      
      const playerArray = _.get(getRemovingTeamDetails, 'details.players');
      
      var filteredArray = playerArray.filter(obj => obj.id !== player_id);
      
      props.updateTeamAPI({
        body:{
          id: _.get(props, 'experience_league_data.leage_id'),//513
          team_data: [
            {id: _.get(getRemovingTeamDetails, 'details.id'), name: _.get(getRemovingTeamDetails, 'details.name'), players: filteredArray.map(obj => obj.id) },
            // {id: TEAM_ID, name: TEAM_NAME, players: [4,5,6]}
            ]
        },
        callback: (res, val) => {
          if(res){
            notification.success({
              message: "Success",
              description: "Team updated successfully"
            });
            getLeaguesTeamsList();
          }
        }
      })
      // if(_.isEmpty(filteredArray)){
        let filter_unassigned = getTeamItems.filter(obj => obj.type === "unassigned")
        let user_unassigned = player_id
        props.updateTeamAPI({
          body:{
            id: _.get(props, 'experience_league_data.leage_id'),
            team_data: [
              {id: filter_unassigned[0].id, name: filter_unassigned[0].name, players: _.size(filter_unassigned[0].players) > 0 ? filter_unassigned[0].players.map(player => player.id).concat(user_unassigned): [user_unassigned] },
              // {id: TEAM_ID, name: TEAM_NAME, players: [4,5,6]}
              ]
          },
          callback: (res, val) => {
            
            if(res){;
              getLeaguesTeamsList();
            }
          }
        })
      // }
      setIsRemoveTeamModalOpen(false);
    };

    const cancelHandleRemoveFromTeam = () => {
      setIsRemoveTeamModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };


    const dynamicColumns = [
        {
          title: 'First Name',
          dataIndex: 'dyn_first_name',
          key: 'dyn_first_name',
        },
        {
          title: 'Last Name',
          dataIndex: 'dyn_last_name',
          key: 'dyn_last_name',
        },
      //   {
      //       title: 'Gender',
      //       dataIndex: 'dyn_gender',
      //       key: 'dyn_gender',
      //   },
      //   {
      //     title: 'Skill level',
      //     dataIndex: 'dyn_skill_level',
      //     key: 'dyn_skill_level',
      // },
      //   {
      //       title: 'Check-In Status',
      //       dataIndex: 'dyn_check_in_status',
      //       key: 'dyn_check_in_status',
      //   },
        // {
        //     title: 'Action',
        //     dataIndex: 'dyn_action',
        //     key: 'dyn_action',
        //     render: (text, record) => {
        //         return(
        //             <Popconfirm title="Sure to delete?" onConfirm={() => handleRemoveRow(record.key)}>
        //            {/* <Button style={{marginLeft: "10px"}}>Delete</Button> */}
        //            <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/>
        //           </Popconfirm>
        //         )
        //     }
        // },
      ];

    

    const handleAddRow = () => {
        const newRow = {
          key: dynamicAdding.length + 1,
          dyn_first_name: 'Max',
          dyn_last_name: 'M',
          dyn_gender: 'Male',
          dyn_skill_level: 'Beginner',
          dyn_check_in_status: <Select 
          defaultValue='checked_in'
          options={[
            {
              value: 'checked_in',
              label: 'Checked-In',
            },
            {
              value: 'not_checked_in',
              label: 'Not Checked-In',
            },
            {
              value: 'coming_late',
              label: 'Coming Late',
            },
          ]}
          />,
          dyn_action:  <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm>
        };
        setDynamicAdding([...dynamicAdding, newRow]);
      };

      const handleRemoveRow = (key) => {
        
        const newData = dynamicAdding.filter((item) => item.key !== key);
        setDynamicAdding(newData);
      };

      const onChangeTeamSize = (event) => {
        
        setTeamSizeValue(event);
        setExpData(event)
      };

      const handleChangeLate = (value) => {
        
        setLateValue(value)
      };

      const refreshAPIs = () => {
        let refresh_value = "refresh"
        getLeaguesTeamsList(refresh_value);
      };

    const render = () => {
      
      // if(_.get(getSocketData, 'data.event_data.loading') === false){
      //   getLeaguesTeamsList();
      // viewExperience();
      // }
    
      // 
        const columns = [
            {
              title: 'First Name',
              dataIndex: 'first_name',
              key: 'first_name',
            },
            {
              title: 'Last Name',
              dataIndex: 'last_name',
              key: 'last_name',
            },
            {
                title: 'Gender',
                dataIndex: 'gender',
                key: 'gender',
            },
            {
              title: 'Skill level',
              dataIndex: 'skill_level',
              key: 'skill_level',
          },
            {
                title: 'Check-In Status',
                dataIndex: 'check_in_status',
                key: 'check_in_status',
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
            },
          ];

          const data = [
            {
              key: '1',
              first_name: 'Kerish',
              last_name: 'Maharaj',
              gender: 'Male',
              skill_level: "Beginner",
              check_in_status: <Select 
              defaultValue='checked_in'
              options={[
                {
                  value: 'checked_in',
                  label: 'Checked-In',
                },
                {
                  value: 'not_checked_in',
                  label: 'Not Checked-In',
                },
                {
                  value: 'coming_late',
                  label: 'Coming Late',
                },
              ]}
              />
            },
          ];

          

          

        return(
            <div>
              {contextHolder}
                 <div>
                 <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        {/* <Button onClick={() => {showResetModal()}} style={{marginRight: "15px", borderRadius: "20px", color: "#fff", backgroundColor: "#e85f2f"}}>Reset<Image style={{width: "20px", height: "20px"}} preview={false} src={require('../../../../assets/img/refresh-cw.png')}/></Button> */}
                        <button className='save_and_reset_btn'> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.5 6.66667H7.16667C6.69996 6.66667 6.4666 6.66667 6.28834 6.57584C6.13154 6.49594 6.00406 6.36846 5.92416 6.21166C5.83333 6.0334 5.83333 5.80004 5.83333 5.33333V2.5M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>Save</button>
                        <button  className='save_and_reset_btn' style={{marginLeft: "32px"}} onClick={() => refreshAPIs()} > <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.167 4.27116C15.9348 5.55911 17.0837 7.64538 17.0837 10C17.0837 13.912 13.9123 17.0833 10.0003 17.0833H9.58366M5.83366 15.7288C4.06588 14.4409 2.91699 12.3546 2.91699 10C2.91699 6.08798 6.08831 2.91667 10.0003 2.91667H10.417M10.8337 18.6667L9.16699 17L10.8337 15.3333M9.16699 4.66667L10.8337 3L9.16699 1.33333" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>Refresh</button>
                    </div>
                 <div className='build_teams_stats_card'>
                 <div className='build_teams_stats_text'>Expected participants: {_.get(getTeamsDataStats, 'expected')} </div>
                    <div className='build_teams_stats_text'>Total checked-in: {_.get(getTeamsDataStats, 'checked_in')} </div>
                    <div className='build_teams_stats_text'>Total not checked-in: { _.get(getTeamsDataStats, 'not_checked_in')} </div>
                    <div className='build_teams_stats_text'>Total late: {_.get(getTeamsDataStats, 'late')} </div>
                 </div>

            <div className='build_teams_team_generate_inputs_card'>
              <div>
              <div style={{width: "100%", display: "flex", alignItems: "flext-start"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='build_teams_header_txt'>Team size</div>
                          <div className='build_teams_description_txt'>What is the team size you want to generate?</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                          <div style={{marginTop: "16px"}}>
                            <InputNumber defaultValue={getExpData} value={getExpData} onChange={(e) => onChangeTeamSize(e)} style={{width: "100%"}}/>
                            </div>
                          </div>
                          </div>

                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "32px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='build_teams_header_txt'>Include late participants</div>
                          <div className='build_teams_description_txt'>Do you want to generate teams with participants who indicated they will be late?</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                          <div style={{marginTop: "16px"}}>
                          <Select
                                defaultValue={1}
                                style={{
                                    width: "100%",
                                }}
                                  onChange={(e) => handleChangeLate(e)}
                                options={[
                                    {
                                    value: 1,
                                    label: 'Yes',
                                    },
                                    {
                                    value: 0,
                                    label: 'No',
                                    },
                                ]}
                                />
                            </div>
                          </div>
                          </div>
              </div>
            </div>
            {/* <div 
            style={{marginTop: "20px", 
            marginLeft: "250px",  
            // display: _.get(getEventData, 'event_status') > 1 ? "none" : getSocketData === false ? "none" : "block"
            }}>
                <Button 
                // disabled={_.get(getTeamsDataStats, 'not_coming') === 0 ? true : false} 
                onClick={() => {showModal()}} style={{borderRadius: "20px", backgroundColor: "#e85f25", color: "#fff"}}>{_.size(getTeamItems) > 0 ? "Re-Generate Teams" : "Generate Teams"}</Button>
            </div> */}
            { _.size(getTeamItems) > 0 && <button onClick={() => {showModal()}} className='generate_regenerate_teams_btn'> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1667 1.66669L17.5 5.00002M17.5 5.00002L14.1667 8.33335M17.5 5.00002H6.5C5.09987 5.00002 4.3998 5.00002 3.86502 5.2725C3.39462 5.51219 3.01217 5.89464 2.77248 6.36504C2.5 6.89982 2.5 7.59989 2.5 9.00002V9.16669M2.5 15H13.5C14.9001 15 15.6002 15 16.135 14.7275C16.6054 14.4879 16.9878 14.1054 17.2275 13.635C17.5 13.1002 17.5 12.4002 17.5 11V10.8334M2.5 15L5.83333 18.3334M2.5 15L5.83333 11.6667" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>Re-Generate Teams</button>}


            {_.isEmpty(getTeamItems) && <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "16px"}}>
                  <button onClick={() => {showModal()}} className='generate_teams_btn'> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_10924_848)">
                  <path d="M10.0003 6.66669V13.3334M6.66699 10H13.3337M18.3337 10C18.3337 14.6024 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6024 1.66699 10C1.66699 5.39765 5.39795 1.66669 10.0003 1.66669C14.6027 1.66669 18.3337 5.39765 18.3337 10Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_10924_848">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>Genrate teams</button>
            </div>}

            <div style={{width: "100px", height: "100px", marginTop: "20px", marginLeft: "250px"}}>
      {isLoading ? ( 
        <CircularProgressbar value={progressPercentage} text={`${progressPercentage}%`} />
       ) : ""}
    </div>

            {/* <div style={{display: generateTeamsResponse === true ? "flex" : "none", flexDirection: "row", marginTop: "10px"}}>
                <div className="card" style={{marginBottom: "10px", width:"100%", backgroundColor: "#eee"}}>
                    <div className="card-body">
                        <h5 className="card-title">Team Stats</h5>
                        <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{display: "flex", flexDirection: 'row'}}>
                        <p className="card-text">Total Teams</p>
                        <p className="card-text" style={{marginLeft: '20px'}}>6</p>
                        </div>
                        <div style={{display: "flex", flexDirection: 'row'}}>
                        <p className="card-text" style={{marginLeft: "150px"}}>Total Not Assigned</p>
                        <p className="card-text" style={{marginLeft: '20px'}}>1</p>
                        </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{display: "flex", flexDirection: 'row'}}>
                        <p className="card-text">Average Team Size</p>
                        <p style={{marginLeft: '20px'}}>4</p>
                        </div>
                        </div>
                    </div>
                    </div>
            </div> */}
            {/* <div style={{marginTop: "10px", display: generateTeamsResponse === true ? "block" : "none"}}>
                <div style={{display:"flex", flexDirection: "row"}}>
            <Image style={{width: "20px", height: "20px", marginLeft: "20px"}} preview={false} src={require('../../../../assets/img/alert-circle.png')}/>
            <h5 style={{marginBottom: "10px"}}>The follow participants are not assigned a team:</h5>
            </div>
                <Table columns={columns} dataSource={data} pagination={false}/>
            </div> */}
            {/* <div style={{marginTop: "25px", display: _.get(getEventData, 'event_status') === 2 ||  _.get(getEventData, 'event_status') === 3 || _.get(getEventData, 'event_status') === 4  || getSocketData === false ? "block" : "none"}}> */}
                {/* <div style={{display:"flex", flexDirection: "row"}}>
            <h5 style={{marginBottom: "10px"}}>Dragons</h5>
            <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm>
            </div>
                <Table 
                columns={dynamicColumns} 
                dataSource={getDynTableList} 
                pagination={false}
                footer={() => (
                    <>
                      <Button onClick={handleAddRow} style={{width: "100%", color: "#000", backgroundColor: "#e85f2f"}}><Image style={{width: "20px", height: "20px", marginLeft: "20px"}} preview={false} src={require('../../../../assets/img/plus-circle.png')}/></Button>
                    </>
                  )}
                /> */}

<div >
      {_.size(getUnassignedList) > 0 && getUnassignedList.map(item => {
        
        if(_.get(item, 'players')){
          let a = _.get(item, 'players');
        return(
        <div key={item.id}>
          <div style={{display:"flex", flexDirection: "row"}}>
         <img style={{height: "40px", width: "40px", marginBottom: "10px", marginTop: "-55px"}} src={require('../../../../assets/img/league/team_unassigned_image.png')}/>  <h5 style={{marginBottom: "10px", marginTop: "-50px", marginLeft: "8px"}}>{item.name}</h5>
          {/* <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm> */}
         
          </div>
          <TeamPlayersUnassignedTable players={item.players} itemDetails={item} />
        </div>
        )
        }
    })}
    </div>

<div >
      {_.size(getTeamItems) > 0 && getTeamItems.map(item => {
        
        if(_.get(item, 'players') && _.get(item, 'type') === "playing"){
          let a = _.get(item, 'players');
        

        const selectChange = (value) => {
          
          props.updateCaptainAPI({
            body:{
              id:item.id,
              captain: _.toString(value)
            },
            callback: (res, val) => {
              
              
              if(res){
                notification.success({
                  message: "Success",
                  description: _.get(val, 'message')
                })
              }
            }
          })
        };

        const selectOptions = () => {
          return a.map((option, index) => (
            <Select.Option key={index} value={option.id}>
              {_.get(option, 'first_name') + " " + _.get(option, 'last_name')}
            </Select.Option>
          ));
        }
        return(
        <div key={item.id}>
          <div style={{display:"flex", flexDirection: "row"}}>
          <h5 style={{marginBottom: "10px", marginTop: "20px"}}>{item.name}</h5>
          {/* <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm> */}
          <div style={{marginLeft: "10px"}}>
          Captain: <Select style={{marginLeft: "10px", marginTop: "15px"}} defaultValue={_.get(item, 'captain.first_name') + " " + _.get(item, 'captain.last_name')} onChange={selectChange}>
          {selectOptions()}
        </Select>
        </div>
          </div>
          <TeamPlayersTable players={item.players} itemDetails={item}/>
        </div>
        )
        }
    })}
    </div>
            {/* </div> */}

            {/* <div style={{marginTop: "25px", display: generateTeamsResponse === true ? "block" : "none"}}>
                <div style={{display:"flex", flexDirection: "row"}}>
            <h5 style={{marginBottom: "10px"}}>Panthers</h5>
            <Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm>
            </div>
                <Table 
                columns={dynamicColumns} 
                pagination={false}
                />
            </div> */}
            {/* <div style={{marginTop: "20px", marginLeft: "300px", display: generateTeamsResponse === true ? "block" : "none"}}>
                <Button onClick={() => setAddAnotherTeamButton(true)} style={{borderRadius: "20px", backgroundColor: "#e85f25", color: "#000"}}><Image style={{width: "20px", height: "20px", marginLeft: "20px"}} preview={false} src={require('../../../../assets/img/plus-circle.png')}/> Add Another Team</Button>
            </div> */}
              <Modal centered={true} title="Generate Teams" okText="Continue" cancelText="Cancel" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                 <p>Teams will be generated with the users who are currently checked-in. Users who check-in after you generate teams will need to be assigned manually.</p>
            </Modal>
              <Modal centered={true} title="Reset" okText="Continue" cancelText="Cancel" open={isResetModalOpen} onOk={confirmReset} onCancel={cancelReset}>
                 <p>Resetting will clear all teams. This action cannot be undone. You will need regenerate teams after this is complete.</p>
            </Modal>
              <Modal centered={true} title="Remove from team" okText="Continue" cancelText="Cancel" open={isRemoveTeamModalOpen} onOk={handleRemoveFromTeam} onCancel={cancelHandleRemoveFromTeam}>
        <p>Are you sure you want to remove this player from this team? They will be set to unassigned.</p>
      </Modal>
        <Modal centered={true} 
      title="Please select player" 
      open={isAddFromUnassignedOpen} 
      onOk={confirmSelectPalyers} 
      onCancel={cancelSelect}
      okButtonProps={{
        disabled: selectedPlayerBoolean ? false : true
      }}
      >
        <div>
          <p>Please select which unassigned player you want to add to this team</p>
        <Select 
        placeholder="Select player"
        // defaultValue={playerOptions.length > 0 && playerOptions[0].value} 
        style={{width: "50%"}} 
        onSelect={handleSelect}
        >
  {playerOptions.length > 0 && playerOptions.map((option) => (
    <Option key={option.value} value={option.value}>
      {option.label}
    </Option>
  ))}
</Select>
        </div>
        <p></p>

      </Modal>
        <Modal centered={true} title="Move to New Team" open={isMoveToNewTeamModalOpen}
      okButtonProps={{
        disabled: selectedTeamBoolean ? false : true
      }}
       onOk={handleNewTeamOk} onCancel={handleNewTeamCancel}>
        <div>
        <p>Please confirm which team you want to move this player to:</p>
        <Select placeholder="Select your team" 
        // defaultValue={newTeamOptions.length > 0 && newTeamOptions[0].value} 
        style={{width: "50%"}} onSelect={handleNewTeamSelect}>
  {newTeamOptions.length > 0 && newTeamOptions.map((option) => (
    <Option key={option.value} value={option.value}>
      {option.label}
    </Option>
  ))}
</Select>
        </div>
        
      </Modal>
                </div>
            </div>
        );
    };
    return render();
};

const mapStateToProps = state => {
  
  return {
     checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
     isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
     islogindata: _.get(state, 'app.account.isLogin'),
     userProfile: _.get(state, 'app.host.profile.data.details'),
     guestUserProfile: _.get(state, 'app.guest.profile.data.details')

  }
};

const mapDispatchToProps = dispatch => ({
  changeBuildTeamsEventCheckInAPI: details => dispatch(global.redux.action.league.leagueEventCheckIn(details)),
  changeLeagueEventStatusAPI: details => dispatch(global.redux.action.league.changeEventStatus(details)),
  leagueTeamsListAPI: details => dispatch(global.redux.action.league.leagueTeamsList(details)),
  viewExperienceAPI: details => dispatch(global.redux.action.experience.view(details)),
  viewLeagueEventsAPI: details => dispatch(global.redux.action.league.viewLeagueEvents(details)),
  updateCaptainAPI: details => dispatch(global.redux.action.league.leagueUpdateCaptain(details)),
  updateTeamAPI: details => dispatch(global.redux.action.league.leagueUpdateTeam(details)),
  viewTeamAPI: details => dispatch(global.redux.action.league.viewTeam(details)),
  setTeamTableListFunction: details =>
    dispatch(global.redux.action.settings.teamTabTable(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(BuildTeams)