import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Route, Routes, useLocation, Navigate, useNavigate, useParams, useSearchParams, Await } from "react-router-dom";
import routeConfigs from './private-route-config';
import publicRouteConfigs from './public-route-config';
import global from '../config/global'
import { connect } from 'react-redux';
import appPages from './app-pages';
import { useTheme } from '@mui/material/styles';

import RedirectRouteConfig from '../routes/redirect-route-config'
import logs from '../utils'
import { Input, Form, Button, Spin, Upload, Pagination, notification, Table, Select, message, Dropdown, Menu, Image, Tag, Empty } from "antd";
import PageHeader from '../../components/Header';
import LoginHeader from '../../components/Header/login-header'
import PageFooter from '../../components/Footer';
import * as Sentry from "@sentry/react";
import globalConfig from '../config/env-variables'
// import OneSignal from 'react-onesignal';
import { NotificationFilled, SmileOutlined } from '@ant-design/icons';
// import disableBrowserBackButton from 'disable-browser-back-navigation';
import Scrollbars from 'react-custom-scrollbars';
import chatkitty from '../chat-system/chatkitty';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';
// disableBrowserBackButton();
const notification_number = {
   background: '#FF7A59',
   width: '23px',
   height: '23px',
   borderRadius: '50%',
   display: 'flex',
   alignItems: 'center',
   textAlign: 'center'
}
window.OneSignal = window.OneSignal || [];
const RoutesPage = (props) => {
   const OneSignal = window.OneSignal;

   const DynamicDiv = styled.div`
   width: 100%;
   float:left;
   // padding-right:5rem;
   // padding-left:5rem;

  @media (min-width: 600px) {
    /* Tablet */
    padding-right:0rem;
   padding-left:0rem;
  }

  @media (min-width: 900px) {
    /* Laptop */
    padding-right:0rem;
   padding-left:0rem;
  }

  @media (min-width: 1200px) {
    /* Desktop */
    padding-right:0rem;
    padding-left:0rem;
  }
`;
const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   let navigate = useNavigate();
   const [searchParams, setSearchParams] = useSearchParams();
   const [windowSize, setWindowSize] = useState(getWindowSize());

   // Declare a new state variable, which we'll call "appRouteConfigs"
   const [appRouteConfigs, setAppRouteConfigs] = useState([]);
   // Declare a new state variable, which we'll call "appRouteConfigsCount"
   const [appRouteConfigsCount, setAppRouteConfigsCount] = useState(0);
   const [isLoginDetails, setIsloginDetails] = useState({});
   const [loading, setLoading] = useState(false);
   const [profileData, setProfileData] = useState({});
   const [notificationsListData, setNotificationsListData] = useState([]);
   const [onesignalUserId, setOnesignalUserId] = useState('');
const [showHeader, setshowHeader] = useState(false);
   const [isNotification, setIsNotification] = useState(false);


   const location = useLocation()
   const { dynamicValue, id } = useParams()

useEffect(() => {
  
   Sentry.init({
      dsn:globalConfig.SENTRY_CONFIG.DSN,
      debug:true,
      environment:'development',
      
   })
}, [])


   useEffect(() => {
     if(_.get(props,"userProfile.stripe_user_id")){
      setshowHeader(true)
     }
   }, [_.get(props,"userProfile")])
   
   // useEffect(() => {
   //    function handleWindowResize() {
   //       setWindowSize(getWindowSize());
   //    }

   //    window.addEventListener('resize', handleWindowResize);

   //    return () => {
   //       window.removeEventListener('resize', handleWindowResize);
   //    };
   // }, []);

   
  
   function getWindowSize() {
      const { innerWidth, innerHeight } = window;
      return { innerWidth, innerHeight };
   }
   const onsignalInit =async (profileDetails) => {
      
   await   OneSignal.init({ appId: globalConfig.ONESIGNAL.APP_ID })
   await  OneSignal.addListenerForNotificationOpened(function (data) {
         
         
         if (_.get(data, 'data.message_type')=="chat") {
            navigate(`/communiti/chats-notification?channel_id=${_.get(data, 'data.channel_details')}`)
         }
      });
      OneSignal.on('notificationDisplay', function (value) {
         
         notificationList()
      })
      OneSignal.getUserId((userId) => {
         setOnesignalUserId(userId)
         chatkitty.instance.updateCurrentUser((user) => {
            user.properties = {
               ...user.properties,
               'onesignal-push-token': userId,
            };
            return user;
         });

      })

      OneSignal.on('subscriptionChange', function (isSubscribed) {

         OneSignal.getUserId((userId) => {
            setOnesignalUserId(userId)
            verifyOneSignalIds(_.get(profileData, 'onesignal_player_ids'), userId, isSubscribed)
            chatkitty.instance.updateCurrentUser((user) => {
               user.properties = {
                  ...user.properties,
                  'onesignal-push-token': userId,
               };
               return user;
            });
         })

      });



   }
   // OneSignal.setNotificationOpenedHandler( function(data){
   //    
   //    
   // });


   const verifyOneSignalIds = (ids = [], id, isSubscribed) => {
      if (isSubscribed == true) {
         if (_.includes(ids, id) == false) {
            updateOneSignalIds([...ids, id])
         }

      } else {
         let filter_ids = _.filter(ids, (a) => a != id)
         updateOneSignalIds(filter_ids)
      }
   }

   useEffect(() => {
      const A1 = notificationsListData
      const A2 = _.get(props, 'notificationGetList', [])

      const results = _.xorBy(A1, A2, 'id');
      if (_.size(results) > 0) {
         
         notificationList()
      }

   }, [_.get(props, 'notificationGetList')])

   const updateOneSignalIds = (onesignal_player_ids) => {
      if (_.get(props, 'islogindata.isLoggedIn') == true) {
         let conditionType1 = _.includes(_.get(props, 'islogindata.types'), 1) == true ? 'hostUpdateProfile' : 'guestUpdateProfile';

         let conditionType = _.includes(_.get(props, 'islogindata.types'), 1) == true ? 'hostProfileData' : 'guestProfileData';
         props[conditionType1]({
            body: {
               onesignal_player_ids
            },
            callback: async (res, data) => {
               chatkitty.instance.updateCurrentUser((user) => {
                  user.properties = {
                     ...user.properties,
                     'onesignal-push-token': _.last(onesignal_player_ids),
                  };
                  return user;
               });
               if (res == true && _.get(data, 'details[0].id')) {
                  await setProfileData({ ..._.get(data, 'details[0]') })
                  props[conditionType]({
                     callback: async (res, data) => {
                     }
                  })
               }

            }
         })
      }
   }
   // Defining component didMount
   useEffect(() => {

      commonIslogin()
      interceptRouteConfigs();


   }, []);

   const commonIslogin = () => {
      props.isLogin({
         callback: (response, data) => {
            if (response) {
               setIsloginDetails(data)
               // _.get(data,'isLoggedIn')===true ?setAppRouteConfigs(routeConfigs)  :setAppRouteConfigs(publicRouteConfigs) 

               if (_.get(data, 'isLoggedIn') === true) {

                  getProfile('Log', data)


               }



            }
         }
      });
   }

   useEffect(() => {

      setIsloginDetails(_.get(props, 'islogindata'))

      if (_.get(props, 'islogindata.isLoggedIn') === true) {

         getProfile()



      } else {
         setAppRouteConfigs(publicRouteConfigs)
      }






   }, [_.get(props, 'checkUpdate')]);

   useEffect(() => {
      
      if (_.get(props, 'islogindata.isLoggedIn') === false && _.get(props, 'isLoginLoadingInProgress') == false) {
         // if(location.pathname === "/"){
            navigate('/communiti/login')
         // } 
         
      } else if (_.get(props, 'isLoginLoadingInProgress') == false) {
         // navigate('athletes')


      }
   }, [_.get(props, 'isLoginLoadingInProgress')]);

   const notificationList = () => {
      props.notificationsListApi({
         params: {
            sort: 'created_at.desc',
            limit: 10,
            user_type: 1,
            // status:2,
         },
         callback: (response, data) => {
            if (response) {
               let a = _.times(10, String)
               let b = []
               _.map(a, (value, index) => {
                  b.push({ ..._.get(data, 'data.items[0]'), key: index })
               })
               setNotificationsListData(_.get(data, 'data.items', []))
               setIsNotification(true)



            }
         }
      });
   }


   const getProfile = (type, data) => {

      let conditionType1 = _.includes(_.get(data, 'types'), 1) == true ? 'hostProfileData' : 'guestProfileData';
      let conditionType = _.includes(_.get(props, 'islogindata.types'), 1) == true ? 'hostProfileData' : 'guestProfileData';

      props[type == 'Log' ? conditionType1 : conditionType]({
         callback: async (res, data) => {
            
            if (res == true && _.get(data, 'data.details.id')) {
               await setProfileData({ profile_type: type == 'Log' ? conditionType1 : conditionType, ..._.get(data, 'data.details') })

              

               if (conditionType == 'hostProfileData') {

                  if (_.get(data, 'data.details.approved_status') == true && _.get(data, 'data.details.stripe_user_id') != null) {
                     setAppRouteConfigs(routeConfigs)
                     await onsignalInit(_.get(data, 'data.details'))

                     setTimeout(() => {
                        if (_.get(location, 'pathname') == "/communiti/login"  || _.get(location, 'pathname') == "/communiti/login") {
                           // navigate("/communiti/main-menu")
                           navigate("/template-list?menu=template");
                           
                        }
                     }, 400);

                     notificationList()

                  } else if (_.get(data, 'data.details.approved_status') == false&& _.get(data, 'data.details.business_type')!='coordinator') {
                     setAppRouteConfigs(routeConfigs)
                     navigate("/communiti/onboarding")
                  } else if (_.get(data, 'data.details.approved_status') == true && _.get(data, 'data.details.stripe_user_id') == null && _.get(data, 'data.details.business_type')!='coordinator') {
                     setAppRouteConfigs(routeConfigs)
                     if (searchParams.get('code')) {
                        navigate(`/communiti/onboarding?code=${searchParams.get('code')}`)
                     } else {
                        navigate("/communiti/onboarding")
                     }

                  }
               } else if (conditionType == 'guestProfileData') {
                  if (_.get(data, 'data.details.approved_status') == true && _.get(data, 'data.details.stripe_user_id') != null) {
                     setAppRouteConfigs(routeConfigs)
                     
                     if (_.get(location, 'pathname') == "/communiti/login" ||_.get(location, 'pathname') == "/") {
                        navigate("/template-list?menu=template")
                        
                     }


                  } else if (_.get(data, 'data.details.approved_status') == false && _.get(data, 'data.details.business_type')!='coordinator') {
                     setAppRouteConfigs(routeConfigs)
                     navigate("/communiti/onboarding")
                  } else if(_.get(data, 'data.details.approved_status') == false && _.get(data, 'data.details.business_type')==='coordinator'){
                     setAppRouteConfigs(routeConfigs)
                     navigate("/communiti/onboarding")
                  } else if (_.get(data, 'data.details.approved_status') == false &&  _.get(data, 'data.details.business_type')!='coordinator' || conditionType1 != "hostProfileData") {

                     setAppRouteConfigs(routeConfigs)
                     navigate("/communiti/onboarding")
                  } else if (_.get(data, 'data.details.approved_status') == true && _.get(data, 'data.details.business_type')==='coordinator' && _.get(data, 'data.details.parent_host') === null){
                     setAppRouteConfigs(routeConfigs)
                     navigate("/communiti/info")
                  }else if (_.get(data, 'data.details.approved_status') == true && _.get(data, 'data.details.stripe_user_id') == null && _.get(data, 'data.details.business_type')!='coordinator') {
                     setAppRouteConfigs(routeConfigs)
                     
                     if (searchParams.get('code')) {
                        navigate(`/communiti/onboarding?code=${searchParams.get('code')}`)
                     } else {
                        conditionType1 == "hostProfileData" && navigate("/communiti/onboarding")
                     }

                  }else if(_.get(data, 'data.details.approved_status') == true && _.get(data, 'data.details.business_type')=='coordinator'){
                     setAppRouteConfigs(routeConfigs)
                     setTimeout(() => {
                        navigate("/communiti/manage")   
                     }, 200);
                     
                     
                     
                  }

               } else {
                  
                  if (_.get(location, 'pathname') == "/communiti/login") {
                     // navigate("/communiti/main-menu")
                     navigate("/template-list?menu=template");
                     
                  }
                  setAppRouteConfigs(routeConfigs)
               }

            } else if (conditionType == 'guestProfileData') {
               setAppRouteConfigs(routeConfigs)
               navigate("/communiti/onboarding")

            } else {
               
               if (_.get(location, 'pathname') == "/communiti/login") {
                  // navigate("/communiti/main-menu")
                  navigate("/template-list?menu=template");
                  
               }
               setAppRouteConfigs(routeConfigs)
            }
         }
      })
   }


   // handler for appRouteConfigs
   useEffect(() => {
      setAppRouteConfigsCount(_.add(appRouteConfigsCount, 1));
   }, [appRouteConfigs]);



   const interceptRouteConfigs = async () => {
      setLoading(true)
      let modifyRouteConfig = _.clone(appRouteConfigs);
      await new Promise((resolve) => {
         _.forEach(modifyRouteConfig, (routeConfig, key) => {
            if (_.chain(routeConfig).get('dashboardWrapper', false).eq(true).value()) {
               const Component = _.chain(routeConfig).clone().get('component').value();
               routeConfig.element = (props) => {
                  return (<React.Fragment><Component {...props} /></React.Fragment>);
               };
               if (_.chain(modifyRouteConfig).size().subtract(1).eq(key).value()) {
                  resolve();
               }
            }
         });


         setAppRouteConfigs(modifyRouteConfig);
         setLoading(false)
      });


   };
   const filter_types = [
      // {id:'booking',color:'#f04732'},
      { id: 'cancellation', color: '#f04732' },
      { id: 'registration', color: '#58b14d' },
      { id: 'system', color: '#2754b1' },
      { id: 'chat', color: '#f68a31' }
   ]

   const getOrderDetails = (value) => {

      props.viewOrder({
         params: { id: _.get(value, 'type_id'), expand: 'experience,experience_template,experience,experience_template_photos,guest' },
         callback: (response, data) => {
            
            if (response === true) {
               props.notificationsReadApi({
                  params: { ids: [_.get(value, 'id')], user_type: 1 },
                  body: { ids: [_.get(value, 'id')], user_type: 1 },
                  callback: (res, data1) => {

                     if (res == true) {
                        navigate(`/communiti/integrated-tabs?id=${_.get(data,'data.details.experience.template.id')}&exp_id=${_.get(data,'data.details.experience.id')}&template_type=${_.get(data,'data.details.experience.template.type')}&name_of_exp=${_.get(data,'data.details.experience.template.title')}&regTab=1`)
                     }
                  }
               })

            }
         }
      })

   }
   const getNotificationData = () => {
      let a = []
      _.map([...notificationsListData], (value, index) => {
         if (_.get(value, 'status') == 2) {
            let c = _.split(_.get(value, 'label'), '-')[1]
            a.push({
               key: _.get(value, 'id'),
               label: (<div onClick={() => getOrderDetails(value)}><span>      <Tag color={_.chain(filter_types).filter(['id', _.get(value, 'type')]).head().get('color').value()}>{_.startCase(_.get(value, 'type'))}</Tag>
               </span><span>{c}</span></div>
               ),
            })
         }

      })

      // 
      return a
   }

   const menu = (<Scrollbars style={{ height: _.size(getNotificationData()) >= 5 ? 300 : _.size(getNotificationData()) * 60 }} className="hidden-scroll-x"
      renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
      renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
   >
      <Menu
         // style={{height:'150px'}}
         // getPopupContainer={trigger => trigger.parentNode}

         items={_.size(getNotificationData()) > 0 ? getNotificationData() : [{
            key: 1,
            label: (<div ><Empty description="Not have a new message" /></div>
            ),
         }]}
      />
   </Scrollbars>);

   const render = () => {
      return (<>
         <div className='full-width'>

            <div style={{paddingLeft:`${location?.pathname}`.includes("/communiti/onboarding")?"0px": isMobile==true?"20px":"80px",
            paddingRight:`${location?.pathname}`.includes("/communiti/onboarding")?"0px": isMobile==true?"20px":"80px",
         }}>
              {(showHeader==true || _.get(profileData,"stripe_user_id") || _.get(props,"userProfile.stripe_user_id")) && <PageHeader />}
            { `${location?.pathname}`.includes("/communiti/login") && <LoginHeader/>}

               <div className="pagecontainer">
                  {(
                     _.size(appRouteConfigs) == 0 || loading) ?
                     <div style={{ width: '100%', float: 'left', textAlign: 'center', height: 'calc(100vh - 206.73px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spin size='large' /></div>
                     : <div style={{ width: '100%', float: 'left', backgroundColor: '#fff' }}>
                        <global.chatSystem.components.Home {...props} />
                        <Routes>

                           {/* <Route exact path="/" element={ _.get(props,'islogindata.isLoggedIn')===true?
                 <appPages.homePage /> :<appPages.loginPage /> } /> */}
                           {
                              _.map(appRouteConfigs, appRouteConfig => {
                                 const props = {
                                    ...appRouteConfig
                                 };

                                 return (<Route {...props} />)
                              })
                           }
                        </Routes>
                        {/* {_.isEmpty(isLoginDetails)===false &&<Navigate to={RedirectRouteConfig(props,isLoginDetails)}/>} */}

                        {windowSize.innerHeight && _.includes(_.get(props, 'userProfile.onesignal_player_ids', []), onesignalUserId) == true
                           && onesignalUserId && isNotification && <div style={{
                              position: 'fixed',
                              left: 0,
                              bottom: 0,
                              right: 13,
                              top: windowSize.innerHeight - 100,
                              //  display:'flex',
                              width: '30px',
                              marginLeft: (windowSize.innerWidth - 100) + 'px'
                              //  justifyContent:'flex-end'


                           }}
                              className="notification-dropdown"
                           ><Dropdown getPopupContainer={(trigger) => trigger.parentElement}
                              overlay={menu}
                           //  open={true}
                           //  overlayStyle={{position:'absolute'}}

                           >
                                 <a onClick={(e) => e.preventDefault()}>
                                    <Image onClick={() => navigate('/communiti/chats-notification?tab=2')} src={require('../../assets/img/onesignal-bell.png')} width="80px" preview={false} />

                                    {_.size(getNotificationData()) > 0 &&

                                       <div style={{ marginTop: '-76px', marginLeft: '61px', ...notification_number }}>
                                          <span style={{ ...notification_number, marginLeft: '7px' }}>{_.size(getNotificationData())}</span></div>}
                                 </a>
                              </Dropdown></div>}
                     </div>
                  }
               </div>

               {/* <PageFooter /> */}
            </div>
         </div></>
      );
   };

   return render();
};
const mapStateToProps = state => {

   return {
      checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
      isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
      islogindata: _.get(state, 'app.account.isLogin'),
      userProfile: _.get(state, 'app.host.profile.data.details'),
      notificationGetList: _.get(state, 'app.notifications.list.data.items')

   }
}

const mapDispatchToProps = dispatch => ({
   isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
   hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
   guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
   hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
   guestUpdateProfile: details => dispatch(global.redux.action.guest.update(details)),
   notificationsListApi: details => dispatch(global.redux.action.notifications.list(details)),
   notificationsDeleteApi: details => dispatch(global.redux.action.notifications.delete(details)),
   notificationsReadApi: details => dispatch(global.redux.action.notifications.read(details)),
   viewOrder: details => dispatch(global.redux.action.finances.view(details)),



});
export default connect(mapStateToProps, mapDispatchToProps)(RoutesPage)