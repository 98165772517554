import React, { useState } from 'react';
import { Dropdown, Menu, List, Avatar, Button, Divider } from 'antd';
import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import  _  from 'lodash';
import { useNavigate } from 'react-router';



const NotificationDropdown = (props) => {
  let navigate = useNavigate()
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const closeDropdown = () => {
    setVisible(false);
  };

  const menu = (
    <Menu style={{width:'440px',padding:'24px'}} rootClassName='notification-container-menu'>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'24px' }}>
        <span className='notification-header-title'>Notifications</span>
        <svg onClick={closeDropdown} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15 5L5 15M5 5L15 15" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        {/* <CloseOutlined onClick={closeDropdown} style={{ cursor: 'pointer' }} /> */}
      </div>
      <List
        itemLayout="horizontal"
        dataSource={props?.notificationsListData}
        renderItem={(item,index) => (
          <div style={{cursor:'pointer'}} onClick={()=>{
            props?.readNotification(item);
            setVisible(false);
          }} key={index}><List.Item>
            <List.Item.Meta
              avatar={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9.35442 21C10.0596 21.6224 10.9858 22 12.0002 22C13.0147 22 13.9409 21.6224 14.6461 21M2.29414 5.81989C2.27979 4.36854 3.06227 3.01325 4.32635 2.3M21.7024 5.8199C21.7167 4.36855 20.9342 3.01325 19.6702 2.3M18.0002 8C18.0002 6.4087 17.3681 4.88258 16.2429 3.75736C15.1177 2.63214 13.5915 2 12.0002 2C10.4089 2 8.88283 2.63214 7.75761 3.75736C6.63239 4.88258 6.00025 6.4087 6.00025 8C6.00025 11.0902 5.22072 13.206 4.34991 14.6054C3.61538 15.7859 3.24811 16.3761 3.26157 16.5408C3.27649 16.7231 3.31511 16.7926 3.46203 16.9016C3.59471 17 4.19284 17 5.3891 17H18.6114C19.8077 17 20.4058 17 20.5385 16.9016C20.6854 16.7926 20.724 16.7231 20.7389 16.5408C20.7524 16.3761 20.3851 15.7859 19.6506 14.6054C18.7798 13.206 18.0002 11.0902 18.0002 8Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}
              title={<div style={{display:'flex',justifyContent:'space-between',alignItems:'start'}}><div><span className='notification-session-title'>{item?.label}</span>
              <span className='notification-session-time' style={{marginLeft:'8px'}}>{moment(item?.created_at).format("MMM DD, hh:mm A")}</span></div><div style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-end'}}>
                {item?.status==2 &&<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <circle cx="5" cy="5" r="4" fill="#12B76A"/>
            </svg>}</div></div>}
              description={<span className='notification-session-description'>{item?.description}</span>}
            />
          </List.Item>
          <Divider style={{margin:'0px',marginTop:'16px',marginBottom:'16px'}}/>
          </div>
        )}
      />
     {_.size(props?.notificationsListData)>4 && <div 
     onClick={()=>{
      setVisible(false);
      navigate('/communiti/host?menu=profile&profile_menu=notifications')
      
     }}
     style={{ textAlign: 'center',display:'flex',justifyContent:'center',cursor:'pointer'}}>
      <div className='notification-view-button'>
<span className='notification-view-button-title'>View all notifications</span>

      </div>
      </div>}
    </Menu>
  );

  return (
    <Dropdown
    overlay={menu}
rootClassName='notification-dropdown-root-container'
      trigger={['click']}
      onOpenChange={handleVisibleChange}
      open={visible}
      
    >
      <div className="notification-header-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M7.79496 17.5003C8.38257 18.019 9.15444 18.3337 9.99981 18.3337C10.8452 18.3337 11.6171 18.019 12.2047 17.5003M14.9998 6.66699C14.9998 5.34091 14.473 4.06914 13.5353 3.13146C12.5977 2.19378 11.3259 1.66699 9.99981 1.66699C8.67373 1.66699 7.40196 2.19378 6.46428 3.13146C5.5266 4.06914 4.99981 5.34091 4.99981 6.66699C4.99981 9.24215 4.3502 11.0053 3.62453 12.1715C3.01242 13.1552 2.70636 13.6471 2.71758 13.7843C2.73001 13.9362 2.7622 13.9942 2.88463 14.085C2.99519 14.167 3.49364 14.167 4.49052 14.167H15.5091C16.506 14.167 17.0044 14.167 17.115 14.085C17.2374 13.9942 17.2696 13.9362 17.282 13.7843C17.2933 13.6471 16.9872 13.1552 16.3751 12.1715C15.6494 11.0053 14.9998 9.24215 14.9998 6.66699Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                                     </div>
    </Dropdown>
  );
};

export default NotificationDropdown;
