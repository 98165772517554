import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from 'lodash';

     import { GrDrag } from "react-icons/gr";
import { Card, Divider, Image, Input, Modal, Select, Switch, notification,Button } from "antd";
import styled from 'styled-components';
import './style.css'
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { IoMdClose } from "react-icons/io";
const CreateQuestions =(props)=>{
  const [isHovered, setIsHovered] = useState({id:'',visible:false});
const [questionSetTitle, setquestionSetTitle] = useState('');
const [searchParams, setSearchParams] = useSearchParams();
const [sortOrderId, setsortOrderId] = useState([]);
const [spinnerLoading, setspinnerLoading] = useState(false);
const [buttonLoading, setbuttonLoading] = useState(false);
let history = useNavigate()
const[changesHappened, setChangesHappened] = useState(false);

const [successModalShow, setsuccessModalShow] = useState(false)
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
const result = Array.from(list);
const [removed] = result.splice(startIndex, 1);
result.splice(endIndex, 0, removed);

return result;
};
useEffect(() => {
  
}, [sortOrderId]);
          
            const [items, setItems] = useState([]);
            const onDragEnd = (result) => {
             
              const { source, destination } = result;
          
              if (!destination) {
                return;
              }
let questionSort = `${result.draggableId}`.includes("question-")
              if(questionSort==true){
                setItems(reorder(items, source.index, destination.index));
              }else if(destination.index !== source.index && destination.droppableId == source.droppableId){
                let optionSort = `${result.draggableId}`.split("_")
                if(_.size(optionSort)==2){
                  let allItems =[...items]
                  allItems[_.head(optionSort)]['options']=reorder(allItems[_.head(optionSort)]['options'], source.index, destination.index)
                  setItems(allItems)
                }
                
              }else{
                
              }
          
             
            };
          

            useEffect(() => {
            
              if(items.length==0){
                setItems([{
                  id:"1",
                  title:"",
                  description:"",
                  type:1,
                  is_required:false,
                  options:[{value:"option 1"}]
                }])
              }
              questionList();
              viewQuestionSet()

            }, []);

            const onRemoveOptions =(index,option,optionIndex)=>{
              let options = option.splice(optionIndex, 1);
              let a =[...items]
              a[index].options=option
              setItems(a)
            }

            const onRemoveQuestionItem =(index,question_id)=>{
              let a =[]
             _.map(items,(v,i)=>{
              if(i!==index){
                a.push(v)
              }
             })
            
              setItems(a)

              if(question_id){

                props.questionDelete({
                  body:{ids:[question_id]},
                  callback:(success,data)=>{
                    if(success==true){
                      
                      
                    }
                  }
                })
              }
            }

            const findOthers = (option)=>{
              let b= option.filter((v)=>v?.isOthers==true)

              return _.size(b)>0 ?true:false
            }
            const MultipleOption =(option,index,id,providedParent,type,question_id)=>{


             return <div>
              { option && _.isArray(option) && _.size(option)>0 &&  _.map(option,(optionItem,optionIndex)=>{
                
                return optionItem?.isOthers==true? 
                <>{providedParent.placeholder}
                <div  className={optionItem?.isOthers==true?"multiple-choice-container-others":"multiple-choice-container"} 
                   style={{width:'100%'}}>
                <Input 
                readOnly={optionItem?.isOthers==true?true:false}
                onBlur={(e)=>{
                  if(_.size(option)==1){
                    let a =[...items]
                     if(a[index] && a[index]["options"] && a[index]["options"][optionIndex] && !a[index]["options"][optionIndex]?.value){
         
            a[index]["options"][optionIndex].value="option 1"
            setItems(a)

        

                    }
                    
                }}}
                style={{border:'none',paddingLeft:'0px',backgroundColor:optionItem?.isOthers==true?"#F9FAFB":"#FFF"}} onChange={(e)=>{
                  setChangesHappened(true)
                    
                    let a =[...items]
                    if(a[index] && a[index]["options"] && a[index]["options"][optionIndex] ){
                      a[index]["options"][optionIndex].value=e.target.value
                      setItems(a)
         
         
                             }
                  
                  
                }} value={optionItem?.value=="#Others"?"Others":optionItem?.value}  placeholder={`Option ${optionIndex+1}`}  />
                 {findOthers(option)==true &&_.size(option)==2 ?(optionItem?.isOthers==true? <span style={{cursor:'pointer'}} onClick={()=>{
                    onRemoveOptions(index,option,optionIndex)
                  }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17 7L7 17M7 7L17 17" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>:null):_.size(option)>1 &&<span style={{cursor:'pointer'}} onClick={()=>{
                    onRemoveOptions(index,option,optionIndex)
                  }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17 7L7 17M7 7L17 17" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>}
                </div></>
                :
                <Draggable
                isDragDisabled={optionItem?.isOthers==true?true:false}
                draggableId={`${index}_${optionIndex}`}
                key={`${index}_${optionIndex}`}
                index={optionIndex}
              >
                {(provided) => (
                    <div 
                   
                   ref={provided.innerRef}
                   {...provided.draggableProps}
                  
                key={optionIndex}   >
                  {provided.placeholder}
                  <div style={{display:'flex',alignItems:'center',width:'100%'}}>
                  <div    {...provided.dragHandleProps}>
                  {isHovered.id==`question_option_${index}` &&isHovered.visible==true &&optionItem?.isOthers!=true  && <svg style={{marginRight:'15px'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19 6C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4C18.4477 4 18 4.44772 18 5C18 5.55228 18.4477 6 19 6Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19 20C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18C18.4477 18 18 18.4477 18 19C18 19.5523 18.4477 20 19 20Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 20C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>}
                  </div>
                  {type==2 ?<div style={{marginRight:'16px'}}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="white"/>
<rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#475467"/>
</svg></div>:type==3 && <div style={{marginRight:'16px'}}><span className="dropdown-class-text">{optionIndex+1}.</span>
</div>
}
                  <div  className={optionItem?.isOthers==true?"multiple-choice-container-others":"multiple-choice-container"} 
                   style={{width:'100%'}}>
                <Input 
                readOnly={optionItem?.isOthers==true?true:false}
                onBlur={(e)=>{
                  if(_.size(option)==1){
                    let a =[...items]
                     if(a[index] && a[index]["options"] && a[index]["options"][optionIndex] && !a[index]["options"][optionIndex]?.value){
         
            a[index]["options"][optionIndex].value="option 1"
            setItems(a)

        

                    }
                    
                }}}
                style={{border:'none',paddingLeft:'0px',backgroundColor:optionItem?.isOthers==true?"#F9FAFB":"#FFF"}} onChange={(e)=>{
                  setChangesHappened(true)
                    
                    let a =[...items]
                    if(a[index] && a[index]["options"] && a[index]["options"][optionIndex] ){
                      a[index]["options"][optionIndex].value=e.target.value
                      setItems(a)
         
         
                             }
                  
                  
                }} value={optionItem?.value=="#Others"?"Others":optionItem?.value}  placeholder={`Option ${optionIndex+1}`}  />
                 {findOthers(option)==true &&_.size(option)==2 ?(optionItem?.isOthers==true? <span style={{cursor:'pointer'}} onClick={()=>{
                    onRemoveOptions(index,option,optionIndex)
                  }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17 7L7 17M7 7L17 17" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>:null):_.size(option)>1 &&<span style={{cursor:'pointer'}} onClick={()=>{
                    onRemoveOptions(index,option,optionIndex)
                  }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17 7L7 17M7 7L17 17" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>}
                </div></div>
                </div>
                )}
                </Draggable>
              })}
{providedParent.placeholder}
              <div >
              <div style={{display:'flex',marginTop:'16px'}} >
                  <div className="question-option-add-another-btn" onClick={()=>{
let a =[...items]
let b= [...a[index]["options"]].filter((v)=>v?.isOthers==true)
if(_.size(b)>0){
  let withoutOthers= [...a[index]["options"]].filter((v)=>v?.isOthers!=true)
  a[index]["options"]=[...withoutOthers,{value:`Option ${_.size(withoutOthers)+1}`},{value:`#Others`,isOthers:true}]
  setItems(a)
}else{
  a[index]["options"]=[...a[index]["options"],{value:`Option ${_.size(option)+1}`}]
  setItems(a)
}



                  }}>
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_8711_669)">
    <path d="M9.99996 6.66669V13.3334M6.66663 10H13.3333M18.3333 10C18.3333 14.6024 14.6023 18.3334 9.99996 18.3334C5.39759 18.3334 1.66663 14.6024 1.66663 10C1.66663 5.39765 5.39759 1.66669 9.99996 1.66669C14.6023 1.66669 18.3333 5.39765 18.3333 10Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8711_669">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg></span>
                <span className="add-other-btn-text">Add option</span>
                  </div>

                  {findOthers(option) ==false &&type!=3 &&<div className="add-other-btn" onClick={()=>{
let a =[...items]
a[index]["options"]=[...a[index]["options"],{value:`#Others`,isOthers:true}]
setItems(a)
                  }}>
<span className="add-other-btn-text">Add “Other”</span>
                  </div>}
                </div>
                <Divider style={{marginTop:'16px',marginBottom:'0px'}} />
                  <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'8px'}}>
<div style={{display:'flex',alignItems:'center',marginRight:'16px'}}>
<Switch style={{backgroundColor: items[index].is_required ? '#EF6820' : '#F2F4F7',outline:'none'}} value={items[index].is_required} onChange={()=>{
  let a = [...items]
  a[index].is_required = !a[index].is_required
setItems(a)
}} />
<span className="required-class-text" style={{marginLeft:'8px'}}>Required</span>
</div>
<div style={{cursor:'pointer'}} onClick={()=>{
  onRemoveQuestionItem(index,question_id)
}}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
                </div>
              </div> 
            
              </div>
            }

            const renderOptionsList =(option,type,index,id,provided,question_id)=>{
              

              if(type==1 || type==2 ||type==3){
                return <div
                onMouseEnter={() => setIsHovered({id:`question_option_${index}`,visible:true})}
                   onMouseLeave={() => setIsHovered({id:'',visible:false})}
                > {MultipleOption(option,index,id,provided,type,question_id)}</div>
              }else if(type==4){
                return <div
                onMouseEnter={() => setIsHovered({id:`question_option_${index}`,visible:true})}
                   onMouseLeave={() => setIsHovered({id:'',visible:false})}
                > <div>
                  <div className="short-answer-container">
                  <span className="short-answer-container-text">Your users will see a text box like this one</span>
                  </div>
                  <Divider style={{marginTop:'16px',marginBottom:'0px'}} />
                  <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'8px'}}>
<div style={{display:'flex',alignItems:'center',marginRight:'16px'}}>
<Switch style={{backgroundColor: items[index].is_required ? '#EF6820' : '#F2F4F7',outline:'none'}} value={items[index].is_required}
 onChange={()=>{
  let a = [...items]
  a[index].is_required = !a[index].is_required
setItems(a)
}} />
<span className="required-class-text" style={{marginLeft:'8px'}}>Required</span>
</div>
<div style={{cursor:'pointer'}} onClick={()=>{

onRemoveQuestionItem(index,question_id)
}}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
                </div>
                  </div></div>
              }
              

            }
            const handleChange = (value) => {
              
            };

            const onChangeSingleQuestions =(value,field,index)=>{
              let a =[...items]
              a[index][field]=value
setItems(a)
            }

            const validationForm =(itemsList)=>{
              let a =[...items]
              let b =[]
              _.map(itemsList,(v,i)=>{
                if(v?.title &&v?.type==4){
                  b.push(v)
                }else if(v?.title &&_.size(v?.options)>0){
                  b.push(v)
                }
              })
              return b.length==items.length?true:false


            }

            const viewQuestionSet =()=>{
              if(searchParams.get('question_id')){
                props.questionSetsView({
                  params:{id:searchParams.get('question_id')},
                  callback:(success,data)=>{
                   if(success==true && _.get(data,'data.details.id')){
                    setquestionSetTitle(_.get(data,'data.details.label'))
                   }

                  }})

              }
            }
            const questionList =()=>{
              if(searchParams.get('question_id')){
                let body={label:questionSetTitle}
                if(searchParams.get('question_id')){
                  body.experience_registration_info_set_id=searchParams.get('question_id')
                  body.limit=1000
                  setspinnerLoading(true)
                props.questionList({
                  params:body,
                  callback:(success,data)=>{
                    setspinnerLoading(false)
                    if(success==true){
                      let types =[
                        "",
                        "multichoice",
                        "checkbox",
                        "dropdown",
                        "text"
                    
                    ]
                    let a = []
                  if(_.size(_.get(data,'data.items'))>0){
                    
                    _.map(_.get(data,'data.items'),(value,index)=>{
                      

                      const UpdateOptionValue =(option) =>{
                        
                        if(_.size(option)>0 && _.isArray(option)==true){
                         return _.map(option,(v)=>{
                          return v?.value=="#Others"?{value:"#Others",isOthers:true}:{...v}
                         })

                        }else{
                          return option
                        }
                      }
                      a.push({
                        id:`${index+1}`,
                        question_id:value?.id,
                        title:value?.label,
                        description:value?.description,
                        type:_.indexOf(types,value?.type),
                        is_required:value?.is_required,
                        options:UpdateOptionValue(value?.options)
                      })
                    })

                  }
                  setItems(a)

                    }
                  }
                }) 
              }

                }
                }
let list_sort_id =[]
            const questionSetsCreateUpdate =()=>{

              if(questionSetTitle){
                let id =searchParams.get('question_id')?"questionSetsUpdate":"questionSetsCreate" 
                let body={label:questionSetTitle}
                if(searchParams.get('question_id')){
                  body.id=searchParams.get('question_id')
                }
                setbuttonLoading(true)
                setspinnerLoading(true)
                props[id]({
                  body:body,
                  callback:(success,data)=>{
                    if(success==true && _.get(data,'details.id')){
                      
                      if(_.size(items)>0){
                        
                        questionCreateUpdate(items,_.get(data,'details.id'),[])
                      }else{
                        setsuccessModalShow(true)
                        setbuttonLoading(false)
                        setspinnerLoading(false)
                      }
                      
                    }
                  }
                }) }}



            const questionCreateUpdate =(items,id,questionIds=[])=>{
           
              let types =[
                "",
                "multichoice",
                "checkbox",
                "dropdown",
                "text"
            
            ]
           let value = _.head(items)
           if(value){

      
                let options =[]
if(value?.type==1 || value?.type==2 || value?.type==3){
                  _.map(value?.options,(v,i)=>{
options.push({value:v?.value})
                  })

                }
               
                let body ={
                  "label": value?.title,
                  "is_required": value?.is_required,
                  "type": types[value?.type],
                  "info_type": "general",
                  "description": value?.description,
                  "options": value?.type==4?null:options
              }
              if(!value?.question_id){
                body.experience_registration_info_set_id= id
              }
              
              
              createUpdateQuestion(value,body,id,items,questionIds)
            }
            }

            const createUpdateQuestion =(value,body,id,items,questionIds)=>{
              let list =[...items]
              let url =value?.question_id?"questionUpdate":"questionCreate" 
              let params ={}
              if(value?.question_id){
                params.id = value?.question_id
                // params.registration_info_set_id = id
              }
              setbuttonLoading(true)
              setspinnerLoading(true)
              props[url]({
                body:body,
                params:params,
                callback:(success,data)=>{
                  let question_id = _.isArray(questionIds)==true && _.size(questionIds)>0?[...questionIds]:[]
                  
              
                  if(success==true){
                    question_id=[...questionIds,data?.details?.id];
                    if(data?.details?.id){
                    }
                    let a =list.slice(1,_.size(list))
                    
                    if(_.size(a)>0){
                      questionCreateUpdate(a,id,question_id)
                    }else{
                      setspinnerLoading(false)
                      setsuccessModalShow(true)
                      setbuttonLoading(false)
                      if(searchParams.get("question_id")){
                        questionSortFunction(question_id);
                      }
                      
                    }
                   

                  }else{
                    setbuttonLoading(false)
                  }
                }
              })
            
              
             }


//             const questionCreateUpdate =(items,id)=>{
           
//               let types =[
//                 "",
//                 "multichoice",
//                 "checkbox",
//                 "dropdown",
//                 "text"
            
//             ]
//             let list_id =[]
//             
//             let filter_items =[...items]
//               _.map(items,(value,index)=>{
//                 let options =[]

//                 if(value?.type==1 || value?.type==2 || value?.type==3){
//                   _.map(value?.options,(v,i)=>{
// options.push({value:v?.value})
//                   })

//                 }
               
//                 let body ={
//                   "label": value?.title,
//                   "is_required": value?.is_required,
//                   "type": types[value?.type],
//                   "info_type": "general",
//                   "description": value?.description,
//                   "options": value?.type==4?null:options
//               }
//               if(!value?.question_id){
//                 body.experience_registration_info_set_id= id
//               }
//               
//               let url =value?.question_id?"questionUpdate":"questionCreate" 
//               let params ={}
//               if(value?.question_id){
//                 params.id = value?.question_id
//               }
//               setbuttonLoading(true)
//               props[url]({
//                 body:body,
//                 params:params,
//                 callback:(success,data)=>{
//                   
              
//                   if(success==true){
//                     
//                     if(data?.details?.id){
//                       filter_items[index]["q_id"]=data?.details?.id
//                       list_id.push({id:_.get(value,'id'),item:data?.details?.id})
//                       list_sort_id=[...list_sort_id,data?.details?.id]
//                       setsortOrderId([...sortOrderId,data?.details?.id])
//                       // list_id.push(data?.details?.id)
//                       questionSortFunction(list_id,filter_items)
//                     }
//                     if(_.size(items)==index+1){
//                       setItems(filter_items)
//                       setbuttonLoading(false)
//                       // questionList()
                     
                      


//                     }

//                   }else{
//                     setbuttonLoading(false)
//                   }
//                 }
//               })
//               })
//             }


          
const questionSortFunction =(list_id)=>{
 
if(_.size(list_id)==_.size(items)){
  let body_params = {
    registration_info_ids:list_id
  };

  if(searchParams.get("question_id")){
    body_params.registration_info_set_id = searchParams.get("question_id")
  }
  props?.questionSort({
    body:body_params,
    callback:(success,data)=>{
      
     if(success==true){
      questionList()
     }
    }
  })
}

  // let a =_.map(items,'id')
}

const modalTitle = (
  <div style={{ display: "flex", flexDirection: "column", paddingLeft: '24px', paddingRight: '24px' }}>
         <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="alert-circle">
<path id="Icon" d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>

                <IoMdClose style={{ height: '24px', width: '24px', cursor: 'pointer' }} onClick={() => {
                     props?.onOutSideClick(false)
                }} color='#667085' />

         </div>
         <div className='email-modal-title' >You may have unsaved changes!</div>

  </div>
);
useEffect(() => {
if(changesHappened==true){
props?.setoutsideDisabled(true)
}
}, [changesHappened]);
            return (<>

  <Modal centered={true}
              closeIcon={null}
              title={modalTitle}
              open={props?.outsideClick}
              onOk={() => {
                     props?.onOutSideClick(false)

              }}
              // onCancel={() => {
              //        setIsModalOpen(false)
              // }}
              footer={null}
              rootClassName='email-modal-content'
       >
       <div style={{paddingLeft:'24px',paddingRight:'24px',marginTop:'4px'}}>
       <div className="email-modal-title-des">Any changes that haven’t been saved will be lost. Are you sure you want to leave this page?</div>
       <div style={{display:'flex',justifyContent:'flex-end',marginTop:'32px'}}>
        <Button style={{height:'44px',marginRight:'12px'}}
        onClick={()=>{
          props?.onOutSideClick(false)
        }}
        className="create-template-modal-button-1">No, take me back</Button>
        <Button
        onClick={()=>{
          props?.onBack()
        }}
        style={{height:'44px'}} className="create-template-modal-button-2">Yes, leave page</Button>
       </div>
       </div>

        </Modal>
               <Modal centered={true} 
             
             footer={null} closeIcon={null} title={null} open={successModalShow} className="create-question-modal-container" style={{width:'435px'}}>
       <div style={{padding:'16px'}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <span><svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8"/>
<path d="M25 27L28 30L38 20M32 19H23.8C22.1198 19 21.2798 19 20.638 19.327C20.0735 19.6146 19.6146 20.0735 19.327 20.638C19 21.2798 19 22.1198 19 23.8V32.2C19 33.8802 19 34.7202 19.327 35.362C19.6146 35.9265 20.0735 36.3854 20.638 36.673C21.2798 37 22.1198 37 23.8 37H32.2C33.8802 37 34.7202 37 35.362 36.673C35.9265 36.3854 36.3854 35.9265 36.673 35.362C37 34.7202 37 33.8802 37 32.2V28" stroke="#12B76A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
<span style={{cursor:'pointer'}} onClick={()=>{
  setsuccessModalShow(false)
  history('/template-list?menu=template')
  props?.onCreate() 
}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="x-close">
<path id="Icon" d="M18 6L6 18M6 6L18 18" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
</span>

        </div>
        <div style={{marginTop:'16px'}}>
          <div className="create-question-modal-title">Your question set was successfully {searchParams.get('question_id')?"updated":"created"}!</div>
          <div className="create-question-modal-des" style={{marginTop:'4px'}}>You can now assign sessions to this question set on the next screen</div>
        </div>
        <div style={{display:'flex',justifyContent:'flex-end',marginTop:'30px'}}>
          <div className="create-question-modal-button" onClick={()=>{
  setsuccessModalShow(false)
  history('/template-list?menu=template')
  props?.onCreate() 
}}>
            <span className="create-question-modal-button-text">Done</span>
          </div>
        </div>
       </div>
      </Modal>
             {spinnerLoading==true &&<div className="spinner-overlay">
      <div className="spinner-container">
      <ThreeDots
  visible={true}
  height="80"
  width="80"
  color="#EF6820"
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
      </div>
    </div>}
            <div className='header-class-card-new' style={{ display: 'flex',justifyContent:'space-between',paddingLeft:'-4px',paddingBottom:'16px' }}>
            <div className="create-template-container">
               <Image onClick={()=>{
                if(changesHappened){
                  props?.onOutSideClick(true)
                }else{
                  props?.onBack()
                }


                     }} preview={false} src={require('../../../assets/img/template/back.png')} style={{width:'48px',height:'48px',cursor:'pointer'}}/>
               <div className="create-template-header-title" style={{marginLeft:'6px'}}>{searchParams.get('question_id')?"Edit":"Create"} a question set</div>
            </div>
           
            </div>
              <DragDropContext onDragEnd={onDragEnd}>
<Droppable  droppableId="question" type="droppableItem" >
          {(provided) => (
 <div ref={provided.innerRef} className="create-question-container">
<div style={{marginTop:'32px'}}>
<Input value={questionSetTitle} onChange={(v)=>{
   setChangesHappened(true)
        setquestionSetTitle(v.target.value)
      }} placeholder="Add a title*" className="title-input" style={{width:'100%'}}/>
</div>

{items.map((item, index) => (
                <Draggable  draggableId={`question-${item.id}`}
               
                key={`question-${item.id}`}
                index={index}>
                  {(parentProvider) => (
<div className="card-drag-container" ref={parentProvider.innerRef}
 onMouseEnter={() => setIsHovered({id:`question_${item.id}`,visible:true})}
 onMouseLeave={() => setIsHovered({id:'',visible:false})}
                        {...parentProvider.draggableProps}
                >
                   <Droppable  droppableId={item.id} key={item.id} >
                   {(provided) => (
<div ref={provided.innerRef}>
{ <div {...parentProvider.dragHandleProps} style={{marginBottom:"8px"}}>
{isHovered.id==`question_${item.id}` &&isHovered.visible==true  &&<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19 6C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4C18.4477 4 18 4.44772 18 5C18 5.55228 18.4477 6 19 6Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19 20C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18C18.4477 18 18 18.4477 18 19C18 19.5523 18.4477 20 19 20Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 20C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>}
</div>}
  <div className="title-select-option-container">
    <Input value={item?.title} onChange={(v)=>{
       setChangesHappened(true)
        onChangeSingleQuestions(v.target.value,'title',index)
      }} placeholder="Question title" className="title-input"/>
    <Select
    value={`${item?.type}`}
      className="title-select-option"
      onChange={(v)=>{
        onChangeSingleQuestions(v,'type',index)
      }}
      options={[
        { value: '1', label: 'Multiple choice' },
        { value: '2', label: 'Checkbox' },
        { value: '3', label: 'Dropdown' },
        { value: '4', label: 'Short answer'},
      ]}
    />
  </div>
  <div style={{marginTop:'8px',marginBottom:'16px'}}>
  <Input   value={item?.description} onChange={(v)=>{
     setChangesHappened(true)
        onChangeSingleQuestions(v.target.value,'description',index)
      }} placeholder="Question description (optional)" className="title-input"/>
  </div>
  
   
    {renderOptionsList(item?.options,item?.type,index,item.id,provided,item?.question_id)}


    {/* {provided.placeholder} */}
</div>)}

</Droppable></div> )}
                </Draggable>
              ))}
              {provided.placeholder}
              <div 
              onClick={()=>{
               if(items.length==0){
                setItems([{
                  id:"1",
                  title:"",
                  description:"",
                  type:1,
                  is_required:false,
                  options:[{value:"option 1"}]
                }])
               }else{
                setItems([...items, {
                  id:`${items.length+1}`,
                  title:"",
                  description:"",
                  type:1,
                  is_required:false,
                  options:[{value:"option 1"}]
                }])
               }

              }}
              style={{display:'flex',justifyContent:'center',marginTop:'24px',marginBottom:'24px'}}><div className="add-another-questions-btn" >
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_8711_669)">
    <path d="M9.99996 6.66669V13.3334M6.66663 10H13.3333M18.3333 10C18.3333 14.6024 14.6023 18.3334 9.99996 18.3334C5.39759 18.3334 1.66663 14.6024 1.66663 10C1.66663 5.39765 5.39759 1.66669 9.99996 1.66669C14.6023 1.66669 18.3333 5.39765 18.3333 10Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8711_669">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg></span>
                <span className="add-another-question-text">Add question</span>
              </div></div>

              {
                <div
              onClick={()=>{
                if(questionSetTitle && questionSetTitle!=""&& validationForm(items)==true &&buttonLoading==false){
                  questionSetsCreateUpdate()
                }
                //
               
              }}
              style={{display:'flex',justifyContent:'center',marginTop:'24px',marginBottom:'24px'}}>
                <div 
              className={(questionSetTitle && questionSetTitle!=""&& validationForm(items)==true)?`save-questions-set-btn ${buttonLoading==true?"extra-width-class":""}`
              :"save-questions-set-btn-disabled"
              }  >
               {buttonLoading==true? <ThreeDots
                visible={true}
              height={'30'}
                width="80"
                color="#fff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
                />: <><span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M15.5 8H9.1C8.53995 8 8.25992 8 8.04601 7.89101C7.85785 7.79513 7.70487 7.64215 7.60899 7.45399C7.5 7.24008 7.5 6.96005 7.5 6.4V3M17.5 21V14.6C17.5 14.0399 17.5 13.7599 17.391 13.546C17.2951 13.3578 17.1422 13.2049 16.954 13.109C16.7401 13 16.4601 13 15.9 13H9.1C8.53995 13 8.25992 13 8.04601 13.109C7.85785 13.2049 7.70487 13.3578 7.60899 13.546C7.5 13.7599 7.5 14.0399 7.5 14.6V21M21.5 9.32548V16.2C21.5 17.8802 21.5 18.7202 21.173 19.362C20.8854 19.9265 20.4265 20.3854 19.862 20.673C19.2202 21 18.3802 21 16.7 21H8.3C6.61984 21 5.77976 21 5.13803 20.673C4.57354 20.3854 4.1146 19.9265 3.82698 19.362C3.5 18.7202 3.5 17.8802 3.5 16.2V7.8C3.5 6.11984 3.5 5.27976 3.82698 4.63803C4.1146 4.07354 4.57354 3.6146 5.13803 3.32698C5.77976 3 6.61984 3 8.3 3H15.1745C15.6637 3 15.9083 3 16.1385 3.05526C16.3425 3.10425 16.5376 3.18506 16.7166 3.29472C16.9184 3.4184 17.0914 3.59135 17.4373 3.93726L20.5627 7.06274C20.9086 7.40865 21.0816 7.5816 21.2053 7.78343C21.3149 7.96237 21.3957 8.15746 21.4447 8.36154C21.5 8.59171 21.5 8.8363 21.5 9.32548Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg></span>
                              <span className="save-question-text">Save question set</span></>}
                {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <path d="M15.5 8H9.1C8.53995 8 8.25992 8 8.04601 7.89101C7.85785 7.79513 7.70487 7.64215 7.60899 7.45399C7.5 7.24008 7.5 6.96005 7.5 6.4V3M17.5 21V14.6C17.5 14.0399 17.5 13.7599 17.391 13.546C17.2951 13.3578 17.1422 13.2049 16.954 13.109C16.7401 13 16.4601 13 15.9 13H9.1C8.53995 13 8.25992 13 8.04601 13.109C7.85785 13.2049 7.70487 13.3578 7.60899 13.546C7.5 13.7599 7.5 14.0399 7.5 14.6V21M21.5 9.32548V16.2C21.5 17.8802 21.5 18.7202 21.173 19.362C20.8854 19.9265 20.4265 20.3854 19.862 20.673C19.2202 21 18.3802 21 16.7 21H8.3C6.61984 21 5.77976 21 5.13803 20.673C4.57354 20.3854 4.1146 19.9265 3.82698 19.362C3.5 18.7202 3.5 17.8802 3.5 16.2V7.8C3.5 6.11984 3.5 5.27976 3.82698 4.63803C4.1146 4.07354 4.57354 3.6146 5.13803 3.32698C5.77976 3 6.61984 3 8.3 3H15.1745C15.6637 3 15.9083 3 16.1385 3.05526C16.3425 3.10425 16.5376 3.18506 16.7166 3.29472C16.9184 3.4184 17.0914 3.59135 17.4373 3.93726L20.5627 7.06274C20.9086 7.40865 21.0816 7.5816 21.2053 7.78343C21.3149 7.96237 21.3957 8.15746 21.4447 8.36154C21.5 8.59171 21.5 8.8363 21.5 9.32548Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
                <span className="save-question-text">Save question set</span> */}
              </div>
              
              </div>}
              
            </div>
          )}
        </Droppable>
      </DragDropContext></>
            );

}

const mapStateToProps = state => {
  return {

    userProfile: _.get(state, 'app.host.profile.data.details'),

  }
}

const mapDispatchToProps = dispatch => ({
  questionSetsCreate: details => dispatch(global.redux.action.questions.questionSetsCreate(details)),
  questionSetsUpdate: details => dispatch(global.redux.action.questions.questionSetsUpdate(details)),
  questionSetsView: details => dispatch(global.redux.action.questions.questionSetsView(details)),
  questionList: details => dispatch(global.redux.action.questions.questionList(details)),
  questionCreate: details => dispatch(global.redux.action.questions.questionCreate(details)),
  questionUpdate: details => dispatch(global.redux.action.questions.questionUpdate(details)),
  questionSort: details => dispatch(global.redux.action.questions.questionSort(details)),
  questionDelete: details => dispatch(global.redux.action.questions.questionDelete(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestions)
