import React, {useEffect, useState} from 'react';
import { Rating } from 'react-simple-star-rating';
import { Avatar, Spin, Image, Button, Pagination } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { Scrollbars } from 'react-custom-scrollbars';

import "./style.css";

const ProfileReviews = (props) => {

  const [hostData, setHostData] = useState({});
  const [reviewItem, setReviewItem] = useState([]);
  const [getRatingMeta, setRatingMeta] = useState({});
  const [getLoading, setLoading] = useState(true);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3,
    total: 0,
  });

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const page = parseInt(urlParams.get('page')) || 1;
    const pageSize = parseInt(urlParams.get('pageSize')) || 3;
    setPagination((prev) => ({ ...prev, current: page, pageSize }));
    hostProfileReviews();
  }, []);

  const hostProfileReviews = (pageSize = 25) => {
    props.hostProfileData({
      callback:(res, data) => {
        
        if(!_.isEmpty(_.get(data, 'data.details'))){
          setHostData(_.get(data, 'data.details'));
          props.ratingListApi({
            params: {
              sort: 'created_at.desc',
              expand: 'host,guest,top_tags,experience',
              limit: pageSize,
              host: _.get(data, 'data.details.id')
            },
            callback: (response, value) => {
              if(response){
                setLoading(false)
              }
                
                if(_.size(_.get(value, 'data.items')) > 0){
                  setReviewItem(_.get(value, 'data.items'))
                  setRatingMeta(_.get(value, 'data.meta'));
                  setPagination((prev) => ({ ...prev, total: _.get(value, 'data.meta.total', {}) }));
                }
            }
          })
        }
      }
    })
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({ ...prev, current: page }));
    // fetchData(page, pageSize); the experience load list have to be comes here
    hostProfileReviews(page, pageSize)
    window.history.pushState({ page, pageSize }, "Review Title", `?page=${page}&pageSize=${pageSize}`);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return 
    }
    if (type === 'next') {
      return 
    }
    return originalElement;
  };


  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
      }}
      spin
    />
  );

  const handleScroll = (data, type) => {
    if (data && data.top > 0.9 && _.get(getRatingMeta, 'total') > 25) {
      if (_.size(reviewItem) < _.get(getRatingMeta, 'total')) {
        hostProfileReviews(_.get(getRatingMeta, 'limit') + 25)
      }


    }

  }
  return (
    <div>
        <div style={{width: "100%", marginTop: "30px"}}>
                                <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                                  <div className='container-titles'>Reviews</div>
                                  
                                  {
                                    _.size(reviewItem) > 0 && (
                                      <div style={{display: "flex", flexDirection: "row"}}>
                                  <Rating initialValue={1} readonly={true} iconsCount={1} size={20} className='experince-rating-star'/>
                                  <p className='rated-star' style={{marginTop: "3px", marginLeft: "5px"}}>{_.get(hostData, 'average_ratings').toFixed(1)}</p>
                                  <p className='dot'></p>
                                  <p className='rated-star' style={{marginLeft: "5px", marginTop: "3px"}}>{`${_.get(hostData, 'no_of_ratings')} Reviews`}</p>
                                  </div>
                                    )
                                  }
                                  
                                  </div>
                                  <Spin spinning={getLoading} style={{display: "flex", alignItems: "center", justifyContent: "center", color: "#e85d04"}} indicator={antIcon} />
                                  {
                                    _.size(reviewItem) > 0 && reviewItem.map((value, index) => {
                                      let src = `${_.get(getRatingMeta, 'photo.path')}/${_.get(getRatingMeta, 'photo.folders.guest')}/${_.get(getRatingMeta, 'photo.sizes.medium')}/${_.get(value, 'guest.photo')}`
                                      const fullName = `${_.get(value, 'guest.first_name')} ${_.get(value, 'guest.last_name')}`;
                                  const initials = fullName.split(' ').map(name => name[0]).join('');
                                      return(
                                        <div>
                                          <Scrollbars 
                                          className="hidden-scroll-x"
                                          onScrollFrame={(e) => handleScroll(e)}
                                          // style={{ height: 'calc(100vh - 125px)' }}
                                          autoHeight={true}
                                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                          >
                                <div className='card' style={{marginBottom: "12px"}}>
                                  <div className='rating-container' style={{display: "flex", flexDirection: "row"}}>
                                  {
                                    !_.isEmpty(_.get(value, 'guest.photo')) ?  <Avatar src={src} size={40}/> :  <Avatar size={40}>{_.upperCase(initials)}</Avatar>
                                   }
                          
                           <div className='rating-name'>
                           {
                           
                           `${_.startCase(_.get(value, 'guest.first_name'))} ${_.startCase(_.get(value, 'guest.last_name'))}`
                          }
                          </div>
                          </div>
                          <div className='rating-date'>{moment(_.get(value, 'updated_at')).format('MMMM DD, YYYY')}</div>
                          <div className='rate-star-container'>
                          <Rating initialValue={_.get(value, 'star_rating')} readonly={true} iconsCount={_.get(value, 'star_rating')} size={20} className='experince-rating-star'/>
                          </div>
                          <div className='rate-message'>
                          {_.get(value, 'message')}
                          </div>
                          </div>
                          </Scrollbars>
                                    </div>
                                      )
                                  
                                    })
                                  }
                                  {
                                    !getLoading && _.isEmpty(reviewItem) && <div style={{display: "flex", flexDirection: "column"}}>
                                      <p className='container-titles-desc'>No reviews yet</p>
                                      <Image preview={false} src={require('../../assets/img/no_reviews_1.png')}/>
                                      </div>
                                  }
                                  
                                  </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
     
  }
}

const mapDispatchToProps = dispatch => ({
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
  ratingListApi: details => dispatch(global.redux.action.finances.ratingList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileReviews)