import { UpOutlined,FileImageOutlined } from '@ant-design/icons'
// import Input from 'antd/lib/input/Input'
import React, { useEffect, useState, useRef } from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, Tabs, Modal, Input, message} from 'antd'
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { geocodeByAddress } from 'react-places-autocomplete';
import './style.css'
import { connect } from 'react-redux'
import TextArea from 'antd/lib/input/TextArea';

import MainMenu from '../main-menu';
import RatingReviews from '../reviews/reviews-list';
import globalConfig from '../../core_units/config/env-variables'
import axios from 'axios';
import moment from 'moment';
import kitty from '../../core_units/chat-system/chatkitty/instance';
import PlacesAutocomplete from 'react-places-autocomplete';
import UserHandle from './userHandle'
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;
const { Search } = Input;
const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
]
const currencyList =[{name:'CAD',value:'CAD'},
{name:'USD',value:'USD'}
]
const ProfileInfo =(props)=>{
  const messageRef = useRef(null);
  let history = useNavigate();

  const [form] = Form.useForm();
  const [confirmCoachClub,setConfirmCoachClub] =useState('1');
  const [taxToggle,setTaxToggle] =useState('1')
  const [categoriesList,setCategoriesList] =useState([])
  const [address_results, setaddress_results] = useState([]);
  const [address_fetching, setaddress_fetching] = useState(false);
  const [photo_display, setphoto_display] = useState(null);
  const [photo_display_details, setphoto_display_details] = useState({});
  const [userInfoDetails, setuserInfoDetails] = useState({})
  const [userInfoDetailsMeta, setuserInfoDetailsMeta] = useState({});
  const [address, setAddress] = useState('');

  const [selectedValues, setSelectedValues] = useState([]);
  const [country, setcountry] = useState(null)
  const [TaxListData, setTaxListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preSettingCountry, setPresettingCountry] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [tempSelectedOption, setTempSelectedOption] = useState(null);
  const[dataDetails, setDataDetails] =  useState({});
  const[checkAvailablityState, setCheckAvailablityState] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const options = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ];


  useEffect(()=>{
    loadTaxList()
    getProfileDetails()
  },[]);

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);


  const handleSelectChange = (value) => {
    
    setTempSelectedOption(value);
    setIsModalOpen(true);
  };

  const handleModalOk = (value) => {
    
    setSelectedOption(tempSelectedOption);
    setcountry(tempSelectedOption);
    form.setFieldsValue({currency:tempSelectedOption});
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    
    setTempSelectedOption(_.upperCase(_.get(dataDetails, 'country'))=="CA"?"CAD":"USD");
    setcountry(_.upperCase(_.get(dataDetails, 'country'))=="CA"?"CAD":"USD")
    form.setFieldsValue({country:_.upperCase(_.get(dataDetails, 'country'))=="CA"?"CAD":"USD"});
    setIsModalOpen(false);
  };


  const loadTaxList =()=>{
    props.taxList({
      params:{limit:100},
      callback:(res,data)=>{
        
    if(res==true && _.get(data,'data.items[0]'))
        setTaxListData(_.get(data,'data.items'))
      }
    })
        }
  const getProfileDetails =()=>{
    let conditionType = _.includes(_.get(props,'islogindata.types'),1)==true?'hostProfileData':'guestProfileData';

    getCategoriesList()
    props[conditionType]({
 callback:(res,data)=>{
  setDataDetails(_.get(data,'data.details'));
   
  if(_.get(data,'data.details')){
    setuserInfoDetails(_.get(data,'data.details',{}))
    
    form.setFieldsValue({
      first_name:_.startCase(_.get(data,'data.details.first_name')),
      last_name:_.startCase(_.get(data,'data.details.last_name')),
      display_name:_.startCase(_.get(data,'data.details.display_name')),
      bio:_.get(data,'data.details.bio'),
      email:_.get(data,'data.details.email'),
      phone:_.get(data,'data.details.contact_details[0].phone'),
      refund_policy: _.get(data,'data.details.meta_details.refund_policy'),
      country:_.upperCase(_.get(data,'data.details.country'))=="CA"?"CAD":"USD",
      currency:_.upperCase(_.get(data,'data.details.country'))=="CA"?"CAD":"USD",
      user_handle: _.get(data,'data.details.handle')
    });
    if(!_.isEmpty(_.get(data,'data.details.meta_details.timezones'))){
      form.setFieldsValue({
        timezones: _.isArray(_.get(data,'data.details.meta_details.timezones'))?_.head(_.get(data,'data.details.meta_details.timezones')):_.get(data,'data.details.meta_details.timezones')
      })
    }

    setConfirmCoachClub(_.get(data,'data.details.business_type')=='coach'?'2':'1')
    setTaxToggle(_.get(data,'data.details.is_taxes_collected')==false?'2':'1')
    if (_.get(data, 'data.details.address') && _.get(data, 'data.details.location')) {
      let split_location = _.split(_.get(data, 'data.details.location.lat'), ', ')
      const address_results = [{
          text: _.get(data, 'data.details.address'),
          value: JSON.stringify({ text: _.get(data, 'data.details.address'), lat: _.get(data, 'data.details.location.x'), lng: _.get(data, 'data.details.location.y'), postal_code: '' }),
      }]
      form.setFieldsValue({
          location:  _.get(data, 'data.details.address')
      })

      setaddress_results(address_results)
      setuserInfoDetailsMeta({..._.get(data, 'data.meta')})
      if(_.get(data, 'data.details.photo')){
        let photo_path =`${_.get(data, 'data.meta.photo.path')}/${_.get(data, 'data.meta.photo.folder')}/${_.get(data, 'data.meta.photo.sizes.medium')}/${_.get(data, 'data.details.photo')}?time=${new Date().getTime()}`
        setphoto_display(photo_path)
      }
      
  }
  }
 } 
})
  }

  const getFilterTax = (list,currency)=>{
    let country = currency=='CAD' ? 'CA' :'US'
        let a = _.filter(list,(v,i)=>_.get(v,'country')==country)
    return a
      }

  const getCategoriesList  =()=>{
    props.list({
      callback:(res,data)=>{
        if(_.get(data,'data.items')){
          setCategoriesList(_.get(data,'data.items'))
        }
      }
    })
  }
  const fetchPostalCode = address_components => {
    let postal_code = null;
    address_components.forEach(value => {
        value.types.forEach(type => {
            if (type === 'postal_code') {
                postal_code = value.long_name
            }
        })
    })
    return postal_code;
};
const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 50);
};
const removeImage = info => {
};

const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const checkImageWidth = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          resolve({width:image.width,height:image.height});
          return {width:image.width,height:image.height};
        };
        reader.onerror = (err) => reject(err);
      };
    });
  };


const onChangePhoto = async info => {
    
        getBase64(info.file.originFileObj, (imageUrl) => {
          setphoto_display_details(info.file)  
          setphoto_display(imageUrl);
        });
    
};


   //Address search
   const fetchAddress = value => {
    setaddress_results([])
    setaddress_fetching(true);
    geocodeByAddress(value).then(body => {
        
        const address_results = _.map(body, location => ({
            text: location.formatted_address,
            value: JSON.stringify({ text: location.formatted_address, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
        }));

        setaddress_results(address_results)
        setaddress_fetching(false);

    }).catch(error => {
        setaddress_results([])
        setaddress_fetching(true);
    });
};
//Initializing debounce for address
const addressDelayedQuery = _.debounce(q => fetchAddress(q), 1200);


  const formatPhoneNumber=(phoneNumberString)=> {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      // return   match[1] + '-' + match[2] + '-' + match[3];
      form.setFieldsValue({
        phone:match[1] + '-' + match[2] + '-' + match[3]
      })
    }
    return null;
  }
const isJson=(str)=> {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}
const chartkittyuserupdate = async(data) =>{
 
  await kitty.updateCurrentUserDisplayPicture({
     file: {
       url:  data.displayPictureUrl,
       name: data.display_name? data.display_name:`${data.first_name} ${data.last_name}`,
       contentType: 'image/jpeg',
       size: 123115,
     },
   });
  return kitty.updateCurrentUser((user) => {
         user.displayName = data.display_name? data.display_name:`${data.first_name} ${data.last_name}`
         user.displayPictureUrl =data.displayPictureUrl
         return user;
       });
       
 }
  const onFinish =(e)=>{
    
    let request_details={...e}
    
if (isJson(_.get(_.head(address_results),'value'))) {
  let location_parse = JSON.parse(_.get(_.head(address_results),'value'))
  request_details.location=`${_.get(location_parse,'lat')},${_.get(location_parse,'lng')}`
  request_details.address = _.get(location_parse,'text')
  request_details.zip_code = _.get(location_parse,'postal_code')
}
// request_details.meta_details={timezones: _.get(request_details, 'timezones') , };
request_details.business_type=confirmCoachClub==1?"club":'coach'
request_details.is_taxes_collected=taxToggle==1?true:false
request_details.contact_details=JSON.stringify([{'phone':_.get(e,'phone')}])

if(request_details['email'] === _.get(dataDetails, 'email')){
delete request_details['email']
} 

if(!_.isEmpty(photo_display_details)){
  updatePhoto(_.get(e,'photo'))
};


  request_details.meta_details={currency:_.get(request_details,'currency'),
  base_platform_amount_applicable: _.get(userInfoDetails, 'meta_details.base_platform_amount_applicable'),base_platform_amount_visible: _.get(userInfoDetails, 'meta_details.base_platform_amount_visible'),
   refund_policy: _.get(request_details, 'refund_policy'), timezones: [_.get(request_details, 'timezones')]}

  request_details.country =_.get(request_details, 'country')=="USD"?"US":"CA"
  request_details.tax_state=_.get(request_details, 'tax_state')



props.hostUpdateProfile({
  body:{...request_details, 
    // meta_details:{base_platform_amount_applicable: _.get(userInfoDetails, 'meta_details.base_platform_amount_applicable'),base_platform_amount_visible: _.get(userInfoDetails, 'meta_details.base_platform_amount_visible'), refund_policy: _.get(request_details, 'refund_policy'), timezones: [_.get(request_details, 'timezones')]}
  },
  callback:(res,data)=>{
if(res){
  getProfileDetails()
}
if (_.get(data,'details[0]')) {
  
  let displayPictureUrl= `${_.get(userInfoDetailsMeta, 'photo.path')}/${_.get(userInfoDetailsMeta, 'photo.folder')}/${_.get(userInfoDetailsMeta, 'photo.sizes.medium')}/${_.get(data, 'details[0].photo')}` 
  chartkittyuserupdate({..._.get(data,'details[0]'),displayPictureUrl})
 notification.success({
   message:'Success',
   description:'Profile has been updated successfully'

  })

}
  }
})

// props.updatePhone({
//   body:{phone:_.get(e,'phone')},
//   callback:(res,data)=>{
//     if(res==true){

//     }
    
//   }
// })
  }
  const updatePhoto = (data) => {
    return new Promise(resolve => {
    if (_.get(photo_display_details, 'originFileObj')) {
      
        let formData = new FormData()
        formData.append('photo', _.get(photo_display_details, 'originFileObj'))
        props.UpdatePhotoData({
            body: formData,
            callback: (res, data) => {
              
                if (res == true) {
                  let details =JSON.parse(_.get(data,'data'))
                  let displayPictureUrl= `${_.get(userInfoDetailsMeta, 'photo.path')}/${_.get(userInfoDetailsMeta, 'photo.folder')}/${_.get(userInfoDetailsMeta, 'photo.sizes.medium')}/${_.get(details, 'details[0].photo')}` 
  chartkittyuserupdate({..._.get(details,'details[0]'),displayPictureUrl})
                  getProfileDetails()
                    // notification.success(
                    //     { message: 'Success', description: _.get(responseData, 'message') }
                    // )  
                }
                    resolve();
            }
        });
    }
    else{
        resolve();
    }
})
};

const onChangeTab = () => {
}

const updateStripeAccountInfo = ()=>{
  if(_.get(userInfoDetails,'stripe_user_id')){
    axios.post(`https://api.stripe.com/v1/accounts/${_.get(userInfoDetails,'stripe_user_id')}/login_links`,{},{
      headers:{
        'Authorization':`Bearer ${globalConfig.STRIPE.SECRET_KEY}`
      }
    }).then((res)=>{
      if(_.get(res,'data.url')){
        window.open(_.get(res,'data.url'),'_blank')
      }
    }).catch((error)=>{
    })
        }
};

const handleChange = (value) => {
  
  if(typeof value === "string"){
    setSelectedValues([value]);
  }
  
  
};
const handleChange1 = (value) => {
  setAddress(value);
};

const handleSelect = async (value) => {

  
  setaddress_results([])
    setaddress_fetching(true);
    geocodeByAddress(value).then(body => {
        
        const address_results = _.map(body, location => ({
            text: location.formatted_address,
            value: JSON.stringify({ text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
        }));

        setaddress_results(address_results)
        setaddress_fetching(false);
       
      form.setFieldsValue({
          location: value
      })
      
      let state =extractStateName(body)
      let ada = _.filter(TaxListData,(v)=>_.lowerCase(v.state)==_.lowerCase(state))
      if(_.size(ada)>0){
        form.setFieldsValue({
          tax_state: _.get(ada[0],'id')
      })
      }
      
      
      setaddress_results(address_results)
    }).catch(error => {
        setaddress_results([])
        setaddress_fetching(true);
    });
};
const extractStateName = results => {
  for (let i = 0; i < results[0].address_components.length; i++) {
    const component = results[0].address_components[i];
    if (component.types.includes('administrative_area_level_1')) {
      return component.long_name; // Return the state name
    }
  }
  return '';
};

const searchOptions = {
  componentRestrictions: { country: form.getFieldValue('country')=="USD"?['us']:['ca'] }, // Restrict search to United States and Canada
};

const onSearchUserHandle = (value) => {

if(value){
  props.checkUserHandleAvailablityAPI({
    params:{
      handle: value
    },
    callback:(res, data)=>{
      
      if(res){
       
        if(_.get(data, 'data.availability')){
          setCheckAvailablityState(_.get(data, 'data.availability'))
          setShowSuccessMessage(true)
            props.updateUserHandleAPI({
              body:{
                handle: value
              },
              callback : (updatedResponse, updatedValue) =>{
                
                if(updatedResponse){
                  getProfileDetails()
                }
              }
            })
        } else {
          setCheckAvailablityState(_.get(data, 'data.availability'))
          setShowMessage(true)
        }
        
      }
    }
  })
}
}
    const render =()=>{
      
        const uploadButton = (
            <div>
               <FileImageOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          );
        return (
          <div>
            <MainMenu profileTab={true} />
           
        <div className="coach-full-conatiner profiles-tabs" style={{display:'flex'}}>
        <Tabs defaultActiveKey="1" onChange={onChangeTab} style={{width:'100%'}}>
              <TabPane tab="Profile" key="1">
              <Form form={form} onFinishFailed={(e)=>console.log('onFinishFailed',e)} onFinish={(e)=>onFinish(e)}>
            <div className="coach-info-conatiner-first">
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'900',fontSize:'27px'}}>Profile</span>
                    <span style={{fontWeight:'700',fontSize:'20px'}}>User Information</span>
                </div>
      
      
<div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
  First Name
</div>
<Form.Item name='first_name'   rules={[
          {
            required: true,
            message: 'Please input your First Name!',
          },
        ]}><Input /></Form.Item>

                    </div>
                    </div>
                    <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
   Last Name
</div>
<Form.Item name='last_name'   rules={[
          {
            required: true,
            message: 'Please input your Last Name!',
          },
        ]}><Input /></Form.Item>

                    </div>
                    </div>
                    <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
   Email
</div>
<Form.Item name='email'   rules={[
          {
            required: true,
            message: 'Please input your Email!',
          },
        ]}><Input /></Form.Item>

                    </div>
                    </div>
    <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
   Phone Number
</div>
<Form.Item name='phone'   rules={[
          {
            required: true,
            message: 'Please input your phone!',
          },
        ]}><Input onChange={(e)=>formatPhoneNumber(_.get(e,'target.value'))} /></Form.Item>

                    </div>
                    </div>
                    <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
  <div className="title-style">
Country
</div>
<Form.Item name='country'   rules={[
          {
            required: true,
            message: 'Please input your Country!',
          },
        ]}>
  <Select
  // onClick={() => showModal()}
  // onSelect={(value)=>{
  //   
  //   setcountry(value);

  //   form.setFieldsValue({currency:value})
  
  // }}
  onChange={handleSelectChange}
  value={tempSelectedOption}
  // defaultValue={selectedOption}
  style={{width:'100%'}}
    optionFilterProp="children">
   {_.map(countryList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select>
  </Form.Item>
  </div>
  </div>
                    <div className="name-inner-conatiner-input1">
<div style={{padding:'8px 29px'}}>
<div className="title-style">
Address
</div>
<Form.Item
                                name="location"
                                rules={[{ required: true, message: 'Please input your location!' }]}
                            >
                              <PlacesAutocomplete
                              

        value={address}
        onChange={handleChange1}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input {...getInputProps({ placeholder: 'Search address' })}  />
            {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
            <div>
              {loading ? <div>Loading...</div> : null}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
                  marginTop:10,
                };
                
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                                {/* <Select getPopupContainer={trigger => trigger.parentNode}
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    onSearch={search => search ? addressDelayedQuery(search) : ''}
                                    //   onChange={(e) => onChangeForm(e, 'address')}
                                    placeholder={'Search address...'}
                                    notFoundContent={
                                        address_fetching ? (
                                            <Spin size="small" />
                                        ) : null
                                    }
                                >
                                    {address_results && address_results.length > 0 && address_results.map(d => (
                                        <Select.Option key={d.value}>{d.text}</Select.Option>
                                    ))}
                                </Select> */}
                            </Form.Item>

                            </div>
                    </div>
                    <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
   Select Timezone
</div>
<Form.Item name='timezones'>
          <Select
    // mode="multiple"
    value={selectedValues}
    style={{
      width: '100%',
    }}
    placeholder="Select a timezone"
    // defaultValue={['china']}
    onChange={handleChange}
    // optionLabelProp="label"
  >
     {(moment.tz.zonesForCountry(country=="USD"?"US": "CA")).map((item, index) => {
      
                                  return (
                                    <Option key={index} value={item}>{item}</Option>

                                  )
                                })}
  </Select>
        </Form.Item>

                    </div>
                    </div>
                   
  <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
  <div className="title-style">
Currency
</div>
<Form.Item name='currency'   rules={[
          {
            required: true,
            message: 'Please input your Currency!',
          },
        ]}>
  <Select
  style={{width:'100%'}}
  value={country}
  disabled
    optionFilterProp="children">
   {_.map(currencyList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select></Form.Item>
  </div>
  </div>

  {/* <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
Province</div>
<Form.Item name='tax_state'   rules={[
          {
            required: true,
            message: 'Please input your Province!',
          },
        ]}>
          <Select disabled={form.getFieldValue('currency')?false:true} getPopupContainer={trigger => trigger.parentNode}
                                    placeholder={'Search Province...'}
                                >
                                    { _.map(getFilterTax(TaxListData,form.getFieldValue('currency')),(d,i) => (
                                        <Select.Option  key={_.get(d,'id')}>{_.startCase(_.get(d,'state'))}</Select.Option>
                                    ))}
                                </Select>
        </Form.Item>

                    </div>
                    </div> */}
                    <div className="name-inner-conatiner-input1">
                    <div style={{padding:'8px 29px'}}>
<div className="title-style">
Do you wish to charge and collect taxes
</div>
<div className="btn-container" style={{cursor:'pointer',justifyContent:'left',backgroundColor:'#fff'}}> <div
 style={{backgroundColor:taxToggle==1?"#e85f24":"#a7a7a7"}}
 onClick={()=>setTaxToggle('1')}
className="btn-inner-container">
                <div style={{    padding:"10px 69px",color: "#fff"}}>Yes</div></div>
           <div  
 onClick={()=>setTaxToggle('2')} className="btn-inner-container"
            style={{backgroundColor:taxToggle==2?"#e85f24":"#a7a7a7",marginLeft:34,cursor:'pointer'}}> 
            <div style={{    padding:"10px 69px",color: "#fff"}}>No</div></div>
            </div>

                    </div>
                    </div>
                    {/* <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
   Do you wish to charge and collect Taxes
</div>
<div className="btn-container" style={{cursor:'pointer',justifyContent:'left',backgroundColor:'#fff'}}> <div
 style={{backgroundColor:taxToggle==1?"#e85f24":"#a7a7a7"}}
 onClick={()=>setTaxToggle('1')}
className="btn-inner-container">
                <div style={{    padding:"10px 69px",color: "#fff"}}>Yes</div></div>
           <div  
 onClick={()=>setTaxToggle('2')} className="btn-inner-container"
            style={{backgroundColor:taxToggle==2?"#e85f24":"#a7a7a7",marginLeft:34,cursor:'pointer'}}> 
            <div style={{    padding:"10px 69px",color: "#fff"}}>No</div></div>
            </div>

                    </div>
                    </div> */}
    
                
            </div>

            <div className="coach-info-conatiner-first" style={{marginTop:56}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                   <span style={{fontWeight:'700',fontSize:'20px'}}>Club/Coach Profile</span>
                </div>
                <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
Club/Coach Selection
</div>
<div className="btn-container" style={{cursor:'pointer',justifyContent:'left',backgroundColor:'#fff'}}> <div
 style={{backgroundColor:confirmCoachClub==1?"#e85f24":"#a7a7a7"}}
 onClick={()=>setConfirmCoachClub('1')}
className="btn-inner-container">
                <div style={{    padding:"10px 69px",color: "#fff"}}>Club</div></div>
           <div  
 onClick={()=>setConfirmCoachClub('2')} className="btn-inner-container"
            style={{backgroundColor:confirmCoachClub==2?"#e85f24":"#a7a7a7",marginLeft:34,cursor:'pointer'}}> 
            <div style={{    padding:"10px 69px",color: "#fff"}}>Coach</div></div>
            </div>

                    </div>
                    
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
Club/Coach Name
</div>
<Form.Item name='display_name'   rules={[
          {
            required: true,
            message: 'Please input your name!',
          },
        ]}><Input /></Form.Item>

                    </div>
                    
                    </div>

{/* User handle starts */}
{/* <div style={{display:'flex',flexDirection:'column'}}>
                   <span style={{fontWeight:'700',fontSize:'20px'}}>Handle</span>
                </div>
                    <div className="name-inner-conatiner-input1">
                      <div style={{padding:'8px 29px'}}>
                    <div className="user-handle-title-style">
                    Your handle is what is used to create a custom URL for your listings. For example, if you choose a handle called AllStars,<br/>then it will be: communiti.app/allstars
                    </div>

                    </div>
                    
    <div style={{padding:'8px 29px'}}>
      <Form.Item style={{marginBottom: "5px"}} name='user_handle'>
          <UserHandle defaultData = {_.get(dataDetails, 'handle')}/>
      </Form.Item>

                    </div>
                    
                    </div> */}
    {/* User handle ends */}

    
                    <div className="name-inner-conatiner-input1">
 <div style={{padding:'8px 29px'}}>
<div className="title-style">
Club / Coach Description
</div>
<Form.Item name='bio'   rules={[
          {
            required: true,
            message: 'Please input your bio!',
          },
        ]}><TextArea /></Form.Item>

                    </div>
                    
                    </div>

                    <div className="name-inner-conatiner-input1">
 <div style={{padding:'8px 29px'}}>
<div className="title-style">
Refund Policy
</div>
<Form.Item name='refund_policy'   rules={[
          {
            required: true,
            message: 'Please input your refund policy!',
          },
        ]}><TextArea /></Form.Item>

                    </div>
                    
                    </div>
    
                    
 
<div className="title-style" style={{color:'#000',fontSize:'16px'}}>
Profile Picture
</div>
<Form.Item style={{width:photo_display?'15%':'100%'}} name='photo'   rules={[
          {
            required: photo_display?false: true,
            message: 'Please input your photo!',
          },
        ]}> <Upload
        accept=".jpg,.jpeg"
        name="avatar"
        style={{width:'55%'}}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        onRemove={removeImage}
        onChange={onChangePhoto}
        customRequest={dummyRequest}
    >
        {photo_display ? <img  src={photo_display} alt="avatar" style={{ width: '100%',height:'100%', borderRadius: '20px' }} /> : uploadButton}

    </Upload>
</Form.Item>

                    
                    
                    </div>
 
            
                
           <Form.Item   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"flex-end",display:'flex',marginTop:'5%'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button className='form-btn-4'style={{justifyContent:'flex-end'}} htmlType="submit">Update</Button>
            {/* </div> */}
            </Form.Item>
            {/* <div style={{    padding:"10px 69px",color: "#fff"}}>Apply</div></div> */}
            
            </Form>  
            <div className="financial-details">

              <div className="financial-details-1">
              <span style={{fontWeight:'900',fontSize:'27px'}}>Financial Information</span>
<div>
  <span>You will be redirected to Stripe's website to update you account information. When you are finished, save your updates and it will be updated on Communiti.</span>
  <div   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"flex-start",display:'flex',marginTop:'2%',width:'78%'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button onClick={()=>updateStripeAccountInfo()} className='financial-btn' style={{width:'78%',fontWeight:'bold'}} >Update Your Banking Information</Button>
            {/* </div> */}
            </div>
</div>
              </div>
              <div className="financial-details-2" style={{marginTop:'5%'}}>
              <span style={{fontWeight:'900',fontSize:'27px'}}>Contact</span>
<div>
  <span>If you are experiencing issues or require any help please contact us at <span style={{textDecorationLine:'underline',cursor:'pointer'}} onClick={()=> window.open(`mailto:support@communiti.app`,'_blank')}>support@communiti.app</span> and we will get to you as soon as possible.</span>
  
</div>
</div>

<div className="financial-details-2" style={{marginTop:'5%'}}>
<span style={{fontWeight:'900',fontSize:'27px'}}>Terms of Service and Privacy Policy</span>
<div style={{display:'flex',flexDirection:'row',marginTop:'2%'}}>
<div style={{display:'flex',flexDirection:'column'}}>
    <span onClick={()=>window.open(globalConfig.COMMUNITI_PRIVACY.TERMS,'_blank')} style={{textDecorationLine:'underline',cursor:'pointer',fontSize:'22px',color:'#e85f25'}}>Terms of Service</span>
    <span style={{fontSize:'11px'}}>Last Updated: March 7, 2021</span>
  </div>
  <div style={{display:'flex',flexDirection:'column',marginLeft:'3%'}}>
    <span onClick={()=>window.open(globalConfig.COMMUNITI_PRIVACY.URL,'_blank')} style={{textDecorationLine:'underline',cursor:'pointer',fontSize:'22px',color:'#e85f25'}}>Privacy Policy</span>
    <span style={{fontSize:'11px'}}>Last Updated: March 7, 2021</span>
  </div>
  
  
</div>
              </div>


            </div>
              </TabPane>
              <TabPane tab="Reviews" key="2">
              <RatingReviews />
              </TabPane>
            </Tabs>
            <div>
              <Modal centered={true} title="Are you sure?" open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel} okText="Yes" cancelText="No">
        <p>Do you want to change country?</p>
      </Modal>
            </div>
          
        </div>
        </div>
        )
    }

    return render()
}
const mapStateToProps = state => {
  return {
     checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
     isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
     islogindata: _.get(state, 'app.account.isLogin'),
     userProfile: _.get(state, 'app.host.profile.data.details'),
     guestUserProfile: _.get(state, 'app.guest.profile.data.details')

  }
}

const mapDispatchToProps = dispatch => ({
  isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
  list: details => dispatch(global.redux.action.categories.list(details)),
  hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
  guestUpdateProfile: details => dispatch(global.redux.action.guest.update(details)),
  updatePhone: details => dispatch(global.redux.action.guest.updatePhone(details)),
  UpdatePhotoData: details => dispatch(global.redux.action.host.updatePhoto(details)),
  taxList: details => dispatch(global.redux.action.tax.list(details)),
  checkUserHandleAvailablityAPI: details => dispatch(global.redux.action.userHandle.checkUserHandleAvailablity(details)),
  updateUserHandleAPI: details => dispatch(global.redux.action.userHandle.createOrUpdateUserHandle(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo)