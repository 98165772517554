import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { Form, Input, Image, notification } from "antd";
import "./login.css";
import _ from "lodash";
import { connect } from "react-redux";
import appEnvirontVariables from "../../core_units/config/env-variables";
import {
  gapi,
} from "gapi-script";
import { GoogleLogin } from "@react-oauth/google";
import { Container, Grid, Box, Typography, Button } from '@mui/material';
import PhoneNumberModal from "./phone/phone-login-modal";
const LoginPageComponent = (props) => {
  let history = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: appEnvirontVariables.GOOGLE.GOOGLE_CLIENT_ID,
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const onLogin = (values) => {
    values.username = lowercaseFirstLetter(_.get(values, "username"));
    setLoading(true);
    props.login({
      body: { ...values },
      callback: (response, data) => {
        setLoading(false);
        if (response == true) {
        } else {
        }
      },
    });
  };
  const lowercaseFirstLetter = (str) => {
    // converting first letter to lowerCase
    const lowerCase = str.replace(/^./, `${str[0]}`.toLowerCase());

    return lowerCase;
  };
  const getGoogleToken = (response, signInType) => {
    //
    
    if (response) {
      //
      let requestParams = {
        type: "google",
        token: _.get(response, "credential"),
        // token: response.tokenId || response.Zi.id_token
      };
      //
      props.login({
        body: requestParams,
        callback: (res, data) => {
          if (res == true && _.get(data, "isLoggedIn") == true) {
            //
            notification.success({
              message: "Success",
              description: "Login successful",
            });
            window.location.reload();

            // history('/communiti/terms-service')
          }
        },
      });
    }
  };

  // const login = useGoogleLogin({
  // 	onSuccess: tokenResponse => getGoogleToken(tokenResponse),
  //   });

  const getFacebookToken = (response, signInType) => {
    if (response.accessToken) {
      let requestParams = {
        type: "facebook",
        token: response.accessToken,
      };
      props.login({
        body: requestParams,
        callback: (res, data) => {
          if (res == true && _.get(data, "isLoggedIn") == true) {
            notification.success({
              message: "Success",
              description: "Login successful",
            });
            history("/communiti/onboarding");
          }
        },
      });
    }
  };
  const getAppleToken = (response, signInType) => {
    if (_.get(response, "getAppleToken.id_token")) {
      let requestParams = {
        type: "apple",
        token: _.get(response, "getAppleToken.id_token"),
      };
      props.login({
        body: requestParams,
        callback: (res, data) => {
          if (res == true && _.get(data, "isLoggedIn") == true) {
            notification.success({
              message: "Success",
              description: "Login successful",
            });
            // history('/terms-service')
          }
        },
      });
    }
  };
  const customStyles = {
	container: {
	  backgroundColor: '#f0f0f0',
	  border: '1px solid #ccc',
	  padding: '10px',
	  borderRadius: '5px',
	},
	button: {
	  color: '#ffffff',
	  backgroundColor: '#4285f4',
	  border: 'none',
	  padding: '10px 20px',
	  borderRadius: '5px',
	  fontSize: '16px',
	}
     };
  let host = window.location.host;
  let hostName = host.split(":");
  const baseUrl = `${window.location.protocol}//${hostName[0]}`;

  const render = () => {
    
    return (
       <Container sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',height:"calc(100vh - 2px)",padding:'0px'}}>
		
      <Grid  justifyContent="center" sx={{width:{
	  xs: "300px", // mobile
         sm: "300px", // tablet
         md: "450px", // small desktop
         lg: "450px",

      } 
}}>
        <Grid item xs={12} sm={5} lg={12} >
          <Box
            sx={{
              borderRadius: "12px",
              background: "var(--Base-White, #FFF)",
boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
              
		paddingLeft:{
			xs: "12px", // mobile
			sm: "20px", // tablet
			md: "29px", // small desktop
			lg: "32px",
		},
		paddingRight:{
			xs: "12px", // mobile
			sm: "20px", // tablet
			md: "29px", // small desktop
			lg: "32px",
		},
		paddingBottom:{
			xs: "12px", // mobile
			sm: "20px", // tablet
			md: "29px", // small desktop
			lg: "32px",
		},
            }}
          >
            <Box
              sx={{  paddingBottom: "16px" }}
            >
              <Typography sx={{ color:"#101828",fontSize:"24px",fontWeight:'600' }} textAlign={"center"}>Log in or Sign up</Typography>
            </Box>
            <Box >
              <Box sx={{ marginBottom: "24px" }}>
               
                <Typography  sx={{ color:"#475467",fontSize:"16px", }}  textAlign={"center"}>
		  To get started, choose an option below to create an account, or access your existing account
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'center',marginBottom:"24px",display:'flex',alignItems:'center',justifyContent:'center', width:"100%" }}>
		
    <GoogleLogin
     
      onSuccess={getGoogleToken}
      size="large"
      logo_alignment="left"
      text="continue_with"
      
      style={customStyles.button}
    />

              </Box>
		
		<Box sx={{ textAlign: 'center',marginBottom:"24px" }}>
		{<PhoneNumberModal />}
		
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
    );
  };
  return render();
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (details) =>
      dispatch(global.redux.action.account.socialLogin(details)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent);
