import { EditFilled,DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, ArrowLeftOutlined, SaveFilled, ClockCircleFilled, ArrowRightOutlined, CheckOutlined, InfoCircleOutlined, CalendarOutlined, FolderOpenOutlined, BorderOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React,{useState,useEffect} from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, InputNumber,Modal,Image, Radio, Popconfirm, message} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { head, set } from 'lodash';
import { connect } from 'react-redux'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import GoogleMapReact from 'google-map-react';
import ViewTemplate from '../view/index';
import Geocode from "react-geocode";
import PlacesAutocomplete from 'react-places-autocomplete';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import '../../onboarding-screen/style.css'
import MainMenu from '../../main-menu'
import Menus from '../../menu'

import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

Geocode.setApiKey('AIzaSyBIU6YefaUI89t5f7LwsvY0z21sLmLdpYc');

const { Panel } = Collapse;
const {confirm} =Modal
const { Option } = Select;
const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
]
const currencyList =[{name:'CAD',value:'CAD'},
{name:'USD',value:'USD'}
]
var Link = Quill.import('formats/link');

class MyLink extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    if(!value.startsWith("http")) {
      value = "http://" + value;
    }
    node.setAttribute('href', value);
    return node;
  }
}

Quill.register(MyLink);
const AnyReactComponent = ({ text }) => <div><Image width={35} preview={false} height={35} src={require('../../../assets/img/map-marker-icon.png')} /></div>;
const TemplateTab =(props)=>{
    const [form] = Form.useForm();
    let history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [address_results, setaddress_results] = useState([]);
    const [address_fetching, setaddress_fetching] = useState(false);
    const [photo_display, setphoto_display] = useState(null);
    const [photo_display_details, setphoto_display_details] = useState({});
    const [clauseTitle, setclauseTitle] = useState('')
    const [clauseDes, setclauseDes] = useState('')
    const [clauseObject, setclauseObject] = useState({})
    const [clauseArray, setclauseArray] = useState([])
    const [pageLoading, setPageLoading] = useState(false);
    const [clauseInputShow, setClauseInputShow] = useState(true);
    const [fileList, setFileList] = useState([])
    const [buttonLoading,setButtonLoading] =useState(false)
    const [ModalVisable,setModalVisable] =useState(false)
    const [BackModalVisable,setBackModalVisable] =useState(false)
    const [TaxListData, setTaxListData] = useState([])
    const [categoriesList,setCategoriesList] =useState([])
    const [LocationData, setLocationData] = useState({lat: 11.937871, lng: 79.819953})
const [TemplateViewDetails, setTemplateViewDetails] = useState({})
const [TemplateViewDetailsMeta, setTemplateViewDetailsMeta] = useState({})
const [templateViewType, settemplateViewType] = useState('view')

const [templateTypeModal, setTemplateTypeModal] = useState(false);
const [getScheduledValue, setScheduledValue] = useState(false);
const [getOpenValue, setOpenValue] = useState(false);
const [getLeagueValue, setLeagueValue] = useState(false);
const[getSelectedTemplateType, setSelectedTemplateType] = useState();

const [isConcurrentModalOpen, setIsConcurrentModalOpen] = useState(false);
const [getYourProfileDetails, setYourProfileDetails] = useState({});

const [clauseLink, setClauseLink] = useState('')
const [isValidUrl, setIsValidUrl] = useState('');
const[getClauseOptions, setClauseOptions] = useState('description');

const [moreAboutArray, setMoreAboutArray] = useState([]);
const [moreAboutInputShow, setMoreAboutInputShow] = useState(true);
const [moreAboutObject, setMoreAboutObject] = useState({});
const [moreAboutTitle, setMoreAboutTitle] = useState('');
const [moreAboutDes, setMoreAboutDes] = useState('');
const [moreAboutLink, setMoreAboutLink] = useState('');
const [moreAboutUrl, setMoreAboutUrl] = useState('');
const [country, setcountry] = useState(null)
const[changesHappened, setChangesHappened] = useState(false);
const [value, setValue] = useState('');

const [address, setAddress] = useState('');

    const defaultProps = {
      center: {
        lat: 11.937871, lng: 79.819953
      },
      zoom: 15
    };

    useEffect(() => {
      getCategoriesList()
      if(!searchParams.get('id')){
        getLocationsData()
      }
      // getLocationsData()
    loadTaxList();
    getUserProfile();
    if(searchParams.get('id')){
      templateView(searchParams.get('id'))
    }
    
    }, []);

    const showTemplateTypeModal = () => {
        setTemplateTypeModal(true);
      };

      const handleCancel = () => {
        setTemplateTypeModal(false);
      };


    const templateView =(id)=>{
      props.viewTemplate({
        params:{id:id,expand:'photos'},
        callback:(res,data)=>{
          
          if(res){
            let dataValue = _.get(data,'data.details')
            setLocationData({lat:_.get(data,'data.details.location.x'), lng:_.get(data,'data.details.location.y')})
            setTemplateViewDetails(_.get(data,'data.details'))
            setcountry(_.get(data,'data.details.meta_details.currency'))
            setTemplateViewDetailsMeta(_.get(data,'data.meta'))
            form.setFieldsValue({currency: _.get(data,'data.details.meta_details.currency')})
            form.setFieldsValue({...dataValue, country: _.get(data,'data.details.meta_details.currency')})
            setFieldValue(data);
            // form.form.setFieldsValue({
            //   team_size: "6"
            // })
          }
        }
      })
    }
    const setFieldValue =(values)=>{
      
let details = _.pick(_.get(values,'data.details'), [
  'address', 'id', 'clauses', 'description',
  'max_no_of_openings','min_no_of_openings','host_price','tax_state','zip_code','photos','title', 'type','extra_info','team_size'

]);

if (_.get(values,'data.details.address') && _.get(values,'data.details.location')) {
                    
  const address_results = [{
      text: _.get(values,'data.details.address') ,
      value: JSON.stringify({ text:  _.get(values,'data.details.address') , lat: _.get(values,'data.details.location.x'), lng:  _.get(values,'data.details.location.y'), postal_code:''}),
    }]
    form.setFieldsValue({
        location:  _.get(values,'data.details.address') 
                           })
    setaddress_results(address_results)
}

// form.setFieldsValue({...details,country:country})

if(_.get(details,'clauses[0]')){
  setclauseArray(_.get(details,'clauses'))
  setClauseInputShow(false)

  
}

if(_.get(details,'extra_info[0]')){
  setMoreAboutArray(_.get(details,'extra_info'))
  setMoreAboutInputShow(false)
}

if(_.get(details,'photos[0]')){
  let set_photos =[]
_.map(_.get(details,'photos'),(value,index)=>{
  let make_url =`${_.get(values,'data.meta.photo.path')}/${_.get(values,'data.meta.photo.folder')}/${_.get(values,'data.meta.photo.sizes.medium')}/${_.get(value,'photo')}`

  set_photos.push( {
    uid: _.get(value,'id'),
    id: _.get(value,'id'),
    name: _.get(value,'photo'),
    status: 'done',
    url: make_url,
  },)
})
form.setFieldsValue({
  photo:set_photos
})
setFileList(set_photos)
}



    }
    const getLocationsData =async()=>{
      const { data, status } = await axios.get('https://ipapi.co/json');
      if(_.get(TemplateViewDetails, "location")){
        setLocationData({lat:_.get(TemplateViewDetails, "location.x"),lng:_.get(TemplateViewDetails, "location.y")})
      } else {
        setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
      }
      
      // defaultProps.center.lat=_.get(data,'latitude')
      // defaultProps.center.lng=_.get(data,'longitude')
     
    }
      
    const getCategoriesList  =()=>{
      props.list({
        callback:(res,data)=>{
          if(_.get(data,'data.items')){
            setCategoriesList(_.get(data,'data.items'))
          }
        }
      })
    }
    const loadTaxList =()=>{
props.taxList({
  params:{limit:100},
  callback:(res,data)=>{
if(res==true && _.get(data,'data.items[0]'))
    setTaxListData(_.get(data,'data.items'))
  }
})
    }
    

    const fetchPostalCode = address_components => {
        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        
        if(postal_code){
          form.setFieldsValue({
            zip_code:postal_code
          })
        }
        
        return postal_code;
    };
    const findLocationLocality = (addressComponentsArray, levelArray) => {
      
      for (let i = 0; i < addressComponentsArray.length; ++i) {
          for (let j = 0; j < levelArray.length; j++) {
              if (addressComponentsArray[i].types.indexOf(levelArray[j]) > -1) {
                  return {
                      locality_level: levelArray[j],
                      value: addressComponentsArray[i].long_name
                  }
              }
          }
      }
  }
const setTaxValuesTigger =(data)=>{
  let stateName = findLocationLocality(
    data,
    [
        'administrative_area_level_1'
    ]
).value

let sameState = _.filter(TaxListData, m => _.lowerCase(m.state) == _.lowerCase(stateName) && m)

let getTaxState = _.head(sameState)
if (_.get(getTaxState, 'id')) {
  
  form.setFieldsValue({
    tax_state:_.get(getTaxState, 'id')
  })
}
}

  //Get Zipcode Action
  const locationZipcode = (x, y) => {
      Geocode.fromLatLng(x, y)
          .then(json => {
            
            var addressComponent = json.results[0].formatted_address;
            setTaxValuesTigger(json.results[0].address_components)
             form.setFieldsValue({
               zip_code:fetchPostalCode(addressComponent)
             }) 
             
              
          })
          .catch(error => console.warn(error));
  }
    
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 50);
    };
    const removeImage = info => {
      
      if(_.get(info,'id')){
        props.deletePhoto({
          params:{id:searchParams.get('id')},
          body:{ids:[_.get(info,'id')]}
          ,callback:(res,data)=>{
            if(res){

            }
          }
        })
      }
    };
    
    const getBase64 = async (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const checkImageWidth = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
              resolve({width:image.width,height:image.height});
              return {width:image.width,height:image.height};
            };
            reader.onerror = (err) => reject(err);
          };
        });
      };
      const isJson=(str)=> {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      }
      const updatePhoto = (data) => {
        return new Promise(resolve => {
        if (_.get(photo_display_details, 'originFileObj')) {
            let formData = new FormData()
            formData.append('photo', _.get(photo_display_details, 'originFileObj'))
            props.UpdatePhotoData({
                body: formData,
                callback: (res, responseData) => {
                    
                    if (res == true) {
                        // notification.success(
                        //     { message: 'Success', description: _.get(responseData, 'message') }
                        // )  
                    }
                        resolve();
                }
            });
        }
        else{
            resolve();
        }
    })
    };
    const onChangePhoto = ({ fileList: newFileList })  => {
      setFileList(newFileList)
      form.setFieldsValue({
        photo:newFileList
      })
        
    };
    const onFinish =(e)=>{
        
        let request_details={...e};
        
    if (isJson(_.get(_.head(address_results),'value'))) {
      let location_parse =JSON.parse(_.get(_.head(address_results),'value'))
      request_details.location=`${_.get(location_parse,'lat')},${_.get(location_parse,'lng')}`
      request_details.address = _.get(location_parse,'text')
    }
    if(_.get(getYourProfileDetails, 'categories[0].id')){
      request_details.category = _.get(getYourProfileDetails, 'categories[0].id')
    }

      request_details.meta_details = {"team_size":_.get(request_details, 'team_size')?_.get(request_details, 'team_size'):_.get(TemplateViewDetails, 'meta_details.team_size'),currency:_.get(request_details,'currency') ? _.get(request_details,'currency') : _.get(TemplateViewDetails, 'meta_details.currency'),
      
    }
    delete request_details['team_size']
    delete request_details['email']
    request_details['clauses']=clauseArray
    request_details['extra_info']=moreAboutArray
    request_details['min_no_of_openings']=1
    request_details['mode']=1
    request_details.country =_.get(request_details, 'country')=="USD"?"US":"CA"
    request_details.tax_state=_.get(request_details, 'tax_state')
    
    setButtonLoading(true)
    let url = searchParams.get('id')?'updateTemplate':'createTemplate'
    if(searchParams.get('id')){
      request_details.id=searchParams.get('id')
      request_details['price']=_.get(request_details,'host_price')
    }
    
    props[url]({
      body:{...request_details},
      callback:(res,data)=>{

    if(url=="createTemplate" && res === true){
      history('/template-list?menu=template')
    }
    
    
    if (_.get(data,'details.id')) {
      if(_.get(e,'photo')){
        addPhotoTemplateAPI(_.get(e,'photo'),_.get(data,'details.id'))
      }else{
        setButtonLoading(false)
        addPhotoTemplateAPI([],_.get(data,'details.id'))
      }
    }else{
     
    }
    
      }
    })
    
      }

      const addPhotoTemplateAPI =(photo,id)=>{
let final_photos =[]
        if(searchParams.get('id')){
_.map(photo,(value,i)=>{
  if(!_.get(value,'id')){
    final_photos.push(value)
  }
})
}else{
  _.map(photo,(value,i)=>{
    
      final_photos.push(value)
  })
}
if(_.size(final_photos)==0){
  notification.success({
    message:'Success',
    description: `Template has been ${searchParams.get('id')?'updated':'created'} successfully.`
 
   })
   settemplateViewType('view')
   templateView(id)
   setModalVisable(true)
   setTimeout(() => {
    // setModalVisable(false)
    if(!searchParams.get('id')){
      history(`/template-list?menu=template`)
     }
     
     
   }, 1400);
   
   setButtonLoading(false)
}else{
  
  
  updateImageAPI(final_photos,id)
       
      }
      }
      const updateImageAPI =(photos,id)=>{
        
        if(_.isArray(photos)==true && _.size(photos)>0){
          let a =_.head(photos)
          updateImage(a,id,photos)
        }else{
             notification.success({
                message:'Success',
                description: `Template has been ${searchParams.get('id')?'updated':'created'} successfully.`
             
               })
               setModalVisable(true)
 
               settemplateViewType('view')
               templateView(id)
               setTimeout(() => {
                setModalVisable(false)
                if(!searchParams.get('id')){
                  history(`/template-list?menu=template`)
                 }
                 
                 
               }, 1400);
               setButtonLoading(false)
        }
      
      }

      const updateImage =(value,id,photos)=>{
       let p =[...photos]
       
          let formData = new FormData()
        // formData.append('id', id)
        formData.append('photo', _.get(value, 'originFileObj'))
        props.addPhotoTemplate({
          body:formData,
          params:{id:id},
          callback:(res,data)=>{
            if(res==true){
             
              // var pulled =photos.shift()
              let a =p.slice(1,_.size(p))
              

              updateImageAPI(_.size(p)>0?a:[],id)
            }
           
          }
        })
       
      }
    
       //Address search
       const fetchAddress = value => {
        setaddress_results([])
        setaddress_fetching(true);
        geocodeByAddress(value).then(body => {
            
            const address_results = _.map(body, location => ({
                text: location.formatted_address,
                value: JSON.stringify({ text: location.formatted_address, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
            }));
    
            setaddress_results(address_results)
            setaddress_fetching(false);
    
        }).catch(error => {
            setaddress_results([])
            setaddress_fetching(true);
        });
    };
    //Initializing debounce for address
    const addressDelayedQuery = _.debounce(q => fetchAddress(q), 1200);
    

    const setClauseDetails =()=>{
        let details =[...clauseArray]
      
if(getClauseOptions === 'description'){
  
  if(_.isEmpty(clauseDes)){
    notification.error({
      message: "Error",
      description: "Please provide clause description"
    })
  }
  if(clauseTitle && clauseDes){
    let duplicate = _.some(details,{name:clauseTitle,description:clauseDes})      
    if(duplicate==false) {
      details.push({name:clauseTitle,description:clauseDes})
    }else{
      notification.error({
        message:'Error',
        description:'This clauses title and description is already taken.'
      })
    }
      
    }
} else if(getClauseOptions === 'link'){
 
  if(_.isEmpty(clauseLink)){
    notification.error({
      message: "Error",
      description: "Please provide clause link"
    })
  }
  if(clauseTitle && clauseLink){
    let duplicate = _.some(details,{name:clauseTitle,link:clauseLink})      
    if(duplicate==false) {
      details.push({name:clauseTitle,link:clauseLink})
    }else{
      notification.error({
        message:'Error',
        description:'This clauses title and description is already taken.'
      })
    }
      
    }
}
       
if(!_.isEmpty(clauseTitle) && !_.isEmpty(clauseLink) || !_.isEmpty(clauseTitle) && !_.isEmpty(clauseDes)){       
setclauseObject({})
            setclauseDes('')
            setclauseTitle('')
            setclauseArray(details)
            setClauseInputShow(false)
}
        };
       

        // set more about session starts here
        const setMoreAboutDetails =()=>{
          let details =[...moreAboutArray];

          if(_.isEmpty(moreAboutTitle)){
            notification.error({
              message: "Error",
              description: "Please provide title"
            })
          }

          if(_.isEmpty(moreAboutDes)){
            
            notification.error({
              message: "Error",
              description: "Please provide description"
            })
          }

          if(!_.isEmpty(moreAboutTitle) && !_.isEmpty(moreAboutDes)){
            if(moreAboutTitle && moreAboutDes && moreAboutLink){
              let duplicate = _.some(details,{name:moreAboutTitle,description:moreAboutDes, link: moreAboutLink})      
              if(duplicate==false) {
                details.push({name:moreAboutTitle,description:moreAboutDes, link: moreAboutLink})
              }
                
              }
          }
    
          if(!_.isEmpty(moreAboutTitle) && !_.isEmpty(moreAboutDes)){
            if(moreAboutTitle && moreAboutDes){
              let duplicate = _.some(details,{name:moreAboutTitle,description:moreAboutDes})      
            if(duplicate==false) {
              details.push({name:moreAboutTitle,description:moreAboutDes})
            }
            }
          }
      
  
         if(!_.isEmpty(moreAboutTitle) && !_.isEmpty(moreAboutDes)){
          setMoreAboutObject({})
          setMoreAboutDes('')
          setMoreAboutTitle('')
          setMoreAboutLink('')
          setMoreAboutArray(details)
          setMoreAboutInputShow(false)
         }
              
     
          }

           // set more about session ends here
       
    

    const onEditClasuess =async(value, type,index, clauseOpt)=>{
        
if(type=="edit"){
  if(!_.isEmpty(_.get(value, 'link'))){
    setClauseOptions('link')
  }
  if(!_.isEmpty(_.get(value, 'description'))){
    setClauseOptions('description')
  }
  setclauseObject(value)
  setclauseTitle(_.get(value,'name'))
  setclauseDes(_.get(value,'description'))
  setClauseLink(_.get(value,'link'))
let remove = _.remove(clauseArray,(e,i)=>i==index)

  setclauseArray(clauseArray)
  
  setClauseInputShow(true)
}else{
  let details =[...clauseArray]
  let remove = _.remove(details,(e,i)=>i==index)
  setclauseArray(details)   

  setTimeout(() => {
    setclauseArray(details)   
  }, 200);
 

}


    }

    const onEditMoreAbout =async(value, type,index, clauseOpt)=>{
      
if(type=="edit"){
  setMoreAboutObject(value)
  setMoreAboutTitle(_.get(value,'name'))
  setMoreAboutDes(_.get(value,'description'))
  setMoreAboutLink(_.get(value,'link'))
let remove = _.remove(moreAboutArray,(e,i)=>i==index)

setMoreAboutArray(moreAboutArray)

setMoreAboutInputShow(true)
}else{
let details =[...moreAboutArray]
let remove = _.remove(details,(e,i)=>i==index)
setMoreAboutArray(details)   

setTimeout(() => {
  setMoreAboutArray(details)   
}, 200);


}


  }

    const loadClausesList =(data)=>{
      
      return  _.map(data,(value,index)=>{
       
return <div key={index}>
   
   <div className="name-details">
       <span className="name-details-title">Title</span>
       <span>{_.get(value,'name')}</span>
   </div>
   
   
   {!_.isEmpty(_.get(value,'description')) ? 
   <div className="name-details">
   <span className="name-details-title">Description
</span>
<div dangerouslySetInnerHTML={{ __html: _.get(value,'description') }}/>
       {/* <span>{_.get(value,'description')}</span> */}
   </div>
   : ''
   }
   
   
   
 {!_.isEmpty(_.get(value,'link')) ?
  <div className="name-details">
 <span className="name-details-title">Link
</span>
     <span>{_.get(value,'link')}</span>
 </div>
 :''
 }
 
   
  
   <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
  <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>onEditClasuess(value,'edit',index, getClauseOptions)}>
    <EditFilled size={23}  style={{marginRight:7,fontSize:20}}/> Edit</div> 

    <Popconfirm
    style={{display:'flex',alignItems:'center',cursor:'pointer'}}
    title="Are you sure to delete this clause?"
    onConfirm={() => onEditClasuess(value,'delete',index)}
    // onCancel={cancel}
    okText="Continue"
    cancelText="Cancel"
  >
   <div style={{display:'flex',alignItems:'center',cursor:'pointer'}}><DeleteFilled 
    size={23} style={{marginLeft:23,marginRight:7,fontSize:20}} /> Delete</div> 
    </Popconfirm>
   </div>
   
   </div>
        })
    }

    //More about session starts here

    const loadMoreAboutList =(data)=>{
      
      return  _.map(data,(value,index)=>{
        
return <div key={index}>
   
   <div className="name-details">
       <span className="name-details-title">Title</span>
       <span>{_.get(value,'name')}</span>
   </div>
   
   
   {!_.isEmpty(_.get(value,'description')) ? 
   <div className="name-details">
   <span className="name-details-title">Description
</span>
<div dangerouslySetInnerHTML={{ __html: _.get(value,'description') }}/>
       {/* <span>{_.get(value,'description')}</span> */}
   </div>
   : ''
   }
   
   
   
 {!_.isEmpty(_.get(value,'link')) ?
  <div className="name-details">
 <span className="name-details-title">Link
</span>
     <span>{_.get(value,'link')}</span>
 </div>
 :''
 }
 
   
  
   <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
  <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>onEditMoreAbout(value,'edit',index)}>
    <EditFilled size={23}  style={{marginRight:7,fontSize:20}}/> Edit</div> 
    <Popconfirm
    style={{display:'flex',alignItems:'center',cursor:'pointer'}}
    title="Are you sure to delete?"
    onConfirm={() => onEditMoreAbout(value,'delete',index)}
    // onCancel={cancel}
    okText="Continue"
    cancelText="Cancel"
  >
   <div style={{display:'flex',alignItems:'center',cursor:'pointer'}}><DeleteFilled 
    size={23}   style={{marginLeft:23,marginRight:7,fontSize:20}} /> Delete</div> 
    </Popconfirm>
   </div>
   
   </div>
        })
    }

    //More about session ends here

    const onChangeForm =(value,type)=>{
      setChangesHappened(true)
let josn_data = JSON.parse(value)
defaultProps.center.lat= _.get(josn_data,'lat')
defaultProps.center.lng= _.get(josn_data,'lng')
setLocationData({lat: _.get(josn_data,'lat'),lng:_.get(josn_data,'lng')})
if(_.get(josn_data,'lat') &&_.get(josn_data,'lng')){
  locationZipcode(_.get(josn_data,'lat'),_.get(josn_data,'lng'))
}

      
    }

    const onChangeBack =()=>{
      if(searchParams.get('id')){
      // let getFormsValues =form.getFieldsValue([
      //   'category', 'description',
      //   'max_no_of_openings','host_price','tax_state','zip_code','title','address','team_size'
      
      // ])
      // let oldFormData = _.pick(TemplateViewDetails,[ 'category', 'description',
      // 'max_no_of_openings','host_price','tax_state','zip_code','title','address','team_size'])
      if(changesHappened === true){
        setBackModalVisable(true)
      }else{
        setBackModalVisable(false)
        history('/template-list?menu=template')
      }
    }else{  history('/template-list?menu=template')

    }
    };

    const onClickScheduledRadio = () => {
        setScheduledValue(true);
        setOpenValue(false);
        setLeagueValue(false)
    }
    const onClickOpenRadio = () => {
        setOpenValue(true);
        setScheduledValue(false);
        setLeagueValue(false);
    }
    const onClickLeagueRadio = () => {
        setLeagueValue(true);
        setScheduledValue(false);
        setOpenValue(false);
    }

    const onSelectTemplateType = (value) => {
      
      setSelectedTemplateType(value);
      setChangesHappened(true)

    };

    const showConcurrentModal = () => {
      setIsConcurrentModalOpen(true)
    };

    const concurrentOk = () => {
      setIsConcurrentModalOpen(false);
    };
    
    const concurrentCancel = () => {
      setIsConcurrentModalOpen(false);
    };

    const getUserProfile = () => {
      props.hostProfileData({
        params:{
          expand: "categories"
        },
        callback: (response, data) => {
          
          if(response){
            setYourProfileDetails(_.get(data, 'data.details'));
            
          }
        }
      })
    }
    const getFilterTax = (list,currency)=>{
      let country = currency=='CAD' ? 'CA' :'US'
          let a = _.filter(list,(v,i)=>_.get(v,'country')==country)
      return a
        }
    const onChangeClauseUrl = (e) => {
      setClauseLink(e.target.value)
      const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
     if(regex.test(e.target.value)){
      setIsValidUrl('')
     } else {
      setIsValidUrl('Please enter valid URL')
     }
    };

    const onChangeMoreAboutUrl = (e) => {
      setMoreAboutLink(e.target.value)
      const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
     if(regex.test(e.target.value)){
      setMoreAboutUrl('')
     } else {
      setMoreAboutUrl('Please enter valid URL')
     }
    }

    const onChangeClauseOptions = (e) => {
      
      setClauseOptions(e.target.value)

    }
    const handleChange1 = (value) => {
      setAddress(value);
    };
    
    const handleSelect = async (value) => {
    
      
      setaddress_results([])
        setaddress_fetching(true);
        geocodeByAddress(value).then(body => {
            
            const address_results = _.map(body, location => ({
                text: location.formatted_address,
                value: JSON.stringify({ text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
            }));
    
            setaddress_results(address_results)
            setaddress_fetching(false);
           
          form.setFieldsValue({
              location: value,
          })
          if(_.size(address_results)>0){
            let a = JSON.parse(address_results[0].value)
            form.setFieldsValue({
              zip_code: _.get(a,'postal_code'),
          })
          }
          
          let state =extractStateName(body)
          let ada = _.filter(TaxListData,(v)=>_.lowerCase(v.state)==_.lowerCase(state))
          if(_.size(ada)>0){
            form.setFieldsValue({
              tax_state: _.get(ada[0],'id')
          })
          }
          
          
          setaddress_results(address_results)
          let a = JSON.parse(address_results[0].value)
          setLocationData({lat:a.lat,lng:a.lng})
        }).catch(error => {
            setaddress_results([])
            setaddress_fetching(true);
        });
    };
    const extractStateName = results => {
      for (let i = 0; i < results[0].address_components.length; i++) {
        const component = results[0].address_components[i];
        if (component.types.includes('administrative_area_level_1')) {
          return component.long_name; // Return the state name
        }
      }
      return '';
    };
    
    const searchOptions = {
      componentRestrictions: { country: form.getFieldValue('country')=="USD"?['us']:['ca'] }, // Restrict search to United States and Canada
    };
    const archiveOrUnarchive = () => {
      if(searchParams.get('id')){
        props.archiveActiveTemplates({
          body:{
            id : parseInt(searchParams.get("id")),
            status: _.get(TemplateViewDetails, 'status') === 1 ? 0 : 1,
            status_glossary: _.get(TemplateViewDetails, 'status') === 1 ? "Archived" : "Un Archived"
          },
          callback: (res, data) => {
            if(res){
              getCategoriesList()
              getLocationsData()
              loadTaxList()
              templateView(searchParams.get('id'))
            }
          }
        })
      }
    }
    const onChangeCountry = (value) =>{
      setcountry(value);

      form.setFieldsValue({currency:value})
    };

    const formats = [
      'size',
      'bold', 'italic',
      'list', 'bullet',
      'link'
    ];
    
    const modules = {
      toolbar: [
        ['bold', 'italic'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['link']
      ]
    };
    const render =()=>{
      
        const uploadButton = (
            <div>
               <FileImageOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          );
        return (<> 
        <div style={{marginLeft: "120px"}}>
        <Menus/>
        </div>
        
          <div style={{marginTop:'1%'}}>
           
          
        <div className="coach-full-conatiner">
            <Form form={form} 
            onFinishFailed={(e)=>console.log('onFinishFailed',e)} 
            onFinish={(e)=>onFinish(e)}>
            <div className="coach-info-conatiner-first">
            <div style={{display:'flex',flexDirection:'row',marginBottom:23,justifyContent:'space-between'}}>
            
            <Button onClick={()=>onChangeBack()} disabled={buttonLoading} className='form-btn-4-back'  style={{backgroundColor:'#a7a7a7'}}
             ><div style={{fontSize:'15px',fontWeight:'600'}}><ArrowLeftOutlined className='back-arrow-icon' /><span>Back</span></div></Button>
            <div style={{display: "flex", alignItems: "center"}}>
              <Button style={{marginRight: "20px", color: "#fff", backgroundColor: "#e85f25", borderRadius: "20px", height: "44px"}} onClick={() => archiveOrUnarchive()} >{_.get(TemplateViewDetails, 'status') === 1 ? "Archive" : "Make Active"}</Button>
             {(searchParams.get('id')==null || templateViewType=="edit")? <Button loading={buttonLoading} onClick={()=>form.submit()} 
              className='form-btn-4' style={{backgroundColor:'#e85f25'}}
             ><span><SaveFilled /> Save</span></Button>:<Button onClick={()=>settemplateViewType('edit')} className='form-btn-4' style={{backgroundColor:'#e85f25', display: _.get(TemplateViewDetails, 'status') === 0 ? 'none' : "block"}}
             ><EditFilled /> Edit</Button>}
             <Button onClick={()=>history(`/communiti/schedule?id=${searchParams.get('id')}`)} disabled={buttonLoading} style={{backgroundColor:'#787878',width:'52%', display: _.get(TemplateViewDetails, 'status') === 0 ? 'none' : "block"}} className='form-btn-4-schedule'
             ><ClockCircleFilled color='#a7a7a7' />Schedule</Button>
            </div>
                   
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'900',fontSize:'27px'}}>Template</span>
                   
                </div>
                  <Modal centered={true} maskClosable
                 onCancel={()=>setBackModalVisable(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={BackModalVisable}>
                  <div style={{    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',}}>
                  <span style={{    fontSize: '29px',fontWeight: '700'}}>You have unsaved changes to your Template</span>
                  <span style={{fontSize: '17px',alignItems: 'center',textAlign: 'center',width: '82%'}}>Do you wish to discard your changes?
                  </span>
                  </div>
                  <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
    <Button onClick={()=>setBackModalVisable(false)} className='form-btn-4 unsaved-btn' style={{backgroundColor:'#a7a7a7',marginRight:23,width:'23%'}}
             >Cancel</Button>
                  <Button onClick={()=>history('/template-list?menu=template')} className='form-btn-4 unsaved-btn' style={{backgroundColor:'#e85f25',width:'23% !important'}}
             >Discard Changes</Button></div>
                  </Modal>
               { searchParams.get('id')?
                  <Modal centered={true}
                 onCancel={()=>setModalVisable(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={ModalVisable}>
                  <div style={{    display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',}}>
                  <span style={{    fontSize: '29px',fontWeight: '700'}}>Your template has been updated</span>
                  <span style={{fontSize: '17px',alignItems: 'center',textAlign: 'center',width: '82%'}}>Your template has been updated and will reflect in new sessions that you schedule. Please note that sessions that have already been scheduled will not reflect these new changes.</span>
                  </div>
                  <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
                  <Button onClick={()=>setModalVisable(false)} className='form-btn-4' style={{backgroundColor:'#e85f25'}}
             >Continue</Button></div>
                  </Modal>:
                     <Modal centered={true}
                   onCancel={()=>setModalVisable(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={ModalVisable}>
                    <div style={{    display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',}}>
                    <CheckOutlined className='check-right'/>
                    <span style={{    fontSize: '29px',fontWeight: '700'}}>Your Templated Has Been Created & Saved</span>
                    </div>
                    <div style={{ display: 'flex',
      justifyContent: 'center',
      marginTop: '32px'}}>   
                    <Button onClick={()=>setModalVisable(false)} className='form-btn-4' style={{backgroundColor:'#e85f25'}}
               >Continue <ArrowRightOutlined /></Button></div>
                    </Modal>}
                {(searchParams.get('id')==null || templateViewType=="edit") ?<div style={{width: "100%", float: "left"}}> 
                <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
                    {/* <div className='open-session-title'> */}
<div className="title-style">
Template Type
</div>
<div onClick={() => showTemplateTypeModal()} className="title-style" style={{fontSize:"12px"}}>
<em>What is this?</em> {<InfoCircleOutlined style={{marginTop:"-4px"}} className='open-session-info' />}
</div>
{/* </div> */}
<Form.Item name='type'   rules={[
          {
            required: true,
            message: 'Please input your Template Type!',
          },
        ]}><Select
    optionFilterProp="children"
    onSelect={(e) => onSelectTemplateType(e)}
    >
   <Option key={"sheduled_type"} value={"scheduled"}>Scheduled Session</Option>
   <Option key={"open_session_type"} value={"open"}>Open Session</Option>
   <Option key={"league_session_type"} value={"league"}>League</Option>
   {/* <Option key={"league_type"} value={"league"}>League</Option> */}
  </Select></Form.Item>

                    </div>
                </div>
                </div>
</div>
          <div style={{ display:getSelectedTemplateType === 'open' || getSelectedTemplateType === 'scheduled' || getSelectedTemplateType === 'league' || _.get(TemplateViewDetails, 'type') === 'open' || _.get(TemplateViewDetails, 'type') == 'scheduled' || _.get(TemplateViewDetails, 'type') == 'league' ? "block" : "none"}}>
                <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
   Template Name
</div>
<Form.Item name='title'   rules={[
          {
            required: true,
            message: 'Please input your title!',
          },
        ]}><Input onChange={() => setChangesHappened(true)} /></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Categories
</div>
<Input defaultValue={_.startCase(_.get(getYourProfileDetails, 'categories[0].name'))} 
value={_.startCase(_.get(getYourProfileDetails, 'categories[0].name'))} readOnly={true}/>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Description
</div><Form.Item name='description'   rules={[
          {
            required: true,
            message: 'Please input your description!',
          },
        ]}>
          <ReactQuill 
          theme="snow" 
          value={value}
           onChange={setValue} 
           modules={modules}
           formats={formats}
           />
        </Form.Item>


                    </div>
                </div>
                </div>
</div>
{/* //Country */}
                    <div className="name-inner-conatiner-input1" style={{marginTop:'23px'}}>
    <div style={{padding:'8px 29px'}}>
  <div className="title-style">
Country
</div>
<Form.Item name='country'   rules={[
          {
            required: true,
            message: 'Please input your Country!',
          },
        ]}>
  <Select
  onChange={(value)=>{onChangeCountry(value)}}
  defaultValue={country}
  value={country}
  style={{width:'100%'}}
    optionFilterProp="children">
   {_.map(countryList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select>
  </Form.Item>
  </div>
  </div>
                    <div className="name-inner-conatiner-input1">
<div style={{padding:'8px 29px'}}>
<div className="title-style">
Address
</div>
<Form.Item
                                name="location"
                                rules={[{ required: true, message: 'Please input your location!' }]}
                            >
                              <PlacesAutocomplete
                              

        value={address}
        onChange={handleChange1}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input {...getInputProps({ placeholder: 'Search address' })}  />
            {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
            <div>
              {loading ? <div>Loading...</div> : null}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
                  marginTop:10,
                };
                
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                               
                            </Form.Item>

                            </div>
                    </div>
                   
                   
  <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
  <div className="title-style">
Currency
</div>
<Form.Item name='currency'   rules={[{
            required: true,
            message: 'Please input your Currency!',
          },
        ]}>
  <Select
  style={{width:'100%'}}
  value={country}
  disabled
    optionFilterProp="children">
   {_.map(currencyList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select></Form.Item>
  </div>
  </div>

  <div className="name-inner-conatiner-input1">
    <div style={{padding:'8px 29px'}}>
<div className="title-style">
Province / State</div>
<Form.Item name='tax_state'   rules={[
          {
            required: true,
            message: 'Please input your Province / State!',
          },
        ]}>
          <Select disabled={form.getFieldValue('currency')?false:true} getPopupContainer={trigger => trigger.parentNode}
                                    placeholder={'Search Province / State...'}
                                >
                                    { _.map(getFilterTax(TaxListData,form.getFieldValue('currency')),(d,i) => {
                                      
                                      return(
                                        <Select.Option  key={_.get(d,'id')}>{_.startCase(_.get(d,'state'))}</Select.Option>
                                      )
                                      })}
                                </Select>
        </Form.Item>

                    </div>
                    </div>
                   
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Postal / Zip Code</div>
<Form.Item name='zip_code'   rules={[
          {
            required: true,
            message: 'Please input your Postal / Zip Code!',
          },
        ]}><Input onChange={() => setChangesHappened(true)} /></Form.Item>

                    </div>
                </div>
                </div>

</div>

<div style={{ height: '55vh', width: '100%',borderRadius:'23px',marginTop:'23px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBIU6YefaUI89t5f7LwsvY0z21sLmLdpYc" }}
        defaultCenter={LocationData}
        defaultZoom={defaultProps.zoom}
        center={LocationData}
      >
        <AnyReactComponent
          lat={_.get(LocationData,'lat')}
          lng={_.get(LocationData,'lng')}
          
          text="My Marker"
        />
      </GoogleMapReact>
    </div>


    
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">

{getSelectedTemplateType === 'open' ?  `Maximum # of Concurrent Registrants`  : _.get(TemplateViewDetails, 'type') == 'open' && templateViewType == 'edit' ? `Maximum # of Concurrent Registrants`:'Maximum # of Allowed Registrants'}{getSelectedTemplateType === 'open' ? <InfoCircleOutlined className='open-session-info' onClick={showConcurrentModal}/>  : _.get(TemplateViewDetails, 'type') == 'open' && templateViewType == 'edit' ?<InfoCircleOutlined className='open-session-info' onClick={showConcurrentModal}/>:''}</div>
<Form.Item name='max_no_of_openings'   rules={[
          {
            required: true,
            message: 'Please input your Max no of openings!',
          },
        ]}><InputNumber /></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Price per Registrant</div>
<Form.Item name='host_price'   rules={[
          {
            required: true,
            message: 'Please input your price!',
          },
        ]}><InputNumber onChange={() => setChangesHappened(true)}  addonBefore={form.getFieldValue('currency')?`${form.getFieldValue('currency')} $`:'$'} 
        /></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px', display: getSelectedTemplateType == 'league' || _.get(TemplateViewDetails, 'type') == 'league' ? "block" : "none" }}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Team Size</div>
<Form.Item name='team_size'><InputNumber
 onChange={() => setChangesHappened(true)} defaultValue={_.get(TemplateViewDetails, 'meta_details.team_size')}
        /></Form.Item>

                    </div>
                </div>
                </div>
</div>

{/** */}

<div style={{marginTop:'23px'}}><span style={{fontWeight:'700',fontSize:'20px'}}>More about this session</span>
<div style={{marginTop:"10px"}}>
  <span>
  In this section you can provide users more information, and links to material you have about this session. For example, you may have rulebooks, parking information, policies or FAQs that you want to provide users information.
  </span>
</div>
                {moreAboutArray &&_.size(moreAboutArray)>0 &&<div > <div className="first-container-1" style={{marginTop:23}}>
<div className="second-container">

 {loadMoreAboutList(moreAboutArray)}</div> </div>{<div onClick={()=>setMoreAboutInputShow(true)} className='add-another-container'><PlusSquareFilled style={{fontSize:23,marginRight:7}}/><span>Add Another</span></div>}</div>}

 {(_.size(moreAboutArray)==0 || moreAboutInputShow) &&<div className='agreement-clauses-data'>
 <div className="first-container-1" style={{marginTop:23,padding: '1px 28px'}}>
                
               
               {(_.size(moreAboutArray)==0 || moreAboutInputShow) && <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Title
</div>
<div><Input placeholder='This is the heading the user will see, use something like, “FAQ” or “Session Rules”' 
value={moreAboutTitle} onChange={(e)=>{setMoreAboutTitle(_.get(e,'target.value')); setChangesHappened(true)}} /></div>

                    </div>
                </div>
                </div>
</div>}
{(_.size(moreAboutArray)==0 || moreAboutInputShow) &&<div className="first-container" style={{marginTop:'23px'}}>
 
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Description
</div>
<div>
  {/* <TextArea placeholder='Provide a description about the additional information you are providing. For example, you can list the different questions and answers you have in your FAQ. If you are providing a link below, explain what information the link is providing.' value={moreAboutDes} onChange={(e)=>{setMoreAboutDes(_.get(e,'target.value')); setChangesHappened(true)}} /> */}
  <ReactQuill 
  theme="snow" 
  value={moreAboutDes}
   onChange={(e)=>{setMoreAboutDes(e); setChangesHappened(true)}}
   modules={modules}
    formats={formats}
   />
  </div>

                    </div>
                </div>
                </div>

                {(_.size(moreAboutArray)==0 || moreAboutInputShow) && <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Link (Optional)
</div>
<div><Input placeholder='Provide a link that can bring the user to the information you want to provide. For example, “https://communiti.app/”'
value={moreAboutLink} onChange={(e) =>{setMoreAboutLink(_.get(e, 'target.value')); setChangesHappened(true)}} />
{/* {moreAboutUrl} */}
</div>

                    </div>
                </div>
                </div>
</div>}
      
                <div   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"end",display:'flex',marginTop:'2%',marginBottom:'17px'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button className='saveButtonCtn' icon={ <SaveFilled style={{marginRight:'-5px'}} />} style={{
              backgroundColor: "#e85f25",
    borderRadius: "22px",
    // width: "100px",
    height: "44px" ,
    color: "#fff" ,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
            }}

            //  onClick={()=>props.onChangeTab('3')}
             onClick={()=>setMoreAboutDetails()}
            //  htmlType="submit"
             > {_.isEmpty(moreAboutObject)?"Add":'Update'} </Button>
              {!_.isEmpty(moreAboutObject) &&<Button className='form-btn-4'
              style={{backgroundColor:'#ababab',marginLeft:15}}
            //  onClick={()=>props.onChangeTab('3')}
             onClick={()=>setMoreAboutDetails()}
            //  htmlType="submit"
             >Cancel</Button>}
            {/* </div> */}
            </div>
</div>
    }
                </div>  </div> }</div>    
{/** */}
<div style={{marginTop:'23px'}}><span style={{fontWeight:'700',fontSize:'20px'}}>Agreement Clauses</span>
                {clauseArray &&_.size(clauseArray)>0 &&<div > <div className="first-container-1" style={{marginTop:23}}>
<div className="second-container">

 {loadClausesList(clauseArray)}</div> </div>{<div onClick={()=>setClauseInputShow(true)} className='add-another-container'><PlusSquareFilled style={{fontSize:23,marginRight:7}}/><span>Add Another</span></div>}</div>}

 {(_.size(clauseArray)==0 || clauseInputShow) &&<div className='agreement-clauses-data'>
 <div className="first-container-1" style={{marginTop:23,padding: '1px 28px'}}>
                
               
               {(_.size(clauseArray)==0 || clauseInputShow) && <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Clause title
</div>
<div><Input value={clauseTitle} onChange={(e)=>{setclauseTitle(_.get(e,'target.value')); setChangesHappened(true)}} /></div>

                    </div>
                </div>
                </div>
</div>}
<div style={{marginTop:"20px"}}>
<Radio.Group  onChange={onChangeClauseOptions}>
<Radio.Button className='radio-scheduled-template' value="description">Provide Description</Radio.Button>
<Radio.Button className='radio-scheduled-template' value="link">Provide Link</Radio.Button>
</Radio.Group>
</div>
{(_.size(clauseArray)==0 || clauseInputShow) && <div className="first-container" style={{marginTop:'23px'}}>
  {getClauseOptions === 'link' ? 
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Clause Link
</div>
<div>
  <Input type='url' value={clauseLink} onChange={(e)=>{onChangeClauseUrl(e); setChangesHappened(true)}} />
  {isValidUrl}
</div>

                    </div>
                </div>
                </div>
                :''
      }
</div>}
{(_.size(clauseArray)==0 || clauseInputShow) &&<div className="first-container" style={{marginTop:'23px'}}>
  {getClauseOptions === 'description' ?
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Clause Description
</div>
<div>
  {/* <TextArea value={clauseDes} onChange={(e)=>{setclauseDes(_.get(e,'target.value')); setChangesHappened(true)}} /> */}
  <ReactQuill 
  theme="snow" 
  value={clauseDes}
   onChange={(e)=>{setclauseDes(e); setChangesHappened(true)}}
   modules={modules}
   formats={formats}
   />
  </div>

                    </div>
                </div>
                </div>
                :''
      }
                <div   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"end",display:'flex',marginTop:'2%',marginBottom:'17px'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button className='saveButtonCtn' icon={ <SaveFilled style={{marginRight:'-5px'}} />} style={{
              backgroundColor: "#e85f25",
    borderRadius: "22px",
    // width: "100px",
    height: "44px" ,
    color: "#fff" ,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
            }}

            //  onClick={()=>props.onChangeTab('3')}
             onClick={()=>setClauseDetails()}
            //  htmlType="submit"
             >{_.isEmpty(clauseObject)?"Add":'Update'} </Button>
              {!_.isEmpty(clauseObject) &&<Button className='form-btn-4'
              style={{backgroundColor:'#ababab',marginLeft:15}}
            //  onClick={()=>props.onChangeTab('3')}
             onClick={()=>setClauseDetails()}
            //  htmlType="submit"
             >Cancel</Button>}
            {/* </div> */}
            </div>
</div>
    }
                </div>  </div> }</div>              
               
                <div className="first-container-1" style={{marginTop:43}}>
                <span style={{fontWeight:'700',fontSize:'20px'}}>Pictures</span>
               
                <Form.Item name='photo' className='upload-template-image'  rules={[
          {
            required: false,
            message: 'Please input your photo!',
          },
        ]}> <Upload
        accept=".jpg,.jpeg"
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        fileList={fileList}
        onRemove={removeImage}
        onChange={onChangePhoto}
        customRequest={dummyRequest}
    >
        {photo_display ? <img src={photo_display} alt="avatar" style={{ width: '100%',height:'100%', borderRadius: '20px' }} /> : uploadButton}

    </Upload>
</Form.Item>
                  
                </div>
                </div>
                </div>:<ViewTemplate template={{details:TemplateViewDetails,photos:fileList,clasuse:clauseArray}} />}
            </div>
           
            </Form>
              <Modal centered={true} width="45%" className='appropriateTemplateModal'  footer={null} title="Selecting the Appropriate Template Type" open={templateTypeModal} onCancel={() => handleCancel()}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Radio.Group  defaultValue="a" size="large">
            <Radio.Button className='radio-scheduled-template' onClick={() => onClickScheduledRadio()} value="a">Scheduled</Radio.Button>
            <Radio.Button className='radio-scheduled-template' onClick={() => onClickOpenRadio()} value="b">Open</Radio.Button>
            <Radio.Button className='radio-scheduled-template' onClick={() => onClickLeagueRadio()} value="c">League</Radio.Button>
            </Radio.Group>
             </div>
             {
              getScheduledValue === true ? 
              <div style={{marginTop:"35px", display:"flex", alignItems:"center", justifyContent:"center"}}>
             <CalendarOutlined style={{fontSize: "40px"}} />
             </div> : getOpenValue === true ? <div style={{marginTop:"35px", display:"flex", alignItems:"center", justifyContent:"center"}}>
             <FolderOpenOutlined style={{fontSize: "40px"}} />
             </div> : getLeagueValue === true ? <div style={{marginTop:"35px", display:"flex", alignItems:"center", justifyContent:"center"}}>
             <BorderOutlined style={{fontSize: "40px"}} />
             </div> : <div style={{marginTop:"35px", display:"flex", alignItems:"center", justifyContent:"center"}}>
             <CalendarOutlined style={{fontSize: "40px"}} />
             </div>
             }
             
             {
                getScheduledValue === true ? 
                <div className='session-type-modal' style={{marginTop:"35px"}}>
             <p>Use a scheduled template when you have exact dates and times that users can book a session with you.</p>
             <p> This template type is best for programs, or when you have booked a space and know definitively that you will be available at that time.</p>
             </div> : getOpenValue === true ? <div className='session-type-modal' style={{marginTop:"35px"}}>
             <p>Use an open template when you want to leave a session schedule open ended For example, you offer private coaching lessons, but want to coordinate with the user to settle on a date and time. </p>
             <p>This template type is best for private lessons where you work with your client to find days and times that work best for the two of you.</p>
             </div> : getLeagueValue === true ? <div className='session-type-modal' style={{marginTop:"35px"}}>
             <p>Use a league template when you are setting up specific tournament style sessions. For example, you’ve booked a court, field or rink for a specific day and want to allow for people to sign up to play.</p>
             <p> League templates are best suited towards one-day tournaments, or pick-up style games for teams.</p>
             </div> : <div className='session-type-modal' style={{marginTop:"35px"}}>
             <p>Use a scheduled template when you have exact dates and times that users can book a session with you.</p>
             <p> This template type is best for programs, or when you have booked a space and know definitively that you will be available at that time.</p>
             </div>
             }
             
      </Modal>

      <div>
  <Modal centered={true} className='appropriateTemplateModal' width='40%' footer={null} title="What is maximum # of concurrent registrations?" open={isConcurrentModalOpen} onOk={concurrentOk} onCancel={concurrentCancel}>
        <p>The maximum number of current registrations is the number of oustanding registrations that you can have before users can no longer book this session.</p>
        <p>For example, if you set this number to 10, and you have 10 oustanding registrations, then users will no longer be able to book this session.</p>
        <p>If you complete one of the sessions, then that number reduces to 9 and a user will be able to book with you again.</p>
      </Modal>
</div>
<div>
{/* <Popconfirm
    title="Are you sure to delete this task?"
    onConfirm={onEditMoreAbout}
    // onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    <a href="#">Delete</a>
  </Popconfirm> */}
</div>

        </div>
        </div></>)
    }

    return render()
}
const mapStateToProps = state => {
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),
       guestUserProfile: _.get(state, 'app.guest.profile.data.details')
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
    list: details => dispatch(global.redux.action.categories.list(details)),
    hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
    createTemplate: details => dispatch(global.redux.action.template.create(details)),
    updateTemplate: details => dispatch(global.redux.action.template.update(details)),
    addPhotoTemplate: details => dispatch(global.redux.action.template.addPhoto(details)),
    taxList: details => dispatch(global.redux.action.tax.list(details)),
    getLocations: details => dispatch(global.redux.action.host.getLocation(details)),
    viewTemplate: details => dispatch(global.redux.action.template.view(details)),
    deletePhoto: details => dispatch(global.redux.action.template.deletePhoto(details)),
    archiveActiveTemplates: details => dispatch(global.redux.action.template.changeStatusTemplate(details)),


   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(TemplateTab)