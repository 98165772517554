import { Box, Card, CardContent, Container, Divider, Typography } from "@mui/material";
import { useState } from "react";
import RightSideHeader from "./right-side-header";
import RightFooter from "./right-footer";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import _ from 'lodash';



const AccountSuccess =(props)=>{
  let navigate = useNavigate()


  const redirectTemplate =()=>{
    props.hostProfileData({
      callback:(res,data)=>{
       if(_.get(data,'data.details')){
        navigate("/template-list?menu=template")
       }}})
  }
return<Box sx={{width:'100%',height:'100%'}} >
<RightSideHeader />
<Box component={'div'} sx={{width:"100%", display:'flex',justifyContent:'center',height:'80%',}}> 
     <Box sx={{width:{
            xs: "90%", // mobile
            sm: "80%", // tablet
            md: "70%", // small desktop
            lg: "65%",
     },height:'100%',display:'flex',justifyContent:'center',flexDirection:'column'}}>
<Box mt={5} textAlign="center">
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FDEAD7"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF6EE" stroke-width="8"/>
<path d="M25 28L27 30L31.5 25.5M25.2019 36.6009C25.5297 36.5575 25.8607 36.6464 26.1218 36.8475L27.3251 37.7708C27.7228 38.0764 28.2761 38.0764 28.6727 37.7708L29.9215 36.812C30.1548 36.6331 30.4492 36.5542 30.7403 36.5931L32.3024 36.7986C32.799 36.8642 33.2779 36.5875 33.4701 36.1242L34.0712 34.6709C34.1834 34.3987 34.3989 34.1832 34.6711 34.0709L36.1243 33.4698C36.5876 33.2787 36.8643 32.7988 36.7987 32.3021L36.601 30.7966C36.5576 30.4688 36.6465 30.1377 36.8476 29.8766L37.7709 28.6733C38.0764 28.2755 38.0764 27.7222 37.7709 27.3256L36.812 26.0767C36.6332 25.8434 36.5543 25.549 36.5932 25.2579L36.7987 23.6957C36.8643 23.199 36.5876 22.7201 36.1243 22.5279L34.6711 21.9268C34.3989 21.8146 34.1834 21.5991 34.0712 21.3269L33.4701 19.8736C33.279 19.4102 32.799 19.1336 32.3024 19.1991L30.7403 19.4047C30.4492 19.4447 30.1548 19.3658 29.9226 19.188L28.6738 18.2292C28.2761 17.9236 27.7228 17.9236 27.3262 18.2292L26.0774 19.188C25.8441 19.3658 25.5496 19.4447 25.2586 19.4069L23.6965 19.2014C23.1998 19.1358 22.721 19.4125 22.5288 19.8758L21.9288 21.3291C21.8155 21.6002 21.6 21.8157 21.3289 21.9291L19.8757 22.529C19.4124 22.7213 19.1357 23.2001 19.2013 23.6968L19.4068 25.259C19.4446 25.5501 19.3657 25.8445 19.188 26.0767L18.2291 27.3256C17.9236 27.7233 17.9236 28.2767 18.2291 28.6733L19.188 29.9222C19.3668 30.1555 19.4457 30.4499 19.4068 30.741L19.2013 32.3032C19.1357 32.7999 19.4124 33.2787 19.8757 33.471L21.3289 34.0721C21.6011 34.1843 21.8166 34.3998 21.9288 34.672L22.5299 36.1253C22.721 36.5887 23.201 36.8653 23.6976 36.7998L25.2019 36.6009Z" stroke="#EF6820" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>




</Box>
<Typography component={"div"} sx={{
color:"#101828",
marginTop:'32px',
fontSize: {
       xs: "17px", // mobile
       sm: "19px", // tablet
       md: "23px", // small desktop
       lg: "30px",
     },
     fontWeight: "600",
     lineHeight: {
        xs: "28px", // mobile
        sm: "30px", // tablet
        md: "33px", // small desktop
        lg: "38px",
      },
}} align="center" >
You’re all set
</Typography>
<Typography component={"div"} align="center"
sx={{
marginTop:'12px',
color:"#475467",
fontSize: {
       xs: "14px", // mobile
       sm: "15px", // tablet
       md: "16px", // small desktop
       lg: "16px",
     },
     
}}
>
Your account was successfully verified. You’re ready to set up sessions and receive payments
</Typography>


<Box component={"div"} sx={{
  marginTop:"32px",
  padding:"10px 18px", 
  // height:'44px',
  borderRadius: "8px",
  border:"1px solid var(--Orange-500, #EF6820)",
  background: "var(--Orange-500, #EF6820)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  cursor:"pointer"
}}
onClick={()=>{
  redirectTemplate()
}}
>
<Typography component={"div"} align="center"
sx={{

color:"var(--Base-White, #FFF)",
fontSize: {
       xs: "14px", // mobile
       sm: "15px", // tablet
       md: "16px", // small desktop
       lg: "16px",
     },
     fontWeight:"600"
     
}}
>
Continue</Typography>


</Box>
</Box>
</Box>
<RightFooter />
</Box>

}


const mapStateToProps = state => {
      
  return {
     checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
     

  }
}

const mapDispatchToProps = dispatch => ({
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),



});
export default connect(mapStateToProps, mapDispatchToProps)(AccountSuccess)
