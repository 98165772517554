import React from 'react'
import { BrowserRouter as Switch, Route, router } from 'react-router-dom';
import ApplicationSuccess from '../../containers/application-success';
import CoachInfoApplication from '../../containers/coach-information-application';
import LoginPageComponent from '../../containers/login/login';
import CoachOnboarding from '../../containers/onboarding-screen';
import Profile from '../../containers/profile';
import TermsService from '../../containers/terms-service';
class LayoutContent extends React.Component {
	render() {
		return (
			<div style={{ width: '100%', float: 'left' }}>
				<Route exact path="/" component={LoginPageComponent} />
				<Route exact path="/terms-services" component={TermsService} />
				<Route exact path="/application-info" component={CoachInfoApplication} />
				<Route exact path="/application-success" component={ApplicationSuccess} />
				<Route exact path="/coach-onboard" component={CoachOnboarding} />
				<Route exact path="/profile" component={Profile} />
				
			</div>
		);
	}
}

export default LayoutContent;