import { InfoCircleFilled, InfoCircleOutlined, UpOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React, { useEffect, useState } from 'react'
import {Button, Collapse, Form, Select,notification, Tooltip} from 'antd'
import _ from 'lodash';
import { useNavigate } from "react-router-dom";

import './style.css'
import { connect } from 'react-redux'

const { Panel } = Collapse;
const { Option } = Select;

const CoachInfoApplication =(props)=>{
  let history = useNavigate();
const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
]
const currencyList =[{name:'CAD',value:'CAD'},
{name:'USD',value:'USD'}
]
  const [form] = Form.useForm();
  const [confirmCoachClub,setConfirmCoachClub] =useState('1')
  const [taxToggle,setTaxToggle] =useState('1')
  const [categoriesList,setCategoriesList] =useState([])
  const [inviteCode,setInviteCode] =useState(false)
  const [buttonLoading,setButtonLoading] =useState(false)
  const [inviteCodeResponse,setInviteCodeResponse] =useState(null)
  const [inviteCodeErrors , setInviteCodeErrors] = useState({message:'',success:false,loading:false})
  const [inviteCodeValue,setInviteCodeValue] =useState('')
  const [country, setcountry] = useState(null)
  const [TaxListData, setTaxListData] = useState([])
  useEffect(()=>{
    let conditionType = _.includes(_.get(props,'islogindata.types'),1)==true?'hostProfileData':'guestProfileData';
    loadTaxList()
    getCategoriesList()
    props[conditionType]({
 callback:(res,data)=>{
  if(_.get(data,'data.details')){
    form.setFieldsValue({
      first_name:_.startCase(_.get(data,'data.details.first_name')),
      last_name: _.startCase(_.get(data,'data.details.last_name')),
      email:_.get(data,'data.details.email'),
      phone:_.get(data,'data.details.contact_details[0].phone')
    })
    setConfirmCoachClub(_.get(data,'data.details.business_type')=='coach'?'2':'1')
  }
 } 
})
  },[])
  const loadTaxList =()=>{
    props.taxList({
      params:{limit:100},
      callback:(res,data)=>{
        
    if(res==true && _.get(data,'data.items[0]'))
        setTaxListData(_.get(data,'data.items'))
      }
    })
        }
  const getCategoriesList  =()=>{
    props.list({
      callback:(res,data)=>{
        if(_.get(data,'data.items')){
          setCategoriesList(_.get(data,'data.items'))
        }
      }
    })
  }

  const formatPhoneNumber=(phoneNumberString)=> {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      // return   match[1] + '-' + match[2] + '-' + match[3];
      form.setFieldsValue({
        phone:match[1] + '-' + match[2] + '-' + match[3]
      })
    }
    return null;
  }

  const getFilterTax = (list,currency)=>{
let country = currency=='CAD' ? 'CA' :'US'
    let a = _.filter(list,(v,i)=>_.get(v,'country')==country)
return a
  }

  const onFinish =(e)=>{
    
    // setButtonLoading(true)
    // props.updatePhone({
    //   body:{phone:_.get(e,'phone')},
    //   callback:(res,data)=>{
    //     if(res==true){
let details ={...e}
if(_.get(inviteCodeErrors,'success')==false){
  delete details.invite_code
}
details.categories=[_.get(e,'categories')]
details.is_taxes_collected = taxToggle==1?true:false;
details.business_type=confirmCoachClub==1?"club":confirmCoachClub==2?'coach':'coordinator';
details.contact_details=JSON.stringify([{'phone':_.get(e,'phone')}])
// details.tax_state=_.get(e,'tax_state')
details.country=_.get(e,'country')=="USD"?'US':'CA'
details.contact_details = {email: e.email, phone: e.phone}
// details.meta_details=JSON.stringify({currency:_.get(e,'currency')})



// request_details.contact_details=JSON.stringify([{'phone':_.get(e,'phone')}])

        props.hostCreate({
          body:{...details},
          callback:(res,data)=>{
            
            if(res==true){
              history('/communiti/coach-information-success')
            }
          setButtonLoading(false)  
            
          }
        })
    //   }else{
    //     setButtonLoading(false)
    //   }
    // }
    // })
    

  }

  const verifyCode = (code)=>{
    setInviteCodeErrors({
      message:'',
      success:false,
      loading:true
    })
    if(!_.isEmpty(code)){
      props.verifyInviteCode({
        body:{code:code},
        callback:(res,data)=>{
          if(res === true && _.get(data,'is_valid')==false){
            setInviteCodeResponse(false)
          }
          
  if(res==true){
    if(_.get(data,'is_valid')==false){
      setInviteCodeErrors({
        message:'Your invite code is invalid and has not been added to your application',
        success:false,
        loading:false
      })
     
    }else if(_.get(data,'is_valid')==true){
      setInviteCode(true)
      setInviteCodeErrors({
        message:'Your invite code has been added to your application',
        success:true,
        loading:false
      })
  }
  }
        }
      })
    }

    if(_.isEmpty(code)){
      setInviteCodeResponse(true)
    }
    
  }
  const verifyCodeDelayedQuery = _.debounce(q => verifyCode(q), 1200);

    const render =()=>{
        return (
        <div className="coach-full-conatiner">
          <Form form={form} onFinish={(e)=>onFinish(e)}>
            <div className="coach-info-conatiner-first">
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'900',fontSize:'27px'}}>Application</span>
                    <span style={{fontWeight:'700',fontSize:'20px'}}>Profile Information</span>
                </div>
                <Collapse style={{marginTop:43}} expandIconPosition="right" collapsible="header" defaultActiveKey={['1']}>
      <Panel  header={<div className="name-inner-conatiner-1">
    <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">1</span></div>
    <span style={{fontWeight:550,marginLeft:'7px'}}>Confirm Your First Name </span>
</div>} key="1">
<div className="name-inner-conatiner-input">
<div className="title-style">
   First Name
</div>
<Form.Item name='first_name'   rules={[
          {
            required: true,
            message: 'Please enter your First Name',
          },
        ]}><Input /></Form.Item>

                    </div>
      </Panel>
    </Collapse>
    <Collapse style={{marginTop:43}} expandIconPosition="right" collapsible="header" defaultActiveKey={['1']}>
      <Panel  header={<div className="name-inner-conatiner-1">
    <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">2</span></div>
    <span style={{fontWeight:550,marginLeft:'7px'}}>Confirm Your Last Name </span>
</div>} key="1">
<div className="name-inner-conatiner-input">
<div className="title-style">
   Last Name
</div>
<Form.Item name='last_name'   rules={[
          {
            required: true,
            message: 'Please enter your Last Name',
          },
        ]}><Input /></Form.Item>

                    </div>
      </Panel>
    </Collapse>
    <Collapse style={{marginTop:23}} expandIconPosition="right" collapsible="header" defaultActiveKey={['1']}>
      <Panel  header={<div className="name-inner-conatiner-1">
    <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">3</span></div>
    <span style={{fontWeight:550,marginLeft:'7px'}}>Confirm Email </span>
</div>} key="1">
<div className="name-inner-conatiner-input">
<div className="title-style">
   Email
</div>
<Form.Item name='email'   rules={[
          {
            required: true,
            message: 'Please enter your Email Address',
          },
          {
            type:'email',
            message: 'Please enter a valid Email Address',

          }
        ]}><Input inputMode='email' /></Form.Item>

                    </div>
      </Panel>
    </Collapse>
    <Collapse style={{marginTop:23}} expandIconPosition="right" collapsible="header" defaultActiveKey={['1']}>
      <Panel  header={<div className="name-inner-conatiner-1">
    <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">4</span></div>
    <span style={{fontWeight:550,marginLeft:'7px'}}>Confirm Phone Number </span>
</div>} key="1">
<div className="name-inner-conatiner-input">
<div className="title-style">
   Phone Number
</div>
<Form.Item name='phone'   rules={[
          {
            required: true,
            message: 'Please enter your phone number',
          },
        ]}><Input onChange={(e)=>formatPhoneNumber(_.get(e,'target.value'))} /></Form.Item>

                    </div>
      </Panel>
    </Collapse>

    <Collapse style={{marginTop:23}} expandIconPosition="right" collapsible="header" defaultActiveKey={['1']}>
      <Panel  header={<div className="name-inner-conatiner-1">
    <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">5</span></div>
    <span style={{fontWeight:550,marginLeft:'7px'}}>Tax Details <Tooltip placement="right" title={'Selecting yes will automatically charge and collect taxes on your behalf'}>
    <InfoCircleOutlined className='info-tax-icon' color='#e85f25' style={{color:'#e85f25',marginLeft:'1px'}} />
      </Tooltip></span>
</div>} key="1">
<div className="name-inner-conatiner-input">
  <div style={{width:'100%',marginBottom:20}}>
  <div className="title-style">
Country
</div>
<Form.Item name='country'   rules={[
          {
            required: true,
            message: 'Please input your Country!',
          },
        ]}>
  <Select
  onSelect={(value)=>{setcountry(value);
    form.setFieldsValue({currency:value})}}
  style={{width:'100%'}}
    optionFilterProp="children">
   {_.map(countryList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select>
  </Form.Item>
  </div>
  {/* <div style={{width:'100%',marginBottom:20}}>
  <div className="title-style">
Currency
</div>
<Form.Item name='currency'   rules={[
          {
            required: true,
            message: 'Please input your Currency!',
          },
        ]}>
  <Select
  style={{width:'100%'}}
  value={country}
  disabled
    optionFilterProp="children">
   {_.map(currencyList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select></Form.Item>
  </div> */}
  {/* <div className="name-inner-conatiner-input">
<div className="title-style">
Province</div>
<Form.Item name='tax_state'   rules={[
          {
            required: true,
            message: 'Please input your Province!',
          },
        ]}>
          <Select disabled={country?false:true} getPopupContainer={trigger => trigger.parentNode}
                                    placeholder={'Search Province...'}
                                >
                                    { _.map(getFilterTax(TaxListData,country),(d,i) => (
                                        <Select.Option  key={_.get(d,'id')}>{_.startCase(_.get(d,'state'))}</Select.Option>
                                    ))}
                                </Select>
        </Form.Item>

                    </div> */}
<div className="title-style">
Do you wish to charge and collect taxes
</div>
<div className="btn-container" style={{cursor:'pointer',justifyContent:'left',backgroundColor:'#fff'}}> <div
 style={{backgroundColor:taxToggle==1?"#e85f24":"#a7a7a7"}}
 onClick={()=>setTaxToggle('1')}
className="btn-inner-container">
                <div style={{    padding:"10px 69px",color: "#fff"}}>Yes</div></div>
           <div  
 onClick={()=>setTaxToggle('2')} className="btn-inner-container"
            style={{backgroundColor:taxToggle==2?"#e85f24":"#a7a7a7",marginLeft:34,cursor:'pointer'}}> 
            <div style={{    padding:"10px 69px",color: "#fff"}}>No</div></div>
            </div>

                    </div>
      </Panel>
    </Collapse>
    
                
            </div>

            <div className="coach-info-conatiner-first" style={{marginTop:56}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                   <span style={{fontWeight:'700',fontSize:'20px'}}>Club or Coach Information</span>
                </div>
                <Collapse style={{marginTop:33}} expandIconPosition="right" collapsible="header" defaultActiveKey={['1']}>
      <Panel  header={<div className="name-inner-conatiner-1">
    <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">6</span></div>
    <span style={{fontWeight:550,marginLeft:'7px'}}>Confirm if you are a Club or Coach</span>
</div>} key="1">
<div className="name-inner-conatiner-input">

<div className="btn-container" style={{cursor:'pointer',justifyContent:'left',backgroundColor:'#fff'}}> <div
 style={{backgroundColor:confirmCoachClub==1?"#e85f24":"#a7a7a7"}}
 onClick={()=>setConfirmCoachClub('1')}
className="btn-inner-container">
                <div style={{    padding:"10px 69px",color: "#fff"}}>Club</div></div>
           <div  
 onClick={()=>setConfirmCoachClub('2')} className="btn-inner-container"
            style={{backgroundColor:confirmCoachClub==2?"#e85f24":"#a7a7a7",marginLeft:34,cursor:'pointer'}}> 
            <div style={{    padding:"10px 69px",color: "#fff"}}>Coach</div></div>
            {/* <div  
 onClick={()=>setConfirmCoachClub('3')} className="btn-inner-container"
            style={{backgroundColor:confirmCoachClub==3?"#e85f24":"#a7a7a7",marginLeft:34,cursor:'pointer'}}> 
            <div style={{    padding:"10px 69px",color: "#fff"}}>Coordinator</div></div> */}
            </div>
            <div style={{marginTop:'3%'}}>
<div className="title-style">
Club/Coach Name
</div>
<Form.Item name='display_name'   rules={[
          {
            required: true,
            message: 'Please input your Club/Coach Name',
          },
        ]}><Input /></Form.Item>

                    </div>
                    </div>
      </Panel>
    </Collapse>
    <Collapse style={{marginTop:23}} expandIconPosition="right" collapsible="header" defaultActiveKey={['1']}>
      <Panel  header={<div className="name-inner-conatiner-1">
    <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">7</span></div>
    <span style={{fontWeight:550,marginLeft:'7px'}}>Confirm the sport you are listing on Communiti </span>
</div>} key="1">
<div className="name-inner-conatiner-input">
{/* <div className="title-style">
   Email
</div> */}
<Form.Item name='categories'   rules={[
          {
            required: true,
            message: 'Please input your Categories',
          },
        ]}><Select
    optionFilterProp="children">
   {_.map(categoriesList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'id')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select></Form.Item>

                    </div>
      </Panel>
    </Collapse>
    {/* <Collapse style={{marginTop:23}} expandIconPosition="right" collapsible="header" defaultActiveKey={['1']}>
      <Panel  header={<div className="name-inner-conatiner-1">
    <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">6</span></div>
    <span style={{fontWeight:550,marginLeft:'7px'}}>Invite Code (Optional) </span>
</div>} key="1">
<div className="name-inner-conatiner-input">
<div className="title-style">
   Code
</div>
<Form.Item name='invite_code'   rules={[
          {
            required: false,
            message: 'Please input your Invite Code',
          },
        ]}><Input onChange={(e)=>verifyCodeDelayedQuery(_.get(e,'target.value'))} /></Form.Item>

                    </div>
      </Panel>
    </Collapse> */}
    
                
            </div>


            {/* Invite COde */}
            <div className="coach-info-conatiner-first" style={{marginTop:56}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                   <span style={{fontWeight:'700',fontSize:'20px'}}>Invite Code</span>
                </div>
               
   
    <Collapse style={{marginTop:23}} expandIconPosition="right" collapsible="header" defaultActiveKey={['1']}>
      <Panel  header={<div className="name-inner-conatiner-1">
    <div className="name-inner-conatiner-2"><span style={{color:'#fff'}} className="number-text">8</span></div>
    <span style={{fontWeight:550,marginLeft:'7px'}}>Invite Code <Tooltip style={{width:'160px'}} openClassName='invite-code-icon' className='invite-code-icon'  placement="right" 
    title={'If you received an invite code, enter it here. Invite codes automatically approve your application. If you do not have an invite code, then leave this field blank. Your application will be reviewed within 24 hours'}>
    <InfoCircleOutlined className='info-tax-icon' color='#e85f25' style={{color:'#e85f25',marginLeft:'1px'}} />
      </Tooltip> </span>
</div>} key="1">
<div className="name-inner-conatiner-input">
<div className="title-style">
   Code
</div>
<Form.Item name='invite_code'   rules={[
          {
            required: false,
            message: 'Please input your Invite Code',
          },
        ]}><Input 
        onChange={(e)=>{
          if(_.get(e,'target.value')){
             setInviteCodeValue(_.get(e,'target.value'))
          }else{
            setInviteCodeValue('')
            setInviteCodeErrors({...inviteCodeErrors, message:''})
          }
        }} 
        /></Form.Item>
        {inviteCodeValue &&_.get(inviteCodeErrors,'message') && <span style={{color:_.get(inviteCodeErrors,'success')==false?'#ff4d4f':'#28a745',
      marginTop:'-17px',
      marginBottom:'11px'
      }}>
          {_.get(inviteCodeErrors,'message')}</span>
        
      }
        <Button loading={_.get(inviteCodeErrors,'loading')} className='verify-invite-code-btn'
        onClick={()=>form.getFieldValue('invite_code') && verifyCode(form.getFieldValue('invite_code') ) }
        >Add Invite Code to Application</Button>

                    </div>
      </Panel>
    </Collapse>
    
                
            </div>
            
                
           <Form.Item  className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"end",display:'flex',marginTop:'5%'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button loading={buttonLoading}   className='form-btn-4' htmlType="submit">Apply</Button>
            {/* </div> */}
            </Form.Item>
            {/* <div style={{    padding:"10px 69px",color: "#fff"}}>Apply</div></div> */}
            
            </Form>  
        </div>)
    }

    return render()
}
const mapStateToProps = state => {
  return {
     checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
     isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
     islogindata: _.get(state, 'app.account.isLogin'),
     userProfile: _.get(state, 'app.host.profile.data.details'),
     guestUserProfile: _.get(state, 'app.guest.profile.data.details')

  }
}

const mapDispatchToProps = dispatch => ({
  isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
  list: details => dispatch(global.redux.action.categories.list(details)),
  hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
  guestUpdateProfile: details => dispatch(global.redux.action.guest.update(details)),
  updatePhone: details => dispatch(global.redux.action.guest.updatePhone(details)),
  request: details => dispatch(global.redux.action.host.request(details)),
  hostCreate: details => dispatch(global.redux.action.host.hostCreate(details)),
  verifyInviteCode: details => dispatch(global.redux.action.host.verifyInviteCode(details)),
  taxList: details => dispatch(global.redux.action.tax.list(details)),


 
});
export default connect(mapStateToProps, mapDispatchToProps)(CoachInfoApplication)