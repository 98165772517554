import { useState } from "react";
import _ from "lodash";

import "react-slideshow-image/dist/styles.css";
import { Box, Typography } from "@mui/material";
import { StarFilled } from "@ant-design/icons";
import "../style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { connect } from "react-redux";
import { useNavigate } from "react-router";
var Carousel = require("react-responsive-carousel").Carousel;

const LeftContainer = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  let history = useNavigate();
  const clubDetails = [
    {
      description:
        "Communiti has really taken a lot of the individual contact work off of my plate and centralized it for easy management",
      profile_image: require("../../../assets/img/club/club1.png"),
      name: "Dana Cooke",
      club_name: "Founder, DoSomethingVolleyball",
    },
    {
      description:
        "Communiti has streamlined our processes and has eliminated over 20 hours of manual registration information input for every registration cycle",
      profile_image: require("../../../assets/img/club/club2.png"),
      name: "Kaitlyn Ferreri",
      club_name: "Instructor, Lakefield Skating Club",
    },
    {
      description:
        "Communiti has been a life changer. Easy to manage athletes, message them and has significantly cut time going into registrations",
      profile_image: require("../../../assets/img/club/club3.png"),
      name: "Lisa Conley",
      club_name: "Head of Skating Programs, Trenton Figure Skating Club",
    },
  ];
  function Item(props) {
    
    return (
      <Box
        key={props?.key}
        sx={{
          width: "100%",
            display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="club-details-container"
      >
       
        <Typography
          sx={{
            textAlign:'center',
            width:props?.index_key==0? "60%":"65%",
            fontSize: {
              xs: "14px", // mobile
              sm: "16px", // tablet
              md: "23px", // small desktop
              lg: "30px",
            },
            // width:'98%',
            color: "var(--Gray-900, #101828)",
            fontWeight: "500",
            lineHeight: {
              xs: "28px", // mobile
              sm: "30px", // tablet
              md: "33px", // small desktop
              lg: "38px",
            }
          }}
          className="slider-club-description"
        >
          {props?.item?.description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "32px",
            marginBottom: "32px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="span"
            sx={{
              width: "64px",
              height: "64px",
            }}
          >
            <Box
              component="img"
              sx={{
                width: "64px",
                height: "64px",
              }}
              src={props?.item?.profile_image}
              alt="sliders"
            />
          </Box>
          <Typography
            className="slider-club-name"
            sx={{
              marginTop: "16px",
            }}
          >
            {props?.item?.name}
          </Typography>
          <Typography
            className="slider-club-sub-text"
            sx={{
              marginTop: "4px",
            }}
          >
            {props?.item?.club_name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: {
              xs: "16px", // mobile
              sm: "24px", // tablet
              md: "28px", // small desktop
              lg: "32px", // large desktop
            },
          }}
        >
          {_.map(_.times(5), (v, i) => (
            <Box key={i}>
              <StarFilled
                size={20}
                color="#FEC84B"
                style={{ height: "20px", width: "20px", color: "#FEC84B" }}
              />
            </Box>
          ))}{" "}
        </Box>
      </Box>
    );
  }
  
  const indicators = (index, data) => {
    return (
      <div key={index} className="dot-indicator" style={{ marginRight: "6px" }}>
        <span
          key={index}
          className={
            index === currentSlide ? "dot-another active" : "dot-another"
          }
          onClick={() => setCurrentSlide(index)}
        />
      </div>
    );
  };
  const logout = () => {
    props.logout({
        callback: (response, data) => {
            // googleLogout();
            history('/communiti/login')
            window.location.reload(false);
        }
    })
}
  return (
    <Box
      width={"100%"}
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        display: "flex",
        flexDirection: "column",
       
      }}

      // height="calc(100vh - 25px)"
    >
      <Box sx={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        paddingTop: {
          xs: "16px", // mobile
          sm: "22px", // tablet
          md: "28px", // small desktop
          lg: "32px", // large desktop
        },
        paddingLeft: {
          xs: "16px", // mobile
          sm: "32px", // tablet
          md: "64px", // small desktop
          lg: "80px", // large desktop
        },
        paddingRight: {
          xs: "16px", // mobile
          sm: "32px", // tablet
          md: "64px", // small desktop
          lg: "80px", // large desktop
        },
        width:'100%'
      }}>
        <Box component={"img"}  sx={{
                width: "142px",
                height: "32px",
                display:'flex',
        alignItems:'flex-start',
        justifyContent:'flex-start'
              }}
              src={require('../../../assets/img/club/header-logo1.png')}
              alt="sliders" />
              <Box sx={{cursor:"pointer"}} component={"div"} onClick={()=>logout()}>
                <Typography sx={{color:"#EF6820",fontSize:"16px",fontWeight:"600"}}>Sign out</Typography>
              </Box>
      </Box>

      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection:'column',
          paddingLeft: {
            xs: "16px", // mobile
            sm: "32px", // tablet
            md: "64px", // small desktop
            lg: "80px", // large desktop
          },
          paddingRight: {
            xs: "16px", // mobile
            sm: "32px", // tablet
            md: "64px", // small desktop
            lg: "80px", // large desktop
          },
        }}
        
      >
        <Carousel
          selectedItem={currentSlide}
          className="slider-container-left-side"
          renderIndicator={indicators}
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          autoPlay={true}
          infiniteLoop={true}
          onChange={(e)=>setCurrentSlide(e)}
          // interval={2000}
        >
          {clubDetails.map((item, index) => (
            <>
              <Item key={index} index_key={index} item={item} />{" "}
            </>
          ))}
        </Carousel>
        <Box>
        <Box className="dot-indicator" sx={{ marginRight: "6px" }}>
          <Box
            onClick={() =>
              currentSlide != 0 && setCurrentSlide(currentSlide - 1)
            }
            sx={{
              cursor: "pointer",
              marginRight: {
                xs: "34px", // mobile
                sm: "44px", // tablet
                md: "54px", // small desktop
                lg: "64px", // large desktop
              },
            }}
            component={"span"}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Box>
          {_.map(clubDetails, (value, dotIndex) => {
            return (
              <Box
                component={"span"}
                key={dotIndex}
                style={{ marginRight: "6px" }}
                className={
                  currentSlide === dotIndex
                    ? "dot-another active"
                    : "dot-another"
                }
                onClick={() => setCurrentSlide(dotIndex)}
              />
            );
          })}
          <Box
            onClick={() =>
              currentSlide + 1 != _.size(clubDetails) &&
              setCurrentSlide(currentSlide + 1)
            }
            sx={{
              cursor: "pointer",
              marginLeft: {
                xs: "34px", // mobile
                sm: "44px", // tablet
                md: "54px", // small desktop
                lg: "64px", // large desktop
              },
            }}
            component={"span"}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 15L12.5 10L7.5 5"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Box>
        </Box>
      </Box>
      </Box>

      <Box 
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection:{
          sx:"column",
          sm: "column", // tablet
          md: "row", // small desktop
          lg: "row", // large desktop
        },
        paddingLeft: {
          xs: "16px", // mobile
          sm: "22px", // tablet
          md: "28px", // small desktop
          lg: "32px", // large desktop
        },
        paddingRight: {
          xs: "16px", // mobile
          sm: "22px", // tablet
          md: "28px", // small desktop
          lg: "32px", // large desktop
        },
      
      }}

      >
      <Typography
          sx={{color:"#475467",fontSize:'14px',fontWeight:'400'}}
          >
            © 2024 Communiti Inc.
          </Typography>
          <Box sx={{
            display:'flex',
            alignItems:'center'
          }}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.33334 4.66699L6.77662 8.47729C7.2174 8.78583 7.43779 8.94011 7.67752 8.99986C7.88927 9.05265 8.11075 9.05265 8.3225 8.99986C8.56223 8.94011 8.78262 8.78583 9.2234 8.47729L14.6667 4.66699M4.53334 13.3337H11.4667C12.5868 13.3337 13.1468 13.3337 13.5747 13.1157C13.951 12.9239 14.2569 12.618 14.4487 12.2416C14.6667 11.8138 14.6667 11.2538 14.6667 10.1337V5.86699C14.6667 4.74689 14.6667 4.18683 14.4487 3.75901C14.2569 3.38269 13.951 3.07673 13.5747 2.88498C13.1468 2.66699 12.5868 2.66699 11.4667 2.66699H4.53334C3.41324 2.66699 2.85319 2.66699 2.42536 2.88498C2.04904 3.07673 1.74308 3.38269 1.55133 3.75901C1.33334 4.18683 1.33334 4.74689 1.33334 5.86699V10.1337C1.33334 11.2538 1.33334 11.8138 1.55133 12.2416C1.74308 12.618 2.04904 12.9239 2.42536 13.1157C2.85319 13.3337 3.41324 13.3337 4.53334 13.3337Z" stroke="#667085" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            

          <Typography
          sx={{marginLeft:'8px',color:"#475467",fontSize:'14px',fontWeight:'400'}}
            >
           support@communiti.app
          </Typography>
          </Box>
      </Box>
     
    </Box>
  );
};

const mapStateToProps = state => {
  return {
       
   }
}

const mapDispatchToProps = dispatch => ({
  
   logout: details => dispatch(global.redux.action.account.logout(details)),
  

});

export default connect(mapStateToProps, mapDispatchToProps)(LeftContainer)

