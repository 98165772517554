import React, {useEffect, useState} from 'react';
import { Button, Table } from 'antd';
// import * as Sentry from '@sentry/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import '../../../onboarding-screen/style.css';

const LeaderBoard = (props) => {

  const [running, setRunning] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [leaderBoardListData, setLeaderBoardListData] = useState([])
  const [responseData, setResponseData] = useState(true)

    const dataSource = [
       {
        key: '1',
        rank: "1",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>TT</p> <p>Taylor Theobalds</p></div>,
        last_weeks_results: "4-2",
        total_games_played: "30",
        total_games_won: "20",
        win_percentage: "67%"
       },
       {
        key: '2',
        rank: "2",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>NP</p> <p>Nick Poon</p></div>,
        last_weeks_results: "3-3",
        total_games_played: "30",
        total_games_won: "19",
        win_percentage: "63%"
       },
       {
        key: '3',
        rank: "3",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>LB</p> <p>Lesley Baker</p></div>,
        last_weeks_results: "6-0",
        total_games_played: "30",
        total_games_won: "18",
        win_percentage: "60%"
       },
       {
        key: '4',
        rank: "4",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>DR</p> <p>Derwin Remedios</p></div>,
        last_weeks_results: "5-1",
        total_games_played: "30",
        total_games_won: "17",
        win_percentage: "57%"
       },
       {
        key: '5',
        rank: "5",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>JT</p> <p>Josef Tardif</p></div>,
        last_weeks_results: "3-3",
        total_games_played: "30",
        total_games_won: "17",
        win_percentage: "53%"
       },
       {
        key: '6',
        rank: "6",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>CH</p> <p>Colin Harriot</p></div>,
        last_weeks_results: "4-2",
        total_games_played: "30",
        total_games_won: "16",
        win_percentage: "53%"
       },
       {
        key: '7',
        rank: "6",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>JM</p> <p>James Manesh</p></div>,
        last_weeks_results: "4-2",
        total_games_played: "30",
        total_games_won: "16",
        win_percentage: "53%"
       },
       {
        key: '8',
        rank: "8",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>PH</p> <p>Perry Hanssen</p></div>,
        last_weeks_results: "4-2",
        total_games_played: "30",
        total_games_won: "15",
        win_percentage: "50%"
       },
       {
        key: '9',
        rank: "8",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>PL</p> <p>Paul Ligon</p></div>,
        last_weeks_results: "3-3",
        total_games_played: "30",
        total_games_won: "15",
        win_percentage: "50%"
       },
       {
        key: '10',
        rank: "8",
        name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>PC</p> <p>Pamela Clark</p></div>,
        last_weeks_results: "2-4",
        total_games_played: "30",
        total_games_won: "15",
        win_percentage: "50%"
       },
      ];

      const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',  
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: "30%"
        },
        // {
        //   title: "Last Week's Results",
        //   dataIndex: 'last_weeks_results',
        //   key: 'last_weeks_results',
        // },
        {
          title: 'Total Games Played',
          dataIndex: 'total_games_played',
          key: 'total_games_played',
        },
        {
            title: 'Total Games Won',
            dataIndex: 'total_games_won',
            key: 'total_games_won',
          },
          {
            title: 'Win %',
            dataIndex: 'win_percentage',
            key: 'win_percentage',
          },
      ];

      useEffect(() => {
        getLeaderBoardList();
      }, [])

      const getLeaderBoardList = () => {
        props.leaderBoardListAPI({
          params:{
            id: searchParams.get('id'),
            expand: 'guest,profile',
            limit: 200
          },
          callback: async(res, value) => {
            
            if(res){
              if(_.size(_.get(value, 'data.items')) > 0){
                setResponseData(false)
              } else if(_.isEmpty(_.get(value, 'data.items'))){
                setResponseData(false)
              }
              await setLeaderBoardTableData(_.get(value, 'data.items'))
            }
          }
        })
      };

      const setLeaderBoardTableData = (data) => {
        if(_.size(data) > 0){
          let a =[]
          _.map(data,(value,index)=>{
              a.push({
                  key:index,
                  rank: index+1,
                  name: <div style={{display: "flex", flexDirection: "row"}}><p style={{fontWeight: "bold", marginRight: "10px"}}>{_.startCase(_.get(value, 'guest.first_name')).charAt(0)}{_.startCase(_.get(value, 'guest.last_name')).charAt(0)}</p> <p>{_.get(value, 'guest.first_name')}  {_.get(value, 'guest.last_name')}</p></div>,
                  // last_weeks_results: _.isEmpty(_.get(value, 'latest_match')) ? "-": _.get(value, 'latest_match'),
                  total_games_played: _.get(value, 'total_played'),
                  total_games_won: _.get(value, 'total_wins'),
                  win_percentage:`${_.get(value,'win_pecentange')} %`,
                  leaderBoard_details: value
                  
              })
          })
            setLeaderBoardListData(a)
        }
       
      }


    const render = () => {

        return(
            <div>
              <div style={{display: "flex", flexDirection: "row"}}>
              {/* <Button onClick={startLoop}>Start Loop</Button> */}
              {/* <Button onClick={stopLoop} style={{marginLeft: "20px"}}>Stop Loop</Button> */}
              </div>
                <Table dataSource={leaderBoardListData} columns={columns} pagination={false} loading={responseData} />
            </div>
        );
    };

    return render();
};

const mapStateToProps = state => {
  return {

  }
};

const mapDispatchToProps = dispatch => ({
  leaderBoardListAPI: details => dispatch(global.redux.action.league.leaderBoardList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoard)