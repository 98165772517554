import { Button, Checkbox, DatePicker, Divider, Input, InputNumber, Modal, Select, TimePicker } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import momentTimeZone from "moment-timezone"
import _ from 'lodash';
import { useSearchParams, useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { ThreeDots } from "react-loader-spinner";
import { useEffect } from "react";
const { Option } = Select;

const CreateUpdateCoupon =(props)=>{
  const [spinnerLoading, setspinnerLoading] = useState(false);
  const [successModalShow, setsuccessModalShow] = useState(false)
  let history = useNavigate();

  const [couponFormDetails, setcouponFormDetails] = useState({
    name:"",
    coupon_code:"",
    timezone:"",
    start_in:null,
    expires_in:null,
    amount_type:0,
    amount:"",
    min_amount:"",
    start_date:null,
    start_time:null,
    end_date:null,
    end_time:null,
    enable_end_date:false,
    



  });
       const [isModalOpen, setIsModalOpen] = useState(true);
       const [selectTimeZone, setselectTimeZone] = useState(null);
       const [country, setcountry] = useState(null);
       const [checked, setChecked] = useState(true);
       const [searchParams, setSearchParams] = useSearchParams();

useEffect(() => {
  if(searchParams.get("coupon_id")){
    couponView(searchParams.get("coupon_id"))
  }
 
}, []);

const couponView =(id)=>{
  props.couponView({
    params:{id:id},
    callback:(res,data)=>{
    
      if(_.get(data,'data.details.id')){
       let start =  _.get(data,'data.details.starts_in')? momentTimeZone.tz(_.get(data,'data.details.starts_in'),_.get(data,'data.details.timezone')).format("DD/MM/YYYY"):null
       let end =  _.get(data,'data.details.expires_in')? momentTimeZone.tz(_.get(data,'data.details.expires_in'),_.get(data,'data.details.timezone')).format("DD/MM/YYYY"):null
       let startTime =  _.get(data,'data.details.starts_in')? momentTimeZone.tz(_.get(data,'data.details.starts_in'),_.get(data,'data.details.timezone')).format("hh:mm A"):null
       let endTime =  _.get(data,'data.details.expires_in')? momentTimeZone.tz(_.get(data,'data.details.expires_in'),_.get(data,'data.details.timezone')).format("hh:mm A"):null
        setcouponFormDetails({
          name:_.get(data,'data.details.label'),
          coupon_code:_.get(data,'data.details.coupon_code'),
          timezone:_.get(data,'data.details.timezone'),
         
         amount_type:_.get(data,'data.details.amount_type'),
          amount:parseFloat(_.get(data,'data.details.amount')),
          min_amount:parseFloat(_.get(data,'data.details.min_amount')),
          start_date:start,
          start_time:startTime,
          end_date:end,
          end_time:endTime,
          enable_end_date:_.get(data,'data.details.expires_in')==null?false:true,
        })
      }

    }
  })
}

       const handleChange = (value) => {

        if(typeof value === "string"){
          setcouponFormDetails({...couponFormDetails,timezone:value})
          setselectTimeZone([value]);
        }
        
        
      };


      const hostUserProfile =()=>{
        props.hostProfileData({
          callback:(res,data)=>{
           setcountry(_.upperCase(_.get(data,'data.details.country'))=="CA"?"CAD":"USD");

          }})
      }


const disabledTime = (current,type) => {
  const now = moment().format("DD/MM/YYYY")
  const date1 = moment(current).format("DD/MM/YYYY")
  const date2 = type=="end_time"? couponFormDetails.end_date?couponFormDetails.end_date:now: couponFormDetails.start_date?couponFormDetails.start_date:now
  if (current&&  date2==date1) {
      return {
          disabledHours: () => disabledHours(),
          disabledMinutes: (selectedHour) => disabledMinutes(selectedHour),
      }
  } else {
      return {
          disabledHours: () => [],
          disabledMinutes: () => [],
      }
  }
}
const disabledHours = () => {
  var hours = [];
    for(var i =0; i < moment().hour(); i++){
      hours.push(i);
  }
  return hours;
  
};
const disabledMinutes = (selectedHour) => {
  
  var minutes= [];
    if (selectedHour === moment().hour()){
        for(var i =0; i < moment().minute(); i++){
            minutes.push(i);
        }
    }
    return minutes;
};

const onchangeStartDateTime =(value, type)=>{
  
  if(type=="startDate"){
    setcouponFormDetails({...couponFormDetails,start_date:value? moment(value).format("DD/MM/YYYY"):"",start_time:null})
    
  }else if(type=="endDate"){
    setcouponFormDetails({...couponFormDetails,end_date:value? moment(value).format("DD/MM/YYYY"):"",end_time:null})
  }else if(type=="startTime"){
    setcouponFormDetails({...couponFormDetails,start_time:value? value:""})
  }else if(type=="endTime"){
    setcouponFormDetails({...couponFormDetails,end_time:value? value:""})
  }
  
}


const disableButton =()=>{
  let amountType = (couponFormDetails.amount_type==0|| couponFormDetails.amount_type==1)?false:true

let condition =!couponFormDetails.name || !couponFormDetails.coupon_code || !couponFormDetails.timezone || 
!couponFormDetails.start_date || !couponFormDetails.start_time ||  
!couponFormDetails.amount || amountType


  if(couponFormDetails.enable_end_date==false){
    if(condition){
      
      return true
    }else{
      return false
    }
  }else if(condition ||!couponFormDetails.end_date||!couponFormDetails.end_time){
    return true
  }else {
    return false
  }
  
}

const createUpdateCouponData =()=>{
let url = searchParams.get("coupon_id")?"couponUpdate":"couponCreate"
let body ={
  label:couponFormDetails.name,
  coupon_code:couponFormDetails.coupon_code,
  timezone:couponFormDetails.timezone,
  starts_in:`${moment(couponFormDetails.start_date,"DD/MM/YYYY").format('YYYY-MM-DD')}T${moment(couponFormDetails.start_time, ["h:mm A"]).format("HH:mm")}`,
  expires_in:couponFormDetails?.enable_end_date==true? `${moment(couponFormDetails.end_date,"DD/MM/YYYY").format('YYYY-MM-DD')}T${moment(couponFormDetails.end_time, ["h:mm A"]).format("HH:mm")}`:null,
  amount_type:couponFormDetails.amount_type,
  amount:couponFormDetails.amount,
  min_amount:couponFormDetails.min_amount?couponFormDetails.min_amount:0,
  day_type:0,
}
if(searchParams.get("coupon_id")){
  body.id=searchParams.get("coupon_id")
}

setspinnerLoading(true)
props[url]({
  body:body,
  callback:(res,data)=>{

    if(res==true){
      setsuccessModalShow(true)
    }
    
    // props.oncloseModal()
    
    setspinnerLoading(false)

  }
})


  
}


const resetAllfields = () => {
  setcouponFormDetails({
    name:"",
    coupon_code:"",
    timezone:"",
    start_in:null,
    expires_in:null,
    amount_type:0,
    amount:"",
    min_amount:"",
    start_date:null,
    start_time:null,
    end_date:null,
    end_time:null,
    enable_end_date:false,
  })
}
       return <div>
          <Modal centered={true} 
             
             footer={null} closeIcon={null} title={null} open={successModalShow} className="create-question-modal-container" style={{width:'435px'}}>
       <div style={{padding:'16px'}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <span><svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8"/>
<path d="M25 27L28 30L38 20M32 19H23.8C22.1198 19 21.2798 19 20.638 19.327C20.0735 19.6146 19.6146 20.0735 19.327 20.638C19 21.2798 19 22.1198 19 23.8V32.2C19 33.8802 19 34.7202 19.327 35.362C19.6146 35.9265 20.0735 36.3854 20.638 36.673C21.2798 37 22.1198 37 23.8 37H32.2C33.8802 37 34.7202 37 35.362 36.673C35.9265 36.3854 36.3854 35.9265 36.673 35.362C37 34.7202 37 33.8802 37 32.2V28" stroke="#12B76A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
<span style={{cursor:'pointer'}} onClick={()=>{
  setsuccessModalShow(false)
  resetAllfields();
              props?.oncloseModal() 
}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="x-close">
<path id="Icon" d="M18 6L6 18M6 6L18 18" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
</span>

        </div>
        <div style={{marginTop:'16px'}}>
          <div className="create-question-modal-title">Your promotion was successfully {searchParams.get('coupon_id')?"updated":"created"}!</div>
          <div className="create-question-modal-des" style={{marginTop:'4px'}}>You can now assign sessions to this promotion on the next screen</div>
        </div>
        <div style={{marginTop:'16px',width:'97%'}}><Divider style={{padding:'0px',margin:'0px',}}/></div>

        <div style={{display:'flex',justifyContent:'flex-end',marginTop:'30px'}}>
          <div className="create-question-modal-button" onClick={()=>{
  setsuccessModalShow(false)
  
  resetAllfields();
  history('/template-list?menu=template')
              props?.oncloseModal()
}}>
            <span className="create-question-modal-button-text">Done</span>
          </div>
        </div>
       </div>
      </Modal>
      <Modal centered={true} 
       className="coupon-modal-container"
       style={{width:"60%"}}
       open={isModalOpen}
       footer={null}
       title={null}
       closeIcon={null} 
     >
       <div>
        
       {spinnerLoading==true &&<div className="spinner-overlay">
      <div className="spinner-container">
      <ThreeDots
  visible={true}
  height="80"
  width="80"
  color="#EF6820"
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
      </div>
    </div>}

             <div style={{display:'flex',justifyContent:'space-between'}}> <div className="coupon-header-title">{searchParams.get("coupon_id")?"Edit promotion": "Create a new promotion"}</div>
             <div style={{cursor:'pointer'}} onClick={()=>{
              resetAllfields();
              props?.oncloseModal();
              history('/template-list?menu=template')
             }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></div>
             </div>
             <div style={{marginTop:'16px'}}>
             <div className="align-items-class" style={{width:'100%'}}>
                    <div  style={{width:'30%'}}>
                    <div className="coupon-field-title">Promotion name</div>
                    <div className="coupon-field-description">Give your promotion a name.</div>
                    </div> 
                    <div  style={{width:'70%'}}><div className="coupon-field-title">Promotion name</div>
                    <Input style={{width:'95%',height:'44px',marginTop:'6px'}} 
                    value={couponFormDetails.name} onChange={(e)=>setcouponFormDetails({...couponFormDetails,name:e.target.value})} placeholder="Give your promotion a name" />
                    </div>
              </div>

              <div className="align-items-class" style={{width:'100%',marginTop:'16px'}}>
                    <div  style={{width:'30%'}}>
                    <div className="coupon-field-title">Promotion code</div>
                    <div className="coupon-field-description" style={{width:'85%'}}>This is the code users will need to enter to take advantage of your promo</div>
                    </div> 
                    <div  style={{width:'70%'}} className={searchParams.get("coupon_id")? "disable-input-coupon":""}><div className="coupon-field-title">Promotion code</div>
                    <Input 
                    disabled={searchParams.get("coupon_id")}
                    
                    value={couponFormDetails.coupon_code} onChange={(e)=>setcouponFormDetails({...couponFormDetails,coupon_code:`${e.target.value}`.toUpperCase()})} 
                    style={{width:'95%',height:'44px',marginTop:'6px'}} className={"placeholderClass"} placeholder="All promo codes must be upper case and not include any special characters" />
                    </div>
              </div>
              <div style={{marginTop:'16px',width:'97%'}}><Divider style={{padding:'0px',margin:'0px',}}/></div>
              <div className="align-items-class" style={{width:'100%',marginTop:'16px'}}>
                    <div  style={{width:'30%'}}>
                    <div className="coupon-field-title">Promotion start date & end date</div>
                    <div className="coupon-field-description" style={{width:'85%'}}>Choose a start date, start time, end date (optional) and end time(optional)</div>
                    </div> 
                    <div  style={{width:'70%'}}><div className="coupon-field-title">Timezone</div>
                    <Select
    // mode="multiple"
    value={couponFormDetails.timezone || undefined}
    style={{
      width: '95%',
      height:'44px',
      marginTop:'6px'
    }}
    placeholder={<div style={{display:'flex',alignItems:'center'}}>
<div><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_6852_3032)">
    <path d="M9.99996 4.99984V9.99984L13.3333 11.6665M18.3333 9.99984C18.3333 14.6022 14.6023 18.3332 9.99996 18.3332C5.39759 18.3332 1.66663 14.6022 1.66663 9.99984C1.66663 5.39746 5.39759 1.6665 9.99996 1.6665C14.6023 1.6665 18.3333 5.39746 18.3333 9.99984Z" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_6852_3032">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg></div>
<div className="select-timezone-coupon-placeholder" style={{marginLeft:'8px'}}>EST</div>
<div className="select-timezone-coupon-placeholder" style={{marginLeft:'8px'}}>UTC−05:00</div>

    </div>}
    // defaultValue={['china']}
    onChange={handleChange}
    // optionLabelProp="label"
  >
     {(moment.tz.zonesForCountry(country=="USD"?"US": "CA")).map((item, index) => {
      
                                  return (
                                    <Option key={index} value={item}>{item}</Option>

                                  )
                                })}
  </Select>

  <div style={{display:'flex',marginTop:'16px'}}>
  <div  style={{width:'70%'}}><div className="coupon-field-title">Start date</div>
                    <DatePicker placeholder="DD/MM/YYYY" disabledDate={(current)=> {
  return current && current.valueOf() < moment().add(-1, 'days') ;
}} style={{width:'90%',height:'44px',marginTop:'6px'}}
onChange={(e,dateString)=>{
  onchangeStartDateTime(dateString,'startDate')
}}
value={couponFormDetails?.start_date?dayjs(couponFormDetails?.start_date, "DD/MM/YYYY"):null}

/>
                    </div>
                    <div  style={{width:'70%'}}><div className="coupon-field-title">Start time</div>
                    <TimePicker
                    
                    value={couponFormDetails?.start_time?dayjs(couponFormDetails?.start_time,"hh:mm A"):null}
                    onChange={(e,dateString)=>{
                      
                      onchangeStartDateTime(dateString,'startTime')
                    }}
                    // disabledTime={(date)=>{
                    //   return {disabledHours:couponFormDetails.start_date ? moment().format("DD/MM/YYYY")==couponFormDetails.start_date?disabledHours():[]:[],
                    //   disabledMinutes:(selectedHour) =>couponFormDetails.start_date ? moment().format("DD/MM/YYYY")==couponFormDetails.start_date?disabledMinutes(selectedHour):[]:[]}
                    // }}
                    format={"hh:mm A"} 
                    disabledTime={(e)=>disabledTime(e,"start_time")}
                    disabled={!couponFormDetails.start_date} style={{width:'90%',height:'44px',marginTop:'6px'}} placeholder="00:00 AM" changeOnScroll needConfirm={false}/>
                    </div>
  </div>

  <div style={{marginTop:'16px'}}>
    <div><Checkbox checked={couponFormDetails.enable_end_date} className="set-date-checkbox"  onChange={()=>setcouponFormDetails({...couponFormDetails,enable_end_date:!couponFormDetails.enable_end_date})}>
    Set end date
        </Checkbox></div>
  </div>
 {couponFormDetails.enable_end_date==true && <div style={{display:'flex',marginTop:'16px'}}>
  <div  style={{width:'70%'}}><div className="coupon-field-title">End date</div>
                    <DatePicker 
                    minDate={couponFormDetails?.start_date?dayjs(couponFormDetails?.start_date, "DD/MM/YYYY"):null }
                    onChange={(e,dateString)=>{
                      onchangeStartDateTime(dateString,'endDate');
                    }}
                    value={couponFormDetails?.end_date?dayjs(couponFormDetails?.end_date, "DD/MM/YYYY"):null}

                    placeholder="DD/MM/YYYY" disabledDate={(current)=> {
  return current && current.valueOf() < moment().add(-1, 'days') ;
}} style={{width:'90%',height:'44px',marginTop:'6px'}}/>
                    </div>
                    <div  style={{width:'70%'}}><div className="coupon-field-title">End time</div>
                    <TimePicker 
                    value={couponFormDetails?.end_time?dayjs(couponFormDetails?.end_time,"hh:mm A"):null}
                    onChange={(e,dateString)=>{
                      onchangeStartDateTime(dateString,'endTime')
                    }}
                    disabledTime={(e)=>disabledTime(e,"end_time")}
                    format={"hh:mm A"} 
                    disabled={!couponFormDetails.end_date} style={{width:'90%',height:'44px',marginTop:'6px'}} placeholder="00:00 AM" 
                    changeOnScroll needConfirm={false}/>
                    </div>
  </div>}
  
                    </div>
              </div>

              <div style={{marginTop:'16px',width:'97%'}}><Divider style={{padding:'0px',margin:'0px',}}/></div>

              <div className="align-items-class" style={{width:'100%',marginTop:'16px'}}>
                    <div  style={{width:'30%'}}>
                    <div className="coupon-field-title">Discount type and amount</div>
                    <div className="coupon-field-description" style={{width:'85%'}}>Choose the type of discount you, the amount or percentage, and specify any minimum spend (optional)</div>
                    </div> 
                    <div  style={{width:'70%'}}><div className="coupon-field-title">Discount type</div>
                    <div style={{display:'flex',marginTop:'6px',justifyContent:'space-between',width:'95%'}}>
                      <div className={couponFormDetails.amount_type===0?"coupon-discount-type-container":" coupon-discount-type-container-inactive"}  style={{width:'48%',cursor:'pointer'}} onClick={()=>{
                       {!searchParams.get("coupon_id") &&  setcouponFormDetails({...couponFormDetails,amount_type:0,amount:""});
                      
                      }
                      }}>
                        <span className={couponFormDetails.amount_type===0?"active-title":"inactive-title"}>Percentage</span>
                        <span 
                        className={couponFormDetails.amount_type===0?"active-description":"inactive-description"}
                        >This will be a percentage based on the total price of the session</span>

                      </div>
                      <div className={couponFormDetails.amount_type===1?"coupon-discount-type-container":" coupon-discount-type-container-inactive"}  style={{width:'48%',cursor:'pointer'}} onClick={()=>{
                      !searchParams.get("coupon_id") &&  setcouponFormDetails({...couponFormDetails,amount_type:1,amount:""})
                      }}>
                        <span className={couponFormDetails.amount_type===1?"active-title":"inactive-title"}>Fixed amount</span>
                        <span className={couponFormDetails.amount_type===1?"active-description":"inactive-description"} style={{width:'86%'}}>This will be a fixed amount that doesn’t change</span>

                      </div>  
                    </div>

                    <div  style={{marginTop:'16px'}} className="coupon-number-input"><div className="coupon-field-title">Discount</div>
                    <InputNumber min={1}
                    max={couponFormDetails.amount_type===1?99999999:100}
                    disabled={searchParams.get("coupon_id")}
                    onChange={(e)=>{
                      setcouponFormDetails({...couponFormDetails,amount:e})
                    }}
                    value={couponFormDetails?.amount}
                    suffix={couponFormDetails.amount_type==1? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M5 13.3332C5 15.1741 6.49238 16.6665 8.33333 16.6665H11.6667C13.5076 16.6665 15 15.1741 15 13.3332C15 11.4922 13.5076 9.99984 11.6667 9.99984H8.33333C6.49238 9.99984 5 8.50745 5 6.6665C5 4.82555 6.49238 3.33317 8.33333 3.33317H11.6667C13.5076 3.33317 15 4.82555 15 6.6665M10 1.6665V18.3332" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15.8333 4.1665L4.16663 15.8332M7.49996 5.83317C7.49996 6.75365 6.75377 7.49984 5.83329 7.49984C4.91282 7.49984 4.16663 6.75365 4.16663 5.83317C4.16663 4.9127 4.91282 4.1665 5.83329 4.1665C6.75377 4.1665 7.49996 4.9127 7.49996 5.83317ZM15.8333 14.1665C15.8333 15.087 15.0871 15.8332 14.1666 15.8332C13.2462 15.8332 12.5 15.087 12.5 14.1665C12.5 13.246 13.2462 12.4998 14.1666 12.4998C15.0871 12.4998 15.8333 13.246 15.8333 14.1665Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} style={{width:'45%',height:'44px',marginTop:'6px'}} className="placeholderClass"  />
                    </div>
                    <div  style={{marginTop:'16px'}} className="coupon-number-input"><div className="coupon-field-title">Minimum spend (optional)</div>
                    <InputNumber 
                     value={(couponFormDetails?.min_amount ||couponFormDetails?.min_amount==0)?couponFormDetails?.min_amount:""}
                    onChange={(e)=>{
                      setcouponFormDetails({...couponFormDetails,min_amount:e})
                    }}
                    suffix={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M5 13.3332C5 15.1741 6.49238 16.6665 8.33333 16.6665H11.6667C13.5076 16.6665 15 15.1741 15 13.3332C15 11.4922 13.5076 9.99984 11.6667 9.99984H8.33333C6.49238 9.99984 5 8.50745 5 6.6665C5 4.82555 6.49238 3.33317 8.33333 3.33317H11.6667C13.5076 3.33317 15 4.82555 15 6.6665M10 1.6665V18.3332" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} style={{width:'45%',height:'44px',marginTop:'6px'}} className="placeholderClass"  />
                    </div>
                    </div>
              </div>
              <div style={{marginTop:'16px',width:'97%'}}><Divider style={{padding:'0px',margin:'0px',}}/></div>
              <div style={{display:'flex',justifyContent:'flex-end',marginTop:'16px',width:'97%'}}>
                <Button disabled={disableButton()} onClick={()=>{
createUpdateCouponData()
                }} className={disableButton()==true?"coupon-create-button-disabled":"coupon-create-button"}> {searchParams.get("coupon_id")? "Save changes":"Create promotion"}</Button>
              </div>
             </div>


       </div>


       </Modal> </div> 
}


const mapStateToProps = state => {
       return {
     
       }
     }
     
     const mapDispatchToProps = dispatch => ({
       couponList: details => dispatch(global.redux.action.promotion.couponList(details)),
       couponCreate: details => dispatch(global.redux.action.promotion.couponCreate(details)),
       couponUpdate: details => dispatch(global.redux.action.promotion.couponupdate(details)),
       couponView: details => dispatch(global.redux.action.promotion.couponView(details)),
       hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
     });
     export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateCoupon)