import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { connect } from "react-redux";
import chatKittyInstance from '../../chatkitty/instance';
const MainComponent = (props)=>{
  const [chatKittyCurrentUserDetails, setchatKittyCurrentUserDetails] = useState({});

  useEffect(()=>{
     chatKittyInstance.onCurrentUserChanged((currentUser) => {
      
      const current_user_details = _.pick(currentUser, ['id', 'name', 'displayName', 'displayPictureUrl']);
      setchatKittyCurrentUserDetails(current_user_details);
    });
  }, []);

  useEffect(()=>{
    if(!_.chain(props).get('settings.chatKittyCurrentUserData', {}).isEqual(chatKittyCurrentUserDetails).value()){
      
      setChatKittyCurrentUserData(chatKittyCurrentUserDetails);
      
      updateChatKittyUserIdInHostProfile(_.get(chatKittyCurrentUserDetails, 'id', null));
    }
  }, [chatKittyCurrentUserDetails]);

  const setChatKittyCurrentUserData = (chatKittyCurrentUserData)=>{
    props.setChatKittyCurrentUserData({
      value: chatKittyCurrentUserData
    });
  };

  const updateChatKittyUserIdInHostProfile = (chatkitty_user_id)=>{
    const condition = _.overSome([
      host_profile_chatkitty_user_id => _.isEmpty(host_profile_chatkitty_user_id),
      host_profile_chatkitty_user_id => !_.eq(host_profile_chatkitty_user_id, _.toString(chatkitty_user_id))
    ]);
    const host_profile_chatkitty_user_id = _.chain(props).get('hostProfile.details.chatkitty_user_id', null).toString().value();
    if(_.get(props,'hostProfile.details.id') && !_.chain(chatkitty_user_id).toString().isEmpty().value() && condition(host_profile_chatkitty_user_id)){
      props.updateHostProfile({
        body: {chatkitty_user_id}
      });
    }
  };

  // const renderNotificationsComponent=()=>{
  //   if(!_.chain(props).get('settings.chatKittyCurrentUserData', {}).isEmpty().value()){
  //     return (<Notifications/>);
  //   }
  // };

  const render=()=>{
    return (
      <React.Fragment>
        
      </React.Fragment>
    );
  };

  return render(); 
};

const mapStateToProps = state => {
  return {
    settings: _.get(state, `app.${global.redux.actionTypes.settings.name}`, {}),
    hostProfile: _.get(state, `app.${global.redux.actionTypes.host.name}.profile.data`, {})
  }
};

const mapDispatchToProps = dispatch => ({
  setChatKittyCurrentUserData: details =>
    dispatch(global.redux.action.settings.setChatKittyCurrentUserData(details)),
  updateHostProfile: details =>
    dispatch(global.redux.action.host.update(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);