import React, { createRef, useEffect, useRef, useState } from 'react';
import { Modal, Box, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input,Button } from 'antd';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router';
const useStyles = makeStyles((theme) => ({
  modalBackground: {
    display: 'flex',
    alignItems: 'center',
    
    justifyContent: 'center',
    backgroundColor: 'rgba(52, 64, 84, 0.70)', // Background color when modal is open
    minHeight: '100vh',
  },
  modalContent: {
       padding:"24px",
    backgroundColor: '#fff',
    borderRadius: 8,
    outline: 'none',
//     width: '400px',
  },
  inputField: {
    marginBottom: 2,
  },
  button: {
    marginTop: 2,
  },
}));

const LoginSuccess = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  let navigate =  useNavigate();

  
  return (
    <>
      
        <Box className={classes.modalContent} sx={{width:{
            xs: "330px", // mobile
            sm: "400px", // tablet
            md: "540px", // small desktop
            lg: "640px",  
        },padding:{
          xs: "16px", // mobile
          sm: "18px", // tablet
          md: "24px", // small desktop
          lg: "24px",
        }}}>
         {isMobile==false &&<Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'16px'}}>
      <Box sx={{borderRadius: "10px",
       display:'flex',alignItems:'center',justifyContent:'center',
border: "1px solid var(--Gray-200, #EAECF0)",
background: "var(--Base-White, #FFF)",
boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",width:"48px",height:'48px'}}>
       <Typography>
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 2V3.4C15 3.96005 15 4.24008 14.891 4.45399C14.7951 4.64215 14.6422 4.79513 14.454 4.89101C14.2401 5 13.9601 5 13.4 5H10.6C10.0399 5 9.75992 5 9.54601 4.89101C9.35785 4.79513 9.20487 4.64215 9.10899 4.45399C9 4.24008 9 3.96005 9 3.4V2M8.2 22H15.8C16.9201 22 17.4802 22 17.908 21.782C18.2843 21.5903 18.5903 21.2843 18.782 20.908C19 20.4802 19 19.9201 19 18.8V5.2C19 4.07989 19 3.51984 18.782 3.09202C18.5903 2.71569 18.2843 2.40973 17.908 2.21799C17.4802 2 16.9201 2 15.8 2H8.2C7.0799 2 6.51984 2 6.09202 2.21799C5.71569 2.40973 5.40973 2.71569 5.21799 3.09202C5 3.51984 5 4.0799 5 5.2V18.8C5 19.9201 5 20.4802 5.21799 20.908C5.40973 21.2843 5.71569 21.5903 6.09202 21.782C6.51984 22 7.07989 22 8.2 22Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</Typography>
       </Box>
       
</Box>}
          <Typography sx={{color:"#101828",fontSize:{  xs: "18px", // mobile
         sm: "18px", // tablet
         md: "18px", // small desktop
         lg: "18px",},fontWeight:"600",marginBottom: isMobile?"32px":"16px"}}>
           Sign up with a phone number
          </Typography>

         
         
          <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
            <Box>
            <Box sx={{borderRadius: "28px",
border: "6px solid var(--Success-50, #ECFDF3)",
background: "var(--Success-100, #D1FADF)",
display: "flex",
width: "48px",
height: "48px",
padding: "12px",
justifyContent: "center",
alignItems: "center"}}>
             <Typography>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M22 11.0857V12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69279 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572M22 4L12 14.01L9 11.01" stroke="#12B76A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </Typography>
             </Box>
            </Box>
            <Typography sx={{color:"#101828",fontSize:{  xs: "18px", // mobile
         sm: "18px", // tablet
         md: "20px", // small desktop
         lg: "20px",},fontWeight:"600",marginBottom:"6px",marginTop:'16px'}}>
          Account created!
          </Typography>
          <Typography textAlign={"center"} sx={{color:"#475467",fontSize:{  xs: "14px", // mobile
         sm: "15px", // tablet
         md: "16px", // small desktop
         lg: "16px",},fontWeight:"400",marginBottom:"24px"}}>
          Your account was successfully created.  We still need to verify your information before you can start collecting payments. You will now be redirected to our onboarding process
          </Typography>
          </Box>
         
          
          <Box sx={{display:'flex',alignItems:'center',
          flexDirection:{
            xs: "column-reverse", // mobile
            sm: "row", // tablet
            md: "row", // small desktop
            lg: "row",
         },justifyContent:'space-between',marginTop:isMobile?"16px": "32px"}}>
          <Button disabled={true} style={{
              borderRadius: "8px",
              border: "1px solid var(--Gray-200, #EAECF0)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              width: '100%', marginTop: isMobile?"12px": '4px', height: '44px',  color: "#D0D5DD",
              fontSize: '16px',
              fontWeight: "600"
            }}>
              Cancel
            </Button>
            <Button  style={{
              width: '100%', marginTop: '4px', height: '44px', background: "#EF6820", color: "#FFF",
              fontSize: '16px',
              fontWeight: "600",
              marginLeft:isMobile?"0px": '12px'
            }}
            onClick={()=>{props?.closeModal();
              navigate("/communiti/onboarding")
            }
              
              
            }
            >
              Start onboarding
            </Button>
          </Box>
        </Box></>
      
  );
};

export default LoginSuccess;
