import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateSendEmailState;
// guest actions

export default {
    postEmails: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/orders/send-email`
                },
                actionType: actionType.sendEmails.emailToGuest,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}