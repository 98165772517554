import React, {useEffect, useState} from 'react';
import { Button, Form, Input, Select, Modal, notification, Table, InputNumber, Spin } from "antd";
import { connect } from 'react-redux';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { AiOutlineLoading } from "react-icons/ai";
import IntegratedTab from '../manage/session/integratedTabs.js';
import LeaderBoard from '../manage/session/leader-board';


import "./style.css";
import Tags from './tags';
import RegistrationList from './registration-list';
import Menus from '../menu';

const { Option } = Select;
const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
];

const lineStyle = {
  height: '2px',
  backgroundColor: '#EAECF0',
  width: '100%', // Adjust the width as needed
  marginBottom: "10px"
};

const EditExperiences = (props) => {
    const [form] = Form.useForm();
    const initialOptions = ['Session Details', 'Registration'];
    const [options, setOptions] = useState(initialOptions);
  const called_off_options = ['Session Details'];
  const card_menu = ["Schedule", "Start & End Time", "Location", "Price", "Spaces", "Tags"];
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState('Session Details');
  const [experienceDetails, setExperienceDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [menuSelectedOption, setMenuSelectedOption] = useState('Schedule');
  const [selectedCard, setSelectedCard] = useState(false);
  const [selectedProrateCard, setSelectedProrateCard] = useState(false);
  const [tempSelectedOption, setTempSelectedOption] = useState(null);
  const [address, setAddress] = useState('');
  const [selectValues, setSelectValues] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectOnsite, setSelectOnsite] = useState(false)
  useEffect(() => {
    viewSession();
   
  }, []);

  useEffect(() => {
    if (searchParams.get("type") === "league") {
      setOptions(prevOptions => [...prevOptions, "Onsite-facilitation", "Leaderboard"]);
    } else {
      // Reset options to initialOptions if not league type
      setOptions(initialOptions);
    }
  }, [searchParams]);

  useEffect(() => {
    if(searchParams.get("from") === "notifications" || searchParams.get("from") === "customers")
      setSelectedOption('Registration');
  }, [searchParams])

  const handleOptionClick = (option) => {
      
      setSelectedOption(option);
      setSelectOnsite(false)
      
    };

    const handleMenuClick = (option) => {
      const targetId = option.replace(/\s+/g, '_').toLowerCase();
      const targetElement = document.getElementById(targetId);
        
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setMenuSelectedOption(option);
    }
    };

    const viewSession = () => {
        props.experienceViewAPI({
            params:{
                id: searchParams.get("id"),
                expand: "template"
            },
            callback: (response, data) => {
                
                if(!_.isEmpty(_.get(data, 'data.details'))){
                    setLoading(false);
                    // setSelectValues(_.get(data, 'data.details.tags'))
                    setExperienceDetails(_.get(data, 'data.details'));
                    setSelectedCard(_.get(data, 'data.details.can_late_register'));
                    setSelectedProrateCard(_.get(data, 'data.details.can_prorate'));
                    form.setFieldsValue({
                      spaces: _.get(data, 'data.details.max_no_of_openings'),
                      last_reg: `${moment.tz(_.get(data, 'data.details.late_registration_time_limit'), !_.isEmpty(_.get(data, 'data.details.meta_details.timezone')) ? _.get(data, 'data.details.meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')}`,
                      start_time: `${_.get(data, 'data.details.experience_start_time') ?
                      moment.tz(_.get(data, 'data.details.experience_date'), !_.isEmpty(_.get(data, 'data.details.meta_details.timezone')) ? _.get(data, 'data.details.meta_details.timezone') : "America/Toronto").format("hh:mm A") : '--'}`,
                      end_time: `${_.get(data, 'data.details.experience_end_time') ?
                      moment.tz(_.get(data, 'data.details.experience_end_date'), !_.isEmpty(_.get(data, 'data.details.meta_details.timezone')) ? _.get(data, 'data.details.meta_details.timezone') : "America/Toronto").format("hh:mm A") : '--'}`,
                      price: _.get(data, 'data.details.price'),
                      country: "CAD",
                      location: _.get(data, 'data.details.address'),
                      timezone: _.get(data, 'data.details.meta_details.timezone'),
                      tags: _.isEmpty(_.get(data, 'data.details.tags')) ? [] : _.get(data, 'data.details.tags')
                    })
                }
            }
        })
    }

    const columns = [
        {
          title: <div className='session-details-table'>ID</div>,
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: <div className='session-details-table'>Session Title</div>,
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: <div className='session-details-table'>Schedule</div>,
          dataIndex: 'schedule_details',
          key: 'schedule_details',
          render: () => (
            <>
              <div className='session-details-table'>{`${moment.tz(_.get(experienceDetails, 'experience_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(experienceDetails, 'experience_end_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')}`}</div>
              <div className='session-details-table'>{_.get(experienceDetails, 'type') == 'open' ? "" :
          `${_.get(experienceDetails, 'experience_start_time') ?
            moment.tz(_.get(experienceDetails, 'experience_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format("hh:mm") : '--'}-${_.get(experienceDetails, 'experience_end_time') ? moment.tz(_.get(experienceDetails, 'experience_end_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`}</div>
              <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{ _.size(_.get(experienceDetails, 'schedule_dates')) > 1 ? `${_.size(_.get(experienceDetails, 'schedule_dates'))+1} Sessions` :  _.isEmpty(_.get(experienceDetails, 'schedule_dates')) && _.get(experienceDetails, 'type') !== "open" ? '1 session' : _.isEmpty(_.get(experienceDetails, 'schedule_dates')) && _.get(experienceDetails, 'type') === "open" ? "" : `${_.size(_.get(experienceDetails, 'schedule_dates'))} Session`}</div> {_.get(experienceDetails, 'can_late_register') === true && <div className="custom-button-tag">Late Registration</div>} </div>
            </>
          ),
        },

        {
            title: <div className='session-details-table'>Session Info</div>,
            dataIndex: 'session_info',
            key: 'session_info',
            render: () => (
              <>
                <div className='session-details-table'>{_.get(experienceDetails, 'type') == "league" ? "League Session" : _.get(experienceDetails, 'type') == "open" ? "Open Session" : "Scheduled Session"}</div>
                <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{`$${_.get(experienceDetails, 'price')} ${_.get(experienceDetails, 'template.meta_details.currency')}`}</div> {_.get(experienceDetails, 'can_prorate') === true && <div className="custom-button-tag">Prorate</div>}</div>
                <div className='session-details-table'>{`${_.get(experienceDetails, 'no_of_bookings')}/${_.get(experienceDetails, 'max_no_of_openings')} Registrations`}</div>
              </>
            ),
          },
      ];

      const dataSource = [
        {
          key: '1',
          id: <div className='session-details-table'>{_.get(experienceDetails, 'id')}</div>,
          title: <div className='session-details-table'>{_.get(experienceDetails, 'template.title')}</div>,
          schedule_details: null, // The render function will populate this
          session_info: null,  // The render function will populate this
        },
      ];
      

      const saveAndCancel = () => {
        return(
          <div style={{display: "flex", flexDirection: "row"}}>
            <Button style={{marginRight: "10px", height: "35px"}}>Cancel</Button>
          <Button style={{backgroundColor: "#EF6820", color: "#FFFFFF", height: "35px"}}>Save</Button>
          </div>
        )
      };

      const handleCardClick = (card) => {
        
        setSelectedCard(card);
        };

        const handleProrateCardClick = (card) => {
          setSelectedProrateCard(card);
          };

          const handleSelectChange = (value) => {
            
            setTempSelectedOption(value);
            // setIsModalOpen(true);
          };

          const ScheduleCard = ({ date }) => {
            return (
              <div className="card" style={{ backgroundColor: '#FFFFFF', border: "none", color: '#475467', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: '20px' }}>
                <div className="card-body">
                  <p className="card-title">{date}</p>
                </div>
              </div>
            );
          };

          const Schedule = ({ dates }) => {
            return (
              <div className="container mt-4">
                <p>Session Schedule</p>
                <hr style={{ backgroundColor: '#EAECF0' }} />
          
                {_.size(dates) > 0 && dates.map((date, index) => (
                  <React.Fragment key={index}>
                    <ScheduleCard date={date} />
                    <hr style={{ backgroundColor: '#EAECF0' }} />
                  </React.Fragment>
                ))}
              </div>
            );
          };

          const onFinishExperience = (value) => {
              
              // if()
              props.updateSpacesAPI({
                body:{
                  id: searchParams.get("id"),
                  max_no_of_openings: _.get(value, 'spaces')
                },
                callback: (res, data) => {
                  if(res){
                    viewSession();
                  }
                  
                }
              });

              props.updateExperienceAPI({
                body:{
                  id: searchParams.get("id"),
                  tags: _.isEmpty(_.get(value, 'tags')) ? [] : _.get(value, 'tags')
                },
                callback: (r, v) => {
                  if(r){
                    viewSession();
                    notification.success({
                      message: "Success",
                      description: "Session updated successfully"
                    })
                  }
                }
              })
          }

          const onHandleChangeNumeric = (e) => {
            let valu = e.target.value;
           
            if (!Number(valu)) {
            return;
            }
           };

           const handleSearch = (value) => {
            setInputValue(value);
          };

          const handleEnterPress = (e) => {
            if (e.key === 'Enter' && inputValue.trim() !== '') {
              setSelectValues([...selectValues, inputValue]);
              setInputValue('');
            }
          };

          const antIcon = (
            <LoadingOutlined
              style={{
                fontSize: 40,
              }}
              spin
            />
          );

          const handleSelectOnsite = () => {
            setSelectOnsite(true);
          }

  const render = () => {
      return (
          <div>
            {
              !loading && _.get(props, 'userProfile.id') === _.get(experienceDetails, 'host') && <div className='container' style={{maxWidth:'100%'}}>
              <div className="row">
                 
                 <div className='col-12' style={{paddingLeft:'0px'}}>
                   <Menus/>
                     <div style={{display: "flex", flexDirection: "column", marginTop: "10px"}}>
                         <div className='profile-title'>
                             Manage Session
                         </div>
                     </div>
                     <hr/>
                 </div>

                 {/* <div className='col-12'> */}
                     <div className='card' style={{marginTop: "8px", width: "100%", marginBottom: "15px", border: "none"}}>
                         <div style={{display: "flex", flexDirection: "row"}}>
                         <div className='col-lg-3' style={{paddingLeft:'0px'}}>
                         <div style={{display: "flex", flexDirection: "column", marginTop: "12px"}}>
                         
                         {options.map((option, index) => (
                             <div
                             style={{width: "75%"}}
                                 key={index}
                                 className={`radio-button ${selectedOption === option ? 'selected' : ''}`}
                                 onClick={() => handleOptionClick(option)}
                             >
                                 {option}
                                 
                             </div>
                         ))}
                                {/* <div 
                                 className={`radio-button ${selectOnsite === true ? 'selected' : ''}`}
                                 onClick={() => handleSelectOnsite()}
                                 >
                                  Onsite-facilitation 
                                 </div> */}
                         </div>
                     </div>
                    
                     <div className='col-lg-9'>
                       {
                           selectedOption === 'Session Details' && (
                               <div>
                                 <div style={{display: "flex", justifyContent: "space-between"}}>
                                   <div className='session-static-title'>
                                   Session Details
                                   </div>
                                   { searchParams.get('action') !== 'called_off' && saveAndCancel()}
                                   </div>
                                   { searchParams.get('action') !== 'called_off' && <p className='session-static-desc'>You are managing the session below. View or update your session details here.</p>}
                                   <hr/>
                                   <Table loading={loading} pagination={false} columns={columns} dataSource={dataSource}/>

                                   <div className='card' style={{marginTop: "12px", backgroundColor: "#F2F4F7"}}>
                                       <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                       {card_menu.map((option, index) => (
                             <div
                                 key={index}
                                 className={`menu-card-button ${menuSelectedOption === option ? 'menu-selected' : ''}`}
                                 onClick={() => handleMenuClick(option)}
                             >
                                 {option}
                             </div>
                         ))}
                                       </div>
                                   </div>
                                   <Form 
                       layout='vertical'
                       form={form}
                       onFinish={(e) => onFinishExperience(e)}
                       >

                       <div id="schedule" style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='container-titles'>Select session dates</div>
                         <div className='container-titles-desc'>Select the dates the session takes place. Start and end date are automatically computed based on your selection</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                           {/* <Form.Item 
                           label="Session Dates"
                           name="session_dates" 
                           rules={[
                             {
                               required: false,
                               message: 'Please input your date',
                             },
                           ]}
                           >
                          <Input/>
                           </Form.Item> */}
                           <div className='card'>
                             <div className='card-body'>
                             <p className='card-title' style={{marginLeft: "12px", marginTop: "10px"}}>Session schedule</p>
                             <hr/>
                             <div>
                                   <p className='card-text' style={{marginLeft: "12px", marginTop: "10px"}}>{`${moment.tz(_.get(experienceDetails, 'experience_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('dddd MMMM DD, YYYY')}`}</p>
                              <hr />
                                   </div>
                             {
                               _.size(_.get(experienceDetails, 'schedule_dates')) > 0 && _.get(experienceDetails, 'schedule_dates').map((value, index, array) => {
                                 return(
                                   <div>
                                   <p className='card-text' style={{marginLeft: "12px", marginTop: "10px"}}>{`${moment.tz(value, !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('dddd MMMM DD, YYYY')}`}</p>
                                   {index !== array.length - 1 && <hr />}
                                   </div>
                                 )
                               })
                             }
                             </div>
                           </div>
                         </div>
                         </div>
                       <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='container-titles'>Late registration</div>
                         <div className='container-titles-desc'>Enable or disable users from registering<br/> after a session has started</div>
                         </div>
                         
                         <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                         <div style={{display: "flex", flexDirection: "row"}}>
                         <div
                              className={`card ${selectedCard === true ? 'cardselected' : ''}`}
                              style={{ borderRadius: '20px', marginBottom: '10px' }}
                               // onClick={() => handleCardClick(true)}
                           >
                               <div className='card-body'>
                               <p className={`card-title custom-card-title ${selectedCard === true ? 'titleselected' : ''}`}>Allow Late Registration</p>
                               <p className={`card-text custom-card-title ${selectedCard === true ? 'textselected' : ''}`}>
                               Allow users to register after a session has started.
                               </p>
                               </div>
                           </div>

                           <div
                            className={`card w-5 ${selectedCard === false ? 'cardselected' : ''}`}
                               style={{ borderRadius: '20px', marginLeft: "8px", marginBottom: '10px'}}
                               // onClick={() => handleCardClick(false)}
                           >
                               <div className='card-body'>
                               <p className={`card-title custom-card-title ${selectedCard === false ? 'titleselected' : ''}`}>Do Not Allow Late Registration</p>
                               <p className={`card-text custom-card-title ${selectedCard === false ? 'titleselected' : ''}`}>
                               Do not allow users to register after a session has started.
                               </p>
                               </div>
                           </div>
                         </div>
                         { _.get(experienceDetails, 'late_registration_time_limit') && <Form.Item 
                         label="Last Registration Date"
                         name="last_reg"
                         >
                         <Input disabled placeholder='Select last registration date' style={{height: "40px"}}/>
                         </Form.Item>}
                         
                         </div>
                        
                         </div>
                         <hr/>
                         <div id="start_end_time" style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='container-titles'>Start time, end time and timezone</div>
                         <div className='container-titles-desc'>Choose the timezone, start time,<br/> and end time for the session</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                           <Form.Item 
                           label="Start Time"
                           name="start_time" 
                           rules={[
                             {
                               required: false,
                               message: 'Please input your start time',
                             },
                           ]}
                           >
                           <Input disabled style={{ width: "100%", height: "40px"}}/>
                           </Form.Item>
                           <Form.Item 
                           label="End Time"
                           name="end_time" 
                           rules={[
                             {
                               required: false,
                               message: 'Please input your end time',
                             },
                           ]}
                           >
                           <Input disabled style={{ width: "100%", height: "40px"}}/>
                           </Form.Item>
                           <Form.Item 
                           label="Timezone"
                           name="timezone" 
                           rules={[
                             {
                               required: false,
                               message: 'Please input your timezone',
                             },
                           ]}
                           >
                           <Input disabled style={{ width: "100%", height: "40px"}}/>
                           </Form.Item>
                         </div>
                         
                         </div>
                         <hr/>
                         <div id="location" style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='container-titles'>Country and address</div>
                         <div className='container-titles-desc'>Select your country, and enter the address of where the session<br/> takes place</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                         <Form.Item 
                           label="Country"
                           name="country" 
                           rules={[
                             {
                               required: false,
                               message: 'Please select your country.',
                             },
                           ]}
                           >
                           <Select
                           disabled
                           onChange={handleSelectChange}
                           value={tempSelectedOption}
                           // defaultValue={selectedOption}
                           style={{width:'100%'}}
                               optionFilterProp="children">
                           {_.map(countryList,(v,i)=>{
                               return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
                           })}
                           </Select>
                           </Form.Item>
                           <Form.Item 
                           label="Address"
                           name="location" 
                           rules={[
                             {
                               required: false,
                               message: 'Please input your address.',
                             },
                           ]}
                           >
                           <Input disabled/>
                           </Form.Item>
                         </div>
                         </div>
                         <hr/>
                         <div id="price" style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='container-titles'>Price and prorate settings</div>
                         <div className='container-titles-desc'>Enter the price of your session and choose whether you will prorate late registrations</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                           <Form.Item 
                           label="Price"
                           name="price" 
                           rules={[
                             {
                               required: false,
                               message: 'Please input your price',
                             },
                           ]}
                           >
                           <Input disabled style={{height: "40px", width: "100%"}}/>
                           </Form.Item>
                           <Form.Item 
                           label="Prorate Settings"
                           >
                              <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                         <div style={{display: "flex", flexDirection: "row"}}>
                         <div
                              className={`card ${selectedProrateCard === true ? 'cardselected' : ''}`}
                              style={{ borderRadius: '20px', marginBottom: '10px' }}
                               // onClick={() => handleProrateCardClick(true)}
                           >
                               <div className='card-body'>
                               <p className={`card-title custom-card-title ${selectedProrateCard === true ? 'titleselected' : ''}`}>Prorate</p>
                               <p className={`card-text custom-card-title ${selectedProrateCard === true ? 'textselected' : ''}`}>
                               Price will automatically reduce if users register after the start date.
                               </p>
                               </div>
                           </div>

                           <div
                            className={`card w-5 ${selectedProrateCard === false ? 'cardselected' : ''}`}
                               style={{ borderRadius: '20px', marginLeft: "8px", marginBottom: '10px'}}
                               // onClick={() => handleProrateCardClick(false)}
                           >
                               <div className='card-body'>
                               <p className={`card-title custom-card-title ${selectedProrateCard === false ? 'titleselected' : ''}`}>Do Not Prorate</p>
                               <p className={`card-text custom-card-title ${selectedProrateCard === false ? 'titleselected' : ''}`}>
                               Price will not automatically reduce if users register after the start date.
                               </p>
                               </div>
                           </div>
                         </div>
                         </div>
                           </Form.Item>
                         </div>
                         </div>
                         <hr/>
                         <div id="spaces" style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='container-titles'>Session spaces</div>
                         <div className='container-titles-desc'>Enter the amount of spaces available for this session</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                           <Form.Item 
                           label="Spaces"
                           name="spaces" 
                           >
                           <InputNumber
                           disabled = {searchParams.get('action') === 'called_off' ? true : false}
                           style={{height: "40px", width: "100%"}}
                           />
                           </Form.Item>
                         </div>
                         </div>
                         <hr/>
                         <div id="tags" style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='container-titles'>Tags</div>
                         <div className='container-titles-desc'>Enter tags to simplify filtering and<br/> finding this session</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                           <Form.Item 
                           label="Tags"
                           name="tags" 
                           >
                           {/* <Tags experienceData={_.get(experienceDetails, 'tags')}/> */}

                                     <Select
                             mode="multiple"
                             disabled = {searchParams.get('action') === 'called_off' ? true : false}
                             style={{ width: '100%' }}
                             placeholder={searchParams.get('action') === 'called_off' ? "" : 'Type and press Enter to add'}
                             value={selectValues}
                             defaultValue={selectValues}
                             onChange={(values) => setSelectValues(values)}
                             onSearch={handleSearch}
                             onKeyDown={handleEnterPress}
                           >
                             {selectValues.map((value) => (
                               <Option key={value} value={value}>
                                 {value}
                               </Option>
                             ))}
                           </Select>
                           </Form.Item>
                           
                           
                         </div>
                         </div>
                         <hr/>
                         <Form.Item>
                             { searchParams.get("action") !== "called_off" && <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end"}}>
                             <Button style={{marginRight: "15px", height: "40px"}}>Cancel</Button>
                             <Button htmlType="submit" style={{backgroundColor: "#EF6820", color: "#fff", height: "40px"}}>Save</Button>
                             </div>}
                           </Form.Item>
                       </Form>
                               </div>
                           )
                       }

                       {
                         selectedOption === "Registration" && (<RegistrationList/>)
                       }

                       {
                         selectedOption === "Onsite-facilitation"  && (<IntegratedTab experience_id={searchParams.get('id')} experience_name={_.get(experienceDetails, 'template.title')}/>)
                       }

                       {
                        selectedOption === "Leaderboard" && (<LeaderBoard/>)
                       }
                       
                      
                         
                     </div>
                         </div>
                     
                     
                     </div>
                 {/* </div> */}
              
             
             
                 
              </div>
              </div>
            }
                {
                  loading && <Spin
                  style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "24px", color: "#FF692E"}}
                  spinning={loading}
                 indicator={antIcon}
                />
                }
            {
              !loading && _.get(props, 'userProfile.id') !== _.get(experienceDetails, 'host') && <div className='container'>
                <div className='col-12'>
                   <Menus/>
                     <hr/>
                     <h4 style={{display: "flex", alignItems: "center", justifyContent: "center"}}>404 Not found</h4>
                 </div>
              </div>
            }
               
             
               
          </div>
        )
  }

  return render();
 
}

const mapStateToProps = state => {
  
    return {
      userProfile: _.get(state, 'app.host.profile.data.details'),
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    experienceViewAPI: details => dispatch(global.redux.action.experience.view(details)),
    updateSpacesAPI: details => dispatch(global.redux.action.experience.increaseMaxSlot(details)),
    updateExperienceAPI: details => dispatch(global.redux.action.experience.updateExperienceTag(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(EditExperiences)