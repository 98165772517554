import React, { useState, useEffect } from 'react';
import { Table, Button, Image, Divider, message } from 'antd';
import { FiPlusCircle } from "react-icons/fi";
import { connect } from 'react-redux';
import _ from  'lodash';
import moment from 'moment';
import CreateUpdateCoupon from "./create"
import momentTimezone from "moment-timezone"
import './style.css';
import './promo.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'antd/lib';
import AssignList from './assign-list';
import ViewPromotions from './view-promotions';

const PromotionList = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [tabActiveKey, settabActiveKey] = useState('1');
  let history = useNavigate();
  const[questionSetList, setQuestionList] = useState([]);
  const[questionExpiredList, setQuestionExpiredList] = useState([]);
  const[assignQuestion, setAssignQuestion] = useState(false);
  const[getQuestionSetId, setQuestionSetId] = useState();
  const [getQuestionName, setQuestionName] = useState('')
  const [timeZone, setTimeZone] = useState('');
  const [pasueModalShow, setPasueModalShow] = useState(false)
  const [detailsPasuse, setdetailsPasuse] = useState({});
  const [isAssign, setIsAssign] = useState(false);
  const [couponId, setCouponId] = useState();
  const [couponName, setCouponName] = useState();
  const [isViewCouponModalOpen, setIsViewCouponModalOpen] = useState(false);
  useEffect(() => {
    getLocationsData()
    
  }, []);

  const getLocationsData =async()=>{
    const { data, status } = await axios.get('https://ipapi.co/json')
    // setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
  await  setTimeZone(_.get(data,'timezone'))
  couponList();
    couponList('expired',_.get(data,'timezone'))
  }
  const onCopyURL = (value) => {
  
    navigator.clipboard.writeText(value)
    .then(() => {
      
      messageApi.open({
        type: 'success',
        content: 'Copied',
      });
    })
    .catch(err => {
      console.error('Error copying value:', err);
    });
  }
  const columns = [
    {
      title: <div className='questions-list-table-title'>Promo name</div>,
      dataIndex: 'name',
      key: 'name',
      width:"15%",
      render:(text,item)=>{
        return <div className='coupon-table-value-title'>{text}</div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Promo code</div>,
      dataIndex: 'coupon_code',
      key: 'coupon_code',
      width:"9%",
      render:(text,item)=>{
        return <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><span className='coupon-table-value-title' style={{lineHeight:'30px',marginRight:'8px'}}>{text}</span> <span onClick={()=>{
          onCopyURL(text)
        }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_9176_3320)">
          <path d="M10.6668 10.6668V12.5335C10.6668 13.2802 10.6668 13.6536 10.5215 13.9388C10.3937 14.1897 10.1897 14.3937 9.93882 14.5215C9.6536 14.6668 9.28023 14.6668 8.5335 14.6668H3.46683C2.72009 14.6668 2.34672 14.6668 2.06151 14.5215C1.81063 14.3937 1.60665 14.1897 1.47882 13.9388C1.3335 13.6536 1.3335 13.2802 1.3335 12.5335V7.46683C1.3335 6.72009 1.3335 6.34672 1.47882 6.06151C1.60665 5.81063 1.81063 5.60665 2.06151 5.47882C2.34672 5.3335 2.72009 5.3335 3.46683 5.3335H5.3335M7.46683 10.6668H12.5335C13.2802 10.6668 13.6536 10.6668 13.9388 10.5215C14.1897 10.3937 14.3937 10.1897 14.5215 9.93882C14.6668 9.6536 14.6668 9.28023 14.6668 8.5335V3.46683C14.6668 2.72009 14.6668 2.34672 14.5215 2.06151C14.3937 1.81063 14.1897 1.60665 13.9388 1.47882C13.6536 1.3335 13.2802 1.3335 12.5335 1.3335H7.46683C6.72009 1.3335 6.34672 1.3335 6.06151 1.47882C5.81063 1.60665 5.60665 1.81063 5.47882 2.06151C5.3335 2.34672 5.3335 2.72009 5.3335 3.46683V8.5335C5.3335 9.28023 5.3335 9.6536 5.47882 9.93882C5.60665 10.1897 5.81063 10.3937 6.06151 10.5215C6.34672 10.6668 6.72009 10.6668 7.46683 10.6668Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_9176_3320">
            <rect width="16" height="16" fill="white"/>
          </clipPath>
        </defs>
      </svg></span></div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Discount</div>,
      dataIndex: 'discount',
      key: 'discount',
      width:"5%",
      render:(text,item)=>{
        return <div className='coupon-table-value-title'>{text}</div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Promo start/end date</div>,
      dataIndex: 'date',
      key: 'date',
      width:"18%",
      render:(text,item)=>{
        return <div className='coupon-table-value-title'>{text}</div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Status</div>,
      dataIndex: 'status',
      key: 'status',
      render:(text,item) =>{
        return text=="Active"?<div className='coupon-active-status-ctn'>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
  <circle cx="4" cy="4" r="3" fill="#12B76A"/>
</svg>
          <div className='coupon-active-status-title'>{text}</div></div>:<div className='coupon-pause-status-ctn' style={{width:'85px'}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
  <circle cx="4" cy="4" r="3" fill="#F79009"/>
</svg>
            <div className='coupon-pause-status-title'>{text}</div></div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Quick Actions</div>,
      dataIndex: 'actions',
      key: 'actions',
      width:"40%",
      render: (text, item) => {
        
        return(
          <div style={{display: "flex", flexDirection: "row"}}>
            <div onClick={() => {assignPromo(item)}} className='question-set-quick-actions'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8985_3742)">
    <path d="M8.00016 5.33337V10.6667M5.3335 8.00004H10.6668M14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8985_3742">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg> Assign</div>
            <div 
            onClick={()=>{
              history(`/template-list?menu=template&coupon_id=${item?.key}`)
              props?.onCreate()
            }}
            style={{marginLeft: "32px"}} className='question-set-quick-actions'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_8985_3749)">
              <path d="M7.3335 2.66666H4.5335C3.41339 2.66666 2.85334 2.66666 2.42552 2.88464C2.04919 3.07639 1.74323 3.38235 1.55148 3.75867C1.3335 4.1865 1.3335 4.74655 1.3335 5.86666V11.4667C1.3335 12.5868 1.3335 13.1468 1.55148 13.5746C1.74323 13.951 2.04919 14.2569 2.42552 14.4487C2.85334 14.6667 3.41339 14.6667 4.5335 14.6667H10.1335C11.2536 14.6667 11.8137 14.6667 12.2415 14.4487C12.6178 14.2569 12.9238 13.951 13.1155 13.5746C13.3335 13.1468 13.3335 12.5868 13.3335 11.4667V8.66666M5.33348 10.6667H6.44984C6.77596 10.6667 6.93902 10.6667 7.09247 10.6298C7.22852 10.5972 7.35858 10.5433 7.47788 10.4702C7.61243 10.3877 7.72773 10.2724 7.95833 10.0418L14.3335 3.66666C14.8858 3.11437 14.8858 2.21894 14.3335 1.66666C13.7812 1.11437 12.8858 1.11437 12.3335 1.66665L5.95832 8.04182C5.72772 8.27242 5.61241 8.38772 5.52996 8.52228C5.45685 8.64157 5.40298 8.77163 5.37032 8.90768C5.33348 9.06113 5.33348 9.22419 5.33348 9.55031V10.6667Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_8985_3749">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg> Edit</div>
          {item?.isActive ==1 ?<div 
            onClick={()=>{
              setdetailsPasuse(item)
             setTimeout(() => {
              setPasueModalShow(true)
             }, 400);
            }}
            style={{marginLeft: "32px"}} className='question-set-quick-actions'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_8985_3801)">
              <path d="M6.3335 10.0002V6.00016M9.66683 10.0002V6.00016M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_8985_3801">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg> Pause</div>:
          <div 
            onClick={()=>{
              setdetailsPasuse(item)
             setTimeout(() => {
              setPasueModalShow(true)
             }, 400);
            }}
            style={{marginLeft: "32px"}} className='question-set-quick-actions'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_8985_3826)">
              <path d="M8.00016 14.6668C11.6821 14.6668 14.6668 11.6821 14.6668 8.00016C14.6668 4.31826 11.6821 1.3335 8.00016 1.3335C4.31826 1.3335 1.3335 4.31826 1.3335 8.00016C1.3335 11.6821 4.31826 14.6668 8.00016 14.6668Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.3335 5.97705C6.3335 5.65886 6.3335 5.49977 6.39999 5.41095C6.45794 5.33355 6.54664 5.28512 6.64308 5.27823C6.75375 5.27033 6.88758 5.35636 7.15523 5.52842L10.3023 7.55153C10.5346 7.70084 10.6507 7.7755 10.6908 7.87042C10.7258 7.95337 10.7258 8.04696 10.6908 8.1299C10.6507 8.22483 10.5346 8.29948 10.3023 8.44879L7.15523 10.4719C6.88758 10.644 6.75375 10.73 6.64308 10.7221C6.54664 10.7152 6.45794 10.6668 6.39999 10.5894C6.3335 10.5006 6.3335 10.3415 6.3335 10.0233V5.97705Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_8985_3826">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg> Resume</div>}
            </div>
        )
      }
    },
  ];

  const columnsExpired = [
    {
      title: <div className='questions-list-table-title'>Promo name</div>,
      dataIndex: 'name',
      key: 'name',
      width:"15%",
      render:(text,item)=>{
        return <div className='coupon-table-value-title'>{text}</div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Promo code</div>,
      dataIndex: 'coupon_code',
      key: 'coupon_code',
      width:"7%",
      render:(text,item)=>{
        return <div className='coupon-table-value-title'>{text}</div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Discount</div>,
      dataIndex: 'discount',
      key: 'discount',
      width:"5%",
      render:(text,item)=>{
        return <div className='coupon-table-value-title'>{text}</div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Promo start/end date</div>,
      dataIndex: 'date',
      key: 'date',
      width:"18%",
      render:(text,item)=>{
        return <div className='coupon-table-value-title'>{text}</div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Status</div>,
      dataIndex: 'status',
      key: 'status',
      render:(text,item) =>{
        return <div className='coupon-expired-status-ctn'>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
  <circle cx="4" cy="4" r="3" fill="#667085"/>
</svg>
            <div className='coupon-pause-status-title' style={{color:"#344054"}}>Expired</div></div>
      }
    },
    {
      title: <div className='questions-list-table-title'>Quick Actions</div>,
      dataIndex: 'actions',
      key: 'actions',
      render: (text, item) => {
        
        return(
          <div style={{display: "flex", flexDirection: "row"}}>
            <div onClick={() => handleViewCoupon(item)} className='question-set-quick-actions'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M1.61342 8.47562C1.52262 8.33186 1.47723 8.25998 1.45182 8.14911C1.43273 8.06583 1.43273 7.9345 1.45182 7.85122C1.47723 7.74035 1.52262 7.66847 1.61341 7.52471C2.36369 6.33672 4.59693 3.3335 8.00027 3.3335C11.4036 3.3335 13.6369 6.33672 14.3871 7.52471C14.4779 7.66847 14.5233 7.74035 14.5487 7.85122C14.5678 7.9345 14.5678 8.06583 14.5487 8.14911C14.5233 8.25998 14.4779 8.33186 14.3871 8.47562C13.6369 9.6636 11.4036 12.6668 8.00027 12.6668C4.59693 12.6668 2.36369 9.6636 1.61342 8.47562Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.00027 10.0002C9.10484 10.0002 10.0003 9.10473 10.0003 8.00016C10.0003 6.89559 9.10484 6.00016 8.00027 6.00016C6.8957 6.00016 6.00027 6.89559 6.00027 8.00016C6.00027 9.10473 6.8957 10.0002 8.00027 10.0002Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> View</div>
          
            </div>
        )
      }
    },
  ];

  const CreateQuestionSetIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
  );

  const couponList = (type,timeZone="") => {
    let params;
    if(type=="expired" && timeZone){
      var time = momentTimezone().tz(timeZone).format("HH:mm");
      const date2 = momentTimezone().tz(timeZone).format("YYYY-MM-DD");
      let end_date = momentTimezone(`${date2}T${time}`).tz(timeZone).toISOString()
      params={
        limit: 50,
        host:_.get(props,"userProfile.id"),
        expires_in:end_date,
        
      }
    }else{
     
      params={
        limit: 50,
        host:_.get(props,"userProfile.id"),
        sort:"created_at.desc",
        is_active:1
      
      }
    }
      props.couponList({
        params:params,
        callback: (res, data) => {
          
          if(res){
            if(_.size(_.get(data, 'data.items')) > 0){
              // setQuestionList(_.get(data, 'data.items'));
              let a =[]

              _.map(_.get(data,'data.items'),(value)=>{
                let convertedDateTime;
                if(value?.expires_in){
                  convertedDateTime =`${momentTimezone.tz(value?.starts_in,value?.timezone).format("MMM DD, YYYY")} - ${momentTimezone.tz(value?.expires_in,value?.timezone).format("MMM DD, YYYY")}`
                }else{
                  convertedDateTime =`${momentTimezone.tz(value?.starts_in,value?.timezone).format("MMM DD, YYYY")}`
                }
              

                
                  a.push({
                      key:_.get(value,'id'),
                      id:_.get(value,'id'),
                      status:_.get(value,'status')==1?"Active":"Paused",
                      discount:_.get(value,'amount_type')==0?`${_.get(value,'amount')} %`:`$ ${_.get(value,'amount')}`,
                      name:_.get(value,'label')?_.get(value,'label'):"Test discount",
                      coupon_code: _.get(value, 'coupon_code'),
                      date: convertedDateTime,
                      isActive:_.get(value,'status')
                      
                  })
              })
              if(type=="expired" && timeZone){
                setQuestionExpiredList(a)
              }else{
                setQuestionList(a)
              }
              
            }
          }
        }
      })
  }

  let locale = {
    emptyText: (
     <div style={{marginTop:'32px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <div>
<Image preview={false} style={{height:'128px',width:'172px'}} src={require('../../assets/img/template/Illustration.png')} />
      </div>
      <div className="empty-template-title" style={{marginTop:'16px',marginBottom:'8px'}}>No promotions found</div>
      <div className="empty-template-title-des" style={{width:'45%'}}>Promotions help your users take advantage of special offers and discounts .Get started by clicking the button below</div>
      <div style={{marginTop: "32px"}}>
          <button onClick={()=>{
             history(`/template-list?menu=template`)
              props?.onCreate()
            }} className='create-question-set-btn'><CreateQuestionSetIcon/> Create a promotion</button>
        </div>
     </div>
    )
  };

  const clickBackBtn = () => {
    setIsAssign(false)
  }

  const assignPromo = (value) => {
    setIsAssign(true)
    setCouponId(_.get(value, 'id'))
    setCouponName(_.get(value, 'name'))
    // setAssignQuestion(true);
    // setQuestionName(_.get(value, 'question_name'));
  };

  const handleViewCoupon = (value) => {
    setCouponId(_.get(value, 'id'))
    setIsViewCouponModalOpen(true)
  }

  const handleCancelViewModal = () => {
    setIsViewCouponModalOpen(false)
  }

  const handleOkViewModal = () => {
    setIsViewCouponModalOpen(false)
  }


  const changeStatus =(status)=>{
   
    props?.couponChangeStatus({
      body:{
        id:detailsPasuse?.id,
        status:status,
        status_glossary:"Paused"
      },
      callback:(res,data)=>{
       
      
        if(res==true){
          couponList();
          setdetailsPasuse({})
          setPasueModalShow(false)
        }
      }
    })
  }

  const render = () => {
    return (
      <div>
        {isAssign ? <AssignList coupon_id={couponId} coupon_name={couponName} clickBackBtn={clickBackBtn}/> :  <div>
        {contextHolder}
        <Modal centered={true} 
             
             footer={null} closeIcon={null} title={null} open={pasueModalShow} className="create-question-modal-container" style={{width:'435px'}}>
       <div style={{padding:'16px'}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <span><svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#F2F4F7"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9FAFB" stroke-width="8"/>
<path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</span>
<span style={{cursor:'pointer'}} onClick={()=>{
  setPasueModalShow(false)
              
}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="x-close">
<path id="Icon" d="M18 6L6 18M6 6L18 18" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
</span>

        </div>
        <div style={{marginTop:'16px'}}>
          <div className="create-question-modal-title">{detailsPasuse?.isActive==1?"Are you sure you want to pause this promotion?":"Are you sure you want to resume this promotion?"}</div>
          <div className="create-question-modal-des" style={{marginTop:'4px'}}>{detailsPasuse?.isActive==1?"Customers will not be able to use this code while its paused.":"Customers will be able to use this code once the promotion is resumed"}</div>
        </div>
        <div style={{marginTop:'16px',width:'97%'}}><Divider style={{padding:'0px',margin:'0px',}}/></div>

        <div style={{display:'flex',justifyContent:'flex-end',marginTop:'30px'}}>
        <div className="coupon-pause-modal-button" style={{backgroundColor:"#FFF",border:"1px solid var(--Gray-300, #D0D5DD)",marginRight:'12px'}} onClick={()=>{
  setPasueModalShow(false)
}}>
            <span className="coupon-pause-modal-button-text" style={{color:"#475467"}}>Cancel</span>
          </div>
          <div className="coupon-pause-modal-button" onClick={()=>{
  changeStatus(detailsPasuse?.isActive==1?0:1)
}}>
            <span className="coupon-pause-modal-button-text">{detailsPasuse?.isActive==1?"Pause promotion":"Resume promotion"}</span>
          </div>
        </div>
       </div>
      </Modal>
        {/* { assignQuestion &&  <div className='header-class-card-new' style={{ display: 'flex',justifyContent:'space-between',paddingLeft:'-4px',paddingBottom:'16px' }}>
            <div className="create-template-container">
               <Image onClick={()=>{
               setAssignQuestion(false)
                  props?.onBack() 
           


                     }} preview={false} src={require('../../assets/img/template/back.png')} style={{width:'48px',height:'48px',cursor:'pointer'}}/>
               <div className="create-template-header-title" style={{marginLeft:'6px'}}>Question assign list</div>
            </div>
           
            </div>} */}
        <div className='card' style={{paddingTop:'16px', marginTop: "16px", borderRadius: "12px", marginBottom: "40px"}}>

         {(_.size(questionSetList)>0 ||_.size(questionExpiredList)>0) &&  <div style={{display:'flex',paddingLeft:'16px'}}>
            <div className={tabActiveKey==1?"coupon-active-tab-container":"coupon-Inactive-tab-container"} 
            style={{borderTopLeftRadius:'8px',
          borderBottomLeftRadius:'8px'}}
            onClick={()=>{
settabActiveKey(1)
            }}><div 
            className={tabActiveKey==1?"coupon-active-tab-title":"coupon-Inactive-tab-title"}>Active</div> </div>
            <div onClick={()=>{
settabActiveKey(2)
            }}
            style={{borderTopRightRadius:'8px',
            borderBottomRightRadius:'8px'}}
            className={tabActiveKey==2?"coupon-active-tab-container":"coupon-Inactive-tab-container"}
            ><div className={tabActiveKey==2?"coupon-active-tab-title":"coupon-Inactive-tab-title"}>Expired</div> </div>
          </div>}
          {(_.size(questionSetList)>0 ||_.size(questionExpiredList)>0) &&<div style={{paddingTop:'16px'}}><Divider style={{margin:'0px'}} /></div>}
          {props?.createCouponEnabled==false &&  <div style={{paddingLeft:'16px',paddingTop:(_.size(questionSetList)>0 ||_.size(questionExpiredList)>0) ?'16px':"0px"}}>
          <div>
            <button onClick={()=>{
              props?.onCreate()
            }} className='create-question-set-btn'><CreateQuestionSetIcon/> Create a promotion</button>
          </div>
          <div style={{marginTop: "16px"}}>
               {tabActiveKey==1? <Table locale={locale} pagination={false} dataSource={questionSetList} columns={columns} />:
                <Table locale={locale} pagination={false} dataSource={questionExpiredList} columns={columnsExpired} />}
          </div>
          </div>}
          
        </div>
        {props?.createCouponEnabled==true && <CreateUpdateCoupon oncloseModal={()=>{
          couponList()
          props.oncloseCopuonModal()
        }} />}
         <div>
                            <Modal
                             width={1072} 
                             closable = {false} 
                             style={{borderRadius: "12px"}}
                             footer={null}
                             open={isViewCouponModalOpen} 
                             onOk={handleOkViewModal}
                             onCancel={handleCancelViewModal}
                             >
                                <div style={{padding: "32px"}}>
                                <div style={{display: "flex", justifyContent:"space-between"}}>
                                <div className='promotion_assign_title'>View your promotion</div>
                                <img onClick={() => handleCancelViewModal()} style={{height: "24px", width: "24px", cursor: "pointer"}} src={require('../../assets/img/template/x-close.png')}/>
                                </div>
                                <div style={{marginTop: "16px"}}>
                                <ViewPromotions id={couponId} onCancel={() => handleCancelViewModal()} props={props}/>
                                </div>
                                </div>
                              
                            </Modal>
                            </div>
        </div>}
       
      </div>
    )


  }
  return render();
}

const mapStateToProps = state => {
  return {
    userProfile: _.get(state, 'app.host.profile.data.details'),
  }
}

const mapDispatchToProps = dispatch => ({
  couponList: details => dispatch(global.redux.action.promotion.couponList(details)),
  couponChangeStatus: details => dispatch(global.redux.action.promotion.couponChangeStatus(details)),
  viewPromotionsAPI: details => dispatch(global.redux.action.promotion.viewPromotions(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(PromotionList)