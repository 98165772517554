import { EditFilled,DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, ArrowLeftOutlined, SaveFilled, ClockCircleFilled, ArrowRightOutlined, CheckOutlined, InfoCircleOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React,{useState,useEffect} from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, InputNumber,Modal,Image, Radio} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { head, set } from 'lodash';
import { connect } from 'react-redux'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import GoogleMapReact from 'google-map-react';
import ViewTemplate from '../view/index';
import Geocode from "react-geocode";


import '../../onboarding-screen/style.css'
import MainMenu from '../../main-menu'

import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
Geocode.setApiKey('AIzaSyBIU6YefaUI89t5f7LwsvY0z21sLmLdpYc');

const { Panel } = Collapse;
const {confirm} =Modal
const { Option } = Select;

const AnyReactComponent = ({ text }) => <div><Image width={35} preview={false} height={35} src={require('../../../assets/img/map-marker-icon.png')} /></div>;
const OpenSessionCreate =(props)=>{
    const [form] = Form.useForm();
    let history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [address_results, setaddress_results] = useState([]);
    const [address_fetching, setaddress_fetching] = useState(false);
    const [photo_display, setphoto_display] = useState(null);
    const [photo_display_details, setphoto_display_details] = useState({});
    const [clauseTitle, setclauseTitle] = useState('')
    const [clauseDes, setclauseDes] = useState('')
    const [clauseObject, setclauseObject] = useState({})
    const [clauseArray, setclauseArray] = useState([])
    const [pageLoading, setPageLoading] = useState(false);
    const [clauseInputShow, setClauseInputShow] = useState(true);
    const [fileList, setFileList] = useState([])
    const [buttonLoading,setButtonLoading] =useState(false)
    const [ModalVisable,setModalVisable] =useState(false)
    const [BackModalVisable,setBackModalVisable] =useState(false)
    const [TaxListData, setTaxListData] = useState([])
    const [categoriesList,setCategoriesList] =useState([])
    const [LocationData, setLocationData] = useState({lat: 11.937871, lng: 79.819953})
const [TemplateViewDetails, setTemplateViewDetails] = useState({})
const [TemplateViewDetailsMeta, setTemplateViewDetailsMeta] = useState({})
const [templateViewType, settemplateViewType] = useState('view')

const [templateTypeModal, setTemplateTypeModal] = useState(false);
const [getScheduledValue, setScheduledValue] = useState(false);
const [getOpenValue, setOpenValue] = useState(false);
const [getLeagueValue, setLeagueValue] = useState(false);
    const defaultProps = {
      center: {
        lat: 11.937871, lng: 79.819953
      },
      zoom: 15
    };

    useEffect(() => {
      getCategoriesList()
      getLocationsData()
    loadTaxList()
    if(searchParams.get('id')){
      templateView(searchParams.get('id'))
    }
    
    }, [searchParams.get('id')]);

    const showTemplateTypeModal = () => {
        setTemplateTypeModal(true);
      };

      const handleCancel = () => {
        setTemplateTypeModal(false);
      };


    const templateView =(id)=>{
      props.viewTemplate({
        params:{id:id,expand:'photos'},
        callback:(res,data)=>{
          if(res==true){
            setTemplateViewDetails(_.get(data,'data.details'))
            setTemplateViewDetailsMeta(_.get(data,'data.meta'))
            setFieldValue(data)
          }
        }
      })
    }
    const setFieldValue =(values)=>{
let details = _.pick(_.get(values,'data.details'), [
  'address', 'id', 'category', 'clauses', 'description',
  'max_no_of_openings','min_no_of_openings','host_price','tax_state','zip_code','photos','title'

]);

if (_.get(values,'data.details.address') && _.get(values,'data.details.location')) {
                    
  const address_results = [{
      text: _.get(values,'data.details.address') ,
      value: JSON.stringify({ text:  _.get(values,'data.details.address') , lat: _.get(values,'data.details.location.x'), lng:  _.get(values,'data.details.location.y'), postal_code:''}),
    }]
    form.setFieldsValue({
        address: JSON.stringify({ text:  _.get(values,'data.details.address') , lat: _.get(values,'data.details.location.x'), lng:  _.get(values,'data.details.location.y'), postal_code:''}),
                           })
    setaddress_results(address_results)
}

form.setFieldsValue({...details})
if(_.get(details,'clauses[0]')){
  setclauseArray(_.get(details,'clauses'))
  setClauseInputShow(false)
}
if(_.get(details,'photos[0]')){
  let set_photos =[]
_.map(_.get(details,'photos'),(value,index)=>{
  let make_url =`${_.get(values,'data.meta.photo.path')}/${_.get(values,'data.meta.photo.folder')}/${_.get(values,'data.meta.photo.sizes.medium')}/${_.get(value,'photo')}`

  set_photos.push( {
    uid: _.get(value,'id'),
    id: _.get(value,'id'),
    name: _.get(value,'photo'),
    status: 'done',
    url: make_url,
  },)
})
form.setFieldsValue({
  photo:set_photos
})
setFileList(set_photos)
}



    }
    const getLocationsData =async()=>{
      const { data, status } = await axios.get('https://ipapi.co/json')
      setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
      // defaultProps.center.lat=_.get(data,'latitude')
      // defaultProps.center.lng=_.get(data,'longitude')
     
    }
      
    const getCategoriesList  =()=>{
      props.list({
        callback:(res,data)=>{
          if(_.get(data,'data.items')){
            setCategoriesList(_.get(data,'data.items'))
          }
        }
      })
    }
    const loadTaxList =()=>{
props.taxList({
  params:{limit:100},
  callback:(res,data)=>{
if(res==true && _.get(data,'data.items[0]'))
    setTaxListData(_.get(data,'data.items'))
  }
})
    }
    

    const fetchPostalCode = address_components => {
        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        
        if(postal_code){
          form.setFieldsValue({
            zip_code:postal_code
          })
        }
        
        return postal_code;
    };
    const findLocationLocality = (addressComponentsArray, levelArray) => {
      
      for (let i = 0; i < addressComponentsArray.length; ++i) {
          for (let j = 0; j < levelArray.length; j++) {
              if (addressComponentsArray[i].types.indexOf(levelArray[j]) > -1) {
                  return {
                      locality_level: levelArray[j],
                      value: addressComponentsArray[i].long_name
                  }
              }
          }
      }
  }
const setTaxValuesTigger =(data)=>{
  let stateName = findLocationLocality(
    data,
    [
        'administrative_area_level_1'
    ]
).value

let sameState = _.filter(TaxListData, m => _.lowerCase(m.state) == _.lowerCase(stateName) && m)

let getTaxState = _.head(sameState)
if (_.get(getTaxState, 'id')) {
  
  form.setFieldsValue({
    tax_state:_.get(getTaxState, 'id')
  })
}
}

  //Get Zipcode Action
  const locationZipcode = (x, y) => {
      Geocode.fromLatLng(x, y)
          .then(json => {
            
            var addressComponent = json.results[0].formatted_address;
            setTaxValuesTigger(json.results[0].address_components)
             form.setFieldsValue({
               zip_code:fetchPostalCode(addressComponent)
             }) 
             
              
          })
          .catch(error => console.warn(error));
  }
    
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 50);
    };
    const removeImage = info => {
      
      if(_.get(info,'id')){
        props.deletePhoto({
          params:{id:searchParams.get('id')},
          body:{ids:[_.get(info,'id')]}
          ,callback:(res,data)=>{
            if(res){

            }
          }
        })
      }
    };
    
    const getBase64 = async (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const checkImageWidth = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
              resolve({width:image.width,height:image.height});
              return {width:image.width,height:image.height};
            };
            reader.onerror = (err) => reject(err);
          };
        });
      };
      const isJson=(str)=> {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      }
      const updatePhoto = (data) => {
        return new Promise(resolve => {
        if (_.get(photo_display_details, 'originFileObj')) {
            let formData = new FormData()
            formData.append('photo', _.get(photo_display_details, 'originFileObj'))
            props.UpdatePhotoData({
                body: formData,
                callback: (res, responseData) => {
                    
                    if (res == true) {
                        // notification.success(
                        //     { message: 'Success', description: _.get(responseData, 'message') }
                        // )  
                    }
                        resolve();
                }
            });
        }
        else{
            resolve();
        }
    })
    };
    const onChangePhoto = ({ fileList: newFileList })  => {
      setFileList(newFileList)
      form.setFieldsValue({
        photo:newFileList
      })
        
    };
    const onFinish =(e)=>{
        
        let request_details={...e}
    if (isJson(_.get(e,'address'))) {
      let location_parse = JSON.parse(_.get(e,'address'))
      request_details.location=`${_.get(location_parse,'lat')},${_.get(location_parse,'lng')}`
      request_details.address = _.get(location_parse,'text')
    }
    delete request_details['email']
    request_details['clauses']=clauseArray
    request_details['min_no_of_openings']=1
    request_details['mode']=1
    
    
    setButtonLoading(true)
    let url = searchParams.get('id')?'updateTemplate':'createTemplate'
    if(searchParams.get('id')){
      request_details.id=searchParams.get('id')
      request_details['price']=_.get(request_details,'host_price')
    }
    props[url]({
      body:{...request_details},
      callback:(res,data)=>{

    if(url=="createTemplate" && res === true){
      history('/template-list?menu=template')
    }
    
    
    if (_.get(data,'details.id')) {
      if(_.get(e,'photo')){
        addPhotoTemplateAPI(_.get(e,'photo'),_.get(data,'details.id'))
      }else{
        setButtonLoading(false)
        addPhotoTemplateAPI([],_.get(data,'details.id'))
      }
    }else{
     
    }
    
      }
    })
    
      }

      const addPhotoTemplateAPI =(photo,id)=>{
let final_photos =[]
        if(searchParams.get('id')){
_.map(photo,(value,i)=>{
  if(!_.get(value,'id')){
    final_photos.push(value)
  }
})
}else{
  _.map(photo,(value,i)=>{
    
      final_photos.push(value)
  })
}
if(_.size(final_photos)==0){
  notification.success({
    message:'Success',
    description: `Template has been ${searchParams.get('id')?'updated':'created'} successfully.`
 
   })
   settemplateViewType('view')
   templateView(id)
   setModalVisable(true)
   setTimeout(() => {
    // setModalVisable(false)
    if(!searchParams.get('id')){
      history(`/template-list?menu=template`)
     }
     
     
   }, 1400);
   
   setButtonLoading(false)
}else{
  
  
  updateImageAPI(final_photos,id)
       
      }
      }
      const updateImageAPI =(photos,id)=>{
        
        if(_.isArray(photos)==true && _.size(photos)>0){
          let a =_.head(photos)
          updateImage(a,id,photos)
        }else{
             notification.success({
                message:'Success',
                description: `Template has been ${searchParams.get('id')?'updated':'created'} successfully.`
             
               })
               setModalVisable(true)
 
               settemplateViewType('view')
               templateView(id)
               setTimeout(() => {
                setModalVisable(false)
                if(!searchParams.get('id')){
                  history(`/template-list?menu=template`)
                 }
                 
                 
               }, 1400);
               setButtonLoading(false)
        }
      
      }

      const updateImage =(value,id,photos)=>{
       let p =[...photos]
       
          let formData = new FormData()
        // formData.append('id', id)
        formData.append('photo', _.get(value, 'originFileObj'))
        props.addPhotoTemplate({
          body:formData,
          params:{id:id},
          callback:(res,data)=>{
            if(res==true){
             
              // var pulled =photos.shift()
              let a =p.slice(1,_.size(p))
              

              updateImageAPI(_.size(p)>0?a:[],id)
            }
           
          }
        })
       
      }
    
       //Address search
       const fetchAddress = value => {
        setaddress_results([])
        setaddress_fetching(true);
        geocodeByAddress(value).then(body => {
            
            const address_results = _.map(body, location => ({
                text: location.formatted_address,
                value: JSON.stringify({ text: location.formatted_address, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
            }));
    
            setaddress_results(address_results)
            setaddress_fetching(false);
    
        }).catch(error => {
            setaddress_results([])
            setaddress_fetching(true);
        });
    };
    //Initializing debounce for address
    const addressDelayedQuery = _.debounce(q => fetchAddress(q), 1200);
    

    const setClauseDetails =()=>{
        let details =[...clauseArray]

        if(clauseTitle && clauseDes){
          let duplicate = _.some(details,{name:clauseTitle,description:clauseDes})      
          if(duplicate==false) {
            details.push({name:clauseTitle,description:clauseDes})
          }else{
            notification.error({
              message:'Error',
              description:'This clauses title and description is already taken.'
            })
          }
            
          }
            
setclauseObject({})
            setclauseDes('')
            setclauseTitle('')
            setclauseArray(details)
            setClauseInputShow(false)
        }
       
    

    const onEditClasuess =async(value, type,index)=>{

if(type=="edit"){
  setclauseObject(value)
  setclauseTitle(_.get(value,'name'))
  setclauseDes(_.get(value,'description'))
let remove = _.remove(clauseArray,(e,i)=>i==index)

  setclauseArray(clauseArray)
  
  setClauseInputShow(true)
}else{
  let details =[...clauseArray]
  let remove = _.remove(details,(e,i)=>i==index)
  setclauseArray(details)   

  setTimeout(() => {
    setclauseArray(details)   
  }, 200);
 

}


    }

    const loadClausesList =(data)=>{
      
      return  _.map(data,(value,index)=>{
return <div key={index}>
   
   <div className="name-details">
       <span className="name-details-title">Title</span>
       <span>{_.get(value,'name')}</span>
   </div>
   <div className="name-details">
   <span className="name-details-title">Description
</span>
       <span>{_.get(value,'description')}</span>
   </div>
   <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
  <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>onEditClasuess(value,'edit',index)}>
    <EditFilled size={23}  style={{marginRight:7,fontSize:20}}/> Edit</div> 
   <div onClick={()=>onEditClasuess(value,'delete',index)}  style={{display:'flex',alignItems:'center',cursor:'pointer'}}><DeleteFilled 
    size={23}   style={{marginLeft:23,marginRight:7,fontSize:20}} /> Delete</div> 
   </div>
   
   </div>
        })
    }
    const onChangeForm =(value,type)=>{
let josn_data = JSON.parse(value)
defaultProps.center.lat= _.get(josn_data,'lat')
defaultProps.center.lng= _.get(josn_data,'lng')
setLocationData({lat: _.get(josn_data,'lat'),lng:_.get(josn_data,'lng')})
if(_.get(josn_data,'lat') &&_.get(josn_data,'lng')){
  locationZipcode(_.get(josn_data,'lat'),_.get(josn_data,'lng'))
}

      
    }

    const onChangeBack =()=>{
      if(searchParams.get('id')){
      let getFormsValues =form.getFieldsValue([
        'category', 'description',
        'max_no_of_openings','host_price','tax_state','zip_code','title','address'
      
      ])
      let oldFormData = _.pick(TemplateViewDetails,[ 'category', 'description',
      'max_no_of_openings','host_price','tax_state','zip_code','title','address'])
      if(_.isEqual(getFormsValues,oldFormData)==false){
        setBackModalVisable(true)
      }else{
        setBackModalVisable(false)
        history('/template-list?menu=template')
      }
    }else{  history('/template-list?menu=template')

    }
    };

    const onClickScheduledRadio = () => {
        setScheduledValue(true);
        setOpenValue(false);
        setLeagueValue(false)
    }
    const onClickOpenRadio = () => {
        setOpenValue(true);
        setScheduledValue(false);
        setLeagueValue(false);
    }
    const onClickLeagueRadio = () => {
        setLeagueValue(true);
        setScheduledValue(false);
        setOpenValue(false);
    }

    const render =()=>{
        const uploadButton = (
            <div>
               <FileImageOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          );
        return (<> <MainMenu {...props} templateTab={true}/>
          <div style={{marginTop:'11%'}}>
           
          
        <div className="coach-full-conatiner">
            <Form form={form} onFinishFailed={(e)=>console.log('onFinishFailed',e)} onFinish={(e)=>onFinish(e)}>
            <div className="coach-info-conatiner-first">
            <div style={{display:'flex',flexDirection:'row',marginBottom:23,justifyContent:'space-between'}}>
            
            <Button onClick={()=>onChangeBack()} disabled={buttonLoading} className='form-btn-4-back'  style={{backgroundColor:'#a7a7a7'}}
             ><div style={{fontSize:'15px',fontWeight:'600'}}><ArrowLeftOutlined className='back-arrow-icon' /><span>Back</span></div></Button>
            <div style={{display: "flex", alignItems: "center"}}>
             {(searchParams.get('id')==null || templateViewType=="edit")? <Button loading={buttonLoading} onClick={()=>form.submit()}  className='form-btn-4' style={{backgroundColor:'#e85f25'}}
             ><span><SaveFilled /> Save</span></Button>:<Button onClick={()=>settemplateViewType('edit')} className='form-btn-4' style={{backgroundColor:'#e85f25'}}
             ><EditFilled /> Edit</Button>}
             <Button onClick={()=>history(`/communiti/schedule?id=${searchParams.get('id')}`)} disabled={buttonLoading} style={{backgroundColor:'#787878',width:'52%'}} className='form-btn-4-schedule'
             ><ClockCircleFilled color='#a7a7a7' />Schedule</Button>
            </div>
                   
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'900',fontSize:'27px'}}>Template</span>
                   
                </div>
                  <Modal centered={true} maskClosable
                 onCancel={()=>setBackModalVisable(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={BackModalVisable}>
                  <div style={{    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',}}>
                  <span style={{    fontSize: '29px',fontWeight: '700'}}>You have unsaved changes to your Template</span>
                  <span style={{fontSize: '17px',alignItems: 'center',textAlign: 'center',width: '82%'}}>Do you wish to discard your changes?
                  </span>
                  </div>
                  <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
    <Button onClick={()=>setBackModalVisable(false)} className='form-btn-4 unsaved-btn' style={{backgroundColor:'#a7a7a7',marginRight:23,width:'23%'}}
             >Cancel</Button>
                  <Button onClick={()=>history('/template-list?menu=template')} className='form-btn-4 unsaved-btn' style={{backgroundColor:'#e85f25',width:'23% !important'}}
             >Discard Changes</Button></div>
                  </Modal>
               { searchParams.get('id')?
                  <Modal centered={true}
                 onCancel={()=>setModalVisable(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={ModalVisable}>
                  <div style={{    display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',}}>
                  <span style={{    fontSize: '29px',fontWeight: '700'}}>Your template has been updated</span>
                  <span style={{fontSize: '17px',alignItems: 'center',textAlign: 'center',width: '82%'}}>Your template has been updated and will reflect in new sessions that you schedule. Please note that sessions that have already been scheduled will not reflect these new changes.</span>
                  </div>
                  <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
                  <Button onClick={()=>setModalVisable(false)} className='form-btn-4' style={{backgroundColor:'#e85f25'}}
             >Continue</Button></div>
                  </Modal>:
                     <Modal centered={true}
                   onCancel={()=>setModalVisable(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={ModalVisable}>
                    <div style={{    display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',}}>
                    <CheckOutlined className='check-right'/>
                    <span style={{    fontSize: '29px',fontWeight: '700'}}>Your Templated Has Been Created & Saved</span>
                    </div>
                    <div style={{ display: 'flex',
      justifyContent: 'center',
      marginTop: '32px'}}>   
                    <Button onClick={()=>setModalVisable(false)} className='form-btn-4' style={{backgroundColor:'#e85f25'}}
               >Continue <ArrowRightOutlined /></Button></div>
                    </Modal>}
                {(searchParams.get('id')==null || templateViewType=="edit") ?<div style={{width: "100%", float: "left"}}> 
                <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
                    <div className='open-session-title'>
<div className="title-style">
Template Type
</div>
<div onClick={() => showTemplateTypeModal()} className="title-style" style={{marginLeft: "35px"}}>
What is this? {<InfoCircleOutlined />}
</div>
</div>
<Form.Item name='template_type'   rules={[
          {
            required: true,
            message: 'Please input your Categories!',
          },
        ]}><Select
    optionFilterProp="children">
   <Option key={"sheduled_type"} value={"scheduled"}>Scheduled Session</Option>
   <Option key={"open_session_type"} value={"open"}>Open Session</Option>
   <Option key={"league_type"} value={"league"}>League</Option>
  </Select></Form.Item>

                    </div>
                </div>
                </div>
</div>
                <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
   Template Name
</div>
<Form.Item name='title'   rules={[
          {
            required: true,
            message: 'Please input your title!',
          },
        ]}><Input /></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Categories
</div>
<Form.Item name='category'   rules={[
          {
            required: true,
            message: 'Please input your Categories!',
          },
        ]}><Select
    optionFilterProp="children">
   {_.map(categoriesList,(v,i)=>{
     return  <Option key={i} value={_.get(v,'id')}>{_.startCase(_.get(v,'name'))}</Option>
   })}
  </Select></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Description
</div><Form.Item name='description'   rules={[
          {
            required: true,
            message: 'Please input your description!',
          },
        ]}><TextArea /></Form.Item>


                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Address
</div>
<Form.Item
                                name="address"
                                rules={[{ required: true, message: 'Please input your address!' }]}
                            >
<Select getPopupContainer={trigger => trigger.parentNode}
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    onSearch={search => search ? addressDelayedQuery(search) : ''}
                                      onChange={(e) => onChangeForm(e, 'address')}
                                    placeholder={'Search address...'}
                                    notFoundContent={
                                        address_fetching ? (
                                            <Spin size="small" />
                                        ) : null
                                    }
                                >
                                    {address_results && address_results.length > 0 && address_results.map(d => (
                                        <Select.Option key={d.value}>{d.text}</Select.Option>
                                    ))}
                                </Select>
                                </Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Province / State</div>
<Form.Item name='tax_state'   rules={[
          {
            required: true,
            message: 'Please input your Province / State!',
          },
        ]}>
          <Select getPopupContainer={trigger => trigger.parentNode}
                                    placeholder={'Search Province / State...'}
                                >
                                    { _.map(TaxListData,(d,i) => (
                                        <Select.Option  key={_.get(d,'id')}>{_.startCase(_.get(d,'state'))}</Select.Option>
                                    ))}
                                </Select>
        </Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Postal / Zip Code</div>
<Form.Item name='zip_code'   rules={[
          {
            required: true,
            message: 'Please input your Postal / Zip Code!',
          },
        ]}><Input /></Form.Item>

                    </div>
                </div>
                </div>

</div>

<div style={{ height: '55vh', width: '100%',borderRadius:'23px',marginTop:'23px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBIU6YefaUI89t5f7LwsvY0z21sLmLdpYc" }}
        defaultCenter={LocationData}
        defaultZoom={defaultProps.zoom}
        center={LocationData}
      >
        <AnyReactComponent
          lat={_.get(LocationData,'lat')}
          lng={_.get(LocationData,'lng')}
          
          text="My Marker"
        />
      </GoogleMapReact>
    </div>


    
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Maximum # of Allowed Registrants</div>
<Form.Item name='max_no_of_openings'   rules={[
          {
            required: true,
            message: 'Please input your Max no of openings!',
          },
        ]}><InputNumber /></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Price per Registrant</div>
<Form.Item name='host_price'   rules={[
          {
            required: true,
            message: 'Please input your price!',
          },
        ]}><InputNumber  addonBefore="$" 
        /></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div style={{marginTop:'23px'}}><span style={{fontWeight:'700',fontSize:'20px'}}>Agreement Clauses</span>
                {clauseArray &&_.size(clauseArray)>0 &&<div > <div className="first-container-1" style={{marginTop:23}}>
<div className="second-container">

 {loadClausesList(clauseArray)}</div> </div>{<div onClick={()=>setClauseInputShow(true)} className='add-another-container'><PlusSquareFilled style={{fontSize:23,marginRight:7}}/><span>Add Another</span></div>}</div>}

 {(_.size(clauseArray)==0 || clauseInputShow) &&<div className='agreement-clauses-data'>
 <div className="first-container-1" style={{marginTop:23,padding: '1px 28px'}}>
                
               
               {(_.size(clauseArray)==0 || clauseInputShow) && <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Clause title
</div>
<div><Input value={clauseTitle} onChange={(e)=>setclauseTitle(_.get(e,'target.value'))} /></div>

                    </div>
                </div>
                </div>
</div>}
{(_.size(clauseArray)==0 || clauseInputShow) &&<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Clause Description
</div>
<div><TextArea value={clauseDes} onChange={(e)=>setclauseDes(_.get(e,'target.value'))} /></div>

                    </div>
                </div>
                </div>
                <div   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"end",display:'flex',marginTop:'2%',marginBottom:'17px'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button className='form-btn-4'
            //  onClick={()=>props.onChangeTab('3')}
             onClick={()=>setClauseDetails()}
            //  htmlType="submit"
             > <SaveFilled />{_.isEmpty(clauseObject)?"Add":'Update'} </Button>
              {!_.isEmpty(clauseObject) &&<Button className='form-btn-4'
              style={{backgroundColor:'#ababab',marginLeft:15}}
            //  onClick={()=>props.onChangeTab('3')}
             onClick={()=>setClauseDetails()}
            //  htmlType="submit"
             >Cancel</Button>}
            {/* </div> */}
            </div>
</div>
    }
                </div>  </div> }</div>              
               
                <div className="first-container-1" style={{marginTop:43}}>
                <span style={{fontWeight:'700',fontSize:'20px'}}>Pictures</span>
               
                <Form.Item name='photo' className='upload-template-image'  rules={[
          {
            required: false,
            message: 'Please input your photo!',
          },
        ]}> <Upload
        accept=".jpg,.jpeg"
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        fileList={fileList}
        onRemove={removeImage}
        onChange={onChangePhoto}
        customRequest={dummyRequest}
    >
        {photo_display ? <img src={photo_display} alt="avatar" style={{ width: '100%',height:'100%', borderRadius: '20px' }} /> : uploadButton}

    </Upload>
</Form.Item>
                  
                </div>
                </div>:<ViewTemplate template={{details:TemplateViewDetails,photos:fileList,clasuse:clauseArray}} />}
            </div>
           
            </Form>
              <Modal centered={true} footer={null} title="Selecting the Appropriate Template Type" open={templateTypeModal} onCancel={() => handleCancel()}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Radio.Group  defaultValue="a" size="large">
            <Radio.Button onClick={() => onClickScheduledRadio()} value="a">Scheduled</Radio.Button>
            <Radio.Button onClick={() => onClickOpenRadio()} value="b">Open</Radio.Button>
            <Radio.Button onClick={() => onClickLeagueRadio()} value="c">League</Radio.Button>
            </Radio.Group>
             </div>
             {
                getScheduledValue === true ? 
                <div className='session-type-modal' style={{marginTop:"35px"}}>
             <p>Use a scheduled template when you have exact dates and times that users can book a session with you.</p>
             <p> This template type is best for programs, or when you have booked a space and know definitively that you will be available at that time.</p>
             </div> : getOpenValue === true ? <div className='session-type-modal' style={{marginTop:"35px"}}>
             <p>Use an open template when you want to leave a session schedule open ended For example, you offer private coaching lessons, but want to coordinate with the user to settle on a date and time. </p>
             <p>This template type is best for private lessons where you work with your client to find days and times that work best for the two of you.</p>
             </div> : getLeagueValue === true ? <div className='session-type-modal' style={{marginTop:"35px"}}>
             <p>Use a league template when you are setting up specific tournament style sessions. For example, you’ve booked a court, field or rink for a specific day and want to allow for people to sign up to play.</p>
             <p> League templates are best suited towards one-day tournaments, or pick-up style games for teams.</p>
             </div> : <div className='session-type-modal' style={{marginTop:"35px"}}>
             <p>Use a scheduled template when you have exact dates and times that users can book a session with you.</p>
             <p> This template type is best for programs, or when you have booked a space and know definitively that you will be available at that time.</p>
             </div>
             }
             
      </Modal>
        </div>
        </div></>)
    }

    return render()
}
const mapStateToProps = state => {
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),
       guestUserProfile: _.get(state, 'app.guest.profile.data.details')
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
    list: details => dispatch(global.redux.action.categories.list(details)),
    hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
    createTemplate: details => dispatch(global.redux.action.template.create(details)),
    updateTemplate: details => dispatch(global.redux.action.template.update(details)),
    addPhotoTemplate: details => dispatch(global.redux.action.template.addPhoto(details)),
    taxList: details => dispatch(global.redux.action.tax.list(details)),
    getLocations: details => dispatch(global.redux.action.host.getLocation(details)),
    viewTemplate: details => dispatch(global.redux.action.template.view(details)),
    deletePhoto: details => dispatch(global.redux.action.template.deletePhoto(details)),
    

   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(OpenSessionCreate)