import _ from 'lodash';

import tools from '../../tools';
import chatKittySession from '../session';
import authentication from '../../authentication';

const initialyzeChatKitty=(criteria, guest_profile_details)=>{
    
    const chat_kitty_user_data =tools.getChatUserData(guest_profile_details);
    const handlingExceptionalErrors=(data, callback)=>{
        if(_.chain(data).get('error.error').eq('AccessDeniedError').value()){
            registerChatKittyAccountForGuest(callback);
        }else{
            
        }
    };

    const registerChatKittyAccountForGuest=(callback=()=>{})=>{
        authentication.register({
            userData: chat_kitty_user_data,
            callback: ()=>{
                signInChatKittyAccountForGuest((response, chatKittyUserSessionData)=>{
                    if(response){
                        callback(criteria, chatKittyUserSessionData);
                    }else{
                        // 
                    }
                });
            }
        });
    };
    const signInChatKittyAccountForGuest=(callback=()=>{})=>{
        chatKittySession.start({
            userData: {...chat_kitty_user_data,id:guest_profile_details?.id},
            callback: (response, data)=>{
                if(response){
                    callback(criteria, data);
                }else{
                    handlingExceptionalErrors(data, callback);
                }
            }
        });
    };

    if(!_.chain(guest_profile_details).get('chatkitty_user_id').isEmpty().value()){
        signInChatKittyAccountForGuest();
    }else{
        registerChatKittyAccountForGuest();
    }

};

export default initialyzeChatKitty;