import { Box, Card, CardContent, Container, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import RightSideHeader from "./right-side-header";
import RightFooter from "./right-footer";



const ConnectStripe =(props)=>{
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const baseUrl = `${url.protocol}//${url.hostname}`;
  console.log('sjhgcfdgf', baseUrl);
  let url_change = baseUrl == 'https://communitiweb.app'?'https://communitiweb.app' : baseUrl == 'https://club.communiti.app' ? 'https://club.communiti.app' : 'https://coach.communiti.app'
  let STRIPE_URL =`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${url_change}/communiti/onboarding&client_id=ca_J0I6HLfkszlobgVH4CIriHJMhl6Yn7hc`
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if(searchParams.get('code')){
    ConnecttoStripe(searchParams.get('code'))
    }
  }, []);

  const ConnecttoStripe =(code) =>{
    props.stripe_onboard_connect({
      body:{code:code},
      callback:(res,data)=>{
        if(res==true){
          props.hostProfileData({
            callback:(res,data)=>{
             if(_.get(data,'data.details')){
          // history('/communiti/coach-onboard-complete')
          props?.onChangeFlow(6)

             }}})
          
        }
      }
    })
  }
 
return <Box sx={{width:'100%',height:'100%'}}  >
<RightSideHeader />
<Box component={'div'} sx={{width:"100%", display:'flex',justifyContent:'center',height:'80%',}}> 
     <Box sx={{width:{
            xs: "90%", // mobile
            sm: "80%", // tablet
            md: "70%", // small desktop
            lg: "65%",
     }
     ,height:'100%',display:'flex',justifyContent:'center',flexDirection:'column'}}>
<Box mt={5} textAlign="center">
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FDEAD7"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF6EE" stroke-width="8"/>
<path d="M21 25V33M25.5 25V33M30.5 25V33M35 25V33M19 34.6L19 35.4C19 35.9601 19 36.2401 19.109 36.454C19.2049 36.6422 19.3578 36.7952 19.546 36.891C19.7599 37 20.0399 37 20.6 37H35.4C35.9601 37 36.2401 37 36.454 36.891C36.6422 36.7952 36.7951 36.6422 36.891 36.454C37 36.2401 37 35.9601 37 35.4V34.6C37 34.04 37 33.7599 36.891 33.546C36.7951 33.3579 36.6422 33.2049 36.454 33.109C36.2401 33 35.9601 33 35.4 33H20.6C20.0399 33 19.7599 33 19.546 33.109C19.3578 33.2049 19.2049 33.3579 19.109 33.546C19 33.7599 19 34.04 19 34.6ZM27.6529 19.0772L20.2529 20.7216C19.8059 20.8209 19.5823 20.8706 19.4155 20.9908C19.2683 21.0969 19.1527 21.2409 19.0811 21.4076C19 21.5965 19 21.8255 19 22.2835L19 23.4C19 23.9601 19 24.2401 19.109 24.454C19.2049 24.6422 19.3578 24.7952 19.546 24.891C19.7599 25 20.0399 25 20.6 25H35.4C35.9601 25 36.2401 25 36.454 24.891C36.6422 24.7952 36.7951 24.6422 36.891 24.454C37 24.2401 37 23.9601 37 23.4V22.2835C37 21.8255 37 21.5965 36.9188 21.4076C36.8473 21.2409 36.7317 21.0969 36.5845 20.9908C36.4177 20.8706 36.1942 20.8209 35.7471 20.7216L28.3471 19.0772C28.2176 19.0484 28.1528 19.034 28.0874 19.0282C28.0292 19.0231 27.9708 19.0231 27.9126 19.0282C27.8472 19.034 27.7824 19.0484 27.6529 19.0772Z" stroke="#EF6820" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



</Box>
<Typography component={"div"} sx={{
color:"#101828",
marginTop:'32px',
fontSize: {
       xs: "17px", // mobile
       sm: "19px", // tablet
       md: "23px", // small desktop
       lg: "30px",
     },
     fontWeight: "600",
     lineHeight: {
        xs: "28px", // mobile
        sm: "30px", // tablet
        md: "33px", // small desktop
        lg: "38px",
      },
}} align="center" >
Connect your bank account to Stripe
</Typography>
<Typography component={"div"} align="center"
sx={{
marginTop:'12px',
color:"#475467",
fontSize: {
       xs: "14px", // mobile
       sm: "15px", // tablet
       md: "16px", // small desktop
       lg: "16px",
     },
     
}}
>
Communiti partners with stripe to process all payments
</Typography>
{<Box sx={{marginTop:'12px',
   borderRadius: "12px",
   background: "var(--Warning-50, #FFFAEB)",
   padding: "4px 10px 4px 4px" 
}}>
  <Typography component={"div"} align="left"
sx={{

color:"var(--Warning-700, #B54708)",
fontSize: {
       xs: "11px", // mobile
       sm: "11px", // tablet
       md: "12px", // small desktop
       lg: "12px",
     },
     
}}
>
You will now be redirected to Stripe’s secure platform to fill in your banking information.
</Typography></Box>}

<Box component={"div"} sx={{
  marginTop:"32px",
  padding:"10px 18px", 
  // height:'44px',
  borderRadius: "8px",
  border:"1px solid var(--Orange-500, #EF6820)",
  background: "var(--Orange-500, #EF6820)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  cursor:"pointer"
}}
onClick={()=>{
  // props?.onChangeFlow(6)
  window.location.href=STRIPE_URL
}}
>
<Typography component={"div"} align="center"
sx={{

color:"var(--Base-White, #FFF)",
fontSize: {
       xs: "14px", // mobile
       sm: "15px", // tablet
       md: "16px", // small desktop
       lg: "16px",
     },
     fontWeight:"600"
     
}}
>
Continue</Typography>


</Box>
</Box>
</Box>
<RightFooter />
</Box>

}

const mapStateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => ({
  stripe_onboard_connect: details => dispatch(global.redux.action.account.stripeOnboardWeb(details)),
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(ConnectStripe)