import hostAction from './host';
import guestAction from './guest';
import accountAction from './account';
import categorieAction from './categories';
import templateAction from './templates';
import taxAction from './tax';
import experienceAction from './experiences';
import financeAction from './finances';
import leagueAction from './league';
import settingsAction from './settings';
import notificationsAction from './notification';
import userHandleAction from './userHandle';
import sendEmailAction from './sendEmail';
import questions from './questions';
import promotion from './promotion';
import otpLogin from './otp-login';

export const reduxAction = {
  host: hostAction,
  guest: guestAction,
  account: accountAction,
  categories:categorieAction,
  template:templateAction,
  tax:taxAction,
  experience:experienceAction,
  finances:financeAction,
  league:leagueAction,
  notifications:notificationsAction,
  settings:settingsAction,
  userHandle:userHandleAction,
  sendEmail:sendEmailAction,
  questions:questions,
  promotion:promotion,
  otpLogin:otpLogin
  


};
export default reduxAction;