import React from 'react';

import { Menu, Button } from 'antd';
import LayoutContent from '../Content';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import "./footer.css";

class PageFooter extends React.Component {
    render() {
        return (
            <div className="Footer">
                <div className="container">
                    <div className="footerLeft">
                        <Link to="/">Terms & Conditions | </Link>
                        <Link to="/">Privacy Policy | </Link>
                        <Link to="/">Support</Link>
                    </div>
                    <div className="copyRights">
                        © 2021 Wardbord. All rights reserved.
                    </div>
                </div>
            </div>
        );
    }
}

export default PageFooter;