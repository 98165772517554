import React, {useEffect, useState} from 'react';
import { Tabs, Button, Switch, Modal, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import _ from 'lodash';
import { connect } from 'react-redux';

import '../../onboarding-screen/style.css';
import "./onsite.css";
import RosterTab from '../session/rosterTab'
import MainMenu from '../../main-menu';
import CourtsTab from '../session/courts-tab';
import BuildTeams from '../session/build-teams-tab';
import BuildMatchSchedule from '../session/buildMatchSchedule';


const FacilitateSession = (props) => {
  

    let history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getOnsiteValue, setOnsiteValue] = useState(false);
    const [getDetailsLeagueEvents, setDetailsLeagueEvents] = useState({});

    useEffect(() => {
        leagueEventDetails();
      }, []);

    const onChangeBack =()=>{
      if(_.get(props,'userProfile.business_type')=='coordinator'){
        history(`/communiti/integrated-tabs?id=${searchParams.get('id')}&exp_id=${searchParams.get('exp_id')}&template_type=${searchParams.get('template_type')}&name_of_exp=${searchParams.get('name_of_exp')}`)
      } else {
        history(`/communiti/integrated-tabs?id=${searchParams.get('id')}&exp_id=${searchParams.get('exp_id')}&template_type=${searchParams.get('template_type')}&name_of_exp=${searchParams.get('name_of_exp')}&onsiteTab=4`)
      }        };

        
            
        const modalOpen = (value) => {
            setIsModalOpen(true)
        }

          const handleOk = () => {
            props.changeLeagueEventStatusAPI({
                body: {
                    id: _.get(getDetailsLeagueEvents, 'id'),
                    event_status: _.get(getDetailsLeagueEvents, 'event_status') === 0 ? 1 : 0
                },
                callback: (response, data) => {
                    if(response){
                        notification.success({
                            message: "Success",
                            description: "Status changed successfully"
                        })
                        leagueEventDetails();
                    }
                }
            })
            setIsModalOpen(false);
          };
          const handleCancel = () => {
            setIsModalOpen(false);
          };

          const leagueEventDetails = () => {
            let parameters = {
              id: _.get(props, 'experience_league_data.leage_id')
            }
            props.viewLeagueEventsAPI({
              params: parameters,
              callback: (response, data) => {
                
                
                if(response){
                  setDetailsLeagueEvents(_.get(data, 'data.details'));
                }
              }
            })
          };

          const handleTabChange = (key) => {
            
            if(key === "1"){
              props.hitRosterFunction();
            }
            if(key === "3"){
              if(typeof props.hitTeamFunction === "function"){
                props.hitTeamFunction();
              }
              
            }
          }

    const render = () => {
        return(
            <div>
            <div>
                <p className="card-text" style={{fontWeight: "bold", marginTop: "20px"}}>{searchParams.get('name_of_exp')}</p>
                 <Tabs onChange={handleTabChange} defaultActiveKey="1" size='large'>
                    <Tabs.TabPane tab="Roster" key="1">
                        <RosterTab experience_league_data={_.get(props, 'experience_league_data')} experience_id={_.get(props, 'experience_id')} nameOfExp={_.get(props, 'nameOfExp')}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Courts" key="2">
                    <CourtsTab experience_league_data={_.get(props, 'experience_league_data')} experience_id={_.get(props, 'experience_id')} nameOfExp={_.get(props, 'nameOfExp')}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Build Teams" key="3">
                        <BuildTeams experience_league_data={_.get(props, 'experience_league_data')} experience_id={_.get(props, 'experience_id')} nameOfExp={_.get(props, 'nameOfExp')}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Build Match Schedule" key="4">
                   <BuildMatchSchedule experience_league_data={_.get(props, 'experience_league_data')} experience_id={_.get(props, 'experience_id')} nameOfExp={_.get(props, 'nameOfExp')} eventDetails = {getDetailsLeagueEvents}/>
                    </Tabs.TabPane>
                 </Tabs>
                   <Modal centered={true} title={_.get(getDetailsLeagueEvents, 'event_status') === 0 ? "Are you sure you want to start the onsite?":"Are you sure you wish to close the onsite?"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                 {
                _.get(getDetailsLeagueEvents, 'event_status') === 0 ? <p>Setting this button to active will send a notification and enable a live banner to all users phones who are registered for this League.</p> : <p>Closing the onsite will remove the live banner from all users phones and they will no longer be able to view any live information about the League.</p>
              }
       
      </Modal>
            </div>
            </div>
        )
    };
    return render();
};

const mapStateToProps = state => {
  
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),
       guestUserProfile: _.get(state, 'app.guest.profile.data.details'),
        hitRosterFunction: _.get(state, 'app.settings.onsitePlayersCheckIn'),
        hitTeamFunction: _.get(state, 'app.settings.tabTeam'),
        experienceData: _.get(state, 'app.experiences.view.data.details')
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    viewLeagueEventsAPI: details => dispatch(global.redux.action.league.viewLeagueEvents(details)),
   changeLeagueEventStatusAPI: details => dispatch(global.redux.action.league.changeEventStatus(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(FacilitateSession)