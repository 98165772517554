import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Table, Button, Radio, Modal, Form, Input, Tag, notification, AutoComplete, Select, DatePicker, message,InputNumber, Tabs } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { BsPlusCircleDotted } from "react-icons/bs";
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';

import {  DownloadOutlined } from '@ant-design/icons';
import { BsArrowReturnRight } from "react-icons/bs";
import ReactQuill from 'react-quill';
import { Scrollbars } from 'react-custom-scrollbars';

import "./style.css";
import CancelledRegistrationList from './cancelled-registration';
import { AiOutlineCopy } from 'react-icons/ai';
import Chat from '../new-manage/session/chat';
import chatKittyInstance from '../../core_units/chat-system/chatkitty/instance';

const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
]
const { Option } = Select;

const sample_data = [
    {
      key: '1',
      firstName: 'Max',
      lastName: 'Smith',
    },
    {
      key: '2',
      firstName: 'John',
      lastName: 'Doe',
    },
    // Add more data as needed
  ];
const RegistrationList = (props) => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const [searchParams, setSearchParams] = useSearchParams();
    const [experienceDetails, setExperienceDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('active');
    const [activeOrderItem, setActiveOrderItem] = useState([]);
    const [activeLoading, setActiveLoading] = useState(false);
    const [addRegistration, setAddregistration] = useState(false);
    const [selectedCard, setSelectedCard] = useState();
    const [selectedProfileCard, setSelectedProfileCard] = useState(false);
    const [selectedProfileTypeCard, setSelectedProfileTypeCard] = useState();
    const [isManageRegModalOPen, setIsManageRegModalOPen] = useState(false);
    const [regDetails, setRegDetails] = useState({});
    const [registrationInfo, setRegistrationInfo] = useState([]);
    const [clauseInfo, setClauseInfo] = useState([]);
    const [options, setOptions] = useState([]);
    const [saveContactInfo, setSaveContactInfo] = useState(true)
    const [registrationProfile, setRegistrationProfile] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [tempSelectedOption, setTempSelectedOption] = useState(null);
    const [address, setAddress] = useState('');
    const [address_results, setaddress_results] = useState([]);
    const [address_fetching, setaddress_fetching] = useState(false);
    const [TaxListData, setTaxListData] = useState([]);
    const [emailAddress, setEmailAddress] = useState();
    const [guestItems, setGuestItems] = useState([]);
    const [registrationDeatils,  setRegistrationDeatils] = useState();
    const [dob, setDob]  = useState('');
    const [getGender, setGender] = useState('');
    const [createdGuestData, setCReatedGuestData] = useState({});
    const [guestProfileCreatedData, setGuestProfileCreatedData] = useState({});
    const [csvItems, setCsvItems] = useState([]);

    const [isManagePaymentModalOPen, setIsManagePaymentModalOPen] = useState(false);
    const [managePaymentDataSource, setManagePaymentDataSource] = useState([]);
    const [orderDataSource, setOrderDataSource] = useState([]);
    const [getOrderDetailsData, setOrderDetailsData] = useState({});
    const [getTransactionDataSource, setTransactionDataSource] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [refundedRows, setRefundedRows] = useState([]);

    const [getDataArray, setDataArray] = useState([]);
    const [getExpId, setExpId] = useState();
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [chennelData, setchennelData] = useState('');
    const [getTaxData, setTaxData] = useState();

    const [isGender, setIsSelfGender] = useState(false);
    const [getSelfGenderValue, setSelfGenderValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [activeOrderMeta, setActiveOrderMeta] = useState({});
    const [activeOrderData, setActiveOrderData] = useState([]);

    const handleRefundClick = (record) => {
        setExpandedRowKeys([record.key]);
        setSelectedData(record);
        setRefundedRows([...refundedRows, record.key]);
      };

    const handleExpand = (expanded, record) => {
        if (!expanded) {
            setExpandedRowKeys([]);
            setSelectedData({});
          }
      };
     
      const refundOrder = (e, data) => {
        
        let sample = "76"
        let amount = parseFloat(_.get(e, 'refund_amount'));
        let transa_amount = _.get(data, 'amount');
        
        let body_params = {...e};
        body_params.id = _.get(data, 'order');
        body_params.refund_type = 'transaction'
        body_params.transaction_id = _.get(data, 'id')
        body_params.cancel_order = 0;
        body_params.partial_refund = 0

        if(amount === parseFloat(transa_amount)){
          body_params.partial_refund = 0
        }
        if(amount !== parseFloat(transa_amount)){
            body_params.refund_amount = amount;
            body_params.partial_refund = 1
        } else {
            delete body_params.refund_amount
            // delete body_params.partial_refund
        }
        props.orderRefundAPI({
            body:{
                ...body_params
            },
            callback: (res, v) => {

                
                if(_.get(v, 'success')){
                    notification.success({
                        message: "Success",
                        description: "Amount refunded successfully"
                    })
                    transactionItems(_.get(data, 'order'));
                }
               
            }
        })
        
      }
      const expandedRowRender = (value) => {
        
        // if(_.get(value, 'details')){
           form.setFieldsValue({
            refund_amount: _.get(value, 'details.amount'),
           })
        // }
        return (
          <div>
            {/* <Input
              placeholder="Input box for editing data"
              value={selectedData.firstName}
              onChange={(e) =>
                setSelectedData({ ...selectedData, firstName: e.target.value })
              }
            /> */}
            {/* You can add more input fields for other data if needed */}
            <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "20px", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 2}}>
                          <div className='container-titles'>Amount</div>
                          <div className='container-titles-desc'>{`You may partially refund or refund up to the total amount of $${_.get(value, 'details.amount')}.`}<br/>
                            Note for Stripe payments refunding the total amount means you are responsible for covering the Stripe & Communiti fees.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form
                            form={form}
                            layout='vertical'
                            onFinish={(e) => refundOrder(e, _.get(value, 'details'))}
                            >
                                <Form.Item
                                label="Amount"
                                name="refund_amount"
                                rules={[
                                    {
                                        required:true,
                                        message: "Please enter an amount"
                                    }
                                ]}
                                >
                                <InputNumber max={_.get(value, 'details.amount')} placeholder='CAD $0.00' style={{height: "40px", width: "100%"}}/>
                                </Form.Item>
                                <Form.Item>
                              <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end"}}>
                              <Button style={{marginRight: "15px"}}>Cancel</Button>
                              <Button htmlType="submit">Refund Payment</Button>
                              </div>
                            </Form.Item>
                            </Form>
                            
                          </div>
                          </div>
          </div>
        );
      };


    //Invoice
    const [paymentRequestList, setpaymentRequestList] = useState([])
    const [tableExpandId, settableExpandId] = useState('')
    const [invoiceAmount, setinvoiceAmount] = useState('')
    const [invoiceNote, setinvoiceNote] = useState('')
    const [invoiceLoading, setinvoiceLoading] = useState(false)
    const [showDeleteConfirm, setshowDeleteConfirm] = useState(false)
    const [cancelInvoiceId, setcancelInvoiceId] = useState('')
    const [offlineAmount, setofflineAmount] = useState('')

    const active_headers = [
        {
          name: 'First Name & Last Name',
            key: 'first_last_name',
        },
        {
          name: 'Gender',
            key: 'gender',
        },
        {
          name: 'Date of Birth',
            key: 'gender_date_birth',
        },
        {
          name: 'Status',
            key: 'status',
        },
        
      ];

    useEffect(() => {
        viewExperience();
        listOrder();
        loadTaxList();
        listOrderForCSV('active')
        // getPaymentRequestList()
       
    }, []);
    const onCopyURL = (value) => {
      
      navigator.clipboard.writeText(value)
      .then(() => {
        
        messageApi.open({
          type: 'success',
          content: 'Copied',
        });
      })
      .catch(err => {
        console.error('Error copying value:', err);
      });
    }
    const getPaymentRequestList =(id)=>{
      props.getPaymentRequestList({
        params:{
            page:1,
            user_type: 1,
            limit:1000,
            order:id
        },
        callback: (response, data) => {
          if(response){
            let a =[]
            

            _.map(_.get(data,'data.items'),(v,i)=>{
              a.push({
                payment_type:
      <Tag onClick={ ()=>onCopyURL(v?.id)} style={{borderRadius:'16px',cursor:'pointer'}}><div style={{display:'flex',alignItems:'center'}}><div className='invoice-table-items' style={{marginRight:'5px'}}>Invoice</div><div><AiOutlineCopy/></div></div></Tag>
            
                ,
                amount: <div className='invoice-table-items' >${v?.amount}</div>,
                last_updated:<div className='invoice-table-items' >{moment(v?.updated_at).format('MMM d, YYYY')}</div>,
                status:v?.paid==0 &&v.status!=4?<Tag style={{borderRadius:'16px'}} >Pending</Tag>:v.status==4?<Tag style={{borderRadius:'16px'}} color='red'>Cancelled</Tag>:<Tag style={{borderRadius:'16px'}} color='#32D583'>Paid</Tag>,
                expand_details:v
              })
          })
            setpaymentRequestList(_.reverse(a))

          }
            
        }
    })
    }

    const loadTaxList =()=>{
        props.taxList({
          params:{limit:100},
          callback:(res,data)=>{
            
        if(res==true && _.get(data,'data.items[0]'))
            setTaxListData(_.get(data,'data.items'))
          }
        })
            }

    const viewExperience = () => {
        props.experienceViewAPI({
            params:{
                id: searchParams.get("id"),
                expand: "template"
            },
            callback: (response, data) => {
                if(!_.isEmpty(_.get(data, 'data.details'))){
                    setLoading(false);
                    setExperienceDetails(_.get(data, 'data.details'));
                }
            }
        })
    };

    const listOrder = (pageSize = 25) => {
      setActiveLoading(true)
        props.orderListAPI({
            params:{
                experience:searchParams.get("id"),
                user_type:'1',
                expand:'guest,experience,experience_template,profile',
                // paid:1,
                is_completed:0,
                status: 1,
                limit: pageSize
            },
            callback: (res, data) => {
                
                setActiveLoading(false);
                if(res){
                    
                    setActiveOrderMeta(_.get(data, 'data.meta', {}));
                    setActiveOrderData(_.get(data, 'data.items'));
                    if(!_.isEmpty(_.get(data, 'data.items'))){
                        
                        let a = []
                        _.map(_.get(data, 'data.items'), (value, index) => {
                            a.push({
                              key: index,
                              first_last_name: <div className='session-details-table'>{`${_.startCase(_.get(value, 'profile.first_name'))} ${_.startCase(_.get(value, 'profile.last_name'))}`}</div>,
                              gender_date_birth: <div className='session-details-table'>{`${moment(_.get(value, 'profile.date_of_birth')).format("MMMM DD, YYYY")}`}</div>,
                              gender: <div className='session-details-table'>{_.startCase(_.get(value, 'profile.gender'))}</div>,
                              status: _.get(value, 'paid'),
                              details: value,
                              email:_.get(value, 'profile.email')?_.get(value, 'profile.email'):_.get(value, 'guest.contact_details.email')
                            })
                          })
                        setActiveOrderItem(a);
                    };
                }
            }
        })
    };

    const getLabesData =(data)=>{
let registration_info_list =_.map(data,'registration_info')
      return _.uniqBy(_.flatten(registration_info_list),"id")

    }

    const listOrderForCSV = (radio) => {
        props.orderListAPI({
            params:{
                experience:searchParams.get("id"),
                user_type:'1',
                expand:'guest,experience,experience_template,profile',
                // paid:1,
                is_completed:0,
                status: radio === 'cancelled' ? 4 : 1,
                limit: 500
            },
            callback: (res, data) => {
                
                if(res){
                    if(!_.isEmpty(_.get(data, 'data.items'))){
                      // downloadCSV(_.get(data,'data.items',[]))
                      setDataArray(_.get(data,'data.items',[]));
                        let a = []
                        _.map(_.get(data, 'data.items'), (value, index) => {
                          
                            a.push({
                              key: index,
                              first_last_name: `${_.startCase(_.get(value, 'profile.first_name'))} ${_.startCase(_.get(value, 'profile.last_name'))}`,
                              gender_date_birth: `${moment(_.get(value, 'profile.date_of_birth')).format("MMMM DD, YYYY")}`,
                              gender: _.startCase(_.get(value, 'profile.gender')),
                              status: _.get(value, 'status') === 4 ? "Cancelled" : "Pending",
                            })
                          });
                          setCsvItems(a)
                    };
                }
            }
        })
    };

    const manageRegistration = (data) => {
        
        setRegDetails(data)
        setIsManageRegModalOPen(true);
        if(!_.isEmpty(_.get(data, 'details.registration_info'))){
                          
          let a = []
          _.map(_.get(data, 'details.registration_info'), (value, index) => {
              a.push({
                key: index,
                label: <div className='session-details-table'>{_.get(value, "label")}</div>,
                answer: <div className='session-details-table'>{_.get(value, "answer")}</div>,
              })
            })
          setRegistrationInfo(a);
      };
  
      if(!_.isEmpty(_.get(data, 'details.experience.clauses'))){
                          
        let c = []
        _.map(_.get(data, 'details.experience.clauses'), (value, index) => {
            c.push({
              key: index,
              name: <div className='session-details-table'>{_.get(value, "name")}</div>,
              created_at: <div className='session-details-table'>{`Accepted on ${moment.tz(_.get(data, 'details.created_at'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')} at ${moment.tz(_.get(data, 'details.created_at'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('hh:mm A z')}`}</div>,
            })
          })
          setClauseInfo(c);
    };
      };

      const handleManageRegOk = () => {
        setIsManageRegModalOPen(false);
        setRegistrationInfo([]);
      setClauseInfo([]);
      };
  
      const handleManageRegCancel = () => {
        setIsManageRegModalOPen(false);
        setRegistrationInfo([]);
      setClauseInfo([]);
      };

      const openChat = async (id) => {
        // 
        if (id) {

          const result = await chatKittyInstance.createChannel({
            type: "DIRECT",
            members: [{ id: id }],
        });
          
          if (result.succeeded) {
            const channel = result.channel; // Handle channel
            setchennelData(channel);
        }
    
      };
    
    }

      const showEmailModal = (value) => {
        
        setExpId(value)
        setIsEmailModalOpen(true);
        openChat(_.get(value, 'details.guest.chatkitty_user_id'));
        form.setFieldsValue({
          emails: _.get(value, 'email')
        })
      };

      const handleEmailOk = () => {
        setIsEmailModalOpen(false);
        setExpId()
        form.setFieldsValue({
          emails:'',
          title: '',
          message: ''
        })
      };

      const handleEmailCancel = () => {
        setIsEmailModalOpen(false);
        setExpId();
        form.setFieldsValue({
          emails:'',
          title: '',
          message: ''
        })
      };

      const onFinish = (values) => {
        
        props.sendEmailAPI({
          body: {
            experience: searchParams.get("id"),
            subject: values.title,
            message: values.message,
            guest_ids: _.get(getExpId, 'details.guest.id')
          },
          callback: (res, val) => {
            
            if (_.get(val, 'success')) {
              notification.success({
                message: "Success",
                description: "Email sent"
              })
              setIsEmailModalOpen(false);
              form.setFieldsValue({
                emails:'',
                title: '',
                message: ''
              })
            }
          }
        })
      };
      const onFinishFailed = (errorInfo) => {
        
      };

    const columns = [
        {
          title: <div className='session-details-table'>ID</div>,
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: <div className='session-details-table'>Session Title</div>,
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: <div className='session-details-table'>Schedule</div>,
          dataIndex: 'schedule_details',
          key: 'schedule_details',
          render: () => (
            <>
              <div className='session-details-table'>{`${moment.tz(_.get(experienceDetails, 'experience_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(experienceDetails, 'experience_end_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')}`}</div>
              <div className='session-details-table'>{_.get(experienceDetails, 'type') == 'open' ? "" :
          `${_.get(experienceDetails, 'experience_start_time') ?
            moment.tz(_.get(experienceDetails, 'experience_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format("hh:mm") : '--'}-${_.get(experienceDetails, 'experience_end_time') ? moment.tz(_.get(experienceDetails, 'experience_end_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`}</div>
              <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{ _.size(_.get(experienceDetails, 'schedule_dates')) > 1 ? `${_.size(_.get(experienceDetails, 'schedule_dates')) + 1} Sessions` :  _.isEmpty(_.get(experienceDetails, 'schedule_dates')) && _.get(experienceDetails, 'type') !== "open" ? '1 session' : _.isEmpty(_.get(experienceDetails, 'schedule_dates')) && _.get(experienceDetails, 'type') === "open" ? "" : `${_.size(_.get(experienceDetails, 'schedule_dates'))} Session`}</div> {_.get(experienceDetails, 'can_late_register') === true && <div className="custom-button-tag">Late Registration</div>} </div>
            </>
          ),
        },

        {
            title: <div className='session-details-table'>Session Info</div>,
            dataIndex: 'session_info',
            key: 'session_info',
            render: () => (
              <>
                <div className='session-details-table'>{_.get(experienceDetails, 'type') == "league" ? "League Session" : _.get(experienceDetails, 'type') == "open" ? "Open Session" : "Scheduled Session"}</div>
                <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{`$${_.get(experienceDetails, 'price')} ${_.get(experienceDetails, 'template.meta_details.currency')}`}</div> {_.get(experienceDetails, 'can_prorate') === true && <div className="custom-button-tag">Prorate</div>}</div>
                <div className='session-details-table'>{`${_.get(experienceDetails, 'no_of_bookings')}/${_.get(experienceDetails, 'max_no_of_openings')} Registrations`}</div>
              </>
            ),
          },
      ];

      const dataSource = [
        {
          key: '1',
          id: <div className='session-details-table'>{_.get(experienceDetails, 'id')}</div>,
          title: <div className='session-details-table'>{_.get(experienceDetails, 'template.title')}</div>,
          schedule_details: null, // The render function will populate this
          session_info: null,  // The render function will populate this
        },
      ];

      const handleRadioChange = (e) => {
        
        listOrderForCSV(e.target.value)
        setSelectedTab(e.target.value);
      };
      const sharedOnCell = (_, index) => {
        if (index === 1) {
          return {
            colSpan: 0,
          };
        }
        return {};
      };

      const active_columns = [
        {
            title: <div className='session-details-table'>First Name & Last Name</div>,
            dataIndex: 'first_last_name',
            key: 'first_last_name',
            width: "25%",
            render: (text, item) => {
                return item?.id == 1 ? "" : <div className='session-details-table'>{_.get(item, 'first_last_name')}</div>
              },
          },
          {
            title: <div className='session-details-table'>Gender & Date of Birth</div>,
            dataIndex: 'gender_date_birth',
            key: 'gender_date_birth',
            width: "25%",
            render: (text, item) => {
                    return item?.id == 1 ? "" : <>
                  <div className='session-details-table'>{_.get(item, 'gender')}</div>
                  <div className='session-details-table'>{_.get(item, 'gender_date_birth')}</div>
                </>
                    
            }
                
              ,
          },
          {
            title: <div className='session-details-table'>Payment Status</div>,
            dataIndex: 'payment_status',
            key: 'payment_status',
            width: "25%",
            render: (text, item) => {
              
                return item?.id == 1 && searchParams.get('action') !== 'called_off' ? <div  style={{ display: 'flex', alignItems: 'center',marginLeft:-82 }}>
                <BsPlusCircleDotted style={{ marginRight: 10 }} color="#475467" size={24} />
                <div className="header-first-row">Add New Registration</div>
              </div> :<>
              {activeOrderItem.length > 0 && <div className='session-details-table'>{_.get(item, 'status') === 1 ? <div className='paid-customized-tag'>Paid</div> : (searchParams.get('action') !== 'called_off' &&<div className='paid-customized-tag'>Pending</div>)}</div>}
            </>
                
        }
          },
          {
            title: <div className='session-details-table'>Actions</div>,
            dataIndex: 'action',
            key: 'action',
            width: "65%",
            render: (_, record) => {
              
                return record?.id == 1 ? "" : <div style={{display: "flex", flexDirection: "row"}}>
                        <div onClick={() => manageRegistration(record)} className='header-first-row' >Manage Registration</div>
                        <div onClick={() => showManagePaymentModal(record)} className='header-first-row' style={{marginLeft: "12px"}}>Manage Payment</div>
                        <div onClick={() => showEmailModal(record)} className='header-first-row' style={{marginLeft: "12px"}}>Message</div>
                    </div>
                
        }
          }
      ];

      const showAddRegistrationModal = () => {
        setAddregistration(true)
      };

      const handleOkRegistration = () => {
        setAddregistration(false);

        form.resetFields(['first_name', 'last_name', 'location', 'email', 'country', 'profile_first_name', 'profile_last_name', 'profile_gender', 'profile_dob']);
        setOptions([]);
        setSaveContactInfo(true);
        setRegistrationProfile(false);
        setSelectedOption(null);
        setTempSelectedOption(null);
        setAddress('');
        setaddress_results([]);
        setaddress_fetching(false);
        setTaxListData([]);
        setEmailAddress();
        setGuestItems([]);
        setRegistrationDeatils();
        setDob();
        setGender('');
        setCReatedGuestData({});
        setGuestProfileCreatedData({});
        setSelectedCard();
        setSelectedProfileCard(false);
        setSelectedProfileTypeCard();
        setIsSelfGender(false)
        setSelfGenderValue('')
      };

      const cancelAddRegistration = () => {
        setAddregistration(false);

        form.resetFields(['first_name', 'last_name', 'location', 'email', 'country', 'profile_first_name', 'profile_last_name', 'profile_gender', 'profile_dob']);
        setOptions([]);
        setSaveContactInfo(true);
        setRegistrationProfile(false);
        setSelectedOption(null);
        setTempSelectedOption(null);
        setAddress('');
        setaddress_results([]);
        setaddress_fetching(false);
        setTaxListData([]);
        setEmailAddress();
        setGuestItems([]);
        setRegistrationDeatils();
        setDob();
        setGender('');
        setCReatedGuestData({});
        setGuestProfileCreatedData({});
        setSelectedCard();
        setSelectedProfileCard(false);
        setSelectedProfileTypeCard();
        setIsSelfGender(false)
        setSelfGenderValue('')
      };

      const handleCardClick = (card) => {
        
          setSelectedCard(card);
        };

        const handleProfileCardClick = (card) => {
            
            setSelectedProfileCard(card);
            };

            const chooseProfileType = (card) => {
                
                setSelectedProfileTypeCard(card);
                };

        const registrant_column = [
            {
              title: <div className='session-details-table'>Registrant Type</div>,
              dataIndex: "reg_type",
              key:"reg_type"
            },
            {
              title: <div className='session-details-table'>Registrant First & Last Name</div>,
              dataIndex: "reg_first_last_name",
              key:"reg_first_last_name"
            },
            {
              title: <div className='session-details-table'>Registrant Gender & Date of Birth</div>,
              dataIndex: "reg_gender_dob",
              key:"reg_gender_dob"
            },
            {
              title: <div className='session-details-table'>Contact First & Last Name</div>,
              dataIndex: "con_first_last_name",
              key:"con_first_last_name"
            },
            {
              title: <div className='session-details-table'>Contact Email</div>,
              dataIndex: "con_email",
              key:"con_email"
            },
            {
              title: <div className='session-details-table'>Contact Address</div>,
              dataIndex: "con_address",
              key:"con_address"
            },
          ];
    
          const reg_dataSource = [
            {
              key: "1",
              reg_type: <div className='session-details-table'>{_.get(regDetails, 'details.profile_info.profile_type')}</div>,
              reg_first_last_name: <div className='session-details-table'>{`${_.startCase(_.get(regDetails, 'details.profile_info.first_name'))} ${_.startCase(_.get(regDetails, 'details.profile_info.last_name'))}`}</div>,
              reg_gender_dob: <div className='session-details-table'>{`${_.startCase(_.get(regDetails, 'details.profile_info.gender'))} ${moment(_.get(regDetails, 'details.profile_info.date_of_birth')).format("MMMM DD, YYYY")}`}</div>,
              con_first_last_name: <div className='session-details-table'>{`${_.startCase(_.get(regDetails, 'details.guest.first_name'))} ${_.startCase(_.get(regDetails, 'details.guest.last_name'))}`}</div>,
              con_email: <div className='session-details-table'>{_.get(regDetails, 'details.guest.contact_details.email')}</div>,
              con_address: <div className='session-details-table'>{_.get(regDetails, 'details.guest.address')}</div>,
            }
          ];

          const additional_column = [
            {
              title: <div className='session-details-table'>Question Title</div>,
              dataIndex: "label",
              key: "label"
            },
    
            {
              title: <div className='session-details-table'>Registrant Response</div>,
              dataIndex: "answer",
              key: "answer"
            },
          ]
    
          const clause_column = [
            {
              title: <div className='session-details-table'>Clause Title</div>,
              dataIndex: "name",
              key: "name"
            },
    
            {
              title: <div className='session-details-table'>Acceptance Timestamp</div>,
              dataIndex: "created_at",
              key: "created_at",
            },
          ];

          const cancelRegistration = () => {
            props.orderRefundAPI({
                body:{
                    "id":_.get(regDetails, 'details.id'),
                      "refund_type": "order",
                      "cancel_order": 1,
                      "partial_refund": 0
                },
                callback: (cancel_res, cancel_data) => {
                    
                    if(cancel_res){
                        setIsManageRegModalOPen(false);
                        listOrder();
                        notification.success({
                            message: "Registration cancelled",
                            description: "You have successfully cancelled this registration."
                        })
                    }
                }
            })
          };

        const handleSearch = async (value) => {
              setSearchValue(value);
              setEmailAddress(value);
              setRegistrationDeatils()
              setCReatedGuestData({});
              form.resetFields(['first_name', 'last_name', 'location']);
              setSelectedOption(null);
            
          };

          const handleKeyDown = (e) => {
            // Handle your keyboard event here
            
    
            // Example: Prevent the Enter key from submitting a form
            if (e.key === 'Enter') {
              props.searchGuestAPI({
                params:{
                    limit: 1,
	                page: 1,
	                search: searchValue,
                    search_type: 0

                },
                callback: (res, val) => {
                    
                    const filteredOptions = _.get(val, 'data.items');
                    
                    setOptions(filteredOptions);

                    const selectedObject = filteredOptions.find((option) => _.get(option, 'email') === searchValue);
                    setSelectedOption(selectedObject);
                    
                        handleGetGuestProfile(_.get(selectedObject, 'id'));
                    
                    form.setFieldsValue({
                        first_name: _.startCase(_.get(selectedObject, 'first_name', '')),
                        last_name: _.startCase(_.get(selectedObject, 'last_name', '')),
                        country:_.upperCase(_.get(selectedObject,'country'))=="CA"?"CAD":"USD",
                        location: _.get(selectedObject, 'address', ''),
                    });
                }
            })
                
            }
        };

          const isJson=(str)=> {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
          };

          const saveNext = (e) => {
            
            if(_.isEmpty(createdGuestData)){
                let request_details={...e}

            if (isJson(_.get(_.head(address_results),'value'))) {
                let location_parse = JSON.parse(_.get(_.head(address_results),'value'))
                request_details.location=`${_.get(location_parse,'lat')},${_.get(location_parse,'lng')}`
                request_details.address = _.get(location_parse,'text')
                request_details.zip_code = _.get(location_parse,'postal_code')
              }
            setRegistrationDeatils(request_details);
            setSaveContactInfo(false);
            setRegistrationProfile(true);
            request_details.country =_.get(request_details, 'country')=="USD"?"US":"CA";
            request_details.managed_by_host = 1
            if(!_.isEmpty(getTaxData)){
              request_details.tax_state = getTaxData
            }
            if(_.isEmpty(selectedOption)){
                props.guestCreateAPI({
                    body:{
                        ...request_details
                    },
                    callback: (res, data) => {
                        
                        if(_.get(data, 'success')){
                            setTaxData();
                            setGuestItems([])
                            setSelectedOption(null);
                            setCReatedGuestData(_.get(data, 'details'));
                            notification.success({
                                message: "Success",
                                description: "Guest created successfully."
                            })
                        }
                       
                    }
                })
            }
            }
            setSaveContactInfo(false);
            setRegistrationProfile(true);
           
          }

          const handleBack = () => {
            setSaveContactInfo(true);
            setRegistrationProfile(false);
            setSelectedCard();
          }

          const saveRegProfile = (e) => {
            
            if(selectedCard === false){
                props.guestProfileCreateAPI({
                    body:{
                        profile_type: selectedProfileTypeCard === true ? "self" : "dependent",
                        first_name: e.profile_first_name,
                        last_name: e.profile_last_name,
                        date_of_birth: dob,
                        gender: isGender ? getSelfGenderValue : getGender,
                        managed_by_host: 1,
                        guest:  !_.isEmpty(createdGuestData) ? _.get(createdGuestData, 'id') : _.get(selectedOption, 'id')
                    },
                    callback: (res, d) => {
                        if(_.get(d, 'success')){
                          setIsSelfGender(false);
                          setSelfGenderValue('');
                            setGuestProfileCreatedData(_.get(d, 'details'));
                            props.manualOrderCreateAPI({
                                body:{
                                    experience: searchParams.get("id"),
                                    profile: _.get(d, 'details.id') ? _.get(d, 'details.id') : selectedProfileCard,
                                    managed_by_host: 1,
                                    guest: _.get(createdGuestData, 'id') ? _.get(createdGuestData, 'id') : _.get(selectedOption, 'id')
                                },
                                callback: (res, data) => {
                                    
                                    if(res){
                                        notification.success({
                                            message: "Registration complete",
                                            description: "You have added a registration to this session."
                                        });
                                        handleOkRegistration();
                                        listOrder();
                                    }
                                }
                                
                            })
                        }
                    }
                });
            };
            
            if(selectedCard === true){
                            props.manualOrderCreateAPI({
                                body:{
                                    experience: searchParams.get("id"),
                                    profile: selectedProfileCard,
                                    managed_by_host: 1,
                                    guest: _.get(selectedOption, 'id')
                                },
                                callback: (res, data) => {
                                    
                                    if(res){
                                        notification.success({
                                            message: "Registration complete",
                                            description: "You have added a registration to this session."
                                        });
                                        handleOkRegistration();
                                        listOrder();
                                    }
                                }
                                
                            })
                   
            }
            
            

            
          };

          const selectedValue = (value) => {
                const selectedObject = options.find((option) => _.get(option, 'email') === value);
                setSelectedOption(selectedObject);
                
                    handleGetGuestProfile(_.get(selectedObject, 'id'));
                
                form.setFieldsValue({
                    first_name: _.startCase(_.get(selectedObject, 'first_name', '')),
                    last_name: _.startCase(_.get(selectedObject, 'last_name', '')),
                    country:_.upperCase(_.get(selectedObject,'country'))=="CA"?"CAD":"USD",
                    location: _.get(selectedObject, 'address', ''),
                });
                
          };

          const changeSelect =(value) => {
            setEmailAddress(value);
            setRegistrationDeatils()
            setCReatedGuestData({});
            form.resetFields(['first_name', 'last_name', 'location']);
            setSelectedOption(null)
          }

          const handleSelectChange = (value) => {
            
            setTempSelectedOption(value);
            // setIsModalOpen(true);
          };

          const handleChange1 = (value) => {
            setAddress(value);
          };
          const fetchPostalCode = address_components => {
            let postal_code = null;
            address_components.forEach(value => {
                value.types.forEach(type => {
                    if (type === 'postal_code') {
                        postal_code = value.long_name
                    }
                })
            })
            return postal_code;
        };

          const handleSelect = async (value) => {

            
            setaddress_results([])
              setaddress_fetching(true);
              geocodeByAddress(value).then(body => {
                  
                  const address_results = _.map(body, location => ({
                      text: location.formatted_address,
                      value: JSON.stringify({ text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
                  }));
          
                  setaddress_results(address_results)
                  setaddress_fetching(false);
                 
                form.setFieldsValue({
                    location: value
                })
                
                let state =extractStateName(body)
                let ada = _.filter(TaxListData,(v)=>_.lowerCase(v.state)==_.lowerCase(state))
                if(_.size(ada)>0){
                  setTaxData(_.get(ada[0],'id'))
                  form.setFieldsValue({
                    tax_state: _.get(ada[0],'id')
                })
                }
                
                
                setaddress_results(address_results)
              }).catch(error => {
                  setaddress_results([])
                  setaddress_fetching(true);
              });
          };

          const extractStateName = results => {
            for (let i = 0; i < results[0].address_components.length; i++) {
              const component = results[0].address_components[i];
              if (component.types.includes('administrative_area_level_1')) {
                return component.long_name; // Return the state name
              }
            }
            return '';
          };

          const searchOptions = {
            componentRestrictions: { country: form.getFieldValue('country')=="USD"?['us']:['ca'] }, // Restrict search to United States and Canada
          };

          const handleGetGuestProfile = (id) => {
                props.guestProfileListAPI({
                    params:{
                        status:1,
                        guest: id,
                        managed_by_host: 1
                    },
                    callback: (guest_res, guest_data) => {
                        
                        if(_.size(_.get(guest_data, 'data.items')) > 0){
                            setGuestItems(_.get(guest_data, 'data.items'));
                        }
                    }
                })
          };

          const onChangeDob = (date, dateString) => {
            
            setDob(dateString)
          }

          const handleChangeGender = (value) => {
                
                setGender(value)

                if(value === 'self'){
                  setIsSelfGender(true)
                } else {
                  setIsSelfGender(false)
                }
          };

          const disabledDate = (current) => {
            // If the current date is after today, disable it
            return current && current > moment().endOf('day');
          };

          const showManagePaymentModal = (data) => {
            
            setOrderDetailsData(_.get(data, 'details'));
            transactionItems(_.get(data, 'details.id'))
            getPaymentRequestList(_.get(data, 'details.id'))
            setIsManagePaymentModalOPen(true);

            const total_amount = parseFloat(_.get(data, 'details.total'));
            const base_platform_amount = parseFloat(_.get(data, 'details.base_platform_amount'));
            const base_platform_tax_amount = parseFloat(_.get(data, 'details.base_platform_tax_amount'));
            const club_amount = (total_amount)-(base_platform_amount)-(base_platform_tax_amount)

            


            if(!_.isEmpty(_.get(data, 'details'))){
                          
                let a = [];
                    a.push({
                      key: _.get(data, 'details.id'),
                      id: <div className='session-details-table'>{_.get(data, 'details.id')}</div>,
                      session_title: <div className='session-details-table'>{_.get(data, 'details.experience.template.title')}</div>,
                      first_and_last: <div className='session-details-table'>{`${_.startCase(_.get(data, 'details.profile_info.first_name'))} ${_.startCase(_.get(data, 'details.profile_info.last_name'))}`}</div>,
                      details: _.get(data, 'details'),
                      email: _.get(data, 'email'),
                    })
                    setManagePaymentDataSource(a);
            };

            if(!_.isEmpty(_.get(data, 'details'))){
                let b = [];
                b.push({
                    key: _.get(data, 'details.id'),
                    order_value: <div className='session-details-table'>${_.get(data, 'details.total')}</div>,
                    status: <div className='session-details-table'>{_.get(data, 'details.paid') === 1 ? <div className='paid-customized-tag'>Paid</div> : <div className='paid-customized-tag'>Pending</div>}</div>,
                    running_total: <div className='session-details-table'>{`$ ${_.get(data, 'details.running_total')}`}</div>,
                    refund_amount: <div className='session-details-table'>{`$ ${_.get(data, 'details.refund_amount')}`}</div>,
                    tax_amount: <div className='session-details-table'>{`$ ${club_amount.toFixed(2)}`}</div>,
                    tax_rate: <div className='session-details-table'>{`$ ${_.get(data, 'details.tax_rate')}`}</div>,
                    base_platform_amount: <div className='session-details-table'>{`$ ${_.get(data, 'details.base_platform_amount')}`}</div>,
                    base_platform_tax_amount: <div className='session-details-table'>{`$ ${_.get(data, 'details.base_platform_tax_amount')}`}</div>,
                    discount: <div className='session-details-table'>{_.isEmpty(_.get(data, 'details.discount')) ? "$ 0.00" : `$ ${_.get(data, 'details.discount')}`}</div>,
                })
                setOrderDataSource(b);
            }
          };

          const manage_payment_column = [
            {
                title:<div className='session-details-table'>ID</div>,
                key:"id",
                dataIndex: "id"
            },
            {
                title:<div className='session-details-table'>Session Title</div>,
                key:"session_title",
                dataIndex: "session_title"
            },
            {
                title:<div className='session-details-table'>Schedule</div>,
                key:"schedule",
                dataIndex: "schedule",
                render:(text, item)=>{
                    
                        return  <>
                        <div className='session-details-table'>{`${moment.tz(_.get(item, 'details.experience.experience_date'), !_.isEmpty(_.get(item, 'details.experience.meta_details.timezone')) ? _.get(item, 'details.experience.meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(item, 'details.experience.experience_end_date'), !_.isEmpty(_.get(item, 'details.experience.meta_details.timezone')) ? _.get(item, 'details.experience.meta_details.timezone') : "America/Toronto").format('MMM D')}`}</div>
                        <div className='session-details-table'>{_.get(item, 'details.experience.type') == 'open' ? "null" :
                    `${_.get(item, 'details.experience.experience_start_time') ?
                      moment.tz(_.get(item, 'details.experience.experience_date'), !_.isEmpty(_.get(item, 'details.experience.meta_details.timezone')) ? _.get(item, 'details.experience.meta_details.timezone') : "America/Toronto").format("hh:mm") : '--'}-${_.get(item, 'details.experience.experience_end_time') ? moment.tz(_.get(item, 'details.experience.experience_end_date'), !_.isEmpty(_.get(item, 'details.experience.meta_details.timezone')) ? _.get(item, 'details.experience.meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`}</div>
                        <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{ _.size(_.get(item, 'details.experience.schedule_dates')) > 1 ? `${_.size(_.get(item, 'details.experience.schedule_dates'))} Sessions` : `${_.size(_.get(item, 'details.experience.schedule_dates'))} Session`}</div></div>
                      </>
                }
            },
            {
                title: <div className='session-details-table'>Session Info</div>,
                dataIndex: 'session_info',
                key: 'session_info',
                render: (text, item) => (
                  <>
                    <div className='session-details-table'>{_.get(item, 'details.experience.type') == "league" ? "League Session" : _.get(item, 'details.experience.type') == "open" ? "Open Session" : "Scheduled Session"}</div>
                    <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{`$${_.get(item, 'details.experience.price')} ${_.get(item, 'details.experience.template.meta_details.currency')}`}</div></div>
                    <div className='session-details-table'>{`${_.get(item, 'details.experience.no_of_bookings')}/${_.get(item, 'details.experience.max_no_of_openings')} Registrations`}</div>
                  </>
                ),
              },
            {
                title:<div className='session-details-table'>First & Last Name</div>,
                key:"first_and_last",
                dataIndex: "first_and_last"
            },
            {
                title:<div className='session-details-table'>Gender & Date of Birth</div>,
                key:"Genders_dob",
                dataIndex: "Genders_dob",
                render:(text, item) => {
                  
                    return <>
                        <div className='session-details-table'>{_.startCase(_.get(item, 'details.profile_info.gender'))}</div>
                        <div className='session-details-table'>{moment(_.get(item, 'details.profile_info.date_of_birth')).format("MMMM DD, YYYY")}</div>
                    </>
                }
            },
          ]

          const order_column = [
            {
                title:<div className='session-details-table'>Status</div>,
                key: "status",
                dataIndex:"status"
            },
            {
              title:<div className='session-details-table'>Order Value</div>,
              key: "order_value",
              dataIndex:"order_value"
          },
            {
                title:<div className='session-details-table'>Total Paid</div>,
                key: "running_total",
                dataIndex:"running_total"
            },
            {
                title:<div className='session-details-table'>Total Refunded</div>,
                key: "refund_amount",
                dataIndex:"refund_amount"
            },
            {
                title:<div className='session-details-table'>Club Amount</div>,
                key: "tax_amount",
                dataIndex:"tax_amount"
            },
            {
                title:<div className='session-details-table'>Club Tax</div>,
                key: "tax_rate",
                dataIndex:"tax_rate"
            },
            {
                title:<div className='session-details-table'>Communiti Fee</div>,
                key: "base_platform_amount",
                dataIndex:"base_platform_amount"
            },
            {
                title:<div className='session-details-table'>Communiti Tax</div>,
                key: "base_platform_tax_amount",
                dataIndex:"base_platform_tax_amount"
            },
            {
                title:<div className='session-details-table'>Discounts</div>,
                key: "discount",
                dataIndex:"discount"
            },
          ]

          //Invoice
          const handleChange = (e) => {
            
            const reg = /^-?\d*(\.\d*)?$/;
            if (reg.test(e) || e === '' || e === '-') {
              setinvoiceAmount(e)
            }
          };

          //Create invoice request
          const paymentRequestsCreate =(amount,order_id)=>{
            
            setinvoiceLoading(true)
            props.paymentRequestsCreate({
              body:{amount:amount,
                id:order_id
              },
              params:{id:order_id},
              callback: (response, data) => {
                setinvoiceLoading(false)
                
                if(response){
                  setinvoiceAmount("")
                  settableExpandId("")
                  getPaymentRequestList(order_id)
                  // setpaymentRequestList(_.get(data,'data.items'))
                }
                  
              }
          })
          }

          const renderOfflineContent =(item)=>{
            
            return <div>
              <div style={{display:'flex',marginBottom:'16px'}}>
                {item?.payment_type}
                <div style={{marginLeft:'10%'}}>{item?.amount}</div> 
                <div style={{marginLeft:'10%'}}>{item?.last_updated}</div>
                <div style={{marginLeft:'15%'}}> {item?.status}</div>
                {/* <div style={{width:'25%'}}></div> */}
              </div>
              <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Apply Offline Payment</p>
            <div style={{display:'flex'}}>
              <div style={{width:'48%',marginRight:'30px'}}>
              <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Confirm Amount</p>
              <p className='session-static-desc'>Confirm the amount applied an offline payment up to the total amount invoiced.</p>
              
              <p className='session-static-desc' style={{marginTop:'14px'}}>Note: You can only apply the full amount of the invoice in an offline payment.</p>
              </div>
              <div style={{width:'52%'}}>
                <div style={{marginBottom:'8px'}}>
                  <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Amount</p>
                  <Input readOnly={true} disabled={true} value={`$${offlineAmount}`} style={{height:'40px',borderRadius:'8px',border:'1px solid #D0D5DD)',background:'#FFF'}} placeholder='CAD $0.00'
                  
                  />
                </div>
                <div>
                <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Add Note</p>
                  <Input onChange={(e)=>setinvoiceNote(e.target.value)} style={{height:'40px',borderRadius:'8px',border:'1px solid #D0D5DD)',background:'#FFF'}} placeholder='Enter a note for this offline payment'/>
                </div>
                <div style={{marginTop:'16px',display:'flex',justifyContent:'flex-end'}}>
                  <Button disabled={invoiceLoading} onClick={()=>{
                    
                    settableExpandId("")
                  }} style={{height:'40px'}}>Cancel</Button>
                  <Button
                  loading={invoiceLoading}
                  disabled={parseFloat(offlineAmount)>0?false:true}
                  onClick={()=>{
                    
                    if(tableExpandId && invoiceNote && offlineAmount){
                      paymentOffline(tableExpandId)
                    }
                    
                    // settableExpandId("")
                    // paymentRequestsCreate(parseFloat(invoiceAmount),_.get(_.head(managePaymentDataSource),'details.id'))
                    
                  }} style={{height:'40px',marginLeft:'8px'}}>Apply Offline Payment</Button>
                </div>
              </div>
            </div>
          </div>
          }
          const invoice_column = [
            {
                title:<div className='session-details-table'>Payment Type</div>,
                key: "payment_type",
                dataIndex:"payment_type",
                onCell: (item, index) => {
                  
                  return {
                  colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5 :1,
                  className:"",
                  
                }},
                
                
                
                render:(text, item)=>{
                return item?.id==1?tableExpandId==item?.id ? <div>
                 <p style={{marginBottom:'14px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Send Invoice</p>
                <div style={{display:'flex'}}>
                  <div style={{width:'48%',marginRight:'30px'}}>
                  <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Amount</p>
                    <p className='session-static-desc'>Enter the amount to invoice the registrant. This invoice will be sent to their email address.</p>
                    {/* <p className='session-static-desc'>Enter the amount to invoice the registrant. This invoice will be sent to their email address.</p> */}
                  </div>
                  <div style={{width:'52%'}}>
                    <div style={{marginBottom:'8px'}}>
                      <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Amount</p>
                      <Input  value={invoiceAmount} style={{height:'40px',borderRadius:'8px',border:'1px solid #D0D5DD)',background:'#FFF'}} placeholder='CAD $0.00'
                      onChange={(e)=>handleChange(e.target.value)}
                      />
                    </div>
                    {/* <div>
                    <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Add Note</p>
                      <Input style={{height:'40px',borderRadius:'8px',border:'1px solid #D0D5DD)',background:'#FFF'}} placeholder='Enter a note for this offline payment'/>
                    </div> */}
                    <div style={{marginTop:'16px',display:'flex',justifyContent:'flex-end'}}>
                      <Button disabled={invoiceLoading} onClick={()=>{
                        
                        settableExpandId("")
                      }} style={{height:'40px'}}>Cancel</Button>
                      <Button
                      loading={invoiceLoading}
                      disabled={parseFloat(invoiceAmount)>0?false:true}
                      onClick={()=>{
                        
                        // settableExpandId("")
                        paymentRequestsCreate(parseFloat(invoiceAmount),_.get(_.head(managePaymentDataSource),'details.id'))
                        
                      }} style={{height:'40px',marginLeft:'8px'}}>Confirm</Button>
                    </div>
                  </div>
                </div>
              </div>: "": _.get(item,'expand_details.id')==tableExpandId?renderOfflineContent(item):<div>{text}</div>
                }
            },
            {
                title:<div className='session-details-table'>Amount</div>,
                key: "amount",
                dataIndex:"amount",
                render:(text, item)=>{
                  return item?.id==1?"": _.get(item,'expand_details.id')==tableExpandId?"":<div>{text}</div>
                  },
                  onCell: (item, index) => {
                    
                    return {
                      colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5: 1,
                    className:item?.id==1?tableExpandId==item?.id?'test-class-cell':"":_.get(item,'expand_details.id')==tableExpandId?'test-class-cell':"",
                    
                  }},
            },
            {
                title:<div className='session-details-table'>Last Updated</div>,
                key: "last_updated",
                dataIndex:"last_updated",
                onCell: (item, index) => {
                  
                  return {
                    colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5: 1,
                    className:item?.id==1?tableExpandId==item?.id?'test-class-cell':"":_.get(item,'expand_details.id')==tableExpandId?'test-class-cell':"",
                  
                }},
                // onCell: (text,item)=>{
                //   
                //   return {
                //     colSpan:  5 
                //   }
                // },
                render:(text, item)=>{
                  return  item?.id==1?tableExpandId=="" ?<div onClick={  ()=>{

                     searchParams.get('action') !== 'called_off' && settableExpandId(_.get(item, 'id'))
                  }} style={{ display: 'flex', alignItems: 'center', cursor: searchParams.get('action') !== 'called_off' ? 'pointer' : 'not-allowed'}}>
                  <BsPlusCircleDotted style={{ marginRight: 10 }} color="#475467" size={24} />
                  {<div style={{cursor: searchParams.get('action') !== 'called_off' ? 'pointer' : 'not-allowed'}} className="header-first-row">Send Invoice</div>}
                </div>:<div onClick={()=>{

settableExpandId(_.get(item, 'id'))
}} style={{ display: 'flex', alignItems: 'center' }}>
<BsPlusCircleDotted style={{ marginRight: 10 }} color="#475467" size={24} />
<div className="header-first-row">Send Invoice</div>
</div> : _.get(item,'expand_details.id')==tableExpandId?"":<div>{text}</div>
                  }
            },
            {
                title:<div className='session-details-table'>Status</div>,
                key: "status",
                dataIndex:"status",
                onCell: (item, index) => {
                  
                  return {
                    colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5: 1,
                    className:item?.id==1?tableExpandId==item?.id?'test-class-cell':"":_.get(item,'expand_details.id')==tableExpandId?'test-class-cell':"",
                  
                }},
                render:(text, item)=>{
                  return item?.id==1?"":_.get(item,'expand_details.id')==tableExpandId?"": <div>{text}</div>
                  }
            },
            {
              onCell: (item, index) => {
                
                return {
                  colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5: 1,
                  className:item?.id==1?tableExpandId==item?.id?'test-class-cell':"":_.get(item,'expand_details.id')==tableExpandId?'test-class-cell':"",
                
              }},
                title:<div className='session-details-table'>Action</div>,
                key: "action",
                dataIndex:"action",
                render:(text, item)=>{
                  return item?.id==1?"":_.get(item,'expand_details.id')==tableExpandId?"": <div>
{item?.expand_details?.paid==0 &&item?.expand_details?.status!=4&&item?.expand_details?.paid!=1 && <div onClick={()=>{setshowDeleteConfirm(true);
setcancelInvoiceId(_.get(item,'expand_details.id'))

}} style={{fontWeight:500,fontSize:'14px',cursor:'pointer'}}>Cancel</div>}
{item?.expand_details?.status!=4&&item?.expand_details?.paid!=1 &&<div 
onClick={()=>{
  
  settableExpandId(_.get(item,'expand_details.id'));
  setofflineAmount(item?.expand_details?.amount)
}}
style={{fontWeight:500,fontSize:'14px',cursor:'pointer'}}>Offline Payment</div>}
                    
                  </div>
                  }
            },
          
          ]


          const handleOkPaymentModal = (value) => {
            
            setIsManagePaymentModalOPen(false);
            setTransactionDataSource([])
          }

          const handleCancelPaymentModal = () => {
            setIsManagePaymentModalOPen(false);
            setTransactionDataSource([])
          };

          const transactionItems = (id) => {
            props.transactionListAPI({
                params:{
                    order:id,
                    user_type: 1,
                },
                callback: (res, val) => {
                    
                        if(_.size(_.get(val, 'data.items')) > 0){
                            // const transa_items = _.get(val, 'data.items');

                            // const transformedItems = transa_items.reduce((acc, item) => {
                            //     
                            //     const existingItem = acc.find((accItem) => accItem.id === item.parent_transaction);
                              
                            //     if (existingItem) {
                            //       if (!existingItem.refunds) {
                            //         existingItem.refunds = [];
                            //       }
                            //       existingItem.refunds.push(item);
                            //     } else {
                            //       acc.push({
                            //         ...item,
                            //         refunds: null,
                            //       });
                            //     }
                              
                            //     return acc;
                            //   }, []);
                            //   

                            const shop = [
                              {
                                  id: "34",
                                  amount: "400",
                                  name: "Apple",
                                  parent_transcation:null
                              },
                              {
                                  id: "66",
                                  amount: "5400",
                                  name: "Computer",
                                  parent_transcation:null
                              },
                              {
                                  id: "67",
                                  amount: "700",
                                  name: "Orange",
                                  parent_transcation:null
                              },
                              {
                                  id: "12",
                                  amount: "20",
                                  name: "G",
                                  parent_transcation:"34"
                              },
                              {
                                  id: "87",
                                  amount: "800",
                                  name: "Y",
                                  parent_transcation:"66"
                              },
                              {
                                  id: "56",
                                  amount: "300",
                                  name: "Banana",
                                  parent_transcation:"67"
                              },
                              {
                                id: "768",
                                amount: "4000",
                                name: "Green",
                                parent_transcation:"34"
                            },
                          ];
                         
                        };

                        

                        

                       

                    if(_.size(_.get(val, 'data.items')) > 0){
                      const sortedShop = _.get(val, 'data.items').sort((a, b) => {
                        const aParent = a.parent_transaction !== null ? a.parent_transaction : a.id;
                        const bParent = b.parent_transaction !== null ? b.parent_transaction : b.id;
                        return aParent.localeCompare(bParent);
                      });
                      // 
                        let a = [];
                        _.size(_.get(val, 'data.items')) > 0 && _.map(sortedShop, (value, index) => {
                            a.push({
                                key: index,
                                payment_type: <div className='session-details-table'>{_.startCase(_.get(value, 'payment_source'))}</div>,
                                amount: <div className='session-details-table'>${_.get(value, 'amount')}</div>,
                                updated_at: <div className='session-details-table'>{moment(_.get(value, 'updated_at')).format("MMM DD, YYYY")}</div>,
                                status: <div className='session-details-table'>{_.get(value, 'status') === 1 ? <div className='paid-customized-tag'>Paid</div> : <div className='paid-customized-tag'>Pending</div>}</div>,
                                details: value
                            });
                        });
                        setTransactionDataSource(a);
                    }
                }
            })
          };

          const transaction_column = [
            {
                title:<div className='session-details-table'>Payment Type</div>,
                key:"payment_type",
                dataIndex:"payment_type",
                render:(text, item) => {
                    return<>
                        <div style={{display: "flex", flexDirection: "row"}}>{!_.isEmpty(_.get(item, 'details.parent_transaction'))? <BsArrowReturnRight /> : ""}<div className='session-details-table' style={{marginLeft: !_.isEmpty(_.get(item, 'details.parent_transaction')) ? "20px" : ""}}>{_.startCase(_.get(item, 'details.payment_source'))}</div></div>
                    </>
                }
            },
            {
                title:<div className='session-details-table'>Amount</div>,
                key:"amount",
                dataIndex:"amount"
            },
            {
                title:<div className='session-details-table'>Last Updated</div>,
                key:"updated_at",
                dataIndex:"updated_at"
            },
            {
                title:<div className='session-details-table'>Status</div>,
                key:"status",
                dataIndex:"status",
                render: (text, item) => {
                    return <>
                            <div className='paid-customized-tag'>{!_.isEmpty(_.get(item, 'details.parent_transaction')) ? "Refunded" : "Paid"}</div>
                    </>
                }
            },
            {
                title:<div className='session-details-table'>Action</div>,
                key:"actions",
                dataIndex:"actions",
                render:(text, record) => {
                    
                    return <>
                    {
                        _.get(record, 'details.type') === 1 ? <div 
                        onClick={() => { searchParams.get('action') !=='called_off' && handleRefundClick(record)}} 
                          style={{cursor: searchParams.get('action') !=='called_off' ? 'pointer' : 'not-allowed'}}
                        className={selectedData && selectedData.key === record.key ? "header-first-row disabled" :"header-first-row"}>Refund</div> : ""
                    }
                        
                    </>
                }
            },
          ];

          const expand_columns = [
            { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
            { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                    <Button
                      type="primary"
                      onClick={() => handleRefundClick(record)}
                      disabled={selectedData && selectedData.key === record.key}
                    >
                      Refund
                    </Button>
                  ),
              },
          ];


          const paymentChangeStatus =(id)=>{
            
            setinvoiceLoading(true)
            props.paymentChangeStatus({
              body:{status:4,
                id:id,
                cancellation_glossary:"Cancelled"
              },
              params:{id:id},
              callback: (response, data) => {
                setinvoiceLoading(false)
                
                if(response){
                  setinvoiceAmount("")
                  settableExpandId("")
                  getPaymentRequestList(getOrderDetailsData?.id)
                  setshowDeleteConfirm(false)
                  setcancelInvoiceId("")
                  // setpaymentRequestList(_.get(data,'data.items'))
                }
                  
              }
          })
          }
          const paymentOffline =(id)=>{
            
            setinvoiceLoading(true)
            props.paymentOffline({
              body:{type:1,
                id:id,
                cancellation_glossary:"Cancelled",
                label:"Apply Offline Payment",
                payment_source_type:invoiceNote,

              },
              params:{id:id},
              callback: (response, data) => {
                setinvoiceLoading(false)
                
                if(response){
                  setinvoiceAmount("")
                  setofflineAmount("")
                  settableExpandId("")
                  getPaymentRequestList()
                  setshowDeleteConfirm(false)
                  setcancelInvoiceId("")
                  setinvoiceNote("")
                  // setpaymentRequestList(_.get(data,'data.items'))
                }
                  
              }
          })
          };

          const labels =(data)=>{
return data.map((item) =>
  _.size(item.registration_info) > 0 && item.registration_info.map((info) => {
     // Wrap the value in double quotes if it contains commas
     if (info.label.includes(',')) {
       return `"${info.label}"`;
     }
     return info.label;
   })
   );
          } 

                const clauses =(data) =>{
return data.map((item) =>
  _.size(item.clauses) > 0 && item.clauses.map((info) => {
      // Wrap the value in double quotes if it contains commas
      if (info.name.includes(',')) {
        return `"${info.name}", Accepted Time`;
      }
      return `${info.name}, Accepted Time`;
    })
    );
                } 

          function convertToCSV(data, labels, clauses,anotherLabel) {
            let labelsData = _.map(anotherLabel,"label")
            
            const header = [
              "Order ID",
              "Template ID",
              "Program Title",
              "Program Start Date",
              "Program Start Time",
              "Program End Time",
              "Order Status",
              "Refunded",
              "Price Club",
              "Communiti Fee",
              "Communiti Tax",
              "Tax",
              "Discount",
              "Total Paid",
              'Registration First Name', 
              'Registration Last Name', 
              "Registrant Date of Birth",
              "Registrant Gender",
              "Contact First Name",
              "Contact Last Name",
              "Contact Email",
              "Contact Address",
              ...labelsData,
              ...(Array.isArray(clauses[0]) ? clauses[0].flat() : []),
            ].join(',');
          
            const csvData = data.map((item) => {
              // let answer =[]
            let answer =  _.map(anotherLabel,(info)=>{
let filterQuestions =_.filter(item?.registration_info,(value)=>value?.id==info?.id)
if(_.size(filterQuestions)){
return _.get(_.head(filterQuestions),"answer")
}else{
  return "--"
}
             })

     
             
              return[
                item.id,
              _.get(item, 'experience.template.id'),
              _.get(item,'experience.template.title'),
              moment(_.get(item, 'experience.experience_date')),
              moment.tz(_.get(item,'experience.experience_date'), !_.isEmpty(_.get(item,'experience.meta_details.timezone')) ? _.get(item,'experience.meta_details.timezone') : "America/Toronto").format("hh:mm A"),
              moment.tz(_.get(item,'experience.experience_end_date'), !_.isEmpty(_.get(item,'experience.meta_details.timezone')) ? _.get(item,'experience.meta_details.timezone') : "America/Toronto").format("hh:mm A"),
              _.get(item,'paid') === 1 ? "PURCHASED": '-',
              _.get(item,'refund') === 0 ? "NO": 'YES',
              _.get(item,'experience.template.price'),
              _.get(item,'base_platform_amount'),
              _.get(item,'base_platform_tax_amount'),
              _.get(item,'tax_amount'),
              _.get(item,'discount_amount'),
              _.get(item,'total'),
              item.profile.first_name,
              item.profile.last_name,
              !_.isEmpty(_.get(item, 'profile.date_of_birth')) ? _.get(item, 'profile.date_of_birth') : "-",
              _.get(item, 'profile.gender') == "male" || _.get(item, 'profile.gender') == "female" ? _.get(item, 'profile.gender') :`Self Identified (${_.get(item, 'profile.gender')})`,
             _.get(item, 'guest.first_name'),
             _.get(item, 'guest.last_name'),
             _.get(item, 'guest.contact_details.email'),
             !_.isEmpty(_.get(item, 'guest.address')) ? _.get(item, 'guest.address').replace(/<[^>]*>/g, '').replace(/,/g, '').replace(/\n/g, '') : "-",
             answer.length > 0 ? [...answer] : [],
            _.size(item.clauses) > 0 && [...item.clauses.map((clause) => clause.description ? `${clause.description.replace(/<[^>]*>/g, '').replace(/,/g, '').replace(/\n/g, '')},${moment(_.get(item, 'created_at')).format('MMMM Do YYYY h:mm A')}` : `${clause.link},${moment(_.get(item, 'created_at')).format('MMMM Do YYYY h:mm A')}`)],
            //  !_.isEmpty(_.get(item, 'clauses')) ? moment(_.get(item, 'created_at')).format('MMMM Do YYYY h:mm A') : "",
            ]
          });
          const rows = csvData.map((data) => data.join(','));
          
         
          
            return [header, ...rows].join('\n');
          };

          function downloadCSV(data) {
            let timestamp = moment().format('YYYYMMDDHHmmss');

// Create the formatted string
let formattedString = `${_.snakeCase(experienceDetails?.template?.title)}_Registration_${timestamp}`;


           let registration_info = getLabesData(data)
            const csvContent = convertToCSV(data, labels(data), clauses(data),registration_info);
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
          
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${formattedString}.csv`;
          
            document.body.appendChild(a);
            a.click();
          
            window.URL.revokeObjectURL(url);
          };

          const onChangeSelfGender = (e) => {
            setSelfGenderValue(e.target.value)
        };

        const handleScroll = (data, type) => {
          if (activeLoading == false && data && data.top > 0.9 && _.get(activeOrderMeta, 'total') > 25) {
            if (_.size(activeOrderData) < _.get(activeOrderMeta, 'total')) {
              listOrder(_.get(activeOrderMeta, 'limit') + 25)
            }
      
      
          }
      
        }
    const render = () => {
        
        return (
            <div>
              {contextHolder}
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div className='session-static-title'>
              Registrations
              </div>
              </div>
              <p className='session-static-desc'>Manage your registrations and payments for this session.</p>
              <hr/>
              <Table loading={loading} pagination={false} columns={columns} dataSource={dataSource}/>
              <div className='card' style={{marginTop: "16px"}}>
                <div style={{marginTop: "12px", display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                <Radio.Group value={selectedTab} onChange={handleRadioChange} defaultValue="active" buttonStyle="solid" style={{marginLeft: "16px", marginTop: "16px"}}>
              <Radio.Button value="active">Active</Radio.Button>
              <Radio.Button value="cancelled">Cancelled</Radio.Button>
            </Radio.Group>
            <Button onClick={()=>downloadCSV(getDataArray)} 
            style={{marginRight: "16px",  
            marginTop: "16px"}}
            >
              Export .csv
              </Button>
            {/* <ExportJsonCsv fileTitle="Order data" style={{borderRadius: "6px", backgroundColor: "#fff", borderColor: "#D0D5DD", cursor: "pointer", height: "35px", borderWidth: "thin", marginRight: "12px", marginTop: "12px"}} headers={active_headers} items={csvItems}><DownloadOutlined /> Export to .csv</ExportJsonCsv> */}
                </div>
                {
                    selectedTab === "active" && (
                      <Scrollbars 
             className="hidden-scroll-x"
             onScrollFrame={(e) => handleScroll(e)}
             style={{ height: 'calc(100vh - 400px)' }}
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            >
                        <Table 
                        style={{marginTop: "12px"}} 
                        loading={activeLoading} 
                        columns={active_columns} 
                        dataSource={[{ id: 1, title: 'Test' }, ...activeOrderItem]} 
                        pagination={false}
                        rowClassName={(id, key) => {
                            return key == 0 && 'first-row-class'
                          }} onRow={(record, rowIndex) => {
                            return {
                              onClick: event => {
                                if (rowIndex == 0 && _.get(record, 'id') == 1) {
                                    showAddRegistrationModal();
                                }
                      
                              }, // click row
                            };
                          }} 
                        />
                        </Scrollbars>
                    )
                }

                {selectedTab === "cancelled" && (
                        <CancelledRegistrationList/>
                    )

                }
              
              </div>

              <div>
              <Modal 
              centered={true}
              className='common-modal-ctn' 
              title="Add a new registration" 
              open={addRegistration} 
              onOk={handleOkRegistration} 
              onCancel={cancelAddRegistration}
              footer={null}
              >
                <div>
                    <p className='session-static-desc'>You are manually adding a registration to the following session:</p>
                    <hr/>
              <Table loading={loading} pagination={false} columns={columns} dataSource={dataSource}/>
              {
                saveContactInfo && (
                    <div>
              <div className='session-static-title' style={{marginTop: "12px"}}>
              Enter registration info
              </div>
              <p className='session-static-desc' style={{marginTop: "16px"}}>Add the information of the person you are registering.</p>

              <Form 
                        layout='vertical'
                        form={form}
                        onFinish={(e) => saveNext(e)}
                        >
                        
                          <hr/>
                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Email</div>
                          <div className='container-titles-desc'>Enter the email of the person you are adding. If they have registered with Communiti before, their information will autopopulate in the name section below.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item 
                            label={<div className='container-titles'>Email</div>}
                            name="email" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email',
                                }
                            ]}
                            >
                            <Input onChange={(e) => handleSearch(e.target.value)} onKeyDown={handleKeyDown}  style={{ width: "100%", height: "40px"}}/>
                            {/* <AutoComplete
                            style={{ width: "100%", height: "40px"}}
                            onSearch={handleSearch}
                            placeholder="Search"
                            onSelect={selectedValue}
                            onChange={(e) => changeSelect(e)}
                        >
                            {options.map((option) => {
                               
                                return (
                                    <AutoComplete.Option key={_.get(option, 'id')} value={_.get(option, 'email')}>
                                    {_.get(option, 'email')}
                                </AutoComplete.Option>
                                )
                            }
                            
                            )}
                        </AutoComplete> */}
                            </Form.Item>
                          </div>
                          </div>
                          <hr/>
                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Contact info</div>
                          <div className='container-titles-desc'>If the person has not registered with Communiti before, this will temporarily create an account when you register them. Enter their information here.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                              <Form.Item
                              label={<div className='container-titles'>First Name</div>}
                              name="first_name"
                              rules={[
                                {
                                    required: true,
                                    message: 'Please input your first name',
                                }
                            ]}
                              >
                                <Input
                                // disabled={!_.isEmpty(selectedOption)}
                                className={ !_.isEmpty(selectedOption) ?'disabled' : ''}
                              style={{width: "100%", height: "40px"}}
                            />
                            {/* </div> */}
                            </Form.Item>
                            <Form.Item
                              label={<div className='container-titles'>Last Name</div>}
                              name="last_name"
                              rules={[
                                {
                                    required: true,
                                    message: 'Please input your last name',
                                }
                            ]}
                              >
                                <Input
                                // disabled={!_.isEmpty(selectedOption)}
                                className={ !_.isEmpty(selectedOption) ?'disabled' : ''}
                              style={{width: "100%", height: "40px"}}
                            />
                            </Form.Item>
                            
                            <Form.Item
                              label={<div className='container-titles'>Country</div>}
                              name="country"
                            //   rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input your country',
                            //     }
                            // ]}
                              >
                               <Select
                               className={ !_.isEmpty(selectedOption) ?'disabled' : ''}
                            //    disabled={!_.isEmpty(selectedOption)}
                            onChange={handleSelectChange}
                            value={tempSelectedOption}
                            // defaultValue={selectedOption}
                            style={{width:'100%', height: "40px"}}
                                optionFilterProp="children">
                            {_.map(countryList,(v,i)=>{
                                return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
                            })}
                            </Select>
                            {/* </div> */}
                            </Form.Item>
                            <Form.Item
                              label={<div className='container-titles'>Address</div>}
                              name="location"
                            //   rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input your address',
                            //     }
                            // ]}
                              >
                               <PlacesAutocomplete
                              
                              value={address}
                              onChange={handleChange1}
                              onSelect={handleSelect}
                              searchOptions={searchOptions}
                              
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className={ !_.isEmpty(selectedOption) ?'disabled' : ''}>
                                  <Input style={{height: "40px"}} {...getInputProps({ placeholder: 'Search address' })}  />
                                  {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
                                  <div>
                                    {loading ? <div>Loading...</div> : null}
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
                                        marginTop:10,
                                      };
                                      
                                      return (
                                        <div {...getSuggestionItemProps(suggestion, { style })}>
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            {/* </div> */}
                            </Form.Item>
                            <Form.Item>
                              <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end", marginTop: "12px"}}>
                              <Button htmlType="submit" style={{height: "40px"}}>Save</Button>
                              </div>
                            </Form.Item>
                          </div>
                          
                         
                          </div>
                        </Form>
                        </div>
                )
              }

              {
                registrationProfile && (
                    <div>
              <div className='session-static-title' style={{marginTop: "12px"}}>
              Enter registration profile
              </div>
              <p className='session-static-desc' style={{marginTop: "16px"}}>Add the registration information of the person who is participating in the session.</p>

              <Form 
                        layout='vertical'
                        form={form}
                        onFinish={(e) => saveRegProfile(e)}
                        >
                        
                          <hr/>
                            <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                          <div className='container-titles'>Create or select existing profile</div>
                          <div className='container-titles-desc'>Existing Communiti accounts may have an existing registration profile you can select from here. If none exist, create a new registration profile.</div>
                          </div>
                          
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item
                            label="Create or select an existing registration profile"
                            >
                          <div style={{display: "flex", flexDirection: "row"}}>
                            {
                                _.size(guestItems) > 0 && (
                                    <div
                               className={`card ${selectedCard === true ? 'cardselected' : ''}`}
                               style={{ borderRadius: '12px', marginBottom: '10px' }}
                                onClick={() => handleCardClick(true)}
                            >
                                <div className='card-body'>
                                <p className={`card-title custom-card-title ${selectedCard === true ? 'titleselected' : ''}`}>Select an existing profile</p>
                                <p className={`card-text custom-card-title ${selectedCard === true ? 'textselected' : ''}`}>
                                Select one of the existing profiles under this account.
                                </p>
                                </div>
                            </div>
                                )
                            }
                          

                            <div
                             className={`card w-5 ${selectedCard === false ? 'cardselected' : ''}`}
                                style={{  borderRadius: '12px', marginLeft: "8px", marginBottom: '10px'}}
                                onClick={() => handleCardClick(false)}
                            >
                                <div className='card-body'>
                                <p className={`card-title custom-card-title ${selectedCard === false ? 'titleselected' : ''}`}>Create a new profile</p>
                                <p className={`card-text custom-card-title ${selectedCard === false ? 'titleselected' : ''}`}>
                                Create a new registration profile under this account.
                                </p>
                                </div>
                            </div>
                          </div>
                          </Form.Item>
                          
                          </div>
                          
                          </div>
                          {
                                   _.size(guestItems) > 0 && selectedCard === true && (
                                        <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                          <div className='container-titles'>Choose an existing profile</div>
                          <div className='container-titles-desc'>Choose the profile of the person who is participating in the session.</div>
                          </div>
                          
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item
                            label="Select a registration profile"
                            >
                                
                          <div style={{display: "flex", flexDirection: "column"}}>
                          {_.size(guestItems) > 0 && guestItems.map((item) => (
                                    <div key={item.id} className="card" style={{ border: selectedProfileCard === item.id ? "2px solid #E04F16" : '2px solid #D0D5DD', borderRadius: '12px', marginBottom: '10px'}} 
                                    onClick={() => handleProfileCardClick(item.id)}>
                                   <p style={{marginLeft: "12px", marginTop: "12px"}} className={`card-title custom-card-title ${selectedProfileCard === item.id ? 'titleselected' : ''}`}>{`${_.startCase(_.get(item, 'first_name'))} ${_.startCase(_.get(item, 'last_name'))}`}</p>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                <p style={{marginLeft: "12px"}} className={`card-text custom-card-title ${selectedProfileCard === item.id  ? 'textselected' : ''}`}>
                                {_.startCase(_.get(item, 'gender'))}
                                </p>
                                <div className={selectedProfileCard === item.id ? `customized-tag-selected` : `customized-tag`} style={{marginRight: "20px", borderRadius: "16px"}}>{_.startCase(_.get(item, 'profile_type'))}</div>
                                </div>
                                
                                
                                <p style={{marginLeft: "12px"}} className={`card-title custom-card-title ${selectedProfileCard === item.id ? 'titleselected' : ''}`}>{moment(_.get(item, 'date_of_birth')).format("MMM DD, YYYY")}</p>
                                    </div>
                                ))}
                          </div>
                          </Form.Item>
                          
                          </div>
                          
                          </div>
                                    )
                        }

                        {
                            selectedCard === false && (
                                <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                          <div className='container-titles'>Profile type</div>
                          <div className='container-titles-desc'>Select whether this person registering<br/> is a dependent.</div>
                          </div>
                          
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item
                            label="Profile type"
                            >
                                 <div style={{display: "flex", flexDirection: "row"}}>
                          <div
                               className={`card ${selectedProfileTypeCard === true ? 'cardselected' : 'notselectedcard'}`}
                               style={{ borderRadius: '12px', marginBottom: '10px' }}
                                onClick={() => chooseProfileType(true)}
                            >
                                <div className='card-body'>
                                <p className={`card-title custom-card-title ${selectedProfileTypeCard === true ? 'titleselected' : ''}`}>Self</p>
                                <p className={`card-text custom-card-title ${selectedProfileTypeCard === true ? 'textselected' : ''}`}>
                                The person is registering themself for this session.
                                </p>
                                </div>
                            </div>

                            <div
                             className={`card w-5 ${selectedProfileTypeCard === false ? 'cardselected' : 'notselectedcard'}`}
                                style={{ borderRadius: '12px', marginLeft: "8px", marginBottom: '10px'}}
                                onClick={() => chooseProfileType(false)}
                            >
                                <div className='card-body'>
                                <p className={`card-title custom-card-title ${selectedProfileTypeCard === false ? 'titleselected' : ''}`}>Dependent</p>
                                <p className={`card-text custom-card-title ${selectedProfileTypeCard === false ? 'titleselected' : ''}`}>
                                The person registering for the session is a dependent.
                                </p>
                                </div>
                            </div>
                          </div>
                          </Form.Item>
                          
                          </div>
                          
                          </div>
                            )
                        }

                        {
                         selectedCard === false &&  selectedProfileTypeCard === true || selectedProfileTypeCard === false ? (
                                <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1}}>
                          <div className='container-titles'>Name, gender and date of birth</div>
                          <div className='container-titles-desc'>Enter the name, gender and date of birth for the person participating in the session.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item 
                            label={<div className='container-titles'>First Name</div>}
                            name="profile_first_name" 
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your first name"
                                }
                            ]}
                            >
                            <Input style={{ width: "100%", height: "40px"}}/>
                            </Form.Item>
                            <Form.Item 
                            label={<div className='container-titles'>Last Name</div>}
                            name="profile_last_name" 
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your last name"
                                }
                            ]}
                            >
                            <Input style={{ width: "100%", height: "40px"}}/>
                            </Form.Item>
                            <Form.Item 
                            label={<div className='container-titles'>Gender</div>}
                            name="profile_gender" 
                            rules={[
                                {
                                    required: true,
                                    message: "Please select your gender"
                                }
                            ]}
                            >
                             <Select
                                      onChange={handleChangeGender}
                                      options={[
                                        {
                                          value: 'male',
                                          label: 'Male',
                                        },
                                        {
                                          value: 'female',
                                          label: 'Female',
                                        },
                                        {
                                          value: 'self',
                                          label: 'Self-identify',
                                        },
                                      ]}
                                      style={{height: "40px"}}
                                    />
                            </Form.Item>
                            {
                                    isGender && <Input onChange={(e) => onChangeSelfGender(e)}/>
                                  }
                            <Form.Item 
                            label={<div className='container-titles'>Date of Birth</div>}
                            name="profile_dob" 
                            rules={[
                                {
                                    required: true,
                                    message: "Please select your date of birth"
                                }
                            ]}
                            >
                            <DatePicker disabledDate={disabledDate} style={{width: "100%", height: "40px"}} onChange={onChangeDob} />
                            </Form.Item>
                          </div>
                          </div>
                            ) : ''
                        }

                
                          
                          
                          <Form.Item>
                              <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", marginTop: "12px"}}>
                              <Button onClick={() => handleBack()} htmlType="submit" style={{height: "40px"}}>Back</Button>
                              <Button htmlType="submit" style={{height: "40px"}}>Save & Complete Registration</Button>
                              </div>
                            </Form.Item>
                        </Form>
                        </div>
                )
              }
              
                </div>
            </Modal>

            <div>
                   <Modal 
                   centered={true}
              className='common-modal-ctn' 
              title="Manage Registration" 
              open={isManageRegModalOPen} 
              onOk={handleManageRegOk} 
              onCancel={handleManageRegCancel}
              footer={null}
              >
                <div>
                <p className='session-static-desc'>You are manually adding a registration to the following session:</p>
              <hr/>
              <Table loading={loading} pagination={false} columns={columns} dataSource={dataSource}/>

              <div className='session-static-title' style={{marginTop: "12px"}}>
              Registrant & Contact Information
              </div>
              <p className='session-static-desc' style={{marginTop: "12px"}}>Below is the information of the registrant participating in the session.</p>
                  <Table 
                  columns={registrant_column}
                   dataSource={reg_dataSource} 
                   pagination={false}
                   />
                   <div className='session-static-title' style={{marginTop: "12px"}}>
                   Registrant Additional Information
              </div>
              <p className='session-static-desc'>Below are the responses and timestamps provided by the participant to the additional questions and clauses displayed upon registration.</p>

              <div>
                            {
                               !_.isEmpty(_.get(regDetails, 'details.registration_info')) && <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                               <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                                 <div className='container-titles'>Question & Reponses</div>
                                 <div className='container-titles-desc'>These are the responses provided by the registrant to the questions displayed to them upon registration.</div>
                                 </div>
                                 <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                                  
                                  <Table columns={additional_column} dataSource={registrationInfo} pagination={false}/>
                                 </div>
                                 </div>
                            }
                          </div>
                          <div>
                    {
                      !_.isEmpty(_.get(regDetails, 'details.experience.clauses')) && <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                      <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                        <div className='container-titles'>Clauses & Acceptance Timestamp</div>
                        <div className='container-titles-desc'>These are the clauses and acceptance timestamps the registrant completed upon registration.</div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                         
                         <Table columns={clause_column} dataSource={clauseInfo} pagination={false}/>
                        </div>
                        </div>
                    }
                   </div>
                            <hr/>
                            { searchParams.get('action') !== 'called_off' && <div>
                          <div className='session-static-title' style={{marginTop: "12px"}}>
                          Manage Registration Status
                        </div>
                        <p className='session-static-desc'>Below you can change this registrant’s status for this session.</p>

                        <div style={{display: "flex", justifyContent: "space-between",marginTop: "20px"}}>
                        <div className='cancel-note-title'>
                        Cancel Registration
                        </div>
                        <Button onClick={() => cancelRegistration()}>Cancel Registration</Button>
                        </div>
                        <p className='session-static-desc'>This set’s a registrant’s status to “Cancelled”.</p>
                        <p className='cancel-note-title'>Note: Cancelling a registration does not automatically trigger a refund. You will need to do this under the “Manage Payment” section. It is recommended to complete your refund (if necessary) prior to cancellation.</p>
                        </div>}
                </div>

                </Modal>
                   </div>

                   <div>
              <Modal centered={true} className='common-modal-ctn' title="" footer={null} open={isEmailModalOpen} onOk={handleEmailOk} onCancel={handleEmailCancel}>
              <Table loading={loading} pagination={false} columns={columns} dataSource={dataSource}/>
               <Tabs
               className='tab-email-ctn'
               style={{marginTop:'20px'}}
        defaultActiveKey="1"
        type="card"
        size={'middle'}
        items={[
          {
            key: '1',
            label: <div style={{color:'#fff',fontSize:'14px',fontWeight:600}} className='title-tab-email'>Email</div>,
            children: 
            <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            layout='vertical'
          >
            {/* <div style={{ marginBottom: "10px" }}>{_.get(getExpId, 'template_name')}</div> */}
            <Form.Item
              label="Email"
              name="emails"
              rules={[
                {
                  required: true,
                  message: 'Please input atleast one email!',
                },
              ]}
            >
             
              <Input readOnly/>

            </Form.Item>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please input your title!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: 'Please input your message!',
                },
              ]}
            >
              
              <ReactQuill style={{height:'100px'}} theme="snow" />
            </Form.Item>
            <Form.Item
            >
              <Button style={{ width: "100%", backgroundColor: "#e85d04", color: "#fff", marginTop: "50px" }} type="primary" htmlType="submit">
                Send
              </Button>
            </Form.Item>
          </Form>,
          },
          {
            key: '2',
            label: <div style={{color:'#fff',fontSize:'14px',fontWeight:600}} className='title-tab-email'>Chat</div>,
            children: chennelData !='' ?<Chat channelData={chennelData} />:null,
          },
        ]}
      />
               
                {/*  */}
              </Modal>
            </div>
                   
                   
                   <div>
                   <Modal 
                   centered={true}
              className='payment-modal-ctn' 
              title={<div className='session-static-title'>Manage Payment</div>}
              open={isManagePaymentModalOPen} 
              onOk={handleOkPaymentModal} 
              onCancel={handleCancelPaymentModal}
              footer={null}
              >
                <div>
                <p className='session-static-desc'>You are managing payment for the following session and registrant:</p>
                <hr/>
              <Table columns={manage_payment_column} dataSource={managePaymentDataSource} pagination={false} />

              <div className='session-static-title' style={{marginTop: "12px"}}>
              Order Status & Breakdown
                        </div>
                        <p className='session-static-desc'>Below is the order status and payment breakdown for this registrant:</p>

                        <Table columns={order_column} dataSource={orderDataSource} pagination={false} />
                
                        <div className='session-static-title' style={{marginTop: "12px"}}>
                        Invoices
                        </div>
                        <p className='session-static-desc'>You can send invoices to the registrant from here. Sending an invoice will trigger an email to the registrant. They can either pay online via Stripe, or you  can apply an offline payment.</p>

                        <div style={{display:'flex'}}>
                          <div style={{width:'30%'}}>
                          <p className='session-static-title' style={{color:'#344054',fontWeight:400,fontSize:'14px',marginBottom:'0px'}}>Send and view pending invoices</p>
                          <p className='session-static-desc'>Click the +Send Invoice button to send the registrant an invoice.</p>
                          <p className='session-static-desc'>You can also view pending invoices here. Once they are paid the will automatically be listed in the detailed transactions table below.</p>
                        
                          </div>
                          <div style={{width:'65%',marginLeft:'4%'}}>
                        <Table
                        
                         rowClassName={(id, key) => {
                          return key == 0 && 'first-row-class'
                        }} onRow={(record, rowIndex) => {
                          return {
                            onClick: event => {
                              if (rowIndex == 0 && _.get(record, 'id') == 1) {
                                  // showAddRegistrationModal();
                                  

                                  if(_.get(record, 'id')==tableExpandId){
                                    // settableExpandId("")
                                  }else{
                                    // settableExpandId(_.get(record, 'id'))
                                  }
                                  
                              }
                              
                    
                            }, // click row
                            // colSpan:_.get(record, 'id') == 1?5:1,
                            // rowSpan:0,
                            colSpan:tableExpandId==_.get(record, 'id') ? 0:tableExpandId==_.get(record,'expand_details.id')?0:1
                            

                            
                          };
                        }} 

                        
                        columns={invoice_column} dataSource={[{id:1},...paymentRequestList]} pagination={false} />
                        </div>
                        </div>
                </div>

                <div className='session-static-title' style={{marginTop: "12px"}}>
                Detailed Transactions
                        </div>
                        <p className='session-static-desc'>Below are the detailed transactions that comprise the above order. You can refund from the table below.</p>

                        <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                          <div className='container-titles'>Create or select existing profile</div>
                          <div className='container-titles-desc'>Existing Communiti accounts may<br/> have an existing registration profile<br/> you can select from here.<br/> If none exist, create a new registration<br/> profile.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Table 
                            columns={transaction_column} 
                            dataSource={getTransactionDataSource} 
                            pagination={false}
                            expandedRowRender={expandedRowRender}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={handleExpand}
                             />
                          </div>
                          
                          </div>

              </Modal>
                   </div>
              </div>
              <Modal
              centered={true} maskClosable
                 onCancel={()=>setshowDeleteConfirm(false)} 
                  style={{padding:'68px 18px',width:'936px'}} footer={null} open={showDeleteConfirm}>
                  <div style={{    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',}}>
                  <span style={{    fontSize: '18px',fontWeight: '600'}}>Are you sure you wish to cancel this invoice?</span>
                  {/* <p style={{color:'#475467',fontSize:'14px',marginTop:'6px'}}>All the data you’ve inputted for this session will be removed and you will need to enter it again.</p> */}
                  
                  </div>
                  <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '22px'}}>   
    <Button 
    disabled={invoiceLoading}
    onClick={()=>setshowDeleteConfirm(false)} style={{backgroundColor:'#FFF',marginRight:23,width:'23%',borderRadius:'8px',border:'1px solid #D0D5DD',height:'40px'}}
             >Cancel</Button>
                  <Button loading={invoiceLoading} onClick={()=>{
                    if(cancelInvoiceId){
                      paymentChangeStatus(cancelInvoiceId)
                    }
                  }}  style={{backgroundColor:'#FFF',borderRadius:'8px',border:'1px solid #D0D5DD',height:'40px'}}
             >Continue</Button></div>
                  </Modal>
          </div>
          )
    };

    return render();
  
}

const mapStateToProps = state => {
    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    experienceViewAPI: details => dispatch(global.redux.action.experience.view(details)),
    orderListAPI: details => dispatch(global.redux.action.experience.orderList(details)),
    searchGuestAPI: details => dispatch(global.redux.action.guest.guestList(details)),
    proceedRefundAPI: details => dispatch(global.redux.action.experience.refund(details)),
    taxList: details => dispatch(global.redux.action.tax.list(details)),
    guestProfileListAPI: details => dispatch(global.redux.action.guest.guestProfileList(details)),
    guestCreateAPI: details => dispatch(global.redux.action.guest.guestCreate(details)),
    guestProfileCreateAPI: details => dispatch(global.redux.action.guest.guestProfilesCreate(details)),
    manualOrderCreateAPI: details => dispatch(global.redux.action.guest.manualOrderCreate(details)),

    getPaymentRequestList: details => dispatch(global.redux.action.finances.getPaymentRequestList(details)),
    paymentRequestsCreate: details => dispatch(global.redux.action.finances.paymentRequestsCreate(details)),
    paymentStripe: details => dispatch(global.redux.action.finances.paymentStripe(details)),
    paymentOffline: details => dispatch(global.redux.action.finances.paymentOffline(details)),
    paymentChangeStatus: details => dispatch(global.redux.action.finances.paymentChangeStatus(details)),
    transactionListAPI: details => dispatch(global.redux.action.experience.transList(details)),
    orderRefundAPI: details => dispatch(global.redux.action.guest.orderRefund(details)),

    sendEmailAPI: details => dispatch(global.redux.action.sendEmail.postEmails(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(RegistrationList)