import React, {useEffect, useState} from 'react'
import { Input, Form, Button, notification, message } from 'antd';
import { connect } from 'react-redux';
import _ from "lodash";
import global from '../../core_units/config/global'
// import "./style.css";

const { Search } = Input;

const UserHandle = (props) => {
    const [form] = Form.useForm();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const[checkAvailablityState, setCheckAvailablityState] = useState();
    const [getHandleData, setHandleData] = useState('');
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        getProfile();
    }, []);

    useEffect(() => {
        if (showMessage) {
          const timer = setTimeout(() => {
            setShowMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      }, [showMessage]);
    
      useEffect(() => {
        if (showSuccessMessage) {
          const timer = setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
    
          return () => clearTimeout(timer);
        }
      }, [showSuccessMessage]);

      const success = () => {
        messageApi.open({
          type: 'success',
          content: 'Hande updated',
        });
      };

    const getProfile = () => {
        props.hostProfileData({
            callback: (res, data) => {
                
                if(res){
                    form.setFieldsValue({
                        user_handle : _.get(data,'data.details.handle'),
                    })
                    setHandleData(_.get(data,'data.details.handle'))
                }
            }
        })
    }

    const checkUserAvailablity = () => {
      props.checkUserHandleAvailablityAPI({
        params:{
          handle: getHandleData
        },
        callback: (res, data) => {
          if(res){
           
            if(_.get(data, 'data.availability')){
              setCheckAvailablityState(_.get(data, 'data.availability'))
              setShowSuccessMessage(true);
            }  else {
              setCheckAvailablityState(_.get(data, 'data.availability'))
              setShowMessage(true)
            }
          
          } 
        }
      })
    }

    const updateHandle = () => {
      props.updateUserHandleAPI({
        body:{
          handle: getHandleData
        },
        callback: (updatedResponse, updatedValue) => {
          
          if(updatedResponse){
            success()
            getProfile();
            setCheckAvailablityState(false)
        }
        }
      })
    };

    const onCopyURL = (value) => {
      
      navigator.clipboard.writeText(value)
      .then(() => {
        
        messageApi.open({
          type: 'success',
          content: 'Copied',
        });
      })
      .catch(err => {
        console.error('Error copying value:', err);
      });
    }
const render = () => {
    return (
      <>
      {contextHolder}
      <div style={{display: "flex", flexDirection: "row"}}>
        <Form form={form}>
            <Form.Item style={{marginBottom: "5px"}} name="user_handle">
               <Input
               onChange={(e) =>setHandleData(e.target.value)}
               addonBefore="https://book.communiti.app/"
               placeholder='Enter your handle'
               />
           </Form.Item>
           {
             checkAvailablityState === false && showMessage && <div className='user-handle-error-message'>Already taken</div>
           }
           {
             checkAvailablityState && showSuccessMessage && <div className='user-handle-success-message'>Available</div>
           }
       </Form>
       {
        checkAvailablityState === true ?
        <Button 
        role='update_button'
        style={{color: "#fff", backgroundColor: "#e85f24", marginLeft: "10px"}}
        onClick={() => updateHandle()}
        >Update Hande</Button>
        : <div>
          <Button 
        style={{color: "#fff", backgroundColor: "#e85f24", marginLeft: "10px"}}
        onClick={() => checkUserAvailablity()}
        >Confirm availability</Button>
        <Button onClick={() =>onCopyURL(`https://book.communiti.app/${getHandleData}`)} style={{marginLeft: "8px", backgroundColor: "#e85f24", color: "#fff"}}>Copy link</Button>
        </div>
       }
        
       </div>
       </>
       )
}

return render();
  
}

const mapStateToProps = state => {
    return {
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return{
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    checkUserHandleAvailablityAPI: details => dispatch(global.redux.action.userHandle.checkUserHandleAvailablity(details)),
    updateUserHandleAPI: details => dispatch(global.redux.action.userHandle.createOrUpdateUserHandle(details)),
    }
   
  }
  export default connect(mapStateToProps, mapDispatchToProps)(UserHandle)