import {  TextField, Typography, Container, Box, InputAdornment, styled, makeStyles } from '@mui/material';
import { Formik, Field, ErrorMessage, useFormikContext } from 'formik';
import { Form, Input,Button, Select, AutoComplete } from 'antd';
import * as Yup from 'yup';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import _ from 'lodash';

import "../style.css";
import { connect } from 'react-redux';
import FinalForm from './invite-form';
import CommonLoader from './common-loader';

import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useEffect, useState } from 'react';
const validationSchema = Yup.object().shape({
  is_taxes_collected: Yup.string().required('Please select an option'),
 country: Yup.string().required('Please select an option'),
 location: Yup.string().required('Please select address'),
    });

    const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
];

const { Option } = Select;


 
const AddressForm =(props)=>{
  
   const [form] = Form.useForm();
      const [userDetails, setuserDetails] = useState({})
      const formikProps = useFormikContext()
      const [initialValues, setInitialValues] = useState({
        is_taxes_collected: '',
       country: '',
       location: '',
           })

       const [clubCoachInfoForms, setClubCoachInfoForms] = useState(2);
       const [tempSelectedOption, setTempSelectedOption] = useState(null);
       const [address, setAddress] = useState('');
       const [address_results, setaddress_results] = useState([]);
       const [address_fetching, setaddress_fetching] = useState(false);
       const [TaxListData, setTaxListData] = useState([]);
       const [addressFormDetails, setAddressFormDetails] = useState({
        is_taxes_collected: '',
           country: '',
           location: ''
       })
         
      useEffect(() => {
             
             let conditionType = _.includes(_.get(props,'islogindata.types'),1)==true?'hostProfile':'guestProfile';
             if(_.get(props,`${conditionType}.id`)){
                    setuserDetails(_.get(props,`${conditionType}`))
                    setInitialValues({
                           ...initialValues
                    })
             }

             loadTaxList();
            
      }, []);
          
      const isJson=(str)=> {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      };
        
           const handleSubmit = (values) => {
             // props?.onChangeFlow(3)
             
             if (isJson(_.get(_.head(address_results),'value'))) {
              let location_parse = JSON.parse(_.get(_.head(address_results),'value'))
              setAddressFormDetails({
                is_taxes_collected: _.get(values, 'is_taxes_collected'),
                 country: _.get(values, 'country') === 'USD' ? "US" : "CA",
                 location: `${_.get(location_parse,'lat')},${_.get(location_parse,'lng')}`,
                 address: _.get(location_parse,'text')
               })
             setClubCoachInfoForms(3);
             handleSendDataParent(3)
            }
            
           };

          

   const customFieldLabel =(text)=>{
return <Box sx={{display:'flex',alignItems:'flex-start'}}><Typography
sx={{color:"#344054",fontSize:'14px',fontWeight:"500"}}
>{text}</Typography><Typography sx={{color:"#344054",fontSize:'14px',fontWeight:"500"}}>*</Typography></Box>
   }  
  
   const customErrorText =(text)=>{
return <Box component={"span"} sx={{display:'flex',alignItems:'flex-start',marginTop:'6px'}}><Typography
sx={{color:"#F04438",fontSize:'14px',fontWeight:"400"}}
>{text}</Typography></Box>
   }

   const handleSelectChange = (value) => {
       form.setFieldsValue({
           location: ''
       })
       setTempSelectedOption(value);
     };

     const loadTaxList =()=>{
       props.taxList({
         params:{limit:100},
         callback:(res,data)=>{
          
       if(res==true && _.get(data,'data.items[0]'))
           setTaxListData(_.get(data,'data.items'))
         }
       })
           }

     const handleChange1 = (value) => {
       setAddress(value);
     };

     const fetchPostalCode = address_components => {
       let postal_code = null;
       address_components.forEach(value => {
           value.types.forEach(type => {
               if (type === 'postal_code') {
                   postal_code = value.long_name
               }
           })
       })
       return postal_code;
   };

     const handleSelect = async (value) => {

       
       setaddress_results([])
         setaddress_fetching(true);
         geocodeByAddress(value).then(body => {
            
             const address_results = _.map(body, location => ({
                 text: location.formatted_address,
                 value: JSON.stringify({ text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
             }));
    
             setaddress_results(address_results)
             setaddress_fetching(false);
           form.setFieldsValue({
               location: value
           })
          
           let state =extractStateName(body)
           let ada = _.filter(TaxListData,(v)=>_.lowerCase(v.state)==_.lowerCase(state))
           if(_.size(ada)>0){
             form.setFieldsValue({
               tax_state: _.get(ada[0],'id')
           })
           }
          
          
           setaddress_results(address_results)
         }).catch(error => {
             setaddress_results([])
             setaddress_fetching(true);
         });
     };

     const extractStateName = results => {
       for (let i = 0; i < results[0].address_components.length; i++) {
         const component = results[0].address_components[i];
         if (component.types.includes('administrative_area_level_1')) {
           return component.long_name; // Return the state name
         }
       }
       return '';
     };

     const searchOptions = {
       componentRestrictions: { country: form.getFieldValue('country')=="USD"?['us']:['ca'] }, // Restrict search to United States and Canada
     };

     const handleSendDataParent = (value) => {
      // Call the callback function passed from parent to send data
      props.sendDataToParent(value);
    };

    

return <>
     

{clubCoachInfoForms === 2 && <Formik
     initialValues={initialValues}
     validationSchema={validationSchema}
     onSubmit={handleSubmit}
     enableReinitialize={true}
   >
     {({setFieldValue, handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
       <Form form={form} className='personal-info-form' layout='vertical' onFinish={handleSubmit}>
        
           <Form.Item
           style={{ marginBottom: touched.is_taxes_collected && errors.is_taxes_collected ? "40px" : '20px' }}
           help={touched.is_taxes_collected && errors.is_taxes_collected ? customErrorText(errors.is_taxes_collected) : ''}
           label={customFieldLabel("Will you be collecting taxes on your listings?")}
           validateStatus={touched.is_taxes_collected && errors.is_taxes_collected ? 'error' : ''}
           id='is_taxes_collected'
           key={"is_taxes_collected"}
           name='is_taxes_collected'
         >

       <Select
       placeholder="Select an option"
       id='is_taxes_collected'
       key={"is_taxes_collected"}
       name='is_taxes_collected'
     style={{
       width: '100%',
       height: "44px",
       textAlign:'left',
       boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
     }}
     onChange={(value) => setFieldValue('is_taxes_collected', value)}
     options={[
       {
         value: 'yes',
         label: 'Yes',
       },
       {
         value: 'no',
         label: 'No',
       }
     ]}
     rootClassName='common-placeholder-container'
   />
         </Form.Item>

         <Form.Item
          style={{ marginBottom: touched.country && errors.country ? "40px" : '20px' }}
          help={touched.country && errors.country ? customErrorText(errors.country) : <Box sx={{marginBottom:'16px'}}><Typography component={"div"} align="left"
          sx={{
                marginTop:'6px',
                color:"#475467",
                fontSize: {
                       xs: "15px", // mobile
                       sm: "15px", // tablet
                       md: "14px", // small desktop
                       lg: "14px",
                     },
                    
          }}
          >
          Select the country where you will be offering lessons/programs
          </Typography> </Box>}
          label={customFieldLabel("Country")}
          validateStatus={touched.country && errors.country ? 'error' : ''}
         name='country'
       >
       <Select
        placeholder="Select an option"
       name='country'
       id='country'
       key='country'
       onChange={(value) => {handleSelectChange(value); setFieldValue('country', value)}}
       value={tempSelectedOption}
                           // defaultValue={selectedOption}
       style={{width:'100%', height: "44px", textAlign:'left', boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}}
       optionFilterProp="children"
       rootClassName='common-placeholder-container'
       >
       {_.map(countryList,(v,i)=>{
       return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
        })}
       </Select>
       
       </Form.Item>

       <Form.Item
        style={{ marginBottom: touched.location && errors.location ? "40px" : '20px' }}
        help={touched.location && errors.location ? customErrorText(errors.location) : ''}
        label={customFieldLabel("Address")}
        validateStatus={touched.location && errors.location ? 'error' : ''}
        name="location"
        className='common-placeholder-container'
                           >
                           <PlacesAutocomplete
                          
                          key='location'
                            value={address}
                            onChange={(value) => {handleChange1(); setFieldValue('location', value)}}
                            onSelect={handleSelect}
                            searchOptions={searchOptions}
                            
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <Input 
                                // rootClassName='common-placeholder-container'
                                name="location" 
                                id='location' 
                                key={'location'} 
                                style={{height: "44px", boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}} 
                                {...getInputProps({ placeholder: 'Search address' })} 
                                rootClassName='common-placeholder-container'
                               //  onChange={(value) => setFieldValue('location', value)}
                                 />
                                {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
                                <div>
                                  {loading ? <div>Loading...</div> : null}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
                                      marginTop:10,
                                      textAlign:'left',
                                      cursor: "pointer"
                          
                                    };
                                   
                                    return (
                                      <div {...getSuggestionItemProps(suggestion, { style })} >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          
                           </Form.Item>
                           
                           

         <Form.Item>
           <Button
           type="primary" htmlType="submit"
           style={{
             width: '100%', marginTop: '4px', height: '44px', background: "#EF6820", color: "#FFF",
             fontSize: '16px',
             fontWeight: "600"
           }}>
             Continue
           </Button>
         </Form.Item>
      

     {/* 2nd form */}

     {/* {
       clubCoachInfoForms === 2 && <>
      
     </>
     } */}
       </Form>
     )}
   </Formik>}

   {
       clubCoachInfoForms === 3 && <FinalForm onChangeFlow={props.onChangeFlow} personalInfo={props?.personalInfo}  businessFormData={_.get(props, 'businessFormData')} addressFormDetails={addressFormDetails}/>
   }

   {/* {
    clubCoachInfoForms === 3 && <CommonLoader/>
   } */}
</>

}


const mapStateToProps = state => {
      
      return {
         checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
         isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
         islogindata: _.get(state, 'app.account.isLogin'),
         hostProfile: _.get(state, 'app.host.profile.data.details'),
         guestProfile: _.get(state, 'app.guest.profile.data.details')
   
      }
    }
   
    const mapDispatchToProps = dispatch => ({
      isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
      hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
      guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
      list: details => dispatch(global.redux.action.categories.list(details)),
      hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
      guestUpdateProfile: details => dispatch(global.redux.action.guest.update(details)),
      updatePhone: details => dispatch(global.redux.action.guest.updatePhone(details)),
      request: details => dispatch(global.redux.action.host.request(details)),
      hostCreate: details => dispatch(global.redux.action.host.hostCreate(details)),
      verifyInviteCode: details => dispatch(global.redux.action.host.verifyInviteCode(details)),
      taxList: details => dispatch(global.redux.action.tax.list(details)),
   
   
    
    });
    export default connect(mapStateToProps, mapDispatchToProps)(AddressForm)

