import React, { useState, useEffect} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, notification } from 'antd'
import { connect } from 'react-redux';
import _ from 'lodash';

import Menus from '../../menu';
import BulkSchedule from '../../bulk-schedule';
import LoadingTemplate from './loading-container';
import './view-style.css';

const ViewArchivedTemplate = (props) => {
  
  let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedButton, setSelectedButton] = useState(1);
    const [templateDetails, setTemplateDetails] = useState({});
    const [templateDetailsMeta, setTemplateDetailsMeta] = useState({});
    const [loading, setLoading]  = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
      viewTemplate();
    }, []);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      archiveTemplate()
      
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const archiveTemplate = () => {
     
          props.archiveActiveTemplates({
              body:{
                  id: searchParams.get('id'),
                  status: 1,
                  status_glossary:"Un Archived"
              }, 
              callback: (res, data) => {
                  if(res){
                      setIsModalOpen(false);
                      notification.success({
                          message: "Success",
                          description: "Template Unarchived successfully"
                      });
                      navigate('/template-list?menu=template');
                      // loadTemplateList();
                  }
              }
          })
      
  }

    const viewTemplate = () => {
      setLoading(true);
        props.viewTemplateAPI({
          params:{
            id: searchParams.get('id'),
            expand: 'photos,category'
          },
          callback: (res, val) => {
            setLoading(false);
            
            if(res){
              if(_.get(val, 'data.details')){
                setTemplateDetails(_.get(val, 'data.details'));
                setTemplateDetailsMeta(_.get(val, 'data'))
              }
            }
          }
        })
    }

   
    const handleButtonClick = (buttonId) => {
        setSelectedButton(buttonId);
      };

      const cancelBtnStyle = {
        height: '44px',
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '8px',
        background: "#FFFFFF",
        cursor: 'pointer',
        outline: 'none',
        color: "#475467",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: '20px',
        border: '1px solid var(--Gray-300, #D0D5DD)',
        borderRadius: "8px"
      };

      const archiveButtonStyle = {
        height: '44px',
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '8px',
        background: 'var(--Orange-500, #EF6820)',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: '20px',
        marginLeft: "12px",
        borderRadius: "8px"
      };

      const render = () => {
        return (
            <div className='container' style={{maxWidth: '100%' }}>
                {/* menu container */}
                <div style={{marginTop: "16px"}}>
                    <Menus/>
                </div>
                <hr/>
                {/* menu container ends*/}
               {loading && <LoadingTemplate/>}
                { !loading && <div style={{display: "flex", flexDirection: "row"}}>
                <div>
                <div>
      <button
      onClick={() => handleButtonClick(1)}
      style={{
        display: 'flex',
        padding: '12px',
        alignItems: 'center',
        gap: '8px',
        alignSelf: 'stretch',
        borderRadius: '6px',
        backgroundColor:
          selectedButton === 1 ? 'var(--Orange-500, #EF6820)' : '#FFFFFF',
        border: 'none',
        height: "44px",
        width: "119px",
        color: selectedButton === 1 ? '#FFFFFF' : '#667085',
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        cursor: "pointer"
      }}
      >
       { selectedButton === 1 && <img style={{width: "16px", height: "16px"}} src={require('../../../assets/img/view_temp_file_1.png')}/>} { selectedButton === 2 && <img style={{width: "16px", height: "16px"}} src={require('../../../assets/img/view_temp_file_2.png')}/>} Template 
      </button>
      <button
       onClick={() => handleButtonClick(2)}
       style={{
         display: 'flex',
         padding: '12px',
         alignItems: 'center',
         gap: '8px',
         alignSelf: 'stretch',
         borderRadius: '6px',
         backgroundColor:
           selectedButton === 2 ? 'var(--Orange-500, #EF6820)' : '#FFFFFF',
         border: 'none',
         height: "44px",
         width: "119px",
         marginTop: "4px",
         color: selectedButton === 2 ? '#FFFFFF' : '#667085',
         fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        cursor: "pointer"
       }}
      >
       { selectedButton === 2 && <img style={{width: "16px", height: "16px"}} src={require('../../../assets/img/view_template_calendar_1.png')}/>} { selectedButton === 1 && <img style={{width: "16px", height: "16px"}} src={require('../../../assets/img/view_temp_calendar_2.png')}/>} Schedule
      </button>
    </div>
        </div>
        {selectedButton === 2 && <div className='col-lg-9' style={{marginLeft: "32px"}}><BulkSchedule/></div>}
        {selectedButton === 1 && <div className='col-lg-9' style={{marginLeft: "32px"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
            <div onClick={() => navigate('/template-list?menu=template')} className='back-arrow-btn'>
                <img style={{width: "20px", height: "20px"}} src={require('../../../assets/img/arrow-left.png')}/>
            </div>
            <div className='view-template-header'> View your archived template</div>
            </div>
            <hr/>
            {/* template type */}
            <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Template Type</div>
                         <div className='template-sub-description'>Choose between scheduled, open and league templates</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                            <div className='template-type-container' style={{display: "flex", flexDirection: "row"}}>
                                {_.get(templateDetails, 'type') === 'scheduled' && <img style={{width: "20px", height: "20px"}} src={require('../../../assets/img/calendar-plus-02.png')}/>}
                                {_.get(templateDetails, 'type') === 'league' && <img style={{width: "20px", height: "20px"}} src={require('../../../assets/img/league_image.png')}/>}
                                {_.get(templateDetails, 'type') === 'open' && <img style={{width: "20px", height: "20px"}} src={require('../../../assets/img/open_image.png')}/>}
                                <div className='template-sub-description' style={{marginLeft: "8px"}}>{_.startCase(_.get(templateDetails, 'type'))}</div>
                            </div>
                         </div>
                         </div>
                                {/* template type ends*/}

                                {/* template name */}
                         <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Template name</div>
                         <div className='template-sub-description'>Give your template a name</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                            <div className='template-type-container'>
                                <div className='template-sub-description'>{_.startCase(_.get(templateDetails, 'title'))}</div>
                            </div>
                         </div>
                         </div>
                         {/* template name ends*/}

                         {/* sport type */}

                         <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Sport</div>
                         <div className='template-sub-description'>Choose your sport</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                            <div className='template-type-container'>
                                <div className='template-sub-description'>{_.startCase(_.get(templateDetails, 'category.name'))}</div>
                            </div>
                         </div>
                         </div>
                            <hr/>
                         {/* sport type ends */}

                         {/* about session */}

                         <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>About this session</div>
                         <div className='template-sub-description'>Tell us more about this session including details like why users should buy it</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                            <div className='template-type-container'>
                                <div className='template-sub-description' dangerouslySetInnerHTML={{ __html: _.get(templateDetails,'description') }}/>
                            </div>
                         </div>
                         </div>
                            <hr/>

                            {/* about session ends */}

                            {/* address detials */}

                            

                         <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Address</div>
                         <div className='template-sub-description'>Enter the location of your session</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                            <div className='template-type-container'>
                                <div className='template-sub-description'>{_.get(templateDetails,'address')}</div>
                            </div>
                         </div>
                         </div>
                    
                         <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Postal Code</div>
                         <div className='template-sub-description'>Confirm or edit the postal code</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                            <div className='template-type-container'>
                                <div className='template-sub-description'>{_.get(templateDetails,'zip_code')}</div>
                            </div>
                         </div>
                         </div>

                         <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Currency</div>
                         <div className='template-sub-description'>This is not editable and will be based on the location of the session</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                            <div className='template-type-container'>
                                <div className='template-sub-description'>{_.get(templateDetails,'meta_details.currency')}</div>
                            </div>
                         </div>
                         </div>

                         <hr/>

                         {/* address details ends */}

                         {/* price & space */}

                         <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Price per registration</div>
                         <div className='template-sub-description'>Set your price before any applicable taxes or platform fees</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                            <div className='template-type-container'>
                                <div className='template-sub-description'>{`${_.get(templateDetails,'meta_details.currency')} $ ${_.get(templateDetails,'price')}`}</div>
                            </div>
                         </div>
                         </div>

                         { _.get(templateDetails,'meta_details.team_size') && <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Spaces</div>
                         <div className='template-sub-description'>How many slots are available for this program - this can be changed later at any time</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                            <div className='template-type-container'>
                                <div className='template-sub-description'>{_.get(templateDetails,'meta_details.team_size')}</div>
                            </div>
                         </div>
                         </div>}
                                {_.get(templateDetails,'meta_details.team_size') && <hr/>}
                         {/* price & space ends */}

                         {/* additional info */}
                            { _.size(_.get(templateDetails,'extra_info')) > 0 &&
                                <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                                <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                                  <div className='template-sub-header'>Additional info</div>
                                  <div className='template-sub-description'>In this section you can provide users more information, and links to material you have about this session. For example, you may have rule books, parking information, policies or FAQs that you want to provide users information.</div>
                                  </div>
                                  <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                                  {_.size(_.get(templateDetails,'extra_info')) > 0 && _.get(templateDetails,'extra_info').map((data, index) => {
                                    return(
                                      <div className='template-type-container' style={{ marginTop: index >= 1 ? '12px' : 0 }}>
                                      <div className='additional-info-title'>
                                          Title
                                      </div>
                                      <div className='additional-info-answers'>
                                        {_.get(data, 'name')}
                                      </div>
      
                                      {_.get(data, 'description') && <div>
                                        <div className='additional-info-title' style={{marginTop: "24px"}}>
                                      Description
                                      </div>
                                      <div className='additional-info-answers' dangerouslySetInnerHTML={{ __html: _.get(data, 'description') }} />
                                      
                                      </div>
                                      }
                                      {_.get(data, 'link') && <div>
                                        <div className='additional-info-title' style={{marginTop: "24px"}}>
                                      Link
                                      </div>
                                      <a href={_.get(data, 'link')} target='_blank' className='additional-info-answers'>
                                        {_.get(data, 'link')}
                                        </a>
                                      
                                      </div>
                                      }
                                  </div>
                                    )
                                  })}
                                     
                                     
                                  </div>
                                  </div>
                                    
                            }
                          {_.size(_.get(templateDetails,'extra_info')) > 0 && <hr/>}
                         {/* additional info ends */}

                        {/* clauses */}

                         { _.size(_.get(templateDetails,'clauses')) > 0 && <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Clauses</div>
                         <div className='template-sub-description'>In this section you can provide clauses users need to consent to before booking your session</div>
                         </div>
                         {
                          _.size(_.get(templateDetails,'clauses')) > 0 && <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "32px"}}>
                          {
                            _.size(_.get(templateDetails,'clauses')) > 0 && _.get(templateDetails,'clauses').map((val, index) => {
                              return(
                                <div className='template-type-container' style={{ marginTop: index >= 1 ? '12px' : 0 }}>
                               
                              <div className='additional-info-title'>
                                      Title
                                  </div>
                                  <div className='additional-info-answers'>
                                    {_.get(val, 'name')}
                                  </div>
  
                                  { _.get(val, 'description') && <div>
                                    <div className='additional-info-title' style={{marginTop: "24px"}}>
                                  Description
                                  </div>
                                  <div className='additional-info-answers' dangerouslySetInnerHTML={{ __html: _.get(val, 'description') }}/>
                                  </div>}
                                  { _.get(val, 'link') && <div>
                                    <div className='additional-info-title' style={{marginTop: "24px"}}>
                                  Link
                                  </div>
                                  <a href={_.get(val, 'link')} target='_blank' className='additional-info-answers'>
                                    {_.get(val, 'link')}
                                    </a>
                                  </div>}
                              </div>
                              )
                            })
                          }
                           
                         </div>
                         }
                         
                         </div>}
                                {_.size(_.get(templateDetails,'clauses')) > 0 && <hr/>}
                         {/* clauses info ends */}
                            {/* pictures */}
                         {_.size(_.get(templateDetails, 'photos')) > 0 && <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "20px"}}>
                       <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                         <div className='template-sub-header'>Pictures</div>
                         <div className='template-sub-description'>Add pictures to showcase your listing, users tend to respond more positively to listing with many images</div>
                         </div>
                         <div style={{display: "flex", flexDirection: "column", flex: 2, marginLeft: "0px"}}>
                         <div className='template-picture-container'>
                          {
                            _.size(_.get(templateDetails, 'photos')) > 0 && _.get(templateDetails, 'photos').map((val, index) => {
                              return(
                                <img style={{height: "140px", width: "140px", marginLeft: '12px',  marginTop: index >= 3 ? '12px' : 0}} src={`${_.get(templateDetailsMeta, 'meta.photo.path')}/${_.get(templateDetailsMeta, 'meta.photo.folder')}/${_.get(templateDetailsMeta, 'meta.photo.sizes.medium')}/${_.get(val, 'photo')}`}/>
                              )
                            })
                          }
                         </div>
                          
                         </div>
                         </div>}
                                {_.size(_.get(templateDetails, 'photos')) > 0 && <hr/>}
                         {/* pictures ends */}
                          <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "32px"}}>
                          <button onClick={() => showModal()} className='unarchive-btn-container'><img style={{height: "24px", width:"24px"}} src={require('../../../assets/img/unarchive_view_template.png')}/> Unarchive template</button>
                          </div>
                         
        </div>}
                </div>}

                  <Modal centered={true} 
                closable={false}
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        footer={[
          <div style={{padding: "24px 24px 0px 24px"}}>
            <button style={cancelBtnStyle} onClick={handleCancel}>Cancel</button>
            <button style={archiveButtonStyle} onClick={() => handleOk()} >Unarchive template</button>
            </div>
          ]}
        >
            <div style={{padding: "24px 24px 0px 24px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <img style={{height: "48px", width: "48px"}} src={require('../../../assets/img/archive_alert_icon.png')}/>
                    <img onClick={() => handleCancel()} style={{height: "44px", width: "44px", cursor: "pointer"}} src={require('../../../assets/img/template/modal_close_icon.png')}/>
                </div>
                <div style={{marginTop: "16px", color: "#101828", fontSize: "18px", fontWeight: 600, lineHeight: "28px"}}>
                Are you sure you want to unarchive this template?
                </div>
                <div style={{color: "#475467", fontSize: "14px", fontWeight: 400, lineHeight: "20px", marginTop: '4px'}}>This template will appear on your active templates list and can be used to schedule an experience</div>
            </div>
      </Modal>
            </div>
            
          )
      };

      return render();
  
}

const mapStateToProps = state => {
  return {
     

  }
}

const mapDispatchToProps = dispatch => ({
  
  viewTemplateAPI: details => dispatch(global.redux.action.template.view(details)),
  archiveActiveTemplates: details => dispatch(global.redux.action.template.changeStatusTemplate(details)),

 
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewArchivedTemplate)