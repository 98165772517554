import React, {useEffect, useState} from 'react';
import socketIOClient from "socket.io-client";
import _ from 'lodash';
import {notification, Modal, Button} from 'antd';
// import { Button } from '@chatscope/chat-ui-kit-react';

const TestSocket = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getSocketData, setSocketData] = useState('');

    useEffect(() => {
        const socket = socketIOClient("http://localhost:80", {
    reconnection: false,
    transports: ['websocket']
  });
    socket.open();
    socket.on('utilitiesClient', data => {
      
      // alert(data.data.status)
    //   Modal.destroy();
    //   notification.open({message: _.get(data, 'data.status')});
    // if(data){
        setSocketData(_.get(data, 'data.status'))
        setIsModalOpen(true);
    // }
    })
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
      };

      const handleOk = () => {
        setIsModalOpen(false);
      };

      const handleCancel = () => {
        setIsModalOpen(false);
      };

    const render = () => {
        return (
            <div>
                {
                    _.isEmpty(getSocketData) ? <h1 style={{marginTop: "175px", marginLeft: "500px"}}>The socket is listening</h1>: ''
                }
                
                {/*   <Modal centered={true} title="Socket Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>The status is {getSocketData}</p>
      </Modal> */}
      {
        !_.isEmpty(getSocketData) ? getSocketData == '1' ? <div><Button style={{color: "#fff", marginLeft: "650px", padding: "37px", backgroundColor: "green", marginTop: "175px"}}>Active</Button></div> : <div><Button style={{color: "#fff", marginLeft: "650px", padding: "37px", backgroundColor: "red", marginTop: "175px"}}>Inactive</Button></div>
        : ''
      }
                   
            </div>
        )
    };

    return render();

};

export default TestSocket;