import React, {useState, useEffect} from 'react';
import { Button, Table, Tag, Modal, Input, Select, notification, Form, InputNumber, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, EyeOutlined, ArrowLeftOutlined, FileTextOutlined } from '@ant-design/icons';
import { MdOutlinePayment } from "react-icons/md";
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from "react-redux";
import _ from 'lodash';
import moment from 'moment';

import { renderToString } from "react-dom/server";
import jsPDF from "jspdf";
// import ReactToPdf from "react-to-pdf";
import html2canvas from 'html2canvas';
import momentTimezone from 'moment-timezone';
import { BsArrowReturnRight } from "react-icons/bs";
import { AiOutlineCopy } from 'react-icons/ai';
import { BsPlusCircleDotted } from "react-icons/bs";

import '../onboarding-screen/style.css';
import './style.css';
import './invoice.css';
// import InvoicePdf from './invoice';
import ViewRegisteredInfo from '../manage/view-registered-screen';
import axios from 'axios';

const ref = React.createRef();

const styles = {
	fontFamily: "sans-serif",
	textAlign: "center"
};
const { Option } = Select;
const { TextArea } = Input;
const userColumns = [
  {
    title: 'Program Title',
    dataIndex: 'program_title',
    key: 'program_title',
  },
  {
    title: 'Session Type',
    dataIndex: 'temp_type',
    key: 'temp_type',
  },
  {
    title: 'Date',
    dataIndex: 'experience_date',
    key: 'experience_date',
  },
  {
    title: 'Time',
    dataIndex: 'experience_time',
    key: 'experience_time',
    // width:"50%"
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: "Registrant's Full Name",
    dataIndex: 'regi_name',
    key: 'regi_name',
  },
  {
    title: "Amount",
    dataIndex: 'finance_amount',
    key: 'finance_amount',
  },
  {
    title: "Purchase Date",
    dataIndex: 'purchase_date',
    key: 'purchase_date',
  },
  {
    title: "Purchase Time",
    dataIndex: 'purchase_time',
    key: 'purchase_time',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
  },

];

const viewPurchaseInformation = [
  {
    title: 'First Name',
    dataIndex: 'guest_name',
    key: 'guest_name',
  },
  {
    title: 'Last Name',
    dataIndex: 'guest_last_name',
    key: 'guest_last_name',
  },
  {
    title: 'Program Date',
    dataIndex: 'program_date',
    key: 'program_date',
  },
  {
    title: 'Program Time',
    dataIndex: 'program_time',
    key: 'program_time',
  },
  {
    title: 'Purchase Date',
    dataIndex: 'purchase_date',
    key: 'purchase_date',
  },
  {
    title: 'Purchase Time',
    dataIndex: 'purchase_time',
    key: 'purchase_time',
  },
  {
    title: 'Purchase Amount',
    dataIndex: 'purchase_amount',
    key: 'purchase_amount',
  },
  {
    title: "Purchase Status",
    dataIndex: 'purchase_status',
    key: 'purchase_status',
    
  },
];

const headers = [
  {
    key: 'ptitle',
    name: 'Program Title',
  },
  {
    key: 'finance_date',
    name: 'Date',
  },
  {
    key: 'finance_time',
    name: 'Time',
  },
  {
    key: 'finance_status',
    name: 'Status',
  },
  {
    key: 'registar_name',
    name: "Registrant's Full Name",
  },
  {
    key: 'finance_amount',
    name: "Amount", 
  },
]

const options = {
  orientation: 'portrait',
	unit: 'in',
	format: [10, 50]
};
const FinancesList =(props)=>{
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
    let history = useNavigate();
    const [timeZone, setTimeZone] = useState('')
    const[financesListData, setFinancesListData] = useState([]);
    const[financesListMetaData, setFinancesListMetaData] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [financeDetails, setFinanceDetails] = useState({});
    const [count, setCount] = useState(1);
    const [pageNumber, setPageNumber] = useState(0)
    const [filterValue, setFilterValue] = useState('');
    const [oneMonthFilter, setOneMonthFilter] = useState('');
    const [twoMonthFilter, setTwoMonthFilter] = useState('');
    const [threeMonthFilter, setThreeMonthFilter] = useState('');
    const [pageLoading, setPageLoading] = useState(true)

    const[loadMoreValues, setLoadMoreValues] = useState([])
    const [viewModal, setViewModal] = useState(false);
    const [isManageRegModalOPen, setIsManageRegModalOPen] = useState(false);
    const [registrationInfo, setRegistrationInfo] = useState([]);
    const [clauseInfo, setClauseInfo] = useState([]);
    const [managePaymentDataSource, setManagePaymentDataSource] = useState([]);
    const [isManagePaymentModalOPen, setIsManagePaymentModalOPen] = useState(false);
    const [orderDataSource, setOrderDataSource] = useState([]);
    const [getTransactionDataSource, setTransactionDataSource] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [refundedRows, setRefundedRows] = useState([]);
    const [getOrderDetailsData, setOrderDetailsData] = useState({});

    //invoices
    const [tableExpandId, settableExpandId] = useState('');
    const [paymentRequestList, setpaymentRequestList] = useState([]);
    const [invoiceLoading, setinvoiceLoading] = useState(false);
    const [invoiceAmount, setinvoiceAmount] = useState('');
    const [offlineAmount, setofflineAmount] = useState('')
    const [invoiceNote, setinvoiceNote] = useState('');
    const [cancelInvoiceId, setcancelInvoiceId] = useState('')
    const [showDeleteConfirm, setshowDeleteConfirm] = useState(false)

 useEffect(()=>{
getLocationsData()
 },[]);

 useEffect(() => {
  financesList();
 }, [_.get(props, 'search')])

 const handleRefundClick = (record) => {
  setExpandedRowKeys([record.key]);
  setSelectedData(record);
  setRefundedRows([...refundedRows, record.key]);
};

const handleExpand = (expanded, record) => {
  if (!expanded) {
      setExpandedRowKeys([]);
      setSelectedData({});
    }
};

const handleChange = (e) => {
            
  const reg = /^-?\d*(\.\d*)?$/;
  if (reg.test(e) || e === '' || e === '-') {
    setinvoiceAmount(e)
  }
};

const onCopyURL = (value) => {
  
  navigator.clipboard.writeText(value)
  .then(() => {
    
    messageApi.open({
      type: 'success',
      content: 'Copied',
    });
  })
  .catch(err => {
    console.error('Error copying value:', err);
  });
}

const getPaymentRequestList =(id)=>{
  props.getPaymentRequestList({
    params:{
        page:1,
        user_type: 1,
        limit:1000,
        order:id
    },
    callback: (response, data) => {
      if(response){
        let a =[]
        

        _.map(_.get(data,'data.items'),(v,i)=>{
          a.push({
            payment_type:
  <Tag onClick={ ()=>onCopyURL(v?.id)} style={{borderRadius:'16px',cursor:'pointer'}}><div style={{display:'flex',alignItems:'center'}}><div className='invoice-table-items' style={{marginRight:'5px'}}>Invoice</div><div><AiOutlineCopy/></div></div></Tag>
        
            ,
            amount: <div className='invoice-table-items' >${v?.amount}</div>,
            last_updated:<div className='invoice-table-items' >{moment(v?.updated_at).format('MMM d, YYYY')}</div>,
            status:v?.paid==0 &&v.status!=4?<Tag style={{borderRadius:'16px'}} >Pending</Tag>:v.status==4?<Tag style={{borderRadius:'16px'}} color='red'>Cancelled</Tag>:<Tag style={{borderRadius:'16px'}} color='#32D583'>Paid</Tag>,
            expand_details:v
          })
      })
        setpaymentRequestList(_.reverse(a))

      }
        
    }
})
}

const paymentRequestsCreate =(amount,order_id)=>{
  
  setinvoiceLoading(true)
  props.paymentRequestsCreateAPI({
    body:{amount:amount,
      id:order_id
    },
    params:{id:order_id},
    callback: (response, data) => {
      setinvoiceLoading(false)
      
      if(response){
        setinvoiceAmount("")
        settableExpandId("")
        getPaymentRequestList(order_id)
        // setpaymentRequestList(_.get(data,'data.items'))
      }
        
    }
})
};

const paymentOffline =(id)=>{
            
  setinvoiceLoading(true)
  props.paymentOffline({
    body:{type:1,
      id:id,
      cancellation_glossary:"Cancelled",
      label:"Apply Offline Payment",
      payment_source_type:invoiceNote,

    },
    params:{id:id},
    callback: (response, data) => {
      setinvoiceLoading(false)
      
      if(response){
        setinvoiceAmount("")
        setofflineAmount("")
        settableExpandId("")
        getPaymentRequestList()
        setshowDeleteConfirm(false)
        setcancelInvoiceId("")
        setinvoiceNote("")
        // setpaymentRequestList(_.get(data,'data.items'))
      }
        
    }
})
};

const renderOfflineContent =(item)=>{
            
            return <div>
              <div style={{display:'flex',marginBottom:'16px'}}>
                {item?.payment_type}
                <div style={{marginLeft:'10%'}}>{item?.amount}</div> 
                <div style={{marginLeft:'10%'}}>{item?.last_updated}</div>
                <div style={{marginLeft:'15%'}}> {item?.status}</div>
                {/* <div style={{width:'25%'}}></div> */}
              </div>
              <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Apply Offline Payment</p>
            <div style={{display:'flex'}}>
              <div style={{width:'48%',marginRight:'30px'}}>
              <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Confirm Amount</p>
              <p className='session-static-desc'>Confirm the amount applied an offline payment up to the total amount invoiced.</p>
              
              <p className='session-static-desc' style={{marginTop:'14px'}}>Note: You can only apply the full amount of the invoice in an offline payment.</p>
              </div>
              <div style={{width:'52%'}}>
                <div style={{marginBottom:'8px'}}>
                  <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Amount</p>
                  <Input readOnly={true} disabled={true} value={`$${offlineAmount}`} style={{height:'40px',borderRadius:'8px',border:'1px solid #D0D5DD)',background:'#FFF'}} placeholder='CAD $0.00'
                  
                  />
                </div>
                <div>
                <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Add Note</p>
                  <Input onChange={(e)=>setinvoiceNote(e.target.value)} style={{height:'40px',borderRadius:'8px',border:'1px solid #D0D5DD)',background:'#FFF'}} placeholder='Enter a note for this offline payment'/>
                </div>
                <div style={{marginTop:'16px',display:'flex',justifyContent:'flex-end'}}>
                  <Button disabled={invoiceLoading} onClick={()=>{
                    
                    settableExpandId("")
                  }} style={{height:'40px'}}>Cancel</Button>
                  <Button
                  loading={invoiceLoading}
                  disabled={parseFloat(offlineAmount)>0?false:true}
                  onClick={()=>{
                    
                    if(tableExpandId && invoiceNote && offlineAmount){
                      paymentOffline(tableExpandId)
                    }
                    
                    // settableExpandId("")
                    // paymentRequestsCreate(parseFloat(invoiceAmount),_.get(_.head(managePaymentDataSource),'details.id'))
                    
                  }} style={{height:'40px',marginLeft:'8px'}}>Apply Offline Payment</Button>
                </div>
              </div>
            </div>
          </div>
          }

    const financesList = (pageSize=10) => {
      setPageLoading(true);
      let params = {
        sort: 'created_at.desc',
        expand: 'experience,experience_template,experience,experience_template_photos,host,guest',
        user_type: 1,
        limit: pageSize,
        search: _.lowerCase(props.search),
        search_type: 1
      };

            if(_.isEmpty(props.search)){
              delete params.search;
              delete params.search_type;
            }
            props.financesListApi({
                params: params,
                callback: (response, data) => {
                    if(response){
                        setFinancesListData(data)
                        setFinancesListMetaData(data)
                        setPageNumber(_.get(data, 'data.meta.page'))
                    }
                    setPageLoading(false)
                }});
        
    }

    const handleScroll = (data, type) => {
      
      if (pageLoading==false && data && data.top > 0.9 && _.get(financesListMetaData,'data.meta.total') > 10) {
          if( _.size(_.get(financesListData, 'data.items'))<_.get(financesListMetaData,'data.meta.total')){
            financesList(_.get(financesListMetaData,'data.meta.limit')+10)
          }
              
          
      } 
  
  }

    const dateFilter = (value) => {
      setFilterValue(value)
      let oneMonthFilter = moment().subtract(1,'months').format("DD.MM.YYYY");
      setOneMonthFilter(oneMonthFilter)
        let twoMonthFilter = moment().subtract(2,'months').format("DD.MM.YYYY");
        setTwoMonthFilter(twoMonthFilter)
        let threeMonthFilter = moment().subtract(3,'months').format("DD.MM.YYYY");
        setThreeMonthFilter(threeMonthFilter)
        let endDate = moment().format("DD.MM.YYYY");
      if(value === '30_days'){
        props.financesListApi({
          params: {
            paid:1,
          start_checkin_date: oneMonthFilter,
          end_checkin_date: endDate,
          sort: 'created_at.desc',
          expand: 'experience,experience_template,experience,experience_template_photos,host,guest',
          user_type: 1
          },
          callback: (response, data) => {
              if(response){
                  setFinancesListData(data)
              }
          }});
      };

      if(value === '60_days'){
        props.financesListApi({
          params: {
            paid:1,
          start_checkin_date: twoMonthFilter,
          end_checkin_date: endDate,
          sort: 'created_at.desc',
          expand: 'experience,experience_template,experience,experience_template_photos,host,guest',
          user_type: 1
          },
          callback: (response, data) => {
              if(response){
                  setFinancesListData(data)
              }
          }});
      };
      
      if(value === '90_days') {
        props.financesListApi({
          params: {
            paid:1,
          start_checkin_date: threeMonthFilter,
          end_checkin_date: endDate,
          sort: 'created_at.desc',
          expand: 'experience,experience_template,experience,experience_template_photos,host,guest',
          user_type: 1
          },
          callback: (response, data) => {
              if(response){
                  setFinancesListData(data)
              }
          }});
      };

      if(value === 'all') {
        props.financesListApi({
          params: {
            paid:1,
          sort: 'created_at.desc',
          expand: 'experience,experience_template,experience,experience_template_photos,host,guest',
          user_type: 1
          },
          callback: (response, data) => {
              if(response){
                  setFinancesListData(data)
              }
          }});
      };
    };

    const getLocationsData =async()=>{
      const { data, status } = await axios.get('https://ipapi.co/json')
      // setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
    await  setTimeZone(_.get(data,'timezone'))
    financesList();
    }

    const purchaseInformation = (value) => {
      
        props.viewFinance({
          params:{id:_.get(value, 'id'), expand:'experience,experience_template,experience,experience_template_photos,guest'},
          callback:(response,data)=>{
            
            if(response === true){
              const total_amount = parseFloat(_.get(data, 'data.details.total'));
            const base_platform_amount = parseFloat(_.get(data, 'data.details.base_platform_amount'));
            const base_platform_tax_amount = parseFloat(_.get(data, 'data.details.base_platform_tax_amount'));
            const club_amount = (total_amount)-(base_platform_amount)-(base_platform_tax_amount)
              transactionItems(_.get(value, 'id'))
             setOrderDetailsData(_.get(data, 'data.details'));
              setFinanceDetails(_.get(data, 'data'))
              
              if(!_.isEmpty(_.get(data, 'data.details.registration_info'))){
                          
                let a = []
                _.map(_.get(data, 'data.details.registration_info'), (value, index) => {
                    a.push({
                      key: index,
                      label: <div className='session-details-table'>{_.get(value, "label")}</div>,
                      answer: <div className='session-details-table'>{_.get(value, "answer")}</div>,
                    })
                  })
                setRegistrationInfo(a);
            };

            if(!_.isEmpty(_.get(data, 'data.details.experience.clauses'))){
                          
              let c = []
              _.map(_.get(data, 'data.details.experience.clauses'), (value, index) => {
                  c.push({
                    key: index,
                    name: <div className='session-details-table'>{_.get(value, "name")}</div>,
                    created_at: <div className='session-details-table'>{`Accepted on ${moment.tz(_.get(data, 'data.details.created_at'), !_.isEmpty(_.get(data, 'data.details.experience.meta_details.timezone')) ? _.get(data, 'data.details.experience.meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')} at ${moment.tz(_.get(data, 'data.details.created_at'), !_.isEmpty(_.get(data, 'data.details.experience.meta_details.timezone')) ? _.get(data, 'data.details.experience.meta_details.timezone') : "America/Toronto").format('hh:mm A z')}`}</div>,
                  })
                })
                setClauseInfo(c);
          };

          if(!_.isEmpty(_.get(data, 'data.details'))){
                          
            let a = [];
                a.push({
                  key: _.get(data, 'data.details.id'),
                  id: <div className='session-details-table'>{_.get(data, 'data.details.id')}</div>,
                  session_title: <div className='session-details-table'>{_.get(data, 'data.details.experience.template.title')}</div>,
                  first_and_last: <div className='session-details-table'>{`${_.startCase(_.get(data, 'data.details.profile_info.first_name'))} ${_.startCase(_.get(data, 'data.details.profile_info.last_name'))}`}</div>,
                  details: _.get(data, 'data.details'),
                  email: _.get(data, 'data.details.guest.contact_details.email'),
                })
                setManagePaymentDataSource(a);
        };

        if(!_.isEmpty(_.get(data, 'data.details'))){
          let b = [];
          b.push({
              key: _.get(data, 'data.details.id'),
              order_value: <div className='session-details-table'>${_.get(data, 'data.details.total')}</div>,
              status: <div className='session-details-table'>{_.get(data, 'data.details.paid') === 1 ? <div className='paid-customized-tag'>Paid</div> : <div className='paid-customized-tag'>Pending</div>}</div>,
              running_total: <div className='session-details-table'>{`$ ${_.get(data, 'data.details.running_total')}`}</div>,
              refund_amount: <div className='session-details-table'>{`$ ${_.get(data, 'data.details.refund_amount')}`}</div>,
              tax_amount: <div className='session-details-table'>{`$ ${club_amount.toFixed(2)}`}</div>,
              tax_rate: <div className='session-details-table'>{`$ ${_.get(data, 'data.details.tax_rate')}`}</div>,
              base_platform_amount: <div className='session-details-table'>{`$ ${_.get(data, 'data.details.base_platform_amount')}`}</div>,
              base_platform_tax_amount: <div className='session-details-table'>{`$ ${_.get(data, 'data.details.base_platform_tax_amount')}`}</div>,
              discount: <div className='session-details-table'>{_.isEmpty(_.get(data, 'data.details.discount')) ? "$ 0.00" : `$ ${_.get(data, 'data.details.discount')}`}</div>,
          })
          setOrderDataSource(b);
      }
            }
          }
        })
        setIsModalVisible(true);
    }

    const handleOk = () => {
      setIsModalVisible(false);
      setRegistrationInfo([]);
      setClauseInfo([]);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
      setRegistrationInfo([]);
      setClauseInfo([]);
    };
    const viewRegisterInfo = () => {
      // setViewModal(true)
      setIsModalVisible(true)
      setIsManageRegModalOPen(true)
    }

    const viewPaymentInfo = () => {
      setIsManagePaymentModalOPen(true)
    };

    const handleOkPaymentModal = () => {
      setIsManagePaymentModalOPen(false)
    };

    const handleCancelPaymentModal = () => {
      setIsManagePaymentModalOPen(false)
    };

    

    const Prints = () => {
      return(
      <div style={{ width: '650px' }}>
        <div className="header-container">
          <span className="header-title">
            <span className="header-title-line"></span>
            <span className="header-title-text">YOUR REGISTRATION INFORMATION</span>
            <span className="header-title-line"></span>
          </span>
        </div>
        <div className="register-info-body">
          <div>
            <div className="register-body-set">
              <p>Hello {_.startCase(_.get(financeDetails, 'details.guest.first_name'))},</p>
              <p>You've registered for a program on Communiti. You can find your registration information below:</p>
            </div>
            <div className="register-body-set">
              <p><b>PROGRAM INFORMATION</b></p>
              <p><b>Program Name:</b> {_.startCase(_.get(financeDetails, 'details.experience.template.title'))}</p>
              <p><b>Program Start Date:</b>{moment(_.get(financeDetails, 'details.experience.experience_date')).format("MM/DD/YYYY")} </p>
              <p><b>Program Time:</b> {_.get(financeDetails, 'details.experience.experience_start_time') !== null ? moment.utc(_.get(financeDetails, 'details.experience.experience_start_time'), 'HH:mm A').local().format('h:mm A') : '-'}</p>
            </div>
            <div className="register-body-set">
              <p><b>REGISTRANT INFORMATION</b></p>
              <p><b>First Name:</b> {_.startCase(_.get(financeDetails, 'details.guest.first_name'))}</p>
              <p><b>Last Name:</b> {_.startCase(_.get(financeDetails, 'details.guest.last_name'))}</p>
              <p><b>E-Mail:</b> {_.get(financeDetails, 'details.guest.contact_details.email')}</p>
              <p>Is this session booked for a child? {_.get(financeDetails, 'details.relations') === null ? "No" : "Yes"}</p>
              <p><b>Child Name:</b> {_.get(financeDetails, 'details.relations[0].Firstname')}</p>
              <p><b>Child Age:</b> 20</p>
            </div>
            <div className="register-body-set">
              <p><b>PAYMENT INFORMATION</b></p>
              <p><b>Date Program Booked:</b> {moment(_.get(financeDetails, 'details.created_at')).format("MM/DD/YYYY")}</p>
              <p><b>Time:</b> {moment.utc(_.get(financeDetails, 'details.created_at'), 'HH:mm A').local().format('h:mm a')}</p>
              <p><b>Program Cost:</b> <span className="color-red"><b>{`$${_.get(financeDetails, 'details.experience.template.host_price')}`}</b> (this is slightly different than what the Coach / Club sees as it isn't the template price, but the price paid)</span></p>
            </div>
            <div className="register-body-set bb-none">
              <p>Please keep a copy of this e-mail for your records. Your club may ask for a copy of this e-mail when you come to your first class.</p>
            </div>
          </div>
        </div>
        <div className="footer-container">
          Logo
        </div>
      </div>
    )
}

    const print = () => {
      window.html2canvas = html2canvas;
      // const pdf =new jsPDF('p', 'mm', [300, 300]);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: [935, 650],
        putOnlyUsedFonts: true,
      });
      const htmlToConvert = renderToString(<Prints />)
      pdf.html(htmlToConvert, {
        callback: function(pdf) {
          pdf.save("invoice");
        }
      });
    };

    const getStartTime =(time,timezone)=>{
    
      // let date = momentTimezone().tz(timezone).format("YYYY-MM-DD");
  
              // let start_time = momentTimezone(time).tz(timezone).format('hh:mm A')
let new_date = moment(new Date(time)).format('hh:mm A')
      
           return new_date
          }

          const columns = [
            {
              title: <div className='session-details-table'>ID</div>,
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: <div className='session-details-table'>Session Title</div>,
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: <div className='session-details-table'>Schedule</div>,
              dataIndex: 'schedule_details',
              key: 'schedule_details',
              render: () => (
                <>
                  <div className='session-details-table'>{`${moment.tz(_.get(financeDetails, 'details.experience.experience_date'), !_.isEmpty(_.get(financeDetails, 'details.experience.meta_details.timezone')) ? _.get(financeDetails, 'details.experience.meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(financeDetails, 'details.experience.experience_end_date'), !_.isEmpty(_.get(financeDetails, 'details.experience.meta_details.timezone')) ? _.get(financeDetails, 'details.experience.meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')}`}</div>
                  <div className='session-details-table'>{_.get(financeDetails, 'details.experience.type') == 'open' ? "" :
              `${_.get(financeDetails, 'details.experience.experience_start_time') ?
                moment.tz(_.get(financeDetails, 'details.experience.experience_date'), !_.isEmpty(_.get(financeDetails, 'details.experience.meta_details.timezone')) ? _.get(financeDetails, 'details.experience.meta_details.timezone') : "America/Toronto").format("hh:mm") : '--'}-${_.get(financeDetails, 'details.experience.experience_end_time') ? moment.tz(_.get(financeDetails, 'details.experience.experience_end_date'), !_.isEmpty(_.get(financeDetails, 'details.experience.meta_details.timezone')) ? _.get(financeDetails, 'details.experience.meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`}</div>
                  <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{ _.size(_.get(financeDetails, 'details.experience.schedule_dates')) > 1 ? `${_.size(_.get(financeDetails, 'details.experience.schedule_dates'))} Sessions` :  _.isEmpty(_.get(financeDetails, 'details.experience.schedule_dates')) && _.get(financeDetails, 'details.experience.type') !== "open" ? '1 session' : _.isEmpty(_.get(financeDetails, 'details.experience.schedule_dates')) && _.get(financeDetails, 'details.experience.type') === "open" ? "" : `${_.size(_.get(financeDetails, 'details.experience.schedule_dates'))} Session`}</div> {_.get(financeDetails, 'details.experience.can_late_register') === true && <div className="custom-button-tag">Late Registration</div>} </div>
                </>
              ),
            },
    
            {
                title: <div className='session-details-table'>Session Info</div>,
                dataIndex: 'session_info',
                key: 'session_info',
                render: () => (
                  <>
                    <div className='session-details-table'>{_.get(financeDetails, 'details.experience.type') == "league" ? "League Session" : _.get(financeDetails, 'details.experience.type') == "open" ? "Open Session" : "Scheduled Session"}</div>
                    <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{`$${_.get(financeDetails, 'details.experience.price')} ${_.get(financeDetails, 'details.experience.template.meta_details.currency')}`}</div> {_.get(financeDetails, 'details.experience.can_prorate') === true && <div className="custom-button-tag">Prorate</div>}</div>
                    <div className='session-details-table'>{`${_.get(financeDetails, 'details.experience.no_of_bookings')}/${_.get(financeDetails, 'details.experience.max_no_of_openings')} Registrations`}</div>
                  </>
                ),
              },
          ];

          const dataSource = [
            {
              key: '1',
              id: <div className='session-details-table'>{_.get(financeDetails, 'details.id')}</div>,
              title: <div className='session-details-table'>{_.get(financeDetails, 'details.experience.template.title')}</div>,
              schedule_details: null, // The render function will populate this
              session_info: null,  // The render function will populate this
            },
          ];


          const registrant_column = [
            {
              title: <div className='session-details-table'>Registrant Type</div>,
              dataIndex: "reg_type",
              key:"reg_type"
            },
            {
              title: <div className='session-details-table'>Registrant First & Last Name</div>,
              dataIndex: "reg_first_last_name",
              key:"reg_first_last_name"
            },
            {
              title: <div className='session-details-table'>Registrant Gender & Date of Birth</div>,
              dataIndex: "reg_gender_dob",
              key:"reg_gender_dob"
            },
            {
              title: <div className='session-details-table'>Contact First & Last Name</div>,
              dataIndex: "con_first_last_name",
              key:"con_first_last_name"
            },
            {
              title: <div className='session-details-table'>Contact Email</div>,
              dataIndex: "con_email",
              key:"con_email"
            },
            {
              title: <div className='session-details-table'>Contact Address</div>,
              dataIndex: "con_address",
              key:"con_address"
            },
          ];

          const reg_dataSource = [
            {
              key: "1",
              reg_type: <div className='session-details-table'>{_.get(financeDetails, 'details.profile_info.profile_type')}</div>,
              reg_first_last_name: <div className='session-details-table'>{`${_.startCase(_.get(financeDetails, 'details.profile_info.first_name'))} ${_.startCase(_.get(financeDetails, 'details.profile_info.last_name'))}`}</div>,
              reg_gender_dob: <div className='session-details-table'>{`${_.startCase(_.get(financeDetails, 'details.profile_info.gender'))} ${moment(_.get(financeDetails, 'details.profile_info.date_of_birth')).format("MMMM DD, YYYY")}`}</div>,
              con_first_last_name: <div className='session-details-table'>{`${_.startCase(_.get(financeDetails, 'details.guest.first_name'))} ${_.startCase(_.get(financeDetails, 'details.guest.last_name'))}`}</div>,
              con_email: <div className='session-details-table'>{_.get(financeDetails, 'details.guest.contact_details.email')}</div>,
              con_address: <div className='session-details-table'>{_.get(financeDetails, 'details.guest.address')}</div>,
            }
          ];

          const additional_column = [
            {
              title: <div className='session-details-table'>Question Title</div>,
              dataIndex: "label",
              key: "label"
            },
    
            {
              title: <div className='session-details-table'>Registrant Response</div>,
              dataIndex: "answer",
              key: "answer"
            },
          ];

          const clause_column = [
            {
              title: <div className='session-details-table'>Clause Title</div>,
              dataIndex: "name",
              key: "name"
            },
    
            {
              title: <div className='session-details-table'>Acceptance Timestamp</div>,
              dataIndex: "created_at",
              key: "created_at",
            },
          ];

          const handleManageRegOk = () => {
            setIsManageRegModalOPen(false);
          };
      
          const handleManageRegCancel = () => {
            setIsManageRegModalOPen(false);
          };

          const cancelRegistration = () => {
            props.orderRefundAPI({
                body:{
                    "id":_.get(financeDetails, 'details.id'),
                      "refund_type": "order",
                      "cancel_order": 1,
                      "partial_refund": 0
                },
                callback: (cancel_res, cancel_data) => {
                    
                    if(cancel_res){
                        setIsManageRegModalOPen(false);
                        financesList();
                        notification.success({
                            message: "Registration cancelled",
                            description: "You have successfully cancelled this registration."
                        })
                    }
                }
            })
          };

          const manage_payment_column = [
            {
                title:<div className='session-details-table'>ID</div>,
                key:"id",
                dataIndex: "id"
            },
            {
                title:<div className='session-details-table'>Session Title</div>,
                key:"session_title",
                dataIndex: "session_title"
            },
            {
                title:<div className='session-details-table'>Schedule</div>,
                key:"schedule",
                dataIndex: "schedule",
                render:(text, item)=>{
                    
                        return  <>
                        <div className='session-details-table'>{`${moment.tz(_.get(item, 'details.experience.experience_date'), !_.isEmpty(_.get(item, 'details.experience.meta_details.timezone')) ? _.get(item, 'details.experience.meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(item, 'details.experience.experience_end_date'), !_.isEmpty(_.get(item, 'details.experience.meta_details.timezone')) ? _.get(item, 'details.experience.meta_details.timezone') : "America/Toronto").format('MMM D')}`}</div>
                        <div className='session-details-table'>{_.get(item, 'details.experience.type') == 'open' ? "null" :
                    `${_.get(item, 'details.experience.experience_start_time') ?
                      moment.tz(_.get(item, 'details.experience.experience_date'), !_.isEmpty(_.get(item, 'details.experience.meta_details.timezone')) ? _.get(item, 'details.experience.meta_details.timezone') : "America/Toronto").format("hh:mm") : '--'}-${_.get(item, 'details.experience.experience_end_time') ? moment.tz(_.get(item, 'details.experience.experience_end_date'), !_.isEmpty(_.get(item, 'details.experience.meta_details.timezone')) ? _.get(item, 'details.experience.meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`}</div>
                        <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{ _.size(_.get(item, 'details.experience.schedule_dates')) > 1 ? `${_.size(_.get(item, 'details.experience.schedule_dates'))} Sessions` : `${_.size(_.get(item, 'details.experience.schedule_dates'))} Session`}</div></div>
                      </>
                }
            },
            {
                title: <div className='session-details-table'>Session Info</div>,
                dataIndex: 'session_info',
                key: 'session_info',
                render: (text, item) => (
                  <>
                    <div className='session-details-table'>{_.get(item, 'details.experience.type') == "league" ? "League Session" : _.get(item, 'details.experience.type') == "open" ? "Open Session" : "Scheduled Session"}</div>
                    <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{`$${_.get(item, 'details.experience.price')} ${_.get(item, 'details.experience.template.meta_details.currency')}`}</div></div>
                    <div className='session-details-table'>{`${_.get(item, 'details.experience.no_of_bookings')}/${_.get(item, 'details.experience.max_no_of_openings')} Registrations`}</div>
                  </>
                ),
              },
            {
                title:<div className='session-details-table'>First & Last Name</div>,
                key:"first_and_last",
                dataIndex: "first_and_last"
            },
            {
                title:<div className='session-details-table'>Gender & Date of Birth</div>,
                key:"Genders_dob",
                dataIndex: "Genders_dob",
                render:(text, item) => {
                  
                    return <>
                        <div className='session-details-table'>{_.startCase(_.get(item, 'details.profile_info.gender'))}</div>
                        <div className='session-details-table'>{moment(_.get(item, 'details.profile_info.date_of_birth')).format("MMMM DD, YYYY")}</div>
                    </>
                }
            },
          ];

          const order_column = [
            {
                title:<div className='session-details-table'>Status</div>,
                key: "status",
                dataIndex:"status"
            },
            {
              title:<div className='session-details-table'>Order Value</div>,
              key: "order_value",
              dataIndex:"order_value"
          },
            {
                title:<div className='session-details-table'>Total Paid</div>,
                key: "running_total",
                dataIndex:"running_total"
            },
            {
                title:<div className='session-details-table'>Total Refunded</div>,
                key: "refund_amount",
                dataIndex:"refund_amount"
            },
            {
                title:<div className='session-details-table'>Club Amount</div>,
                key: "tax_amount",
                dataIndex:"tax_amount"
            },
            {
                title:<div className='session-details-table'>Club Tax</div>,
                key: "tax_rate",
                dataIndex:"tax_rate"
            },
            {
                title:<div className='session-details-table'>Communiti Fee</div>,
                key: "base_platform_amount",
                dataIndex:"base_platform_amount"
            },
            {
                title:<div className='session-details-table'>Communiti Tax</div>,
                key: "base_platform_tax_amount",
                dataIndex:"base_platform_tax_amount"
            },
            {
                title:<div className='session-details-table'>Discounts</div>,
                key: "discount",
                dataIndex:"discount"
            },
          ];

          const transactionItems = (id) => {
            props.transactionListAPI({
                params:{
                    order:id,
                    user_type: 1,
                },
                callback: (res, val) => {
                  if(res){
                    if(_.size(_.get(val, 'data.items')) > 0){
                      const sortedShop = _.get(val, 'data.items').sort((a, b) => {
                        const aParent = a.parent_transaction !== null ? a.parent_transaction : a.id;
                        const bParent = b.parent_transaction !== null ? b.parent_transaction : b.id;
                        return aParent.localeCompare(bParent);
                      });
                      // 
                        let a = [];
                        _.size(_.get(val, 'data.items')) > 0 && _.map(sortedShop, (value, index) => {
                            a.push({
                                key: index,
                                payment_type: <div className='session-details-table'>{_.startCase(_.get(value, 'payment_source'))}</div>,
                                amount: <div className='session-details-table'>${_.get(value, 'amount')}</div>,
                                updated_at: <div className='session-details-table'>{moment(_.get(value, 'updated_at')).format("MMM DD, YYYY")}</div>,
                                status: <div className='session-details-table'>{_.get(value, 'status') === 1 ? <div className='paid-customized-tag'>Paid</div> : <div className='paid-customized-tag'>Pending</div>}</div>,
                                details: value
                            });
                        });
                        setTransactionDataSource(a);
                    }
                  }
                    
                }
            })
          };

          const refundOrder = (e, data) => {
            
            let amount = parseFloat(_.get(e, 'refund_amount'));
            let transa_amount = _.get(data, 'amount')
            let body_params = {...e};
            body_params.id = _.get(data, 'order');
            body_params.refund_type = 'transaction'
            body_params.transaction_id = _.get(data, 'id')
            body_params.cancel_order = 0;

            if(amount === parseFloat(transa_amount)){
              body_params.partial_refund = 0
            }
            if(amount !== parseFloat(transa_amount)){
                body_params.refund_amount = amount;
                body_params.partial_refund = 1
            } else {
                delete body_params.refund_amount
                // delete body_params.partial_refund
            }
            props.orderRefundAPI({
                body:{
                    ...body_params
                },
                callback: (res, v) => {
                    if(_.get(v, 'success')){
                        notification.success({
                            message: "Success",
                            description: "Amount refunded successfully"
                        })
                        transactionItems(_.get(data, 'order'));
                    }
                   
                }
            })
          }

          const expandedRowRender = (value) => {
               form.setFieldsValue({
                refund_amount: _.get(value, 'details.amount'),
               })
            return (
              <div>
                {/* You can add more input fields for other data if needed */}
                <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "20px", marginTop: "30px"}}>
                            <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 2}}>
                              <div className='container-titles'>Amount</div>
                              <div className='container-titles-desc'>{`You may partially refund or refund up to the total amount of $${_.get(value, 'details.amount')}.`}<br/>
                                Note for Stripe payments refunding the total amount means you are responsible for covering the Stripe & Communiti fees.</div>
                              </div>
                              <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                                <Form
                                form={form}
                                layout='vertical'
                                onFinish={(e) => refundOrder(e, _.get(value, 'details'))}
                                >
                                    <Form.Item
                                    label="Amount"
                                    name="refund_amount"
                                    rules={[
                                        {
                                            required:true,
                                            message: "Please enter an amount"
                                        }
                                    ]}
                                    >
                                    <InputNumber max={_.get(value, 'details.amount')} placeholder='CAD $0.00' style={{height: "40px", width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item>
                                  <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end"}}>
                                  <Button style={{marginRight: "15px"}}>Cancel</Button>
                                  <Button htmlType="submit">Refund Payment</Button>
                                  </div>
                                </Form.Item>
                                </Form>
                                
                              </div>
                              </div>
              </div>
            );
          };

          const transaction_column = [
            {
                title:<div className='session-details-table'>Payment Type</div>,
                key:"payment_type",
                dataIndex:"payment_type",
                render:(text, item) => {
                    return<>
                        <div style={{display: "flex", flexDirection: "row"}}>{!_.isEmpty(_.get(item, 'details.parent_transaction'))? <BsArrowReturnRight /> : ""}<div className='session-details-table' style={{marginLeft: !_.isEmpty(_.get(item, 'details.parent_transaction')) ? "20px" : ""}}>{_.startCase(_.get(item, 'details.payment_source'))}</div></div>
                    </>
                }
            },
            {
                title:<div className='session-details-table'>Amount</div>,
                key:"amount",
                dataIndex:"amount"
            },
            {
                title:<div className='session-details-table'>Last Updated</div>,
                key:"updated_at",
                dataIndex:"updated_at"
            },
            {
                title:<div className='session-details-table'>Status</div>,
                key:"status",
                dataIndex:"status",
                render: (text, item) => {
                    return <>
                            <div className='paid-customized-tag'>{!_.isEmpty(_.get(item, 'details.parent_transaction')) ? "Refunded" : "Paid"}</div>
                    </>
                }
            },
            {
                title:<div className='session-details-table'>Action</div>,
                key:"actions",
                dataIndex:"actions",
                render:(text, record) => {
                    
                    return <>
                    {
                        _.get(record, 'details.type') === 1 ? <div 
                        onClick={() => handleRefundClick(record)} 

                        className={selectedData && selectedData.key === record.key ? "header-first-row disabled" :"header-first-row"}>Refund</div> : ""
                    }
                        
                    </>
                }
            },
          ];

          const invoice_column = [
            {
                title:<div className='session-details-table'>Payment Type</div>,
                key: "payment_type",
                dataIndex:"payment_type",
                onCell: (item, index) => {
                  
                  return {
                  colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5 :1,
                  className:"",
                  
                }},
                
                
                
                render:(text, item)=>{
                return item?.id==1?tableExpandId==item?.id ? <div>
                 <p style={{marginBottom:'14px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Send Invoice</p>
                <div style={{display:'flex'}}>
                  <div style={{width:'48%',marginRight:'30px'}}>
                  <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Amount</p>
                    <p className='session-static-desc'>Enter the amount to invoice the registrant. This invoice will be sent to their email address.</p>
                    {/* <p className='session-static-desc'>Enter the amount to invoice the registrant. This invoice will be sent to their email address.</p> */}
                  </div>
                  <div style={{width:'52%'}}>
                    <div style={{marginBottom:'8px'}}>
                      <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Amount</p>
                      <Input  value={invoiceAmount} style={{height:'40px',borderRadius:'8px',border:'1px solid #D0D5DD)',background:'#FFF'}} placeholder='CAD $0.00'
                      onChange={(e)=>handleChange(e.target.value)}
                      />
                    </div>
                    {/* <div>
                    <p style={{marginBottom:'8px',color:'#344054',fontWeight:500,lineHeight:'18px',fontSize:'13px'}}>Add Note</p>
                      <Input style={{height:'40px',borderRadius:'8px',border:'1px solid #D0D5DD)',background:'#FFF'}} placeholder='Enter a note for this offline payment'/>
                    </div> */}
                    <div style={{marginTop:'16px',display:'flex',justifyContent:'flex-end'}}>
                      <Button disabled={invoiceLoading} onClick={()=>{
                        
                        settableExpandId("")
                      }} style={{height:'40px'}}>Cancel</Button>
                      <Button
                      loading={invoiceLoading}
                      disabled={parseFloat(invoiceAmount)>0?false:true}
                      onClick={()=>{
                        
                        // settableExpandId("")
                        paymentRequestsCreate(parseFloat(invoiceAmount),_.get(_.head(managePaymentDataSource),'details.id'))
                        
                      }} style={{height:'40px',marginLeft:'8px'}}>Confirm</Button>
                    </div>
                  </div>
                </div>
              </div>: "": _.get(item,'expand_details.id')==tableExpandId?renderOfflineContent(item):<div>{text}</div>
                }
            },
            {
                title:<div className='session-details-table'>Amount</div>,
                key: "amount",
                dataIndex:"amount",
                render:(text, item)=>{
                  return item?.id==1?"": _.get(item,'expand_details.id')==tableExpandId?"":<div>{text}</div>
                  },
                  onCell: (item, index) => {
                    
                    return {
                      colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5: 1,
                    className:item?.id==1?tableExpandId==item?.id?'test-class-cell':"":_.get(item,'expand_details.id')==tableExpandId?'test-class-cell':"",
                    
                  }},
            },
            {
                title:<div className='session-details-table'>Last Updated</div>,
                key: "last_updated",
                dataIndex:"last_updated",
                onCell: (item, index) => {
                  
                  return {
                    colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5: 1,
                    className:item?.id==1?tableExpandId==item?.id?'test-class-cell':"":_.get(item,'expand_details.id')==tableExpandId?'test-class-cell':"",
                  
                }},
                // onCell: (text,item)=>{
                //   
                //   return {
                //     colSpan:  5 
                //   }
                // },
                render:(text, item)=>{
                  return  item?.id==1?tableExpandId=="" ?<div onClick={()=>{

                    settableExpandId(_.get(item, 'id'))
                  }} style={{ display: 'flex', alignItems: 'center' }}>
                  <BsPlusCircleDotted style={{ marginRight: 10 }} color="#475467" size={24} />
                  <div className="header-first-row">Send Invoice</div>
                </div>:<div onClick={()=>{

settableExpandId(_.get(item, 'id'))
}} style={{ display: 'flex', alignItems: 'center' }}>
<BsPlusCircleDotted style={{ marginRight: 10 }} color="#475467" size={24} />
<div className="header-first-row">Send Invoice</div>
</div> : _.get(item,'expand_details.id')==tableExpandId?"":<div>{text}</div>
                  }
            },
            {
                title:<div className='session-details-table'>Status</div>,
                key: "status",
                dataIndex:"status",
                onCell: (item, index) => {
                  
                  return {
                    colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5: 1,
                    className:item?.id==1?tableExpandId==item?.id?'test-class-cell':"":_.get(item,'expand_details.id')==tableExpandId?'test-class-cell':"",
                  
                }},
                render:(text, item)=>{
                  return item?.id==1?"":_.get(item,'expand_details.id')==tableExpandId?"": <div>{text}</div>
                  }
            },
            {
              onCell: (item, index) => {
                
                return {
                  colSpan: item?.id==1?tableExpandId==item?.id? 5 :1 :_.get(item,'expand_details.id')==tableExpandId?5: 1,
                  className:item?.id==1?tableExpandId==item?.id?'test-class-cell':"":_.get(item,'expand_details.id')==tableExpandId?'test-class-cell':"",
                
              }},
                title:<div className='session-details-table'>Action</div>,
                key: "action",
                dataIndex:"action",
                render:(text, item)=>{
                  return item?.id==1?"":_.get(item,'expand_details.id')==tableExpandId?"": <div>
{item?.expand_details?.paid==0 &&item?.expand_details?.status!=4&&item?.expand_details?.paid!=1 && <div onClick={()=>{setshowDeleteConfirm(true);
setcancelInvoiceId(_.get(item,'expand_details.id'))

}} style={{fontWeight:500,fontSize:'14px',cursor:'pointer'}}>Cancel</div>}
{item?.expand_details?.status!=4&&item?.expand_details?.paid!=1 &&<div 
onClick={()=>{
  
  settableExpandId(_.get(item,'expand_details.id'));
  setofflineAmount(item?.expand_details?.amount)
}}
style={{fontWeight:500,fontSize:'14px',cursor:'pointer'}}>Offline Payment</div>}
                    
                  </div>
                  }
            },
          
          ];

          const paymentChangeStatus =(id)=>{
            
            setinvoiceLoading(true)
            props.paymentChangeStatus({
              body:{status:4,
                id:id,
                cancellation_glossary:"Cancelled"
              },
              params:{id:id},
              callback: (response, data) => {
                setinvoiceLoading(false)
                
                if(response){
                  setinvoiceAmount("")
                  settableExpandId("")
                  getPaymentRequestList(getOrderDetailsData?.id)
                  setshowDeleteConfirm(false)
                  setcancelInvoiceId("")
                  // setpaymentRequestList(_.get(data,'data.items'))
                }
                  
              }
          })
          };

  const render =()=>{
    
  let csvData = [];

  if(_.size(_.get(financesListData, 'data.items')) > 0){
    csvData = _.get(financesListData, 'data.items').map((value, index) => {
      return{
        ptitle:  _.startCase(_.get(value, 'experience.template.title')),
        finance_date: moment(_.get(value, 'experience.experience_date')).format("MM/DD/YYYY"),
        finance_time: _.get(value, 'created_at') !== null ? moment(_.get(value, 'created_at')).format('h:mm A') : '-',
        finance_status: _.get(value, 'experience.template.status') === 1 ? "Paid" :  _.get(value, 'experience.template.status') === 4 ? "Cancelled" : '',
        registar_name: `${_.startCase(_.get(value, 'guest.first_name'))} ${_.startCase(_.get(value, 'guest.last_name'))}`,
        finance_amount: `$${_.get(value, 'experience.template.host_price')}`
      }
    })
  }

  let tableSourceData = [];
  if(_.size(_.get(financesListData, 'data.items')) > 0){
    tableSourceData = _.get(financesListData, 'data.items').map((value, index) => {
      
        return{
            key: _.get(value, 'id'),
            program_title: _.startCase(_.get(value, 'experience.template.title')),
            temp_type: _.startCase(_.get(value, 'experience.template.type')),
            experience_date: moment(_.get(value, 'experience.experience_date')).format("MM/DD/YYYY"),
            experience_time: moment.tz(_.get(value,'experience.experience_date'), !_.isEmpty(_.get(value,'experience.meta_details.timezone')) ? _.get(value,'experience.meta_details.timezone') : "America/Toronto").format("hh:mm A"),
            status: _.get(value, 'experience.template.status') === 1 ? <span className='table-paid-status'>Paid</span> :  _.get(value, 'experience.template.status') === 4 ? <span className='table-status-cancelled'>Cancelled</span> : '',
            regi_name: `${_.startCase(_.get(value, 'profile_info.first_name'))} ${_.startCase(_.get(value, 'profile_info.last_name'))}`,
            finance_amount: `$${_.get(value, 'experience.template.host_price')}`,
            purchase_date: moment(_.get(value, 'created_at')).format("MM/DD/YYYY"),
            purchase_time: _.get(value, 'created_at') !== null ? getStartTime(_.get(value, 'created_at')) : '-',
            action: <Button type='text' onClick={() => purchaseInformation(value)}><EyeOutlined style={{marginLeft:"10px"}} /> View</Button>
        }
    })
  }

  let financeDetailsView = [];
  if(!_.isEmpty(financeDetails)){
    
    financeDetailsView.push({
      key: _.get(financeDetails, 'details.id'),
      guest_name: `${_.startCase(_.get(financeDetails, 'details.profile_info.first_name'))}`,
      guest_last_name: `${_.startCase(_.get(financeDetails, 'details.profile_info.last_name'))}`,
      program_date: moment(_.get(financeDetails, 'details.experience.experience_date')).format("MM/DD/YYYY"),
      // program_time: _.get(financeDetails, 'details.experience.experience_start_time') !== null ? moment.utc(_.get(financeDetails, 'details.experience.experience_start_time'), 'HH:mm A').local().format('h:mm A') : '-',
      program_time: moment.tz(_.get(financeDetails,'details.experience.experience_date'), !_.isEmpty(_.get(financeDetails,'details.experience.meta_details.timezone')) ? _.get(financeDetails,'details.experience.meta_details.timezone') :  "America/Toronto").format("hh:mm A"),
      purchase_date: moment(_.get(financeDetails, 'details.created_at')).format("MM/DD/YYYY"),
      purchase_time: getStartTime(_.get(financeDetails, 'details.created_at')),
      purchase_amount: `$${_.get(financeDetails, 'details.experience.template.host_price')}`,
      purchase_status: _.get(financeDetails, 'details.status') === 1 ? <span className='modal-paid-status'>Active</span> :  _.get(financeDetails, 'details.status') === 2 ? <span className='table-status-cancelled'>Pending</span> : _.get(financeDetails, 'details.status') === 0 ? <span className='table-status-cancelled'>In Active</span>: <span className='table-status-cancelled'>Cancelled</span> ,
      extraDetails:_.get(financeDetails,'details')
  })
  }
    return (
      <div>
        {contextHolder}
        {/* <MainMenu {...props} financeTab={true} /> */}
      <div>
        <ViewRegisteredInfo extraDetails={_.get(financeDetails,'details')} usersDetails={{..._.get(financeDetails, 'details.guest',{}),relations:_.get(financeDetails, 'details.profile_info',{})}}  viewModal={viewModal} onStateChange={(e)=>setViewModal(e)}/>
          <div>
    {/* <span style={{fontSize:'22px',fontWeight:'600'}}>Finances</span> */}
    {/* <div className='filter-by-text'>
      Filter By: 
    <Select  

    width="250px"
    className='filter-by-days'
    onChange={dateFilter}
    placeholder="Select days"
    // popupClassName="filter-day-dropdown"
    >
    <Option value="30_days">Last 30 Days</Option>
    <Option value="60_days">Last 60 Days</Option>
    <Option value="90_days">Last 90 Days</Option>
    <Option value="all">All</Option>
    </Select>
    // <ExportJsonCsv className='form-btn-4' headers={headers} items={csvData}>Export to .csv</ExportJsonCsv>
    </div> */}
</div>
            <div style={{marginTop: "12px"}}>
              <Scrollbars className="hidden-scroll-x"
              
              onScrollFrame={(e) => handleScroll(e)}
              style={{ height: 'calc(100vh - 350px)', marginBottom: "32px" }}
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
              >
              <Table
                  loading={pageLoading} 
                  dataSource={tableSourceData} 
                  columns={userColumns}
                  // pagination={{
                  //   onChange: (page, pageSize) =>
                  //     pageChange(page, pageSize),
                  //   total: _.get(
                  //     financesListData,
                  //     "data.meta.total",
                  //     0
                  //   ),
                  //   pageSize: 10,
                  // }}
                  pagination={false}
              />
              </Scrollbars>
            </div>
            <Modal centered={true} closable={false} className='purchase-modal' width="52%" title="Purchase Information" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
            footer={[
              <Button onClick={viewRegisterInfo} className='view-registration'><FileTextOutlined /> View Registration Info</Button>,
              <Button onClick={() => viewPaymentInfo()} className='view-registration'><MdOutlinePayment /> View Payment Info</Button>,
              //  <Button className='download-invoice' onClick={print}>Download Purchase Invoice</Button>,
              <Button className='back-from-modal'  onClick={handleCancel}><ArrowLeftOutlined/> Back</Button>
            ]}
            >
            <div className="template-list-table">
        <Table dataSource={financeDetailsView} columns={viewPurchaseInformation} pagination={false}/>
        </div>
      </Modal>
      <div>
      <Modal 
      centered={true}
              className='common-modal-ctn' 
              title="View Registration" 
              open={isManageRegModalOPen} 
              onOk={handleManageRegOk} 
              onCancel={handleManageRegCancel}
              footer={null}
              >
                <div>
                <p className='session-static-desc'>You are manually adding a registration to the following session:</p>
              <hr/>
              <Table pagination={false} columns={columns} dataSource={dataSource}/>
              <div className='session-static-title' style={{marginTop: "12px"}}>
              Registrant & Contact Information
              </div>
              <p className='session-static-desc' style={{marginTop: "12px"}}>Below is the information of the registrant participating in the session.</p>
              <Table 
                  columns={registrant_column}
                   dataSource={reg_dataSource} 
                   pagination={false}
                   />
                   <div className='session-static-title' style={{marginTop: "12px"}}>
                   Registrant Additional Information
              </div>
              <p className='session-static-desc'>Below are the responses and timestamps provided by the participant to the additional questions and clauses displayed upon registration.</p>
              <div>
                            {
                               !_.isEmpty(_.get(financeDetails, 'details.registration_info')) && <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                               <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                                 <div className='container-titles'>Question & Reponses</div>
                                 <div className='container-titles-desc'>These are the responses provided by the registrant to the questions displayed to them upon registration.</div>
                                 </div>
                                 <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                                  
                                  <Table columns={additional_column} dataSource={registrationInfo} pagination={false}/>
                                 </div>
                                 </div>
                            }
                          </div>

                          <div>
                    {
                      !_.isEmpty(_.get(financeDetails, 'details.experience.clauses')) && <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                      <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                        <div className='container-titles'>Clauses & Acceptance Timestamp</div>
                        <div className='container-titles-desc'>These are the clauses and acceptance timestamps the registrant completed upon registration.</div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                         
                         <Table columns={clause_column} dataSource={clauseInfo} pagination={false}/>
                        </div>
                        </div>
                    }
                   </div>
                   <hr/>
                          <div className='session-static-title' style={{marginTop: "12px"}}>
                          Manage Registration Status
                        </div>
                        <p className='session-static-desc'>Below you can change this registrant’s status for this session.</p>
                        <div style={{display: "flex", justifyContent: "space-between",marginTop: "20px"}}>
                        <div className='cancel-note-title'>
                        Cancel Registration
                        </div>
                        <Button onClick={() => cancelRegistration()}>Cancel Registration</Button>
                        </div>
                        <p className='session-static-desc'>This set’s a registrant’s status to “Cancelled”.</p>
                        <p className='cancel-note-title'>Note: Cancelling a registration does not automatically trigger a refund. You will need to do this under the “Manage Payment” section. It is recommended to complete your refund (if necessary) prior to cancellation.</p>
                </div>

                </Modal>
      </div>
      <div>
      <Modal 
      centered={true}
              className='payment-modal-ctn' 
              title={<div className='session-static-title'>Manage Payment</div>}
              open={isManagePaymentModalOPen} 
              onOk={handleOkPaymentModal} 
              onCancel={handleCancelPaymentModal}
              footer={null}
              >
                <div>
                <p className='session-static-desc'>You are managing payment for the following session and registrant:</p>
                <hr/>
              <Table columns={manage_payment_column} dataSource={managePaymentDataSource} pagination={false} />    
                </div>

                <div className='session-static-title' style={{marginTop: "12px"}}>
              Order Status & Breakdown
                        </div>
                        <p className='session-static-desc'>Below is the order status and payment breakdown for this registrant:</p>
                        <Table columns={order_column} dataSource={orderDataSource} pagination={false} />
                        <div className='session-static-title' style={{marginTop: "12px"}}>
                        Invoices
                        </div>
                        <p className='session-static-desc'>You can send invoices to the registrant from here. Sending an invoice will trigger an email to the registrant. They can either pay online via Stripe, or you  can apply an offline payment.</p>
                        <div style={{display:'flex'}}>
                          <div style={{width:'30%'}}>
                          <p className='session-static-title' style={{color:'#344054',fontWeight:400,fontSize:'14px',marginBottom:'0px'}}>Send and view pending invoices</p>
                          <p className='session-static-desc'>Click the +Send Invoice button to send the registrant an invoice.</p>
                          <p className='session-static-desc'>You can also view pending invoices here. Once they are paid the will automatically be listed in the detailed transactions table below.</p>
                        
                          </div>
                          <div style={{width:'65%',marginLeft:'4%'}}>
                        <Table
                        
                         rowClassName={(id, key) => {
                          return key == 0 && 'first-row-class'
                        }} onRow={(record, rowIndex) => {
                          return {
                            onClick: event => {
                              if (rowIndex == 0 && _.get(record, 'id') == 1) {
                                  // showAddRegistrationModal();
                                  

                                  if(_.get(record, 'id')==tableExpandId){
                                    // settableExpandId("")
                                  }else{
                                    // settableExpandId(_.get(record, 'id'))
                                  }
                                  
                              }
                              
                    
                            }, // click row
                            // colSpan:_.get(record, 'id') == 1?5:1,
                            // rowSpan:0,
                            colSpan:tableExpandId==_.get(record, 'id') ? 0:tableExpandId==_.get(record,'expand_details.id')?0:1
                            

                            
                          };
                        }} 

                        
                        columns={invoice_column} dataSource={[{id:1},...paymentRequestList]} pagination={false} />
                        </div>
                        </div>
                        <div className='session-static-title' style={{marginTop: "12px"}}>
                Detailed Transactions
                        </div>
                        <p className='session-static-desc'>Below are the detailed transactions that comprise the above order. You can refund from the table below.</p>
                        <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "1px", flex: 1}}>
                          <div className='container-titles'>Create or select existing profile</div>
                          <div className='container-titles-desc'>Existing Communiti accounts may<br/> have an existing registration profile<br/> you can select from here.<br/> If none exist, create a new registration<br/> profile.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Table 
                            columns={transaction_column} 
                            dataSource={getTransactionDataSource} 
                            pagination={false}
                            expandedRowRender={expandedRowRender}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={handleExpand}
                             />
                          </div>
                          
                          </div>
              </Modal>

              <Modal maskClosable
              centered={true}
                 onCancel={()=>setshowDeleteConfirm(false)} 
                  style={{padding:'68px 18px',width:'936px'}} footer={null} open={showDeleteConfirm}>
                  <div style={{    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',}}>
                  <span style={{    fontSize: '18px',fontWeight: '600'}}>Are you sure you wish to cancel this invoice?</span>
                  {/* <p style={{color:'#475467',fontSize:'14px',marginTop:'6px'}}>All the data you’ve inputted for this session will be removed and you will need to enter it again.</p> */}
                  
                  </div>
                  <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '22px'}}>   
    <Button 
    disabled={invoiceLoading}
    onClick={()=>setshowDeleteConfirm(false)} style={{backgroundColor:'#FFF',marginRight:23,width:'23%',borderRadius:'8px',border:'1px solid #D0D5DD',height:'40px'}}
             >Cancel</Button>
                  <Button loading={invoiceLoading} onClick={()=>{
                    if(cancelInvoiceId){
                      paymentChangeStatus(cancelInvoiceId)
                    }
                  }}  style={{backgroundColor:'#FFF',borderRadius:'8px',border:'1px solid #D0D5DD',height:'40px'}}
             >Continue</Button></div>
                  </Modal>
      </div>
      </div>
      </div>
      
    );
  }
  return render();
}

const mapStateToProps = state => {
    return {
      finances_order_list: _.get(state, 'app.finances.list.data')
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    financesListApi: details => dispatch(global.redux.action.finances.list(details)),
    viewFinance: details => dispatch(global.redux.action.finances.view(details)),
    orderRefundAPI: details => dispatch(global.redux.action.guest.orderRefund(details)),
    transactionListAPI: details => dispatch(global.redux.action.experience.transList(details)),
    paymentRequestsCreateAPI: details => dispatch(global.redux.action.finances.paymentRequestsCreate(details)),
    getPaymentRequestList: details => dispatch(global.redux.action.finances.getPaymentRequestList(details)),   
    paymentOffline: details => dispatch(global.redux.action.finances.paymentOffline(details)),
    paymentChangeStatus: details => dispatch(global.redux.action.finances.paymentChangeStatus(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(FinancesList)