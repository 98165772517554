import React, { createRef, useEffect, useRef, useState } from 'react';
import { Modal, Box, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input,Button, notification } from 'antd';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import appConfig from "../../../core_units/config/env-variables";
const useStyles = makeStyles((theme) => ({
  modalBackground: {
    display: 'flex',
    alignItems: 'center',
    
    justifyContent: 'center',
    backgroundColor: 'rgba(52, 64, 84, 0.70)', // Background color when modal is open
    minHeight: '100vh',
  },
  modalContent: {
       padding: "24px",
    backgroundColor: '#fff',
    borderRadius: 8,
    outline: 'none',
//     width: '400px',
  },
  inputField: {
    marginBottom: 2,
  },
  button: {
    marginTop: 2,
  },
}));

const LoginSignupOTPFlow = (props) => {
  const [dotPulseBtnLoader, setDotPulseBtnLoader] = useState(false)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
const [phoneNumberErrors, setphoneNumberErrors] = useState({error:false,message:"We’ll send you a verification code on this number"});
const [otp, setOtp] = useState(['', '', '', '', '', '']);
const [errorOtp, seterrorOtp] = useState(false)
const inputRefs = useRef([...Array(6)].map(() => createRef()));
const [seconds, setSeconds] = useState(30);

useEffect(() => {
       if (seconds > 0) {
         const intervalId = setInterval(() => {
           setSeconds(prevSeconds => prevSeconds - 1);
         }, 1000);
   
         return () => clearInterval(intervalId);
       }
     }, [seconds]);


const handleChange = (index, value) => {
       
       seterrorOtp(false)
       if (value !== '') {
         const newOtp = [...otp];
         newOtp[index] = value;
         setOtp(newOtp);
         if (index < 5 && value !== '') {
           inputRefs.current[index + 1].current.focus();
         }
       }else if (value === '' && index >= 0) {
              const newOtp = [...otp];
              newOtp[index] = '';
              setOtp(newOtp);
              
              
              index!=0 &&inputRefs.current[index - 1].current.focus();
            }
     };
   
     const handleKeyUp = (index, event) => {
       
       if (event.key === '"Shift"' || event.getModifierState('CapsLock')) {
              event.preventDefault(); // Prevent default behavior (moving focus)
            } else if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
         inputRefs.current[index - 1].current.focus();
       } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '') {
         inputRefs.current[index + 1].current.focus();
       }
       
     };
   
     const handleKeyDown = (index, event) => {
       if (event.key === '"Shift"' || event.getModifierState('CapsLock')) {
              event.preventDefault(); // Prevent default behavior (moving focus)
            } else if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
         inputRefs.current[index - 1].current.focus();
       } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '') {
         inputRefs.current[index + 1].current.focus();
       }

       
     };
    const requestOtp =()=>{
       props.otpLoginAPI({
              body:{
                grant_type: "password",
                client_id: appConfig.API.CLIENT_ID,
                client_secret: appConfig.API.CLIENT_SECRET,
                user_type: 0,
                country: 'CA',
                phone: formatPhoneNumber(props?.phoneNumber),
                action: 'request_otp',
              },
              callback: (res, val) => {
                if(_.get(val, 'success')){
                  setSeconds(30)
                }
              }
            })
    }

    const verifyLogin = () => {
      
      // props?.onChangeScreen(4)
      setDotPulseBtnLoader(true)
      
      props.verifyOtpAPI({
        body:{
          grant_type: "password",
          user_type: 0,
          country: 'CA',
          phone: formatPhoneNumber(props?.phoneNumber),
          action: 'verify_otp',
          otp: otp.join(''),
          first_name: _.get(props, 'nameData.first_name'),
          last_name: _.get(props, 'nameData.last_name')
        },
        callback: (res, data) => {
          setDotPulseBtnLoader(false)
          if((_.get(data, 'error.code') === 400) ||res==false){
            seterrorOtp(true)
          };
          if(res==true && _.get(data,'isLoggedIn')==true){
            notification.success({
              message:"Success",
              description:'Login successful'
              });
          if(props?.otpType==2){
            props?.onChangeScreen(4)
          }else{
            
          }
                      }
        }
      })
    };

    const formatPhoneNumber = (value) => {
      // Remove non-numeric characters
      let strippedNumber = value.replace(/\D/g, '');
      
      return strippedNumber
    };

     const handlePaste = (event) => {
              
       event.preventDefault();
       const pastedData = event.clipboardData.getData('Text');
       if (pastedData.length === 6 ) {
         setOtp(pastedData.split('').slice(0, 6));
       }
     };
  return (
    <>
      
        <Box className={classes.modalContent} sx={{width:{
            xs: "330px", // mobile
            sm: "400px", // tablet
            md: "540px", // small desktop
            lg: "640px",  
        },padding:{
          xs: "16px", // mobile
          sm: "18px", // tablet
          md: "24px", // small desktop
          lg: "24px",
        }}}>
        <Box sx={{display:'flex',alignItems:{
           xs: "flex-start", // mobile
           sm: "center", // tablet
           md: "center", // small desktop
           lg: "center",  
        },justifyContent:'space-between',marginBottom:'16px'}}>
       {isMobile==false ?<Box sx={{borderRadius: "10px",
       display:'flex',alignItems:'center',justifyContent:'center',
border: "1px solid var(--Gray-200, #EAECF0)",
background: "var(--Base-White, #FFF)",
boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",width:"48px",height:'48px'}}>
       <Typography>
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 2V3.4C15 3.96005 15 4.24008 14.891 4.45399C14.7951 4.64215 14.6422 4.79513 14.454 4.89101C14.2401 5 13.9601 5 13.4 5H10.6C10.0399 5 9.75992 5 9.54601 4.89101C9.35785 4.79513 9.20487 4.64215 9.10899 4.45399C9 4.24008 9 3.96005 9 3.4V2M8.2 22H15.8C16.9201 22 17.4802 22 17.908 21.782C18.2843 21.5903 18.5903 21.2843 18.782 20.908C19 20.4802 19 19.9201 19 18.8V5.2C19 4.07989 19 3.51984 18.782 3.09202C18.5903 2.71569 18.2843 2.40973 17.908 2.21799C17.4802 2 16.9201 2 15.8 2H8.2C7.0799 2 6.51984 2 6.09202 2.21799C5.71569 2.40973 5.40973 2.71569 5.21799 3.09202C5 3.51984 5 4.0799 5 5.2V18.8C5 19.9201 5 20.4802 5.21799 20.908C5.40973 21.2843 5.71569 21.5903 6.09202 21.782C6.51984 22 7.07989 22 8.2 22Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</Typography>
       </Box>: <Typography sx={{color:"#101828",fontSize:{  xs: "18px", // mobile
         sm: "18px", // tablet
         md: "18px", // small desktop
         lg: "18px",},fontWeight:"600",marginBottom: "4px"}}>
           {props?.otpType==1? "Login with a phone number":"Sign up with a phone number"}
          </Typography>}
       <Typography component={"span"} sx={{cursor:'pointer'}} onClick={()=>{
        
          props?.closeModal()
       }}>
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 6L6 18M6 6L18 18" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
       </Typography>
</Box>
        {isMobile==false &&  <Typography sx={{color:"#101828",fontSize:{  xs: "14px", // mobile
         sm: "15px", // tablet
         md: "18px", // small desktop
         lg: "18px",},fontWeight:"600",marginBottom:"16px"}}>
          {props?.otpType==1? "Login with a phone number":"Sign up with a phone number"}
          </Typography>}

          {props?.otpType==1?<Typography sx={{color:"#475467",fontSize:{  xs: "14px", // mobile
         sm: "15px", // tablet
         md: "16px", // small desktop
         lg: "16px",},fontWeight:"400",marginBottom:"16px"}}>
           Welcome back, {`${_.get(props,"userDetails.first_name")}`}!
          </Typography>:
          
          <Typography sx={{color:"#475467",fontSize:{  xs: "14px", // mobile
         sm: "15px", // tablet
         md: "16px", // small desktop
         lg: "16px",},fontWeight:"400",marginBottom:"16px"}}>
           Looks like this is your first time here. Let’s get you started by creating a new account!
          </Typography>}
          <Typography sx={{color:"#344054",fontSize:{  xs: "14px", // mobile
         sm: "14px", // tablet
         md: "14px", // small desktop
         lg: "14px",},fontWeight:"500",marginBottom:"6px"}}>
           Phone number
          </Typography>
          <Box >
          <Box sx={{borderRadius: "8px",
border: "1px solid var(--Gray-300, #D0D5DD)",
background: "var(--Gray-50, #F9FAFB)",
boxShadow:" 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",display:'flex',alignItems:'center',justifyContent:'left',padding:"10px 14px"}}>
          <Typography sx={{color:"#101828",marginRight:'12px',fontSize:{  xs: "14px", // mobile
         sm: "14px", // tablet
         md: "16px", // small desktop
         lg: "16px",},fontWeight:"400"}}>
          +1
          </Typography>
          <Typography sx={{color:"#667085",fontSize:{  xs: "14px", // mobile
         sm: "14px", // tablet
         md: "16px", // small desktop
         lg: "16px",},fontWeight:"400"}}>
          {props?.phoneNumber}
          </Typography>
          </Box>
           <Typography sx={{marginTop:'6px', color:phoneNumberErrors.error==true?"#F04438": "#475467",fontSize:{  xs: "14px", // mobile
         sm: "14px", // tablet
         md: "14px", // small desktop
         lg: "14px",},fontWeight:"400",marginBottom:{
          xs:"16px",
          sm: "20px", // tablet
         md: "32px", // small desktop
         lg: "32px",
         }}}>
           {phoneNumberErrors.message}
          </Typography>
          </Box>
          <Box sx={{marginBottom:{
             xs:"24px",
             sm: "28px", // tablet
            md: "32px", // small desktop
            lg: "32px",
          }}}>
          <Typography sx={{color:"#475467",fontSize:{  xs: "14px", // mobile
         sm: "14px", // tablet
         md: "16px", // small desktop
         lg: "16px",},fontWeight: isMobile?"500":"600",marginBottom:"6px"}}>
          Verification Code
          </Typography>
          <div className="otp-container">
      {otp.map((digit, index) => (
       <div key={index} style={{display:'flex'}}>
              {!isMobile && index==3 && <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginRight:'8px'}}> 
                     <div style={{width:'28px',height:'10px',backgroundColor:'var(--Gray-300, #D0D5DD)',alignItems:'center'}} />
                     
                     </div>}
               <input
          key={index}
          className={errorOtp==true?"error-otp-text": "otp-input"}
          type="text"
          maxLength="1"
          value={digit}
          
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyUp={(e) => handleKeyUp(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={inputRefs.current[index]}
          onPaste={handlePaste}
          style={{
              marginRight:isMobile?"2px":'8px',
              width:isMobile?"48px":"64px",
              height:isMobile?"48px":"64px",
              fontSize:isMobile?"28px":"48px"

          }}
        />
       </div>
      ))}
    </div>
    {errorOtp ==true &&<div className='error-otp-des' style={{marginTop:'6px' }}>
    Incorrect code! Please try again
              </div>}
              <div className='email-resend-otp-text' style={{paddingTop:'16px',fontSize:isMobile?"14px":"16px"}}>
              Didn’t receive your code? {seconds==0?<span 
              onClick={()=>{
                requestOtp()
              }}
              style={{textDecorationLine:'underline',cursor:'pointer'}}>Resend verification code</span>:
              <span style={{fontSize:'1rem'}}>Resend code in {`(${seconds})`}</span>}
              </div>
          </Box>
          <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',
            flexDirection:{
              xs: "column-reverse", // mobile
              sm: "row", // tablet
              md: "row", // small desktop
              lg: "row",
           }
          }}>
          <Button onClick={()=>{
              dotPulseBtnLoader==false && props?.onChangeScreen(1)
          }}  style={{
              width: '100%', marginTop: isMobile?"12px": '4px', height: '44px', background: "#fff", color: "#344054",
              fontSize: '16px',
              fontWeight: "600"
            }}>
              Cancel
            </Button>
            <Button  style={{
              width: '100%', marginTop: '4px', height: '44px', background: "#EF6820", color: "#FFF",
              fontSize: '16px',
              fontWeight: "600",
              marginLeft:isMobile?"0px": '12px',
              display:'flex',
              alignItems:'center',
              justifyContent:'center'
            }}
            onClick={()=>{
              if(dotPulseBtnLoader==false){
                if(_.size(_.compact(otp))==6){
                  verifyLogin()
                }else{
                  seterrorOtp(true)
                }
               
              }
             
            }}
            >
             {dotPulseBtnLoader==true? <div className='dot-pulse-animation'/>:"Continue"}
            </Button>
          </Box>
        </Box></>
      
  );
};
const mapStateToProps = state => {
  return {
     
islogindata: _.get(state, 'app.account.isLogin'),
  }
}

const mapDispatchToProps = dispatch => ({
  
  login: details => dispatch(global.redux.action.account.socialLogin(details)),
  otpLoginAPI: details => dispatch(global.redux.action.guest.sendOtp(details)),
  checkProfileAPI: details => dispatch(global.redux.action.guest.checkProfile(details)),
  verifyOtpAPI: details => dispatch(global.redux.action.guest.phoneLogin(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginSignupOTPFlow)

