import appPages from './app-pages';

export default [
    {
        key: "login",
        element: <appPages.loginPage />,
        path:'/communiti/login',
        exact:true
    },
    // {
    //     key: "task",
    //     element: <appPages.loginPage />,
    //     path:'/login',
    //     exact:true
    // },
   
];
