import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import './promo.css';

const ViewPromotions = ({id, onCancel, props}) => {
  
    const [viewCouponDetails, setViewCouponDetails] = useState({});

    useEffect(() => {
        handleViewPromotions();
    }, [id]);

   const handleViewPromotions = () => {
    props.viewPromotionsAPI({
        params:{
            id: id
        },
        callback: (res, data) => {
            
            if(_.get(data, 'data.details')){
                setViewCouponDetails(_.get(data, 'data.details'));
            }
        }
    })
   }
  return (
    <div>
        <div style={{width: "100%", display: "flex", alignItems: "flext-start",marginTop: "16px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='view_promotion_name'>Promotion name</div>
                          <div className='view_promotion_supporting_txt'>Give your promotion a name.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                          <div className='view_promotion_name'>Promotion name</div>
                          <div style={{marginTop: "8px"}} className='view_promotion_input_txt_card'>
                          {_.get(viewCouponDetails, 'label')}
                          </div>
                          </div>
                          </div>


                          <div style={{width: "100%", display: "flex", alignItems: "flext-start",marginTop: "16px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='view_promotion_name'>Promotion code</div>
                          <div className='view_promotion_supporting_txt'>This is the code users will need to enter to take advantage of your promo</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                          <div className='view_promotion_name'>Promotion code</div>
                          <div style={{marginTop: "8px"}} className='view_promotion_input_txt_card'>
                          {_.get(viewCouponDetails, 'coupon_code')}
                          </div>
                          </div>
                          </div>
                          <hr/>


                          <div style={{width: "100%", display: "flex", alignItems: "flext-start",marginTop: "16px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='view_promotion_name'>Promotion start date & end date</div>
                          <div className='view_promotion_supporting_txt'>Choose a start date, start time, end date (optional) and end time(optional)</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                          <div className='view_promotion_name'>Timezone</div>
                          <div style={{marginTop: "8px"}} className='view_promotion_time_zone_txt_card'>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_9165_3682)">
    <path d="M9.99984 4.99984V9.99984L13.3332 11.6665M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_9165_3682">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg> {_.get(viewCouponDetails, 'timezone')}
                          </div>


                          <div style={{ display: "flex", flexDirection: "row", marginTop: '16px' }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className='view_promotion_name'>Start date</div>
                            <div style={{width: "255px", marginTop: "8px"}} className='view_promotion_input_txt_card'>
                            {moment.tz(_.get(viewCouponDetails, 'starts_in'), _.get(viewCouponDetails, 'timezone')).format('MM/DD/YYYY')}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "32px" }}>
                            <div className='view_promotion_name'>Start time</div>
                            <div style={{width: "255px", marginTop: "8px"}} className='view_promotion_input_txt_card'>
                            {moment.tz(_.get(viewCouponDetails, 'starts_in'), _.get(viewCouponDetails, 'timezone')).format('hh:mm A')}
                            </div>
                        </div>
                    </div>


                    {_.get(viewCouponDetails, 'expires_in') && <Checkbox style={{marginTop: "16px"}} defaultChecked={true}><div style={{marginLeft: "12px"}} className='view_promotion_name'>Set end date</div></Checkbox>}


                    {_.get(viewCouponDetails, 'expires_in') && <div style={{ display: "flex", flexDirection: "row", marginTop: '16px' }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className='view_promotion_name'>End date</div>
                            <div  style={{width: "255px", marginTop: "8px"}} className='view_promotion_input_txt_card'>
                            {moment.tz(_.get(viewCouponDetails, 'expires_in'), _.get(viewCouponDetails, 'timezone')).format('MM/DD/YYYY')}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "32px" }}>
                            <div className='view_promotion_name'>End time</div>
                            <div  style={{width: "255px", marginTop: "8px"}} className='view_promotion_input_txt_card'>
                           {moment.tz(_.get(viewCouponDetails, 'expires_in'), _.get(viewCouponDetails, 'timezone')).format('hh:mm A')}
                            </div>
                        </div>
                    </div>}
                          </div>
                          </div>
                          <hr/>

                          <div style={{width: "100%", display: "flex", alignItems: "flext-start",marginTop: "16px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='view_promotion_name'>Discount type and amount</div>
                          <div className='view_promotion_supporting_txt'>Choose the type of discount you, the amount or percentage, and specify any minimum spend (optional)</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                          <div className='view_promotion_name'>Discount type</div>

                          <div style={{display: "flex", flexDirection: 'row', marginTop: "8px"}}>
                            <div className={_.get(viewCouponDetails, 'amount_type') === 0 ? 'view_promotion_discount_card' : 'view_promotion_discount_unselected_card'} style={{flexDirection: "column"}}>
                                <div className={_.get(viewCouponDetails, 'amount_type') === 0 ? 'view_promotion_discount_header_text' : 'view_promotion_discount_not_selected_header_txt'}>Percentage</div>
                                <div className={_.get(viewCouponDetails, 'amount_type') === 0 ? 'view_promotion_discount_supporting_text' : 'view_promotion_discount_not_selected_supporting_txt'}>
                                This will be a percentage based on<br/> the total price of the session
                                </div>
                            </div>

                            <div className={_.get(viewCouponDetails, 'amount_type') > 0 ? 'view_promotion_discount_card' : 'view_promotion_discount_unselected_card'} style={{flexDirection: "column", marginLeft: "16px"}}>
                                <div className={_.get(viewCouponDetails, 'amount_type') >  0 ? 'view_promotion_discount_header_text' : 'view_promotion_discount_not_selected_header_txt'}>Fixed amount</div>
                                <div className={_.get(viewCouponDetails, 'amount_type') > 0 ? 'view_promotion_discount_supporting_text' : 'view_promotion_discount_not_selected_supporting_txt'}>
                                This will be a fixed amount that doesn’t change
                                </div>
                            </div>
                            </div>

                            <div style={{marginTop: "16px"}} className='view_promotion_name'>Discount</div>
                          <div style={{width: "253px", marginTop: "8px"}} className='view_promotion_discount_txt_card'>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                    <div>{_.get(viewCouponDetails, 'amount')}</div>
                                    <div style={{marginLeft: '190px'}}>{ _.get(viewCouponDetails, 'amount_type') === 0 ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.8332 4.1665L4.1665 15.8332M7.49984 5.83317C7.49984 6.75365 6.75365 7.49984 5.83317 7.49984C4.9127 7.49984 4.1665 6.75365 4.1665 5.83317C4.1665 4.9127 4.9127 4.1665 5.83317 4.1665C6.75365 4.1665 7.49984 4.9127 7.49984 5.83317ZM15.8332 14.1665C15.8332 15.087 15.087 15.8332 14.1665 15.8332C13.246 15.8332 12.4998 15.087 12.4998 14.1665C12.4998 13.246 13.246 12.4998 14.1665 12.4998C15.087 12.4998 15.8332 13.246 15.8332 14.1665Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 13.3332C5 15.1741 6.49238 16.6665 8.33333 16.6665H11.6667C13.5076 16.6665 15 15.1741 15 13.3332C15 11.4922 13.5076 9.99984 11.6667 9.99984H8.33333C6.49238 9.99984 5 8.50745 5 6.6665C5 4.82555 6.49238 3.33317 8.33333 3.33317H11.6667C13.5076 3.33317 15 4.82555 15 6.6665M10 1.6665V18.3332" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>}</div>
                            </div>
                          </div>

                          <div style={{marginTop: "16px"}} className='view_promotion_name'>Minimum spend (optional)</div>
                          <div style={{width: "253px", marginTop: "8px"}} className='view_promotion_discount_txt_card'>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                    <div>{_.get(viewCouponDetails, 'min_amount') ? _.get(viewCouponDetails, 'min_amount') : '0'}</div>
                                    <div style={{marginLeft: '190px'}}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 13.3332C5 15.1741 6.49238 16.6665 8.33333 16.6665H11.6667C13.5076 16.6665 15 15.1741 15 13.3332C15 11.4922 13.5076 9.99984 11.6667 9.99984H8.33333C6.49238 9.99984 5 8.50745 5 6.6665C5 4.82555 6.49238 3.33317 8.33333 3.33317H11.6667C13.5076 3.33317 15 4.82555 15 6.6665M10 1.6665V18.3332" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg></div>
                            </div>
                          </div>
                          </div>
                          </div>
    </div>
  )
}

export default ViewPromotions