import { Icon, Dropdown, Badge, Menu, Input, AutoComplete, Button, Image, Card } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./header.css";
import { connect } from "react-redux";
import _ from 'lodash';
// import { googleLogout } from '@react-oauth/google';
import { BellFilled, BellOutlined } from "@ant-design/icons";
import chatkitty from "../../core_units/chat-system/chatkitty";
import ChatHome from "../../core_units/chat-system/components/home/index";
import NotificationDropdown from "./notification";
const PageHeader = (props) => {
    let history = useNavigate();
    const [menuVisible, setmenuVisible] = useState(false)
    const [userProfile, setuserProfile] = useState({})
    const [schoolDetailsView, setschoolDetailsView] = useState({})
    const [userProfileMeta, setuserProfileMeta] = useState({})
    const [school_photo, setschool_photo] = useState(null)
    const [notificationsListData, setNotificationsListData] = useState([])
    const ToggleMenu = () => {
        setmenuVisible(!menuVisible)
    }
    const logout = () => {
        props.logout({
            callback: (response, data) => {
                // googleLogout();
                history('/communiti/login')
                window.location.reload(false);
            }
        })
    }
    // useEffect(() => {
    //     getProfile()
    // }, [props.hostProfileData])

    useEffect(() => {
        if (_.get(props, 'checkUpdate') == true) {
            if (_.get(props, 'islogindata.isLoggedIn') === true) {
                setuserProfile({})
                notificationList()
                   getProfile()
               
            }

        }

    }, [_.get(props, 'checkUpdate')]);

    
    const notificationList = () => {
        props.notificationsListApi({
           params: {
              sort: 'created_at.desc',
              limit: 100000,
              user_type: 1,
              expand:"type_id"
              // status:2,
           },
           callback: (response, data) => {
              if (response) {
                 let a = _.times(10, String)
                 let b = []
                 _.map(a, (value, index) => {
                    b.push({ ..._.get(data, 'data.items[0]'), key: index })
                 })
                 const sortedArray = _.sortBy(_.get(data, 'data.items', []), [function(o) { return -o.status; }]);
                 setNotificationsListData(_.take(sortedArray,5))
                 
  
  
  
              }
           }
        });
     }

    const getProfile = async () => {
        let conditionType = _.includes(_.get(props,'islogindata.types'),1)==true?'hostProfileData':'guestProfileData';
        props[conditionType]({
            params:{expand:'invite_code'},
           
            callback: async (res, data) => {
                let displayPictureUrl=`${_.get(data, 'data.meta.photo.path')}/${_.get(data, 'data.meta.photo.folder')}/${_.get(data, 'data.meta.photo.sizes.medium')}/${_.get(data, 'data.details.photo')}` 
                
               chatkitty.initialyze({},{..._.get(data, 'data.details'),displayPictureUrl})
                
                if (res == true && _.get(data, 'data.details.id')) {
                    await setuserProfile(_.get(data, 'data.details'))
                    await setuserProfileMeta(_.get(data, 'data.meta'))
                    setmenuVisible(true)
                }
            }
        })
    }

    const readNotification =(value)=>{
        props.notificationsReadApi({
            params: { ids: [_.get(value, 'id')], user_type: 1 },
            body: { ids: [_.get(value, 'id')], user_type: 1 },
            callback: (res, data1) => {

               if (res == true) {
                notificationList()
                history('/communiti/host?menu=profile&profile_menu=notifications')
               }
            }
         })

    };

    const redirectHome = () => {
        if (_.get(props, 'islogindata.isLoggedIn') === true) {
        history('/template-list?menu=template');
        }
    }

   
    const render = () => {
        let photo =_.get(userProfile, 'photo')==null?require('../../assets/img/push-and-app-icon.png') :`${_.get(userProfileMeta, 'photo.path')}/${_.get(userProfileMeta, 'photo.folder')}/512/${_.get(userProfile, 'photo')}?time=${new Date().getTime()}`


        return (
            <div className="PageHeader">
          {/* {renderCard()} */}
                <div className="PageHeaderLogoContainer" style={{    borderBottom: '1.5px solid #d3d3d3'}}>
                    <div className="container" style={{maxWidth:'100%'}}>
                        <div className="PageHeaderLogoContainerInner">
                            <div className="PageHeaderLogoContainerInnerLeft">
                                <div className="LogoSet">
                                   <div onClick={() => redirectHome()} style={{display:'flex', flexDirection:'row', cursor: "pointer"}}>
                                    <Image height={35} width={35} preview={false} src={require('../../assets/img/push-and-app-icon.png')}/>
                                   <div style={{  marginLeft:'2px',  marginTop: '4px',fontSize: '19px',color: '#e85f2f'}}>communiti</div>
                                   </div>
                                </div>
                               
                            </div>
                            {_.get(props, 'checkUpdate') == true &&
                                _.get(userProfile, 'id') && <div  className="PageHeaderLogoContainerInnerRight">
                                  
                                  {/* {_.get(userProfile, 'approved_status')==true &&_.get(userProfile, 'stripe_user_id')!=null&& <BellFilled  size={45}   />} */}
                             <div style={{width:'120px'}} 
                                    // onClick={e => e.preventDefault()}
                                    >
                                      {/* <div style={{    padding:" 5px 11px",color: "#e85f2f", fontSize: "15px"}}>Club & Coach</div> */}
                                </div>
                                
                   <NotificationDropdown notificationsListData={notificationsListData} readNotification={(value)=>{
                    readNotification(value)
                   }}/>                 
      
   
                                   
                            <Dropdown
                            
                                overlay={(
                                    <Menu>
                                       {_.get(userProfile, 'approved_status')==true &&_.get(userProfile, 'stripe_user_id')!=null&& <>
                                        <Menu.Item onClick={()=>history('/template-list?menu=template')} key="1">
                                            <div >Template</div>
                                        </Menu.Item>
                                        <Menu.Item onClick={()=>history('/communiti/manage?menu=manage')} key="2">
                                            <div >Manage</div>
                                        </Menu.Item>
                                        <Menu.Item onClick={()=>history('/communiti/finances-list?menu=reports')} key="3">
                                            <div >Customers</div>
                                        </Menu.Item>
                                        
                                        <Menu.Item onClick={()=>history(`/communiti/host?menu=profile`)} key="4">
                                            <div >Profile</div>
                                        </Menu.Item>

                                      
                                        {/* <Menu.Item onClick={()=>history('/communiti/main-menu')} key="5">
                                            <div >Main Menu</div>
                                        </Menu.Item> */}
                                        
                                        </>}
                                        <Menu.Item onClick={()=>logout()} key="6">
                                            <div >Sign Out</div>
                                        </Menu.Item>
                                        {/* <Menu.Item onClick={()=>history('/communiti/pay-invoive')} key="7">
                                            <div >Pay Invoice</div>
                                        </Menu.Item> */}
                                        
                                    </Menu>
                                )}
                                overlayStyle={{marginRight: "40px"}}
                                trigger={['click']}>
                                <a className="ant-dropdown-link" 
                                    onClick={e => e.preventDefault()}><img
                                        src={photo}
                                        className="HeaderUserImg"
                                    /></a>
                                    </Dropdown>
                                    
                                </div>}
                        </div>
                    </div>
                </div>
                <span className="MobileOverLay" onClick={() => ToggleMenu()}></span>
            </div>
        );


    }
    return render()


}
const mapStateToProps = state => {
   return {
        checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
        islogindata: _.get(state, 'app.account.isLogin'),
        // userDetails: _.get(state, 'app.host.isLogin'),
        userProfile: _.get(state, 'app.host.profileLoadingInProgress'),
        guestUserProfile: _.get(state, 'app.guest.profileLoadingInProgress')
    }
}

const mapDispatchToProps = dispatch => ({
    isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
    logout: details => dispatch(global.redux.action.account.logout(details)),
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
   guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
   notificationsListApi: details => dispatch(global.redux.action.notifications.list(details)),
   notificationsReadApi: details => dispatch(global.redux.action.notifications.read(details)),

});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
