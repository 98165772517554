import { Button, Card, Divider, Image, Modal, Radio, Select, Space, Table, Tag, Tooltip, DatePicker as AntDatePicker, TimePicker, Input, InputNumber, message } from "antd"
import Scrollbars from "react-custom-scrollbars"
import _, { set } from 'lodash';
import { connect } from 'react-redux';
import { BsPlusCircleDotted } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import momentTimezone from 'moment-timezone';
import moment from 'moment'
import { CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import "react-multi-date-picker/styles/layouts/mobile.css"
import PlacesAutocomplete from 'react-places-autocomplete';

import Icon from "react-multi-date-picker/components/icon"
import { DateObject } from "react-multi-date-picker";
import { geocodeByAddress } from 'react-places-autocomplete';

import './style.css'
import dayjs from "dayjs";

const { Option } = Select;
const countryList = [{ name: 'Canada', value: 'CAD' },
{ name: 'United States', value: 'USD' }
]
const currencyList = [{ name: 'CAD', value: 'CAD' },
{ name: 'USD', value: 'USD' }
]

const BulkSchedule = (props) => {
  const [pageLoading, setPageLoading] = useState(false)
  const [page, setpage] = useState(1)
  const [experiencesList, setExperiencesList] = useState([])
  const [experiencesListMeta, setExperiencesListMeta] = useState({})
  const [messageApi, contextHolder] = message.useMessage();
  const [questionSetId, setquestionSetId] = useState('');
  const [questionSetList, setquestionSetList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [AllTemplateListData, setAllTemplateListData] = useState([])
  const [AllTemplateListMeta, setAllTemplateListMeta] = useState({})
  const [isWarninngModalOpen, setIsWarninngModalOpen] = useState(false);
  const [getWaningData, setWaningData] = useState({});
  const [scheduleSelectId, setScheduleSelectId] = useState('')
  const [scheduledTempId, setscheduledTempId] = useState('')
  const [openTempId, setopenTempId] = useState('')
  const [leagueTempId, setleagueTempId] = useState('')
  const [timeZone, setTimeZone] = useState('');
  const [datePickerModal, setdatePickerModal] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const calendarRef = useRef()

  //Form values
  const [form, setform] = useState(1)
  const [scheduleType, setscheduleType] = useState('')
  const [isLateRegisteration, setisLateRegisteration] = useState(2)
  const [selectedDate, setSelectedDate] = useState([])
  const [templateDetails, settemplateDetails] = useState({})
  const [country, setcountry] = useState(null)
  const [address, setAddress] = useState('');
  const [lateRegisterationDate, setlateRegisterationDate] = useState(null)

  const [startTime, setstartTime] = useState(null);
  const [endTime, setendTime] = useState(null)
  const [startTime1, setstartTime1] = useState(null);
  const [endTime1, setendTime1] = useState(null)
  const [TaxListData, setTaxListData] = useState([])
  const [address_results, setaddress_results] = useState([]);
  const [getTaxesValue, setTaxesValue] = useState('')
  const [isProrate, setisProrate] = useState(1)
  const [currency, setcurrency] = useState('')
  const [zip_code, setzip_code] = useState('')
  const [tax_state, settax_state] = useState('')
  const [tax_statename, settax_statename] = useState('')
  const [sessionScheduleDates, setsessionScheduleDates] = useState([])
  const [iscalenderOpen, setiscalenderOpen] = useState(false)
  const [selectedValues, setSelectedValues] = useState();
  const [sessionPrice, setsessionPrice] = useState('')
  const [noOfOpenings, setnoOfOpenings] = useState('')
  const [teamSize, setteamSize] = useState('')
  const [userTags, setuserTags] = useState([])
  const [sessionTags, setsessionTags] = useState([])
  const [createLoading, setcreateLoading] = useState(false)
  const [bulkSchedule, setbulkSchedule] = useState(1)
  const [bulkScheduleType, setbulkScheduleType] = useState('')
  const [experienceStoredData, setexperienceStoredData] = useState([])
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false)
  const [commonEditDeleteId, setcommonEditDeleteId] = useState('');
  const [CouponList, setCouponList] = useState([]);
      const [couponId, setcouponId] = useState([]);
  useEffect(() => {
    getLocationsData()
    loadTaxList();
    questionSetsList();
    getCouponList()
  }, []);
  const questionSetsList = () => {
    props.questionSetsList({
      params: { limit: 1000 },
      callback: (res, data) => {
        if (res == true && _.get(data, 'data.items[0]'))
          setquestionSetList(_.get(data, 'data.items'))
      }
    })
  }
  const loadTaxList = () => {
    props.taxList({
      params: { limit: 100 },
      callback: (res, data) => {
        if (res == true && _.get(data, 'data.items[0]'))
          setTaxListData(_.get(data, 'data.items'))
      }
    })
  }
  const showWarningModal = (value) => {
    setWaningData(value);
    setIsWarninngModalOpen(true);
  };
  const getLocationsData = async () => {
    const { data, status } = await axios.get('https://ipapi.co/json')
    // setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
    await setTimeZone(_.get(data, 'timezone'))
    loadTemplateList();
    const urlParams = new URLSearchParams(window.location.search);
    const page = parseInt(urlParams.get('page')) || 1;
    const pageSize = parseInt(urlParams.get('pageSize')) || 10;

    setPagination((prev) => ({ ...prev, current: page, pageSize }));
    // fetchData(page, pageSize); actual experience list have to be come
    // if(!_.isEmpty(commonFilterTags)){
    // loadExperienceList(_.get(data, 'timezone'), 1, 100)
    // } 
    // else {
    // loadExperienceList(_.get(data, 'timezone'), page, pageSize)
    // }

  }

  useEffect(() => {
    loadTemplateList()
    let item1 = localStorage.getItem('ExperiencesList')
    let item2 = localStorage.getItem('experienceStoredData')

    if (item1) {
      setExperiencesList(JSON.parse(item1))
    }
    if (item2) {
      setexperienceStoredData(JSON.parse(item2))
    }

  }, [props?.selectedOption])

  const loadTemplateList = () => {
    setPageLoading(true)
    let params = {
      approved_status: 1,
      expand: 'photos',
      sort: 'created_at.desc',
      limit: 1000,
      status: 1

    }
    if (_.get(props, 'userProfile.business_type') == 'coordinator') {
      params.host = _.get(props, 'userProfile.parent_host')
    }
    props.templateList({
      params: params,
      callback: (res, data) => {

        if (res == true && _.get(data, 'data.items[0]')) {
          setAllTemplateListData(_.get(data, 'data.items'))
          setAllTemplateListMeta(_.get(data, 'data.meta'))
        }
        setPageLoading(false)
      }
    })
  }
  const loadExperienceList = (timezone, page, pageSize, search, filter_data_value) => {

    var time = moment("12:00 am", ["hh:mm a"]).format("HH:mm");
    const date2 = momentTimezone().tz(timezone).format("YYYY-MM-DD");
    let end_date = momentTimezone(`${date2}T${time}`).tz(timezone).toISOString()
    let params = {
      expand: 'template,template_photos,completed_orders',
      is_completed: '0',
      sort: 'created_at.desc',
      host: props.userProfile.id,
      limit: pageSize,
      page: page,
      is_open_for_host: 1,
    };


    if (_.get(props, 'userProfile.business_type') == 'coordinator') {
      params.host = _.get(props, 'userProfile.parent_host')
    }
    setPageLoading(true)
    props.list({
      params: params,
      callback: async (response, data) => {
        if (response) {

          await setTableData(_.get(data, 'data.items', []), timezone)
          setExperiencesListMeta(_.get(data, 'data.meta', {}));
          setPagination((prev) => ({ ...prev, total: _.get(data, 'data.meta.total', {}) }));

        }
        setPageLoading(false)
      }
    }
    );
  };
  const cancelationCheck = (value) => {
    if (_.get(value, 'experience_date') && _.get(value, 'experience_start_time') && timeZone) {


      const time = _.get(value, 'experience_start_time', "")
      let dateNow = moment(new Date()).format('YYYY-MM-DD')
      let nowDate = moment(_.get(value, 'experience_date')).tz(timeZone).format("YYYY-MM-DD")
      const date = nowDate
      let todayDate = momentTimezone().tz(timeZone).format('YYYY-MM-DD HH:mm')
      let checkDate = momentTimezone(`${date}T${time}`).tz(timeZone).format('HH:mm')
      checkDate = `${nowDate} ${checkDate}`
      if (moment(todayDate).isSameOrBefore(checkDate)) {
        return true;
      }
      return false;
    } else {
      return false
    }
  }
  const setTableData = async (data, timezone) => {
    let timeZones = Intl.DateTimeFormat().resolvedOptions().timeZone

    let a = []
    _.map(AllTemplateListData, (value, index) => {
      let calculations = _.get(value, 'no_of_bookings') - parseInt(_.get(value, 'completed_orders'));
      a.push({
        key: index,
        title: _.get(value, 'template.type') == 'open' && calculations > 0 && _.get(value, 'status') === 0 ? <div><WarningOutlined className='warning-open-session' onClick={() => showWarningModal(value)} />   {_.get(value, 'template.title')}</div> : _.get(value, 'template.title'),
        template_type: _.get(value, 'template.type'),
        status: _.get(value, 'template.status'),
        start_date: moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('ddd. MMM D, YYYY'), //moment.tz(experience.start_date, user_time_zone).format(); // moment(_.get(value, 'experience_date')).tz(timeZones).format('MMM DD, YYYY')
        is_cancel: cancelationCheck(value),
        start_time: _.get(value, 'template.type') == 'open' ? "null" : _.get(value, 'experience_start_time') ? moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--',  //moment(_.get(value,'experience_start_time')).tz(timeZones).format("hh:mm A")
        tempalate_id: _.get(value, 'template.id'),
        template_name: _.get(value, 'template.title'),
        exp_id: _.get(value, 'id'),
        chatkitty_channel_id: _.get(value, 'chatkitty_channel_id', ''),
        temp_type: _.get(value, 'type'),
        exp_status: _.get(value, 'status'),
        currency_type: _.get(value, 'meta_details.currency'),
        address: _.get(value, 'address'),
        exp_price: _.get(value, 'price'),
        late_registration_time_limit: _.get(value, 'late_registration_time_limit'),
        tags: _.get(value, 'tags'),
        schedule_dates: _.get(value, 'schedule_dates', []),
        no_of_available_slots: _.get(value, 'no_of_available_slots'),
        no_of_bookings: _.get(value, 'no_of_bookings'),
        experience_start_date: `${moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')}`, //moment.tz(experience.start_date, user_time_zone).format(); // moment(_.get(value, 'experience_date')).tz(timeZones).format('MMM DD, YYYY'),
        // experience_details : value,
        experience_start_time: _.get(value, 'template.type') == 'open' ? "null" :
          `${_.get(value, 'experience_start_time') ?
            moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm") : '--'}-${_.get(value, 'experience_end_time') ? moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`,

      })
    })
    setExperiencesList(a)

  };



  const commonTableData = (data) => {

    setexperienceStoredData(data)
    localStorage.setItem('experienceStoredData', JSON.stringify(data));
    let a = []
    _.map(data, (value, index) => {
      let calculations = _.get(value, 'no_of_bookings') - parseInt(_.get(value, 'completed_orders'));

      a.push({
        key: index,
        title: _.get(value, 'template.type') == 'open' && calculations > 0 && _.get(value, 'status') === 0 ? <div><WarningOutlined className='warning-open-session' onClick={() => showWarningModal(value)} />   {_.get(value, 'template.title')}</div> : _.get(value, 'template.title'),
        template_type: _.get(value, 'template.type'),
        type: _.get(value, 'template.type'),
        status: _.get(value, 'template.status'),
        start_date: moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('ddd. MMM D, YYYY'), //moment.tz(experience.start_date, user_time_zone).format(); // moment(_.get(value, 'experience_date')).tz(timeZones).format('MMM DD, YYYY')
        is_cancel: cancelationCheck(value),
        start_time: _.get(value, 'template.type') == 'open' ? "null" : _.get(value, 'experience_start_time') ? moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--',  //moment(_.get(value,'experience_start_time')).tz(timeZones).format("hh:mm A")
        tempalate_id: _.get(value, 'template.id'),
        template_name: _.get(value, 'template.title'),
        exp_id: _.get(value, 'id'),
        chatkitty_channel_id: _.get(value, 'chatkitty_channel_id', ''),
        temp_type: _.get(value, 'type'),
        exp_status: _.get(value, 'status'),
        currency_type: _.get(value, 'meta_details.currency'),
        address: _.get(value, 'address'),
        exp_price: _.get(value, 'price'),
        late_registration_time_limit: _.get(value, 'late_registration_time_limit'),
        tags: _.get(value, 'tags'),
        schedule_dates: _.get(value, 'schedule_dates', []),
        max_no_of_openings: _.get(value, 'max_no_of_openings'),
        experience_start_date: `${moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ? _.get(value, 'meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')}`, //moment.tz(experience.start_date, user_time_zone).format(); // moment(_.get(value, 'experience_date')).tz(timeZones).format('MMM DD, YYYY'),
        experience_start_time: _.get(value, 'template.type') == 'open' ? "null" :
          `${_.get(value, 'start_time') ?
            moment.tz(_.get(value, 'start_time'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ?
              _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm")
            : '--'}-${_.get(value, 'end_time') ?
              moment.tz(_.get(value, 'end_time'), !_.isEmpty(_.get(value, 'meta_details.timezone')) ?
                _.get(value, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`,
        can_prorate: _.get(value, 'can_prorate'),
        can_late_register: _.get(value, 'can_late_register'),
        quickSchedule: _.get(value, 'quickSchedule'),
        template: _.get(value, 'template'),
        meta_details: _.get(value, 'meta_details'),
        save_start_time: _.get(value, 'save_start_time'),
        save_end_time: _.get(value, 'save_end_time'),
        save_late_registration_date: _.get(value, 'save_late_registration_date'),
        save_schedule_dates: _.get(value, 'save_schedule_dates'),
        address_results: _.get(value, 'address_results'),
        tax_state: _.get(value, 'tax_state'),
        price: _.get(value, 'price'),
        uniq_id: _.get(value, 'uniq_id'),
        saved_details: value,
        type: _.get(value, 'template.type'),
      })
    })

    setExperiencesList(a)
    localStorage.setItem('ExperiencesList', JSON.stringify(a));


  }



  const calculateSessionSechduleDates = (dates) => {
    if (_.size(dates) > 0 && _.isArray(dates) == true) {
      let a = []


    }
  }

  const columns = [
    {
      title: 'Template ID & Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, item) => {
        return item?.id == 1 ? "" : <a>{text}</a>
      },
    },
    {
      title: 'Date, Time & Location',
      dataIndex: 'date_time_location',
      key: 'date_time_location',
      render: (text, item) => {
        return item?.id == 1 ? "" : <div className='tool-tip-manage'>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'experience_start_date')}</p>
          {_.get(item, 'template_type') != "open" && _.get(item, 'experience_start_time') != "null" && <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'experience_start_time')}</p>}
          {_.size(_.get(item, 'schedule_dates')) > 0 && <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.size(_.get(item, 'schedule_dates'))} Sessions</p>}
          {_.get(item, 'can_late_register') == true && <Tag color="#32D583" style={{ borderRadius: '16px' }}>Late Registration</Tag>}

        </div>
      },
    },
    {
      title: 'Session Info',
      dataIndex: 'address',
      key: 'address',
      render: (text, item) => {

        return item?.id == 1 ? <div style={{ display: 'flex', alignItems: 'center', marginLeft: -30 }}>
          <BsPlusCircleDotted style={{ marginRight: 10 }} color="#475467" size={24} />
          <div className="header-first-row">Schedule New Session </div>
        </div> : <div>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'template_type') == "league" ? "League Session" : _.get(item, 'template_type') == "open" ? "Open Session" : "Scheduled Session"}</p>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>${_.get(item, 'exp_price')} {_.get(item, 'currency_type')}</p>
          <p style={{ lineHeight: '20px', fontWeight: 400, fontSize: '14px', color: '#475467', marginBottom: '0px' }}>{_.get(item, 'max_no_of_openings')} Spaces</p>
          {_.get(item, 'can_prorate') == true && <Tag color="#32D583" style={{ borderRadius: '16px' }}>Prorate</Tag>}
        </div>
      },
    },
    {
      title: 'Additional Options',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { key }) => (
        key == 0 ? "" : <div></div>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (key, record) => {
        return record?.id == 1 ? "" : <Space size="middle">
          <a onClick={() => {
            editFlow(record)
            setcommonEditDeleteId(_.get(record, 'uniq_id'))

          }}>Edit</a>
          <a onClick={() => {
            setshowDeleteConfirm(true);
            setcommonEditDeleteId(_.get(record, 'uniq_id'))
          }}>Remove</a>
        </Space>
      }
      ,
    },
  ];


  const convertDate = (dates) => {
    let final_array = []
    let final_array1 = []
    _.map(dates, (v) => {
      let date1 = new DateObject(v).format('YYYY-MM-DD')
      final_array.push(new DateObject(v).format('MMM DD, YYYY'))
      // final_array1.push(new DateObject(v))

    })
    setSelectedDate(final_array)
    setsessionScheduleDates(final_array1)

  }

  const renderDatePickerSelectedDate = (dates) => {
    return _.map(dates, (v) => moment(v, "MMM DD, YYYY").format('YYYY-MM-DD'))



  }

  const removeSelectedDate = (d, index) => {
    let a = [...selectedDate]
    var evens = _.remove(a, function (n, i) {
      return i == index;
    });
    setSelectedDate(a)



  }

  const buildCancellationTimeLimit = (experience_start_time, experience_date) => {
    return moment(experience_start_time).format("HH:mm:ss");
  };
  const onSubmitSchedule = () => {
    if (_.get(templateDetails, 'type') == 'scheduled') {
      const isJson = (str) => {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      }



      let address = _.get(_.head(address_results), 'value')
      var details = {
        uniq_id: _.uniqueId('exp_'),
        price: sessionPrice,
        mode: _.get(templateDetails, 'mode', null),
        zip_code: _.get(address, 'postal_code') ? _.get(address, 'postal_code') : zip_code,
        min_no_of_openings: _.get(templateDetails, 'min_no_of_openings', 0),
        max_no_of_openings: noOfOpenings ? noOfOpenings : _.get(templateDetails, 'max_no_of_openings', 0),
        location: `${_.get(address, 'lat')},${_.get(address, 'lng')}`,
        address: _.get(address, 'text'),
        experience_start_time: moment(startTime1).format("HH:mm:ss"),
        experience_end_time: moment(endTime1).format("HH:mm:ss"),
        experience_date: moment(_.head(selectedDate), 'MMM DD, YYYY').format("YYYY-MM-DD"),
        experience_end_date: moment(_.last(selectedDate), 'MMM DD, YYYY').format("YYYY-MM-DD"),
        id: _.get(templateDetails, 'id', null),
        tax_state: tax_state ? parseInt(tax_state) : parseInt(tax_state),
        can_late_register: isLateRegisteration == 1 ? true : false,
        late_registration_time_limit: isLateRegisteration == 1 ? lateRegisterationDate : null,
        cancellation_time_limit: buildCancellationTimeLimit(startTime1, _.head(selectedDate)),
        type: _.get(templateDetails, 'type', null),
        meta_details: {
          timezone: !_.isEmpty(selectedValues) ? _.isArray(selectedValues) ? _.head(selectedValues) : selectedValues : _.isArray(_.get(props, 'userProfile.meta_details.timezone')) ? _.get(props, 'userProfile.meta_details.timezone[0]') : _.get(props, 'userProfile.meta_details.timezone'),
          currency: currency,

        },
        tags: sessionTags,
        can_prorate: isProrate == 1 ? true : false,
        template: templateDetails,
        start_time: startTime1,
        end_time: endTime1,
        quickSchedule: scheduleType,
        address_results: address_results,
        save_start_time: startTime,
        save_end_time: endTime,
        save_late_registration_date: lateRegisterationDate,
        registration_info_set_id:questionSetId

      };

      if (_.size(selectedDate) > 0) {
        details.schedule_dates = _.map(selectedDate, date => moment(date, 'MMM DD, YYYY').format("YYYY-MM-DD"))
        details.save_schedule_dates = selectedDate
      }

      if (_.size(experienceStoredData) > 0) {
        let aDetails = [...experienceStoredData]
        let mapDetails = _.filter(aDetails, (v, i) => v?.uniq_id != commonEditDeleteId)



        commonTableData([details, ...mapDetails])
      } else {
        commonTableData([details, ...experienceStoredData])
      }

      // setexperienceStoredData([ details,...experiencesList])
      // setAllTemplateListData([...AllTemplateListData, details])
      setIsModalOpen(false)
      quickResetScheduleField('all')


    } else if (_.get(templateDetails, 'type') == 'open') {
      var details = {
        uniq_id: _.uniqueId('exp_'),
        price: _.get(templateDetails, 'price', null),
        mode: _.get(templateDetails, 'mode', null),
        zip_code: _.get(templateDetails, 'zip_code', null),
        min_no_of_openings: _.get(templateDetails, 'min_no_of_openings', 0),
        max_no_of_openings: noOfOpenings ? noOfOpenings : _.get(templateDetails, 'max_no_of_openings', 0),
        id: _.get(templateDetails, 'id', null),
        tax_state: _.get(templateDetails, 'tax_state', null),
        type: _.get(templateDetails, 'type', null),
        location: `${_.get(templateDetails, 'location.x')},${_.get(templateDetails, 'location.y')}`,
        address: _.get(templateDetails, 'address'),
        meta_details: {
          currency: _.get(templateDetails, 'meta_details.currency'),
        },
        tags: sessionTags,
        template: templateDetails,
        quickSchedule: scheduleType,
        registration_info_set_id:questionSetId
      };
      // setcreateLoading(true)
      setIsModalOpen(false)
      if (_.size(experienceStoredData) > 0) {
        let aDetails = [...experienceStoredData]
        let mapDetails = _.filter(aDetails, (v, i) => v?.uniq_id != commonEditDeleteId)



        commonTableData([details, ...mapDetails])
      } else {
        commonTableData([details, ...experienceStoredData])
      }
      quickResetScheduleField('all')

    } else if (_.get(templateDetails, 'type') == 'league') {
      let address = _.get(_.head(address_results), 'value')
      var details = {
        uniq_id: _.uniqueId('exp_'),
        price: sessionPrice,
        mode: _.get(templateDetails, 'mode', null),
        zip_code: _.get(address, 'postal_code') ? _.get(address, 'postal_code') : zip_code,
        min_no_of_openings: _.get(templateDetails, 'min_no_of_openings', 0),
        max_no_of_openings: noOfOpenings,
        location: `${_.get(address, 'lat')},${_.get(address, 'lng')}`,
        address: _.get(address, 'text'),
        experience_start_time: moment(startTime1).format("HH:mm:ss"),
        experience_end_time: moment(endTime1).format("HH:mm:ss"),
        experience_date: moment(_.head(selectedDate), 'MMM DD, YYYY').format("YYYY-MM-DD"),
        experience_end_date: moment(_.last(selectedDate), 'MMM DD, YYYY').format("YYYY-MM-DD"),
        id: _.get(templateDetails, 'id', null),
        tax_state: tax_state ? parseInt(tax_state) : parseInt(tax_state),
        can_late_register: isLateRegisteration == 1 ? true : false,
        late_registration_time_limit: isLateRegisteration == 1 ? lateRegisterationDate : null,
        cancellation_time_limit: buildCancellationTimeLimit(startTime1, _.head(selectedDate)),
        meta_details: {
          timezone: !_.isEmpty(selectedValues) ? _.isArray(selectedValues) ? _.head(selectedValues) : selectedValues : _.isArray(_.get(props, 'userProfile.meta_details.timezone')) ? _.get(props, 'userProfile.meta_details.timezone[0]') : _.get(props, 'userProfile.meta_details.timezone'),
          currency: currency,
          team_size: teamSize,
        },
        can_prorate: isProrate == 1 ? true : false,
        tags: sessionTags,
        template: templateDetails,
        start_time: startTime1,
        end_time: endTime1,
        quickSchedule: scheduleType,
        address_results: address_results,
        save_start_time: startTime,
        save_end_time: endTime,
        save_late_registration_date: lateRegisterationDate,
        registration_info_set_id:questionSetId


        // country:country=="USD"?'us':'ca'
      };
      if (_.size(selectedDate) > 0) {
        details.schedule_dates = _.map(selectedDate, date => moment(date, 'MMM DD, YYYY').format("YYYY-MM-DD"))
        details.save_schedule_dates = selectedDate
      }

      // setcreateLoading(true)
      if (_.size(experienceStoredData) > 0) {
        let aDetails = [...experienceStoredData]
        let mapDetails = _.filter(aDetails, (v, i) => v?.uniq_id != commonEditDeleteId)



        commonTableData([details, ...mapDetails])
      } else {
        commonTableData([details, ...experienceStoredData])
      }
      // setAllTemplateListData([...AllTemplateListData,details])
      setIsModalOpen(false)
      quickResetScheduleField('all')


    }

  };

  const callBulkSchedule = () => {

    if (_.size(experiencesList) > 0) {

      _.map(experiencesList, (v, i) => {

        var details = v?.template_type == "open" ? [
          "price",
          "mode",
          "zip_code",
          "min_no_of_openings",
          "max_no_of_openings",
          "location",
          "address",
          "id",
          "type",
          "tax_state",
          "tags",
          "meta_details",
        ] : [
          "price",
          "mode",
          "zip_code",
          "min_no_of_openings",
          "max_no_of_openings",
          "location",
          "address",
          "id",
          "type",
          "experience_start_time",
          "experience_end_time",
          "experience_date",
          "experience_end_date",
          "tax_state",
          "can_late_register",
          "cancellation_time_limit",
          "late_registration_time_limit",
          "meta_details",
          "schedule_dates",
          "tags"
        ]
        let a = _.pick(_.get(v, 'saved_details'), details)

        if(v?.template_type=='open'){
          a.type="open"
        }else if(v?.template_type=="league"){
          a.type="league"
        }
        if (a?.late_registration_time_limit) {
          a.late_registration_time_limit = dayjs(a?.late_registration_time_limit).format('YYYY-MM-DD')
        }

        a.can_prorate = v?.can_prorate
        if (_.isArray(_.get(a, 'schedule_dates')) == true && _.size(_.get(a, 'schedule_dates')) > 0) {
          let b = [...a.schedule_dates]
          a.schedule_dates = b.slice(1)


        }
        if(questionSetId){
          a.meta_details["registration_info_set_id"]=questionSetId
        }

        if(couponId.length>0){
          a.coupon_ids= couponId
        }

        setPageLoading(true)
        setcreateLoading(true)
        props.create({
          body: a,
          callback: (res, data) => {

            if (res == true) {
             if(v?.registration_info_set_id && _.get(data,"data.details.id")){
              // props.questionSetsAssign({
              //   body: {experience_ids:[_.get(data,"data.details.id")]},
              //   params:{id:v?.registration_info_set_id},
              //   callback: (res, data) => {
                  
              //   }})
             }
              if (_.size(experiencesList) == i + 1) {
                setexperienceStoredData([])
                setPageLoading(false)
                setExperiencesList([])
                setcouponId([])
                setcreateLoading(false)
                messageApi.open({
                  type: 'success',
                  content: "Your sessions have been successfully scheduled.",
                });
                localStorage.removeItem('ExperiencesList')
                localStorage.removeItem('experienceStoredData')
              }
            }else{
              setPageLoading(false)
              setcreateLoading(false)
            }

          }
        })

      })

    }
  }


  const editFlow = (details) => {

    if (_.get(details, 'tempalate_id')) {
      setIsModalOpen(true)
      setform(1)
      settemplateDetails(_.get(details, 'template'))

      setScheduleSelectId(_.get(details, 'tempalate_id'))
      // quickSchedule
      setscheduleType(_.get(details, 'quickSchedule'))

      if (_.get(details, 'template_type') == "league") {
        setteamSize(`${_.get(details, 'meta_details.team_size')}`)

      }
      if (_.get(details, 'meta_details.timezone')) {
        setSelectedValues(_.get(details, 'meta_details.timezone'))
      } else {
        setSelectedValues(_.get(props, 'userProfile.meta_details.timezone[0]'))
      }
      if (_.get(details, 'meta_details.currency')) {
        setcountry(_.get(details, 'meta_details.currency'))
        setcurrency(_.get(details, 'meta_details.currency'))
      }
      if (_.size(_.get(details, 'tags')) > 0) {
        setsessionTags(_.get(details, 'tags'))
      }
      setisLateRegisteration(_.get(details, 'can_late_register') == true ? 1 : 2)
      setisProrate(_.get(details, 'can_prorate') == true ? 1 : 2)
      // setstartTime

      if (_.size(_.get(details, 'save_schedule_dates')) > 0) {
        setSelectedDate(_.get(details, 'save_schedule_dates'))
      }

      if (_.get(details, 'save_start_time')) {
        let date1 = dayjs(_.get(details, 'save_start_time')).format('YYYY-MM-DD')
        let time1 = dayjs(_.get(details, 'save_start_time')).format('HH:mm:ss')
        let combineTime1 = `${date1}T${time1}`
        setstartTime1(combineTime1)
        let date2 = dayjs(_.get(details, 'save_end_time')).format('YYYY-MM-DD')
        let time2 = dayjs(_.get(details, 'save_end_time')).format('HH:mm:ss')
        let combineTime2 = `${date2}T${time2}`
        setendTime1(combineTime2)
        setstartTime(_.get(details, 'save_start_time'))
        setendTime(_.get(details, 'save_end_time'))
      }

      if (_.get(details, 'late_registration_time_limit')) {
        setlateRegisterationDate(_.get(details, 'late_registration_time_limit'))
      }

      if (_.size(_.get(details, 'address_results')) > 0) {
        setaddress_results(_.get(details, 'address_results'))
        setAddress(_.get(details, 'address'))

      } else if (_.get(details, 'template_type') == "open") {
        if (_.get(details, 'template.address') && _.get(details, 'template.location')) {

          const address_results = [{
            text: _.get(details, 'template.address'),
            value: { text: _.get(details, 'template.address'), lat: _.get(details, 'template.location.x'), lng: _.get(details, 'template.location.y'), postal_code: _.get(details, 'template.zip_code') },
          }]
          setAddress(_.get(details, 'template.address'))

          setaddress_results(address_results)
        }
      }

      if (_.get(details, 'tax_state')) {
        settax_state(_.get(details, 'tax_state'))
        setTaxesValue(_.get(details, 'tax_state'))

      }

      setsessionPrice(_.get(details, 'price'))
      setnoOfOpenings(_.get(details, 'max_no_of_openings'))




    }


  }


  // const disabledTime = (current) => {
  //   const now = moment()
  //   // 
  //   if (current && current.isSame(startDate, "day")) {
  //     let a = moment().format('a')
  //     let b = current.format('a')
  //     return {
  //       disabledHours: () => disabledHours(),
  //       disabledMinutes: (selectedHour) => disabledMinutes(selectedHour),
  //     }
  //   } else {
  //     return {
  //       disabledHours: () => [],
  //       disabledMinutes: () => [],
  //     }
  //   }
  // }
  // const disabledHours = () => {
  //   var hours = [];
  //   for (var i = 0; i < moment().hour(); i++) {
  //     hours.push(i);
  //   }
  //   return hours;

  // };
  // const disabledMinutes = (selectedHour) => {

  //   var minutes = [];
  //   if (selectedHour === moment().hour()) {
  //     for (var i = 0; i < moment().minute(); i++) {
  //       minutes.push(i);
  //     }
  //   }
  //   return minutes;
  // };
  const handleChange1 = (value) => {
    setAddress(value);
  };
  const fetchPostalCode = address_components => {
    let postal_code = null;
    address_components.forEach(value => {
      value.types.forEach(type => {
        if (type === 'postal_code') {
          postal_code = value.long_name
        }
      })
    })
    return postal_code;
  };

  const handleSelect = async (value) => {
    setaddress_results([])
    geocodeByAddress(value).then(body => {
      const address_results = _.map(body, location => ({
        text: location.formatted_address,
        value: { text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) },
      }));
      setaddress_results(address_results)
      setAddress(value)

      if (_.size(address_results) > 0) {
        let a = address_results[0].value
        setzip_code(_.get(a, 'postal_code'))

      }
      const extractStateName = results => {
        for (let i = 0; i < results[0].address_components.length; i++) {
          const component = results[0].address_components[i];
          if (component.types.includes('administrative_area_level_1')) {
            return component.long_name; // Return the state name
          }
        }
        return '';
      };
      let state = extractStateName(body)
      let ada = _.filter(TaxListData, v => _.lowerCase(v.state) == _.lowerCase(state) && v)

      if (_.size(ada) > 0) {
        settax_state(_.startCase(_.get(ada[0], 'id')))
        settax_statename(_.get(ada[0], 'id'))

      }

      setaddress_results(address_results)
    }).catch(error => {
      setaddress_results([])
      // setaddress_fetching(true);
    });
  };
  const selectedTemplate = (e) => {
    setScheduleSelectId(e)
    let filter_data = _.filter(AllTemplateListData, (v, i) => {
      return v?.id == e
    })
   
    settemplateDetails(_.head(filter_data))

    if(_.get(_.head(filter_data),"meta_details.registration_info_set_id")){
      setquestionSetId(_.get(_.head(filter_data),"meta_details.registration_info_set_id"))
    }
    if(_.size(_.get(_.head(filter_data),'coupon_ids'))>0){
      setcouponId(_.get(_.head(filter_data),'coupon_ids'))
    }else{
      setcouponId([])
    }
    if(_.get(_.head(filter_data),"")){
      setquestionSetId(_.get(_.head(filter_data),"meta_details.registration_info_set_id"))
    }
  }


  const quickSchedule = () => {
    setcountry(_.get(templateDetails, 'meta_details.currency') == 'USD' ? 'USD' : 'CAD')


    setSelectedValues(_.get(props, 'userProfile.meta_details.timezone[0]'))
    setTaxesValue(_.get(templateDetails, 'tax_state'))
    settax_state(_.get(templateDetails, 'tax_state'))
    setsessionPrice(_.get(templateDetails, 'host_price'))
    setcurrency(_.get(templateDetails, 'meta_details.currency'))
    setnoOfOpenings(_.get(templateDetails, 'max_no_of_openings'))
    setteamSize(_.get(templateDetails, 'meta_details.team_size'))
    if (_.get(templateDetails, 'address') && _.get(templateDetails, 'location')) {

      const address_results = [{
        text: _.get(templateDetails, 'address'),
        value: { text: _.get(templateDetails, 'address'), lat: _.get(templateDetails, 'location.x'), lng: _.get(templateDetails, 'location.y'), postal_code: _.get(templateDetails, 'zip_code') },
      }]
      setAddress(_.get(templateDetails, 'address'))

      setaddress_results(address_results)
    }
  }

  const quickResetScheduleField = (type) => {
    setcountry('')

    setnoOfOpenings('')
    setSelectedValues('')
    setTaxesValue('')
    settax_state('')
    setsessionPrice('')
    setcurrency('')
    setteamSize('')
    setAddress('')

    setaddress_results([])

    if (type == 'all') {
      setScheduleSelectId('')
      setstartTime(null)
      setendTime(null)
      setstartTime1(null)
      setendTime1(null)
      setform(1)
      setSelectedDate([])
      settemplateDetails({})
      setscheduleType('')
      setsessionTags([])
      setlateRegisterationDate(null)
      setisLateRegisteration(2)


    }

  }
  const getCouponList = () => {
    props.couponList({
      params: { limit: 1000,host:_.get(props,"userProfile.id") },
      callback: (res, data) => {
        if (res == true && _.get(data, 'data.items[0]'))
          setCouponList(_.get(data, 'data.items'))
      }
    })
  }
  const commonModalrender = () => {


    let addDisableDate = moment(new Date()).format("YYYY-MM-DD");
    const searchOptions = {
      componentRestrictions: { country: country == "USD" ? ['us'] : ['ca'] }, // Restrict search to United States and Canada
    };
    const selectProvince = (value) => {

      setTaxesValue(value)
      settax_state(value)
    }
    const getFilterTax = (list, currency) => {
      let country = currency == 'CAD' ? 'CA' : 'US'

      let a = [];
      _.map(list, (v, i) => {
        if (_.get(v, 'country') == country) {
          a.push({ label: _.startCase(_.get(v, 'state')), value: _.get(v, 'id') })

        }
      })

      return a
    }
    let condition_form = _.get(templateDetails, 'type') == 'open' ? true : false

    const filterTemplateType = (data, type) => {

      return _.filter(data, (v, i) => v?.type == type && v)
      // return data
    }

    if (form == 1) {
      return <>
        <span className="row title-modal-manage" >{bulkSchedule == 1 ? "Schedule a new session" : "Schedule All"}</span>
        <div style={{ display: 'flex', }}>
          <div style={{
            width: '28%',
            marginRight: '33px'
          }}>
            <span className="row sub-title-modal-manage">Select a template</span>
            <span className="row">Choose the template you wish to schedule</span>
          </div>
          <div style={{ width: '85%' }}>
            {bulkSchedule == 1 ?
              <div >
                <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Template</span>
                <Select
                  value={scheduleSelectId|| undefined}
                  placeholder="Choose a template"
                  className='schedule-template-select-manage'
                  optionFilterProp="children" onSelect={(e) => selectedTemplate(e)}>
                  {_.map(AllTemplateListData, (v, i) => {
                    return <Option key={i} value={_.get(v, 'id')}>{_.get(v, 'title')}</Option>
                  })}
                </Select>
              </div>
              : <div>
                <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Select Template Type</span>
                <Radio.Group size="large" onChange={(e) => {
                  setbulkScheduleType(e.target.value)
                }} defaultValue="scheduled" buttonStyle="solid">
                  <Radio.Button value="scheduled">Scheduled</Radio.Button>
                  <Radio.Button value="open">Open</Radio.Button>
                  <Radio.Button value="league">League</Radio.Button>
                </Radio.Group>
                <Select
                  mode="multiple"

                  // value={openTempId}
                  placeholder="Choose a template"
                  className='schedule-template-select-manage'
                  style={{ marginBottom: '12px', marginTop: '12px' }}
                  optionFilterProp="children" onSelect={(e) => {
                    selectedTemplate(e);
                    let a = openTempId.length > 0 ? [...openTempId, e] : [e]
                    setopenTempId(_.uniq(a))

                  }}>
                  {_.map(filterTemplateType(AllTemplateListData, bulkScheduleType), (v, i) => {
                    return <Option key={i} value={_.get(v, 'id')}>{_.get(v, 'title')}</Option>
                  })}
                </Select>

              </div>}
              
              {_.size(questionSetList)>0 &&<div  style={{marginTop:'16px'}}>
                <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Question set</span>
                <Select
                mode="tags"
                maxTagCount={1}
                onDeselect={()=>{
                  setquestionSetId("")
                }}
                  value={questionSetId|| undefined}
                  placeholder="Choose a question set (optional)"
                  className='schedule-template-select-manage2'
                  optionFilterProp="children" onSelect={(e) => setquestionSetId(e)}>
                  {_.map(questionSetList, (v, i) => {
                    return <Option key={i} value={_.get(v, 'id')}>{_.get(v, 'label')}</Option>
                  })}
                </Select>
              </div>}
              {_.size(CouponList)>0 &&<div  style={{marginTop:'16px'}}>
                <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Promotion</span>
                <Select
                mode="tags"
               
                  value={couponId|| undefined}
                  placeholder="Choose a promotion (optional)"
                  className='schedule-template-select-manage2'
                  optionFilterProp="children" onChange={(e) => setcouponId(e)}>
                  {_.map(CouponList, (v, i) => {
                    return <Option key={i} value={_.get(v, 'id')}>{_.get(v, 'label')}</Option>
                  })}
                </Select>
              </div>}
            {bulkSchedule == 1 && <div style={{ display: 'flex', marginBottom: '16px' }}>
              <div style={{ marginTop: '16px' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Quick Schedule or Custom Schedule</span>
                <div style={{ display: 'flex' }}>
                  <div onClick={() => {
                    setscheduleType(1)
                    if (_.get(templateDetails, 'id')) {
                      quickSchedule()
                    }
                  }} className={scheduleType == 1 ? "sechdule-select-active" : "sechdule-select"} style={{ width: '48%', marginRight: '16px' }}>
                    <div className="select-sechdule-title" style={{ color: scheduleType == 1 ? "#EF6820" : "#475467" }}>
                      Quick Schedule
                    </div>
                    <span className="select-sechdule-title-des" style={{ color: scheduleType == 1 ? "#EF6820" : "#475467" }}>
                      You only need to select session dates and time. All other settings (price, location, spaces) are the template default.
                    </span>

                  </div>
                  <div onClick={() => {
                    setscheduleType(2)
                    quickResetScheduleField()
                  }} className={scheduleType == 2 ? "sechdule-select-active" : "sechdule-select"} style={{ width: '48%' }}>
                    <div className="select-sechdule-title" style={{ color: scheduleType == 2 ? "#EF6820" : "#475467" }}>
                      Custom Schedule
                    </div>
                    <span className="select-sechdule-title-des" style={{ color: scheduleType == 2 ? "#EF6820" : "#475467" }}>
                      Completely customize all settings, ignoring template defaults. Only the description elements are used.
                    </span>

                  </div>
                </div>

              </div>

            </div>}
            {_.get(templateDetails, 'type') == "league" && <div style={{}}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Team Size</span>
              <div style={{ display: 'flex' }}>
                <Input
                  readOnly={condition_form} disabled={condition_form}
                  value={`${teamSize}`} onChange={(e) => setteamSize(e.target.value)} style={{
                    borderRadius: '8px', height: '44px', width: '100%',
                    background: condition_form ? "rgba(0, 0, 0, 0.04)" : "#ffffff",
                    color: condition_form ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0.88)"
                  }} placeholder="Enter a team size" />
              </div>




            </div>}
          </div>

        </div>
      </>
    } else if (form == 2) {
      return <>
        <span className="row title-modal-manage" >Schedule <div className="template-modal-title">{_.get(templateDetails, 'title')}</div></span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            width: '30%',
            marginRight: '33px'
          }}>
            <span className="row sub-title-modal-manage">Select session dates</span>
            <span className="row">Select the dates the session takes place. Start and end date are automatically computed based on your selection</span>
          </div>
          <div style={{ width: '64%' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Session Dates</span>
            <Button

              style={{
                width: '99%',
                height: '44px'
              }}
              className='schedule-dates-date-picker'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span onClick={() => {
                  setiscalenderOpen(true)
                  calendarRef.current?.openCalendar()
                }}
                  style={{
                    width: '92%',
                    textAlign: 'left'
                  }}
                >Schedule Dates</span>
                <DatePicker
                  value={renderDatePickerSelectedDate(selectedDate)}
                  minDate={addDisableDate} ref={calendarRef} plugins={[
                    <DatePanel />
                  ]} sort={true} style={{ width: '88%', marginLeft: '96%' }}
                  name={"Session Dates"} onClose={(value) => { setiscalenderOpen(false) }} onChange={(date) => {
                    convertDate(date)
                  }}

                  className={"rmdp-mobile"} render={<Icon />} numberOfMonths={2} multiple={true} />
              </div>
            </Button>

            <div>
              {iscalenderOpen == false && _.size(selectedDate) > 0 && <Card
                size="small"
                title={<div className="card-title-selected-date">Session schedule</div>}
                className="seleted-date-card"
                style={{
                  width: '100%',
                  marginTop: '20px'
                }}
              >
                {_.map(selectedDate, (v, i) => {
                  return <><div key={i} style={{
                    display: 'flex', justifyContent: 'space-between',
                    paddingLeft: '24px', paddingTop: '16px', paddingBottom: '16px',
                    paddingRight: '24px'
                  }}>
                    <span>
                      {moment(v, 'MMM DD, YYYY').format('dddd MMMM DD, YYYY')}
                    </span>
                    <span>
                      {i == 0 && <Tag style={{ borderRadius: '16px' }}>Start Date</Tag>}
                      {_.size(selectedDate) > 1 && _.size(selectedDate) == i + 1 && <Tag style={{ borderRadius: '16px' }}>End Date</Tag>}
                      <CloseOutlined onClick={() => {
                        removeSelectedDate(v, i)
                      }} />
                    </span>

                  </div>
                    <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />
                  </>
                })}

              </Card>}
            </div>
          </div>

        </div>

        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            width: '30%',
            marginRight: '33px'
          }}>
            <span className="row sub-title-modal-manage">Late registration</span>
            <span className="row">Enable or disable users from registering after a session has started</span>
          </div>
          <div style={{ width: '64%' }}>

            <div style={{ display: 'flex' }}>
              <div style={{ marginTop: '16px' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Late registration</span>
                <div style={{ display: 'flex' }}>
                  <div onClick={() => {
                    setisLateRegisteration(1)
                  }} className={isLateRegisteration == 1 ? "sechdule-select-active" : "sechdule-select"} style={{ width: '48%', marginRight: '16px' }}>
                    <div className="select-sechdule-title" style={{ color: isLateRegisteration == 1 ? "#EF6820" : "#475467" }}>
                      Allow Late Registration
                    </div>
                    <span className="select-sechdule-title-des" style={{ color: isLateRegisteration == 1 ? "#EF6820" : "#475467" }}>
                      Allow users to register after a session has started.
                    </span>

                  </div>
                  <div onClick={() => {
                    setisLateRegisteration(2)
                  }} className={isLateRegisteration == 2 ? "sechdule-select-active" : "sechdule-select"} style={{ width: '48%' }}>
                    <div className="select-sechdule-title" style={{ color: isLateRegisteration == 2 ? "#EF6820" : "#475467" }}>
                      Do Not Allow Late Registration
                    </div>
                    <span className="select-sechdule-title-des" style={{ color: isLateRegisteration == 2 ? "#EF6820" : "#475467" }}>
                      Do not allow users to register after a session has started.
                    </span>

                  </div>
                </div>

              </div>


            </div>

            <div >
              <div style={{ marginTop: '16px' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Last Registration Date</span>
                <div style={{ display: 'flex' }}>
                  <AntDatePicker

                    disabledDate={(current) => {
                      if (_.size(selectedDate) > 1) {
                        let start = moment(_.head(selectedDate), 'MMM DD, YYYY').format('YYYY-MM-DD')
                        let end = moment(_.last(selectedDate), 'MMM DD, YYYY').format('YYYY-MM-DD')
                        return current && current.valueOf() < moment(start) || _.size(selectedDate) > 0 && current && current.valueOf() > moment(end)
                      } return current && current.valueOf() < moment().add(-1, 'days');
                    }}
                    disabled={isLateRegisteration == 2} value={lateRegisterationDate} onChange={setlateRegisterationDate} format={"YYYY-MM-DD"} placeholder="Select last registration date" style={{ height: '44px', width: '100%' }} />


                </div>

              </div>



            </div>
          </div>

        </div>

        <Divider />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            width: '30%',
            marginRight: '33px'
          }}>
            <span className="row sub-title-modal-manage">Start time, end time</span>
            <span className="row">Choose the start time, and end time for the session</span>
          </div>
          <div style={{ width: '64%' }}>


            <div style={{ marginTop: '16px' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Start time</span>

              <div style={{ display: 'flex' }}>

                <TimePicker
                // changeOnScroll needConfirm={false}
                  format={"hh:mm a"}
                  value={startTime}
                  // onChange={setstartTime}
                  onChange={(e, dateString) => {
                    let date = dayjs(e).format('YYYY-MM-DD')
                    let time = dayjs(e).format('HH:mm:ss')
                    let combineTime = `${date}T${time}`
                    setstartTime1(combineTime)
                    setstartTime(e)
                  }}
                  placeholder="00:00 AM" style={{ height: '44px', width: '100%' }}  changeOnScroll needConfirm={false} />


              </div>
            </div>




            <div >
              <div style={{ marginTop: '16px' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>End Time</span>

                <div style={{ display: 'flex' }}>

                  <TimePicker
                 
                    value={endTime}
                    onChange={(e, dateString) => {


                      let date = dayjs(e).format('YYYY-MM-DD')
                      let time = dayjs(e).format('HH:mm:ss')
                      let combineTime = `${date}T${time}`
                      setendTime1(combineTime)
                      setendTime(e)
                    }}
                    format={"hh:mm a"} placeholder="00:00 PM" style={{ height: '44px', width: '100%' }}  changeOnScroll needConfirm={false} />




                </div>

              </div>



            </div>

          </div>

        </div>
      </>
    } else if (form == 3) {
      return <>
        <span className="row title-modal-manage" >Schedule <div className="template-modal-title">{_.get(templateDetails, 'title')}</div></span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            width: '30%',
            marginRight: '33px'
          }}>
            <span className="row sub-title-modal-manage">Country, timezone, address and province</span>
            <span className="row">Select your country, timezone, enter the address of where the session takes place and province</span>
          </div>
          <div style={{ width: '64%' }}>


            <div > <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Country</span>

              <div style={{ display: 'flex' }}>

                <Select
                  disabled={condition_form}
                  value={country}
                  style={{ width: '100%', height: '44px' }}

                  onSelect={(value) => {

                    setTaxesValue('');
                    settax_state('');
                    setAddress('');
                    setcountry(value);
                    setcurrency(value)



                  }}
                  optionFilterProp="children">
                  {_.map(countryList, (v, i) => {
                    return <Option key={i} value={_.get(v, 'value')}>{_.startCase(_.get(v, 'name'))}</Option>
                  })}
                </Select>


              </div>
            </div>




            <div >
              <div style={{ marginTop: '16px' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Timezone</span>

                <div style={{ display: 'flex' }}>

                  <Select
                    disabled={condition_form}

                    style={{
                      width: '100%',
                      height: '44px'
                    }}
                    value={selectedValues}
                    placeholder="Select a timezone"
                    defaultValue={selectedValues}
                    onChange={setSelectedValues}
                  // optionLabelProp="label"
                  >
                    {(moment.tz.zonesForCountry(country == "USD" ? "US" : "CA")).map((item, index) => {

                      return (
                        <Option key={index} value={item}>{item}</Option>

                      )
                    })}
                  </Select>




                </div>

              </div>



            </div>
            <div >
              <div style={{ marginTop: '16px' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Address</span>

                <div style={{ display: 'flex' }}>

                  <PlacesAutocomplete


                    value={address}
                    onChange={handleChange1}
                    onSelect={handleSelect}
                    searchOptions={searchOptions}

                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div style={{ width: '100%' }}>
                        <Input readOnly={condition_form} disabled={condition_form} style={{
                          borderRadius: '8px', height: '44px', width: '100%', background: condition_form ? "rgba(0, 0, 0, 0.04)" : "#ffffff",
                          color: condition_form ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0.88)"
                        }} {...getInputProps({ placeholder: 'Search address' })} />
                        {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
                        <div>
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
                              marginTop: 10,
                            };

                            return (
                              <div {...getSuggestionItemProps(suggestion, { style })}>
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>





                </div>

              </div>



            </div>

            <div >
              <div style={{ marginTop: '16px' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Province / State</span>

                <div style={{ display: 'flex' }}>

                  <Select
                    disabled={condition_form}
                    style={{ width: '100%', height: '44px' }}
                    defaultValue={tax_state}
                    value={tax_state} onChange={(e) => selectProvince(e)} getPopupContainer={trigger => trigger.parentNode}
                    placeholder={'Search Province / State...'}

                    options={getFilterTax(TaxListData, currency)}
                  />




                </div>

              </div>



            </div>

          </div>

        </div>



        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            width: '30%',
            marginRight: '33px'
          }}>
            <span className="row sub-title-modal-manage">Price and prorate settings</span>
            <span className="row">Enter the price of your session and choose whether you will prorate late registrations</span>
          </div>
          <div style={{ width: '64%' }}>


            <div style={{}}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Price</span>
              <div style={{ display: 'flex' }}>
                <Input
                  readOnly={condition_form} disabled={condition_form}
                  value={sessionPrice} onChange={(e) => setsessionPrice(e.target.value)} style={{
                    borderRadius: '8px', height: '44px', width: '100%',
                    background: condition_form ? "rgba(0, 0, 0, 0.04)" : "#ffffff",
                    color: condition_form ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0.88)"
                  }} placeholder="CAD $0.00" />
              </div>




            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ marginTop: '16px' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Prorate Settings</span>
                <div style={{ display: 'flex' }}>
                  <div onClick={() => {
                    setisProrate(1)
                  }} className={isProrate == 1 ? "sechdule-select-active" : "sechdule-select"} style={{ width: '48%', marginRight: '16px' }}>
                    <div className="select-sechdule-title" style={{ color: isProrate == 1 ? "#EF6820" : "#475467" }}>
                      Prorate
                    </div>
                    <span className="select-sechdule-title-des" style={{ color: isProrate == 1 ? "#EF6820" : "#475467" }}>
                      Price will automatically reduce if users register after the start date.
                    </span>

                  </div>
                  <div onClick={() => {
                    setisProrate(2)
                  }} className={isProrate == 2 ? "sechdule-select-active" : "sechdule-select"} style={{ width: '48%' }}>
                    <div className="select-sechdule-title" style={{ color: isProrate == 2 ? "#EF6820" : "#475467" }}>
                      Do Not Prorate
                    </div>
                    <span className="select-sechdule-title-des" style={{ color: isProrate == 2 ? "#EF6820" : "#475467" }}>
                      Price will not automatically reduce if users register after the start date.
                    </span>

                  </div>
                </div>

              </div>


            </div>

          </div>

        </div>

        <Divider />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            width: '30%',
            marginRight: '33px'
          }}>
            <span className="row sub-title-modal-manage">Session spaces</span>
            <span className="row">Enter the amount of spaces available for this session</span>
          </div>
          <div style={{ width: '64%' }}>


            <div > <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Spaces</span>

              <div style={{ display: 'flex' }}>

                <Input value={`${noOfOpenings}`} onChange={(e) => setnoOfOpenings(e.target.value)} style={{ borderRadius: '8px', height: '44px', width: '100%' }} placeholder="Enter the number of spaces available for this session" />


              </div>
            </div>






          </div>

        </div>
      </>
    } else {
      return <>
        <span className="row title-modal-manage" >Schedule <div className="template-modal-title">{_.get(templateDetails, 'title')}</div></span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            width: '41%',
            marginRight: '33px'
          }}>
            <span className="row sub-title-modal-manage">Tags</span>
            <span className="row">Enter tags to simplify filtering and finding this session</span>
          </div>
          <div style={{ width: '64%' }}> <span className="row modal-field-title" style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '6px' }}>Tags</span>
            <Select
              mode="tags"
              style={{
                width: '100%',
                height: '44px'
              }}
              value={sessionTags}
              placeholder={<Tag style={{ borderRadius: '18px' }}>+ Add Tag</Tag>}
              onChange={(value) => {
                setsessionTags(value)

              }}
              options={_.size(sessionTags) > 0 ?
                _.map(sessionTags, (v) => {
                  return { label: v, value: v }
                })
                : []}
            />


          </div>

        </div>
      </>
    }
  }


  const validateCondition = (form) => {

    if (form == 1 && _.get(templateDetails, 'id')) {
      if (_.get(templateDetails, 'type') == "league" && teamSize == '') {
        return true
      } else {
        return false
      }

    } else if (form == 2 && _.get(templateDetails, 'id') && _.size(selectedDate) >= 1 && startTime != null && endTime != null) {
      if (isLateRegisteration == 2) {
        return false
      } else if (isLateRegisteration == 1 && lateRegisterationDate == null) {
        return true

      } else {
        return false
      }


      return false
    } else if (form == 3 && _.get(templateDetails, 'id')) {
      if (country && timeZone && address && tax_state && sessionPrice && noOfOpenings) {
        return false
      } else {
        return true
      }

    } else if (form == 4 && _.get(templateDetails, 'id')) {
      return false
    } else {
      return true
    }

    return true
  }
  return <div style={{ width: '100%' }}>
    {contextHolder}
    <Card
      size="small"
      title={
        <div className='header-class-card' style={{ display: 'flex' }}>

          <Button styles={{ icon: { marginRight: '-14px' } }}
            disabled={_.size(experiencesList) > 0 ? false : true}
            onClick={() => {
              callBulkSchedule()
              // setbulkSchedule(2);
              // setIsModalOpen(true)
            }}
            icon={<Image preview={false} src={_.size(experiencesList) > 0 ? require('../../assets/img/upload.png') : require('../../assets/img/upload-1.png')}
              style={{ height: '24px', width: '24px' }}
            />} style={{
              //    paddingTop: '6px', paddingBottom: '7px',
              height: '44px',
              backgroundColor: _.size(experiencesList) > 0 ? '#EF6820' : "#F2F4F7", color: _.size(experiencesList) > 0 ? '#fff' : "#98A2B3", fontWeight: 600,
            }}>Schedule All</Button>



        </div>
      }
      //       extra={<a href="#">More</a>}
      style={{
        width: '100%',
        padding: '0px'
      }}
      className='manage-active-table'
    >
      <div className="coach-full-conatiner coach-full-conatiner-active">

        <Scrollbars style={{ height: 590 }} className="hidden-scroll-x"
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
        >
          <div style={{ backgroundColor: '#fff' }} className='manage-active-table'>
            <Table className="bulk-sechdule-table" rowClassName={(id, key) => {
              return key == 0 && 'first-row-class'
            }} onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  if (rowIndex == 0 && _.get(record, 'id') == 1) {
                    setbulkSchedule(1)
                    setIsModalOpen(true)
                  }

                }, // click row
              };
            }} columns={columns} dataSource={[{ id: 1, title: 'Test' }, ...experiencesList]} loading={pageLoading} pagination={false} />

          </div>
        </Scrollbars>









      </div></Card>
    <Modal
centered={true}
      title={<div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '15px', paddingRight: '15px' }}>
        <Image preview={false} style={{ width: '88px', height: '35px' }} src={
          form == 1 ?
            require('../../assets/img/dot1.png') :
            form == 2 ? require('../../assets/img/dot2.png') :
              form == 3 ? require('../../assets/img/dot3.png') :
                form == 4 ? require('../../assets/img/dot4.png') :
                  require('../../assets/img/dot1.png')
        } />
        <CloseOutlined onClick={() => {
          quickResetScheduleField('all')
          setIsModalOpen(false)
          setform(1)
        }} size={24} />
      </div>}
      footer={null}
      // centered
      closeIcon={null}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      width={1000}
    >
      <div style={{ padding: '15px', marginTop: '-16px', paddingTop: '0px', paddingLeft: '30px' }}>
        {commonModalrender(form)}
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {form != 1 && <Button
              onClick={() => {
                if (form != 1) {
                  if (_.get(templateDetails, 'type') == 'open') {
                    if (form == 3) {
                      setform(form - 2)
                    } else {
                      setform(form - 1)
                    }
                  } else {
                    setform(form - 1)
                  }
                }

              }}
              style={{ height: '36px', borderRadius: '8px' }}><FaArrowLeft /><span className="sub-title-modal-manage">Back</span></Button>}
          </div>
          <div>
            <Button
              loading={createLoading}
              disabled={validateCondition(form)}
              style={{ height: '36px', borderRadius: '8px' }} onClick={() => {

                if (form != 4) {
                  if (_.get(templateDetails, 'type') == 'open') {
                    if (form == 1) {
                      setform(form + 2)
                    } else {
                      setform(form + 1)
                    }
                  } else {
                    setform(form + 1)
                  }


                }
                if (form == 4) {
                  onSubmitSchedule()
                }
              }}><span className="sub-title-modal-manage"> {form == 4 ? "Save and add to queue" : "Next"}</span><FaArrowRight />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal maskClosable
    centered={true}
      onCancel={() => setshowDeleteConfirm(false)}
      style={{ padding: '68px 18px', width: '936px' }} footer={null} open={showDeleteConfirm}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
        <span style={{ fontSize: '18px', fontWeight: '600' }}>Are you sure you wish to remove this session from the schedule queue?</span>
        <p style={{ color: '#475467', fontSize: '14px', marginTop: '6px' }}>All the data you’ve inputted for this session will be removed and you will need to enter it again.</p>

      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '22px'
      }}>
        <Button onClick={() => setshowDeleteConfirm(false)} style={{ backgroundColor: '#FFF', marginRight: 23, width: '23%', borderRadius: '8px', border: '1px solid #D0D5DD', height: '40px' }}
        >Cancel</Button>
        <Button onClick={() => {
          if (_.size(experienceStoredData) > 0) {
            let aDetails = [...experienceStoredData]
            let mapDetails = _.filter(aDetails, (v, i) => v?.uniq_id != commonEditDeleteId)



            commonTableData([...mapDetails])
            setshowDeleteConfirm(false)
          } else {
            // commonTableData([ details,...experienceStoredData])
          }

        }} style={{ backgroundColor: '#FFF', borderRadius: '8px', border: '1px solid #D0D5DD', height: '40px' }}
        >Remove Session</Button></div>
    </Modal>
  </div>
}

const mapStateToProps = state => {

  return {
    checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
    isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
    islogindata: _.get(state, 'app.account.isLogin'),
    userProfile: _.get(state, 'app.host.profile.data.details'),

  }
}

const mapDispatchToProps = dispatch => ({
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  templateList: details => dispatch(global.redux.action.template.list(details)),
  list: details => dispatch(global.redux.action.experience.list(details)),
  experienceViewAPI: details => dispatch(global.redux.action.experience.view(details)),
  updateExperienceTag: details => dispatch(global.redux.action.experience.updateExperienceTag(details)),
  experiencesRequestCancel: details => dispatch(global.redux.action.experience.requestCancel(details)),
  changeStatusOfExperience: details => dispatch(global.redux.action.experience.changeStatus(details)),
  orderList: details => dispatch(global.redux.action.experience.orderList(details)),
  sendEmailAPI: details => dispatch(global.redux.action.sendEmail.postEmails(details)),
  updateHostTag: details => dispatch(global.redux.action.host.updateHostTag(details)),
  hostProfile: details => dispatch(global.redux.action.host.profile(details)),
  taxList: details => dispatch(global.redux.action.tax.list(details)),
  create: details => dispatch(global.redux.action.experience.create(details)),

  questionSetsList: details => dispatch(global.redux.action.questions.questionSetsList(details)),
  questionSetsAssign: details => dispatch(global.redux.action.questions.questionSetsAssign(details)),
  couponList: details => dispatch(global.redux.action.promotion.couponList(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(BulkSchedule)