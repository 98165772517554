import React, { useState } from 'react';
import { Modal, Box, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input,Button } from 'antd';
import LoginSignupOTPFlow from './login-signup-otp-flow';
import SignupFlow from './signup-form';
import LoginSuccess from './login-success';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  modalBackground: {
    display: 'flex',
    alignItems: 'center',
    
    justifyContent: 'center',
    backgroundColor: 'rgba(52, 64, 84, 0.70)', // Background color when modal is open
    minHeight: '100vh',
  },
  modalContent: {
       padding:"24px",
    backgroundColor: '#fff',
    borderRadius: 8,
    outline: 'none',
//     width: '400px',
  },
  inputField: {
    marginBottom: 2,
  },
  button: {
    marginTop: 2,
  },
}));

const PhoneNumberModal = (props) => {
  const [dotPulseBtnLoader, setDotPulseBtnLoader] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOtpType, setisOtpType] = useState(1);
  const classes = useStyles();
  
  const [phoneNumberFlowScreen, setphoneNumberFlowScreen] = useState(1)
const [phoneNumberErrors, setphoneNumberErrors] = useState({error:false,message:"We’ll send you a verification code on this number"});
const [UserData, setUserData] = useState({});
const [dataFromChild, setDataFromChild] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setPhoneNumber("")
  };
  const customSuffixIcon =()=>{
       return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
       <g clip-path="url(#clip0_12730_906)">
         <path d="M7.99992 5.33301V7.99967M7.99992 10.6663H8.00659M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z" stroke="#F04438" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
       </g>
       <defs>
         <clipPath id="clip0_12730_906">
           <rect width="16" height="16" fill="white"/>
         </clipPath>
       </defs>
     </svg>
    }

    const ContinueMobileNumberValidation =(value)=>{
       
       if(!value){
              
              setphoneNumberErrors({
                     error:true,
                     message:"Please enter a valid phone number"
              })
       }else if(`${value}`.length==12) {
              setphoneNumberErrors({
                     error:false,
                     message:"We’ll send you a verification code on this number"
              }) 
              // setphoneNumberFlowScreen(2)
              handleClickSignUp(value)
       }else if(`${value}`.length<12) {
              
              setphoneNumberErrors({
                     error:true,
                     message:"Please enter a valid phone number"
              })
       }
      
    }

    const handleClickSignUp = (phoneInputValue) => {
      setDotPulseBtnLoader(true)
      props.checkProfileAPI({
        params:{
          user_type: 0,
          type: 'phone',
          data:formatPhoneNumber(phoneInputValue),
        },
        callback: (res, val) => {
          setDotPulseBtnLoader(false)
          
          if(_.get(val, 'error.statusCode') === 404){
            setphoneNumberFlowScreen(3)
            setisOtpType(2)
          } else {
            setUserData({..._.get(val, 'data.details'),type:"login"});
            setphoneNumberFlowScreen(5)
            setisOtpType(1)
            
          }
        }
      })
    };

    const formatPhoneNumber = (value) => {
      // Remove non-numeric characters
      let strippedNumber = value.replace(/\D/g, '');
      
      return strippedNumber
    };

    const handleDataFromChild = (data) => {
      setDataFromChild(data);
    };

  return (
    <>
      <Box component={"div"} onClick={()=>{
       handleOpen();
       setphoneNumberFlowScreen(1)
      }} sx={{display:'flex',alignItems:'center',
    borderRadius: "8px",
    justifyContent:'center',
    border:" 1px solid var(--Gray-300, #D0D5DD)",
    background: "var(--Base-White, #FFF)",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    padding:"10px 16px",
    cursor:'pointer'
    }}>
      <Typography component={"span"} sx={{marginRight:'12px'}}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.5 2V3.4C15.5 3.96005 15.5 4.24008 15.391 4.45399C15.2951 4.64215 15.1422 4.79513 14.954 4.89101C14.7401 5 14.4601 5 13.9 5H11.1C10.5399 5 10.2599 5 10.046 4.89101C9.85785 4.79513 9.70487 4.64215 9.60899 4.45399C9.5 4.24008 9.5 3.96005 9.5 3.4V2M8.7 22H16.3C17.4201 22 17.9802 22 18.408 21.782C18.7843 21.5903 19.0903 21.2843 19.282 20.908C19.5 20.4802 19.5 19.9201 19.5 18.8V5.2C19.5 4.07989 19.5 3.51984 19.282 3.09202C19.0903 2.71569 18.7843 2.40973 18.408 2.21799C17.9802 2 17.4201 2 16.3 2H8.7C7.5799 2 7.01984 2 6.59202 2.21799C6.21569 2.40973 5.90973 2.71569 5.71799 3.09202C5.5 3.51984 5.5 4.0799 5.5 5.2V18.8C5.5 19.9201 5.5 20.4802 5.71799 20.908C5.90973 21.2843 6.21569 21.5903 6.59202 21.782C7.01984 22 7.57989 22 8.7 22Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
      </Typography>
<Typography sx={{color:"#344054",fontSize:{xs: "14px", // mobile
            sm: "15px", // tablet
            md: "16px", // small desktop
            lg: "16px",},fontWeight:"600"}}>
Continue with Phone number
</Typography>

    </Box>
      <Modal  open={open}  onClose={(event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    handleClose();}} className={classes.modalBackground}>

       {phoneNumberFlowScreen==1? 
       <Box className={classes.modalContent} sx={{width:{
            xs: "330px", // mobile
            sm: "400px", // tablet
            md: "540px", // small desktop
            lg: "640px",  
        }}}>
        <Box sx={{display:'flex',alignItems:{
           xs: "flex-start", // mobile
           sm: "center", // tablet
           md: "center", // small desktop
           lg: "center",  
        },justifyContent:'space-between',marginBottom:'16px'}}>
       {isMobile==false ? <Box sx={{borderRadius: "10px",
       display:'flex',alignItems:'center',justifyContent:'center',
border: "1px solid var(--Gray-200, #EAECF0)",
background: "var(--Base-White, #FFF)",
boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",width:"48px",height:'48px'}}>
       <Typography >
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 2V3.4C15 3.96005 15 4.24008 14.891 4.45399C14.7951 4.64215 14.6422 4.79513 14.454 4.89101C14.2401 5 13.9601 5 13.4 5H10.6C10.0399 5 9.75992 5 9.54601 4.89101C9.35785 4.79513 9.20487 4.64215 9.10899 4.45399C9 4.24008 9 3.96005 9 3.4V2M8.2 22H15.8C16.9201 22 17.4802 22 17.908 21.782C18.2843 21.5903 18.5903 21.2843 18.782 20.908C19 20.4802 19 19.9201 19 18.8V5.2C19 4.07989 19 3.51984 18.782 3.09202C18.5903 2.71569 18.2843 2.40973 17.908 2.21799C17.4802 2 16.9201 2 15.8 2H8.2C7.0799 2 6.51984 2 6.09202 2.21799C5.71569 2.40973 5.40973 2.71569 5.21799 3.09202C5 3.51984 5 4.0799 5 5.2V18.8C5 19.9201 5 20.4802 5.21799 20.908C5.40973 21.2843 5.71569 21.5903 6.09202 21.782C6.51984 22 7.07989 22 8.2 22Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</Typography>
       </Box>:<Typography sx={{color:"#101828",fontSize:{  xs: "18px", // mobile
         sm: "18px", // tablet
         md: "18px", // small desktop
         lg: "18px",},fontWeight:"600",marginBottom:"16px"}}>
            Login or Sign up with a phone number
          </Typography>}
       <Typography component={"span"} sx={{cursor:'pointer'}} onClick={()=>{
          handleClose();
          setphoneNumberFlowScreen(1)
       }}>
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 6L6 18M6 6L18 18" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
       </Typography>
</Box>
          {isMobile==false &&<Typography sx={{color:"#101828",fontSize:{  xs: "18px", // mobile
         sm: "18px", // tablet
         md: "18px", // small desktop
         lg: "18px",},fontWeight:"600",marginBottom:"16px"}}>
            Login or Sign up with a phone number
          </Typography>}
          <Box>
          <Typography sx={{color:"#344054",fontSize:{  xs: "14px", // mobile
         sm: "14px", // tablet
         md: "14px", // small desktop
         lg: "14px",},fontWeight:"500",marginBottom:"6px"}}>
           Phone number
          </Typography>
          <Input
              suffix={phoneNumberErrors.error==true ? customSuffixIcon() :  <span />}
              name="phoneNumber"
       prefix={"+1"}
       
              placeholder="999-123-4567"
              onChange={(e) => {
                     const { value } = e.target;
                     
                     // Remove any non-digit characters
                     const formattedValue = value.replace(/\D/g, '').slice(0, 10);
                     // Format the number as 3-3-4
                     const formattedNumber = formattedValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

                     // Set the formatted value to the form field
                    setPhoneNumber(formattedNumber)
                    setphoneNumberErrors({
                     error:false,
                     message:"We’ll send you a verification code on this number"
              })
                   }}
             value={phoneNumber}
              style={{
                height: '44px', borderRadius: '8px',
                borderColor: phoneNumberErrors.error==true  ? '#f5222d' : '#D0D5DD',
              }}
            />
           <Typography sx={{marginTop:'6px', color:phoneNumberErrors.error==true?"#F04438": "#475467",fontSize:{  xs: "14px", // mobile
         sm: "14px", // tablet
         md: "14px", // small desktop
         lg: "14px",},fontWeight:"400",marginBottom:"32px"}}>
           {phoneNumberErrors.message}
          </Typography>
          </Box>
          <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:{
             xs: "column-reverse", // mobile
             sm: "row", // tablet
             md: "row", // small desktop
             lg: "row",
          }}}>
          <Button onClick={()=>{
        handleClose();
       }} style={{
              width: '100%',marginTop: isMobile?"12px": '4px', height: '44px', background: "#fff", color: "#344054",
              fontSize: '16px',
              fontWeight: "600",
              border:"1px solid var(--Gray-300, #D0D5DD)"
            }}>
              Cancel
            </Button>
            <Button style={{
              width: '100%', marginTop:  '4px', height: '44px', background: "#EF6820", color: "#FFF",
              fontSize: '16px',
              fontWeight: "600",
              marginLeft:isMobile?"0px": '12px',
              display:'flex',
              alignItems:'center',
              justifyContent:'center'
            }}
            onClick={()=>{
              if(dotPulseBtnLoader==false){
                ContinueMobileNumberValidation(phoneNumber)
              }
              // ContinueMobileNumberValidation(phoneNumber)
             
            }}
            >
              
            {dotPulseBtnLoader==true? <div className='dot-pulse-animation'/>:"Continue"}
            </Button>
           
          </Box>
        </Box>:phoneNumberFlowScreen==3?<SignupFlow onDataPass={handleDataFromChild}  phoneNumber={phoneNumber} onChangeScreen ={(value)=>{ setphoneNumberFlowScreen(value,
setisOtpType(2)
        )}} 
         closeModal={()=>{
          handleClose();
          setphoneNumberFlowScreen(1)
        }}
        />:phoneNumberFlowScreen==4?<LoginSuccess
        closeModal={()=>{
          handleClose();
          setphoneNumberFlowScreen(1)
        }}
        onChangeScreen ={(value)=>{ setphoneNumberFlowScreen(value)}} />: <LoginSignupOTPFlow
        otpType={isOtpType}
        
        userDetails={UserData}
        phoneNumber={phoneNumber}
        closeModal={()=>{
          handleClose();
          setphoneNumberFlowScreen(1)
        }}
        onChangeScreen ={(value)=>{ setphoneNumberFlowScreen(value)}}
         nameData={dataFromChild}/>}
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
     
islogindata: _.get(state, 'app.account.isLogin'),
  }
}

const mapDispatchToProps = dispatch => ({
  
  login: details => dispatch(global.redux.action.account.socialLogin(details)),
  otpLoginAPI: details => dispatch(global.redux.action.guest.sendOtp(details)),
  checkProfileAPI: details => dispatch(global.redux.action.guest.checkProfile(details)),
  verifyOtpAPI: details => dispatch(global.redux.action.account.phoneLogin(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberModal)

