import { Tabs } from 'antd';
import React from 'react'
import ProfileTab from './profile-tab'
import TemplateTab from './template-tab'
import FinancesTab from './finances-tab'
import './style.css'
const { TabPane } = Tabs;

const CoachOnboarding =(props)=>{

  const [activeKey, setActiveKey] = React.useState('1')
  const onKeyChange = (key) => setActiveKey(key)
    const render =()=>{
        return (<div className='coach-full-conatiner profile-tab-onboard'><Tabs defaultActiveKey="1" centered activeKey={activeKey} >
        <TabPane  className='profile-tab-onboard' tab="Profile" key="1">
          <ProfileTab onChangeTab={(e)=>onKeyChange(e)} />
        </TabPane>
        {/* <TabPane className='profile-tab-onboard' tab="Template" key="2">
          <TemplateTab onChangeTab={(e)=>onKeyChange(e)} />
        </TabPane> */}
        <TabPane className='profile-tab-onboard' tab="Finances" key="3">
          <FinancesTab onChangeTab={(e)=>onKeyChange(e)} />
        </TabPane>
      </Tabs></div>)
    }

    return render()
}

export default CoachOnboarding