import React from 'react';
import './view-style.css';

const LoadingTemplate = () => {
  return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "100px"}}>
        <div className='dot-falling'></div>
        </div>
  )
}

export default LoadingTemplate