import React, {useEffect, useState} from 'react';
import { Space, Table, Tag, Modal, Switch, notification, Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import QRCode from "react-qr-code";
import { connect } from 'react-redux';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import moment from 'moment'

import '../../onboarding-screen/style.css';
import FacilitateSession from './facilitateSession'
import axios from 'axios';


const OnsiteFacilitationTab = (props) => {
  
    let history = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getTableData, setTableData] = useState([]);
    const [timeZone, setTimeZone] = useState('');
    const [getScheduledDates, setScheduledDates] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [getLeageId, setLeageId] = useState();
    const [getOnsiteSwitch, setOnsiteSwitch] = useState({});
    const [getOnsiteModalOpen, setOnsiteModalOpen] = useState(false);
    const [closeOnsiteSwitch, setCloseOnsiteSwitch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [manageOnsiteData, setManageOnsiteData] = useState(false);
    const [leagueData, setLeagueData] = useState();
    const [IsOnsiteListVisible, setIsOnsiteListVisible] = useState(false);

    useEffect(() => {
      
      // if(searchParams.get('exp_id')){
      //   viewExperience(searchParams.get('exp_id'))
      // };

      if(_.get(props, 'experience_id')){
        getLeageEventList(_.get(props, 'experience_id'));
      }
    }, []);

    useEffect(() => {
      getLocationsData();
    }, []);
    const showModal = (id) => {
      
      setIsModalOpen(true);
      setLeageId(id)
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleManageOnsite = (value) => {
      
      setManageOnsiteData(true);
      setIsOnsiteListVisible(true)
      setLeagueData(value)
    }



    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '30%'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '25%'
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex:'action',
        width: '40%',
        render: (text, item) => {
          
        return (
          <Space size="middle">
            {/* <a onClick={() => showModal(_.get(item, 'leage_id'))}>View QR Code</a> */}
            <div style={{display: "flex", flexDirection: "row"}}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.33333 2.66667H5.2C4.0799 2.66667 3.51984 2.66667 3.09202 2.88465C2.71569 3.0764 2.40973 3.38236 2.21799 3.75869C2 4.18651 2 4.74656 2 5.86667V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.07989 14 5.2 14H10.1333C11.2534 14 11.8135 14 12.2413 13.782C12.6176 13.5903 12.9236 13.2843 13.1153 12.908C13.3333 12.4802 13.3333 11.9201 13.3333 10.8V8.66667M8.66667 11.3333H4.66667M10 8.66667H4.66667M13.4142 2.58579C14.1953 3.36683 14.1953 4.63317 13.4142 5.41421C12.6332 6.19526 11.3668 6.19526 10.5858 5.41421C9.80474 4.63317 9.80474 3.36683 10.5858 2.58579C11.3668 1.80474 12.6332 1.80474 13.4142 2.58579Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div style={{marginLeft: "4px"}} className='onsite_experience_manage_onsite_txt' onClick={() => {handleManageOnsite(item)}} >Manage Onsite</div>
            </div>
            
            {<div style={{display:"flex", flexDirection: "row"}}>
              <Switch onClick={() => onsiteShowModal(item)} checked={_.get(item, 'event_status') === 0 ? false : true} style={{backgroundColor: _.get(item, 'event_status') === 0 ? "#F2F4F7" : "#EF6820"}}/>
              <div className='onsite_experience_checkin_txt'>{_.get(item, 'event_status') === 0 ? "Open check-in" : "Close check-in"}</div>
              </div>}
          </Space>
        )
        },
      },
    ];



    const onsiteShowModal = (value) => {
      
      setOnsiteSwitch(value);
      if(_.get(value, 'event_status') > 0){
        setCloseOnsiteSwitch(true)
      } else {
        setOnsiteModalOpen(true);
      }
    };

    const onCloseOnsiteCancel = () => {
      setCloseOnsiteSwitch(false)
    };
    const onCloseOnsiteReset = () => {
      props.changeLeagueEventStatusAPI({
        body:{
          id: _.get(getOnsiteSwitch, 'leage_id'),
          retain_data: 0,
          event_status: 0
        },
        callback: (response, data) => {
          
          
          if(response){
            if(_.get(props, 'experience_id')){
              getLeageEventList(_.get(props, 'experience_id'))
            }
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
            setCloseOnsiteSwitch(false)
          }
         
        }
      })
    };
    const onCloseOnsiteSave = () => {
      props.changeLeagueEventStatusAPI({
        body:{
          id: _.get(getOnsiteSwitch, 'leage_id'),
          event_status: 0
        },
        callback: (response, data) => {
          
          
          if(response){
            if(_.get(props, 'experience_id')){
              getLeageEventList(_.get(props, 'experience_id'))
            }
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
            setCloseOnsiteSwitch(false)
          }
         
        }
      })
      
    };

    const confirmOnsiteModal = () => {
      props.changeLeagueEventStatusAPI({
        body:{
          id: _.get(getOnsiteSwitch, 'leage_id'),
          event_status: _.get(getOnsiteSwitch, 'event_status') === 0 ? 1 : 0
        },
        callback: (response, data) => {
          
          
          if(response){
            if(_.get(props, 'experience_id')){
              getLeageEventList(_.get(props, 'experience_id'))
            }
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
            setOnsiteModalOpen(false);
          }
         
        }
      })
      
    };

    const cancelOnsiteModal = () => {
      setOnsiteModalOpen(false);
    };

    const switchOnsite = () => {
      // 
    }

    const getLocationsData =async()=>{
      const { data, status } = await axios.get('https://ipapi.co/json')
      // setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
      setTimeZone(_.get(data,'timezone'))
      // 
    }

    // const viewExperience = (id) => {
    //   
    //   props.viewExperienceAPI({
    //     params: {
    //       id: id,
    //       expand: 'photos,template'
    //     },
    //     callback: (response, data) => {
    //       
    //       if(response){
    //         let experienceData = _.get(data, 'data.details.schedule_dates');
    //         let a = [];
    //         if(_.size(experienceData) > 0){
    //           _.map(experienceData, (value, index) => {
    //             
    //             a.push({
    //               key: index,
    //               date: moment(value).format('MMM DD, YYYY'),
    //               // date: value,
    //               status: 'Not Started'
    //             })
    //           });
    //           setTableData(a);
    //         }
    //       }
    //     }
    //   })
    // };

    const getLeageEventList = (id) => {
      setLoading(true)
      props.leagueEventListAPI({
        params: {
          league: id,
          expand: 'league',
          sort: "start_time.asc",
          limit: 1000
        },
        callback: (response, value) => {
          setLoading(false)
          
          
          if(response){
            let a = [];
            if(_.size(_.get(value, 'data.items')) > 0){
              _.get(value, 'data.items').map((val, index) => {
                
                a.push({
                  key: index,
                  date: <div className='onsite_experience_text_format'>{moment(_.get(val, 'start_time')).format('ddd, MMM Do, YYYY')}</div>,
                  status: _.get(val, 'status') === 2 ? <div className='onsite_experience_status'> <svg style={{marginRight: "6px"}} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="4" cy="4" r="3" fill="#667085"/>
                </svg>Inactive</div>: <div className='onsite_experience_not_checked_in_status'> <svg style={{marginRight: "6px"}} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="4" cy="4" r="3" fill="#12B76A"/>
                </svg>Check-in open</div>,
                  leage_id: _.get(val, 'id'),
                  event_status: _.get(val, 'event_status'),
                  player_checked_in: _.get(val, 'players_check_in'),
                  players_unavailable: _.get(val, 'players_unavailable'),
                  actual_onsite_date: moment(_.get(val, 'start_time')).format('ddd, MMM Do, YYYY')
                })
              });
              setTableData(a);
              
            }
          }
        }
      })
    };

    const handleBack = () => {
      setIsOnsiteListVisible(false);
      setManageOnsiteData(false);
      setLeagueData();
    };

    const render = () => {

      //  let qrValue = searchParams.get('exp_id')
      

        return(
            <div>
              <div>
              <div style={{display: "flex", flexDirection:"row"}}>
                { leagueData && <div onClick={() => handleBack()} className='onsite_handle_back_btn'><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.8334 10H4.16675M4.16675 10L10.0001 15.8333M4.16675 10L10.0001 4.16667" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></div>}
              <div style={{marginLeft: "16px", marginTop: "8px"}} className='experience_name_style'>{_.get(props, 'nameOfExp')} { _.get(leagueData, 'actual_onsite_date') ? `on ${_.get(leagueData, 'actual_onsite_date')}` : ''}</div>
             { _.get(leagueData, 'event_status') > 0 && <div style={{marginTop: "8px"}} className='experience_check_in'><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="3" fill="#12B76A"/>
              </svg> Check-in open</div>}
              {
                _.get(leagueData, 'event_status') === 0 && <div style={{marginLeft: "20px", marginTop: "8px"}} className='onsite_experience_status'><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="3" fill="#667085"/>
              </svg> Inactive</div>
              }
              </div>
             
              <hr/>
              { !manageOnsiteData && !IsOnsiteListVisible && <div>
                <Table loading={loading} columns={columns} dataSource={getTableData} pagination={false} style={{marginTop: '20px'}}/>
                  <Modal centered={true} width="20%" footer={null} title="QR Code" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div style={{ height: "auto", marginLeft: "30px", maxWidth: 64, width: "100%" }}>
                  <QRCode
                  size={150}
                  // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value = {getLeageId}
                  // viewBox={`0 0 300 256`}
                  />
              </div>
            </Modal>
              <Modal centered={true} 
            style={{padding: "24px 24px 0px 24px"}}
            title={"Are you sure you want to start the onsite?"} 
            open={getOnsiteModalOpen} 
            onOk={confirmOnsiteModal} 
            onCancel={cancelOnsiteModal}
            footer={null}
            >
              <div>
              <p>Setting this button to active will send a notification and enable a live banner to all users phones who are registered for this League.</p> 
              <hr/>
              <div style={{display: "flex", justifyContent: "flex-end", flexDirection: "row"}}>
                  <Button style={{height: "44px", color: "#475467"}}>No, take me back</Button>
                  <Button onClick={() => confirmOnsiteModal()} style={{marginLeft: "12px", height: "44px", backgroundColor: "#EF6820", color: "#FFFFFF"}}>Yes, open check-ins</Button>
              </div>
              </div>
              
                
      </Modal>
        <Modal centered={true}
      title="This will close the onsite and stop broadcasting to all users"
    open={closeOnsiteSwitch}
    onCancel={onCloseOnsiteCancel}
    onOk={onCloseOnsiteSave}
    footer={[
      <div style={{flexDirection: "row"}}> 
      <Button onClick={onCloseOnsiteCancel}>Cancel</Button>
      <Button style={{marginLeft: "8px"}} onClick={onCloseOnsiteReset}>Reset</Button>
      <Button style={{marginLeft: "8px"}} onClick={onCloseOnsiteSave}>Save</Button>
      </div>
    ]}
      >
        <p>Press SAVE to save your progress (check-ins, teams and matches will be saved)</p>
        <p>RESET to clear all your progress (users will need to be re-checked-in, teams and matches regenerated)</p>
        <p>CANCEL to exit this prompt. </p> 
      </Modal>
      </div>}

      {
        manageOnsiteData && <FacilitateSession experience_league_data={leagueData} experience_id={_.get(props, 'experience_id')} nameOfExp={_.get(props, 'nameOfExp')}/>
      }
      </div>
            </div>
        )
    };

    return render();
    
};
const mapStateToProps = state => {
  return {
     checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
     isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
     islogindata: _.get(state, 'app.account.isLogin'),
     userProfile: _.get(state, 'app.host.profile.data.details'),
     guestUserProfile: _.get(state, 'app.guest.profile.data.details')

  }
}

const mapDispatchToProps = dispatch => ({
  viewExperienceAPI: details => dispatch(global.redux.action.experience.view(details)),
  leagueEventListAPI: details => dispatch(global.redux.action.league.list(details)),
  changeLeagueEventStatusAPI: details => dispatch(global.redux.action.league.changeEventStatus(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OnsiteFacilitationTab)