import React, { useEffect, useState, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button, message } from 'antd';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';

let index = 0;
const Tags = (props) => {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');
  const inputRef = useRef(null);
  const [sample, setSample] = useState();

  useEffect(() => {
    getDetails();
  }, [])
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    
    e.preventDefault();
    setItems([...items, name]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const removeItem = (value) => {
    const updatedItems = items.filter((item) => item !== value);
    setItems(updatedItems);
  };

  const getDetails = () => {
    props.experienceViewAPI({
      params:{
        id: searchParams.get("id"),
        expand: "template"
      },
      callback: (res, val) => {
        
        if(res){
          if(!_.isEmpty(_.get(val, 'data.details'))){
            if(!_.isEmpty(_.get(val, 'data.details.tags'))){
              setItems(_.get(val, 'data.details.tags'));
            } else {
              setItems([]);
            }
           
          }
        }
        
      }
    })
  };

  const updateTags = () => {
    
    props.updateExperienceTag({
      body:{
        tags: items,
        id: searchParams.get("id")
      },
      callback: (res, data) => {
        
        if(_.get(data, 'success')){
          messageApi.open({
            type: 'success',
            content: 'Tags saved.',
          });
          getDetails();
        }
      }
    })
  }
  
  return (
    <div>
      {contextHolder}
      <Select
    onDeselect={removeItem}
    value={items}
    mode='multiple'
    defaultValue={items.map((v, i) => (v))}
      style={{
        width: "100%",
        height: "40px"
      }}
      placeholder="Add Tag"
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <Input
              placeholder="Please enter tag"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Button 
            disabled={_.isEmpty(name) || items.includes(name)}
            type="text" 
            icon={<PlusOutlined />} 
            onClick={addItem}>
              Add Tag
            </Button>
            <Button onClick={() => updateTags()}>Save</Button>
          </Space>
        </>
      )}
      options={items.map((item) => ({
        label: item,
        value: item,
      }))}
    />
    </div>
    
  );
};
const mapStateToProps = state => {
  return {
     
  }
}

const mapDispatchToProps = dispatch => ({
  experienceViewAPI: details => dispatch(global.redux.action.experience.view(details)),
  updateExperienceTag: details => dispatch(global.redux.action.experience.updateExperienceTag(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Tags)