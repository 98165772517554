import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateExperiencesState;
// host actions

export default {

       //Start Questions sets 
       //List
       questionSetsList: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'get',
                                   url: `/registration-info/sets/list`
                            },
                            actionType: actionType.questions.registrationInfoSetsList,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //View
       questionSetsView: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'get',
                                   url: `/registration-info/sets/view`
                            },
                            actionType: actionType.questions.registrationInfoSetsView,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       // Create 
       questionSetsCreate: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/registration-info/sets/create`
                            },
                            actionType: actionType.questions.registrationInfoSetsCreate,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //Update
       questionSetsUpdate: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/registration-info/sets/update`
                            },
                            actionType: actionType.questions.registrationInfoSetsUpdate,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //Assign
       questionSetsAssign: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/registration-info/sets/assign`
                            },
                            actionType: actionType.questions.registrationInfoSetsAssign,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //End Questions sets


        //Start Questions APIS 
       //List
       questionList: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'get',
                                   url: `/registration-info/question/list`
                            },
                            actionType: actionType.questions.registrationInfoQuestionList,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //View
       questionView: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'get',
                                   method: 'get',
                                   url: `/registration-info/question/view`
                            },
                            actionType: actionType.questions.registrationInfoQuestionView,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       // Create 
       questionCreate: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/registration-info/question/create`
                            },
                            actionType: actionType.questions.registrationInfoQuestionCreate,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //Update
       questionUpdate: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/registration-info/question/update`
                            },
                            actionType: actionType.questions.registrationInfoQuestionUpdate,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //Delete
       questionDelete: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/registration-info/question/delete`
                            },
                            actionType: actionType.questions.registrationInfoQuestionDelete,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //Assign
       questionSort: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/registration-info/question/sort`
                            },
                            actionType: actionType.questions.registrationInfoQuestionSort,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //End Questions APIs



    // get experience templates of logged in host
    questionsSetList: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/experiences/registraton-info/sets/list`
                },
                actionType: actionType.questions.questionsSetList,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    
    groupByExperincesList: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/experiences/list-by-group`
                },
                actionType: actionType.questions.groupByExperincesList,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    assignQuestions: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/registraton-info/sets/assign-experiences`
                },
                actionType: actionType.questions.assignQuestions,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
   
}