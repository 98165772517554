import { UpOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React from 'react'
import {Collapse} from 'antd'

import './style.css'
import { useNavigate } from 'react-router-dom'
const { Panel } = Collapse;

const CoachInfoLanding =(props)=>{
	let history = useNavigate();


    const render =()=>{
        return (
        <div className="coach-full-conatiner">
            <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                <span style={{fontSize:'33px',fontWeight:'500',marginBottom:'13px'}}>Verify your information</span>
                <span style={{fontSize:'15px',marginBottom:'8%'}}>Before you get started we need to confirm a few details</span>
                <div  
            className="btn-inner-container" onClick={()=>history('/communiti/coach-information')} style={{backgroundColor:"#e85f24",cursor:'pointer',justifyContent:'center'}}> 
            <div style={{    padding:"6px 59px",color: "#fff"}}>Continue</div></div>
   </div>
            

            
        </div>)
    }

    return render()
}

export default CoachInfoLanding