import React, {useEffect, useState} from "react";
import { Button, InputNumber, Image, Collapse, Select, Table, Popconfirm, Modal, notification, Switch, Input, Alert, message } from 'antd';
import {MinusCircleOutlined} from "@ant-design/icons";
import _ from 'lodash';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import socketIOClient from "socket.io-client";
import "./onsite.css";

import '../../onboarding-screen/style.css';

const { Panel } = Collapse;
const { Option } = Select;
const BuildMatchSchedule = (props) => {

  const [matchDetails, setmatchDetails] = useState({})

    const[generateButtonHide, setGenerateButtonHide] = useState(false);
    const[getOpenModal, setOpenModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [isBoardCastSwitchModalOpen, setIsBoardCastSwitchModalOpen] = useState(false);
    const [viewDetails, setViewDetails] = useState({});
    const [getBoardCastSwitch, setBoardCastSwitch] = useState(false);
    const [getTotalRoundsValue, setTotalRoundsValue] = useState(3);
    const [getTotalSets, setTotalSets] = useState(2);
    const [getMatchesData, setMatchesData] = useState({});
    const [getMatchDataSource, setMatchDataSource] = useState([]);
    const [getSocketData, setSocketData] = useState(true);
    const [getTableMatch, setTableMatch] = useState([]);
    const [getTableMatchDetsils, setTableMatchDetsils] = useState({});
    // const [scores, setScores] = useState([]);
    const [teamTwoScores, setTeamTwoScores] = useState([]);
    const [getRound1Data, setRound1Data] = useState([]);
    const [getRound2Data, setRound2Data] = useState([]);
    const [getRound3Data, setRound3Data] = useState([]);
    const [getRound4Data, setRound4Data] = useState([]);
    const [getRound1DataSource, setRound1DataSource] = useState([]);
    const [getRound2DataSource, setRound2DataSource] = useState([]);
    const [getRound3DataSource, setRound3DataSource] = useState([]);
    const [buttonRename, setButtonRename] = useState([])

    const [setScores, setSetScores] = useState([]);
    const [set2Scores, set2SetScores] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
      messageApi.open({
        type: 'success',
        content: 'Refreshed successfully',
      });
    };

    const dynamicColumn = (tableIndex) => {
      const columns = [
        {
          title: <div className="build_match_schedule_score_table_column_txt">Court</div>,
          dataIndex: 'court',
          key: 'court',
          
   
        },
        {
          title: <div className="build_match_schedule_score_table_column_txt">Team 1</div>,
          width: "20%",
          dataIndex: 'team_1',
          key: `table${tableIndex}_team_1`,
        },
      ];

      for (let i = 1; i <= _.get(getTableMatchDetsils, 'event.no_of_sets'); i++) {
        columns.push({
          title: <div className="build_match_schedule_score_table_column_txt">{`Set ${i}`}</div>,
          dataIndex: `team_1_set_${i}_score`,
          width: "10%",
          key: `team_1_set_${i}_score`,
         
        });
        // columns.push({
        //   title: `Action`,
        //   dataIndex: `act_tions`,
        //   width: "10%",
        //   key: `act_tions`,
         
        // });
      }
  
      columns.push({
        title: <div className="build_match_schedule_score_table_column_txt">Team 2</div>,
        width: "10%",
          dataIndex: 'team_2',
          key: `table${tableIndex}_team_2`,
      })
  
      for (let i = 1; i <= _.get(getTableMatchDetsils, 'event.no_of_sets'); i++) {
        columns.push({
          title: <div className="build_match_schedule_score_table_column_txt">{`Set ${i}`}</div>,
          dataIndex: `team_2_set_${i}_score`,
          width: "10%",
          key: `team_2_set_${i}_score`,
         
        });
        // columns.push({
        //   title: `Action`,
        //   dataIndex: `act_tions1`,
        //   width: "10%",
        //   key: `act_tions1`,
         
        // });
      }
      columns.push({
        title: <div className="build_match_schedule_score_table_column_txt">Result</div>,
          dataIndex: 'result',
          key: 'result',
      })
      columns.push({
        title: <div className="build_match_schedule_score_table_column_txt">Action</div>,
          dataIndex: 'action',
          key: 'action',
         
      })
      return columns;
    }
    

    

    const handleSetScoreChange = (tableIndex, record, match_1, value, scoreValue) => {
      
      // const updatedSetScores = [...setScores];
      // const uniqueKey = `${record.key}_${match_1}`;
      // // 
      // const existingScore = updatedSetScores.find(
      //   (score) => score.tableIndex === tableIndex && score.matchId === record.key && score.match_1 === match_1
      // );
      //       if (existingScore) {
      //   existingScore[`setScore${match_1}_team1_round${tableIndex}_${record.key}`] = value;
      // } else {
      //   updatedSetScores.push({ tableIndex, matchId: record.key, match_1, [`setScore${match_1}_team1_round${tableIndex}_${record.key}`]: value });
      //     }
  
      // setSetScores(updatedSetScores);
    };

    const handleSetScore2Change = (tableIndex, record, match_2, value) => {
      
      const updatedSetScores = [...set2Scores];
      const uniqueKey = `${record.key}_${match_2}`;
      const existingScore = updatedSetScores.find(
        (score) => score.tableIndex === tableIndex && score.matchId === record.key && score.match_2 === match_2
      );
            if (existingScore) {
        existingScore[`setScore${match_2}_team2_round${tableIndex}_${record.key}`] = value;
      } else {
        updatedSetScores.push({ tableIndex, matchId: record.key, match_2, [`setScore${match_2}_team2_round${tableIndex}_${record.key}`]: value });  
          }
  
      set2SetScores(updatedSetScores);
    };
    const data = [
      { round_no: 1, home_team: 'Team A', away_team: 'Team B' },
      { round_no: 2, home_team: 'Team C', away_team: 'Team D' },
      { round_no: 3, home_team: 'Team E', away_team: 'Team F' },
      { round_no: 1, home_team: 'Team I', away_team: 'Team J' },
      { round_no: 1, home_team: 'Team K', away_team: 'Team L' },
      { round_no: 2, home_team: 'Team M', away_team: 'Team N' },
      { round_no: 3, home_team: 'Team O', away_team: 'Team P' },
    ];

    
    const mapByObject =(data)=>{
      let a =[]
      
      _.map(_.get(data,'data.items'),(items,i)=>{

         
          let b =[]
          _.map(_.times(_.get(items,'no_of_sets',1), Object),(v,i)=>{
              b.push({
                  id:i+1, team_1_score:0,team_2_score:0
              })
          })
          
          a.push({
              name:_.get(items,'name'),
              court:_.get(items,'court'),
              no_of_sets:_.get(items,'no_of_sets'),
              ...items,
              team_1:{..._.get(items,'team_1'),set_scores:_.get(items,'set_scores') &&_.size(_.get(items,'set_scores'))>0?_.get(items,'set_scores'):_.size(_.get(items,'team_1.set_scores'))>0?_.get(items,'team_1.set_scores'): b},
              team_2:{..._.get(items,'team_2'),set_scores:_.get(items,'set_scores') &&_.size(_.get(items,'set_scores'))>0?_.get(items,'set_scores'):_.size(_.get(items,'team_2.set_scores'))>0?_.get(items,'team_2.set_scores'): b},

              
          })
      })
      
      data.items = a
      const roundGroups = a.reduce((groups, match) => {
        const roundNo = match.round_no;
        if (!groups[roundNo]) {
          groups[roundNo] = [];
        }
        groups[roundNo].push(match);
        return groups;
      }, {});
      

      setTableMatch(roundGroups)
      setmatchDetails(data)
  }

    useEffect(() => {
      viewEvent();
      getMatchesList();

    }, [props]);

    useEffect(() => {
      viewEvent();
      getMatchesList();
    }, [getSocketData]);

    useEffect(() => {
      const socket = socketIOClient("", {
  reconnection: false,
  transports: ['websocket']
});
  socket.open();
  socket.on('utilitiesMatch', data => {
    
      setSocketData(_.get(data, 'data.event_data.loading'));
  })
  }, []);

    const getMatchesList = (value) => {
      props.leagueMatchesListAPI({
        params: {
          id: _.get(props, 'experience_league_data.leage_id'),
          expand: "team_1,team_2",
          sort: "round_no.asc"
        },
        callback: async(response, data) => {
          
          if(response){
            if(_.get(data, 'data.details')){
              setMatchesData(_.get(data, 'data.details'))
            }
              if(value === 'refresh'){
                success()
              }
            mapByObject(data)
            if(_.size(_.get(data, 'data.items')) > 0){
              // setTableMatch(_.get(data, 'data.items'))
              setButtonRename(_.get(data, 'data.items'))
              setTableMatchDetsils(_.get(data, 'data.details'))
              
            }
          };
        }
      })
    };

    
      // const roundGroups = getTableMatch.reduce((groups, match) => {
      //   const roundNo = match.round_no;
      //   if (!groups[roundNo]) {
      //     groups[roundNo] = [];
      //   }
      //   groups[roundNo].push(match);
      //   return groups;
      // }, {});


  const team_1_Input =(value, type,itemIndex,roundIndex)=>{
    let a ={}
     _.map(_.times(_.get(value,'no_of_sets'),String),(v,i)=>{
      
 a[`team_${type}_set_${i+1}_score`]= <Input style={{width: "65px"}}  defaultValue={_.get(value,`set_scores[${i}].team_${type}_score`)}  
  onChange={(e)=>onChangeUpdate(e.target.value,`team_${type}_score`,type,itemIndex,i,roundIndex, value)} key={`team_${type}_set_${i+1}_score`} />

    }) 
    
    return a

  }

const updateScroreDetails =(value,setScoreIndex,team,e) =>{
  
  setTimeout(() => {
    props.updateNewMatchAPI({
      params:{id:_.get(value,'id')},
      body:{
        id: _.get(value,'id'),
        user_type: 1,
        set_no: setScoreIndex+1,
        team_no: team,
        score: e
      },
      callback:(res,data)=>{
          
         if(res){
          getMatchesList();
          viewEvent();
          // notification.success({
          //   message: "Success",
          //   description: _.get(data,'message')
          // })

         } else if(res==false && _.get(data,'message')){
          notification.error({
            message: "Error",
            description: _.get(data,'message')
          })
         }else{
         
         }
        
      }
  })
  }, 2000)
}
  const onChangeUpdate =(e,name,team,index,setScoreIndex,roundIndex, value)=>{
       
       updateScroreDetails(value,setScoreIndex,team,e)
      
    let a = getTableMatch
    
    
    if(a[roundIndex][index][`team_${team}`]?.set_scores[setScoreIndex]){
      a[roundIndex][index][`team_${team}`].set_scores[setScoreIndex][name]=e
      
      
      
      a[roundIndex][index].set_scores= a[roundIndex][index][`team_${team}`].set_scores
    
    }else{
      // 
      
      a[roundIndex][index][`team_${team}`].set_scores = [...a[roundIndex][index][`team_${team}`].set_scores,
    {id:_.size(a[roundIndex][index][`team_${team}`].set_scores)+1,team_1_score:team==1?e:0,team_2_score:team==2?e:0}
    
    ]
    a[roundIndex][index].set_scores =  [...a[roundIndex][index][`team_${team}`].set_scores,
    {id:_.size(a[roundIndex][index][`team_${team}`].set_scores)+1,team_1_score:team==1?e:0,team_2_score:team==2?e:0}
    
    ]
    
    }
    
    setTableMatch(a)
        }
        const getTeamScroe =(team,type)=>{
          let a = []
          let b = []
          
          _.map(team,(v,i)=>{
          
              if(parseFloat(_.get(v,'team_1_score'))>parseFloat(_.get(v,'team_2_score'))){
                  a.push(true)
          
              }
              if(parseFloat(_.get(v,'team_1_score'))<parseFloat(_.get(v,'team_2_score'))){
                  b.push(true)
          
              }
              
          })
          return type ==1?_.size(a):_.size(b)
          }
             
        const updateScrore =(items, itemIndex)=>{
          getMatchesList();
          viewEvent();
          notification.success({
            message: "Success",
            description: "Match updated successfully"
          })
      };
      const handleCourtChange = (value, details) => {
          
          if(value && details){
            let coutDetails = {
              id: _.get(value, 'id'),
              "user_type": 1,
              "court": details
            }
            props.updateMatchAPI({
              params:{
                id: _.get(value, 'id')
              },
              body: coutDetails,
              callback: (r, v) => {
                
              }
            })
          }
      }
      const roundTables = (data) => {
       
       

      return  _.map(data,(v,roundIndex)=>{
          let array = [];
          v.length > 0 && v.map((val, itemIndex) => {
        
            
                    array.push({
                      key: itemIndex,
                      team_1: <div className="build_match_schedule_score_table_column_txt">{_.get(val, 'team_1.name')}</div>,
                      team_2: <div className="build_match_schedule_score_table_column_txt">{_.get(val, 'team_2.name')}</div>,
                      // court: _.get(val, 'court'),
                      court:  
                        _.size(_.get(getTableMatchDetsils, 'event.courts')) > 0 ?
                        (<Select onChange={(e) => handleCourtChange(val, e)} defaultValue={_.get(val, 'court')}>
             {_.get(getTableMatchDetsils, 'event.courts').map((court, index) => (
             <Option key={index} value={court.name}>
               {court.name}
             </Option>
             ))}
             </Select>) : "No courts available"
                       ,
                      ...team_1_Input(val,1,itemIndex,roundIndex),
                      ...team_1_Input(val,2,itemIndex,roundIndex),
                      action:<div>
                      <button className="build_match_schedule_save_score_btn" onClick={()=>updateScrore(val, itemIndex)}> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.5 6.66667H7.16667C6.69996 6.66667 6.4666 6.66667 6.28834 6.57584C6.13154 6.49594 6.00406 6.36846 5.92416 6.21166C5.83333 6.0334 5.83333 5.80004 5.83333 5.33333V2.5M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>Save</button>
                    </div>,
                    result: _.get(val, 'result','--')
                    })
                  })

          return   <div key={roundIndex+1}>
                      <div style={{display: "flex", flexDirection: "row"}}> <div style={{marginTop: "18px"}} className="build_match_schedule_game_round"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.00028 9.16666H8.33361M6.66694 7.49999V10.8333M12.5003 9.99999H12.5086M15.0003 8.33332H15.0086M8.70774 4.16666H11.2928C13.4802 4.16666 14.5739 4.16666 15.4324 4.58118C16.1882 4.94615 16.8162 5.5309 17.2341 6.25882C17.7088 7.08557 17.7867 8.17649 17.9425 10.3583L18.1476 13.2287C18.2481 14.6362 17.1334 15.8333 15.7224 15.8333C15.0008 15.8333 14.3165 15.5128 13.8546 14.9585L13.5419 14.5833C13.256 14.2402 13.113 14.0686 12.9502 13.9299C12.6089 13.6393 12.1957 13.4458 11.7539 13.3696C11.5432 13.3333 11.3198 13.3333 10.8731 13.3333H9.12741C8.68072 13.3333 8.45737 13.3333 8.24664 13.3696C7.80486 13.4458 7.39167 13.6393 7.05036 13.9299C6.88756 14.0686 6.74457 14.2402 6.45861 14.5833L6.14597 14.9585C5.68403 15.5128 4.99973 15.8333 4.27816 15.8333C2.86714 15.8333 1.75245 14.6362 1.85298 13.2287L2.05801 10.3583C2.21386 8.17649 2.29178 7.08557 2.76642 6.25882C3.18431 5.5309 3.81231 4.94615 4.56816 4.58118C5.42663 4.16666 6.52033 4.16666 8.70774 4.16666Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg></div> <div className="build_match_schedule_number_of_rounds_txt" style={{marginTop: "23px", marginLeft: "8px"}}>Round {roundIndex}</div></div>
                      <Table style={{marginTop: "20px"}} columns={dynamicColumn(roundIndex)} dataSource={array} pagination={false} />
                    </div>
                  
        })
      }
       
      
    

    const setRound1TableData = (value, cout) => {
      let a = []
      if(_.size(value) > 0){
        value.map((value, index) => {
          
          const noOfSets = _.get(value, 'no_of_sets'); 
          // assuming all items have the same no_of_sets value
        // const tableColumns = hghg(noOfSets);
          a.push({
            key: index,
            courts: _.get(cout, 'event.courts'),
            team_1: _.get(value, 'team_1.name'),
            team_2: _.get(value, 'team_2.name'),
            result: _.get(value, 'result','--')
          })
        });
        setRound1DataSource(a)
      }
    }

    const setRound2TableData = (value, cout) => {
      let a = []
      if(_.size(value) > 0){
        value.map((value, index) => {
          a.push({
            key: index,
            courts: _.get(cout, 'event.courts'),
            team_1: _.get(value, 'team_1.name'),
            team_2: _.get(value, 'team_2.name')
          })
        });
        setRound2DataSource(a)
      }
    }

    const setRound3TableData = (value, cout) => {
      let a = []
      if(_.size(value) > 0){
        value.map((value, index) => {
          a.push({
            key: index,
            courts: _.get(cout, 'event.courts'),
            team_1: _.get(value, 'team_1.name'),
            team_2: _.get(value, 'team_2.name')
          })
        });
        setRound3DataSource(a)
      }
    }

    const courtsSelect = () => {
     if( _.size(_.get(getMatchesData, 'event.courts')) > 0) {
      _.get(getMatchesData, 'event.courts').map((val, index) => {
        return(
          <Select><Option key={index}>{_.get(val, 'name')}</Option></Select>
        )
      })
     }
    }

    const setTableData = (data) => {
      
      let a = [];
      _.map(data, (val, index) => {
        
        a.push({
          key: index,
          team: _.get(val, 'team_1.name'),
          team2: _.get(val, 'team_2.name'),
          court: courtsSelect(),
          action: (<div><Popconfirm title="Sure to delete?"> <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/></Popconfirm></div>),
        })
      })
      
      setMatchDataSource(a)
    };

    const showModal = () => {
      setIsModalOpen(true);
    };

    const showResetModal = () => {
      setIsResetModalOpen(true);
    };

    const showBoardCastModal = (value) => {
      
      setBoardCastSwitch(value)
      setIsBoardCastSwitchModalOpen(true);
    };

    const handleBoardCastOk = (value) => {
      
      let body_params = {
        id:_.get(props, 'experience_league_data.leage_id'),
        event_status: getBoardCastSwitch === false ? 3 : 4,
        no_of_rounds: _.get(getMatchesData, 'event.no_of_rounds'),
        no_of_sets: _.get(getMatchesData, 'event.no_of_sets'),
        retain_data: getBoardCastSwitch === false ? 1 : 0
      }
      if(getBoardCastSwitch){
        delete body_params.no_of_rounds;
        delete body_params.no_of_sets
      }
      if(getBoardCastSwitch){
        delete body_params.retain_data
      }
      props.changeLeagueEventStatusAPI({
        body: body_params,
        callback: (response, data) => {
          if(response){
            setIsBoardCastSwitchModalOpen(false);
           
              viewEvent();
           
            
            setIsBoardCastSwitchModalOpen(false);
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
          }
        }
      })
    };

    const viewEvent = () => {
      let parameters = {
        id: _.get(props, 'experience_league_data.leage_id')
      }
      props.viewLeagueEventsAPI({
        params: parameters,
        callback: (response, data) => {
          
          if(response){
            setViewDetails(_.get(data, 'data.details'))
          }
        }
      })
    }

    const handleBoardCastCancel = () => {
      setIsBoardCastSwitchModalOpen(false);
    }

    const handleReset = () => {
      props.changeLeagueEventStatusAPI({
        body:{
          id:_.get(props, 'experience_league_data.leage_id'),
          event_status: 2,
          retain_data: 0
        },
        callback: (response, data) => {
          if(response){
            setSocketData(true)
            setIsResetModalOpen(false);
            setGenerateButtonHide(false);
         
              // viewEvent();
              // getMatchesList()
          
           
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
          }
        }
      })
      
    };

    const cancelResetModal = () => {
      setIsResetModalOpen(false);
    }
    
    const handleOk = () => {
      props.changeLeagueEventStatusAPI({
        body:{
          id:_.get(props, 'experience_league_data.leage_id'),
          event_status: 3,
          no_of_rounds: getTotalRoundsValue,
          no_of_sets: getTotalSets,
          retain_data: 0
        },
        callback: (response, data) => {
          if(response){
            // setTimeout(() => {
              viewEvent();
              getMatchesList();
            // }, 3000);
            setTableMatch([]);
            
            setIsModalOpen(false);
            setGenerateButtonHide(true)
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
          }
        }
      })
      //   setGenerateButtonHide(true)
      // setIsModalOpen(false);
    };

    const handleCancelBuildMatch = () => {
      setIsModalOpen(false);
    };

    const onClickGenerate = () => {
      props.changeLeagueEventStatusAPI({
        body:{
          id:_.get(props, 'experience_league_data.leage_id'),
          event_status: 3
        },
        callback: (response, data) => {
          if(response){
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
          }
        }
      })
        setGenerateButtonHide(true)
    };
    const onClickReset = () => {
      
      props.changeLeagueEventStatusAPI({
        body:{
          id:_.get(props, 'experience_league_data.leage_id'),
          event_status: 2
        },
        callback: (response, data) => {
          if(response){
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
          }
        }
      })
      setGenerateButtonHide(false)
    };

    const handleBlur = () => {
      
      setOpenModal(true)
    };

    const handleContinue = () => {
      setOpenModal(false)
    };

    const handleCancel = () => {
      setOpenModal(false)
    };

    const handleChangeBoardCast = (value) => {
        
          props.changeLeagueEventStatusAPI({
            body:{
              id:_.get(props, 'experience_league_data.leage_id'),
              event_status: value === true ? 4 : 3
            },
            callback: (response, data) => {
              if(response){
                notification.success({
                  message: "Success",
                  description: "Status changed successfully"
                })
              }
            }
          })
    };

    const setMatchStatus = (value) => {
      props.changeLeagueEventStatusAPI({
        body:{
          id:_.get(props, 'experience_league_data.leage_id'),
          event_status: value
        },
        callback: (response, data) => {
          if(response){
            notification.success({
              message: "Success",
              description: "Status changed successfully"
            })
          }
        }
      })
    };

    const onchangeRounds = (value) => {
      setTotalRoundsValue(value)
    };

    const onChangeSets = (value) => {
      setTotalSets(value)
    };

   

    const refreshAPIs = () => {
      let refreshed_value = 'refresh'
      viewEvent();
      getMatchesList(refreshed_value);
      setTableMatch([])

    };
    

    const render = () => {

        return(
            <div>
              {contextHolder}
                 <div>
                 <div style={{display: "flex", flexDirection: "row", alignItems:"center", justifyContent: "center", marginBottom: "15px"}}>
                        {/* <Button onClick={() => showResetModal()} style={{marginRight: "15px", borderRadius: "20px", color: "#fff", backgroundColor: "#e85f2f"}}>Reset<Image style={{width: "20px", height: "20px"}} preview={false} src={require('../../../assets/img/refresh-cw.png')}/></Button> */}
                        <button className="build_match_schedule_reset_save_btn"  onClick={() => refreshAPIs()} > <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.667 4.27116C16.4348 5.55911 17.5837 7.64538 17.5837 10C17.5837 13.912 14.4123 17.0833 10.5003 17.0833H10.0837M6.33366 15.7288C4.56588 14.4409 3.41699 12.3546 3.41699 10C3.41699 6.08798 6.58831 2.91667 10.5003 2.91667H10.917M11.3337 18.6667L9.66699 17L11.3337 15.3333M9.66699 4.66667L11.3337 3L9.66699 1.33333" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>Refresh</button>
                        <button className="build_match_schedule_reset_save_btn" style={{marginLeft: "32px"}}> <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13 6.66667H7.66667C7.19996 6.66667 6.9666 6.66667 6.78834 6.57584C6.63154 6.49594 6.50406 6.36846 6.42416 6.21166C6.33333 6.0334 6.33333 5.80004 6.33333 5.33333V2.5M14.6667 17.5V12.1667C14.6667 11.7 14.6667 11.4666 14.5758 11.2883C14.4959 11.1315 14.3685 11.0041 14.2117 10.9242C14.0334 10.8333 13.8 10.8333 13.3333 10.8333H7.66667C7.19996 10.8333 6.9666 10.8333 6.78834 10.9242C6.63154 11.0041 6.50406 11.1315 6.42416 11.2883C6.33333 11.4666 6.33333 11.7 6.33333 12.1667V17.5M18 7.77124V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V6.5C3 5.09987 3 4.3998 3.27248 3.86502C3.51217 3.39462 3.89462 3.01217 4.36502 2.77248C4.8998 2.5 5.59987 2.5 7 2.5H12.7288C13.1364 2.5 13.3402 2.5 13.5321 2.54605C13.7021 2.58688 13.8647 2.65422 14.0138 2.7456C14.182 2.84867 14.3261 2.9928 14.6144 3.28105L17.219 5.88562C17.5072 6.17387 17.6513 6.318 17.7544 6.48619C17.8458 6.63531 17.9131 6.79789 17.9539 6.96795C18 7.15976 18 7.36358 18 7.77124Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>Save</button>
                        <Switch  onChange={(e) => showBoardCastModal(e)} checked={_.get(viewDetails, 'event_status') === 4 ? true : false} style={{marginTop: "-5px", marginLeft: "32px", backgroundColor: _.get(viewDetails, 'event_status') === 4 ? "#EF6820" : '#F2F4F7'}}/>
                        <div className="boardcast_match_txt">Broadcast matchups</div>
                    </div>
                 <div className="buildmatch_schedule_stats_card">
                  <div className="buildmatch_schedule_card_inner_txt">Total Courts available: {_.size(_.get(getMatchesData, 'event.courts'))}</div>
                 </div>

            <div className="buildmatch_schedule_generate_input_card">
            <div style={{width: "100%", display: "flex", alignItems: "flext-start"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='buildmatch_schedule_header_txt'>Number of rounds</div>
                          <div className='buildmatch_schedule_description_txt'>How many rounds of matches do you want to generate?</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                          <div style={{marginTop: "16px"}}>
                          <InputNumber style={{width:"100%"}} defaultValue={3} onChange={(e) => onchangeRounds(e)}/>
                            </div>
                          </div>
                          </div>

                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "16px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='buildmatch_schedule_header_txt'>Number of sets</div>
                          <div className='buildmatch_schedule_description_txt'>How many sets will teams play per round?</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                          <div style={{marginTop: "16px"}}>
                          <InputNumber style={{width: "100%"}} defaultValue={2} onChange={(e) => onChangeSets(e)}/>
                            </div>
                          </div>
                          </div>
            </div>

            { _.isEmpty(buttonRename) && <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "16px"}}>
            <button onClick={() => showModal()} className="build_match_schedule_generate_match_schedule_button"> <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5003 6.66666V13.3333M7.16699 9.99999H13.8337M18.8337 9.99999C18.8337 14.6024 15.1027 18.3333 10.5003 18.3333C5.89795 18.3333 2.16699 14.6024 2.16699 9.99999C2.16699 5.39762 5.89795 1.66666 10.5003 1.66666C15.1027 1.66666 18.8337 5.39762 18.8337 9.99999Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>Generate match schedule</button>
            </div>}

            {
              _.size(buttonRename) > 0 && <div style={{marginTop: "16px"}}><button onClick={() => showModal()}  className="build_match_schedule_regenerate_match_btn"> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.1667 1.66666L17.5 4.99999M17.5 4.99999L14.1667 8.33332M17.5 4.99999H6.5C5.09987 4.99999 4.3998 4.99999 3.86502 5.27247C3.39462 5.51216 3.01217 5.89461 2.77248 6.36501C2.5 6.89979 2.5 7.59986 2.5 8.99999V9.16666M2.5 15H13.5C14.9001 15 15.6002 15 16.135 14.7275C16.6054 14.4878 16.9878 14.1054 17.2275 13.635C17.5 13.1002 17.5 12.4001 17.5 11V10.8333M2.5 15L5.83333 18.3333M2.5 15L5.83333 11.6667" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>Regenerate match schedule</button></div>
            }
            
            <div 
            style={{
              // display: _.get(viewDetails, 'event_status') === 3 || _.get(viewDetails, 'event_status') === 4 ? "block" || getSocketData === false ? "block" : "none" : "none"
              }}>
            

           
            {(roundTables(getTableMatch))}
         

            <div style={{marginTop: "30px"}}>
                <div style={{display:"flex", flexDirection: "row"}}>
            </div>
                 <div>
     
      
      
    </div>
            </div>
            </div>
            <div>
                <Modal centered={true}
              title="Are you sure?"
              open={getOpenModal} 
              onOk={handleContinue} 
              onCancel={handleCancel}
              footer={[
                <Button style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}} onClick={() => handleCancel()}>Cancel</Button>,
                <Button style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}} onClick={() => handleContinue()}>Continue</Button>,
              ]}
              >
              <p>Do you  want to save the score</p>
              </Modal>
            </div>

              <Modal centered={true} title="Generate Matchup Schedule" open={isModalOpen} okText="Continue" cancelText="Cancel" onOk={handleOk} onCancel={handleCancelBuildMatch}>
        <p>Matches will be generated with the teams and users that are currently created and checked-in. You will still be able to assign users to teams manually, and they will be to see the set matches.</p>
      </Modal>
            <Modal centered={true} title="Reset" open={isResetModalOpen} okText="Continue" cancelText="Cancel" onOk={handleReset} onCancel={cancelResetModal}>
            <p>Resetting will clear all matches. This action cannot be undone. You will need to regenerated matches after this is complete.</p>
          </Modal>
            <Modal centered={true} title={getBoardCastSwitch === false ? "Boardcast Off":"Boardcast On"} open={isBoardCastSwitchModalOpen} okText="Continue" cancelText="Cancel" onOk={handleBoardCastOk} onCancel={handleBoardCastCancel}>
            {getBoardCastSwitch === false ? <p>Toggling off the broadcast will make it so that users cannot see their team and matches.</p> : <p>This will broadcast the teams and matches you have generated to all users.</p>}
          </Modal>
                </div>
            </div>
        );
    };
    return render();
};

const mapStateToProps = state => {
  return {
     checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
     isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
     islogindata: _.get(state, 'app.account.isLogin'),
     userProfile: _.get(state, 'app.host.profile.data.details'),
     guestUserProfile: _.get(state, 'app.guest.profile.data.details')

  }
};

const mapDispatchToProps = dispatch => ({
  // changeBuildTeamsEventCheckInAPI: details => dispatch(global.redux.action.league.leagueEventCheckIn(details)),
  changeLeagueEventStatusAPI: details => dispatch(global.redux.action.league.changeEventStatus(details)),
  viewLeagueEventsAPI: details => dispatch(global.redux.action.league.viewLeagueEvents(details)),
  leagueMatchesListAPI: details => dispatch(global.redux.action.league.leagueMatchesList(details)),
  updateMatchAPI: details => dispatch(global.redux.action.league.leagueUpdateMatch(details)),
  updateNewMatchAPI: details => dispatch(global.redux.action.league.leagueNewUpdateMatch(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BuildMatchSchedule)