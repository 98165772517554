import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Tag, Modal, Input, Select, Tabs, Rate, Progress, Image, Upload, Spin, notification, message, Divider, Segmented, Badge, Avatar as GroupAvatar, Tooltip } from 'antd';
import _ from 'lodash';
import { connect } from "react-redux";
import moment from "moment";



import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { motion } from "framer-motion";
import { IoCloseSharp } from "react-icons/io5";
import { FaFileLines } from "react-icons/fa6";
import chatKittyInstance from '../../../core_units/chat-system/chatkitty/instance';

import "../../chats/chats.css";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../chats/style.css";



import { ChatKitty } from "chatkitty-platform-sdk";

var parseHTML = require('html-react-parser').default;

// let url_channel = window.location.search.substring(15)
const ChatsAndNotification = (props) => {
  const { channelData } = props;
  const kitty = global.chatSystem.chatKitty.instance;
  const [showEmojiPicker, setshowEmojiPicker] = useState(false);
  const [messages, setMessages] = useState([]);

  const [chennalId, setchennalId] = useState("");
 
  const [messageInput, setMessageInput] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFileImage, setSelectedFileImage] = useState("");
  const [fileUploadProps, setfileUploadProps] = useState({});
  const [sendData, setSendData] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getChannelDetails, setChannelDetails] = useState({});
  const [getChangingChannelName, setChangingChannelName] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [getDeleteChannelDetails, setDeleteChannelDetails] = useState({});

  const [userStatus, setUserStatus] = useState({})
  const [getMembers, setMembers] = useState();
  const [getGuests, setGuests] = useState([]);
  const [MembersDetails, setMembersDetails] = useState([]);
const [IsEmptyList, setIsEmptyList] = useState(false)
  const [reInvokeChatList, setReInvokeChatList] = useState();
  const [getDeleted, setDeleted] = useState()

  const state_location  = useLocation();
  let navigate = useNavigate();

useEffect(() => {
  
  if(channelData?.id){
    setchennalId(channelData)
  }
 
}, [channelData]);
  
  useEffect(() => {
    if (
      _.get(props, "chatKittyCurrentUserData.id") &&
      chennalId
    ) {
      const startChatSessionResult = kitty.startChatSession({
        channel: chennalId ,
        onMessageReceived: (message) => {
         
          setMessages((currentMessages) =>
            [...currentMessages, mapMessage(message)])
        },
       
      });
      if (startChatSessionResult.succeeded) {
        const session = startChatSessionResult.session; // Handle session
      }
      
      if (startChatSessionResult.failed) {
        const error = startChatSessionResult.error; // Handle error
      }

      kitty
        .listMessages({
          channel: chennalId ? chennalId : _.get(state_location, "state.data"),
        })
        .then((result) => {
          setMessages(_.reverse(result.paginator.items.map(mapMessage)));
         
        });

        return startChatSessionResult.session.end;
    }
  }, [
    chennalId
  ]);
  useEffect(() => {
    async function getMembers(){
     let countMember = await kitty.listChannelMembers({channel:chennalId})
     setMembers(_.size(_.get(countMember,'paginator.items')))
     setMembersDetails(_.get(countMember,'paginator.items'))
    }
   if(_.get(chennalId,'type')=="PRIVATE"){
     getMembers()
   }
    
   }, [chennalId])

  async function handleSend(pendingMessages, file) {
    setMessageInput("");
    setSendData(true);
    if(!_.isEmpty(selectedFile)){
      const result = await kitty.sendMessage({
        channel: chennalId ? chennalId : _.get(state_location, "state.data"),
        body: pendingMessages.replace('<br>', ''),
        file: selectedFile,
        properties:fileUploadProps
      });
      if (result.succeeded) {
        setSelectedFile("");
        setfileUploadProps({})
        const message = result.message; // Handle message
      }
      
      if (result.failed) {
        setSelectedFile("");
        setfileUploadProps({})
        const error = result.error; // Handle error
      }
    }else{
      const result = await kitty.sendMessage({
        channel: chennalId ? chennalId : _.get(state_location, "state.data"),
        body: pendingMessages.replace('<br>', ''),
        properties:fileUploadProps
      });
      if (result.succeeded) {
        setSelectedFile("");
        setfileUploadProps({})
        const message = result.message; // Handle message
      }
      
      if (result.failed) {
        setSelectedFile("");
        setfileUploadProps({})
        const error = result.error; // Handle error
      }

    }
    
    
   
  }

  function mapMessage(message) {
    return {
      _id: message.id,
      text: message.body,
      file: _.get(message, "file.url"),
      createdAt: message.createdTime,
      user: mapUser(message.user),
      properties: message?.properties
    };
  }

  function mapUser(user) {
    return {
      _id: user.id,
      name: user.displayName,
      avatar: user.displayPictureUrl,
    };
  }


  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 50);
  };

  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    // reader.addEventListener('load', () => callback(reader.result));
    reader.onload = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(img);
  };

  const onChangePhoto = async (info) => {
    setMessageInput(false);
    getBase64(info.file.originFileObj, (imageUrl) => {
      let size = _.get(info,'file.size') /(1024*1024) 
      setfileUploadProps({
        name:_.get(info,'file.name'),
        size:size>0.9?`${_.round(size,2)} mb`:`${_.round(_.get(info,'file.size') /1024,2) } kb`
      })
      setSelectedFile(info.file.originFileObj);
      setSelectedFileImage(imageUrl);
    });
  };

  function beforeUpload(file) {
    const isLt10M = file.size / 1024 / 1024 <= 10;

    if (!isLt10M) {
      setSelectedFile({});
      message.error("File must be smaller than 10MB!");
    } else {
    }
    // return false;
  }
  const showModal = (value) => {
    setChannelDetails(value);
    setChangingChannelName(_.get(value, "displayName"));
    setIsModalOpen(true);
  };
  const openChat = async (id) => {
    // 
    if (id) {
      const result = await chatKittyInstance.retrieveChannel(id);
      
      if (result.succeeded) {
        
        const channel = result.channel; // Handle channel
      

        
    }

  };

}
  const handleOk = async () => {
    setReInvokeChatList();
    const chatkitty = new ChatKitty({
      clientId: "44b115e1-24db-4633-a7d3-fc1577545ed6",
      clientSecret: "4f458053-91c5-4dd1-aebc-ff710112262e",
    });

    try {
      const response = await chatkitty.Channels.updateChannel(
        _.get(getChannelDetails, "id"),
        {
          displayName: getChangingChannelName,
        }
      );

      if (response.status === 200) {
        openChat(_.get(getChannelDetails, 'id'))
        setReInvokeChatList(_.get(getChannelDetails, 'id'));
        setIsModalOpen(false);
        notification.success({
          message: "Success",
          description: "Name updated successfully.",
        });
      } else {
        // Handle other status codes if needed
        notification.error({
          message: "Error",
          description: "Something went wrong please try again.",
        });
      }
    } catch (error) {
      // Handle any error that occurred during the API request
      console.error(
        "An error occurred during the ChatKitty API request:",
        error
      );
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChangeChannelName = (value) => {
    setChangingChannelName(value);
  };

  const onShowDeleteModal = (value) => {
    setDeleteChannelDetails(value);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async (value) => {
    const chatkitty = new ChatKitty({
      clientId: "44b115e1-24db-4633-a7d3-fc1577545ed6",
      clientSecret: "4f458053-91c5-4dd1-aebc-ff710112262e",
    });

    try {
      const response = await chatkitty.Channels.deleteChannel(
        _.get(getDeleteChannelDetails, "id"),
        {
          channel: getDeleteChannelDetails,
        }
      );

      if (response.status === 200) {
        setDeleted( _.get(getDeleteChannelDetails, "id"))
        setIsDeleteModalOpen(false);
        notification.success({
          message: "Success",
          description: "Deleted successfully",
        });
      } else {
        // Handle other status codes if needed
        notification.error({
          message: "Error",
          description: "Something went wrong please try again.",
        });
      }
    } catch (error) {
      // Handle any error that occurred during the API request
      console.error(
        "An error occurred during the ChatKitty API request:",
        error
      );
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const onChangeMessageInput = (value) => {
    setMessageInput(value);
    // setDisable(false)
  };

  
  function getTimeAgo(date) {
    const currentDate = moment();
    const providedDate = moment(date);

    const difference = currentDate.diff(providedDate, 'seconds');
    const minutes = Math.floor(difference / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        if (days <= 7) {
            return  providedDate.format("ddd h:mm a");
        } else {
            return providedDate.format("ddd h:mm a");
        }
    } else if(hours>2 && moment(date).format("DD.MM.YYYY")==moment().format("DD.MM.YYYY")){
      return `Today ${providedDate.format("h:mm a")}`;
    }
    else if (hours > 0) {
        if (hours === 1 && minutes <= 0) {
            return minutes === 1 ? "1 min ago" : `${minutes} mins ago`;
        } else if (hours === 1 && minutes > 0) {
            return `Today ${providedDate.format("h:mm a")}`;
        } else {
            return hours === 1 ? "1 hr ago" : `${hours} hrs ago`;
        }
    } else if (minutes > 0) {
        return minutes === 1 ? "1 min ago" : `${minutes} mins ago`;
    } else {
        return difference <= 10 ? "Just now" : `${difference} sec ago`;
    }
}



  const getProfileImage=(channel, userId)=>{
      // if (channel?.type === 'DIRECT') {
        let filterImage = _.filter(_.get(channel,"members"),(v)=>v?.id==userId)
  
  
        if(_.size(filterImage)>0 && _.get(_.head(filterImage),"displayPictureUrl") && !`${_.get(_.head(filterImage),"displayPictureUrl")}`.includes("default.png")){
          return  {image:_.get(_.head(filterImage),"displayPictureUrl"),type:"image"}
        } else {
          
          let split = `${_.get(_.head(filterImage),"displayName")}`.split(' ')
          
        if(_.size(split)==1){
          return {name:`${split[0]}`.substring(0, 2).toUpperCase(),type:"name"}
        }else{
          return {name:`${split[0][0]}${split[1][0]}`.toUpperCase(),type:"name"} 
        }
      
        }
  // }
    }
  const getUserActiveStatus =(channel, userId,type)=>{
    let filterUser = _.filter(_.get(channel,"members"),(v)=>v?.id==userId )
  if(type=="name"){
  return _.get(_.head(filterUser),'displayName')
  }else{
    return _.get(_.head(filterUser),'presence.online')
  }
    
  }

  const convertSizeOfBubble =(message,userName,time)=>{
let messageLength = `${message}`.length
let userNameLength = `${userName+time}`.length

if(messageLength<=10 &&userNameLength <10){
  return "19%"
}else if(messageLength<=10 &&userNameLength >=10 && userNameLength<=15){
  return "20%"
}else if(messageLength<=10 &&userNameLength >=10 && userNameLength<=25){
  return "23%"
}else if(messageLength<=10 &&userNameLength >=15 && userNameLength<=30){
  return "32%"
}else if(messageLength<=10 &&userNameLength >=15 && userNameLength<=35){
  return "32%"
}else if(messageLength<=30 &&messageLength>=10 ){
  return "29%"
}else if(messageLength<=60 &&messageLength>=30 ){
  return "35%"
}else {
  return "70%"
}

  }


  
  const commonMessageRender =(chennelDetails,messageDetails,direction,index)=>{
    let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
let isValid = regexForHTML.test(`${messageDetails?.text}`);

    let chennelExtract = {...chennelDetails};

    chennelExtract['members']=_.get(chennelDetails,"type")=="PRIVATE"?MembersDetails :_.get(chennelDetails,"members")
    let chatWidth =convertSizeOfBubble(messageDetails?.text,direction==false?_.startCase(getUserActiveStatus(chennelExtract, messageDetails?.user?._id,'name')):"You",getTimeAgo(_.get(messageDetails, 'createdAt')))

    return <div style={{display:'flex',alignItems:'start',marginBottom:'16px',marginTop:index==0?"16px":"0px",width:'100%',justifyContent:direction==false?"flex-start": 'flex-end'}}>
      {direction==false && <div style={{marginRight:'12px'}}>
      {getProfileImage(chennelExtract, messageDetails?.user?._id)?.type=="name"?
            <div style={{}} className='chat-image-name'>
              { getProfileImage(chennelExtract, messageDetails?.user?._id)?.name}</div>:
              <div style={{width:'40px',height:'40px',borderRadius:'100%'}}>
                <Image preview={false} style={{width:'40px',height:'40px',borderRadius:'100%'}} 
            src={getProfileImage(chennelExtract, messageDetails?.user?._id)?.image} /></div>
  
          }
  
      </div>}
      {direction==false &&
      getUserActiveStatus(chennelExtract, messageDetails?.user?._id)==true &&
      <div className='active-user-chat' style={{marginTop:'26px'}} />}
      <div style={{width:chatWidth}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
          <span className="message-list-chat-username">{direction==false?_.startCase(getUserActiveStatus(chennelExtract, messageDetails?.user?._id,'name')):"You"}</span> 
          <span className="message-list-chat-time">{getTimeAgo(_.get(messageDetails, 'createdAt'))}</span></div>
        <div className={direction==false?"message-list-user-text-container":"message-list-user-text-container-active"} style={{width:`${messageDetails?.text}`.length<9?"100%":'100%',marginTop:'6px'}}>
        <span dangerouslySetInnerHTML={{__html: messageDetails?.text}} className="message-list-user-text" style={{color:direction==false?"var(--Gray-900, #101828)" :"var(--Base-White, #FFF)"}}/>
        </div>
      </div>
  
    </div>
  
  }
  
  const commonFileRender =(chennelDetails,messageDetails,direction,index,image_src,fileType) =>{
    let chennelExtract = {...chennelDetails};
    let fileProps = !_.isEmpty(_.get(messageDetails,"properties"))?_.get(messageDetails,"properties"):{name:`${_.startCase(fileType)} attachment`,size:""}
    chennelExtract['members']=_.get(chennelDetails,"type")=="PRIVATE"?MembersDetails :_.get(chennelDetails,"members")
    return <motion.div key={index} layout initial={{opacity: 0, scale: 1, y: 50,x: 0, }}
    animate={{opacity:1,scale:1,y:0,x:0}} exit={{opacity:0,scale:1,y:1,x:0}}
    transition={{opacity:{duration: 0.1}, layout:{type:"spring",bounce: 0.3,
    duration:messages.indexOf(messageDetails) * 0.05 +0.2 }}} style={{originX:0.5,originY:0.5,}}> <div style={{display:'flex',alignItems:'start',marginBottom:'16px',marginTop:index==0?"16px":"0px",width:'100%',justifyContent:direction==false?"flex-start": 'flex-end'}}>
      {direction==false && <div style={{marginRight:'12px'}}>
      {getProfileImage(chennelExtract, messageDetails?.user?._id)?.type=="name"?
            <div style={{}} className='chat-image-name'>
              { getProfileImage(chennelExtract, messageDetails?.user?._id)?.name}</div>:
              <div style={{width:'40px',height:'40px',borderRadius:'100%'}}>
                <Image preview={false} style={{width:'40px',height:'40px',borderRadius:'100%'}} 
            src={getProfileImage(chennelExtract, messageDetails?.user?._id)?.image} /></div>
  
          }
  
      </div>}
      {direction==false &&
      getUserActiveStatus(chennelExtract, messageDetails?.user?._id)==true &&
      <div className='active-user-chat' style={{marginTop:'26px'}} />}
      <div >
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
          <span className="message-list-chat-username">{direction==false?_.startCase(getUserActiveStatus(chennelExtract, messageDetails?.user?._id,'name')):"You"}</span> 
          <span className="message-list-chat-time">{getTimeAgo(_.get(messageDetails, 'createdAt'))}</span></div>
        {fileType=="image"? <div style={{borderRadius:'8px'}}><Image
                                      width={200}
                                      style={{borderRadius:'8px'}}
                                      src={image_src}
                                      /></div>:<div className={direction==false?"message-list-user-text-container":"message-list-user-text-container-active"} style={{marginTop:'6px'}}>
       <div style={{display:'flex',alignItems:'center'}}>
        <div >
        <Image  style={{width:'32px',height:'32px'}} preview={false} src={fileType=="mp4"? require('../../../assets/img/file-video.png'):require('../../../assets/img/file-doc.png')}/>
        </div>
        <div>
          <span className='media_files_txt' style={{display: "flex",alignItems: "center",  color:direction==false ? "#344054" : "#FFFFFF"}}>{fileProps?.name}</span>
         {fileProps?.size && <span className="media_files_txt" style={{color:direction==false ? "#344054" : "#FFFFFF",fontSize:'12px'}}>{fileProps?.size}</span>}
        </div>
       <div style={{marginLeft: "20px"}}>
                                      <a
                                      href={image_src}
                                      target="_blank"
                                      download
                                      className='chat_media_pdf_download_btn'
                                      >
                                      <div>
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M2.01677 10.5946C1.90328 10.4149 1.84654 10.3251 1.81477 10.1865C1.79091 10.0824 1.79091 9.91824 1.81477 9.81415C1.84654 9.67556 1.90328 9.58571 2.01677 9.40601C2.95461 7.92103 5.74617 4.16699 10.0003 4.16699C14.2545 4.16699 17.0461 7.92103 17.9839 9.40601C18.0974 9.58571 18.1541 9.67556 18.1859 9.81415C18.2098 9.91824 18.2098 10.0824 18.1859 10.1865C18.1541 10.3251 18.0974 10.4149 17.9839 10.5946C17.0461 12.0796 14.2545 15.8337 10.0003 15.8337C5.74617 15.8337 2.95461 12.0796 2.01677 10.5946Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.0003 12.5003C11.381 12.5003 12.5003 11.381 12.5003 10.0003C12.5003 8.61961 11.381 7.50033 10.0003 7.50033C8.61962 7.50033 7.50034 8.61961 7.50034 10.0003C7.50034 11.381 8.61962 12.5003 10.0003 12.5003Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                                        </span>
                                        <span className='file-view-btn' style={{marginLeft:'8px'}}>View</span>
                                      </div>
                                      </a>
                                      </div>
       </div>
        </div>}
      </div>
  
    </div></motion.div>
  }
  
  const commonPreviewFile =()=>{
    if(_.get(selectedFile,"name")){
      return `${_.get(selectedFile,"type")}`.includes("image") ?<div  style={{marginBottom:'10px',width:'100px',height:'100px'}}>
  <div style={{marginBottom:'10px',width:'100px',height:'100px'}}>
  <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer'}}
  onClick={()=>{
    setSelectedFile("")
  }}
  ><IoCloseSharp /></div>
    <div style={{width:'100px',height:'60px'}}><Image  style={{width:'100px',height:'80px'}} src={selectedFileImage} /></div>
  </div>
      </div>:<div style={{marginBottom:'10px',width:'50px'}}>
  <div>
  <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer'}} onClick={()=>{
    setSelectedFile("")
  }}><IoCloseSharp /></div>
    <div><FaFileLines  size={45} color="#EF6820"/></div>
  </div>
      </div>
    }
  }

  const guestNameSplit = (value) => {
    let name = value.toUpperCase().split(" ") // Split the name into an array of parts
    .map(part => part.charAt(0)) // Get the first character of each part
    .join("");
    
    return <div className='avatar_in_display_name'>{name}</div>
    };
    
    const handleGuestList = () => {
    if(chennalId && chennalId?.type=="DIRECT"){
    let current_guest = _.get(chennalId, 'members');
    const chennal_filter_guest = current_guest.filter((item) => item.id !== _.get(props,'chatKittyCurrentUserData.id'));
    setGuests(chennal_filter_guest[0].properties.id)
    }
    };
    
    const handleViewAccount = () => {
    navigate(`/communiti/view-account?guest_id=${getGuests}&menu=reports`)
    }
    
    const filterGuestName = (value) => {
    let guestArray = _.get(value, 'members');
    const filter_name = guestArray.filter((item) => item.id != _.get(props,'chatKittyCurrentUserData.id'));
    return filter_name[0].displayName
    };
    
    const guestDisplayPicture = (value) => {
    let guestPic = _.get(value, 'members');
    const filter_pic = guestPic.filter((item) => item.id !== _.get(props,'chatKittyCurrentUserData.id'));
    return filter_pic[0].displayPictureUrl
    }
    
    const render = () => {
      return (
        <>
            <div
              
              style={{ backgroundColor: "#FFF" }}
            >
              <div >
             
                {
                <div>
                  {_.get(props, "chatKittyCurrentUserData.id") && (
                   <Spin size="large" spinning={pageLoading} className='empty-chat-loading-spinner'>
               <div style={{  width: "100%" }}>
                    
                        <div
                          style={{
                            marginBottom: "16px",
                            // width: "70%",
                            marginLeft: "12px",
                           
                          }}
                        >{chennalId  &&<>
                           <div style={{display: "flex", flexDirection: "row"}}>
<div className='custome_avator_img' style={{display: "flex", position: 'relative'}}>{_.get(chennalId, 'type') === "PRIVATE" ? <img style={{height: "56px", width: "56px"}} src={require('../../../assets/img/group_chat_image.png')}/> : _.get(chennalId, 'type') === 'DIRECT' && guestDisplayPicture(chennalId).includes("default.png") ? guestNameSplit(filterGuestName(chennalId)) : <img style={{height: "56px", width: "56px", borderRadius: "200px"}} src={chennalId ? `${guestDisplayPicture(chennalId)}?time=${new Date()}` : `${_.get(state_location, 'state.data.creator.displayPictureUrl')}?time=${new Date()}`}/>}
{_.get(chennalId, 'type') === "DIRECT" && _.get(userStatus, 'presence.online') === true && <div style={{
borderRadius: "5px",
border: "1.5px solid var(--Base-White, #FFF)",
background: "var(--Success-500, #12B76A)",
width: "10px",
height: "10px",
position: "absolute",
marginLeft: "29px",
marginTop: "-10px",
bottom: 5,
right: 5
}}></div>}
</div>
<div style={{display: "flex", flexDirection: "column", marginLeft: "16px", justifyContent: "center"}}>
<div className='conv_header_title' style={{width:'100%'}}>{ chennalId ? (_.get(chennalId,'type')=="PRIVATE" ?
<Tooltip title={_.get(chennalId, 'displayName')}>{_.get(chennalId, 'displayName')}</Tooltip>: 
<Tooltip title={filterGuestName(chennalId)}>{_.startCase(filterGuestName(chennalId))}</Tooltip>) : 
<Tooltip title={_.get(state_location, 'state.data.creator.displayName')}>{_.get(state_location, 'state.data.creator.displayName')}</Tooltip>}</div>
<div className='conv_header_supporting_txt'>{ _.get(chennalId,'type')=="PRIVATE" ? `Group chat with ${getMembers} participants` : ''}</div>
</div>
<div style={{marginLeft: "auto", display: "flex", alignItems: "center", justifyContent: "center"}}>
{_.get(chennalId, 'type') === 'DIRECT' && !_.isEmpty(getGuests) && <button onClick={() => handleViewAccount()} style={{marginLeft: "8px"}} className='view_account_btn'>View account</button>}
{/* {_.get(chennalId, 'type') === 'PRIVATE' && <button onClick={() => showModal(chennalId)} className='edit_group_btn'>Edit</button>}
{_.get(chennalId, 'type') === 'PRIVATE' && <button onClick={() => onShowDeleteModal(chennalId)} style={{marginLeft: "8px"}} className='delete_group_btn'>Delete group </button>} */}
</div>
</div>
<hr/></>}
                          <div className="chatBoxContainer">
                           
                           {chennalId ? 
                           
                           <MainContainer>
                            
                              <ChatContainer>
                            
                                <MessageList>
                                  {_.map(_.uniqBy(messages,"_id"), (value, index) => {
                                    
                                    let image_src = _.get(value, "file");
                                    
                                    if (image_src) {
                                      const getFileType = (link) => {
                                      const queryStringIndex = link.indexOf('?'); // Find the index of the query string
                                      if (queryStringIndex !== -1) {
                                      link = link.substring(0, queryStringIndex); // Remove the query string
                                      }
                                      const extension = link.split('.').pop().toLowerCase();
                                      if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                                      return 'jpg';
                                      } else if (extension === 'pdf') {
                                      return 'pdf';
                                      } else if (extension === 'doc'){
                                      return 'doc';
                                      } else if (extension === 'docx'){
                                      return 'docx';
                                      } else if (extension === 'mp4'){
                                      return 'mp4';
                                      } else {
                                      return 'unknown';
                                      }
                                      };
                                      const link = image_src;
                                      const fileType = getFileType(link);
                                     
                                      if(fileType === 'pdf'){
                                      return   <div >
                                        {commonFileRender(chennalId,value,_.get(props, 'chatKittyCurrentUserData.id') == _.get(value, 'user._id'),index,image_src,"pdf")}
                                      
                                      </div>
                                      } else if(fileType === 'doc'){
                                      return <div >
                                      {commonFileRender(chennalId,value,_.get(props, 'chatKittyCurrentUserData.id') == _.get(value, 'user._id'),index,image_src,"doc")}
                                    
                                    </div>
                                      } else if(fileType === 'docx'){
                                      return <div >
                                      {commonFileRender(chennalId,value,_.get(props, 'chatKittyCurrentUserData.id') == _.get(value, 'user._id'),index,image_src,"docx")}
                                    
                                    </div>
                                      } else if(fileType === 'mp4'){
                                      return <div >
                                      {commonFileRender(chennalId,value,_.get(props, 'chatKittyCurrentUserData.id') == _.get(value, 'user._id'),index,image_src,"mp4")}
                                    
                                    </div>
                                      } else if(fileType === 'jpg'){
                                      return <div >
                                      {commonFileRender(chennalId,value,_.get(props, 'chatKittyCurrentUserData.id') == _.get(value, 'user._id'),index,image_src,"image")}
                                    
                                    </div>
                                      }
                                      } else {
                                      return <motion.div key={index} layout initial={{opacity: 0, scale: 1, y: 50,x: 0, }}
                                      animate={{opacity:1,scale:1,y:0,x:0}} exit={{opacity:0,scale:1,y:1,x:0}}
                                      transition={{opacity:{duration: 0.1}, layout:{type:"spring",bounce: 0.3,
                                      duration:messages.indexOf(value) * 0.02 +0.1 }}} style={{originX:0.5,originY:0.5,}}><div>
                                     
                                      <div style={{display: "flex", flexDirection: "row"}}>
                                        {commonMessageRender(chennalId,value,_.get(props, 'chatKittyCurrentUserData.id') == _.get(value, 'user._id'),index)}
                                      
                                      </div>
                                      </div></motion.div>
                                      }
                                      
                                      
                                  })}
                                </MessageList>
                                {chennalId && (
                                  <div
                                    as={MessageInput}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      // marginTop:"10px"
                                    }}
                                  >
                                    
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                      }}
                                    >
                                      
                                      {showEmojiPicker && (
                                        <div className="chatkitty-emoji-picker-container">
                                          {" "}
                                          <Picker
                                            previewPosition="none"
                                            onClickOutside={() =>
                                              setshowEmojiPicker(false)
                                            }
                                            navPosition="bottom"
                                            theme="light"
                                            data={data}
                                            onEmojiSelect={(e) => {
                                              setMessageInput(
                                                messageInput + e?.native
                                              );
                                            }}
                                          />
                                        </div>
                                      )}
                                      {commonPreviewFile()}
                                      <div className="chatkitty-input-container">
                                        
                                        <div style={{ width: "100%" }}>
                                          <MessageInput
                                            placeholder="Send a message"
                                            onSend={ async() =>
                                             await handleSend(
                                                messageInput
                                                  ? messageInput
                                                  : selectedFile
                                              )
                                            }
                                            onChange={(messagesInput) =>
                                              onChangeMessageInput(messagesInput)
                                            }
                                            value={messageInput}
                                            attachButton={false}
                                            sendButton={false}
                                            style={{
                                              // flexGrow: 1,
                                              borderTop: 0,
                                              // flexShrink: "initial",
                                              // paddingTop: "2.5vh",
                                              // paddingBottom: "2.5vh",
                                              // border:'none'
                                            }}
                                            className="chatkitty-message-input-container"
                                          />
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                marginRight: "10px",
                                              }}
                                              onClick={() => {
                                                setshowEmojiPicker(true);
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                              >
                                                <g clip-path="url(#clip0_10764_525)">
                                                  <path
                                                    d="M6.66675 11.667C6.66675 11.667 7.91675 13.3337 10.0001 13.3337C12.0834 13.3337 13.3334 11.667 13.3334 11.667M12.5001 7.50033H12.5084M7.50008 7.50033H7.50842M18.3334 10.0003C18.3334 14.6027 14.6025 18.3337 10.0001 18.3337C5.39771 18.3337 1.66675 14.6027 1.66675 10.0003C1.66675 5.39795 5.39771 1.66699 10.0001 1.66699C14.6025 1.66699 18.3334 5.39795 18.3334 10.0003ZM12.9167 7.50033C12.9167 7.73044 12.7302 7.91699 12.5001 7.91699C12.27 7.91699 12.0834 7.73044 12.0834 7.50033C12.0834 7.27021 12.27 7.08366 12.5001 7.08366C12.7302 7.08366 12.9167 7.27021 12.9167 7.50033ZM7.91675 7.50033C7.91675 7.73044 7.7302 7.91699 7.50008 7.91699C7.26996 7.91699 7.08341 7.73044 7.08341 7.50033C7.08341 7.27021 7.26996 7.08366 7.50008 7.08366C7.7302 7.08366 7.91675 7.27021 7.91675 7.50033Z"
                                                    stroke="#667085"
                                                    stroke-width="1.66667"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_10764_525">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </div>
                                            <div style={{ marginRight: "10px",cursor:'pointer' }}>
                                              <Upload
                                                accept=".jpg,.jpeg,.pdf,.doc,.docx,.png,video/*"
                                                name="avatar"
                                                style={{
                                                  fontSize: "1.2em",
                                                  marginLeft: 0,
                                                  paddingLeft: "0.2em",
                                                  paddingRight: "0.2em",
                                                }}
                                                className="avatar-uploader-attachment"
                                                showUploadList={false}
                                                onChange={onChangePhoto}
                                                maxCount={1}
                                                multiple={false}
                                                beforeUpload={beforeUpload}
                                                customRequest={dummyRequest}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M14.5834 4.38017V13.7503C14.5834 16.2816 12.5314 18.3337 10.0001 18.3337C7.46878 18.3337 5.41675 16.2816 5.41675 13.7503V4.72255C5.41675 3.03501 6.78477 1.66699 8.4723 1.66699C10.1598 1.66699 11.5279 3.03501 11.5279 4.72255V13.7051C11.5279 14.5489 10.8439 15.2329 10.0001 15.2329C9.15631 15.2329 8.4723 14.5489 8.4723 13.7051V5.54296"
                                                    stroke="#667085"
                                                    stroke-width="1.66667"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </Upload>
                                            </div>
                                            <div
                                              className="send-button-container"
                                              style={{ cursor: "pointer" }}
                                              // disabled={getDisable === true ? true : false}
                                              onClick={() => {
                                                if (messageInput.length > 0) {
                                                  handleSend(
                                                    messageInput
                                                      ? messageInput
                                                      : selectedFile
                                                  );
                                                } else if (selectedFile) {
                                                  handleSend(selectedFile);
                                                }
                                              }}
                                            >
                                              <div>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M8.75036 10.0003H4.16702M4.09648 10.2432L2.15071 16.0555C1.99785 16.5121 1.92142 16.7404 1.97627 16.881C2.0239 17.0031 2.1262 17.0957 2.25244 17.1309C2.3978 17.1715 2.61736 17.0727 3.05647 16.8751L16.9827 10.6083C17.4113 10.4154 17.6256 10.319 17.6918 10.185C17.7494 10.0686 17.7494 9.93203 17.6918 9.81564C17.6256 9.68168 17.4113 9.58524 16.9827 9.39237L3.05161 3.12342C2.61383 2.92641 2.39493 2.82791 2.24971 2.86832C2.1236 2.90341 2.0213 2.99573 1.97351 3.1176C1.91847 3.25793 1.99408 3.48574 2.14531 3.94137L4.09702 9.82159C4.12299 9.89984 4.13598 9.93897 4.14111 9.97899C4.14565 10.0145 4.14561 10.0504 4.14097 10.0859C4.13574 10.1259 4.12265 10.165 4.09648 10.2432Z"
                                                    stroke="white"
                                                    stroke-width="1.66667"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                                <span
                                                  style={{
                                                    color:
                                                      "var(--Base-White, #FFF)",
                                                    fontSize: "14px",
                                                    fontWeight: 600,
                                                    marginLeft: "8px",
                                                  }}
                                                >
                                                  Send
                                                </span>
                                              </div>
                                            </div>
  
                                            {/* <SendButton disabled={getDisable === true ? true : false} onClick={() => handleSend(messageInput ? messageInput : selectedFile)} style={{
                                    fontSize: "1.2em",
                                    marginLeft: 0,
                                    paddingLeft: "0.2em",
                                    paddingRight: "0.2em",
                                    color: _.size(messageInput) > 0 || _.size(selectedFile) > 0 ? "#e86537" : "#ccc"
                                  }} /> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </ChatContainer>
                            </MainContainer>:
                            pageLoading==false &&
                            <div style={{display:'flex',justifyContent:'center',flexDirection:'column',height:'550px'}}>
                              <div style={{display:'flex',justifyContent:'center'}} >
                                <Image style={{width:'152px',height:"118px"}} preview={false} src={require('../../../assets/img/message-empty.png')}/>
  
                              </div>
                              <div style={{display:'flex',flexDirection:'column'}}>
                                <span className="chat-empty-text-title">
                                Select a conversation
                                </span>
                                <span className="chat-empty-text-title-des">
                                Choose between individual and group chats. 
                                </span>
                              </div>
                              </div>}
                          </div>
                        </div>
                      </div>
                    </Spin>
                  )}
                </div>}
              </div>
              <Modal
                centered={true}
                title="Edit"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Input
                  style={{ borderRadius: "12px" }}
                  defaultValue={getChangingChannelName}
                  value={getChangingChannelName}
                  onChange={(e) => onChangeChannelName(e.target.value)}
                />
              </Modal>
              <Modal
                centered={true}
                title="Delete"
                open={isDeleteModalOpen}
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}
                okText="Delete this chat"
              >
                <p>
                  Are you sure you wish to delete this chat? This action cannot be
                  undone.
                </p>
              </Modal>
            </div>
         
        </>
      );
    };
    return render();
  };
  
  const mapStateToProps = (state) => {
    return {
      chatKittyCurrentUserData: _.get(
        state,
        `app.settings.chatKittyCurrentUserData`,
        {}
      ),
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    ratingListApi: (details) =>
      dispatch(global.redux.action.finances.ratingList(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(ChatsAndNotification);