import React, {useEffect, useState} from 'react';
import { Form, Input, Upload,Button, message, Row, Col, Image, notification } from "antd";
import { InboxOutlined, FileImageOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FiUploadCloud } from "react-icons/fi";

import kitty from '../../core_units/chat-system/chatkitty/instance';
import "./style.css";

const { Dragger } = Upload;

const MediaImages = (props) => {
    const [form] = Form.useForm();
    const uploadButton = (
      <div>
         <FileImageOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    const [mediaData, setMediaData] = useState({});
    const [photo_display, setphoto_display] = useState(null);
    const [photo_display_details, setphoto_display_details] = useState({});
    const [logo_photo_display, setlogo_photo_display] = useState(null);
    const [logo_photo_display_details, setlogo_photo_display_details] = useState({});
    const [banner_photo_display, setbanner_photo_display] = useState(null);
    const [banner_photo_display_details, setbanner_photo_display_details] = useState({});

    useEffect(() => {
      getMediaProfile();
    }, []);

    const chartkittyuserupdate = async(data) =>{
    
      await kitty.updateCurrentUserDisplayPicture({
         file: {
           url:  data.displayPictureUrl,
           name: data.display_name? data.display_name:`${data.first_name} ${data.last_name}`,
           contentType: 'image/jpeg',
           size: 123115,
         },
       });
      return kitty.updateCurrentUser((user) => {
             user.displayName = data.display_name? data.display_name:`${data.first_name} ${data.last_name}`
             user.displayPictureUrl =data.displayPictureUrl
             return user;
           });
           
     }

    const onMediaFinish = (e) => {
        // 
        if(!_.isEmpty(photo_display_details)){
          updatePhoto(_.get(e,'photo'))
        };

        if(!_.isEmpty(logo_photo_display_details)){
          updateLogoPhoto(_.get(e,'logo_photo'))
        };

        if(!_.isEmpty(banner_photo_display_details)){
          updateBannerPhoto(_.get(e,'banner_photo'))
        };
    }
    const onMediaFinishFailed = (errorInfo) => {
        
    }

    const getMediaProfile = () => {
      props.hostProfileData({
        callback: (res, data) => {
          if(!_.isEmpty(_.get(data, 'data.details'))){
            setMediaData(_.get(data, 'data.details'));
            if(_.get(data, 'data.details.photo')){
              let photo_path =`${_.get(data, 'data.meta.photo.path')}/${_.get(data, 'data.meta.photo.folder')}/${_.get(data, 'data.meta.photo.sizes.medium')}/${_.get(data, 'data.details.photo')}?time=${new Date().getTime()}`
              setphoto_display(photo_path)
              if(_.get(data, 'data.details.meta_details.logo')){
                let logo_photo_path =`${_.get(data, 'data.meta.photo.path')}/${_.get(data, 'data.meta.photo.folder')}/${_.get(data, 'data.meta.photo.sizes.medium')}/${_.get(data, 'data.details.meta_details.logo')}?time=${new Date().getTime()}`
                setlogo_photo_display(logo_photo_path);
              }
              if(_.get(data, 'data.details.meta_details.banner')){
                let banner_photo_path =`${_.get(data, 'data.meta.photo.path')}/${_.get(data, 'data.meta.photo.folder')}/${_.get(data, 'data.meta.photo.sizes.medium')}/${_.get(data, 'data.details.meta_details.banner')}?time=${new Date().getTime()}`
                setbanner_photo_display(banner_photo_path)
              }
            }
        }
        }
      })
    };

    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
          onSuccess("ok");
      }, 50);
  };
    const getBase64 = async (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
  };

  const getLogoBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
    const getBannerBase64 = async (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    };

    const onChangePhoto = async info => {
    
      getBase64(info.file.originFileObj, (imageUrl) => {
        setphoto_display_details(info.file)  
        setphoto_display(imageUrl);
      });
  
};

const updatePhoto = (data) => {
  return new Promise(resolve => {
  if (_.get(photo_display_details, 'originFileObj')) {
    
      let formData = new FormData()
      formData.append('photo', _.get(photo_display_details, 'originFileObj'))
      props.UpdatePhotoData({
          body: formData,
          callback: (res, data) => {
            
              if (res == true) {
                notification.success({
                  message: "Success",
                  description: "Profile photo updated successfully"
                })
                let details =JSON.parse(_.get(data,'data'))
                
                let displayPictureUrl= `${_.get(mediaData, 'photo.path')}/${_.get(mediaData, 'photo.folder')}/${_.get(mediaData, 'photo.sizes.medium')}/${_.get(details, 'details[0].photo')}` 
                chartkittyuserupdate({..._.get(details,'details'),displayPictureUrl})
                getMediaProfile()
                   
              }
                  resolve();
          }
      });
  }
  else{
      resolve();
  }
})
};

const onChangeLogoPhoto = async info => {
    
  getLogoBase64(info.file.originFileObj, (imageUrl) => {
    setlogo_photo_display_details(info.file)  
    setlogo_photo_display(imageUrl);
  });

};

const updateLogoPhoto = (data) => {
  return new Promise(resolve => {
    if (_.get(logo_photo_display_details, 'originFileObj')) {
      
        let formData = new FormData()
        formData.append('photo', _.get(logo_photo_display_details, 'originFileObj'))
        props.UpdatePhotoData({
            params:{
              type: "logo"
            },
            body: formData,
            callback: (res, data) => {
              
                if (res == true) {
                  notification.success({
                    message: "Success",
                    description: "Logo image updated successfully"
                  })
                  getMediaProfile()
                     
                }
                    resolve();
            }
        });
    }
    else{
        resolve();
    }
  })
};

const onChangeBannerPhoto = async info => {
    
  getBannerBase64(info.file.originFileObj, (imageUrl) => {
    setbanner_photo_display_details(info.file)  
    setbanner_photo_display(imageUrl);
  });

};

const updateBannerPhoto = (data) => {
  return new Promise(resolve => {
    if (_.get(banner_photo_display_details, 'originFileObj')) {
      
        let formData = new FormData()
        formData.append('photo', _.get(banner_photo_display_details, 'originFileObj'))
        props.UpdatePhotoData({
            params:{
              type: "banner"
            },
            body: formData,
            callback: (res, data) => {
              
                if (res == true) {
                  notification.success({
                    message: "Success",
                    description: "Banner image updated successfully"
                  })
                  getMediaProfile()
                     
                }
                    resolve();
            }
        });
    }
    else{
        resolve();
    }
  })
};

    const render  = () => {
        return (
            <div>
                        <Form 
                            layout='vertical'
                            form={form}
                            onFinish={(e) => onMediaFinish(e)}
                            onFinishFailed={onMediaFinishFailed}
                            >
                           <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                            <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                              <div className='container-titles'>Avatar / Profile Image</div>
                              <div className='container-titles-desc'>Upload an avatar / profile image. This will be used in places such as chat.</div>
                              </div>
                              <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                              <Form.Item name='photo'>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Dragger
                                    accept=".jpg,.jpeg"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    onChange={onChangePhoto}
                                    customRequest={dummyRequest}
                                >
                                  <FiUploadCloud style={{ fontSize: '36px', color: '#EF6820' }} />
                                  <p style={{ marginTop: '8px' }}>Click or drag and drop</p>
                                </Dragger>
                                </Col>
                                <Col span={12}>
                                {photo_display && (<img  src={photo_display} alt="Profile" style={{ width: '100%',height:'100%', borderRadius: '20px' }} />)}
                                </Col>
                                </Row>
                                
                                 
                            </Form.Item>
                            
                            
                              </div>
                              </div>
                              <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                            <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                              <div className='container-titles'>Logo Image</div>
                              <div className='container-titles-desc'>Upload a logo image. This is used for branding on the top your Communiti page.</div>
                              </div>
                              <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                              <Form.Item name='logo_photo'>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Dragger
                                    accept=".jpg,.jpeg"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    onChange={onChangeLogoPhoto}
                                    customRequest={dummyRequest}
                                >
                                  <FiUploadCloud style={{ fontSize: '36px', color: '#EF6820' }} />
                                  <p style={{ marginTop: '8px' }}>Click or drag and drop</p>
                                </Dragger>
                                </Col>
                                <Col span={12}>
                                {logo_photo_display && (<img  src={logo_photo_display} alt="Logo" style={{ width: '100%',height:'100%', borderRadius: '20px' }} />)}
                                </Col>
                                </Row>
                                
                                 
                            </Form.Item>
                              </div>
                              </div>
                              <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                            <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                              <div className='container-titles'>Banner image</div>
                              <div className='container-titles-desc'>Upload a banner image. This will be used as the main display image when users visit your Communiti page.</div>
                              </div>
                              <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                              <Form.Item name='banner_photo'>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Dragger
                                    accept=".jpg,.jpeg"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    onChange={onChangeBannerPhoto}
                                    customRequest={dummyRequest}
                                >
                                  <FiUploadCloud style={{ fontSize: '36px', color: '#EF6820' }} />
                                  <p style={{ marginTop: '8px' }}>Click or drag and drop</p>
                                </Dragger>
                                </Col>
                                <Col span={12}>
                                {banner_photo_display && (<img  src={banner_photo_display} alt="Banner" style={{ width: '100%',height:'100%', borderRadius: '20px' }} />)}
                                </Col>
                                </Row>
                                
                                 
                            </Form.Item>
                            <Form.Item>
                            <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end"}}>
                            <Button style={{marginRight: "15px"}}>Cancel</Button>
                            <Button htmlType="submit" style={{backgroundColor: "#EF6820", color: "#fff"}}>Save changes</Button>
                            </div>
                          </Form.Item>
                              </div>
                              </div>
                            </Form>
        </div>
      )
    };

    return render();
  
}

const mapStateToProps = state => {
  return {
     
  }
}

const mapDispatchToProps = dispatch => ({
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
  UpdatePhotoData: details => dispatch(global.redux.action.host.updatePhoto(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MediaImages)