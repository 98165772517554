import React, { useState, useEffect } from 'react';
import { Checkbox, notification } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import './promo.css';

const AssignPromoCode = ({ data, userDetails, props, closeModal, promo_id }) => {
  
  const [checkedValues, setCheckedValues] = useState([]);
  const [experienceIds, setExperienceIds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultCheckedValues = [];
    data.forEach(parent => {
      if (parent.experiences) {
        parent.experiences.forEach(exp => {
          if (exp.coupons && exp.coupons.some(coupon => coupon.id === parseInt(promo_id))) {
            defaultCheckedValues.push(exp.id);
          }
        });
      }
    });
    setCheckedValues(defaultCheckedValues);
    handleChange(defaultCheckedValues);
  }, [data]);

  const handleCheckboxChange = (id, checked) => {
    let newCheckedValues = [...checkedValues];
    if (checked && !newCheckedValues.includes(id)) {
      newCheckedValues.push(id);
    } else {
      newCheckedValues = newCheckedValues.filter(value => value !== id);
    }
    setCheckedValues(newCheckedValues);
    handleChange(newCheckedValues);
  };

  const handleParentChange = (parent, checked) => {
    let newCheckedValues = [...checkedValues];
    if (checked) {
      if (parent.experiences) {
        parent.experiences.forEach(exp => {
          if (!newCheckedValues.includes(exp.id)) {
            newCheckedValues.push(exp.id);
          }
        });
      }
    } else {
      if (parent.experiences) {
        parent.experiences.forEach(exp => {
          newCheckedValues = newCheckedValues.filter(value => value !== exp.id);
        });
      }
    }
    setCheckedValues(newCheckedValues);
    handleChange(newCheckedValues);
  };

  const handleChange = checkedValues => {
    
    setExperienceIds(checkedValues.filter(item => typeof item === 'number'))
  };

  const handleAssignPromotions = () => {
    setLoading(true)
    props.promotionsAssignAPI({
      params:{
        id: promo_id
      },
      body:{
        experience_ids: experienceIds,
        action: 'assign'
      },
      callback: (res, value) => {
        setLoading(false)
        if(_.get(value, 'success')){
          closeModal();
          notification.success({
            message: "Success",
            description: "Coupons assigned successfully"
          })
        }
      }
    })
  }

  return (
    <div>
      {data.length > 0 &&
        data
          .filter(item => item.experiences !== null)
          .map(parent => (
            <div className="assign_promo_code_card" key={parent.id} style={{marginBottom: 16}}>
                <Checkbox
                  onChange={e => handleParentChange(parent, e.target.checked)}
                  checked={parent.experiences.every(exp => checkedValues.includes(exp.id))}
                >
                  <div className='group_by_promo_parent_txt'>{parent.title}</div>
                </Checkbox>
                <div style={{ marginLeft: 20, display: "flex", flexDirection: 'column', marginTop: "8px" }}>
                  {parent.experiences.map(exp => {
                    
                    return(
                      <Checkbox
                      key={exp.id}
                      onChange={e => handleCheckboxChange(exp.id, e.target.checked)}
                      checked={checkedValues.includes(exp.id)}
                    >
                      <div className='group_by_promo_child_txt'><span>{`${parent.title}`}</span> {_.get(exp, 'type') !=="open" && "starting"} <span>{_.get(exp, 'type') ==="open" && "Open session"}</span> {_.get(exp, 'type') !=="open" && <span style={{marginLeft: "6px"}}>{moment.tz(exp.experience_date, !_.isEmpty(_.get(userDetails, 'meta_details.timezone[0]')) ? _.get(userDetails, 'meta_details.timezone[0]') : "America/Toronto").format('MMM Do')},</span>} {_.get(exp, 'type') !== "open" && <span>{moment.tz(exp.experience_date, !_.isEmpty(_.get(userDetails, 'meta_details.timezone[0]')) ? _.get(userDetails, 'meta_details.timezone[0]') : "America/Toronto").format('hh:mm A')}</span>} {_.size(_.get(exp, 'coupons')) > 0 && _.get(exp, 'coupons').map((v, i) =>{return(<span className='promo_code_tags' style={{marginLeft: "6px"}}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_6880_5724)">
    <path d="M4.5 4.5H4.505M7.5 7.5H7.505M8 4L4 8M4.60095 10.3004C4.76483 10.2788 4.93037 10.3232 5.06091 10.4238L5.66253 10.8854C5.8614 11.0382 6.13804 11.0382 6.33636 10.8854L6.96075 10.406C7.07741 10.3165 7.22462 10.2771 7.37016 10.2965L8.15121 10.3993C8.39952 10.4321 8.63895 10.2938 8.73505 10.0621L9.03558 9.33546C9.09169 9.19936 9.19946 9.09158 9.33556 9.03547L10.0622 8.73492C10.2938 8.63937 10.4321 8.39938 10.3994 8.15105L10.3005 7.39829C10.2788 7.23441 10.3233 7.06886 10.4238 6.93831L10.8854 6.33666C11.0382 6.13777 11.0382 5.86111 10.8854 5.66279L10.406 5.03836C10.3166 4.9217 10.2771 4.77448 10.2966 4.62893L10.3994 3.84784C10.4321 3.59951 10.2938 3.36007 10.0622 3.26396L9.33556 2.96342C9.19946 2.90731 9.09169 2.79953 9.03558 2.66343L8.73505 1.93678C8.6395 1.70512 8.39952 1.56679 8.15121 1.59957L7.37016 1.70234C7.22462 1.72234 7.07741 1.6829 6.96131 1.59401L6.33692 1.11458C6.13804 0.961807 5.8614 0.961807 5.66308 1.11458L5.03869 1.59401C4.92203 1.6829 4.77482 1.72234 4.62928 1.70345L3.84823 1.60068C3.59992 1.5679 3.3605 1.70623 3.26439 1.93789L2.96442 2.66454C2.90776 2.80009 2.79999 2.90786 2.66444 2.96453L1.93784 3.26452C1.70619 3.36063 1.56787 3.60007 1.60064 3.84839L1.70341 4.62948C1.7223 4.77503 1.68286 4.92225 1.59398 5.03836L1.11457 5.66279C0.961809 5.86167 0.961809 6.13833 1.11457 6.33666L1.59398 6.96108C1.68342 7.07775 1.72286 7.22497 1.70341 7.37052L1.60064 8.15161C1.56787 8.39993 1.70619 8.63937 1.93784 8.73548L2.66444 9.03603C2.80054 9.09214 2.90831 9.19991 2.96442 9.33602L3.26495 10.0627C3.3605 10.2943 3.60048 10.4327 3.84879 10.3999L4.60095 10.3004ZM4.75 4.5C4.75 4.63807 4.63807 4.75 4.5 4.75C4.36193 4.75 4.25 4.63807 4.25 4.5C4.25 4.36193 4.36193 4.25 4.5 4.25C4.63807 4.25 4.75 4.36193 4.75 4.5ZM7.75 7.5C7.75 7.63807 7.63807 7.75 7.5 7.75C7.36193 7.75 7.25 7.63807 7.25 7.5C7.25 7.36193 7.36193 7.25 7.5 7.25C7.63807 7.25 7.75 7.36193 7.75 7.5Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_6880_5724">
      <rect width="12" height="12" fill="white"/>
    </clipPath>
  </defs>
</svg> {_.get(v, 'label')}</span>)})}</div>
                    </Checkbox>
                    )
                    })}
                </div>
            </div>
          ))}
          <hr/>
          <div style={{display:"flex", justifyContent:"flex-end"}}>
            {!loading && experienceIds.length > 0 && <button className='assign_promotion_btn' onClick={() => handleAssignPromotions()}>Assign sessions to promotion</button>}
            {!loading && experienceIds.length === 0 &&<button className='promotion_disabled_assign_btn'>Assign sessions to promotion</button>}
            {loading && <div className='promotion_loader_container'>
              <div className='promotion_assign_loader'></div>
            </div>}
          </div>
    </div>
  );
};

export default AssignPromoCode;
