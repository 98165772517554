import { Button, Modal } from 'antd'
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import _ from 'lodash';

import './style.css'
const { confirm } = Modal;

const TermsService =(props)=>{
  let navigate = useNavigate()
  const logout = () => {
    props.logout({
        callback: (response, data) => {
          navigate('/communiti/login')
            window.location.reload(false);
        }
    })
}
  const info =()=>{
    confirm({
      icon:null,
      okText: 'Continue',
    
    cancelText: 'Cancel',
      content: <div style={{
display:'flex',
flexDirection:'column',
alignItems:'center'

      }}><span style={{fontSize:'29px',fontWeight:'600'}}>Are you sure?</span><span>Declining Terms of service will sign you out</span></div>,

      onOk() {
        logout()
      },

      onCancel() {
        
        
      },
    });
  }

    const render =()=>{
        return (<div> <div className="terms-container-1"><div className="main-title">Terms of Service</div>
          <div className="sub-title">Communiti Inc. Coach Agreement</div>
          <div>
            THIS AGREEMENT made TODAY,{'\n'}BETWEEN:  {'\n'}
            YOU the Coach, {'\n'}
            in the Province of Ontario,{'\n'}
          (hereinafter called the “Coach”)
          </div>
          <div>
              OF THE FIRST PART
            </div>
            <div>
            ‑ and ‑{'\n'}
            <div>COMMUNITI INC,</div> a corporation{'\n'}
            incorporated under the laws of the {'\n'}
            Province of Ontario,{'\n'}
          (hereinafter called the “Corporation”)
          </div>
          <div>
              OF THE SECOND PART.
            </div>
            <div>
              This agreement witnesses that in consideration of the covenants and agreements here in contained the parties here to agree as follows:
            </div>
            <div className="sub-title">GENERAL</div>
            <div className="sub-title">1.1 Coach Services</div>
            <div>
              Subject to the terms and conditions hereof, the Corporation shall retain the Coach to carry out services and the Coach shall render the following services to the Corporation:
            </div>
            <div>
            <div>{'\u2022'}</div>
            <div>
                Provide the corporation with all required documentation / data to facilitate onboarding and ongoing smooth functioning of operations;
              </div>
            </div>

            <div>
            <div>{'\u2022'}</div>
            <div>
                Fulfil any listings for coaching services made and approved on the Communiti Athlete / Coach App to the best of his ability; and
              </div>
            </div>

            <div>
            <div>{'\u2022'}</div>
            <div>
                Provide ongoing support to the corporation to facilitate customer support and resolve disputes in a timely manner;
              </div>
            </div>

            <div>
              and such other services as may from time to time be agreed upon between the parties.
            </div>

            <div className="sub-title">1.2 Term of Agreement</div>
            <div>
              The provision of services by the Coach to the Corporation hereunder shall commence on effective date, and shall continue until December 31st 2021, subject to earlier termination of this Agreement as set forth herein.
            </div>

            <div className="sub-title">2. REMUNERATION OF COACH</div>
            <div className="sub-title">2.1 Fee for Services</div>
            <div>
              The Corporation shall pay the Coach the rate per coaching session set on the Communiti Coach App. The rate per session is determined by the coach on the Communiti Coach App and can be changed with the prior approval of the corporation.
            </div>

            <div className="sub-title">2.2 Expenses</div>
            <div>
              The Corporation shall not reimburse the Coach for any expenses incurred by the Coach in the performance of the services. 
            </div>

            <div className="sub-title">2.3 Invoices</div>
            <div>
              Payment shall be made to the Coach with respect to the services referred to in Section 2.1 and 2.2 within ten (10) days from receipt by the Corporation of satisfactory progress report in respect of such services, all of which shall be submitted and tracked through the Communiti Coach App.
            </div>

            <div className="sub-title">3. COVENANTS OF COACH</div>
            <div className="sub-title">3.1 Services</div>
            <div>
              The Coach shall render performance of the services hereunder to the best of the Coach’s ability and in a competent and professional manner.
            </div>

            <div className="sub-title">3.2 Time of Services</div>
            <div>
              The Coach shall devote such of his time and attention to the business of the Corporation as may be agreed to by the Coach and the Corporation. The time of service to be provided hereunder by the Coach shall be as agreed to from time to time by the Corporation and the Coach through the Communiti Coach App. Subject to the obligations of the Coach hereunder, the Coach shall be free to offer services to any other person.
            </div>

            <div className="sub-title">3.3 Licences and Permits</div>
            <div>
              The Coach shall be responsible for obtaining all necessary licences and permits and for complying with all applicable federal, provincial and municipal laws, codes and regulations in connection with the provision of the services hereunder and the Coach shall when requested provide the Corporation with adequate evidence of his compliance with this Section 3.3.
            </div>

            <div className="sub-title">3.4 Rules and Regulations</div>
            <div>
              The Coach shall comply with all the rules and regulations of the Corporation from time to time in force which are brought to his notice or of which he should reasonably be aware.
            </div>

            <div className="sub-title"> 3.5 Insurance</div>
            <div>
              The Coach shall pay for and maintain for the benefit of the Coach and the Corporation, with insurers or through the appropriate government department and in an amount and in a form acceptable to the Corporation, appropriate insurance concerning the operations and liabilities of the Coach relevant to this Agreement including, without limiting the generality of the foregoing, workers’ compensation and unemployment insurance in conformity with applicable statutory requirements in respect of any remuneration payable by the Coach to any employees of the Coach and public liability and property damage insurance.
            </div>

            <div className="sub-title">3.6 Indemnity</div>
            <div>
              The Coach shall indemnify and save the Corporation harmless from and against all claims, actions, losses, expenses, costs or damages of every nature and kind whatsoever which the Corporation or its officers, employees or agents may suffer as a result of the negligence of the Coach in the performance or non-performance of this Agreement.
            </div>

            <div className="sub-title">3.7 Non‑disclosure</div>
            <div>
              (1) The Coach shall not (either during the term of this Agreement or at any time thereafter) disclose any information relating to the private or confidential affairs of the Corporation or relating to any secrets of the Corporation to any person other than for the Corporation’s purposes and, without limiting the generality of the foregoing, the Coach shall not (either during the term of this Agreement or at any time thereafter) disclose any information regarding the Sports and fitness services business of the Corporation to any person other than for the Corporation’s purposes and shall not (either during the term of this Agreement or at any time thereafter) use for his own purposes or for any purposes other than those of the Corporation any such information or secrets he may acquire in relation to the frozen confectionary business of the Corporation.
            </div>
            <div>
              (2) The Coach shall obtain a written non-disclosure agreement in a form acceptable to the Corporation in respect of the same information and secrets referred to in Section 3.7(1) from all persons, including but not limited to any employees of the Coach, who are in any way involved with the Coach in the provision of consultative services to the Corporation hereunder and in the course thereof may have access to any information or secrets referred to in Section 3.7(1) and the Coach shall provide the Corporation with executed copies of any such non-disclosure agreement.
            </div>

            <div className="sub-title">3.8 Inventions and Patents</div>
            <div>
              In the event the Coach contributes to any patentable invention as a result of his services to the Corporation hereunder, any such patentable invention shall be the exclusive property of the Corporation and the Corporation shall have the exclusive right to file patent applications in the name of the Corporation in connection therewith and the Coach shall cooperate with the Corporation and provide all necessary assistance in the filing and prosecution of any such applications.
            </div>

            <div className="sub-title">4. INDEPENDENT CONTRACTORS</div>
            <div className="sub-title">4.1 Coach is Not an Employee</div>
            <div>
              The Coach is not an employee of the Corporation and shall not be entitled to receive from the Corporation any benefits whatsoever and the Corporation shall not be required to make contributions for unemployment insurance, Canada Pension, workers’ compensation and other similar levies in respect of the fee for services to be paid to the Coach pursuant to Section 2.1.
            </div>

            <div className="sub-title">4.2 Coach Shall Not Contract on Behalf of Corporation</div>
            <div>
              The Coach shall not, without the prior written consent of the Corporation, enter into any contract or commitment in the name of or on behalf of the Corporation or bind the Corporation in any respect whatsoever.
            </div>

            <div className="sub-title">5. TERMINATION</div>
            <div className="sub-title">5.1 Termination by Corporation or Coach for Cause</div>
            <div>
              The Corporation or the Coach may terminate this Agreement at any time in the event of the failure of the other party to comply with any of the provisions hereunder upon such party being notified in writing by the party alleging such failure and failing to remedy such failure within ten (10) days of receiving such notice.
            </div>

            <div className="sub-title">5.2 Termination by Corporation or Coach on Notice</div>
            <div>
              The Corporation or the Coach may terminate this Agreement upon the giving of 10 days’ written notice to the other party. 
            </div>

            <div className="sub-title">5.3 Termination on Death of Coach</div>
            <div>
              This Agreement shall be terminated upon the death of the Coach.
            </div>

            <div className="sub-title">5.4 Provisions which Operate Following Termination</div>
            <div>
              Notwithstanding any termination of this Agreement for any reason whatsoever and with or without cause, the provisions of Sections 3.6, 3.7 and 3.8 and any other provisions of this Agreement necessary to give efficacy thereto shall continue in full force and effect following such termination.
            </div>

             <div className="sub-title">6. INTERPRETATION AND ENFORCEMENT</div>
             <div className="sub-title">6.1 Sections and Headings</div>
            <div>
              The division of this Agreement into Articles and Sections and the insertion of headings are for the convenience of reference only and shall not affect the construction or interpretation of this Agreement. The terms “this Agreement”, “hereof”, “hereunder” and similar expressions refer to this Agreement and not to any particular Article, Section or other portion hereof and include any agreement or instrument supplemental or ancillary hereto. Unless something in the subject matter or context is inconsistent therewith, references herein to Articles and Sections are to Articles and Sections of this Agreement.
            </div>

             <div className="sub-title">6.2 Number</div>
            <div>
              In this Agreement words importing the singular number only shall include the plural and vice versa and words importing the masculine gender shall include the feminine and neuter genders and vice versa and words importing persons shall include individuals, partnerships, associations, trusts, unincorporated organizations and corporations and vice versa.
            </div>

            <div className="sub-title">6.3 Benefit of Agreement</div>
            <div>
              This Agreement shall ensure to the benefit of and be binding upon the heirs, executors, administrators and legal personal representatives of the Coach and the successors and permitted assigns of the Corporation respectively.
            </div>

            <div className="sub-title">6.4 Entire Agreement</div>
            <div>
              This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and cancels and supersedes any prior understandings and agreements between the parties hereto with respect thereto. There are no representations, warranties, forms, conditions, undertakings, or collateral agreements, express, implied or statutory between the parties other than as expressly set forth in this Agreement.
            </div>

            <div className="sub-title">6.5 Amendments and Waivers</div>
            <div>
              No amendment to this Agreement shall be valid or binding unless set forth in writing and duly executed by both of the parties hereto. No waiver of any breach of any term or provision of this Agreement shall be effective or binding unless made in writing and 
signed by the party purporting to give the same and, unless otherwise provided in the written waiver, shall be limited to the specific breach waived.
            </div>

            <div className="sub-title">6.6 Assignability</div>
            <div>
              Except as may be expressly provided in this Agreement, neither party hereto may assign its rights or obligations under this Agreement without the prior written consent of the other party hereto.
            </div>

            <div className="sub-title">6.7 Severability</div>
            <div>
              If any provision of this Agreement is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part thereof and the remaining part of such provision and all other provisions hereof shall continue in full force and effect.
            </div>

            <div className="sub-title">6.8 Notices</div>
            <div>
              Any demand, notice or other communication (hereinafter in this Section 6.8 referred to as a “Communication”) to be given in connection with this Agreement shall be given in writing and may be given by electronic mail addressed to the recipient as follows:
            </div>

            <div>
            To the Coach: {'\n'}
          via the e-mail provided by the Coach {'\n'}
           To the Corporation:{'\n'}
          support@communiti.app
            </div>

            <div>
              or such other address or individual as may be designated by notice by either party to the other. Any Communication given by personal delivery shall be conclusively deemed to have been given on the day of actual delivery thereof and, if made or given by registered mail, on the fourth day, other than a Saturday, Sunday or statutory holiday in Ontario, following the deposit thereof in the mail. If the party giving any Communication knows or ought reasonably to know of any difficulties with the postal system which might affect the delivery of mail, any such Communication shall not be mailed but shall be given by personal delivery.
            </div>

            <div className="sub-title">6.9 Governing Law</div>
            <div>
              This Agreement shall be governed by and construed in accordance with the laws of the Province of Ontario and the laws of Canada applicable therein.
            </div>

            <div className="sub-title">6.10 Attornment</div>
            <div>
              For the purpose of all legal proceedings this Agreement shall be deemed to have been performed in the Province of Ontario and the courts of the Province of Ontario shall have jurisdiction to entertain any action arising under this Agreement. The Corporation and the Coach each hereby attorns to the jurisdiction of the courts of the Province of Ontario provided that nothing herein contained shall prevent the Corporation from proceeding at its election against the Coach in the courts of any other province or country.
            </div>

            <div className="sub-title">6.11 Copy of Agreement</div>
            <div>
              By selecting “Agree” the Coach hereby acknowledges acceptance of this Agreement duly signed by the Corporation, and a copy of the agreement will be sent to the e-mail the Coach provided to Communiti Inc.
            </div></div>

            <div className="btn-container1"  style={{padding:'17px',cursor:'pointer'}}> 
                <Button onClick={()=>info()} style={{  backgroundColor:'#a7a7a7',borderRadius:'25px',height:'11%',   padding:"6px 59px",color: "#fff"}}>Decline</Button>
           <div  
           onClick={()=>navigate('/communiti/coach-info-landing')}
            className="btn-inner-container" style={{backgroundColor:"#e85f24",marginLeft:34,cursor:'pointer',borderRadius:'25px',}}> <div style={{    padding:"6px 59px",color: "#fff"}}>Agree</div></div>
            </div>
        </div>)
    }

    return render()
}
const mapStateToProps = state => {
  return {
      checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
      islogindata: _.get(state, 'app.account.isLogin'),

  }
}

const mapDispatchToProps = dispatch => ({
  isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
  logout: details => dispatch(global.redux.action.account.logout(details)),
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
 guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),

});

export default connect(mapStateToProps, mapDispatchToProps)(TermsService)