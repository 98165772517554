import { FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import Input from 'antd/lib/input/Input'
import {Button, Collapse, Form, Select,Spin,Upload,notification} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _ from 'lodash';
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay';

import './style.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
const { Panel } = Collapse;

const ProfileTab =(props)=>{
    const [form] = Form.useForm();
    let history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [address_results, setaddress_results] = useState([]);
    const [address_fetching, setaddress_fetching] = useState(false);
    const [photo_display, setphoto_display] = useState(null);
    const [photo_display_details, setphoto_display_details] = useState({});
    const [user_details, setuser_details] = useState({});
    const [pageLoading, setPageLoading] = useState(false);
    const [buttonLoading,setButtonLoading] =useState(false)


    const fetchPostalCode = address_components => {
        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        return postal_code;
    };
    useEffect(() => {
      if(searchParams.get('code')){
       props.onChangeTab('3')
      }
    }, [])

    const ConnecttoStripe =(code) =>{
      props.stripe_onboard_connect({
        body:{code:code},
        callback:(res,data)=>{
          setPageLoading(false)

          if(res==true){
            history('/communiti/coach-profile')
            
          }
        }
      })
    }
    
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 50);
    };
    const removeImage = info => {
    };
    
    const getBase64 = async (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const checkImageWidth = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
              resolve({width:image.width,height:image.height});
              return {width:image.width,height:image.height};
            };
            reader.onerror = (err) => reject(err);
          };
        });
      };
      const isJson=(str)=> {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      }
      const updatePhoto = (data) => {
        return new Promise(resolve => {
        if (_.get(photo_display_details, 'originFileObj')) {
            let formData = new FormData()
            formData.append('photo', _.get(photo_display_details, 'originFileObj'))
            props.UpdatePhotoData({
                body: formData,
                callback: (res, responseData) => {
                    
                    if (res == true) {
                        // notification.success(
                        //     { message: 'Success', description: _.get(responseData, 'message') }
                        // )  
                    }
                        resolve();
                }
            });
        }
        else{
            resolve();
        }
    })
    };
    const onChangePhoto = async info => {
        let details = await checkImageWidth(info.file.originFileObj);
        // if(_.chain(details).get('width').eq(1024).value() && _.chain(details).get('height').eq(1024).value()){
            getBase64(info.file.originFileObj, (imageUrl) => {
              setphoto_display_details(info.file)  
              setphoto_display(imageUrl);
            });
        // }else{
        //     notification.warning({
        //         message:"Team Featured Photo",
        //         description:'Please select the 512x512 dimensions image.'
        //     })
        // }
    };
    const onFinish =(e)=>{
      let request_details={...e}
    if (isJson(_.get(e,'address'))) {
      let location_parse = JSON.parse(_.get(e,'address'))
      request_details.location=`${_.get(location_parse,'lat')},${_.get(location_parse,'lng')}`
      request_details.address = _.get(location_parse,'text')
      // request_details.zip_code = _.get(location_parse,'postal_code')
    }
    delete request_details['email']
    if(!_.isEmpty(photo_display_details)){
      updatePhoto(_.get(e,'photo'))
    }
    let details_final = { ...request_details}
    // delete details_final['email']
    setButtonLoading(true)
    props.hostUpdateProfile({
      body:details_final,
      callback:(res,data)=>{
    
    if (_.get(data,'details[0]')) {
      props.hostProfileData({
        callback:(res,data)=>{
         if(_.get(data,'data.details')){
          props.onChangeTab('3')
          notification.success({
            message:'Success',
            description:'Profile has been updated successfully'
         
           })
         }}})
      
    
    }
    setButtonLoading(false)
      }
    })
    
    
      }
    
       //Address search
       const fetchAddress = value => {
        setaddress_results([])
        setaddress_fetching(true);
        geocodeByAddress(value).then(body => {
            
            const address_results = _.map(body, location => ({
                text: location.formatted_address,
                value: JSON.stringify({ text: location.formatted_address, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
            }));
    
            setaddress_results(address_results)
            setaddress_fetching(false);
    
        }).catch(error => {
            setaddress_results([])
            setaddress_fetching(true);
        });
    };
    //Initializing debounce for address
    const addressDelayedQuery = _.debounce(q => fetchAddress(q), 1200);
    
    useEffect(()=>{
      props.hostProfileData({
   callback:(res,data)=>{
    if(_.get(data,'data.details')){
      setuser_details(_.get(data,'data.details'))
      form.setFieldsValue({
        display_name:_.startCase(_.get(data,'data.details.display_name')),
        bio:_.get(data,'data.details.bio')
      })
      if (_.get(data,'data.details.address') && _.get(data,'data.details.location')) {
                    
        const address_results = [{
            text: _.get(data,'data.details.address') ,
            value: JSON.stringify({ text:  _.get(data,'data.details.address') , lat: _.get(data,'data.details.location.x'), lng:  _.get(data,'data.details.location.y'), postal_code:''}),
          }]
          form.setFieldsValue({
              address: JSON.stringify({ text:  _.get(data,'data.details.address') , lat: _.get(data,'data.details.location.x'), lng:  _.get(data,'data.details.location.y'), postal_code:''}),
                                 })
          setaddress_results(address_results)
    }
    if(_.get(data, 'data.details.photo')){
      let photo_path =`${_.get(data, 'data.meta.photo.path')}/${_.get(data, 'data.meta.photo.folder')}/${_.get(data, 'data.meta.photo.sizes.medium')}/${_.get(data, 'data.details.photo')}`
      setphoto_display(photo_path)
    }
    }
   } 
  })
    },[])
    const render =()=>{
        const uploadButton = (
            <div>
               <FileImageOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          );
        return (
          <LoadingOverlay
          active={pageLoading}
          spinner
          text='Loading...'
          >   <div>
            <Form form={form}  onFinishFailed={(e)=>console.log('onFinishFailed',e)} onFinish={(e)=>{
              
              onFinish(e)
            }
              }>
            <div className="coach-info-conatiner-first">
                <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'900',fontSize:'27px'}}>Club or Coach Profile</span>
                   
                </div>
                <div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
   Club or Coach Name
</div>
<Form.Item name='display_name'   rules={[
          {
            required: true,
            message: 'Please input your Display Name',
          },
        ]}><Input onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          
          }
        }} /></Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Club or Coach Address
</div>
<Form.Item
                                name="address"
                                rules={[{ required: true, message: 'Please input your location' }]}
                            >
<Select getPopupContainer={trigger => trigger.parentNode}
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    onSearch={search => search ? addressDelayedQuery(search) : ''}
                                    //   onChange={(e) => onChangeForm(e, 'address')}
                                    placeholder={'Search address...'}
                                    notFoundContent={
                                        address_fetching ? (
                                            <Spin size="small" />
                                        ) : null
                                    }
                                >
                                    {address_results && address_results.length > 0 && address_results.map(d => (
                                        <Select.Option key={d.value}>{d.text}</Select.Option>
                                    ))}
                                </Select>
                                </Form.Item>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{marginTop:'23px'}}>
                <div className="status-container">
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Description
</div>
<Form.Item name='bio'   rules={[
          {
            required: true,
            message: 'Please input your bio',
          },
        ]}><TextArea  /></Form.Item>

                    </div>
                </div>
                </div>
</div>
                <div className="first-container-1" style={{marginTop:43}}>
                <span style={{fontWeight:'700',fontSize:'20px'}}>Club or coach Profile Picture</span>
               
                <Form.Item style={{width:photo_display?'15%':'100%'}} name='photo'   rules={[
          {
            required: photo_display?false: true,
            message: 'Please input your photo',
          },
        ]}> <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        accept=".jpg,.jpeg"
        showUploadList={false}
        onRemove={removeImage}
        onChange={onChangePhoto}
        customRequest={dummyRequest}
    >
        {photo_display ? <img  src={photo_display} alt="avatar" style={{ width: '100%',height:'100%', borderRadius: '20px' }} /> : uploadButton}

    </Upload>
</Form.Item>
                  
                </div>
               
            </div>
            <Form.Item   className="form-btn-inner-container-4"
            style={{cursor:'pointer',justifyContent:"end",display:'flex',marginTop:'5%'}}> 
            {/* <div className="btn-container-1" style={{cursor:'pointer',justifyContent:'end',alignItems:'end'}}>  */}
            <Button className='form-btn-4'
            loading={buttonLoading}
            //  onClick={()=>props.onChangeTab('3')}
             htmlType="submit"
             >Continue</Button>
            {/* </div> */}
            </Form.Item>
            </Form>
        </div></LoadingOverlay>)
    }

    return render()
}

const mapStateToProps = state => {
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),
       guestUserProfile: _.get(state, 'app.guest.profile.data.details')
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
    list: details => dispatch(global.redux.action.categories.list(details)),
    hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
    guestUpdateProfile: details => dispatch(global.redux.action.guest.update(details)),
    updatePhone: details => dispatch(global.redux.action.guest.updatePhone(details)),
    UpdatePhotoData: details => dispatch(global.redux.action.host.updatePhoto(details)),
    stripe_onboard_connect: details =>
    dispatch(global.redux.action.account.stripeOnboardWeb(details)),
   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(ProfileTab)