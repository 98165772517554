import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateCouponState;
// host actions

export default {

       //List
       couponList: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'get',
                                   url: `/coupons/list`
                            },
                            actionType: actionType.coupon.list,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //View
       couponView: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'get',
                                   url: `/coupons/view`
                            },
                            actionType: actionType.coupon.view,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       // Create 
       couponCreate: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/coupons/create`
                            },
                            actionType: actionType.coupon.create,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       //Update
       couponupdate: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/coupons/update`
                            },
                            actionType: actionType.coupon.update,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },

        //Change Status
        couponChangeStatus: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/coupons/change-status`
                            },
                            actionType: actionType.coupon.update,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },
       

         //Start Questions sets 
       //List
       promotionsList: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'get',
                                   url: `/coupons/list`
                            },
                            actionType: actionType.promotion.promotionsList,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },

       promotionsListByGroup: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'get',
                                   url: `/experiences/list-by-group`
                            },
                            actionType: actionType.promotion.promotionsListByGroup,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },

       promotionsUnassign: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/coupons/assign-experiences`
                            },
                            actionType: actionType.promotion.promotionsUnassign,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },

       promotionsAssign: (componentRequest) => {
              return dispatch => {
                     const requestOptions = {
                            axiosParams: {
                                   method: 'post',
                                   url: `/coupons/assign-experiences`
                            },
                            actionType: actionType.promotion.promotionsAssign,
                            reducerName,
                            dispatch,
                            componentRequest,
                            authorization: true
                     };
                     global.api.requestAPI(requestOptions);
              }
       },

       viewPromotions: (componentRequest) => {
              return dispatch => {
                  const requestOptions = {
                      axiosParams: {
                          method: 'get',
                          url: `/coupons/view`
                      },
                      actionType: actionType.promotion.viewPromotions,
                      reducerName,
                      dispatch,
                      componentRequest,
                      authorization: true
                  };
                  global.api.requestAPI(requestOptions);
              }
          },



          
   
}