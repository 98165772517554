import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { SnippetsOutlined, CalendarOutlined, DollarCircleOutlined, LikeOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons'
import {Badge, Button, Image} from 'antd'
import global from '../../core_units/config/global'

import './style.css'
import chatKitty from '../../core_units/chat-system/chatkitty';
import _ from 'lodash';
import ChatKittyHome from '../../core_units/chat-system/components/home/index';
import { connect } from "react-redux";
import  {io}  from "socket.io-client";
import NewMenu from '../menu'




// import disableBrowserBackButton from 'disable-browser-back-navigation';
// disableBrowserBackButton();
const MainMenu = (props) => {
    let history = useNavigate();

    const [channels, setChannels] = useState([]);
  const [isFetchChannelProcessed, setIsFetchChannelProcessed] = useState(false);
  const [lastNotificationChannelId, setLastNotificationChannelId] = useState(null);
  const [lastReadBadge, setLastReadBadge] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [countResut ,setcountResut] = useState(null)

  const [unread, setUnread] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [allMessagesRead, setAllMessagesRead] = useState(false);
  const[getTemplateList,setTemplateList] = useState([]);
  const [response, setResponse] = useState("");
  const [endpoint, setEndpoint] = useState("http://3.96.180.154:1337/realtime/subscribe?access_token=nMWYkiyDHf4hhDHuMrks8HGxB1yZqjtsGYOHQMNtuDlosgrLvEagN2PpWyJkH03iVNuDaXHHYYNoqKCH8mTWNSKP0ovWZAYyBzZIPUyoMTTIRFZtZcXh0htGE8S5PxYRbgEqkKRMQo60DJXc50kJwpntP6MmPPMpeGmofAGmimiNB07X40S0c74DUXWfvM8qVINUzKJpmwPpZu1O1JgCDeLoIbWaXQpFJwMeeCdNmmIrQ2IXWEOBXqe4y5Owodg");

  useEffect(() => {
      loadTemplateList(); 
  }, []);

  

  

  const handleSendSocketRequest = () => {
  const socket = io("http://3.96.180.154:1337/");
  socket.on("realtime/subscribe", () => {
    
  });

  socket.on("disconnect", () => {
    
  });
  
  }

    useEffect(() => {

      
        
        if(_.get(props,'chatKittyCurrentUserData.id')){
          // getChannelList()
          return initialyzeNotificationReceiveEvent();
        }
        
      }, [_.get(props,'chatKittyCurrentUserData.id')]);

      // useEffect(() => {
       
      //   getChannelList()
      // }, [])

      const getChannelList =()=>{
        if(_.get(props,'chatKittyCurrentUserData.id')){
          chatKitty.instance.listChannels({ joinable: false }).then((result) => {
            processChannelData(_.get(result, 'paginator.items', []));
          });
        }
      }

      useEffect(() => {
        
        if(_.get(props,'chatKittyCurrentUserData.id')){
          getChannelList()
        }
       
      }, [props])

      // useEffect(() => {
      //   setInterval(() => {
      //       chatKitty.instance.getChannels({ joinable: false }).then((result) => {
      //           processChannelData(_.get(result, 'paginator.items', []));
      //         });
      //     }, 1000);
        
      // }, []) // checkChannelUnread

      useEffect(() => {
        if(_.get(props,'chatKittyCurrentUserData.id')){
        if (!_.chain(lastNotificationChannelId).toString().isEmpty().value()) {
          updateAthleteUnreadMessagesCount(lastNotificationChannelId);
        }
    }
      }, [lastNotificationChannelId]);



      const initialyzeNotificationReceiveEvent = () => {
        if(_.get(props,'chatKittyCurrentUserData.id')){
        return chatKitty.instance.onNotificationReceived((notification) => {
          const condition1 = _.overEvery([
            notification => _.chain(notification).get('type', null).eq('USER:SENT:MESSAGE').value(),
            notification => !_.chain(notification).get('message.user.name', null).eq(_.get(props, 'chatKittyCurrentUserData.name')).value(),
            () => _.eq('Actions.currentScene', 'DashboardChatChannelList')
          ]);
          const condition2 = _.overEvery([
            notification => _.chain(notification).get('data.type', null).eq('USER:SENT:MESSAGE').value(),
            notification => !_.chain(notification).get('data.message.user.name', null).eq(_.get(props, 'chatKittyCurrentUserData.name')).value(),
            () => _.eq('Actions.currentScene', 'DashboardChatChannelList')
          ]);
          getChannelList()
          // if (condition1(notification)) {
          //   setLastNotificationChannelId(_.get(notification, 'channelId', null));
          // } else if (condition2(notification)) {
          //   setLastNotificationChannelId(_.get(notification, 'data.channelId', null));
          // }
        });
    }
      };

      const updateAthleteUnreadMessagesCount = channelId => {
        if(_.get(props,'chatKittyCurrentUserData.id')){
        const items = _.clone(channels);
        const channelIndex = _.findIndex(items, { 'id': channelId });
        if (_.gt(channelIndex, -1)) {
          items[channelIndex].athleteUnreadMessagesCount = _.add(items[channelIndex].athleteUnreadMessagesCount, 1);
          setChannels(items)
        }
    }
      };

      const processChannelData = async (channelItems, currentItemIndex = 0) => {
        if(_.get(props,'chatKittyCurrentUserData.id')){
        if (_.chain(currentItemIndex).add(1).lte(_.size(channelItems)).value()) {
          const result = await chatKitty.instance.countUnreadMessages({ channel: channelItems[currentItemIndex] });
          channelItems[currentItemIndex].athleteUnreadMessagesCount = _.get(result, 'count');
          processChannelData(channelItems, _.add(currentItemIndex, 1));
        } else {
          setIsFetchChannelProcessed(true);
          let final_messages = []
          _.map(channelItems, (value, index) => {
    
          })
        
          let countResut = _.map(channelItems,'athleteUnreadMessagesCount')
          
          setcountResut(_.sum(countResut))
          setChannels(channelItems);
        }
    }
    // 
      };

      const checkIfUnread = async channel => {
        if(_.get(props,'chatKittyCurrentUserData.id') &&channel){
        const unreadResults = await chatKitty.instance.checkChannelUnread({
          channel: channel,
        });
        if (unreadResults.succeeded) {
          setUnread(unreadResults.unread);
        }
    }
      };

      const getUnreadCount = async channel => {
        

        if(_.get(props,'chatKittyCurrentUserData.id') && channel){
        const countResults = await chatKitty.instance.countUnreadMessages({channel: channel});
    
        if (countResults.succeeded) {
          setUnreadCount(countResults.count);
        }
    }
      };

      useEffect(() => {
        if(_.get(props,'chatKittyCurrentUserData.id')){
          if(_.get(props, 'stateData')){
            checkIfUnread(_.get(props, 'stateData'));
            
            getUnreadCount(_.get(props, 'stateData'));
            if(allMessagesRead === true){
              readAllMessages(_.get(props, 'stateData'))
            }
          }
       
    }
      }, [allMessagesRead]);

      const readAllMessages = async(channel) => {
        if(_.get(props,'chatKittyCurrentUserData.id')){
            const countResults = await chatKitty.instance.readChannel({channel: channel});
            setAllMessagesRead(_.get(countResults, 'succeeded'))
        }
      }

      const loadTemplateList=()=>{
        
        let windowUrl = window.location;
    let striged = windowUrl.toString();
    let url_splitted = striged.split('/')
    
    // if(url_splitted[4] === 'main-menu'){
      props.templateList({
        params:{ sort: 'created_at.desc', limit:1},
        callback:(res,data)=>{
          

if(res==true){
   
setTemplateList(_.get(data, 'data.items'))
}
        }
    })
    // }
        
    }

    const render = () => {
      
      let windowUrl = window.location;
      let striged = windowUrl.toString();
      let url_splitted = striged.split('/')
        return (
            <div className='main-menu-page'>
               <ChatKittyHome {...props}/>
              <div style={{marginLeft:'120px'}}> <NewMenu  /></div>
                {/* {_.get(props,'userProfile.business_type')!='coordinator' &&<div className='container'>
                  
                    <div className={_.get(props, 'chatTab') === true || _.get(props, 'manageTab') === true || _.get(props, 'templateTab') === true ? 'menu-card-container-adjustment' : 'menu-card-container'}>
                        <div className={_.get(props, 'templateTab') === true ? 'individual-menu active' : `individual-menu${_.get(props,'userProfile.business_type')=='coordinator'?' disable-btn':''}`} onClick={() =>_.get(props,'userProfile.business_type')!='coordinator' && history('/template-list?menu=template')}><SnippetsOutlined className='menu-icons' /> <span>Templates</span></div>
                        <div className={_.get(props, 'manageTab') === true ? 'individual-menu active' :  `individual-menu${_.get(props,'userProfile.business_type')=='coordinator'?'':''}`} onClick={() => history('/communiti/manage?menu=manage')}><CalendarOutlined className='menu-icons' /> <span>Manage</span></div>
                        <div className={_.get(props, 'financeTab') === true ? 'individual-menu active' :  `individual-menu${_.get(props,'userProfile.business_type')=='coordinator'?' disable-btn':''}`} onClick={() =>_.get(props,'userProfile.business_type')!='coordinator' && history('/communiti/finances-list?menu=reports')}><DollarCircleOutlined className='menu-icons' /> <span>Reports</span></div>
                  
                        <div className={_.get(props, 'chatTab') === true ? 'individual-menu active' :  `individual-menu${_.get(props,'userProfile.business_type')=='coordinator'?' disable-btn':''}`} onClick={() =>_.get(props,'userProfile.business_type')!='coordinator' && history('/communiti/chats-notification?menu=chat')}>
                        {countResut !=null &&countResut!=0 ? <Badge count={countResut} overflowCount={50}>
                       <div style={{display:'flex',flexDirection:'row',alignContent:'center',alignItems:'center'}}> <MessageOutlined className='menu-icons' /> <span>Communication</span></div>
                        </Badge>:<> <MessageOutlined className='menu-icons' /> <span>Communication</span></>}
                          </div>
                        <div className={_.get(props, 'profileTab') === true ? 'individual-menu active' :  `individual-menu${_.get(props,'userProfile.business_type')=='coordinator'?' disable-btn':''}`} onClick={() =>_.get(props,'userProfile.business_type')!='coordinator' && history('/communiti/host?menu=profile')}><UserOutlined className='menu-icons' /> <span>Profile</span></div>
                    </div>
                </div>} */}

                
                {
                  url_splitted[4] === 'main-menu' && _.size(getTemplateList) === 0 ?
               
                <div  className='main-menu-home-image'>
                <Image preview={false} src={require('../../assets/img/Illustration.png')}/>
                <div className='main-menu-home-page-content-header'><p>Welcome to Communiti!</p></div>
                <p>To begin, start by creating a template. It’s the first step in building a listing</p>
                <div style={{display:"flex", alignContent:"center", marginBottom:"13px"}}>
                <p >that users can register for. </p>
                </div>
                <p>To create a template, click on the Templates in the menu above.</p>
                </div>
                :''
                 }
            </div>
        )
    }

    return render();
};
const mapStateToProps = state => {
  
  return {
    chatKittyCurrentUserData: _.get(state, 'app.settings.chatKittyCurrentUserData'),
    userProfile: _.get(state, 'app.host.profile.data.details'),
  }
}

const mapDispatchToProps = dispatch => ({
  financesListApi: details => dispatch(global.redux.action.finances.list(details)),
  viewFinance: details => dispatch(global.redux.action.finances.view(details)),  
  templateList: details => dispatch(global.redux.action.template.list(details)), 
});
export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)