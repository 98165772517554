import React, {useEffect, useState} from 'react';
import { Tabs, Button } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons'

import MainMenu from '../../main-menu';
import OnsiteFacilitationTab from '../../manage/session/onsiteFacilitationTab';
// import LeaderBoard from '../../manage/session/leader-board';
import '../../manage/style.css';

const { TabPane } = Tabs;


const IntegratedTab = (props) => {
    let history = useNavigate();

    const [TemplateViewDetails, setTemplateViewDetails] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if(searchParams.get('id')){
            templateView(searchParams.get('id'))
          }
          if(_.get(props,'userProfile.business_type')=='coordinator'){

          }
    }, [])

    const templateView =(id)=>{
        props.viewTemplate({
          params:{id:id,expand:'photos'},
          callback:(res,data)=>{
            if(res==true){
              
              setTemplateViewDetails(_.get(data,'data.details'))
            }
          }
        })
      };

      const onChangeBack =()=>{
        history('/communiti/manage')
    
        
        }

    const render = () => {
        
        return(
            <div>
            <OnsiteFacilitationTab experience_id={_.get(props, 'experience_id')} nameOfExp={_.get(props, 'experience_name')} />
            </div>
        )
    };

    return render();
};

const mapStateToProps = state => {
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),
       guestUserProfile: _.get(state, 'app.guest.profile.data.details')
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    viewTemplate: details => dispatch(global.redux.action.template.view(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(IntegratedTab)