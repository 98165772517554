import React, {useEffect, useState} from 'react';
import { Space, Table, Tag, Input, Select, Button, Modal, Switch, notification, message } from 'antd';
import QRCode from "react-qr-code";
import { connect } from 'react-redux';
import socketIOClient from "socket.io-client";

import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import '../../onboarding-screen/style.css';
import './roster.css';
import moment from 'moment';



 const RosterTab = (props) => {
  const { getData } = props;
  
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [getRosterStatsDetails, setRosterStatsDetails] = useState({});
  const [getRosterTableData, setRosterTableData] = useState([]);
  const [getSearchByFilter, setSearchByFilter] = useState('');
  const [getDetailsLeagueEvents, setDetailsLeagueEvents] = useState({});
  const [getSocketData, setSocketData] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: <div className='roster_table_column_txt'>First Name</div>,
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: <div className='roster_table_column_txt'>Last Name</div>,
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
        title:  <div className='roster_table_column_txt'>Gender</div>,
        dataIndex: 'gender',
        key: 'gender',
    },
    // {
    //     title: 'Level',
    //     dataIndex: 'level',
    //     key: 'level',
    // },
    {
        title: <div className='roster_table_column_txt'>Status</div>,
        dataIndex: 'status',
        key: 'status',
        render: (text, item) => {
          
          // 
          let Array_two = _.size(_.get(getDetailsLeagueEvents, 'players_unavailable')) > 0 ? _.get(getDetailsLeagueEvents, 'players_unavailable') : [];
          Array_two.forEach(obj => {
            obj.check_in_status = 0 ;
          });   
          let added_array_value = Array_two
          let Array_one = _.size(_.get(getDetailsLeagueEvents, 'players_check_in')) > 0 ? _.get(getDetailsLeagueEvents, 'players_check_in') : [];
          
          return  <div>
              {
                
                Array_one.concat(added_array_value).map((value, index) => {
                  
                  
                 if( _.isEqual(parseInt(_.get(value, 'id')), parseInt(_.get(item, 'guest.guest.id')))){
                  return(
                  <Select 
              value= {_.get(value, 'check_in_status')}
              onChange={(e) => onClickGenerate(e, _.get(item, 'guest.guest'))}
              style={{width: "60%"}}
              options={[
                {
                  value: 1,
                  label: <div className='roster_select_player_checked_in_status'>Checked-In</div>,
                },
                {
                  value: 0,
                  label: <div className='roster_select_player_not_checked_in_status'>Not Checked-In</div>,
                },
                {
                  value: 2,
                  label: <div className='roster_select_player_coming_late_status'>Coming Late</div>,
                },
                {
                  value: 3,
                  label: <div className='roster_select_player_not_coming_status'>Not Coming</div>,
                },
              ]}
              />
                  )
                 }
                })
              }
              </div>
                           
                      
                  
              },
    },
  //   {
  //     title: 'Action',
  //     dataIndex: 'action',
  //     key: 'action',
  //     width: "30%"
  // },
  ];

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Refreshed successfully',
    });
  };

  useEffect(() => {
      getRoster();
      props.setRosterFunction({
        value: getEventRosterList
      })
  }, []);

  useEffect(() => {
    getRoster();
  }, [getSocketData]);

  useEffect(() => {
    const socket = socketIOClient("http://localhost:80", {
reconnection: false,
transports: ['websocket']
});
socket.open();
socket.on('utilitiesEvent', data => {
  
  // alert(data.data.status)
//   Modal.destroy();
//   notification.open({message: _.get(data, 'data.status')});
// if(data){
    setSocketData(_.get(data, 'data.event_data.loading'));
    // setIsModalOpen(true);
// }
})
}, []);

  

const getRoster = async() => {
await getEventRosterList(getSearchByFilter)
}

      const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

    const handleChange = (value) => {
        
      };

      const sortHandleChange = (value) => {
        
      };

      const getEventRosterList = (search, refresh) => {
        
        let parameters = {
          id: _.get(props, 'experience_league_data.leage_id'),
          expand: 'profile,category_profile,guest',
          limit: 1000,
        }
        if(!_.isEmpty(search)){
          parameters.search = search;
          parameters.search_type = 1
      }
        props.rosterEventListAPI({
          params:parameters,
          callback: async(response, data) => {
            
            if(response){
              if(refresh === 'refresh'){
                success();
              }
              
              setSocketData(true);
              if(_.size(_.get(data, 'data.items')) > 0){
                setRosterStatsDetails(_.get(data, 'data.details.stats'));
                setDetailsLeagueEvents(_.get(data, 'data.details.event'));
                pushedRosterList(_.get(data, 'data.items'));
              } else {
                setDetailsLeagueEvents({});
              }
             
            }
          }
        })
      };

     

     

      const pushedRosterList = async(data) => {
        let a = [];

        _.map(data,(value, index) => {
          
          a.push({
            key: index,
            first_name: <div className='roster_table_column_txt'>{_.startCase(_.get(value, 'guest.first_name'))}</div>,
            last_name: <div className='roster_table_column_txt'>{_.startCase(_.get(value, 'guest.last_name'))}</div>,
            gender: <div className='roster_table_column_txt'>{_.isEmpty(_.get(value, 'guest.gender')) ? '-' : _.get(value, 'guest.gender')}</div>,
            guest: value
          })
        });
       await setRosterTableData(a);
      };

      const searchByNameFilter = (e) => {
        let search = e.target.value
        setSearchByFilter(search)
        getEventRosterList(search)
    };

    const onClickGenerate = (value, data) => {
      
      // setSocketData(true)
      setTimeout(() => {
        props.changeBuildTeamsEventCheckInAPI({
          body:{
            id:_.get(props, 'experience_league_data.leage_id'),
            guest: _.get(data, 'id'),
            user_type: 1,
            check_in_status: value
          },
          callback: (response, data) => {
            if(response){
              getRoster();
              notification.success({
                message: "Success",
                description: "Status changed successfully"
              })
            }
          }
        })
      }, 1000)
     
    };

    const refreshAPIs = (value) => {
      let refreshed_value = "refresh"
      getEventRosterList(value, refreshed_value);
    }

    const render = () => {

        

          let qrValue = _.get(props, 'experience_league_data.leage_id')

        return(
            <div>
              {contextHolder}
            <div>
                <div style={{display: "flex", flexDirection: "row"}}>
                   <div className='roster_stats_card'>
                   <button className='roster_refresh_btn' onClick={() => refreshAPIs(getSearchByFilter)}> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.1667 4.27117C15.9345 5.55911 17.0834 7.64539 17.0834 10C17.0834 13.912 13.9121 17.0833 10.0001 17.0833H9.58342M5.83341 15.7288C4.06564 14.4409 2.91675 12.3546 2.91675 10C2.91675 6.08799 6.08806 2.91667 10.0001 2.91667H10.4167M10.8334 18.6667L9.16675 17L10.8334 15.3333M9.16675 4.66667L10.8334 3L9.16675 1.33334" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>Refresh</button>
                    <div className='roster_stats_text'>Expected participants: {_.get(getRosterStatsDetails, 'expected')} </div>
                    <div className='roster_stats_text'>Total checked-in: {_.get(getRosterStatsDetails, 'checked_in')} </div>
                    <div className='roster_stats_text'>Total not checked-in: {_.get(getRosterStatsDetails, 'not_checked_in')} </div>
                    <div className='roster_stats_text'>Total late: {_.get(getRosterStatsDetails, 'late')} </div>
                   </div>
                    {/* <Button style={{marginLeft:"20px", borderRadius: "12px", backgroundColor: "#e85f2f", color: "#fff"}} onClick={() => showModal()}>View QR Code</Button> */}
                    {/* <Button style={{marginLeft:"20px", borderRadius: "12px", backgroundColor: "#e85f2f", color: "#fff"}} onClick={() => refreshAPIs(getSearchByFilter)}>Refresh</Button> */}
            </div>
               
                {/* <div style={{display: "flex", flexDirection: "row"}}>
                <Input placeholder='Search By Name' allowClear = {true} onChange={(e) => searchByNameFilter(e)} style={{marginBottom: "10px", width: "40%"}}/>
                <Select
                    defaultValue="checked_in"
                    style={{
                        width: 120,
                        marginLeft: "40px"
                    }}
                    onChange={handleChange}
                    options={[
                        {
                        value: 'checked_in',
                        label: 'Checked-In',
                        },
                        {
                        value: 'coming_late',
                        label: 'Coming Late',
                        },
                        {
                        value: 'not_checked_inn',
                        label: 'Not Checked In',
                        },
                    ]}
                    />
                    <Select
                    defaultValue="sort_by_checked_in_status"
                    style={{
                        width: "50%",
                        marginLeft: "40px"
                    }}
                    onChange={sortHandleChange}
                    options={[
                        {
                        value: 'sort_by_checked_in_status',
                        label: 'Sort by Checked-In Status',
                        },
                    ]}
                    />
                </div> */}
                <Table style={{marginTop: "16px"}} columns={columns} dataSource={getRosterTableData} pagination={false} />

                  <Modal centered={true} width="20%" footer={null} title="QR Code" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div style={{ height: "auto", marginLeft: "30px", maxWidth: 64, width: "100%" }}>
                  <QRCode
                  size={150}
                  // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={qrValue}
                  // viewBox={`0 0 300 256`}
                  />
              </div>
            </Modal>
            </div>
            </div>
        )
    };

    return render()
  
    
};
const mapStateToProps = state => {
  
  return {
     checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
     isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
     islogindata: _.get(state, 'app.account.isLogin'),
     userProfile: _.get(state, 'app.host.profile.data.details'),
     guestUserProfile: _.get(state, 'app.guest.profile.data.details'),

  }
}

const mapDispatchToProps = dispatch => ({
  rosterEventListAPI: details => dispatch(global.redux.action.league.leagueEventRosterList(details)),
  changeBuildTeamsEventCheckInAPI: details => dispatch(global.redux.action.league.leagueEventCheckIn(details)),
  viewLeagueEventsAPI: details => dispatch(global.redux.action.league.viewLeagueEvents(details)),
  setRosterFunction: details =>
    dispatch(global.redux.action.settings.setOnsitePlayersCheckIn(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(RosterTab)