import { EditFilled,DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, PlusSquareOutlined, ClockCircleFilled } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React,{useState,useEffect} from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, InputNumber,Modal, Table, Image} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { set } from 'lodash';
import { connect } from 'react-redux';
import {VscVmActive} from "react-icons/vsc"
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { FiPlusCircle } from "react-icons/fi";

import '../../onboarding-screen/style.css'
import '../style.css'
import MainMenu from '../../main-menu'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TbDeviceTabletSearch } from "react-icons/tb";
const { Panel } = Collapse;
const ArchivedTemplateList =(props)=>{
    const [form] = Form.useForm();
    let history = useNavigate();
    const [AllTemplateListData, setAllTemplateListData] = useState([])
    const [AllTemplateListMeta, setAllTemplateListMeta] = useState({})
    const [pageLoading, setPageLoading] = useState(true)
    const [page, setpage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getArchiveId, setArchiveId] = useState();
    const [searchedData, setSearchedData] = useState('');

    useEffect(() => {
        
        if(props.searchFilter.length > 0){
            setSearchedData(props.searchFilter);
            handleSearch();
        } else {
            loadTemplateList();
        }
        
        
}, [props.searchFilter]);

const handleSearch = () => {
    searchFilterTemplate();
};

const searchFilterTemplate = () => {
    setPageLoading(true);
    props.templateList({
        params:{
            search: searchedData,
            sort: 'created_at.desc',
            status: 0,
            limit: 500
        },
        callback: (res, data) => {
            setPageLoading(false)
            if(res){
                let a =[]

            _.map(_.get(data,'data.items'),(value)=>{
                
                a.push({
                    key:_.get(value,'id'),
                    title: _.get(value, 'title'),
                    template_type: _.startCase(_.get(value, 'type'))
                })
            })
            setAllTemplateListData(a)
            }
        }
    })
}

    const showModal = (value) => {
        setArchiveId(value)
        setIsModalOpen(true);
      };

      const handleOk = () => {
        archiveTemplate(getArchiveId)
        
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

    const titleStyle = {
        color: 'var(--Gray-600, #475467)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px', /* 142.857% */
        cursor: "pointer"
      };

      const titleStyleView = {
        color: 'var(--Gray-600, #475467)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px', /* 142.857% */
        cursor: "pointer",
        marginLeft: "4px"
      }
 
let column = [
    {
title:'Template name',
key:'title',
dataIndex:'title',
width:'40%'

},
{
    title:'Template type',
    key:'template_type',
    dataIndex:'template_type',
    width:'20%'
    
    },
{
    title:'Quick Actions',
    width:'40%',
    key:'action',
    dataIndex:'action',
    render: (text, item) => {
        
        return(
        <div className="agent-list-action-btns" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <div onClick={()=>history(`/view-template?id=${_.get(item,'key')}&menu=template`)}><TbDeviceTabletSearch style={{width: "16px", height: "16px", marginBottom: "4px", fontWeight: 600}} /><span style={titleStyleView}>View</span></div>
        <div style={titleStyle} onClick={() => showModal(_.get(item,'key'))}><img style={{height: "16px", width: "16px", marginLeft: "32px"}} src={require('../../../assets/img/unarchive_icon.png')}/> Unarchive</div>
        
    </div>
        )
    }
       
    ,
    
    },
]

let dataSource =[{
    title:'Test 1'
},
{
    title:'Test 2'
},
{
    title:'Test 3'
},
{
    title:'Test 4'
}]
 
useEffect(() => {
  loadTemplateList();
  props.setTemplateArchiveFunction({
    value: loadTemplateList
  })
}, [])

const loadTemplateList=()=>{
    setPageLoading(true)
    props.templateList({
        params:{ sort: 'created_at.desc',status:0, limit: 5},
       
        callback:(res,data)=>{

if(res==true){
    let a =[]

_.map(_.get(data,'data.items'),(value)=>{
    
    a.push({
        key:_.get(value,'id'),
        title: _.get(value, 'title'),
        template_type: _.startCase(_.get(value, 'type'))
    })
})
setAllTemplateListData(a)
setAllTemplateListMeta(_.get(data,'data.meta'))
}
setPageLoading(false)
        }
    })
}


const archiveTemplate = (id) => {
    if(id){
        props.unArchiveActiveTemplates({
            body:{
                id: parseInt(id),
                status: 1,
                status_glossary:"Un Archived"
            }, 
            callback: (res, data) => {
                if(res){
                    setIsModalOpen(false);
                    notification.success({
                        message: "Success",
                        description: "Un Archived successfully"
                    });
                    loadTemplateList();
                }
            }
        })
    }
};

const archiveButtonStyle = {
    height: '44px',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    background: 'var(--Orange-500, #EF6820)',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: '20px',
    marginLeft: "12px",
    borderRadius: "8px"
  };

  const cancelBtnStyle = {
    height: '44px',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    background: "#FFFFFF",
    cursor: 'pointer',
    outline: 'none',
    color: "#475467",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: '20px',
    border: '1px solid var(--Gray-300, #D0D5DD)',
    borderRadius: "8px"
  };

  let locale = {
    emptyText: (
     <div style={{marginTop:'32px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <div>
<Image preview={false} style={{height:'128px',width:'172px'}} src={require('../../../assets/img/template/Illustration.png')} />
      </div>
      <div className="empty-template-title" style={{marginTop:'16px',marginBottom:'8px'}}>No templates found</div>
      <div className="empty-template-title-des" style={{width:'45%'}}>Templates serve as a blueprint to quickly schedule sessions . Get started by clicking the button below and create your first template</div>
      <div>
      <Button styles={{ icon: { marginRight: '-8px' } }} onClick={()=>{
      props?.onClickButton()
    }}
             icon={<FiPlusCircle style={{ height: '24px', width: '24px' }}
            />} style={{
              //    paddingTop: '6px', paddingBottom: '7px',
              height: '44px'
              ,marginTop:'32px',
              backgroundColor: '#EF6820' , color:  '#fff' , fontWeight: 600,
            }}>Create a new template</Button>
      </div>
     </div>
    )
  };

    const render =()=>{
        
        
        return (<>
            <div>
                
        <div>
<div>
<Table loading={pageLoading} locale={locale} columns={column} dataSource={AllTemplateListData} pagination={false}/></div>
        </div>
          <Modal centered={true} 
        closable={false}
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        footer={[
            <div style={{padding: "24px 24px 0px 24px"}}>
            <button style={cancelBtnStyle} onClick={handleCancel}>Cancel</button>
            <button style={archiveButtonStyle} onClick={() => handleOk()} >Unarchive template</button>
            </div>
          ]}
        >
            <div style={{padding: "24px 24px 0px 24px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <img style={{height: "48px", width: "48px"}} src={require('../../../assets/img/archive_alert_icon.png')}/>
                    <img onClick={() => handleCancel()} style={{height: "44px", width: "44px", cursor: "pointer"}} src={require('../../../assets/img/template/modal_close_icon.png')}/>
                </div>
                <div style={{marginTop: "16px", color: "#101828", fontSize: "18px", fontWeight: 600, lineHeight: "28px"}}>
                Are you sure you want to unarchive this template?
                </div>
                <div style={{color: "#475467", fontSize: "14px", fontWeight: 400, lineHeight: "20px", marginTop: '4px'}}>This template will appear on your active templates list and can be used to schedule an experience</div>
            </div>
      </Modal>
        </div>
        </>)
    }

    return render()
}
const mapStateToProps = state => {
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    list: details => dispatch(global.redux.action.categories.list(details)),
    templateList: details => dispatch(global.redux.action.template.list(details)),
    unArchiveActiveTemplates: details => dispatch(global.redux.action.template.changeStatusTemplate(details)),
    setTemplateArchiveFunction: details =>
    dispatch(global.redux.action.settings.setTemplateFuncArchive(details))
   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(ArchivedTemplateList)