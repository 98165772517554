import React, { useState, useEffect } from 'react';
import { Checkbox, Card } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

import './style.css'; // Import custom CSS file

const AssignQuestions = ({ items,userDetails,questionSetId,props,closeModal,clearState,setClearState,triggerFilterQuestionSet }) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (clearState) {
      setCheckedItems([]);
      setFilteredItems([]);
      setClearState(false); // Reset the clear state
    }
  }, [clearState, setClearState]);

  const test_data = [
    {
        id: '65',
        title: 'Volleyball league',
        experiences:[
            {
                id: '1',
                experience_date: "2024-02-14T06:17:00+00:00",
                experience_end_date: "2024-02-14T07:17:00+00:00",
                experience_end_time: "07:17:00+00",
                experience_start_time: "06:17:00+00",
                meta_details:{
                    assign: 1,
                }
            },
            {
                id: '3',
                experience_date: "2024-02-14T13:56:19+00:00",
                experience_end_date: "2024-02-29T21:56:22+00:00",
                experience_end_time: "21:56:22+00",
                experience_start_time: "13:56:19+00",
                meta_details:{
                    assign: 98,
                }
            },
            {
                id: '8',
                experience_date: "2024-02-14T06:17:00+00:00",
                experience_end_date: "2024-02-14T07:17:00+00:00",
                experience_end_time: "07:17:00+00",
                experience_start_time: "06:17:00+00"
                
            },
        ]
    },
    {
        id: '43',
        title: 'Volleyball open session',
        experiences:[
            {
                id: '11',
                experience_date: "2024-03-03T18:22:16+00:00",
                experience_end_date: "2024-03-18T07:22:24+00:00",
                experience_end_time: "07:22:24+00",
                experience_start_time: "18:22:16+00",
            },
        ]
    },
    {
        id: '39',
        title: 'Volleyball scheduled session',
        experiences:[
            {
                id: '56',
                experience_date: "2024-02-14T06:17:00+00:00",
                experience_end_date: "2024-02-14T07:17:00+00:00",
                experience_end_time: "07:17:00+00",
                experience_start_time: "06:17:00+00"
            },
            {
                id: '112',
                experience_date: "2024-02-14T13:56:19+00:00",
                experience_end_date: "2024-02-29T21:56:22+00:00",
                experience_end_time: "21:56:22+00",
                experience_start_time: "13:56:19+00"
            },
            {
                id: '987',
                experience_date: "2024-02-14T06:17:00+00:00",
                experience_end_date: "2024-02-14T07:17:00+00:00",
                experience_end_time: "07:17:00+00",
                experience_start_time: "06:17:00+00",
                meta_details:{
                    assign: 98,
                }
            },
        ]
    },

    {
        id: '778',
        title: 'Volleyball scheduled test',
        experiences:null
    },
];


  useEffect(() => {
    // Filter experiences with assign property
    const filteredIdsArray = items.reduce((acc, item) => {
      if (item.experiences) {
        const filteredExpIds = item.experiences
          .filter(exp => exp.meta_details && exp.meta_details.registration_info_set_id)
          .map(exp => exp.id);
        return [...acc, ...filteredExpIds];
      }
      return acc;
    }, []);

    
      let arrayNumber = filteredIdsArray.map(Number)
      setFilteredItems(arrayNumber);
    
  }, [items]);


  const handleParentCheckboxChange = (parentId, checked) => {
    let newCheckedItems = [...checkedItems];

    // Update the state for parent checkbox
    if (checked) {
      newCheckedItems.push(parentId);
    } else {
      newCheckedItems = newCheckedItems.filter(id => id !== parentId);
    }

    // Update the state for child checkboxes
    items.forEach(item => {
      if (item.id === parentId && item.experiences) {
        item.experiences.forEach(exp => {
          if (!isCheckboxDisabled(exp)) {
            const index = newCheckedItems.indexOf(exp.id);
            if (checked && index === -1) {
              newCheckedItems.push(exp.id);
            } else if (!checked && index !== -1) {
              newCheckedItems.splice(index, 1);
            }
          }
        });
      }
    });

    setCheckedItems(newCheckedItems);
  };

  const handleChildCheckboxChange = (parentId, experienceId, checked) => {
    let newCheckedItems = [...checkedItems];

    // Update the state for child checkbox
    const index = newCheckedItems.indexOf(experienceId);
    if (checked && index === -1) {
      newCheckedItems.push(experienceId);
    } else if (!checked && index !== -1) {
      newCheckedItems.splice(index, 1);
    }

    // Update the state for parent checkbox
    const parentItem = items.find(item => item.id === parentId);
    if (parentItem && parentItem.experiences) {
      const allChildChecked = parentItem.experiences.every(exp => newCheckedItems.includes(exp.id));
      const parentIndex = newCheckedItems.indexOf(parentId);
      if (allChildChecked && parentIndex === -1) {
        newCheckedItems.push(parentId);
      } else if (!allChildChecked && parentIndex !== -1) {
        newCheckedItems.splice(parentIndex, 1);
      }
    }

    setCheckedItems(newCheckedItems);
  };

  const isCheckboxDisabled = (experience) => {
    return experience.meta_details && experience.meta_details.registration_info_set_id;
  };

  const isParentCheckboxDisabled = (parentId) => {
    const parentItem = items.find(item => item.id === parentId);
    if (!parentItem) return true;
    if (!parentItem.experiences) return true;
    return parentItem.experiences.every(exp => isCheckboxDisabled(exp));
  };

  const handleAssignQuestionSet = () => {
    let registerationSetList =[]
    _.map(items,(experience,index)=>{
      _.map(_.get(experience,'experiences'),(details)=>{
        if(_.get(details,'meta_details.registration_info_set_id') &&_.get(details,'meta_details.registration_info_set_id') !=questionSetId){
          registerationSetList.push(_.get(details,'id'))
        }

      })
    })

    setLoading(true);
    const filterOnlyExperiencesId = checkedItems.filter(item => typeof item === 'number')
    let filterIds = _.filter(filterOnlyExperiencesId.concat(filteredItems),(v)=>_.includes(registerationSetList,v)==false)
    props.assignQuestionsAPI({
      body:{
        id:questionSetId,
        experience_ids:filterIds
      },
      callback:(res, data) => {
        
        setLoading(false);
        if(res){
          if(_.get(data, 'success')){
            setCheckedItems([]);
            setFilteredItems([]);
            closeModal();
            triggerFilterQuestionSet();
          }
          
        }
        
      }
    });
          
    
  };

  const toggleChildCheckboxVisibility = (parentId) => {
    setVisibleItems(prevState => ({
      ...prevState,
      [parentId]: !prevState[parentId]
    }));
  };

  const render = () => {
    return (
      <div>
        {
          items.length > 0 ? <div>
          {items.map(item => (
            item.experiences && (
              <Card key={item.id} style={{ marginBottom: '1rem' }}>
                <Checkbox
                  onChange={(e) => handleParentCheckboxChange(item.id, e.target.checked)}
                  checked={checkedItems.includes(item.id)}
                  disabled={isParentCheckboxDisabled(item.id)}
                >
                  <div className='template-title-question'>{item.title}</div>
                </Checkbox>
                { visibleItems[item.id] ? <IoIosArrowUp 
                style={{width: "24px", height:"24px", cursor:"pointer"}}
                onClick={() => toggleChildCheckboxVisibility(item.id)}
                 /> : <IoIosArrowDown 
                 style={{width: "24px", height:"24px", cursor:"pointer"}}
                 onClick={() => toggleChildCheckboxVisibility(item.id)}
                  />}
                <div style={{ marginLeft: 20, display: "flex", flexDirection: 'column', marginTop: "8px" }}>
                  {item.experiences.map(exp => {
                    
                    return(
                      <Checkbox
                    key={exp.id}
                    onChange={(e) => handleChildCheckboxChange(item.id, exp.id, e.target.checked)}
                    checked={checkedItems.includes(exp.id)}
                    disabled={isCheckboxDisabled(exp)}
                    style={{ display: visibleItems[item.id] ? 'none' : '' }}
                    >
                        <div className='template-experince-title'><span>{item.title}</span> <span>{_.get(exp, 'type') ==="open" && "Open session"}</span>{_.get(exp, 'type') !=="open" && <span style={{marginLeft: "6px"}}>{moment.tz(exp.experience_date, !_.isEmpty(_.get(userDetails, '.meta_details.timezone')) ? _.get(userDetails, 'userProfile.meta_details.timezone') : "America/Toronto").format('MMM Do')},</span>} {_.get(exp, 'type') !== "open" && <span>{moment.tz(exp.experience_date, !_.isEmpty(_.get(userDetails, '.meta_details.timezone')) ? _.get(userDetails, 'userProfile.meta_details.timezone') : "America/Toronto").format('hh:mm A')}</span>}{_.get(exp, 'meta_details.registration_info_set_id') && <span className='question-set-name' style={{marginLeft: "12px"}}><img style={{width:"12px", height:"12px"}} src={require('../../assets/img/template/puzzle-piece-02.png')}/> {_.get(exp, 'registration_info_sets.label')}</span>}</div>
                    </Checkbox>
                    )
    })}
                </div>
              </Card>
            )
          ))}
          <hr/>
          <div style={{display:"flex", justifyContent:"flex-end"}}>
            {/* <button className='booked-questions'>Test btn</button> */}
            { checkedItems.length === 0 && <button style={{marginTop:"16px"}} className='assign-qustn-set-btn-disabled'>Assign sessions to question set</button>}
            { checkedItems.length > 0 && !loading && <button onClick={() => handleAssignQuestionSet()} style={{marginTop:"16px"}} className='assign-qustn-set-btn'>Assign sessions to question set</button>}
            { loading && <div style={{marginTop:"16px"}} className='loading-btn-for-assign'><div className='dot-pulse'></div></div>}
          </div>
          </div> : <div className='dot-falling'></div>
        }
        
      </div>
    );
  }

  return render();

  
};

const mapStateToProps = state => {
    return {
        userProfile: _.get(state, 'app.host.profile.data.details'),
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    assignQuestionsAPI: details => dispatch(global.redux.action.questions.assignQuestions(details)),
  
  });
  export default connect(mapStateToProps, mapDispatchToProps)(AssignQuestions)