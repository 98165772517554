import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateFinancesState;
// host actions

export default {
    // get experience templates of logged in host
    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/orders/list`
                },
                actionType: actionType.finances.list,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    ratingList: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/experiences/rating-list`
                },
                actionType: actionType.finances.ratingList,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    view: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/orders/view`
                },
                actionType: actionType.finances.view,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    //Invoice
    getPaymentRequestList: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/payment-requests/list`
                },
                actionType: actionType.finances.paymentRequestList,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    paymentRequestsCreate: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/payment-requests/create`
                },
                actionType: actionType.finances.paymentRequestCreate,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    paymentStripe: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/payment-stripe`
                },
                actionType: actionType.finances.paymentStripe,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    paymentOffline: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/payment-offline`
                },
                actionType: actionType.finances.paymentOffline,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    paymentChangeStatus: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/payment-change-status`
                },
                actionType: actionType.finances.paymentChangeStatus,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}