import { Box, Card, CardContent, Container, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RightSideHeader from "./right-side-header";
import CardSkeleton from "./card-loader";
import _ from 'lodash';
import RightFooter from "./right-footer";



const AccountReviewApproved =(props)=>{
  const [accountStatus, setaccountStatus] = useState(1)
  const [userDetails, setuserDetails] = useState({...props?.userDetails})
useEffect(() => {
  
if( _.get(props,"userDetails.id")){
  setaccountStatus(_.get(props,"userDetails.approved_status")==false?2:1)
  setuserDetails( _.get(props,"userDetails"))

}
}, []);

const commonTitleRender =(text)=>{
  return <Typography component={"div"}  align="left"
  sx={{ color:"#475467", fontSize: {
         xs: "14px", // mobile
         sm: "15px", // tablet
         md: "16px", // small desktop
         lg: "16px",
       },
       fontWeight:"600"
       
  }}
  >
  {text}
  </Typography>
}
const commonDetailRender =(text)=>{
  return <Typography component={"div"}  align="left"
  sx={{
  marginTop:'4px',
  color:"#475467",
  fontSize: {
         xs: "14px", // mobile
         sm: "15px", // tablet
         md: "16px", // small desktop
         lg: "16px",
       },
       fontWeight:"400"
       
  }}
  >
 {text}
  </Typography>
}
return <Box sx={{width:'100%'}} >
<RightSideHeader />
<Box sx={{width:"100%", display:'flex',justifyContent:'center'}}> 

     <Box sx={{width:{
            xs: "90%", // mobile
            sm: "80%", // tablet
            md: "70%", // small desktop
            lg: "65%",
     },display:'flex',justifyContent:'center',flexDirection:'column'}}>
<Box mt={5} textAlign="center">
{accountStatus==2?<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FDEAD7"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF6EE" stroke-width="8"/>
<path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#EF6820" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>:<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FDEAD7"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF6EE" stroke-width="8"/>
<path d="M23.5 28L26.5 31L32.5 25M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#EF6820" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
}


</Box>
<Typography component={"div"} sx={{
color:"#101828",
marginTop:'32px',
fontSize: {
       xs: "17px", // mobile
       sm: "19px", // tablet
       md: "23px", // small desktop
       lg: "30px",
     },
     fontWeight: "600",
     lineHeight: {
        xs: "28px", // mobile
        sm: "30px", // tablet
        md: "33px", // small desktop
        lg: "38px",
      },
}} align="center" >
{accountStatus==2?"Your account is under review":"Your account has been approved"}
</Typography>
<Typography component={"div"} align="center"
sx={{
marginTop:'12px',
color:"#475467",
fontSize: {
       xs: "14px", // mobile
       sm: "15px", // tablet
       md: "16px", // small desktop
       lg: "16px",
     },
     
}}
>
{accountStatus==2?"We need to verify the information you provided before you proceed to the next step.":
"Congratulations, your account has been approved. You may now proceed to the next step to complete onboarding  "}
</Typography>
{accountStatus==2&&<Typography component={"div"} align="center"
sx={{
marginTop:'12px',
color:"#475467",
fontSize: {
       xs: "14px", // mobile
       sm: "15px", // tablet
       md: "16px", // small desktop
       lg: "16px",
     },
     
}}
>
Dont worry - your account will be reviewed within 24 hrs and you will receive and email when there’s an update to your application
</Typography>}
<Box sx={{width:"100%",marginTop:'20px'}}>
<Box component={"div"} sx={{
  borderRadius: "12px",
  border: "1px solid var(--Gray-200, #EAECF0)",
  background: "var(--Base-White, #FFF)"
}}>
  <Box component={"div"} sx={{padding:"16px",display:"flex",alignItems:"center",justifyContent:'space-between'}}>
  <Typography component={"div"} 
sx={{
// marginTop:'12px',
color:"#101828",
fontSize: {
       xs: "16px", // mobile
       sm: "20px", // tablet
       md: "23px", // small desktop
       lg: "24px",
     },
     fontWeight:"600"
     
}}
>
Applicant details 
</Typography>
{accountStatus==2?<Box sx={{
  borderRadius: "16px",
  background: "var(--Gray-100, #F2F4F7)",
  padding:"2px 10px",
  display:'flex',
  alignItems:"center"
}}><Typography 
sx={{
color:"#344054",
fontSize: {
       xs: "14px", // mobile
       sm: "14px", // tablet
       md: "14px", // small desktop
       lg: "14px",
     },
     fontWeight:"500"
     
}}
>
Under review 
</Typography> </Box>:<Box sx={{
  borderRadius: "16px",
  background: "var(--Success-50, #ECFDF3)",
  padding:"2px 10px",
  display:'flex',
  alignItems:"center"
}}><Typography 
sx={{
color:"var(--Success-700, #027A48)",
fontSize: {
       xs: "14px", // mobile
       sm: "14px", // tablet
       md: "14px", // small desktop
       lg: "14px",
     },
     fontWeight:"500"
     
}}
>
Approved 
</Typography> </Box>}
     </Box>
     <Divider />
     <Box component={"div"} sx={{padding:"16px"}}>
  {commonTitleRender("Personal information")}
  {commonDetailRender(`${_.startCase(_.get(userDetails,"first_name"))} ${_.startCase(_.get(userDetails,"last_name"))}`)}
  {commonDetailRender(_.get(userDetails,"contact_details.email"))}
  {commonDetailRender(`+1 ${_.get(userDetails,"contact_details.phone")}`)}

     </Box>
     <Box component={"div"} sx={{padding:"16px",paddingTop:"0px"}}>
     {commonTitleRender("Club/Coach information")}
  {commonDetailRender(_.startCase(_.get(userDetails,"business_type")))}
  {commonDetailRender(_.startCase(_.get(userDetails,"categories[0].name")))}
  {commonDetailRender(_.startCase(_.get(userDetails,"display_name")))}
  
     </Box>
</Box>
<Box 
component={"div"}
sx={{
  marginTop:"24px",
  padding:"10px 18px", 
  // height:'44px',
  borderRadius: "8px",
  border:accountStatus==2? "1px solid var(--Orange-200, #F9DBAF)":"1px solid var(--Orange-500, #EF6820)",
  background: accountStatus==2? "var(--Orange-200, #F9DBAF)":"var(--Orange-500, #EF6820)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  cursor:accountStatus==2?'not-allowed':"pointer"
}}
onClick={()=>{
  if(accountStatus==1){
    props?.onChangeFlow(5)
  }
  
}}
>
<Typography component={"div"} align="center"
sx={{

color:"var(--Base-White, #FFF)",
fontSize: {
       xs: "14px", 
       sm: "15px", 
       md: "16px",
       lg: "16px",
     },
     fontWeight:"600"
     
}}
>
Continue</Typography>

</Box>
</Box>
</Box>
</Box>
<RightFooter />
</Box>

}

export default AccountReviewApproved;