import { Button, Checkbox, Divider, Dropdown, Image, Input, Modal, Popconfirm, Radio, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import MainMenu from '../main-menu';
import { Card, Space } from 'antd';
import { FiSearch } from "react-icons/fi";
import { IoIosMore } from "react-icons/io";

import ActiveTab from './session/active'
import CancelTab from './session/cancel'
import PastTab from './session/past'
import _, { set } from 'lodash';

import './style.css'
import { connect } from 'react-redux';
import { CloseCircleFilled, CloseCircleOutlined, DownloadOutlined, PoweroffOutlined, SearchOutlined } from '@ant-design/icons';
import BulkSchedule from '../bulk-schedule';

import Menus from '../menu';
import { useSearchParams } from 'react-router-dom';
const { TabPane } = Tabs;

const CoachManage = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTagWord, setsearchTagWord] = useState('')
  const localStorageKey = 'selectedOption';
  const [tags, settags] = useState([])
  const [isTagOpen, setisTagOpen] = useState(false)
  const [filterTags, setfilterTags] = useState([])
  const [searchExpName, setsearchExpName] = useState('')
  const [selectedOption, setSelectedOption] = useState(() => {
    // Retrieve the selected option from localStorage on component mount
    return localStorage.getItem(localStorageKey) || null;
  });  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false)

  const [selectedTab, setselectedTab] = useState('Active')


  const handleOptionClick = (option) => {
    
    setSelectedOption(option);
  };

  useEffect(() => {
    // Store the selected option in localStorage whenever it changes
    localStorage.setItem(localStorageKey, selectedOption);
  }, [selectedOption]);

  const options = ['Session List'];
  const [activeKey, setActiveKey] = React.useState('1')
  const onKeyChange = (key) => setActiveKey(key)

  useEffect(() => {

    if (_.size(props?.userProfile?.meta_details?.tags) > 0) {
      tagFunctionHandler(filterTags)
    }
  }, [props?.userProfile?.meta_details?.tags])


useEffect(() => {
  setSelectedOption('Session List')
}, [])

useEffect(() => {
  if(searchParams.get('type')==2){
    handleOptionClick('Schedule')
  }else if(searchParams.get('type')==1){
    handleOptionClick('Session List')
  }

}, [])




  const tagFunctionHandler = (filterTags) => {
    let a = []
    _.map(props?.userProfile?.meta_details?.tags, (v, i) => {
      a = [...a, {
        key: v,
        label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><div  style={{ display: 'flex', alignItems: 'center' }}>
          {_.includes(filterTags, v) == true ? <Image style={{ width: '20px', height: '20px' }} preview={false} src={require('../../assets/img/Checkbox_base.png')} /> : <Image preview={false}
            style={{ width: '20px', height: '20px' }}
            src={require('../../assets/img/Checkbox.png')} />}
          {/* <Checkbox 
          value={_.includes(filterTags,v)?true:false} 
          defaultChecked={_.includes(filterTags,v)?true:false} /> */}
          <div onClick={() => {
          let isCheckTag = _.includes(filterTags, v)
          
          if (isCheckTag == true) {
            let filter_tag = _.filter(filterTags, (tag) => tag != v)
            
            setfilterTags(filter_tag)
            tagFunctionHandler(filter_tag)
          } else {
            setfilterTags(_.size(filterTags)>0?[...filterTags, v]:[v])
            tagFunctionHandler(_.size(filterTags)>0?[...filterTags, v]:[v])
          }

        }} style={{ marginLeft: '12px', color: '#344054', fontSize: '14px', fontWeight: 500 }}>{v}</div></div>
           <div style={{ display: 'flex' }}>
           {/* <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                  ><div>Delete</div></Popconfirm> */}
            <Dropdown
              openClassName='drop-tag-menu'
              overlayClassName='drop-tag-menu'
              rootClassName='drop-tag-menu'
              key={'test-menu'} menu={{
                items: [
                  {
                    label: <div>Delete</div>,
                    key: '0',
                  }], onClick: () => {

                    let tags = _.filter(props?.userProfile?.meta_details?.tags, (t) => t != v ? t : null)

                    updateHostTags(_.compact(tags))

                  }
              }} trigger={['click']}>
              <IoIosMore size={26} color='#475467' />
            </Dropdown>

          </div></div>,
      }, {
        type: 'divider',
      },]

    })
    settags(a)
  }
  const searchFilterTag = (value) => {
    setsearchTagWord(value)
    if (value) {
      let tagsData = [...tags]

      let filter_tag = _.filter(tagsData, (v) => {
        return `${_.lowerCase(v?.key)}`.includes(_.lowerCase(value))
      })

      settags(filter_tag)
    } else {
      tagFunctionHandler()
    }

  }

  const updateHostTags = (tags) => {
    props.updateHostTag({
      body: {
        key: 'tags',
        value: searchTagWord ? [...tags, searchTagWord] : [...tags]
      },
      callback: (res, data) => {
        if (res == true && _.get(data, 'details.id')) {
          setsearchTagWord('')
          props.hostProfile()
        }


      }
    })
  }

  const items = [
    {
      label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><div>
        <Image style={{ width: '20px', height: '20px' }} preview={false} src={require('../../assets/img/manage-tag.png')} /> Tags</div><div>
          <CloseCircleOutlined onClick={() => { setisTagOpen(false); searchFilterTag('') }} size={40} style={{ fontSize: '20px', color: '#4f5b6d' }} />
        </div></div>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <Input
        value={searchTagWord}
        onKeyDown={(e) => {
          if (e.code == 'Enter') {
            
            let a = _.filter(props?.userProfile?.meta_details?.tags, (v) => _.lowerCase(v) == _.lowerCase(searchTagWord))
            
            if (_.size(a) == 0) {
              updateHostTags(props?.userProfile?.meta_details?.tags)
            }
          }


        }}

        onChange={(e) => searchFilterTag(e.target.value)} className='drop-input-menu' style={{ border: 'none', borderWidth: '0px', height: '44px', marginLeft: '-11px' }} prefix={<Image preview={false} style={{ width: '20px', height: '20px', }} src={require('../../assets/img/manage-add.png')} />} placeholder='Search or Create' />,
      key: '1',
    },
    {
      type: 'divider',
    },
    ...tags
  ];
  const render = () => {
    return (
      <div style={{ width: "100%", float: "left" }}
      // onClick={()=>setisTagOpen(false)}
      >
        <div style={{ width: '100%', }}>
          {/* <MainMenu {...props} manageTab={true} /> */}
          
        </div>
          <Modal centered={true} maskClosable
                 onCancel={()=>setshowDeleteConfirm(false)} 
                  style={{padding:'68px 18px',width:'836px'}} footer={null} open={showDeleteConfirm}>
                  <div style={{    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',}}>
                  <span style={{    fontSize: '19px',fontWeight: '600'}}>Are you sure you wish to delete this tag?</span>
                  <p style={{color:'#475467',fontSize:'16px'}}>You will no longer be able to apply this tag to any sessions, nor will you be able to filter by it.</p>
                  
                  </div>
                  <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
    <Button onClick={()=>setshowDeleteConfirm(false)} className='unsaved-btn-cancel' style={{backgroundColor:'#a7a7a7',marginRight:23,width:'23%'}}
             >Cancel</Button>
                  <Button onClick={()=>{

                  }} className='form-btn-4 unsaved-btn' style={{backgroundColor:'#e85f25',width:'23% !important'}}
             >Continue</Button></div>
                  </Modal>
                  <div className='container' style={{maxWidth: '100%' }}>
                    <div className='row'>
                    <div style={{width: '100%' }}>
          <div style={{marginBottom: "15px"}}>
          <Menus/>
          </div>
        
          {/* <div>
            <p className='header-manage-title'>Manage</p>
            <p className='header-manage-des'>Schedule and manage sessions on the Communiti platform.</p>
          </div> */}
          <Divider />

          <div style={{ display: 'flex' }}>
            <div className='manage-session-btn' style={{ marginRight: '50px',marginLeft:'2%' }}>
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`row radio-button ${selectedOption === option ? 'selected' : ''}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </div>
              ))}
            </div>
            <div style={{ width: '100%' }}>
              {/* {selectedOption=="Session List"?<ActiveTab 
                  filter_data_value={filterTags} 
                  searchExpName={searchExpName}
                  onChangeTab={(e) => onKeyChange(e)} />:<BulkSchedule selectedOption={selectedOption} />} */}

<ActiveTab 
                  filter_data_value={filterTags} 
                  searchExpName={searchExpName}
                  onChangeTab={(e) => onKeyChange(e)} />

            </div>
          </div>

        </div>
                    </div>
                  </div>
        
      </div>
    )
  }

  return render()
}
const mapStateToProps = state => {
  
  return {

    userProfile: _.get(state, 'app.host.profile.data.details'),

  }
}

const mapDispatchToProps = dispatch => ({
  updateHostTag: details => dispatch(global.redux.action.host.updateHostTag(details)),
  hostProfile: details => dispatch(global.redux.action.host.profile(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(CoachManage)