import React, {useEffect, useState} from 'react';
import { Radio, Button, Table, Modal, Form, notification, Input, Pagination } from 'antd';
import { connect } from 'react-redux';
import _ from "lodash";
import { useNavigate, useSearchParams } from 'react-router-dom';
import  ExportJsonCsv  from '../export-csv/intex';
import moment from 'moment-timezone';
import ReactQuill from 'react-quill';
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { Scrollbars } from 'react-custom-scrollbars';

import "./style.css";
import FinancesList from '../finances/list';
import Menus from '../menu';

const CustomersList = (props) => {
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
    const [selectedTab, setSelectedTab] = useState('profile');
    const [orderData, setOrderData] = useState([]);
    const [accountOrderData, setAccountOrderData] = useState([]);
    const [financeOrderData, setFinanceOrderData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [getExpId, setExpId] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [profileOrderMeta, setProfileOrderMeta] = useState({});
    const [pageLoading, setPageLoading] = useState(true);
    const [accountOrderMeta, setAccountOrderMeta] = useState({});
    const [accountPageLoading, setAccountPageLoading] = useState(true);
    const [searchText, setSearchText] = useState('');

    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 25,
      total: 0,
    });

    const [accountPagination, setAccountPagination] = useState({
      current: 1,
      pageSize: 25,
      total: 0,
    });
      
      const columns = [
        {
          title: 'First Name',
          dataIndex: 'first_name',
          key: 'first_name',
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'Date of Birth',
            dataIndex: 'date_of_birth',
            key: 'date_of_birth',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          render:(text, item) => {
            
            return <div style={{display:"flex", flexDirection: "row"}}>
              <span onClick={() => viewProfile(item)} className='action-buttons' style={{cursor: "pointer"}}>View Profile</span>
              <span onClick={() => showEmailModal(item)} className='action-buttons' style={{cursor: "pointer",marginLeft: "12px"}}>Email</span>
              </div>
          }
      },
      ];

      const accountColumns = [
        {
          title: 'First Name',
          dataIndex: 'account_first_name',
          key: 'account_first_name',
        },
        {
            title: 'Last Name',
            dataIndex: 'account_last_name',
            key: 'account_last_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          render:(text, item) => {
            
            return <div style={{display:"flex", flexDirection: "row"}}>
              <span onClick={() => viewAccount(item)} className='action-buttons' style={{cursor: "pointer"}}>View Account</span>
              <span onClick={() => showEmailModal(item)} className='action-buttons' style={{cursor: "pointer",marginLeft: "12px"}}>Email</span>
              </div>
          }
      },
      ];

      const headers = [
        {
          name: 'First Name',
          key: 'first_name',
        },
        {
          name: 'Last Name',
            key: 'last_name',
        },
        {
          name: 'Gender',
            key: 'gender',
        },
        {
          name: 'Date of Birth',
            key: 'date_of_birth',
        },
      ]

      const accountHeaders = [
        {
          name: 'First Name',
          key: 'account_first_name',
        },
        {
          name: 'Last Name',
            key: 'account_last_name',
        },
        {
          name: 'Email',
            key: 'email',
        },
      ];

      const orderHeaders = [
        {
          key: 'ptitle',
          name: 'Program Title',
        },
        {
          key: 'finance_date',
          name: 'Date',
        },
        {
          key: 'finance_time',
          name: 'Time',
        },
        {
          key: 'finance_status',
          name: 'Status',
        },
        {
          key: 'registar_name',
          name: "Registrant's Full Name",
        },
        {
          key: 'finance_amount',
          name: "Amount", 
        },
      ]

      useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const page = parseInt(urlParams.get('profile_page')) || 1;
        const pageSize = parseInt(urlParams.get('profile_pageSize')) || 25;
        setPagination((prev) => ({ ...prev, current: page, pageSize }));
        profileOrderListData();
        orderListCsv();

        const account_page = parseInt(urlParams.get('profile_page')) || 1;
        const account_pageSize = parseInt(urlParams.get('profile_pageSize')) || 25;
        setAccountPagination((prev) => ({ ...prev, current: account_page, account_pageSize }));
        accountOrderListData();
      }, [])

      const handleRadioChange = (e) => {
        
        setSelectedTab(e.target.value);
      };

      const profileOrderListData = (pageSize = 25, search) => {
        setPageLoading(true);
        let params = {
          sort: 'created_at.desc',
          expand: 'experience,experience_template,experience,experience_template_photos,host,guest',
          user_type: 1,
          limit: pageSize,
          distinct_by: "profile",
          search: _.lowerCase(search),
          search_type: 3
        };

        if(_.isEmpty(search)){
          delete params.search;
          delete params.search_type
        }
        props.financesListApi({
            params: params,
              callback:(response, data) => {
                setPageLoading(false)
                
                if(response){
                  setPagination((prev) => ({ ...prev, total: _.get(data, 'data.meta.total', {}) }));
                  setProfileOrderMeta(_.get(data, 'data.meta', {}));
                
                let dataItems = [];
                if(data){
                  setLoading(false)
                }
                if(_.size(_.get(data, 'data.items')) > 0){
                    
                    dataItems =   _.get(data, 'data.items').map((value, index) => {
                        
                        return{
                            key: _.get(value, 'profile'),
                            first_name: _.startCase(_.get(value, 'profile_info.first_name')),
                            last_name: _.startCase(_.get(value, 'profile_info.last_name')),
                            gender: _.startCase(_.get(value, 'profile_info.gender')),
                            date_of_birth: _.get(value, 'profile_info.date_of_birth'),
                            order_id: _.get(value, 'id'),
                            profile_id: _.get(value, 'profile'),
                            guest: _.get(value, 'guest'),
                            experience: _.get(value, 'experience')
                        }
                    })

                    setOrderData(dataItems);
                   
                } else {
                  setOrderData([]);
                }
              }
              
            }
        })
        
      }  

      const accountOrderListData = (pageSize = 25, search) => {
        setAccountPageLoading(true);
        let params = {
          sort: 'created_at.desc',
              expand: 'experience,experience_template,experience,experience_template_photos,host,guest',
              user_type: 1,
              limit: pageSize,
              distinct_by: "guest",
              search: _.lowerCase(search),
              search_type: 2
        };
        if(_.isEmpty(search)){
          delete params.search;
          delete params.search_type;
        };
        props.financesListApi({
            params: params,
              callback:(response, data) => {
                // 
                setAccountPageLoading(false);
                if(response){
                  setAccountPagination((prev) => ({ ...prev, total: _.get(data, 'data.meta.total', {}) }));
                  setAccountOrderMeta(_.get(data, 'data.meta', {}));
                
                let dataItems = [];
                if(data){
                  setLoading(false)
                }
                if(_.size(_.get(data, 'data.items')) > 0){
                    dataItems =   _.get(data, 'data.items').map((value, index) => {
                        
                        return{
                            key: index,
                            account_first_name: _.startCase(_.get(value, 'guest.first_name')),
                            account_last_name: _.startCase(_.get(value, 'guest.last_name')),
                            email: _.get(value, 'guest.contact_details.email'),
                            order_id: _.get(value, 'id'),
                            guest_id: _.get(value, 'guest.id'),
                            guest: _.get(value, 'guest'),
                            experience: _.get(value, 'experience')
                        }
                    })

                    setAccountOrderData(dataItems);
                } else {
                  setAccountOrderData([]);
                }
              }
            }
        })
      }  

      const viewProfile = (value) => {
        
        navigate(`/communiti/view-profile?order_id=${_.get(value, 'order_id')}&profile_id=${_.get(value, 'profile_id')}&exp_id=${_.get(value, 'experience.id')}&menu=reports`)
      };

      const viewAccount = (value) => {
        
        navigate(`/communiti/view-account?guest_id=${_.get(value, 'guest_id')}&menu=reports`)
      };

      const showEmailModal = (value) => {
        
        setExpId(value)
        // getEmailList(value)
        setIsEmailModalOpen(true);
        form.setFieldsValue({
          emails: _.get(value, 'guest.contact_details.email')
        })
      };
      
      const handleEmailOk = () => {
        setExpId()
        setIsEmailModalOpen(false);
        setErrorMessage('')
        form.setFieldsValue({
            title:'',
            message:'',
            emails:''
        })
      };
      
      const handleEmailCancel = () => {
        setExpId()
        setIsEmailModalOpen(false);
        setErrorMessage('');
        form.setFieldsValue({
            title:'',
            message:'',
            emails:''
        })
      };

      const onFinish = (values) => {
        
      props.sendEmailAPI({
        body:{
            experience: _.get(getExpId, 'experience.id'),
            subject: values.title,
            message: values.message,
            guest_ids: _.get(getExpId, 'guest.id')
        },
        callback: (res, val) => {
            
            if(_.get(val, 'success')){
              notification.success({
                message: "Success",
                description: "Email has been sent."
              })
                setIsEmailModalOpen(false);
                form.setFieldsValue({
                    title:'',
                    message:'',
                    emails:''
                })
            }
        }
      })
      };
      const onFinishFailed = (errorInfo) => {
        
      };

      const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
          return 
        }
        if (type === 'next') {
          return 
        }
        return originalElement;
      };

      const accountItemRender = (_, type, originalElement) => {
        if (type === 'prev') {
          return 
        }
        if (type === 'next') {
          return 
        }
        return originalElement;
      };

      const handlePageChange = (page, pageSize) => {
        setPagination((prev) => ({ ...prev, current: page }));
        // fetchData(page, pageSize); the experience load list have to be comes here
        profileOrderListData(page, pageSize)
        window.history.pushState({ page, pageSize }, "Order Profile Title", `?profile_page=${page}&profile_pageSize=${pageSize}&menu=reports`);
      };

      const handleAccountPageChange = (page, pageSize) => {
        setAccountPagination((prev) => ({ ...prev, current: page }));
        // fetchData(page, pageSize); the experience load list have to be comes here
        accountOrderListData(page, pageSize)
        window.history.pushState({ page, pageSize }, "Order Account Title", `?account_page=${page}&account_pageSize=${pageSize}&menu=reports`);
      };

      const handleScroll = (data, type) => {
        if (pageLoading == false && data && data.top > 0.9 && _.get(profileOrderMeta, 'total') > 25) {
          if (_.size(orderData) < _.get(profileOrderMeta, 'total')) {
            profileOrderListData(_.get(profileOrderMeta, 'limit') + 25)
          }
    
    
        }
    
      }

      const handleAccountScroll = (data, type) => {
        if (accountPageLoading == false && data && data.top > 0.9 && _.get(accountOrderMeta, 'total') > 25) {
          if (_.size(accountOrderData) < _.get(accountOrderMeta, 'total')) {
            accountOrderListData(_.get(accountOrderMeta, 'limit') + 25)
          }
    
    
        }
    
      };

      const handleSearch = (value) => {
          setSearchText(value);
          if(selectedTab === 'profile'){
            profileOrderListData(25, value);
          };

          if(selectedTab === 'account'){
            accountOrderListData(25, value);
          };
          
      };

      const orderListCsv = () => {
        let params = {
          sort: 'created_at.desc',
          expand: 'experience,experience_template,experience,experience_template_photos,host,guest',
          user_type: 1,
          limit: 500,
        };
        props.financesListApi({
            params: params,
            callback: (res, data) => {
              if(res){
                let csvData = [];
                if(_.size(_.get(data, 'data.items')) > 0){
                csvData =  _.get(data, 'data.items').map((value, index) =>{
                  return{
                    ptitle:  _.startCase(_.get(value, 'experience.template.title')),
                    finance_date: moment(_.get(value, 'experience.experience_date')).format("MM/DD/YYYY"),
                    finance_time: moment.tz(_.get(value, 'experience.experience_date'), _.get(value, 'experience.meta_details.timezone')).format("hh:mm A"),
                    finance_status: _.get(value, 'experience.template.status') === 1 ? "Paid" :  _.get(value, 'experience.template.status') === 4 ? "Cancelled" : '',
                    registar_name: `${_.startCase(_.get(value, 'guest.first_name'))} ${_.startCase(_.get(value, 'guest.last_name'))}`,
                    finance_amount: `$${_.get(value, 'experience.template.host_price')}`
                  }
                })
                setFinanceOrderData(csvData)
              }
            }
            }
        })
      } 
 
    const render = () => {
        return (
            <div>
              {/* <MainMenu {...props} financeTab={true} /> */}
              <div className='container' style={{maxWidth: '100%' }}>
                <div className="row">
                <Menus/>
                <div className='col-12' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <h3>Customers</h3>
        <span>People who have registered for a session</span>
      </div>
      <div>
        {/* <Button>Export .csv</Button> */}
        
        
      </div>
    </div>
    <div className='col-12' style={{marginTop:"12px"}}>
    <div className='card' style={{marginBottom: "12px", boxShadow: "none"}}>
    <div className='col-12' style={{marginTop: "6px", display: "flex", flexDirection: "row", marginTop: "16px"}}>
            <Radio.Group  value={selectedTab} onChange={handleRadioChange} defaultValue="profile" buttonStyle="solid">
              <Radio.Button className='custom-radio-button' value="profile">Profile</Radio.Button>
              <Radio.Button className='custom-radio-button' value="account">Account</Radio.Button>
              <Radio.Button className='custom-radio-button' value="orders">Orders</Radio.Button>
            </Radio.Group>
            <Input 
            prefix={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>}
              style={{marginLeft: "48px"}} 
              className='search_filter_input'
              placeholder='Search'
              onChange={(e) => handleSearch(e.target.value)}
              />
              { selectedTab === 'profile' ? <ExportJsonCsv style={{fontWeight: 600, borderRadius: "6px", backgroundColor: "#fff", borderColor: "#D0D5DD", cursor: "pointer", height: "50px", marginLeft: "12px", border: '1px solid var(--Gray-300, #D0D5DD)' }} headers={headers} items={orderData}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Export .csv</ExportJsonCsv> : selectedTab === 'account' ? <ExportJsonCsv style={{fontWeight: 600,borderRadius: "6px", backgroundColor: "#fff", borderColor: "#D0D5DD", cursor: "pointer", height: "50px", marginLeft: "12px", border: '1px solid var(--Gray-300, #D0D5DD)'}} headers={accountHeaders} items={accountOrderData}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Export .csv</ExportJsonCsv> : <ExportJsonCsv style={{fontWeight: 600,borderRadius: "6px", backgroundColor: "#fff", borderColor: "#D0D5DD", cursor: "pointer", height: "50px", marginLeft: "12px", border: '1px solid var(--Gray-300, #D0D5DD)'}} headers={orderHeaders} items={financeOrderData}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Export .csv</ExportJsonCsv>}
            </div>
            <div className='customer-table'>
              {
                selectedTab === 'profile' && (
                  <>
                  <Scrollbars 
            className="hidden-scroll-x"
            onScrollFrame={(e) => handleScroll(e)}
            style={{ height: 'calc(100vh - 300px)' }}
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            >
              <Table loading={pageLoading} dataSource={orderData} columns={columns} pagination={false} />
            </Scrollbars>
                  </>
                
                
                )
              }

              {
                 selectedTab === 'account' && (
                  <>
                  <Scrollbars 
            className="hidden-scroll-x"
            onScrollFrame={(e) => handleAccountScroll(e)}
            style={{ height: 'calc(100vh - 300px)' }}
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            >
              <Table loading={accountPageLoading} dataSource={accountOrderData} columns={accountColumns} pagination={false} />
            </Scrollbars>
                  {/* <div style={{ display:"flex", justifyContent: "space-between", marginTop: '16px', marginBottom: "10px"}}>
                                      <Button
                                        onClick={() => handleAccountPageChange(accountPagination.current - 1, accountPagination.pageSize)}
                                        disabled={accountPagination.current === 1}
                                      >
                                      <FaArrowLeftLong/>  Previous
                                      </Button>
                                      <Pagination
                                        current={accountPagination.current}
                                        pageSize={accountPagination.pageSize}
                                        total={accountPagination.total}
                                        itemRender={accountItemRender}
                                        onChange={handleAccountPageChange}
                                        onShowSizeChange={handleAccountPageChange}
                                      />
                                      <Button
                                        onClick={() => handleAccountPageChange(accountPagination.current + 1, accountPagination.pageSize)}
                                        disabled={accountPagination.current * accountPagination.pageSize >= pagination.total}
                                      >
                                        Next <FaArrowRightLong />
                                      </Button>
                                    </div> */}
                  </>
                  
                  )
              }

              {
                 selectedTab === 'orders' && (
                  <FinancesList search={searchText}/>
                  )
              }
            
            </div>
            </div>
            </div>
            <div>
<Modal centered={true} title="Email" footer={null} open={isEmailModalOpen} onOk={handleEmailOk} onCancel={handleEmailCancel}>
<Form
    name="basic"
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    form={form}
    layout='vertical'
  >
    <div style={{marginBottom: "10px"}}>{_.get(getExpId, 'experience.template.title')}</div>
    <Form.Item
      label="Email"
      name="emails"
    >
     <Input disabled/>
      
    </Form.Item>
    
    <Form.Item
      label="Title"
      name="title"
      rules={[
        {
          required: true,
          message: 'Please input your title!',
        },
      ]}
    >
      <Input/>
    </Form.Item>

    <Form.Item
      label="Message"
      name="message"
      rules={[
        {
          required: true,
          message: 'Please input your message!',
        },
      ]}
    >
      {/* <Input.TextArea rows={7} disabled={errorMessage ? true : false} /> */}
      <ReactQuill theme="snow"/>
    </Form.Item>
    <Form.Item
    >
      <Button style={{width: "100%", backgroundColor: "#e85d04", color: "#fff", marginTop: "50px"}} type="primary" htmlType="submit">
    Send
      </Button>
    </Form.Item>
  </Form>
      </Modal>
</div>
                </div>
              </div>
              
                
            </div>
          )
    };
    return render();
}

const mapStateToProps = state => {
    return {
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    financesListApi: details => dispatch(global.redux.action.finances.list(details)),
    sendEmailAPI: details => dispatch(global.redux.action.sendEmail.postEmails(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(CustomersList)