import { CalendarFilled } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import "react-multi-date-picker/styles/layouts/mobile.css"

import Icon from "react-multi-date-picker/components/icon"
import moment from "moment";
import { DateObject } from "react-multi-date-picker";
import _ from "lodash";

export default function DatePickerCompoent(props) {
  const [value, setValue] = useState(new Date());
  const [datePciekerModal, setDatePciekerModal] = useState(false);
  const calendarRef = useRef()
  useEffect(() => {
  }, [])
  
  const onChangeButton =(e)=>{
    
    if(datePciekerModal==false){
      calendarRef.current.openCalendar()
      setDatePciekerModal(true);
    }
    
  }

const {startDate} =props
//Thu Jul 06 2023 15:39:09 GMT+0530 (India Standard Time)
const currentDate = new DateObject()
 currentDate.add(1, "months")
//var month = dateVar.split("-")[0];
let addDisableDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
if(!_.isEmpty(_.get(props, 'startingDate'))){
  let year = parseInt(props.startingDate.split("-")[0])
  let month = parseInt(props.startingDate.split("-")[1])
  let day = parseInt(props.startingDate.split("-")[2])
  
  
  
    return <Button 
    onClick={(e)=>onChangeButton(e)}
      style={{width:'54% !important'}} className='form-btn-4-schedule-template-date'>
    Select your Session Dates <DatePicker currentDate={
      new DateObject({ 
        year: year,
        month: month,
        day: day
      })
    } onClose={()=>setDatePciekerModal(false)} ref={calendarRef} plugins={[
      <DatePanel/>
     ]} minDate={addDisableDate} sort={true}  style={{width:'88%'}} 
     name={"Session Dates"} disabled={props.disabled} className="rmdp-mobile" render={<Icon/>} numberOfMonths={2} multiple={true} onChange={
        props.onChangePickers
    } /></Button>;
} else {
  return <Button 
    onClick={(e)=>onChangeButton(e)}
      style={{width:'54% !important'}} className='form-btn-4-schedule-template-date'>
    Select your Session Dates <DatePicker  onClose={()=>setDatePciekerModal(false)} ref={calendarRef} plugins={[
      <DatePanel/>
     ]} minDate={addDisableDate} sort={true}  style={{width:'88%'}} 
     name={"Session Dates"} disabled={props.disabled} className="rmdp-mobile" render={<Icon/>} numberOfMonths={2} multiple={true} onChange={
        props.onChangePickers
    } /></Button>;
}


  
}