let host = window.location.host;
let hostName = host.split(":");


const baseUrl = `${window.location.protocol}//${hostName[0]}`;

const MODES = {
    DEVELOPMENT: {
        API: {
            BASE_URL: `${baseUrl}`,
            API_URL:'https://communitiapi.com',
            CLIENT_ID:'J0SOKSPD',
            CLIENT_SECRET:'btXQd8QthlfFTd29FQFzlMLEK1KxlJh0'
        },
        FACEBOOK: {
            APP_ID: '252849962899986',
            APP_NAME: 'com.studioq.communitiguestapp'
        },
        GOOGLE: {
       GOOGLE_CLIENT_ID:'642317044785-rlpoed3dusngqdlgrr97vv35tv7147h4.apps.googleusercontent.com',
        },
        GOOGLEMAP: {
            LOCATION: 'AIzaSyAIU0XlymmFrHfYvPDn-uPCIcyCdTphABE',
            PRODUCTION_LOCATION_KEY: 'AIzaSyAIU0XlymmFrHfYvPDn-uPCIcyCdTphABE'
        },
        STRIPE: {
            URL: 'https://stripe.com/legal',
            SECRET_KEY: "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",
            PUBLISHABLE_KEY: "pk_test_Vl7NdHPf2yLEUDou4TTJTG0N00F35InABj",
            KEY: 'pk_test_Vl7NdHPf2yLEUDou4TTJTG0N00F35InABj',
            BASE_URL: 'https://api.stripe.com',
            SRIPE_MERCHANT_ID:"merchant.com.communitiapp",
        },
        COMMUNITI_PRIVACY:{
            URL:'https://www.communiti.app/privacy-policy',
            TERMS:'https://www.communiti.app/terms-of-service'
        },
        CHAT_SYSTEM: {
            CHATKITTY: {
                API_KEY: '47e4b1e4-0c84-4ed5-b2f7-3a679a937ed9',
                CLIENT_ID: '47e4b1e4-0c84-4ed5-b2f7-3a679a937ed9',
                CLIENT_SECRET: 'c132dca0-661d-435a-b381-b3f7d15f1508',
                API_BASE_URL: 'https://api.chatkitty.com/v1/applications/7503',
                API_AUTHORIZATION_URL: 'https://authorization.chatkitty.com/',
            },
            EMAIL_DOMAIN: 'communiti.app',
            CONSTANT_PASSWORD: 'fuwl9VffEp6QZhxW',
            USERNAME_PREFIX: 'communiti_coach_production'
        },
        FIREBASE: {
            apiKey: "AIzaSyBsjPUuWTxiDxcWm7DNfygfUZqS14oKBYE",
            authDomain: "communiti-production-app-chat.firebaseapp.com",
            projectId: "communiti-production-app-chat",
            storageBucket: "communiti-production-app-chat.appspot.com",
            messagingSenderId: "170742844089",
            appId: "1:170742844089:web:9d0d65145a0a39d1ea2968",
            measurementId: "G-Z7S0YTDTVJ"
        },
        SENTRY_CONFIG:{
            DSN: "https://3eeddc9190494fd4be48ffeba4775e4a@o1397951.ingest.us.sentry.io/6765724",
        },
        ONESIGNAL:{
            APP_ID:"fc0899d8-f39c-458b-a546-14946c3b989f"
        }
        
    }
};

const getEnvVariables = ()=>{
    return MODES.DEVELOPMENT;
};

export default {
    ...getEnvVariables()
};