import React, { useState, useEffect } from 'react';
import { Table, Image, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import _ from  'lodash';
import moment from 'moment';
import AssignQuestions from './assign-questions';

import './style.css';

const AssignQuestionSetList = (props) => {
        
    const[questionSetList, setQuestionList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getGroupByData, setGroupBydata] = useState([]);
    const [clearChildState, setClearChildState] = useState(false);
    const [loading, setLoading] = useState(true);

    const items = [
        {
            id: '65',
            title: 'Volleyball league',
            experiences:[
                {
                    id: '1',
                    experience_date: "2024-02-14T06:17:00+00:00",
                    experience_end_date: "2024-02-14T07:17:00+00:00",
                    experience_end_time: "07:17:00+00",
                    experience_start_time: "06:17:00+00",
                    meta_details:{
                        assign: 1,
                    }
                },
                {
                    id: '3',
                    experience_date: "2024-02-14T13:56:19+00:00",
                    experience_end_date: "2024-02-29T21:56:22+00:00",
                    experience_end_time: "21:56:22+00",
                    experience_start_time: "13:56:19+00",
                    meta_details:{
                        assign: 98,
                    }
                },
                {
                    id: '8',
                    experience_date: "2024-02-14T06:17:00+00:00",
                    experience_end_date: "2024-02-14T07:17:00+00:00",
                    experience_end_time: "07:17:00+00",
                    experience_start_time: "06:17:00+00"
                    
                },
            ]
        },
        {
            id: '43',
            title: 'Volleyball open session',
            experiences:[
                {
                    id: '11',
                    experience_date: "2024-03-03T18:22:16+00:00",
                    experience_end_date: "2024-03-18T07:22:24+00:00",
                    experience_end_time: "07:22:24+00",
                    experience_start_time: "18:22:16+00",
                },
            ]
        },
        {
            id: '39',
            title: 'Volleyball scheduled session',
            experiences:[
                {
                    id: '56',
                    experience_date: "2024-02-14T06:17:00+00:00",
                    experience_end_date: "2024-02-14T07:17:00+00:00",
                    experience_end_time: "07:17:00+00",
                    experience_start_time: "06:17:00+00"
                },
                {
                    id: '112',
                    experience_date: "2024-02-14T13:56:19+00:00",
                    experience_end_date: "2024-02-29T21:56:22+00:00",
                    experience_end_time: "21:56:22+00",
                    experience_start_time: "13:56:19+00"
                },
                {
                    id: '987',
                    experience_date: "2024-02-14T06:17:00+00:00",
                    experience_end_date: "2024-02-14T07:17:00+00:00",
                    experience_end_time: "07:17:00+00",
                    experience_start_time: "06:17:00+00",
                    meta_details:{
                        assign: 98,
                    }
                },
            ]
        },
    
        {
            id: '778',
            title: 'Volleyball scheduled test',
            experiences:null
        },
    ];
    

    useEffect(() => {
        groupByExperiences();
        handleFilterQuestionSetList();
    },[]);

    useEffect(() => {
        const extractExperiences = () => {
            const experiences = items.reduce((acc, currentItem) => {
                // If experiences array exists and is not null, concatenate it
                if (currentItem.experiences && currentItem.experiences.length > 0) {
                    // Add title to each experience object
                    const experiencesWithTitle = currentItem.experiences.map(experience => ({
                        ...experience,
                        title: currentItem.title
                    }));
                    return acc.concat(experiencesWithTitle);
                }
                return acc;
            }, []);
            
            // setAllExperiences(experiences); // Set all experiences in state
        };

        extractExperiences();
    }, []);

    const CreateQuestionSetIcon = () => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
      );

      const columns = [
        {
          title: <div className='questions-list-table-title'>Session name</div>,
          dataIndex: 'session_name',
          key: 'session_name',
        },
        {
          title: <div className='questions-list-table-title'>Session type</div>,
          dataIndex: 'session_type',
          key: 'session_type',
        },
        {
            title: <div className='questions-list-table-title'>Start date</div>,
            dataIndex: 'start_date',
            key: 'start_date',
          },
        {
          title: <div className='questions-list-table-title'>Quick Actions</div>,
          dataIndex: 'actions',
          key: 'actions',
          render: (text, item) => {
            return(
              <div style={{display: "flex", flexDirection: "row"}}>
                <div onClick={() => removeQuestionSet(item)} className='question-set-quick-actions'><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8985_3753)">
    <path d="M12.0833 5.99996L8.08333 9.99996M8.08333 5.99996L12.0833 9.99996M2.56333 8.63996L5.44333 12.48C5.678 12.7928 5.79533 12.9493 5.94403 13.0621C6.07574 13.1621 6.22488 13.2366 6.38385 13.282C6.56334 13.3333 6.75889 13.3333 7.15 13.3333H12.2167C13.3368 13.3333 13.8968 13.3333 14.3246 13.1153C14.701 12.9236 15.0069 12.6176 15.1987 12.2413C15.4167 11.8134 15.4167 11.2534 15.4167 10.1333V5.86663C15.4167 4.74652 15.4167 4.18647 15.1987 3.75864C15.0069 3.38232 14.701 3.07636 14.3246 2.88461C13.8968 2.66663 13.3368 2.66663 12.2167 2.66663H7.15C6.75889 2.66663 6.56334 2.66663 6.38385 2.71789C6.22488 2.76329 6.07574 2.83786 5.94403 2.93779C5.79533 3.05063 5.678 3.20707 5.44333 3.51996L2.56333 7.35996C2.3912 7.58946 2.30514 7.70422 2.27196 7.83025C2.24268 7.94149 2.24268 8.05842 2.27196 8.16967C2.30514 8.2957 2.3912 8.41045 2.56333 8.63996Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8985_3753">
      <rect width="16" height="16" fill="white" transform="translate(0.75)"/>
    </clipPath>
  </defs>
</svg> Remove</div>
                </div>
            )
          }
        },
      ];

      let locale = {
        emptyText: (
         <div style={{marginTop:'32px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <div>
    <Image preview={false} style={{height:'128px',width:'172px'}} src={require('../../assets/img/template/Illustration.png')} />
          </div>
          <div className="empty-template-title" style={{marginTop:'16px',marginBottom:'8px'}}>No sessions assigned</div>
          <div className="empty-template-title-des" style={{width:'45%'}}>Assign sessions to this question set by clicking the button below and selecting the sessions you want<br/> assigned to this question set</div>
          <div style={{marginTop: "32px"}}>
              <button onClick={() =>showModal()} className='create-question-set-btn'><CreateQuestionSetIcon/> Assign sessions</button>
            </div>
         </div>
        )
      };

      const groupByExperiences = () => {
        props.groupByExperincesListAPI({
            params:{
                group_by:'template',
                sort:'created_at.desc',
                host:_.get(props, 'userProfile.id'),
                status:1,
                limit:500,
                expand: 'registration_info_sets'
            },
            callback:(res, data) => {
                
                if(res){
                    if(_.size(_.get(data, 'data.items')) > 0){
                        setGroupBydata(_.get(data, 'data.items'));
                    }
                }
            }
        })
      };

      const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
        setClearChildState(true);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
        setClearChildState(true);
      };

    const handleFilterQuestionSetList = () => {
        setLoading(true)
        props.groupByExperincesListAPI({
            params:{
                group_by:'template',
                sort:'created_at.desc',
                host:_.get(props, 'userProfile.id'),
                status:1,
                limit:500,
                expand: 'registration_info_sets',
                registration_info_set_id: _.get(props, 'questionSetId')
            },
            callback:(res, data) => {
                
                        if(res){
                            setLoading(false);
                            if(_.get(data, 'data.items')){
                                const experiences = _.get(data, 'data.items').reduce((acc, currentItem) => {
                                    // If experiences array exists and is not null, concatenate it
                                    if (currentItem.experiences && currentItem.experiences.length > 0) {
                                        // Add title to each experience object
                                        const experiencesWithTitle = currentItem.experiences.map(experience => ({
                                            ...experience,
                                            title: currentItem.title
                                        }));
                                        return acc.concat(experiencesWithTitle);
                                    }
                                    return acc;
                                }, []);
                                

                                let a =[]

                                    _.map(experiences,(value)=>{
                                        a.push({
                                            key:_.get(value,'id'),
                                            id: _.get(value,'id'),
                                            session_name: <div className='questions-list-table-title'>{_.get(value, 'title')}</div>,
                                            session_type: <div className='questions-list-table-title'>{_.startCase(_.get(value, 'type'))}</div>,
                                            start_date: <div style={{display:"flex", flexDirection:'column'}}><div className='questions-list-table-title'>{moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(props, 'userDetails.meta_details.timezone')) ? _.get(props, 'userDetails.meta_details.timezone') : "America/Toronto").format('MMM Do, YYYY')} {_.get(value, 'type') !== 'open' && "-"}  {_.get(value, 'type') !== 'open' && moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(props, 'userDetails.meta_details.timezone')) ? _.get(props, 'userDetails.meta_details.timezone') : "America/Toronto").format('MMM Do, YYYY')}</div>{ _.get(value, 'type') !== "open" && <div className='questions-list-table-title'>{moment.tz(_.get(value, 'experience_date'), !_.isEmpty(_.get(props, 'userDetails.meta_details.timezone')) ? _.get(props, 'userDetails.meta_details.timezone') : "America/Toronto").format('hh:mm A')} - {moment.tz(_.get(value, 'experience_end_date'), !_.isEmpty(_.get(props, 'userDetails.meta_details.timezone')) ? _.get(props, 'userDetails.meta_details.timezone') : "America/Toronto").format('hh:mm A')}</div>}</div>
                                        })
                                    })
                                    setQuestionList(a)
                            }
                        }
                
            }
        })
    };

    const removeQuestionSet = (value) => {
            
            props.removeQuestionsAPI({
                body:{
                    id: _.get(props, 'questionSetId'),
                    action:'unassign',
                    experience_ids:[_.get(value, 'id')]
                },
                callback:(res, data) => {
                    
                    if(res){
                        if(_.get(data, 'success')){
                            notification.success({
                                message: "Success",
                                description: "Removed successfully"
                            })
                            handleFilterQuestionSetList();
                        }
                    }
                } 
            })
    }
 
      const render = () => {
        return (
            <div>
               
                <div className='question-name-title'>{_.get(props, 'questionName')}</div>
                <div style={{marginTop: "16px"}}>
                    <button onClick={() =>{showModal();groupByExperiences()}} className='create-question-set-btn'><CreateQuestionSetIcon/> Assign sessions</button>
                  </div>
                  <div style={{marginTop: "16px"}}>
                <Table loading={loading} locale={locale} pagination={false} dataSource={questionSetList} columns={columns} />
                    </div>
                      <Modal centered={true} 
                    width={1072} 
                    closable = {false} 
                    open={isModalOpen} 
                    onOk={handleOk} 
                    onCancel={handleCancel}
                    footer={null}
                    style={{borderRadius:'12px'}}
                    >
                        <div style={{padding: '32px'}}>
                            <div style={{display: "flex", justifyContent:"space-between"}}>
                            <div className='assign-question-modal-title'>Assign sessions to this question set</div>
                            <img onClick={() => handleCancel()} style={{height: "24px", width: "24px", cursor: "pointer"}} src={require('../../assets/img/template/x-close.png')}/>
                            </div>
                            <div style={{marginTop: "12px"}}>
                                <AssignQuestions items={getGroupByData} userDetails={props.userProfile} questionSetId={_.get(props, 'questionSetId')} props={props} closeModal={() => setIsModalOpen(false)} clearState={clearChildState} setClearState={setClearChildState} triggerFilterQuestionSet={handleFilterQuestionSetList}/>
                            </div>
                        </div>
                    </Modal>
            </div>
          )
      };

      return render();
  
}

const mapStateToProps = state => {
    return {
        userProfile: _.get(state, 'app.host.profile.data.details'),
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    groupByExperincesListAPI: details => dispatch(global.redux.action.questions.groupByExperincesList(details)),
    assignQuestionsAPI: details => dispatch(global.redux.action.questions.assignQuestions(details)),
    removeQuestionsAPI: details => dispatch(global.redux.action.questions.assignQuestions(details)),
  
  });
  export default connect(mapStateToProps, mapDispatchToProps)(AssignQuestionSetList)