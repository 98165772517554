import React, {useEffect, useState} from 'react'
import _ from 'lodash'
const NotFoundPage = (props) => {

    
    
  const render = () => {
  return (
    
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px"}}>
         <h3>Home page</h3>
      </div>
      
  )
  }
  return render();
}


export default NotFoundPage;