import { useEffect, useState } from "react";
import _ from "lodash";

import "react-slideshow-image/dist/styles.css";
import { Box, Button, StepConnector, StepContent, Typography, stepConnectorClasses, styled } from "@mui/material";
import { StarFilled } from "@ant-design/icons";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import "../style.css";
import { useNavigate } from "react-router";
import { connect } from "react-redux";


const StepperFlow = (props) => {
  let history = useNavigate();
       const [activeStep, setActiveStep] = useState(0);
       const [skipped, setSkipped] = useState(new Set());
  const [currentSlide, setCurrentSlide] = useState(0);
  const logout = () => {
    props.logout({
        callback: (response, data) => {
            // googleLogout();
            history('/communiti/login')
            window.location.reload(false);
        }
    })
}
  const CustomConnector = styled(StepConnector)(({ theme }) => ({
       [`&.${stepConnectorClasses.alternativeLabel}`]: {
         top: 10,
         left: 'calc(-50% + 16px)',
         right: 'calc(50% + 16px)',
       },
       [`&.${stepConnectorClasses.active}`]: {
         [`& .${stepConnectorClasses.line}`]: {
           borderColor: '#EF6820',
         },
       },
       [`&.${stepConnectorClasses.completed}`]: {
         [`& .${stepConnectorClasses.line}`]: {
           borderColor: '#EF6820',
         },
       },
       [`&.${stepConnectorClasses.disabled}`]: {
              [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#EAECF0',
              },
            },
       [`& .${stepConnectorClasses.line}`]: {
         borderColor: "red",
         borderTopWidth: 3,
         borderRadius: 1,
         height:'40px',
         borderWidth:2
       },
     }));
  const steps = [{
       title:"Personal information",
       description:"Please provide your name, email and phone number"
  },
  {
       title:"Club/Coach information",
       description:"Tell us about your business"
  },
  {
       title:"Connect your bank account",
       description:"Connect your bank account to Stripe to receive payments"
  }];

  useEffect(() => {
    if(props?.screenFlow==2){
      setActiveStep(0)
    }else if(props?.screenFlow==3){
      setActiveStep(1)
    }else if(props?.screenFlow==4){
      setActiveStep(1)
    }else if(props?.screenFlow==5){
      setActiveStep(2)
    }
    else if(props?.screenFlow==6){
      setActiveStep(3)
    }
  }, [props?.screenFlow]);
 
  const isStepOptional = (step) => {
       return step === 1;
     };
   
     const isStepSkipped = (step) => {
       return skipped.has(step);
     };
     const handleNext = () => {
       let newSkipped = skipped;
       if (isStepSkipped(activeStep)) {
         newSkipped = new Set(newSkipped.values());
         newSkipped.delete(activeStep);
       }
   
       setActiveStep((prevActiveStep) => prevActiveStep + 1);
       setSkipped(newSkipped);
     };

     const handleSkip = () => {
       if (!isStepOptional(activeStep)) {
         // You probably want to guard against something like this,
         // it should never occur unless someone's actively trying to break something.
         throw new Error("You can't skip a step that isn't optional.");
       }
   
       setActiveStep((prevActiveStep) => prevActiveStep + 1);
       setSkipped((prevSkipped) => {
         const newSkipped = new Set(prevSkipped.values());
         newSkipped.add(activeStep);
         return newSkipped;
       });
     };
     const handleBack = () => {
       setActiveStep((prevActiveStep) => prevActiveStep - 1);
     };
     const CustomStepIcon = (props) => {
       const { active, completed, className } = props;
     
       return (
         <div className={className} style={{marginLeft:'-3px'}}>
           {completed==true ? (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_12730_601)">
            <rect width="32" height="32" rx="16" fill="#FEF6EE"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7951 9.85322L13.2484 19.0666L10.7151 16.3599C10.2484 15.9199 9.51509 15.8932 8.98176 16.2666C8.46176 16.6532 8.31509 17.3332 8.63509 17.8799L11.6351 22.7599C11.9284 23.2132 12.4351 23.4932 13.0084 23.4932C13.5551 23.4932 14.0751 23.2132 14.3684 22.7599C14.8484 22.1332 24.0084 11.2132 24.0084 11.2132C25.2084 9.98655 23.7551 8.90655 22.7951 9.83989V9.85322Z" fill="#EF6820"/>
            </g>
            <defs>
            <clipPath id="clip0_12730_601">
            <rect width="32" height="32" rx="16" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            
           ) : (
             ( completed==false &&active==false)?  
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="30" height="30" rx="15" stroke="#EAECF0" stroke-width="2"/>
  <circle cx="16" cy="16" r="5" fill="#EAECF0"/>
</svg>
              :<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#FEF6EE"/>
              <circle cx="16" cy="16" r="5" fill="#EF6820"/>
            </svg>
           )}
         </div>
       );
     };
     
  return (
    <Box
      width={"100%"}
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        display: "flex",
        flexDirection: "column",
       
      }}

      // height="calc(100vh - 25px)"
    >
      
      <Box sx={{
        paddingTop: {
          xs: "16px", // mobile
          sm: "22px", // tablet
          md: "28px", // small desktop
          lg: "32px", // large desktop
        },
        paddingLeft: {
          xs: "16px", // mobile
          sm: "32px", // tablet
          md: "64px", // small desktop
          lg: "80px", // large desktop
        },
        paddingRight: {
          xs: "16px", // mobile
          sm: "32px", // tablet
          md: "64px", // small desktop
          lg: "80px", // large desktop
        },
        width:'100%'
        
      }}>
         <Box sx={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        
      }}>
        <Box component={"img"}  sx={{
                width: "142px",
                height: "32px",
                display:'flex',
        alignItems:'flex-start',
        justifyContent:'flex-start'
              }}
              src={require('../../../assets/img/club/header-logo1.png')}
              alt="sliders" />
               <Box sx={{cursor:"pointer"}} component={"div"} onClick={()=>logout()}>
                <Typography sx={{color:"#EF6820",fontSize:"16px",fontWeight:"600"}}>Sign out</Typography>
              </Box>
      </Box>

              <Box sx={{
                      marginTop: {
                            xs: "30px", // mobile
                            sm: "50px", // tablet
                            md: "70px", // small desktop
                            lg: "80px", // large desktop
                          },
                          // marginLeft: {
                          //   xs: "30px", // mobile
                          //   sm: "50px", // tablet
                          //   md: "70px", // small desktop
                          //   lg: "80px", // large desktop
                          // },
                          
              }}>
              <Stepper activeStep={activeStep} orientation="vertical" 
              connector={<CustomConnector />}
              >
        {steps.map((item, index) => {
          const stepProps = {};
          const labelProps = {};
         
          
          return (
            <Step sx={{paddingBottom:'0px'}} expanded={true} key={item.title} {...stepProps}>
              <StepLabel StepIconComponent={CustomStepIcon}  {...labelProps}>
                     <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box component={"span"} 
                            sx={{color:"#344054",fontSize:"16px",fontWeight:"600"}}>
                            {item?.title}
                            </Box>
                            <Box component={"span"} sx={{
                     textAlign:'left',
                     fontSize:'16px',
                     color:"#475467"
              }}>
                            {item?.description}
                            </Box>
                     </Box>
                     
                     
                     </StepLabel>
              
              {/* <StepContent>
              <Typography
              sx={{
                     textAlign:'left',
                     fontSize:'16px',
                     color:"#475467"
              }}
              >{item.description}</Typography>
              </StepContent> */}
            </Step>
          );
        })}
      </Stepper>
      {/* <Box>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </Box> */}
              </Box>
      </Box>

      {/* <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection:'column',
          paddingLeft: {
            xs: "16px", // mobile
            sm: "32px", // tablet
            md: "64px", // small desktop
            lg: "80px", // large desktop
          },
          paddingRight: {
            xs: "16px", // mobile
            sm: "32px", // tablet
            md: "64px", // small desktop
            lg: "80px", // large desktop
          },
        }}
        
      >
  
      </Box> */}

      <Box 
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection:{
          sx:"column",
          sm: "column", // tablet
          md: "row", // small desktop
          lg: "row", // large desktop
        },
        paddingLeft: {
          xs: "16px", // mobile
          sm: "22px", // tablet
          md: "28px", // small desktop
          lg: "32px", // large desktop
        },
        paddingRight: {
          xs: "16px", // mobile
          sm: "22px", // tablet
          md: "28px", // small desktop
          lg: "32px", // large desktop
        },
      
      }}

      >
      <Typography
          sx={{color:"#475467",fontSize:'14px',fontWeight:'400'}}
          >
            © 2024 Communiti Inc.
          </Typography>
          <Box sx={{
            display:'flex',
            alignItems:'center'
          }}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.33334 4.66699L6.77662 8.47729C7.2174 8.78583 7.43779 8.94011 7.67752 8.99986C7.88927 9.05265 8.11075 9.05265 8.3225 8.99986C8.56223 8.94011 8.78262 8.78583 9.2234 8.47729L14.6667 4.66699M4.53334 13.3337H11.4667C12.5868 13.3337 13.1468 13.3337 13.5747 13.1157C13.951 12.9239 14.2569 12.618 14.4487 12.2416C14.6667 11.8138 14.6667 11.2538 14.6667 10.1337V5.86699C14.6667 4.74689 14.6667 4.18683 14.4487 3.75901C14.2569 3.38269 13.951 3.07673 13.5747 2.88498C13.1468 2.66699 12.5868 2.66699 11.4667 2.66699H4.53334C3.41324 2.66699 2.85319 2.66699 2.42536 2.88498C2.04904 3.07673 1.74308 3.38269 1.55133 3.75901C1.33334 4.18683 1.33334 4.74689 1.33334 5.86699V10.1337C1.33334 11.2538 1.33334 11.8138 1.55133 12.2416C1.74308 12.618 2.04904 12.9239 2.42536 13.1157C2.85319 13.3337 3.41324 13.3337 4.53334 13.3337Z" stroke="#667085" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            

          <Typography
          sx={{marginLeft:'8px',color:"#475467",fontSize:'14px',fontWeight:'400'}}
            >
           support@communiti.app
          </Typography>
          </Box>
      </Box>
     
    </Box>
  );
};
const mapStateToProps = state => {
  return {
       
   }
}

const mapDispatchToProps = dispatch => ({
  
   logout: details => dispatch(global.redux.action.account.logout(details)),
  

});

export default connect(mapStateToProps, mapDispatchToProps)(StepperFlow)

