import { EditFilled,DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, ArrowLeftOutlined, SaveFilled, ClockCircleFilled } from '@ant-design/icons'
// import Input from 'antd/lib/input/Input'
import React,{useState,useEffect} from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, InputNumber,Modal,Image, Input, message} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { set } from 'lodash';
import { connect } from 'react-redux'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import GoogleMapReact from 'google-map-react';
import '../list/view.css'
import '../../onboarding-screen/style.css'
import './search.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import {AiOutlineCopy} from 'react-icons/ai';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
const { Panel } = Collapse;
const {confirm} =Modal
const { Option } = Select;
const { Search } = Input;
const AnyReactComponent = ({ text }) => <div><Image width={35} preview={false} height={35} src={require('../../../assets/img/map-marker-icon.png')} /></div>;

const TemplateTab =(props)=>{
    const [form] = Form.useForm();
    let history = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [searchParams, setSearchParams] = useSearchParams();
    const [LocationData, setLocationData] = useState({lat: 11.937871, lng: 79.819953})
const [TemplateViewDetails, setTemplateViewDetails] = useState({})
const [clauseArray, setclauseArray] = useState([])
const [fileList, setFileList] = useState([])
const [TaxListData, setTaxListData] = useState([]);
const [getHostData, setHostData] = useState({})
const [moreAboutArray, setMoreAboutArray] = useState([]);
const [userHandle, setUserHandle] = useState();
const [content, setContent] = useState();

    const defaultProps = {
      center: {
        lat: 11.937871, lng: 79.819953
      },
      zoom: 15
    };

    useEffect(() => {
     
    if(_.get(props,'template.details')){
      const content_desc = _.get(props,'template.details.description');
      
      if(content_desc){
        const updated_content = content_desc.replace(/<p><br><\/p>/g, '');
      setContent(updated_content);
      }
      
        setTemplateViewDetails(_.get(props,'template.details'));
        setFileList(_.get(props,'template.photos'))
        setclauseArray(_.get(props,'template.details.clauses'))
        setMoreAboutArray(_.get(props,'template.details.extra_info'))
        setLocationData({lat:_.get(props,'template.details.location.x'),lng:_.get(props,'template.details.location.y')})
        
            
      
    }
    
    }, [_.get(props,'template')])

    useEffect(() => {
      loadTaxList();
      getHostProfile();
    }, [])
    

    
    const loadTaxList =()=>{
      props.taxList({
        params:{limit:100},
        callback:(res,data)=>{
      if(res==true && _.get(data,'data.items[0]'))
          setTaxListData(_.get(data,'data.items'))
        }
      })
          }
    const loadClausesList =(data)=>{
      
      return  _.map(data,(value,index)=>{
        
return <div key={index}>
   
   <div className="name-details">
       <span className="name-details-title">Title</span>
       <span>{_.get(value,'name')}</span>
   </div>
   {!_.isEmpty(_.get(value, 'link')) ? 
    <div className="name-details">
    <span className="name-details-title">Link</span>
    <span>{_.get(value,'link')}</span>
</div>: ''
   }
  {!_.isEmpty(_.get(value, 'description')) ? 
  <div className="name-details">
  <span className="name-details-title">Description
</span>
<div dangerouslySetInnerHTML={{ __html: _.get(value,'description') }}/>
      {/* <span>{_.get(value,'description')}</span> */}
  </div>:''
  }
    </div>
        })
    }

    const loadMoreAboutList =(data)=>{
      
      return  _.map(data,(value,index)=>{
        
return <div key={index}>
   
   <div className="name-details">
       <span className="name-details-title">Title</span>
       <span>{_.get(value,'name')}</span>
   </div>
   {!_.isEmpty(_.get(value, 'description')) ? 
    <div className="name-details">
    <span className="name-details-title">Description</span>
    <div dangerouslySetInnerHTML={{ __html: _.get(value,'description') }}/>
    {/* <span>{_.get(value,'description')}</span> */}
</div>: ''
   }
  {!_.isEmpty(_.get(value, 'link')) ? 
  <div className="name-details">
  <span className="name-details-title">Link
</span>
      <span>{_.get(value,'link')}</span>
  </div>:''
  }
    </div>
        })
    }

    const getTaxData =(id)=>{
      let filterData =_.filter(TaxListData,(value,index)=>_.get(value,'id')==id)
      return _.startCase(_.get(_.head(filterData),'state'))
    }

    const getHostProfile = () => {
      props.hostProfileData({
        callback: (res, data) => {
          
          if(res){
            const handles = _.get(data, 'data.details.handle');
            setUserHandle(_.get(data, 'data.details.handle'))
            setHostData(_.get(data, 'data.details'))
          }
        }
      })
    }
    const onCopyURL = (value) => {
      
      navigator.clipboard.writeText(value)
      .then(() => {
        
        messageApi.open({
          type: 'success',
          content: 'Copied',
        });
      })
      .catch(err => {
        console.error('Error copying value:', err);
      });
    }

    const onCopyBrandedURL = (value) => {
      
      navigator.clipboard.writeText(value)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Copied',
        });
      })
      .catch(err => {
        console.error('Error copying_value:', err);
      });
    }
    const render =()=>{
      
        return (
        <div >
            {contextHolder}
               
                <div className='view-template-container' style={{backgroundColor:'#e9e9e9', borderRadius:"10px"}}>
                  <div className='view-template-container-1' style={{padding:'13px 21px'}}>
                  {/* <div className="first-container" style={{display: searchParams.get('exp_id') ? "block" : "none"}} >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
   Sessions URLs
</div>
<div className='view-template-title'>
        <Search
        style={{width: "50%"}}
        readOnly
        enterButton={<AiOutlineCopy/>}
        defaultValue={`https://devbooking.communitiapi.com/session/${searchParams.get('exp_id')}`}
        onSearch={onCopyURL}
      />
</div>
                    </div>
                </div>
                </div>
</div> */}
{/* <div className="first-container" style={{display: searchParams.get('exp_id') ? "block" : "none"}}>
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
   Perma Link
</div>
<div className='view-template-title'>
       <p>{`https://devbooking.communitiapi.com/session/${searchParams.get('exp_id')}`}</p>
</div>
                    </div>
                </div>
                </div>
</div> */}
{/* <div className="first-container" style={{display: searchParams.get('exp_id') ? "block" : "none"}}>
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
   Branded Link
</div>
<div className='view-template-title'>
<Search
        style={{width: "50%"}}
        readOnly
        enterButton={<AiOutlineCopy/>}
        defaultValue={`https://devbooking.communitiapi.com/${searchParams.get('handle')}/session/${searchParams.get('exp_id')}`}
        onSearch={onCopyBrandedURL}
      />
</div>
                    </div>
                </div>
                </div>
</div> */}
                  <div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
   Template Type
</div>
<div className='view-template-title'>{_.startCase(_.get(TemplateViewDetails,'type'))}</div>
                    </div>
                </div>
                </div>
</div>
                <div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
   Template Name
</div>
<div className='view-template-title'>{_.get(TemplateViewDetails,'title')}</div>
                    </div>
                </div>
                </div>
</div>

<div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Description
</div><div dangerouslySetInnerHTML={{ __html: _.get(props,'template.details.description') }}/>



                    </div>
                </div>
                </div>
</div>
<div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Address
</div>
<div className='view-template-title'>{_.get(TemplateViewDetails,'address')}</div>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Province / State</div>
<div className='view-template-title'>{getTaxData(_.get(TemplateViewDetails,'tax_state'))}</div>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Postal / Zip Code</div>
<div className='view-template-title'>{_.get(TemplateViewDetails,'zip_code')}</div>

                    </div>
                </div>
                </div>

</div>

<div style={{ height: '55vh', width: '100%',borderRadius:'23px',marginTop:'23px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBIU6YefaUI89t5f7LwsvY0z21sLmLdpYc" }}
        defaultCenter={LocationData}
        defaultZoom={defaultProps.zoom}
        center={LocationData}
      >
        <AnyReactComponent
          lat={_.get(LocationData,'lat')}
          lng={_.get(LocationData,'lng')}
          
          text="My Marker"
        />
      </GoogleMapReact>
    </div>


    
<div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">

{_.get(TemplateViewDetails, 'type') == 'open' ? 'Maximum # of Concurrent Registrants':'Maximum # of Allowed Registrants'}</div>
<div className='view-template-title'>{_.get(TemplateViewDetails, 'type') == 'open' && searchParams.get('exp_id') ? _.get(props, 'yourExpDetails.max_no_of_openings'):_.get(props, 'yourExpDetails.max_no_of_openings')}</div>

                    </div>
                </div>
                </div>
</div>
<div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Price per Registrant</div>
<div className='view-template-title'>${_.get(TemplateViewDetails,'price')}</div>
                    </div>
                </div>
                </div>
</div>
<div className="first-container" >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Currency</div>
<div className='view-template-title'>{_.get(TemplateViewDetails,'meta_details.currency')}</div>
                    </div>
                </div>
                </div>
</div>
<div className="first-container" style={{display: _.get(TemplateViewDetails,'meta_details.team_size') ? "block" : "none"}} >
                <div className="status-container" style={{border:'none',padding:'0px'}}>
                <div>
                <div className="name-inner-conatiner-input">
<div className="title-style">
Team Size</div>
<div className='view-template-title'>{_.get(TemplateViewDetails,'meta_details.team_size')}</div>
                    </div>
                </div>
                </div>
</div>
<div style={{marginTop:'23px'}}><span style={{fontWeight:'700',fontSize:'20px'}}>More about this session</span>
<div style={{marginTop:"10px"}}>
  <span>
  In this section you can provide users more information, and links to material you have about this session. For example, you may have rulebooks, parking information, policies or FAQs that you want to provide users information.
  </span>
</div>
                {moreAboutArray &&_.size(moreAboutArray)>0 &&<div > <div className="first-container-1">
<div className="second-container" style={{padding:'0px'}}>

 {loadMoreAboutList(moreAboutArray)}</div> </div></div>}

 </div> 
<div style={{marginTop:'23px'}}><span style={{fontWeight:'700',fontSize:'20px'}}>Agreement Clauses</span>
                {clauseArray &&_.size(clauseArray)>0 &&<div > <div className="first-container-1">
<div className="second-container" style={{padding:'0px'}}>

 {loadClausesList(clauseArray)}</div> </div></div>}

 </div>              
               
                <div className="first-container-1" style={{marginTop:13}}>
                <span style={{fontWeight:'700',fontSize:'20px'}}>Pictures</span>
               <div className='view-template-photo'>
               {  _.map(fileList,(value,i)=>{
                   return<Image width={'20%'} style={{marginRight:'10px'}} height={'20%'} preview={false} src={_.get(value,'url')} />
                 })}
                 </div>
               
               
                  
                </div>
                </div>
            </div>
        </div>)
    }

    return render()
}
const mapStateToProps = state => {
    return {
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    
    taxList: details => dispatch(global.redux.action.tax.list(details)),
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),

   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(TemplateTab)