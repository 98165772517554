import {  TextField, Typography, Container, Box, InputAdornment, styled, makeStyles } from '@mui/material';
import { Formik, Field, ErrorMessage, useFormikContext } from 'formik';
import { Form, Input,Button, Select, Checkbox } from 'antd';
import * as Yup from 'yup';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import _ from 'lodash';

import "../style.css";
import { connect } from 'react-redux';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useEffect, useState } from 'react';

    const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
];

const { Option } = Select;


 
const FinalForm =(props)=>{
  
  
  
   const [form] = Form.useForm();
      const [userDetails, setuserDetails] = useState({})
      const formikProps = useFormikContext()

       const [clubCoachInfoForms, setClubCoachInfoForms] = useState(3);
       const [initialValues, setInitialValues] = useState({
        invite_code: '',
           });

           const [is_valid_code, set_is_valid_code] = useState();
           const [isAccepted, setIsAccepted] = useState();
           const [throwTermsError, setThrowTermsError] = useState(false);
           const [inputValue, setInputValue] = useState('');
            const [debouncedValue, setDebouncedValue] = useState('');
            const [loading, setLoading] = useState(false)

            useEffect(() => {
              // Set a timeout to update debouncedValue after 2 seconds
              const handler = setTimeout(() => {
                setDebouncedValue(inputValue);
              }, 2000);
          
              // Clear timeout if inputValue changes before 2 seconds
              return () => {
                clearTimeout(handler);
              };
            }, [inputValue]);

            useEffect(() => {
              // Log the debouncedValue to the console
              if (debouncedValue) {
                
                props.verifyInviteCode({
                  body:{
                    code:debouncedValue
                  },
                  callback: (res, data) => {
                    
                    if(_.get(data, 'success') == true){
                      set_is_valid_code(_.get(data, 'is_valid'))
                    }
                  }
                })
              }
            }, [debouncedValue]);
         
      useEffect(() => {
             
             let conditionType = _.includes(_.get(props,'islogindata.types'),1)==true?'hostProfile':'guestProfile';
             if(_.get(props,`${conditionType}.id`)){
                    setuserDetails(_.get(props,`${conditionType}`));
                    setInitialValues({
                      ...initialValues
               })
             }
            
      }, []);

           const handleSubmit = (values) => {

            if( _.isEmpty(inputValue) && isAccepted){
              submitAPI(values.invite_code);
            } else if ( is_valid_code == false && !isAccepted) {
              setThrowTermsError(true)
            } else if(is_valid_code == true && isAccepted){
              submitAPI(values.invite_code);
            } else if (is_valid_code == true && !isAccepted) {
              setThrowTermsError(true)
            } else if (is_valid_code == false && isAccepted){
              set_is_valid_code(false)
            } else if (!isAccepted){
              setThrowTermsError(true)
            }
              
           };

           const submitAPI = (code) => {
            
            setLoading(true);
            let body = {
              first_name: _.get(props, 'personalInfo.first_name'),
              last_name: _.get(props, 'personalInfo.last_name'),
              contact_details: {
                email: _.get(props, 'personalInfo.email'),
                phone: _.get(props, 'personalInfo.phoneNumber'),
              },
              business_type: _.get(props, 'businessFormData.business_type'),
              categories: [_.get(props, 'businessFormData.category')],
              display_name: _.get(props, 'businessFormData.display_name'),
              is_taxes_collected: _.get(props, 'addressFormDetails.is_taxes_collected') === 'yes' ? true : false,
              country: _.get(props, 'addressFormDetails.country'),
              location: _.get(props, 'addressFormDetails.location'),
              address: _.get(props, 'addressFormDetails.address'),
              invite_code: code
            }
            if(!is_valid_code){
              delete body.invite_code;
            };
            
            props.hostCreate({
              body:body,
              callback: (res, data) => {
                setLoading(false);
                
                if(_.get(data, 'success') == true){
                  props.storeIsLogin({
                    body:{types:[0,1]},
                    callback:(status,response)=>{
                      props?.onChangeFlow(4);
              
                    }
                  })
                 
                }
              }
            })
           }

          

   const customFieldLabel =(text)=>{
return <Box sx={{display:'flex',alignItems:'flex-start'}}><Typography
sx={{color:"#344054",fontSize:'14px',fontWeight:"500"}}
>{text}</Typography></Box>
   }  
  
   const customErrorText =(text)=>{
return <Box component={"span"} sx={{display:'flex',alignItems:'flex-start',marginTop:'6px'}}><Typography
sx={{color:"#F04438",fontSize:'14px',fontWeight:"400"}}
>{text}</Typography></Box>
   }


   const onChangeCheck = (e) => {
       setIsAccepted(e.target.checked);
       if(e.target.checked == false){
        setThrowTermsError(true)
       } else {
        setThrowTermsError(false)
       }
     };

     
     const customSuffixIcon =()=>{
      return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_12730_906)">
        <path d="M7.99992 5.33301V7.99967M7.99992 10.6663H8.00659M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z" stroke="#F04438" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_12730_906">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
   }

   const onChangeInviteCode = (e) => {
    setInputValue(e.target.value);
      if(_.isEmpty(e.target.value)){
        set_is_valid_code();
      }
        
       
   };

   const openLinkInNewTab = (url) => {
    window.open(url, '_blank');
  };

return <>
     

{clubCoachInfoForms === 3 && <Formik
initialValues={initialValues}
     onSubmit={handleSubmit}
     enableReinitialize={true}
   >
     {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
       <Form form={form} className='personal-info-form' layout='vertical' onFinish={handleSubmit}>
        
           <Form.Item
           style={{ marginBottom:'20px' }}
           // help={touched.is_tax && errors.is_tax ? customErrorText(errors.is_tax) : ''}
           label={customFieldLabel("Invite code (optional)")}
           // validateStatus={touched.is_tax && errors.is_tax ? 'error' : ''}
           name='invite_code'
           rootClassName='common-placeholder-container'
         >
               <Input
                name='invite_code'
                 id='invite_code' 
                 key={'invite_code'} 
                 onChange={(e) => {handleChange(e);onChangeInviteCode(e)}} 
                 style={{height: "44px", boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}} 
                 placeholder='Enter your code'
                 suffix={is_valid_code === false ? customSuffixIcon() : <span/>}
                 />
                 <Box sx={{display:'flex',alignItems:'flex-start', marginTop: "6px"}}>
                 { is_valid_code !== false && is_valid_code !== true && <Typography 
                 sx={{color:"#475467",fontSize:'14px',fontWeight:"400", lineHeight: "20px", fontStyle: "normal"}}>
                  If you don’t have an Invite code, you can leave this field empty
                  </Typography>}

                 { is_valid_code === false && <Typography
                 sx={{color: "#F04438", fontSize: "14px", fontWeight: "400", fontStyle: "normal", lineHeight: "20px"}}
                 >
                 The invite code you entered is invalid
                 </Typography>}
                 {
                  is_valid_code === true && <Typography
                  sx={{color: "#027A48", fontSize: "14px", fontWeight: "400", fontStyle: "normal", lineHeight: "20px"}}
                  >
                  Invite code applied successfully!
                  </Typography>
                 }
                 </Box>
               {/* {is_valid_code === false && <div className='invite-code-error-txt'>The invite code you entered is invalid</div>} */}
               {/* { is_valid_code !== false && <div style={{marginTop: '6px'}} className='invite-code-supporting-txt'>If you don’t have an Invite code, you can leave this field empty</div>} */}
         </Form.Item>

         <Form.Item>
         <div className="terms-check-box-container">
      <Checkbox onChange={onChangeCheck} />
      <div className="terms-check-box-text-container">
        I agree with <span className="checkbox-txt-link" onClick={() => openLinkInNewTab('https://www.communiti.app/terms-of-service')}>Communit’s terms of service</span>, <span onClick={() => openLinkInNewTab('https://www.communiti.app/privacy-policy')} className="checkbox-txt-link">privacy policy</span>
      </div>
      
    </div>
    and <span onClick={() => openLinkInNewTab('https://www.communiti.app/clubandcoachagreement')} className="checkbox-txt-link-agreement">club & coach agreement</span>
         {throwTermsError == true && <Box sx={{display:'flex',alignItems:'flex-start', marginTop: "6px"}}>
          <Typography sx={{color: "#F04438", fontSize: "14px", fontWeight: "400", fontStyle: "normal", lineHeight: "20px"}}>
           You must consent to our terms of service and privacy policy to proceed
          </Typography>
         </Box>}
         
         </Form.Item>

         <Form.Item>
           {!loading && <Button
          //  onClick={()=> props?.onChangeFlow(4)}
           type="primary" htmlType="submit"
           style={{
             width: '100%', marginTop: '4px', height: '44px', background: "#EF6820", color: "#FFF",
             fontSize: '16px',
             fontWeight: "600"
           }}>
             Continue
           </Button>}
           {loading && <div className='host-create-loading-btn'>
            <div className='dot-pulse'/>
            </div>}
         </Form.Item>
      

     {/* 2nd form */}

     {/* {
       clubCoachInfoForms === 2 && <>
      
     </>
     } */}
       </Form>
     )}
   </Formik>}
</>

}


const mapStateToProps = state => {
      
      return {
         checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
         isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
         islogindata: _.get(state, 'app.account.isLogin'),
         hostProfile: _.get(state, 'app.host.profile.data.details'),
         guestProfile: _.get(state, 'app.guest.profile.data.details')
   
      }
    }
   
    const mapDispatchToProps = dispatch => ({
      isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
      hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
      guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
      list: details => dispatch(global.redux.action.categories.list(details)),
      hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
      guestUpdateProfile: details => dispatch(global.redux.action.guest.update(details)),
      updatePhone: details => dispatch(global.redux.action.guest.updatePhone(details)),
      request: details => dispatch(global.redux.action.host.request(details)),
      hostCreate: details => dispatch(global.redux.action.host.hostCreate(details)),
      verifyInviteCode: details => dispatch(global.redux.action.host.verifyInviteCode(details)),
      taxList: details => dispatch(global.redux.action.tax.list(details)),
      storeIsLogin: details => dispatch(global.redux.action.account.storeIsLogin(details)),

   
    
    });
    export default connect(mapStateToProps, mapDispatchToProps)(FinalForm)

