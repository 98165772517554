import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Table, Button, Radio, Tag, Modal, Input } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { BsPlusCircleDotted } from "react-icons/bs";
import { Scrollbars } from 'react-custom-scrollbars';

import "./style.css";

const CancelledRegistrationList = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [experienceDetails, setExperienceDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('active');
    const [activeOrderItem, setActiveOrderItem] = useState([]);
    const [activeLoading, setActiveLoading] = useState(true);
    const [isManageRegModalOPen, setIsManageRegModalOPen] = useState(false);
    const [regDetails, setRegDetails] = useState({});
    const [registrationInfo, setRegistrationInfo] = useState([]);
    const [clauseInfo, setClauseInfo] = useState([]);
    const [cancelledOrderMeta, setCancelledOrderMeta] = useState({});
    const [cancelledOrderData, setCancelledOrderData] = useState([]);

    useEffect(() => {
        viewExperience();
        listOrder();
    }, []);

    const viewExperience = () => {
        props.experienceViewAPI({
            params:{
                id: searchParams.get("id"),
                expand: "template"
            },
            callback: (response, data) => {
              if(response){
                setLoading(false);
              }
                if(!_.isEmpty(_.get(data, 'data.details'))){
                   
                    setExperienceDetails(_.get(data, 'data.details'));
                }
            }
        })
    };

    const listOrder = (pageSize = 25) => {
      setActiveLoading(true)
        props.orderListAPI({
            params:{
                experience:searchParams.get("id"),
                user_type:'1',
                expand:'guest,experience,experience_template,profile',
                // paid:1,
                is_completed:0,
                status: 4,
                limit: pageSize
            },
            callback: (res, data) => {
                
                setActiveLoading(false)
                if(res){
                  setCancelledOrderMeta(_.get(data, 'data.meta', {}));
                  setCancelledOrderData(_.get(data, 'data.items'));
                    if(!_.isEmpty(_.get(data, 'data.items'))){
                        
                        let a = []
                        _.map(_.get(data, 'data.items'), (value, index) => {
                            a.push({
                              key: index,
                              first_last_name: <div className='session-details-table'>{`${_.startCase(_.get(value, 'profile.first_name'))} ${_.startCase(_.get(value, 'profile.last_name'))}`}</div>,
                              gender_date_birth: <div className='session-details-table'>{`${moment(_.get(value, 'profile.date_of_birth')).format("MMMM DD, YYYY")}`}</div>,
                              gender: <div className='session-details-table'>{_.startCase(_.get(value, 'profile.gender'))}</div>,
                              status: _.get(value, 'status'),
                              details: value
                            })
                          })
                        setActiveOrderItem(a);
                    };
                }
            }
        })
    };

    const manageRegistration = (data) => {
      
      setRegDetails(data)
      setIsManageRegModalOPen(true);
      if(!_.isEmpty(_.get(data, 'details.registration_info'))){
                        
        let a = []
        _.map(_.get(data, 'details.registration_info'), (value, index) => {
            a.push({
              key: index,
              label: <div className='session-details-table'>{_.get(value, "label")}</div>,
              answer: <div className='session-details-table'>{_.get(value, "answer")}</div>,
            })
          })
        setRegistrationInfo(a);
    };

    if(!_.isEmpty(_.get(data, 'details.experience.clauses'))){
                        
      let c = []
      _.map(_.get(data, 'details.experience.clauses'), (value, index) => {
          c.push({
            key: index,
            name: <div className='session-details-table'>{_.get(value, "name")}</div>,
            created_at: <div className='session-details-table'>{`Accepted on ${moment.tz(_.get(data, 'details.created_at'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')} at ${moment.tz(_.get(data, 'details.created_at'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('hh:mm A z')}`}</div>,
          })
        })
        setClauseInfo(c);
  };
    };

    const handleManageRegOk = () => {
      setIsManageRegModalOPen(false);
      setRegistrationInfo([]);
      setClauseInfo([]);
    };

    const handleManageRegCancel = () => {
      setIsManageRegModalOPen(false);
      setRegistrationInfo([]);
      setClauseInfo([]);
    };

    const columns = [
      {
        title: <div className='session-details-table'>ID</div>,
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: <div className='session-details-table'>Session Title</div>,
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: <div className='session-details-table'>Schedule</div>,
        dataIndex: 'schedule_details',
        key: 'schedule_details',
        render: () => (
          <>
            <div className='session-details-table'>{`${moment.tz(_.get(experienceDetails, 'experience_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('MMM D')} - ${moment.tz(_.get(experienceDetails, 'experience_end_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format('MMM D, YYYY')}`}</div>
            <div className='session-details-table'>{_.get(experienceDetails, 'type') == 'open' ? "null" :
        `${_.get(experienceDetails, 'experience_start_time') ?
          moment.tz(_.get(experienceDetails, 'experience_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format("hh:mm") : '--'}-${_.get(experienceDetails, 'experience_end_time') ? moment.tz(_.get(experienceDetails, 'experience_end_date'), !_.isEmpty(_.get(experienceDetails, 'meta_details.timezone')) ? _.get(experienceDetails, 'meta_details.timezone') : "America/Toronto").format("hh:mm A z") : '--'}`}</div>
            <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{ _.size(_.get(experienceDetails, 'schedule_dates')) > 1 ? `${_.size(_.get(experienceDetails, 'schedule_dates'))+1} Sessions` : `${_.size(_.get(experienceDetails, 'schedule_dates'))} Session`}</div> {_.get(experienceDetails, 'can_late_register') === true && <div className="custom-button-tag">Late Registration</div>} </div>
          </>
        ),
      },

      {
          title: <div className='session-details-table'>Session Info</div>,
          dataIndex: 'session_info',
          key: 'session_info',
          render: () => (
            <>
              <div className='session-details-table'>{_.get(experienceDetails, 'type') == "league" ? "League Session" : _.get(experienceDetails, 'type') == "open" ? "Open Session" : "Scheduled Session"}</div>
              <div style={{display: "flex", flexDirection: "row"}}><div className='session-details-table'>{`$${_.get(experienceDetails, 'price')} ${_.get(experienceDetails, 'template.meta_details.currency')}`}</div> {_.get(experienceDetails, 'can_prorate') === true && <div className="custom-button-tag">Prorate</div>}</div>
              <div className='session-details-table'>{`${_.get(experienceDetails, 'no_of_bookings')}/${_.get(experienceDetails, 'max_no_of_openings')} Registrations`}</div>
            </>
          ),
        },
    ];

    const dataSource = [
      {
        key: '1',
        id: <div className='session-details-table'>{_.get(experienceDetails, 'id')}</div>,
        title: <div className='session-details-table'>{_.get(experienceDetails, 'template.title')}</div>,
        schedule_details: null, // The render function will populate this
        session_info: null,  // The render function will populate this
      },
    ];

      const active_columns = [
        {
            title: <div className='session-details-table'>First Name & Last Name</div>,
            dataIndex: 'first_last_name',
            key: 'first_last_name',
            width: "25%",
            render: (text, item) => {
                return <div className='session-details-table'>{_.get(item, 'first_last_name')}</div>
              },
          },
          {
            title: <div className='session-details-table'>Gender & Date of Birth</div>,
            dataIndex: 'gender_date_birth',
            key: 'gender_date_birth',
            width: "25%",
            render: (text, item) => {
                    return <>
                  <div className='session-details-table'>{_.get(item, 'gender')}</div>
                  <div className='session-details-table'>{_.get(item, 'gender_date_birth')}</div>
                </>
                    
            }
                
              ,
          },
          {
            title: <div className='session-details-table'>Payment Status</div>,
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (text, item) => {
                return <>
              <div className='session-details-table'>{_.get(item, 'status') === 1 ? <div className='paid-customized-tag'>Paid</div> : <div className='paid-customized-tag'>Cancelled</div>}</div>
            </>
                
        }
          },
          {
            title: <div className='session-details-table'>Actions</div>,
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
                return <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div onClick={() => manageRegistration(record)}  className='header-first-row'>Manage Registrations</div>
                        
                    </div>
                
        }
          }
      ];

      const registrant_column = [
        {
          title: <div className='session-details-table'>Registrant Type</div>,
          dataIndex: "reg_type",
          key:"reg_type"
        },
        {
          title: <div className='session-details-table'>Registrant First & Last Name</div>,
          dataIndex: "reg_first_last_name",
          key:"reg_first_last_name"
        },
        {
          title: <div className='session-details-table'>Registrant Gender & Date of Birth</div>,
          dataIndex: "reg_gender_dob",
          key:"reg_gender_dob"
        },
        {
          title: <div className='session-details-table'>Contact First & Last Name</div>,
          dataIndex: "con_first_last_name",
          key:"con_first_last_name"
        },
        {
          title: <div className='session-details-table'>Contact Email</div>,
          dataIndex: "con_email",
          key:"con_email"
        },
        {
          title: <div className='session-details-table'>Contact Address</div>,
          dataIndex: "con_address",
          key:"con_address"
        },
      ];

      const reg_dataSource = [
        {
          key: "1",
          reg_type: <div className='session-details-table'>{_.get(regDetails, 'details.profile_info.profile_type')}</div>,
          reg_first_last_name: <div className='session-details-table'>{`${_.startCase(_.get(regDetails, 'details.profile_info.first_name'))} ${_.startCase(_.get(regDetails, 'details.profile_info.last_name'))}`}</div>,
          reg_gender_dob: <div className='session-details-table'>{`${_.startCase(_.get(regDetails, 'details.profile_info.gender'))} ${moment(_.get(regDetails, 'details.profile_info.date_of_birth')).format("MMMM DD, YYYY")}`}</div>,
          con_first_last_name: <div className='session-details-table'>{`${_.startCase(_.get(regDetails, 'details.guest.first_name'))} ${_.startCase(_.get(regDetails, 'details.guest.last_name'))}`}</div>,
          con_email: <div className='session-details-table'>{_.get(regDetails, 'details.guest.contact_details.email')}</div>,
          con_address: <div className='session-details-table'>{_.get(regDetails, 'details.guest.address')}</div>,
        }
      ];

      const additional_column = [
        {
          title: <div className='session-details-table'>Question Title</div>,
          dataIndex: "label",
          key: "label"
        },

        {
          title: <div className='session-details-table'>Registrant Response</div>,
          dataIndex: "answer",
          key: "answer"
        },
      ]

      const clause_column = [
        {
          title: <div className='session-details-table'>Clause Title</div>,
          dataIndex: "name",
          key: "name"
        },

        {
          title: <div className='session-details-table'>Acceptance Timestamp</div>,
          dataIndex: "created_at",
          key: "created_at",
        },
      ];

      const handleScroll = (data, type) => {
        if (activeLoading == false && data && data.top > 0.9 && _.get(cancelledOrderMeta, 'total') > 25) {
          if (_.size(cancelledOrderData) < _.get(cancelledOrderMeta, 'total')) {
            listOrder(_.get(cancelledOrderMeta, 'limit') + 25)
          }
    
    
        }
    
      }

    const render = () => {
        return (
            <div>
                <div style={{marginTop: "12px", display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                
                </div>
                <Scrollbars 
             className="hidden-scroll-x"
             onScrollFrame={(e) => handleScroll(e)}
             style={{ height: 'calc(100vh - 400px)' }}
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            >
              <Table 
                        style={{marginTop: "12px"}} 
                        loading={activeLoading} 
                        columns={active_columns} 
                        dataSource={activeOrderItem} 
                        pagination={false}
                        />
            </Scrollbars>
                        
                   <div>
                   <Modal 
                   centered={true}
              className='common-modal-ctn' 
              title="Manage Registration" 
              open={isManageRegModalOPen} 
              onOk={handleManageRegOk} 
              onCancel={handleManageRegCancel}
              footer={null}
              >
                <div>
                <p className='session-static-desc'>You are manually adding a registration to the following session:</p>
              <hr/>
              <Table loading={loading} pagination={false} columns={columns} dataSource={dataSource}/>

              <div className='session-static-title' style={{marginTop: "12px"}}>
              Registrant & Contact Information
              </div>
              <p className='session-static-desc' style={{marginTop: "12px"}}>Below is the information of the registrant participating in the session.</p>
                  <Table 
                  columns={registrant_column}
                   dataSource={reg_dataSource} 
                   pagination={false}
                   />
                   
                   <div className='session-static-title' style={{marginTop: "12px"}}>
                   Registrant Additional Information
              </div>
              <p className='session-static-desc'>Below are the responses and timestamps provided by the participant to the additional questions and clauses displayed upon registration.</p>
                          <div>
                            {
                               !_.isEmpty(_.get(regDetails, 'details.registration_info')) && <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                               <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                                 <div className='container-titles'>Question & Reponses</div>
                                 <div className='container-titles-desc'>These are the responses provided by the registrant to the questions displayed to them upon registration.</div>
                                 </div>
                                 <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                                  
                                  <Table columns={additional_column} dataSource={registrationInfo} pagination={false}/>
                                 </div>
                                 </div>
                            }
                          </div>
              
                          <div>
                    {
                      !_.isEmpty(_.get(regDetails, 'details.experience.clauses')) && <div style={{width: "100%", display: "flex", alignItems: "flext-start", paddingLeft: "1px", marginTop: "30px"}}>
                      <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                        <div className='container-titles'>Clauses & Acceptance Timestamp</div>
                        <div className='container-titles-desc'>These are the clauses and acceptance timestamps the registrant completed upon registration.</div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                         
                         <Table columns={clause_column} dataSource={clauseInfo} pagination={false}/>
                        </div>
                        </div>
                    }
                   </div>
                          
                          
                </div>

                </Modal>
                   </div>
              
          </div>
          )
    };

    return render();
  
}

const mapStateToProps = state => {
    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    experienceViewAPI: details => dispatch(global.redux.action.experience.view(details)),
    orderListAPI: details => dispatch(global.redux.action.experience.orderList(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(CancelledRegistrationList)