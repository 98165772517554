import React, {useEffect, useState} from 'react';
import { Button, Form, Input, Select, Modal, notification } from "antd";
import { connect } from 'react-redux';
import _ from 'lodash';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom'

import "./style.css";
import Profile from "./profile"
import MediaImages from "./media-images";
import TaxBanking from "./tax-banking";
import ProfileReviews from "./reviews";
import HostNotification from './notifications';
import Menus from '../menu';

const { Option } = Select;
const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
]

const HostProfile = (props) => {
    const [form] = Form.useForm();
  const options = ['Contact', 'Profile', 'Media & Images', 'Tax & Banking', 'Reviews', 'Notifications'];
  const localStorageKey = 'profileOption';
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedOption, setSelectedOption] = useState('Contact');
  const [hostData, setHostData] = useState({});
  const [tempSelectedOption, setTempSelectedOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [country, setcountry] = useState(null);
  const [address, setAddress] = useState('');
  const [address_results, setaddress_results] = useState([]);
  const [address_fetching, setaddress_fetching] = useState(false);
  const [TaxListData, setTaxListData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  
  


  useEffect(() => {
    getHostProfile();
    loadTaxList();
  }, []);

  useEffect(() => {
    if(searchParams.get('profile_menu') === 'notifications'){
      setSelectedOption('Notifications');
    }
  }, [searchParams]);

  const handleChange = (value) => {
    
    if(typeof value === "string"){
      setSelectedValues([value]);
    }
    
    
  };

  const getHostProfile = () => {
    props.hostProfileData({
        callback:(res, data) => {
            
            if(!_.isEmpty(_.get(data, 'data.details'))){
                setHostData(_.get(data, 'data.details'));
                form.setFieldsValue({
                    first_name: _.startCase(_.get(data, 'data.details.first_name')),
                    last_name: _.startCase(_.get(data, 'data.details.last_name')),
                    email: _.get(data, 'data.details.contact_details.email'),
                    phone: _.get(data,'data.details.contact_details.phone').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
                    country:_.upperCase(_.get(data,'data.details.country'))=="CA"?"CAD":"USD",
                    location:  _.get(data, 'data.details.address'),
                    timezones: _.isArray(_.get(data,'data.details.meta_details.timezone'))?_.head(_.get(data,'data.details.meta_details.timezone')):_.get(data,'data.details.meta_details.timezone')
                });
                const address_results = [{
                    text: _.get(data, 'data.details.address'),
                    value: JSON.stringify({ text: _.get(data, 'data.details.address'), lat: _.get(data, 'data.details.location.x'), lng: _.get(data, 'data.details.location.y'), postal_code: '' }),
                }]
          
                setaddress_results(address_results)
            }
        }
    })
  }

  const handleOptionClick = (option) => {
      
      setSelectedOption(option);
    };

    const onFinish = (e) => {
      
      let request_details={...e}
      if (isJson(_.get(_.head(address_results),'value'))) {
        let location_parse = JSON.parse(_.get(_.head(address_results),'value'))
        request_details.location=`${_.get(location_parse,'lat')},${_.get(location_parse,'lng')}`
        request_details.address = _.get(location_parse,'text')
        request_details.zip_code = _.get(location_parse,'postal_code')
      }
        request_details.country =_.get(request_details, 'country')=="USD"?"US":"CA"
        if(_.get(request_details, 'email')){
           delete request_details.email
        };

        if(_.get(request_details, 'phone')){
          delete request_details.phone
       };

       props.updateContactDetailsAPI({
        body:{
          phone: _.get(e, 'phone').replace(/-/g, ''),
          email: _.get(e, 'email')
        },
        callback: (c_res, c_val) => {
          
        }
       })

      props.hostUpdateProfile({
        body:{
            ...request_details
        },
        callback: (response, value) => {
          
            if(response){
              if(_.get(value, 'success')){
                notification.success({
                  message: "Success",
                  description: "Contact details updated successfully"
                })
              }
                getHostProfile();
            }
        }

        
    });

    props.hostMetaDetailsUpdateAPI({
          body:{
            "key": "timezone",
            "value": [_.get(request_details, 'timezones')]
          },
          callback: (meta_res, meta_data) => {
            
          }
    })
    };
    const onFinishFailed = (errorInfo) => {
      
    };

    const handleSelectChange = (value) => {
        
        setTempSelectedOption(value);
        // setIsModalOpen(true);
      };
      const handleModalOk = (value) => {
        
        setSelectedOption(tempSelectedOption);
        setcountry(tempSelectedOption);
        // form.setFieldsValue({currency:tempSelectedOption});
        setIsModalOpen(false);
      };

      const handleModalCancel = () => {
        
        setTempSelectedOption(_.upperCase(_.get(hostData, 'country'))=="CA"?"CAD":"USD");
        setcountry(_.upperCase(_.get(hostData, 'country'))=="CA"?"CAD":"USD")
        form.setFieldsValue({country:_.upperCase(_.get(hostData, 'country'))=="CA"?"CAD":"USD"});
        setIsModalOpen(false);
      };

      const loadTaxList =()=>{
        props.taxList({
          params:{limit:100},
          callback:(res,data)=>{
            
        if(res==true && _.get(data,'data.items[0]'))
            setTaxListData(_.get(data,'data.items'))
          }
        })
            }

      const handleChange1 = (value) => {
        setAddress(value);
      };

      const fetchPostalCode = address_components => {
        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        return postal_code;
    };

      const handleSelect = async (value) => {

        
        setaddress_results([])
          setaddress_fetching(true);
          geocodeByAddress(value).then(body => {
              
              const address_results = _.map(body, location => ({
                  text: location.formatted_address,
                  value: JSON.stringify({ text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
              }));
      
              setaddress_results(address_results)
              setaddress_fetching(false);
             
            form.setFieldsValue({
                location: value
            })
            
            let state =extractStateName(body)
            let ada = _.filter(TaxListData,(v)=>_.lowerCase(v.state)==_.lowerCase(state))
            if(_.size(ada)>0){
              form.setFieldsValue({
                tax_state: _.get(ada[0],'id')
            })
            }
            
            
            setaddress_results(address_results)
          }).catch(error => {
              setaddress_results([])
              setaddress_fetching(true);
          });
      };

      const extractStateName = results => {
        for (let i = 0; i < results[0].address_components.length; i++) {
          const component = results[0].address_components[i];
          if (component.types.includes('administrative_area_level_1')) {
            return component.long_name; // Return the state name
          }
        }
        return '';
      };

      const searchOptions = {
        componentRestrictions: { country: form.getFieldValue('country')=="USD"?['us']:['ca'] }, // Restrict search to United States and Canada
      };

      const formatPhoneNumber=(phoneNumberString)=> {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          // return   match[1] + '-' + match[2] + '-' + match[3];
          form.setFieldsValue({
            phone:match[1] + '-' + match[2] + '-' + match[3]
          })
        }
        return null;
      }

      const isJson=(str)=> {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      };

  const render = () => {
    
      return (
          <div>
               <div className='container' style={{maxWidth:'100%'}}>
               <div className="row">
                  
                  <div className='col-12'>
                    <Menus/>
                      <div style={{display: "flex", flexDirection: "column", marginTop: "10px"}}>
                          <div className='profile-title'>
                              Profile
                          </div>
                          <div className='profile-description'>
                          Update your Club or Coach information.
                          </div>
                      </div>
                      <hr/>
                  </div>

                  {/* <div className='col-12'> */}
                      <div className='card' style={{marginTop: "8px", width: "100%", marginBottom: "15px", border: "none"}}>
                          <div style={{display: "flex", flexDirection: "row"}}>
                          <div className='col-lg-3'>
                          <div style={{display: "flex", flexDirection: "column", marginTop: "12px"}}>
                          {options.map((option, index) => (
                              <div
                              style={{width: "75%"}}
                                  key={index}
                                  className={`radio-button ${selectedOption === option ? 'selected' : ''}`}
                                  onClick={() => handleOptionClick(option)}
                              >
                                  {option}
                              </div>
                          ))}
                          </div>
                      </div>
                     
                      <div className='col-lg-9'>
                        {
                            selectedOption === 'Contact' && (
                                <Form 
                        layout='vertical'
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        >
                        <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Name</div>
                          <div className='container-titles-desc'>Enter your first and last name here. </div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item 
                            label="First name"
                            name="first_name" 
                            rules={[
                              {
                                required: true,
                                message: 'Please input your first name',
                              },
                            ]}
                            >
                            <Input style={{ width: "100%", height: "40px"}}/>
                            </Form.Item>
                            <Form.Item 
                            label="Last name"
                            name="last_name" 
                            rules={[
                              {
                                required: true,
                                message: 'Please input your last name',
                              },
                            ]}
                            >
                            <Input style={{width: "100%", height: "40px"}}/>
                            </Form.Item>
                          </div>
                          </div>
                          <hr/>
                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Contact Email address</div>
                          <div className='container-titles-desc'>Enter your email address here.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item 
                            label="Contact Email"
                            name="email" 
                            rules={[
                              {
                                required: true,
                                message: 'Please input your email',
                              },
                            ]}
                            >
                            <Input style={{ width: "100%", height: "40px"}}/>
                            </Form.Item>
                          </div>
                          </div>
                          <hr/>
                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Contact Phone number</div>
                          <div className='container-titles-desc'>Enter your phone number here. </div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item 
                            label="Contact Phone number"
                            name="phone" 
                            rules={[
                              {
                                required: true,
                                message: 'Please input your phone number',
                              },
                            ]}
                            >
                            <Input style={{height: "40px", width: "100%"}} onChange={(e)=>formatPhoneNumber(_.get(e,'target.value'))} />
                            </Form.Item>
                          </div>
                          </div>
                          <hr/>
                          <div style={{width: "100%", display: "flex", alignItems: "flext-start", marginTop: "30px"}}>
                        <div style={{display: "flex", flexDirection: "column", paddingRight: "15px", flex: 1.5}}>
                          <div className='container-titles'>Address and timezone</div>
                          <div className='container-titles-desc'>Select your country, enter your address and select a default timezone.</div>
                          </div>
                          <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                            <Form.Item 
                            label="Country"
                            name="country" 
                            rules={[
                              {
                                required: true,
                                message: 'Please select your country.',
                              },
                            ]}
                            >
                            <Select
                            onChange={handleSelectChange}
                            value={tempSelectedOption}
                            // defaultValue={selectedOption}
                            style={{width:'100%'}}
                                optionFilterProp="children">
                            {_.map(countryList,(v,i)=>{
                                return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
                            })}
                            </Select>
                            </Form.Item>
                            <Form.Item 
                            label="Address"
                            name="location" 
                            rules={[
                              {
                                required: true,
                                message: 'Please input your address.',
                              },
                            ]}
                            >
                            <PlacesAutocomplete
                              

                              value={address}
                              onChange={handleChange1}
                              onSelect={handleSelect}
                              searchOptions={searchOptions}
                              
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <Input {...getInputProps({ placeholder: 'Search address' })}  />
                                  {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
                                  <div>
                                    {loading ? <div>Loading...</div> : null}
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
                                        marginTop:10,
                                      };
                                      
                                      return (
                                        <div {...getSuggestionItemProps(suggestion, { style })}>
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            </Form.Item>
                            <Form.Item 
                            label="Timezone"
                            name="timezones" 
                            >
                            <Select
                                // mode="multiple"
                                value={selectedValues}
                                style={{
                                width: '100%',
                                }}
                                placeholder="Select a timezone"
                                // defaultValue={['china']}
                                onChange={handleChange}
                                // optionLabelProp="label"
                            >
                                {(moment.tz.zonesForCountry(country=="USD"?"US": "CA")).map((item, index) => {
                                
                                                            return (
                                                                <Option key={index} value={item}>{item}</Option>

                                                            )
                                                            })}
                            </Select>
                            </Form.Item>
                            <Form.Item>
                              <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end"}}>
                              <Button style={{marginRight: "15px"}}>Cancel</Button>
                              <Button htmlType="submit" style={{backgroundColor: "#EF6820", color: "#fff"}}>Save changes</Button>
                              </div>
                            </Form.Item>
                          </div>
                          </div>
                        </Form>
                            )
                        }

                        {
                            selectedOption === 'Profile' && (
                                <Profile/>
                            )
                        }

                        {
                            selectedOption === 'Media & Images' && (
                                <MediaImages/>
                            )
                        }

                        {
                            selectedOption === 'Tax & Banking' && (
                                <TaxBanking/>
                            )
                        }

                        {
                            selectedOption === 'Reviews' && (
                                <ProfileReviews/>
                            )
                        }

{
                            selectedOption === 'Notifications' && (
                                <HostNotification/>
                            )
                        }
                        
                       
                          
                      </div>
                          </div>
                      
                      
                      </div>
                  {/* </div> */}
               
              
              
                  
               </div>
               <div>
              <Modal centered={true} title="Are you sure?" open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel} okText="Yes" cancelText="No">
        <p>Do you want to change country?</p>
      </Modal>
            </div>
               </div>
             
               
          </div>
        )
  }

  return render();
 
}

const mapStateToProps = state => {
    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    taxList: details => dispatch(global.redux.action.tax.list(details)),
    hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
    hostMetaDetailsUpdateAPI: details => dispatch(global.redux.action.host.metaDetailsUpdate(details)),
    updateContactDetailsAPI: details => dispatch(global.redux.action.host.updateHostContactDetails(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(HostProfile)