import {  TextField, Typography, Container, Box, InputAdornment, styled, makeStyles } from '@mui/material';
import { Formik, Field, ErrorMessage, useFormikContext } from 'formik';
import { Form, Input,Button } from 'antd';
import * as Yup from 'yup';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import _ from 'lodash';

import "../style.css"
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import RightSideHeader from './right-side-header';
import RightFooter from './right-footer';
const validationSchema = Yup.object().shape({
       first_name: Yup.string().required('Please enter a first name'),
       last_name: Yup.string().required('Please enter a last name'),
       email: Yup.string().email('Please enter a valid email').required('Please enter a valid email'),
       phoneNumber: Yup.string().matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/g, 'Please enter a valid phone number').required('Please enter a valid phone number'),
     });
   
const PersonalInfo =(props)=>{
       const [userDetails, setuserDetails] = useState({})
       const formikProps = useFormikContext()
       const [initialValues, setInitialValues] = useState({
              first_name: '',
              last_name: '',
              email: '',
              phoneNumber: '',
            })
           
       useEffect(() => {
              
              let conditionType = _.includes(_.get(props,'islogindata.types'),1)==true?'hostProfile':'guestProfile';
              if(_.get(props,`${conditionType}.id`)){
                     setuserDetails(_.get(props,`${conditionType}`))
                     setInitialValues({
                            ...initialValues,
                            first_name:_.get(props,`${conditionType}.first_name`),
                            last_name:_.get(props,`${conditionType}.last_name`),
                     })
              }
              
       }, []);                                                        
     

       const CustomInput = ({ field, form, ...props }) => (
              <Form.Item
                validateStatus={form.touched[field.name] && form.errors[field.name] ? 'error' : ''}
                help={form.touched[field.name] && form.errors[field.name] ? form.errors[field.name] : ''}
              >
                <Input {...field} {...props} />
              </Form.Item>
            );
            
       
          
            const handleSubmit = (values) => {
              props?.onChangeFlow(3,values)
              
            };

            

    const customFieldLabel =(text)=>{
return <Box sx={{display:'flex',alignItems:'flex-start'}}><Typography
sx={{color:"#344054",fontSize:'14px',fontWeight:"500"}}
>{text}</Typography><Typography sx={{color:"#344054",fontSize:'14px',fontWeight:"500"}}>*</Typography></Box>
    }   
    
    const customErrorText =(text)=>{
return <Box component={"span"} sx={{display:'flex',alignItems:'flex-start',marginTop:'6px'}}><Typography
sx={{color:"#F04438",fontSize:'14px',fontWeight:"400"}}
>{text}</Typography></Box>
    }

    const customSuffixIcon =()=>{
       return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
       <g clip-path="url(#clip0_12730_906)">
         <path d="M7.99992 5.33301V7.99967M7.99992 10.6663H8.00659M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z" stroke="#F04438" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
       </g>
       <defs>
         <clipPath id="clip0_12730_906">
           <rect width="16" height="16" fill="white"/>
         </clipPath>
       </defs>
     </svg>
    }
return <Box sx={{width:'100%'}}  >
  <RightSideHeader />
  <Box sx={{width:"100%", display:'flex',justifyContent:'center'}}> 
       <Box sx={{width:{
              xs: "90%", // mobile
              sm: "80%", // tablet
              md: "70%", // small desktop
              lg: "65%",
       }}}>
   
<Box mt={5} textAlign="center">
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FDEAD7"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF6EE" stroke-width="8"/>
<path d="M30 18.2695V22.4001C30 22.9601 30 23.2401 30.109 23.4541C30.2049 23.6422 30.3578 23.7952 30.546 23.8911C30.7599 24.0001 31.0399 24.0001 31.6 24.0001H35.7305M32 29H24M32 33H24M26 25H24M30 18H24.8C23.1198 18 22.2798 18 21.638 18.327C21.0735 18.6146 20.6146 19.0735 20.327 19.638C20 20.2798 20 21.1198 20 22.8V33.2C20 34.8802 20 35.7202 20.327 36.362C20.6146 36.9265 21.0735 37.3854 21.638 37.673C22.2798 38 23.1198 38 24.8 38H31.2C32.8802 38 33.7202 38 34.362 37.673C34.9265 37.3854 35.3854 36.9265 35.673 36.362C36 35.7202 36 34.8802 36 33.2V24L30 18Z" stroke="#EF6820" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</Box>
<Typography component={"div"} sx={{
       color:"#101828",
       marginTop:'32px',
       fontSize: {
              xs: "17px", // mobile
              sm: "19px", // tablet
              md: "23px", // small desktop
              lg: "30px",
            },
            fontWeight: "600",
            lineHeight: {
               xs: "28px", // mobile
               sm: "30px", // tablet
               md: "33px", // small desktop
               lg: "38px",
             },
}} align="center" >
  Personal information
</Typography>
<Typography component={"div"} align="center"
sx={{
       marginTop:'12px',
       color:"#475467",
       fontSize: {
              xs: "14px", // mobile
              sm: "15px", // tablet
              md: "16px", // small desktop
              lg: "16px",
            },
            
}}
>
  Tell us about yourself
</Typography>
<Box sx={{width:"100%",marginTop:'32px'}}>
<Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
        <Form className='personal-info-form' layout='vertical' onFinish={handleSubmit}>
          <Form.Item
            style={{ marginBottom: touched.first_name && errors.first_name ? "40px" : '20px' }}
            help={touched.first_name && errors.first_name ? customErrorText(errors.first_name) : ''}
            label={customFieldLabel("First name")}
            validateStatus={touched.first_name && errors.first_name ? 'error' : ''}
            id='first_name'
            key={"first_name"}
          >
            <Input
            
             suffix={touched.first_name && errors.first_name ? customSuffixIcon() : <span />}
              name="first_name"
              placeholder="Enter your first name"
              onChange={handleChange}
              
              onBlur={handleBlur}
              onFocus={handleBlur}
              value={values.first_name}
              style={{
                height: '44px', borderRadius: '8px',
                borderColor: touched.first_name && errors.first_name ? '#f5222d' : '#D0D5DD',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
              }}
              rootClassName='common-placeholder-container'
            />
          </Form.Item>
          

          <Form.Item
            label={customFieldLabel("Last name")}
            validateStatus={touched.last_name && errors.last_name ? 'error' : ''}
            style={{ marginBottom: touched.last_name && errors.last_name ? "40px" : '20px' }}
            help={touched.last_name && errors.last_name ? customErrorText(errors.last_name) : ''}
            id='last_name'
            key={"last_name"}
          >
            <Input
           
              suffix={touched.last_name && errors.last_name ? customSuffixIcon() :  <span />}
              name="last_name"
              placeholder="Enter your last name"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleBlur}
              value={values.last_name}
              style={{
                height: '44px', borderRadius: '8px',
                borderColor: touched.last_name && errors.last_name ? '#f5222d' : '#D0D5DD',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
              }}
              rootClassName='common-placeholder-container'
            />
          </Form.Item>
          <Form.Item
            label={customFieldLabel("Contact email")}
            validateStatus={touched.email && errors.email ? 'error' : ''}
            style={{ marginBottom: touched.email && errors.email ? "40px" : '20px' }}
            help={touched.email && errors.email ? customErrorText(errors.email) : ''}
          >
            <Input
            
              suffix={touched.email && errors.email ? customSuffixIcon() :  <span />}
              name="email"
              placeholder="name@email.com"
              onChange={handleChange}
              // onBlur={handleBlur}
              // onFocus={handleBlur}
              value={values.email}
              style={{
                height: '44px', borderRadius: '8px',
                borderColor: touched.email && errors.email ? '#f5222d' : '#D0D5DD',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
              }}
              rootClassName='common-placeholder-container'
            />
          </Form.Item>
          <Form.Item
            label={customFieldLabel("Phone number")}
            validateStatus={touched.phoneNumber && errors.phoneNumber ? 'error' : ''}
            style={{ marginBottom: touched.phoneNumber && errors.phoneNumber ? "40px" : '20px' }}
            help={touched.phoneNumber && errors.phoneNumber ? customErrorText(errors.phoneNumber) : ''}
          >
            <Input
              suffix={touched.phoneNumber && errors.phoneNumber ? customSuffixIcon() :  <span />}
              name="phoneNumber"
       prefix={"+1"}
       
              placeholder="999-123-4567"
              onChange={(e) => {
                     const { value } = e.target;
                     // Remove any non-digit characters
                     const formattedValue = value.replace(/\D/g, '').slice(0, 10);
                     // Format the number as 3-3-4
                     const formattedNumber = formattedValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                     // Set the formatted value to the form field
                     handleChange({ target: { name: 'phoneNumber', value: formattedNumber } });
                   }}
              onBlur={handleBlur}
              onFocus={handleBlur}
              value={values.phoneNumber}
              style={{
                height: '44px', borderRadius: '8px',
                borderColor: touched.phoneNumber && errors.phoneNumber ? '#f5222d' : '#D0D5DD',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
              }}
              rootClassName='common-placeholder-container'
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{
              width: '100%', marginTop: '4px', height: '44px', background: "#EF6820", color: "#FFF",
              fontSize: '16px',
              fontWeight: "600"
            }}>
              Continue
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
</Box></Box>   
</Box>
<RightFooter />
</Box>

}


const mapStateToProps = state => {
       
       return {
          checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
          isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
          islogindata: _.get(state, 'app.account.isLogin'),
          hostProfile: _.get(state, 'app.host.profile.data.details'),
          guestProfile: _.get(state, 'app.guest.profile.data.details')
     
       }
     }
     
     const mapDispatchToProps = dispatch => ({
       isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
       hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
       guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
       list: details => dispatch(global.redux.action.categories.list(details)),
       hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
       guestUpdateProfile: details => dispatch(global.redux.action.guest.update(details)),
       updatePhone: details => dispatch(global.redux.action.guest.updatePhone(details)),
       request: details => dispatch(global.redux.action.host.request(details)),
       hostCreate: details => dispatch(global.redux.action.host.hostCreate(details)),
       verifyInviteCode: details => dispatch(global.redux.action.host.verifyInviteCode(details)),
       taxList: details => dispatch(global.redux.action.tax.list(details)),
     
     
      
     });
     export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)

