import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from "react-redux";
import chatKitty from '../../core_units/chat-system/chatkitty';
import ChatKittyHome from '../../core_units/chat-system/components/home/index';

import "./style.css"
import { Badge } from 'antd';

const Menus = (props) => {
const [searchParams, setSearchParams] = useSearchParams();
const [channels, setChannels] = useState([]);
const [isFetchChannelProcessed, setIsFetchChannelProcessed] = useState(false);
const [lastNotificationChannelId, setLastNotificationChannelId] = useState(null);
const [lastReadBadge, setLastReadBadge] = useState(null);
const [messageId, setMessageId] = useState(null);
const [countResut ,setcountResut] = useState(null)

const [unread, setUnread] = useState(false);
const [unreadCount, setUnreadCount] = useState(0);
const [allMessagesRead, setAllMessagesRead] = useState(false);
const[getTemplateList,setTemplateList] = useState([]);
const [response, setResponse] = useState("");
const [endpoint, setEndpoint] = useState("http://3.96.180.154:1337/realtime/subscribe?access_token=nMWYkiyDHf4hhDHuMrks8HGxB1yZqjtsGYOHQMNtuDlosgrLvEagN2PpWyJkH03iVNuDaXHHYYNoqKCH8mTWNSKP0ovWZAYyBzZIPUyoMTTIRFZtZcXh0htGE8S5PxYRbgEqkKRMQo60DJXc50kJwpntP6MmPPMpeGmofAGmimiNB07X40S0c74DUXWfvM8qVINUzKJpmwPpZu1O1JgCDeLoIbWaXQpFJwMeeCdNmmIrQ2IXWEOBXqe4y5Owodg");






let navigate = useNavigate();
const localStorageKey = 'selectedOption';
const [selectedOption, setSelectedOption] = useState(() => {
// Retrieve the selected option from localStorage on component mount
return localStorage.getItem(localStorageKey) || null;
});
const handleOptionClick = (option) => {
setSelectedOption(option);
};

useEffect(() => {

if(_.get(props,'chatKittyCurrentUserData.id')){
// getChannelList()
return initialyzeNotificationReceiveEvent();
}
}, [_.get(props,'chatKittyCurrentUserData.id')]);

const getChannelList =()=>{
if(_.get(props,'chatKittyCurrentUserData.id')){
chatKitty.instance.listChannels({ joinable: false }).then((result) => {
processChannelData(_.get(result, 'paginator.items', []));
});
}
}

useEffect(() => {
if(_.get(props,'chatKittyCurrentUserData.id')){
getChannelList()
}
}, [props]);

useEffect(() => {
if(_.get(props,'chatKittyCurrentUserData.id')){
if (!_.chain(lastNotificationChannelId).toString().isEmpty().value()) {
updateAthleteUnreadMessagesCount(lastNotificationChannelId);
}
}
}, [lastNotificationChannelId]);

const initialyzeNotificationReceiveEvent = () => {
if(_.get(props,'chatKittyCurrentUserData.id')){
return chatKitty.instance.onNotificationReceived((notification) => {
const condition1 = _.overEvery([
notification => _.chain(notification).get('type', null).eq('USER:SENT:MESSAGE').value(),
notification => !_.chain(notification).get('message.user.name', null).eq(_.get(props, 'chatKittyCurrentUserData.name')).value(),
() => _.eq('Actions.currentScene', 'DashboardChatChannelList')
]);
const condition2 = _.overEvery([
notification => _.chain(notification).get('data.type', null).eq('USER:SENT:MESSAGE').value(),
notification => !_.chain(notification).get('data.message.user.name', null).eq(_.get(props, 'chatKittyCurrentUserData.name')).value(),
() => _.eq('Actions.currentScene', 'DashboardChatChannelList')
]);
getChannelList()
// if (condition1(notification)) {
// setLastNotificationChannelId(_.get(notification, 'channelId', null));
// } else if (condition2(notification)) {
// setLastNotificationChannelId(_.get(notification, 'data.channelId', null));
// }
});
}
};

const updateAthleteUnreadMessagesCount = channelId => {
if(_.get(props,'chatKittyCurrentUserData.id')){
const items = _.clone(channels);
const channelIndex = _.findIndex(items, { 'id': channelId });
if (_.gt(channelIndex, -1)) {
items[channelIndex].athleteUnreadMessagesCount = _.add(items[channelIndex].athleteUnreadMessagesCount, 1);
setChannels(items)
}
}
};

const processChannelData = async (channelItems, currentItemIndex = 0) => {
if(_.get(props,'chatKittyCurrentUserData.id')){
if (_.chain(currentItemIndex).add(1).lte(_.size(channelItems)).value()) {
const result = await chatKitty.instance.countUnreadMessages({ channel: channelItems[currentItemIndex] });
channelItems[currentItemIndex].athleteUnreadMessagesCount = _.get(result, 'count');
processChannelData(channelItems, _.add(currentItemIndex, 1));
} else {
setIsFetchChannelProcessed(true);
let final_messages = []
_.map(channelItems, (value, index) => {
})
let countResut = _.map(channelItems,'athleteUnreadMessagesCount')
setcountResut(_.sum(countResut))
setChannels(channelItems);
}
}
//
};

const checkIfUnread = async channel => {
if(_.get(props,'chatKittyCurrentUserData.id') && channel){
const unreadResults = await chatKitty.instance.checkChannelUnread({
channel: channel,
});
if (unreadResults.succeeded) {
setUnread(unreadResults.unread);
}
}
};

const getUnreadCount = async channel => {
  

if(_.get(props,'chatKittyCurrentUserData.id') && channel){
const countResults = await chatKitty.instance.countUnreadMessages({channel: channel});
if (countResults.succeeded) {
setUnreadCount(countResults.count);
}
}
};

useEffect(() => {
if(_.get(props,'chatKittyCurrentUserData.id')){
if(_.get(props, 'stateData')){
checkIfUnread(_.get(props, 'stateData'));
getUnreadCount(_.get(props, 'stateData'));
if(allMessagesRead === true){
readAllMessages(_.get(props, 'stateData'))
}
}
}
}, [allMessagesRead]);

const readAllMessages = async(channel) => {
if(_.get(props,'chatKittyCurrentUserData.id')){
const countResults = await chatKitty.instance.readChannel({channel: channel});
setAllMessagesRead(_.get(countResults, 'succeeded'))
}
}

const loadTemplateList=()=>{
let windowUrl = window.location;
let striged = windowUrl.toString();
let url_splitted = striged.split('/')
// if(url_splitted[4] === 'main-menu'){
props.templateList({
params:{ sort: 'created_at.desc', limit:1},
callback:(res,data)=>{

if(res==true){
setTemplateList(_.get(data, 'data.items'))
}
}
})
// }
}
useEffect(() => {
// Store the selected option in localStorage whenever it changes
localStorage.setItem(localStorageKey, selectedOption);
}, [selectedOption]);
const options = ['Template', 'Manage', 'Reports', 'Communication', 'Profile'];

const handleNavigation = (value) => {
if(props?.type_flow=="template"){
if(props?.outsideDisabled==false){
if(value === "template"){
navigate(`/template-list?menu=template`);
} else if(value === "manage"){
navigate(`/communiti/manage?menu=manage`);
} else if(value === "reports"){
navigate(`/communiti/finances-list?menu=reports`);
} else if(value === "chat"){
navigate(`/communiti/chats-notification?menu=chat`);
} else if(value === "profile"){
navigate(`/communiti/host?menu=profile`);
}
}else if(!props?.outsideDisabled && props?.outsideDisabled!=true){
if(value === "template"){
navigate(`/template-list?menu=template`);
} else if(value === "manage"){
navigate(`/communiti/manage?menu=manage`);
} else if(value === "reports"){
navigate(`/communiti/finances-list?menu=reports`);
} else if(value === "chat"){
navigate(`/communiti/chats-notification?menu=chat`);
} else if(value === "profile"){
navigate(`/communiti/host?menu=profile`);
}
}else{
if(props?.outsideDisabled==true){
props?.onOutSideClick(true)
}
}
}else{
if(value === "template"){
navigate(`/template-list?menu=template`);
} else if(value === "manage"){
navigate(`/communiti/manage?menu=manage`);
} else if(value === "reports"){
navigate(`/communiti/finances-list?menu=reports`);
} else if(value === "chat"){
navigate(`/communiti/chats-notification?menu=chat`);
} else if(value === "profile"){
navigate(`/communiti/host?menu=profile`);
}
}
}

const render = () => {
let customStyle = _.get(props,'customStyle')? {marginTop: "12px",..._.get(props,'customStyle')}:{marginTop: "12px", marginLeft: "70px"}
return (
<div>
<ChatKittyHome {...props}/>
<div style={{marginTop: "12px"}}>
{/* {options.map((option, index) => (
<div
key={index}
className={`radio-button ${selectedOption === option ? 'selected' : ''}`}
onClick={() => handleOptionClick(option)}
>edit_session
{option}
</div>
))} */}
<div onClick={() => handleNavigation("template")} className={`radio-button-menu ${searchParams.get("menu") === "template" ? "selected" : 'unselected'}`}><div style={{display: "flex", flexDirection: "row"}}>{searchParams.get('menu') === 'template' ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} <div style={{marginLeft: "12px"}}>Create</div></div></div>
<div style={{marginLeft: "12px"}} onClick={() => handleNavigation("manage")} className={`radio-button-menu ${searchParams.get("menu") === "manage" ? "selected" : 'unselected'}`}><div style={{display: "flex", flexDirection: "row"}}>{ searchParams.get("menu") === "manage" ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0505 9H5.5C4.11929 9 3 7.88071 3 6.5C3 5.11929 4.11929 4 5.5 4H15.0505M8.94949 20H18.5C19.8807 20 21 18.8807 21 17.5C21 16.1193 19.8807 15 18.5 15H8.94949M3 17.5C3 19.433 4.567 21 6.5 21C8.433 21 10 19.433 10 17.5C10 15.567 8.433 14 6.5 14C4.567 14 3 15.567 3 17.5ZM21 6.5C21 8.433 19.433 10 17.5 10C15.567 10 14 8.433 14 6.5C14 4.567 15.567 3 17.5 3C19.433 3 21 4.567 21 6.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0505 9H5.5C4.11929 9 3 7.88071 3 6.5C3 5.11929 4.11929 4 5.5 4H15.0505M8.94949 20H18.5C19.8807 20 21 18.8807 21 17.5C21 16.1193 19.8807 15 18.5 15H8.94949M3 17.5C3 19.433 4.567 21 6.5 21C8.433 21 10 19.433 10 17.5C10 15.567 8.433 14 6.5 14C4.567 14 3 15.567 3 17.5ZM21 6.5C21 8.433 19.433 10 17.5 10C15.567 10 14 8.433 14 6.5C14 4.567 15.567 3 17.5 3C19.433 3 21 4.567 21 6.5Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} <div style={{marginLeft: "12px"}}>Manage</div></div></div>
<div style={{marginLeft: "12px"}} onClick={() => handleNavigation("chat")} className={`radio-button-menu ${searchParams.get("menu") === "chat" ? "selected" : 'unselected'}`}><div style={{display: "flex", flexDirection: "row"}}>{ searchParams.get('menu') === 'chat' ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 12.5C8.5 12.5 9.8125 14 12 14C14.1875 14 15.5 12.5 15.5 12.5M14.75 7.5H14.76M9.25 7.5H9.26M7 18V20.3355C7 20.8684 7 21.1348 7.10923 21.2716C7.20422 21.3906 7.34827 21.4599 7.50054 21.4597C7.67563 21.4595 7.88367 21.2931 8.29976 20.9602L10.6852 19.0518C11.1725 18.662 11.4162 18.4671 11.6875 18.3285C11.9282 18.2055 12.1844 18.1156 12.4492 18.0613C12.7477 18 13.0597 18 13.6837 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V14C3 14.93 3 15.395 3.10222 15.7765C3.37962 16.8117 4.18827 17.6204 5.22354 17.8978C5.60504 18 6.07003 18 7 18ZM15.25 7.5C15.25 7.77614 15.0261 8 14.75 8C14.4739 8 14.25 7.77614 14.25 7.5C14.25 7.22386 14.4739 7 14.75 7C15.0261 7 15.25 7.22386 15.25 7.5ZM9.75 7.5C9.75 7.77614 9.52614 8 9.25 8C8.97386 8 8.75 7.77614 8.75 7.5C8.75 7.22386 8.97386 7 9.25 7C9.52614 7 9.75 7.22386 9.75 7.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 12.5C8.5 12.5 9.8125 14 12 14C14.1875 14 15.5 12.5 15.5 12.5M14.75 7.5H14.76M9.25 7.5H9.26M7 18V20.3355C7 20.8684 7 21.1348 7.10923 21.2716C7.20422 21.3906 7.34827 21.4599 7.50054 21.4597C7.67563 21.4595 7.88367 21.2931 8.29976 20.9602L10.6852 19.0518C11.1725 18.662 11.4162 18.4671 11.6875 18.3285C11.9282 18.2055 12.1844 18.1156 12.4492 18.0613C12.7477 18 13.0597 18 13.6837 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V14C3 14.93 3 15.395 3.10222 15.7765C3.37962 16.8117 4.18827 17.6204 5.22354 17.8978C5.60504 18 6.07003 18 7 18ZM15.25 7.5C15.25 7.77614 15.0261 8 14.75 8C14.4739 8 14.25 7.77614 14.25 7.5C14.25 7.22386 14.4739 7 14.75 7C15.0261 7 15.25 7.22386 15.25 7.5ZM9.75 7.5C9.75 7.77614 9.52614 8 9.25 8C8.97386 8 8.75 7.77614 8.75 7.5C8.75 7.22386 8.97386 7 9.25 7C9.52614 7 9.75 7.22386 9.75 7.5Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} <div style={{marginLeft: "12px"}}>Messages</div>
{ countResut > 1 && <div className={searchParams.get("menu") === "chat" ? 'message-unread-container' : 'message-unread-container-not-selected'} style={{marginLeft:'8px'}}>
<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
<circle cx="4.00002" cy="4" r="3" fill={searchParams.get("menu") === "chat" ? "white" : '#101828'}/>
</svg>
<span className={searchParams.get("menu") === "chat" ? 'message-unread-text' : 'message-unread-text-not-selected'}>{countResut}</span>
</div>}
</div></div>
<div style={{marginLeft: "12px"}} onClick={() => handleNavigation("reports")} className={`radio-button-menu ${searchParams.get("menu") === "reports" ? "selected" : 'unselected'}`}><div style={{display: "flex", flexDirection: "row"}}>{ searchParams.get('menu') === 'reports' ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 5C21 6.65685 16.9706 8 12 8C7.02944 8 3 6.65685 3 5M21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5M21 5V19C21 20.66 17 22 12 22C7 22 3 20.66 3 19V5M21 9.72021C21 11.3802 17 12.7202 12 12.7202C7 12.7202 3 11.3802 3 9.72021M21 14.44C21 16.1 17 17.44 12 17.44C7 17.44 3 16.1 3 14.44" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 5C21 6.65685 16.9706 8 12 8C7.02944 8 3 6.65685 3 5M21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5M21 5V19C21 20.66 17 22 12 22C7 22 3 20.66 3 19V5M21 9.72021C21 11.3802 17 12.7202 12 12.7202C7 12.7202 3 11.3802 3 9.72021M21 14.44C21 16.1 17 17.44 12 17.44C7 17.44 3 16.1 3 14.44" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} <div style={{marginLeft: "12px"}}>Customers</div></div></div>
<div style={{marginLeft: "12px"}} onClick={() => handleNavigation("profile")} className={`radio-button-menu ${searchParams.get("menu") === "profile" ? "selected" : 'unselected'}`}><div style={{display: "flex", flexDirection: "row"}}>{searchParams.get("menu") === "profile" ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} <div style={{marginLeft: "12px"}}>Profile</div></div></div>
</div>
</div>
);
}

return render();
};

const mapStateToProps = state => {
return {
chatKittyCurrentUserData: _.get(state, 'app.settings.chatKittyCurrentUserData'),
userProfile: _.get(state, 'app.host.profile.data.details'),
}
}

const mapDispatchToProps = dispatch => ({
financesListApi: details => dispatch(global.redux.action.finances.list(details)),
viewFinance: details => dispatch(global.redux.action.finances.view(details)),
templateList: details => dispatch(global.redux.action.template.list(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Menus)

