import React, {useContext, useEffect, useRef, useState} from 'react';
import { Button, Form, Input, Popconfirm, Table, Image, notification, Modal } from 'antd';
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons"
import { connect } from 'react-redux';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import '../../../onboarding-screen/style.css';
import './court-style.css';
const data = [
    {
      key: 1,
      name: '',
    },
  ];

  
const CourtsTab = (props) => {
  
  const [searchParams, setSearchParams] = useSearchParams();
    const [dataSource, setDataSource] = useState(data);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [getEventDetailsData, setEventDetailsData] = useState([]);

    useEffect(() => {
      getEventDetails();
    }, [props]);

    const openResetModal = () => {
      setIsResetModalOpen(true);
    };

    const confirmRest = () => {
      props.createCourtsAPI({
        body:{
          id: _.get(props, 'experience_league_data.leage_id'),
          courts: []
        },
        callback: (res, val) => {
          if(res){
            getEventDetails();
            setIsResetModalOpen(false);
          }
        }
      })
      
    }

    const cancelReset = () => {
      setIsResetModalOpen(false);
    }
      
        const handleInputChange = (value, field, key) => {
          const newData = [...dataSource];
          const index = newData.findIndex((item) => key === item.key);
          const item = newData[index];
          item[field] = value;
          setDataSource(newData);
        };
        const handleCourtNameChange = (key, value) => {
          const newCourts = [...getEventDetailsData];
          const index = newCourts.findIndex(court => court.key === key);
          newCourts[index].name = value;
          setEventDetailsData(newCourts);
        };

        const showModal = () => {
          setIsModalOpen(true);
        };

        const cancelModal = () => {
          setIsModalOpen(false);
        }

        const handleOk = () => {
          setIsModalOpen(false);
        };

        const getEventDetails = () => {
          let parameters = {
            id: _.get(props, 'experience_league_data.leage_id')
          }
          props.viewLeagueEventsAPI({
            params:parameters,
            callback: (response, value) => {
              
              if(response){
                setEventDetailsData(_.get(value, 'data.details.courts').map((obj, index) => ({...obj, key: index + 1})));
              }
            }
          })
        }

        const columns = [
           
            {
              title: 'Court Name (to be seen by users)',
              dataIndex: 'name',
              render: (text, record) => {
                
              return(
                <div style={{display: "flex", flexDirection: "row"}}>
  <Input
   value={text}
   onChange={(e) => handleCourtNameChange(record.key, e.target.value)}  />
   <Popconfirm title="Sure to delete?" onConfirm={() => handleRemoveRow(record.key)}>
               <MinusCircleOutlined style={{marginLeft: "10px", marginTop: "7px"}}/>
              </Popconfirm>
                {/* <Input
                  value={text}
                  onChange={(e) =>
                    handleInputChange(e.target.value, 'name', record.key)
                  }
                  placeholder="Enter a court name"
                /> */}
                
                </div>
              )
                },
            },
          ];
        

        const handleAddRow = () => {
            const newRow = {
              key: getEventDetailsData.length + 1,
              name: '',
            };
            setEventDetailsData([...getEventDetailsData, newRow]);//
          };

          const handleRemoveRow = (key) => {
            
            const newData = getEventDetailsData.filter((item) => item.key !== key);
            setEventDetailsData(newData);
          };

          const onClickSave = () => {

            
            props.createCourtsAPI({
              body:{
                id: _.get(props, 'experience_league_data.leage_id'),
                courts: getEventDetailsData.map(({key, ...rest}) => rest)
              },
              callback: (response, value) => {
                
                
                if(response){
                  showModal();
                }
              }
            })
          }



    const render = () => {
      // 
      let newData = dataSource.map(({key, ...rest}) => rest);
      
        return(
            <div>
                <div className='court_card_container'>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    
                    <button onClick={() => openResetModal()} className='court_reset_btn' style={{marginLeft: "675px"}}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.3335 5.83333H11.6668C14.4283 5.83333 16.6668 8.07191 16.6668 10.8333C16.6668 13.5948 14.4283 15.8333 11.6668 15.8333H3.3335M3.3335 5.83333L6.66683 2.5M3.3335 5.83333L6.66683 9.16667" stroke="#B42318" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>Reset</button>
                  </div>
                  {
  _.size(getEventDetailsData) > 0 && getEventDetailsData.map((input, index) => {
    
    return (
      <Input
        style={{ marginTop: "16px" }}
        addonAfter={
          <svg
            onClick={() => handleRemoveRow(_.get(input, 'key'))} // Pass a callback function here
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167"
              stroke="#344054"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        value={_.get(input, 'name')}
        onChange={(e) => handleCourtNameChange(_.get(input, 'key'), e.target.value)}
      />
    );
  })
}
                 

                  <button onClick={() =>handleAddRow()} className='court_add_court_btn'> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_10824_326)">
    <path d="M9.99984 6.66667V13.3333M6.6665 10H13.3332M18.3332 10C18.3332 14.6024 14.6022 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6024 1.6665 10C1.6665 5.39763 5.39746 1.66667 9.99984 1.66667C14.6022 1.66667 18.3332 5.39763 18.3332 10Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10824_326">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>Add court</button>

                </div>
                {_.size(getEventDetailsData) > 0 && <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <button style={{marginTop: "16px"}} onClick={() => onClickSave()} className='court_save_btn'> <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 6.66667H7.66667C7.19996 6.66667 6.9666 6.66667 6.78834 6.57584C6.63154 6.49594 6.50406 6.36846 6.42416 6.21166C6.33333 6.0334 6.33333 5.80004 6.33333 5.33333V2.5M14.6667 17.5V12.1667C14.6667 11.7 14.6667 11.4666 14.5758 11.2883C14.4959 11.1315 14.3685 11.0041 14.2117 10.9242C14.0334 10.8333 13.8 10.8333 13.3333 10.8333H7.66667C7.19996 10.8333 6.9666 10.8333 6.78834 10.9242C6.63154 11.0041 6.50406 11.1315 6.42416 11.2883C6.33333 11.4666 6.33333 11.7 6.33333 12.1667V17.5M18 7.77124V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V6.5C3 5.09987 3 4.3998 3.27248 3.86502C3.51217 3.39462 3.89462 3.01217 4.36502 2.77248C4.8998 2.5 5.59987 2.5 7 2.5H12.7288C13.1364 2.5 13.3402 2.5 13.5321 2.54605C13.7021 2.58688 13.8647 2.65422 14.0138 2.7456C14.182 2.84867 14.3261 2.9928 14.6144 3.28105L17.219 5.88562C17.5072 6.17387 17.6513 6.318 17.7544 6.48619C17.8458 6.63531 17.9131 6.79789 17.9539 6.96795C18 7.15976 18 7.36358 18 7.77124Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>Save</button>
                </div>}

<div>
{/* <div style={{display: "flex", flexDirection: "row", marginLeft:"588px", marginBottom: "15px"}}>
    <Button onClick={() => openResetModal()} style={{marginRight: "15px", borderRadius: "20px", color: "#fff", backgroundColor: "#e85f2f"}}>Reset<Image style={{width: "20px", height: "20px"}} preview={false} src={require('../../../../assets/img/refresh-cw.png')}/></Button>
    <Button onClick={() => onClickSave()} style={{borderRadius: "20px", color: "#fff", backgroundColor: "#e85f2f"}}>Save</Button>
</div> */}
{/* <Table  
columns={columns} 
dataSource={getEventDetailsData} 
pagination={false}
footer={() => (
<>
  <Button style={{width: "100%", color: "#000", backgroundColor: "#e85f2f"}} onClick={handleAddRow}><Image style={{width: "20px", height: "20px", marginLeft: "20px"}} preview={false} src={require('../../../../assets/img/plus-circle.png')}/>Add Court</Button>
</>
)}
/> */}
<div>
  <Modal centered={true} 
title="Saved" 
open={isModalOpen}
onCancel={cancelModal}
footer={[
<Button onClick={() => handleOk()}>Continue</Button>
]}
>
<p>Your changes have been saved</p>
</Modal>
</div>
<div>
  <Modal centered={true} title="Are you sure want to reset?" open={isResetModalOpen} onOk={confirmRest} onCancel={cancelReset}>
<p>Resetting will delete all the courts that you have created.</p>
</Modal>
</div>
</div>
            </div>
        );
    };

    return render();
};

const mapStateToProps = state => {
  return {
     checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
     isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
     islogindata: _.get(state, 'app.account.isLogin'),
     userProfile: _.get(state, 'app.host.profile.data.details'),
     guestUserProfile: _.get(state, 'app.guest.profile.data.details')

  }
};

const mapDispatchToProps = dispatch => ({
  createCourtsAPI: details => dispatch(global.redux.action.league.courtsCreate(details)),
  viewLeagueEventsAPI: details => dispatch(global.redux.action.league.viewLeagueEvents(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CourtsTab)