import _ from 'lodash';

import actionType from '../actionTypes';
import { reduxUtils } from '../settings';
const reducerName = actionType.reduxUpdateSettingsState;
// host actions

export default {
   
    // this action will set host dashboard accessible status
    setChatKittyCurrentUserData: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                actionType: actionType.settings.chatKittyCurrentUserData,
                reducerName,
                dispatch,
                componentRequest,
            };
            reduxUtils.changeStateDirectly(requestOptions);
        }
    },

    setOnsitePlayersCheckIn: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                actionType: actionType.settings.onsitePlayersCheckIn,
                reducerName,
                dispatch,
                componentRequest,
            };
            reduxUtils.changeStateDirectly(requestOptions);
        }
    },

    setTemplateFuncActive: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                actionType: actionType.settings.setTemplateFuncActive,
                reducerName,
                dispatch,
                componentRequest,
            };
            reduxUtils.changeStateDirectly(requestOptions);
        }
    },

    setTemplateFuncArchive: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                actionType: actionType.settings.setTemplateFuncArchive,
                reducerName,
                dispatch,
                componentRequest,
            };
            reduxUtils.changeStateDirectly(requestOptions);
        }
    },

    teamTabTable: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                actionType: actionType.settings.tabTeam,
                reducerName,
                dispatch,
                componentRequest,
            };
            reduxUtils.changeStateDirectly(requestOptions);
        }
    }
}