import { UpOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React, { useEffect, useState } from 'react'
import {Collapse,Button} from 'antd'
import { connect } from 'react-redux'
import _ from 'lodash';

import './style.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
const { Panel } = Collapse;
let URL =`https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://communitiweb.app/communiti/coach-onboard&client_id=ca_J0I6HLfkszlobgVH4CIriHJMhl6Yn7hc`
const FinancesTab =(props)=>{
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageLoading, setPageLoading] = useState(false);
  useEffect(() => {
    if(searchParams.get('code')){
      setPageLoading(true)
    ConnecttoStripe(searchParams.get('code'))
    }
  }, [])

  const ConnecttoStripe =(code) =>{
    props.stripe_onboard_connect({
      body:{code:code},
      callback:(res,data)=>{
        setPageLoading(false)

        if(res==true){
          props.hostProfileData({
            callback:(res,data)=>{
             if(_.get(data,'data.details')){
          history('/communiti/coach-onboard-complete')

             }}})
          
        }
      }
    })
  }
    useEffect(() => {
      }, []);
    const render =()=>{
        return  <div className="coach-full-conatiner">
        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
            <span style={{fontSize:'33px',fontWeight:'500',marginBottom:'13px'}}>Provide your account details</span>
            <div className='full-style-container'>
            <div className='info-container'>
            <span style={{textAlign:'center'}}>Communiti partners with stripe to process payments. Please click the link 
               below to fill out your account details. This will be the account where funds 
               will be deposited.</span></div>
             <div className='info-container'>
             <span style={{fontWeight:'bolder'}}><span style={{color:'#e85f25'}}>Please Note:</span>You will be redirected to our partner's secure website to fill in your banking information. Once you complete this step, you will be brought back to this page. </span></div>
             
             </div>
             <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'flex-end'}}>
             <div>
             <Button className='form-btn-4-back'
           style={{backgroundColor:'#a7a7a7'}}
            // loading={buttonLoading}
            // disabled={buttonLoading}
            
             onClick={()=>props.onChangeTab('2')}
            //  htmlType="submit"
             >Back</Button>
              <Button className='form-btn-4-continue'
           style={{backgroundColor:'#a7a7a7',width:'100%'}}
            // loading={buttonLoading}
            // disabled={buttonLoading}
            
            onClick={()=>   window.location.href=URL}
            //  htmlType="submit"
             >Continue to Secure Stripe Onboarding Website</Button>
             </div>
            {/* <div  
        className="btn-inner-container" onClick={()=>   window.location.href=URL} style={{backgroundColor:"#e85f24",marginLeft:'60%',cursor:'pointer',justifyContent:'center',borderRadius:'23px'}}> <div style={{    padding:"6px 59px",color: "#fff"}}>Complete Stripe Onboarding</div>
        
        </div> */}
</div>
</div>
        

        
    </div>
        
    }

    return render()
}

const mapStateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => ({
  stripe_onboard_connect: details =>
  dispatch(global.redux.action.account.stripeOnboardWeb(details)),
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(FinancesTab)