import React, { useState, useEffect } from 'react';
import { Radio, Table, Dropdown, Space } from 'antd';
import { connect } from "react-redux";
import _ from 'lodash';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import momentTimezone from 'moment-timezone';
import axios from 'axios';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './style.css';

const HostNotification = (props) => {
    let navigate = useNavigate();
    const [selectionType, setSelectionType] = useState('checkbox');
    const [notificationData, setNotificationData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timeZone, setTimeZone] = useState('');
    const [selected, setSelected] = useState(false);
    const [selectedTwo, setSelectedTwo] = useState(false);
    const [selectedThree, setSelectedThree] = useState(false);
    const [getIds, setIds] = useState([]);
    const [notificationMeta, setNotificationMeta] = useState({})

    const toggleButtonOne = () => {
        setSelected(!selected);
      };

      const toggleButtonTwo = () => {
        setSelectedTwo(!selectedTwo);
      };

      const toggleButtonThree = () => {
        setSelectedThree(!selectedThree);
      };

      const buttonStyle = {
        display: 'flex',
        padding: "2px 8px",
        alignItems: 'center',
        borderRadius: '16px',
        background: selected ? "#EF6820" :'var(--Gray-100, #F2F4F7)',
        mixBlendMode: 'multiply',
        color: selected ? '#FFF':'var(--Gray-700, #344054)',
        textAlign: 'center',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        marginLeft: "4px"
      };

      const buttonStyleTwo = {
        display: 'flex',
        padding: "2px 8px",
        alignItems: 'center',
        borderRadius: '16px',
        background: selectedTwo ? "#EF6820" :'var(--Gray-100, #F2F4F7)',
        mixBlendMode: 'multiply',
        color: selectedTwo ? '#FFF':'var(--Gray-700, #344054)',
        textAlign: 'center',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        marginLeft: "4px"
      };

      const buttonStyleThree = {
        display: 'flex',
        padding: "2px 8px",
        alignItems: 'center',
        borderRadius: '16px',
        background: selectedThree ? "#EF6820" :'var(--Gray-100, #F2F4F7)',
        mixBlendMode: 'multiply',
        color: selectedThree ? '#FFF':'var(--Gray-700, #344054)',
        textAlign: 'center',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        marginLeft: "4px"
      };

      const items = [
        {
          label: (<div  style={{opacity: getIds.length === 0 ? '0.5' : '1', cursor: getIds.length === 0 ? 'not-allowed' : 'pointer'}} onClick={() => handleReadNotification()}>Mark as read</div>),
          key: '1',
        },
        {
          label: (<div style={{opacity: getIds.length === 0 ? '0.5' : '1', cursor: getIds.length === 0 ? 'not-allowed' : 'pointer'}} onClick={() => handleDeleteNotification()}>Delete</div>),
          key: '2',
        },
      ];

    const columns = [
        {
          title: <div className='notification_column_txt'>Title</div>,
          dataIndex: 'title',
        },
        {
            title: <div className='notification_column_txt'>Type</div>,
          dataIndex: 'type',
        },
        {
            title: <div className='notification_column_txt'>Description</div>,
          dataIndex: 'description',
        },
        {
        title: <div className='notification_column_txt'>Date and time</div>,
          dataIndex: 'date_time',
        },
      ];

      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          
          let filterOnlyIds = selectedRows.map((item) => item.ids);
          setIds(filterOnlyIds);
        },
      };

      useEffect(() => {
        handleNotificationsList();
        getLocationsData();
      }, []);

      const handleNotificationsList = (pageSize = 25,filter) => {
        setLoading(true);
        // _.isEmpty(filter) && delete filter
        let params = {
            user_type: 1,
            sort: 'created_at.desc',
            limit: pageSize,
            min_date: _.get(filter, 'min_date'),
            max_date: _.get(filter, 'max_date'),
        }
        if(_.isEmpty(filter)){
            delete params.max_date;
            delete params.min_date;
        } else if (selected === true){
            delete params.max_date;
            delete params.min_date;
        } else if (selectedTwo === true){
            delete params.max_date;
            delete params.min_date;
        } else if (selectedThree === true) {
            delete params.max_date;
            delete params.min_date;
        }
        props.notificationsListApi({
            params:params,
            callback: (res, value) => {
                setLoading(false);
                if(res){
                  setNotificationMeta(_.get(value, 'data.meta', {}));
                  if(_.size(_.get(value, 'data.items')) > 0 ){
                    let a = [];
                    _.get(value, 'data.items').map((val, index) => {
                        a.push({
                            key: index,
                            title: <div onClick={() => viewExperience(val)} className='notification_table_datasource_txt'>{_.get(val, 'label')}</div>,
                            type: <div className='notification_reg_type'><svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="4.6665" cy="4" r="3" fill="#12B76A"/>
                              </svg> {_.startCase(_.get(val, 'type'))}</div>,
                            description: <div className='notification_table_datasource_txt'>{_.get(val, 'description')}</div>,
                            date_time: <div className='notification_table_datasource_txt'>{moment(_.get(val, 'created_at')).format('MMM D, YYYY [at] h:mm A')}</div>,
                            status:_.get(val,'status'),
                            ids: _.get(val, 'id')
                        })
                    });
                    setNotificationData(a);
                }
                }
                
                
            } 
        })
      };

      const getLocationsData =async()=>{
        const { data, status } = await axios.get('https://ipapi.co/json')
        // setLocationData({lat:_.get(data,'latitude'),lng:_.get(data,'longitude')})
      await  setTimeZone(_.get(data,'timezone'))
      
      }


      const filterByDays = (value) => {
        if(value=='30_days'){
            setSelectedTwo(false);
            setSelectedThree(false);
        } else if (value=='60_days'){
            setSelected(false);
            setSelectedThree(false);
        } else if (value=='90_days') {
            setSelected(false);
            setSelectedTwo(false);
        }
        var time = moment("12:00 am", ["hh:mm a"]).format("HH:mm");
        var time1 = moment().format("HH:mm");
        let validate_date = value=='30_days'?1:value=='60_days'?2:value=='90_days'?3:1
        const a = momentTimezone().subtract(validate_date,'months').tz(timeZone).format("YYYY-MM-DD");
        const b = momentTimezone().tz(timeZone).format("YYYY-MM-DD")
        let min_date = momentTimezone(`${a}T${time}`).tz(timeZone).toISOString()
        let max_date = momentTimezone(`${b}T${time1}`).tz(timeZone).toISOString()
      if(value === '30_days'){
            handleNotificationsList(25,{ min_date: min_date,
            max_date: max_date,
            })
        
      };

      if(value === '60_days'){
            handleNotificationsList(25,{
            min_date: min_date,
          max_date: max_date,
          });
      };
      
      if(value === '90_days') {
            handleNotificationsList(25,{
            min_date: min_date,
          max_date: max_date,
          });
      };

      if(value === 'all') {
            handleNotificationsList(25,{
          sort: 'created_at.desc',
          user_type: 1,
          });
      };
    };

    const handleReadNotification = () => {
        props.notificationsReadApi({
          body: {
            user_type: 1,
            ids: getIds
          },
          callback: (res, data) => {
            
            if(_.get(data, 'success')){
                handleNotificationsList();
            }
          }
        })
    }

    const handleDeleteNotification = () => {
        props.notificationsDeleteApi({
          body: {
            user_type: 1,
            ids: getIds
          },
          callback: (res, data) => {
            
            if(_.get(data, 'success')){
                handleNotificationsList();
            }
          }
        })
    };

    const viewExperience = (value) => {
      props.notificationsReadApi({
        body:{
          user_type: 1,
          ids: [_.get(value, 'id')]
        },
        callback: (res, data) => {
          props.viewOrder({
            params:{
              id: _.get(value, 'type_id'),
              expand:'experience,experience_template,experience,experience_template_photos,guest'
            },
            callback: (res, value) => {
              if(_.get(value, 'data.details.experience.id')){
                navigate(`/edit-experience?id=${_.get(value, 'data.details.experience.id')}&menu=manage&type=open&from=notifications`);
              }
            }
          })
        }
      })
        
    };

    const handleScroll = (data, type) => {
      if (loading == false && data && data.top > 0.9 && _.get(notificationMeta, 'total') > 25) {
        if (_.size(notificationData) < _.get(notificationMeta, 'total')) {
          handleNotificationsList(_.get(notificationMeta, 'limit') + 25)
        }
  
  
      }
  
    }

  return (
    <div>
        <div style={{display: "flex", flexDirection: "row"}}>
        <div className='notification_header_txt'>Notifications</div>
        <Dropdown
    menu={{
      items,
    }}
  >
            <div onClick={(e) => e.preventDefault()}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                
            </div>
            </Dropdown>
            <div style={buttonStyle} onClick={() => {filterByDays('30_days'); toggleButtonOne()}}>Last 30 days</div>
            <div style={buttonStyleTwo} onClick={() => {filterByDays('60_days'); toggleButtonTwo()}} >Last 60 days</div>
            <div style={buttonStyleThree} onClick={() => {filterByDays('90_days'); toggleButtonThree()}} >Last 90 days</div>
        </div>

        <div style={{marginTop: "16px"}}>
            <Scrollbars
            className="hidden-scroll-x"
            onScrollFrame={(e) => handleScroll(e)}
            style={{ height: 'calc(100vh - 300px)' }}
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            >
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        rowClassName={(record, index) => {
            return  _.get(record,'status') == 2 ? 'table-row-light' :  ''}
          }
        columns={columns}
        dataSource={notificationData}
        pagination={false}
        loading={loading}
      />
      </Scrollbars>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
    return {
      notifications_order_list: _.get(state, 'app.notifications.list.data')
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    notificationsListApi: details => dispatch(global.redux.action.notifications.list(details)),
    notificationsDeleteApi: details => dispatch(global.redux.action.notifications.delete(details)),
    notificationsReadApi: details => dispatch(global.redux.action.notifications.read(details)),
    viewFinance: details => dispatch(global.redux.action.notifications.view(details)),   
    viewOrder: details => dispatch(global.redux.action.finances.view(details)),   

  });
  export default connect(mapStateToProps, mapDispatchToProps)(HostNotification)