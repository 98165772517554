import { Button, Card, Divider, Image, Modal, Radio, Select, Space, Table, Tag, Tooltip,
   DatePicker as AntDatePicker, TimePicker, Input, InputNumber, message } from "antd"
import Scrollbars from "react-custom-scrollbars"
import _, { set } from 'lodash';
import { connect } from 'react-redux';
import { FiPlusCircle } from "react-icons/fi";

import {  useState } from "react";
import "react-multi-date-picker/styles/layouts/mobile.css"

import './style.css'
import { FiSearch } from "react-icons/fi";


const TemplateList = (props) => {
  const [pageLoading, setPageLoading] = useState(false)
  const [templateList, setTemplateList] = useState([])
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedTab, setselectedTab] = useState('Active')
 



  const columns = [
    {
      title: 'Template name',
      dataIndex: 'title',
      key: 'title',
      render: (text, item) => {
        return item?.id == 1 ? "" : <a>{text}</a>
      },
    },
    {
      title: 'Template type',
      dataIndex: 'type',
      key: 'type',
      render: (text, item) => {
        return item?.id == 1 ? "" : <a>{text}</a>
      },
    },
    {
      title: 'Quick Actions',
      key: 'action',
     
    },
  ];


  let locale = {
    emptyText: (
     <div style={{marginTop:'32px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <div>
<Image preview={false} style={{height:'128px',width:'172px'}} src={require('../../../assets/img/template/Illustration.png')} />
      </div>
      <div className="empty-template-title" style={{marginTop:'16px',marginBottom:'8px'}}>No templates found</div>
      <div className="empty-template-title-des" style={{width:'45%'}}>Templates serve as a blueprint to quickly schedule sessions . Get started by clicking the button below and create your first template</div>
      <div>
      <Button
      
      
      styles={{ icon: { marginRight: '-8px' } }} onClick={() => { props?.onClickButton() }}
             icon={<FiPlusCircle style={{ height: '24px', width: '24px' }}
            />} style={{
              //    paddingTop: '6px', paddingBottom: '7px',
              height: '44px'
              ,marginTop:'32px',
              backgroundColor: '#EF6820' , color:  '#fff' , fontWeight: 600,
            }}>Create a new template</Button>
      </div>
     </div>
    )
  };
  return <div style={{ width: '100%' }}>
    {contextHolder}
    <Card
      size="small"
      title={
        <div>
        <div className='header-class-card' style={{ display: 'flex',justifyContent:'space-between' }}>
<div>
              <Radio.Group defaultValue="Active" buttonStyle="solid"
                // style={{paddingTop:'10px',paddingBottom:'10px'}}

                onChange={(e) => {
                  setselectedTab(e.target.value)
                  
                }}
              >
                <Radio.Button style={{
                  paddingTop: '6px', paddingBottom: '7px',
                  height: '44px'
                }} value="Active" >Active</Radio.Button>
                <Radio.Button style={{
                  paddingTop: '6px', paddingBottom: '7px',
                  height: '44px'
                }} value="Archived">Archived</Radio.Button>
                
              </Radio.Group>
              </div>
              <div style={{marginRight:'12px',width:'42%'}}>
                <Input  placeholder="Search" prefix={<FiSearch size={20} color='#667085' />} style={{ paddingTop: '10px', paddingBottom: '10px', paddingLeft: '14px', paddingRight: '14px' }} />
              </div>



        </div>
        <Divider style={{margin:'0px'}}/>
        <div className='header-class-card' style={{ display: 'flex',justifyContent:'space-between' }}>
        <Button styles={{ icon: { marginRight: '-8px' } }}
            
            onClick={() => { props?.onClickButton() }}
            
            icon={<FiPlusCircle style={{ height: '24px', width: '24px' }}
            />} style={{
              //    paddingTop: '6px', paddingBottom: '7px',
              height: '44px',
              backgroundColor: '#EF6820' , color:  '#fff' , fontWeight: 600,
            }}>Create a new template</Button></div></div>
      }
      //       extra={<a href="#">More</a>}
      style={{
        width: '100%',
        padding: '0px'
      }}
      className='manage-active-table'
    >
      <div className="coach-full-conatiner coach-full-conatiner-active">

        <Scrollbars style={{ height: 590 }} className="hidden-scroll-x"
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
        >
          <div style={{ backgroundColor: '#fff' }} className='manage-active-table'>
            <Table locale={locale} className="bulk-sechdule-table" rowClassName={(id, key) => {
              return key == 0 && 'first-row-class'
            }}  columns={columns} dataSource={[...templateList]} loading={pageLoading} pagination={false} />

          </div>
        </Scrollbars>









      </div></Card>
  </div>
}

const mapStateToProps = state => {

  return {
    checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
    isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
    islogindata: _.get(state, 'app.account.isLogin'),
    userProfile: _.get(state, 'app.host.profile.data.details'),

  }
}

const mapDispatchToProps = dispatch => ({
  hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
  templateList: details => dispatch(global.redux.action.template.list(details)),
  list: details => dispatch(global.redux.action.experience.list(details)),
  experienceViewAPI: details => dispatch(global.redux.action.experience.view(details)),
  updateExperienceTag: details => dispatch(global.redux.action.experience.updateExperienceTag(details)),
  experiencesRequestCancel: details => dispatch(global.redux.action.experience.requestCancel(details)),
  changeStatusOfExperience: details => dispatch(global.redux.action.experience.changeStatus(details)),
  orderList: details => dispatch(global.redux.action.experience.orderList(details)),
  sendEmailAPI: details => dispatch(global.redux.action.sendEmail.postEmails(details)),
  updateHostTag: details => dispatch(global.redux.action.host.updateHostTag(details)),
  hostProfile: details => dispatch(global.redux.action.host.profile(details)),
  taxList: details => dispatch(global.redux.action.tax.list(details)),
  create: details => dispatch(global.redux.action.experience.create(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateList)