import { Box } from "@mui/material";
import RightSideHeader from "./right-side-header";
import RightFooter from "./right-footer";



const RightContainer =(props)=>{

return <Box sx={{height:'100%'}}>
      <RightSideHeader />
       <Box sx={{height:'80%', display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>

       <Box component={"span"} sx={{
              width:{
                     xs: "95%", // mobile
                     sm: "87%", // tablet
                     md: "75%", // small desktop
                     lg: "50%",
                   },
           color: "var(--Gray-900, #101828)",
           fontSize: {
              xs: "20px", // mobile
              sm: "19px", // tablet
              md: "23px", // small desktop
              lg: "30px",
            },
           fontWeight: "600",
           lineHeight: {
              xs: "28px", // mobile
              sm: "30px", // tablet
              md: "33px", // small desktop
              lg: "38px",
            },
            textAlign:"left"
       }}>
       Welcome to Communiti
 </Box>
 <Box component={"span"} 
 sx={{
       textAlign:'left',
       width:{
              xs: "95%", // mobile
              sm: "85%", // tablet
              md: "70%", // small desktop
              lg: "50%",
            },
       marginTop:'12px',
       marginBottom:"12px",
       color: "var(--Gray-600, #475467)",
       fontSize: {
          xs: "14px", // mobile
          sm: "15px", // tablet
          md: "16px", // small desktop
          lg: "16px",
        },
       fontWeight: "400",
      
   }}
 >
 Communiti helps you manage your sports business with ease. Let’s get started by verifying some information about you.
 </Box>
 <Box 
 sx={{
       textAlign:'left',
       width:{
              xs: "95%", // mobile
              sm: "85%", // tablet
              md: "70%", // small desktop
              lg: "50%",
            },
            padding: "4px 10px 4px 4px", 
       color: "#344054",
       fontSize:"14px",
       fontWeight: "500",
      background:"var(--Gray-50, #F9FAFB)",
      borderRadius:'8px'
   }}
 component={"span"}>
 This should take about 10-15 mins to complete   
 </Box>
 <Box component={"span"}
 onClick={()=>{
       props?.onChangeFlow(2)
 }}
 sx={{
       width:{
              xs: "95%", // mobile
              sm: "85%", // tablet
              md: "70%", // small desktop
              lg: "50%",
            },
       marginTop:'32px',
              borderRadius:'8px',
              color:"#FFF",
              cursor:'pointer',
              fontSize:'16px',
              fontWeight:'600',
              padding:'10px 18px',
              border:"1px solid var(--Orange-500, #EF6820)",
              background:"var(--Orange-500, #EF6820)",
              boxShadow:"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"

       }} >Get started</Box>
       </Box>
 <RightFooter />
</Box>

}

export default RightContainer;