import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

const { Box, Typography, useTheme, useMediaQuery } = require('@mui/material')


const RightSideHeader =(props)=>{
  let history = useNavigate();
  const logout = () => {
    props.logout({
        callback: (response, data) => {
            // googleLogout();
            history('/communiti/login')
            window.location.reload(false);
        }
    })
}
       const theme = useTheme();
       const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
       return isMobile? <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'center',
              paddingTop: {
                xs: "16px", // mobile
                sm: "22px", // tablet
                md: "28px", // small desktop
                lg: "32px", // large desktop
              },
              paddingLeft: {
                xs: "8px", // mobile
                sm: "32px", // tablet
                md: "64px", // small desktop
                lg: "80px", // large desktop
              },
              paddingRight: {
                     xs: "8px", // mobile
                sm: "32px", // tablet
                md: "64px", // small desktop
                lg: "80px", // large desktop
              },
              width:'100%'
            }}>
              <Box component={"img"}  sx={{
                      width: "142px",
                      height: "32px",
                      display:'flex',
              alignItems:'flex-start',
              justifyContent:'flex-start'
                    }}
                    src={require('../../../assets/img/club/header-logo1.png')}
                    alt="sliders" />
                    <Box sx={{cursor:"pointer"}} component={"div"} onClick={()=>logout()}>
                      <Typography sx={{color:"#EF6820",fontSize:"16px",fontWeight:"600"}}>Sign out</Typography>
                    </Box>
            </Box>:null
}
const mapStateToProps = state => {
  return {
       
   }
}

const mapDispatchToProps = dispatch => ({
  
   logout: details => dispatch(global.redux.action.account.logout(details)),
  

});

export default connect(mapStateToProps, mapDispatchToProps)(RightSideHeader)
