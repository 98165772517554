import React from 'react';
import { Skeleton } from 'antd';


const Loader = () => {
  return (
    <div>
        <div className='container'>
            <Skeleton active paragraph={{
                rows: 3
              }}/>
          </div>
    </div>
  )
}

export default Loader