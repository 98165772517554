import {  TextField, Typography, Container, Box, InputAdornment, styled, makeStyles } from '@mui/material';
import { Formik, Field, ErrorMessage, useFormikContext } from 'formik';
import { Form, Input,Button, Select } from 'antd';
import * as Yup from 'yup';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import _ from 'lodash';

import "../style.css";
import AddressForm from './address-form'
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import RightSideHeader from './right-side-header';
import RightFooter from './right-footer';
const validationSchema = Yup.object().shape({
 business_type: Yup.string().required('Please select an option'),
 display_name: Yup.string().required('Please enter a display name'),
 category: Yup.string().required('Please select an option'),
    });

    const { Option } = Select;
 
const ClubCoachInfo =(props)=>{
  
 const [form] = Form.useForm();
      const [userDetails, setuserDetails] = useState({});
      const formikProps = useFormikContext()
      const [initialValues, setInitialValues] = useState({
             business_type: '',
             display_name: '',
             category: '',
           })

       const [clubCoachInfoForms, setClubCoachInfoForms] = useState(1);
       const [businessFormData, setBusinessFormData] = useState({});
       const [categoriesList,setCategoriesList] =useState([]);
       const [dataFromChild, setDataFromChild] = useState();

      useEffect(() => {
             
             let conditionType = _.includes(_.get(props,'islogindata.types'),1)==true?'hostProfile':'guestProfile';
             if(_.get(props,`${conditionType}.id`)){
                    setuserDetails(_.get(props,`${conditionType}`))
                    setInitialValues({
                           ...initialValues
                    })
             };

             getCategoriesList();
            
      }, []);                                                       
   

      const CustomInput = ({ field, form, ...props }) => (
             <Form.Item
               validateStatus={form.touched[field.name] && form.errors[field.name] ? 'error' : ''}
               help={form.touched[field.name] && form.errors[field.name] ? form.errors[field.name] : ''}
             >
               <Input {...field} {...props} />
             </Form.Item>
           );
          
     
        
           const handleSubmit = (values) => {
             // props?.onChangeFlow(3)
             
             setBusinessFormData(values)
             setClubCoachInfoForms(2)
           };

           const getCategoriesList  =()=>{
             props.list({
               params: {
                 limit: 1000
               },
               callback:(res,data)=>{
                 if(_.get(data,'data.items')){
                   setCategoriesList(_.get(data,'data.items'))
                 }
               }
             })
           }

          

   const customFieldLabel =(text)=>{
return <Box sx={{display:'flex',alignItems:'flex-start'}}><Typography
sx={{color:"#344054",fontSize:'14px',fontWeight:"500"}}
>{text}</Typography><Typography sx={{color:"#344054",fontSize:'14px',fontWeight:"500"}}>*</Typography></Box>
   }  
  
   const customErrorText =(text)=>{
return <Box component={"span"} sx={{display:'flex',alignItems:'flex-start',marginTop:'6px'}}><Typography
sx={{color:"#F04438",fontSize:'14px',fontWeight:"400"}}
>{text}</Typography></Box>
   }

   const customSuffixIcon =()=>{
      return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_12730_906)">
        <path d="M7.99992 5.33301V7.99967M7.99992 10.6663H8.00659M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z" stroke="#F04438" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_12730_906">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
   };

   const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  
return <Box sx={{width:'100%'}}  >
 <RightSideHeader />
 <Box sx={{width:"100%", display:'flex',justifyContent:'center'}}>
      <Box sx={{width:{
             xs: "90%", // mobile
             sm: "80%", // tablet
             md: "70%", // small desktop
             lg: "65%",
      }}}>
 
<Box mt={5} textAlign="center">
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FDEAD7"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF6EE" stroke-width="8"/>
<path d="M30 18.2695V22.4001C30 22.9601 30 23.2401 30.109 23.4541C30.2049 23.6422 30.3578 23.7952 30.546 23.8911C30.7599 24.0001 31.0399 24.0001 31.6 24.0001H35.7305M32 29H24M32 33H24M26 25H24M30 18H24.8C23.1198 18 22.2798 18 21.638 18.327C21.0735 18.6146 20.6146 19.0735 20.327 19.638C20 20.2798 20 21.1198 20 22.8V33.2C20 34.8802 20 35.7202 20.327 36.362C20.6146 36.9265 21.0735 37.3854 21.638 37.673C22.2798 38 23.1198 38 24.8 38H31.2C32.8802 38 33.7202 38 34.362 37.673C34.9265 37.3854 35.3854 36.9265 35.673 36.362C36 35.7202 36 34.8802 36 33.2V24L30 18Z" stroke="#EF6820" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</Box>
<Typography component={"div"} sx={{
      color:"#101828",
      marginTop:'32px',
      fontSize: {
             xs: "17px", // mobile
             sm: "19px", // tablet
             md: "23px", // small desktop
             lg: "30px",
           },
           fontWeight: "600",
           lineHeight: {
              xs: "28px", // mobile
              sm: "30px", // tablet
              md: "33px", // small desktop
              lg: "38px",
            },
}} align="center" >
 Club/Coach information
</Typography>
<Typography component={"div"} align="center"
sx={{
      marginTop:'12px',
      color:"#475467",
      fontSize: {
             xs: "14px", // mobile
             sm: "15px", // tablet
             md: "16px", // small desktop
             lg: "16px",
           },
          
}}
>
Tell us about your business
</Typography>
{ dataFromChild !=3 && clubCoachInfoForms === 1 && <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
 <div className='club-coach-info-progress-dots'/>
 <div style={{marginLeft: "12px"}} className='club-coach-info-next-dots'/>
 <div style={{marginLeft: "12px"}} className='club-coach-info-next-dots'/>
</div>}

{ dataFromChild !=3 && clubCoachInfoForms === 2 && <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
 
 <div  className='club-coach-info-next-dots'/>
 <div style={{marginLeft: "12px"}} className='club-coach-info-progress-dots'/>
 <div style={{marginLeft: "12px"}} className='club-coach-info-next-dots'/>
</div>}

{ dataFromChild && dataFromChild === 3 && <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
 
 <div  className='club-coach-info-next-dots'/>
 <div style={{marginLeft: "12px"}} className='club-coach-info-next-dots'/>
 <div style={{marginLeft: "12px"}} className='club-coach-info-progress-dots'/>
</div>}
<Box sx={{width:"100%",marginTop:'32px'}}>
{ clubCoachInfoForms === 1 && <Formik
     initialValues={initialValues}
     validationSchema={validationSchema}
     onSubmit={handleSubmit}
     enableReinitialize={true}
   >
     {({ setFieldValue,handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
       <Form form={form} className='personal-info-form' layout='vertical' onFinish={handleSubmit}>
         { <>
           <Form.Item
           style={{ marginBottom: touched.business_type && errors.business_type ? "40px" : '20px' }}
           help={touched.business_type && errors.business_type ? customErrorText(errors.business_type) : ''}
           label={customFieldLabel("Are you a club or individual coach?")}
           validateStatus={touched.business_type && errors.business_type ? 'error' : ''}
           id='business_type'
           key={"business_type"}
           name='business_type'
         >

       <Select
       placeholder="Select an option"
       name='business_type'
       id='business_type'
       key={'business_type'}
     style={{
       width: '100%',
       height: "44px",
       textAlign:'left',
       boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
     }}
     onChange={(value) => setFieldValue('business_type', value)}
     onBlur={handleBlur}
     onFocus={handleBlur}
     options={[
       {
         value: 'club',
         label: 'Club',
       },
       {
         value: 'coach',
         label: 'Coach',
       }
     ]}
     rootClassName='common-placeholder-container'
   />

{/* <select style={{width: "100%", height: "44px"}} name='business_type' id='business_type' onChange={handleChange}>
 <option value="club">Club</option>
 <option value="coach">Coach</option>
</select> */}
         </Form.Item>
        

         <Form.Item
           label={customFieldLabel("Display name")}
           validateStatus={touched.display_name && errors.display_name ? 'error' : ''}
           style={{ marginBottom: touched.display_name && errors.display_name ? "40px" : '20px' }}
           help={ touched.display_name && errors.display_name ? customErrorText(errors.display_name) : <Box sx={{marginBottom:'16px'}}><Typography component={"div"} align="left"
           sx={{
                 marginTop:'6px',
                 color:"#475467",
                 fontSize: {
                        xs: "15px", // mobile
                        sm: "15px", // tablet
                        md: "14px", // small desktop
                        lg: "14px",
                      },
                     
           }}
           >
           This is the name that will be displayed on the platform
           </Typography> </Box>}
           id='display_name'
           key={"display_name"}
           name='display_name'
         >
           <Input
         
             suffix={touched.display_name && errors.display_name ? customSuffixIcon() :  <span />}
             name="display_name"
             placeholder="Toronto Sporting Club"
             onChange={handleChange}
             onBlur={handleBlur}
             onFocus={handleBlur}
             value={values.display_name}
             style={{
               height: '44px', borderRadius: '8px',
               borderColor: touched.display_name && errors.display_name ? '#f5222d' : '#D0D5DD',
               boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
             }}
             rootClassName='common-placeholder-container'
           />
           
         </Form.Item>
         <Form.Item
           label={customFieldLabel("Select the sport that you will be listing")}
           validateStatus={touched.category && errors.category ? 'error' : ''}
           style={{ marginBottom: touched.category && errors.category ? "40px" : '20px' }}
           help={touched.category && errors.category ? customErrorText(errors.category) : ''}
           name='category'
         >

<Select
placeholder="Select an option"
name='category'
id='category'
key={'category'}
style={{
 height: "44px",
 textAlign:'left',
 boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
}}
   optionFilterProp="children"
   onChange={(value) => setFieldValue('category', value)}
   rootClassName='common-placeholder-container'
   >
  {_.map(categoriesList,(v,i)=>{
    return  <Option key={i} value={_.get(v,'id')}>{_.startCase(_.get(v,'name'))}</Option>
  })}
 </Select>

         </Form.Item>
         <Form.Item>
           <Button
           // onClick={() => handleProceedNext()}
           type="primary" htmlType="submit"
           style={{
             width: '100%', marginTop: '4px', height: '44px', background: "#EF6820", color: "#FFF",
             fontSize: '16px',
             fontWeight: "600"
           }}>
             Continue
           </Button>
         </Form.Item>
         </>
     }

     {/* 2nd form */}

     {/* {
       clubCoachInfoForms === 2 && <>
      
     </>
     } */}
       </Form>
     )}
   </Formik>}

   {
     clubCoachInfoForms === 2 && <AddressForm onChangeFlow={props?.onChangeFlow} personalInfo={props?.personalInfo} businessFormData={businessFormData} sendDataToParent={handleDataFromChild} />
   }
</Box>
</Box>  
</Box>
<RightFooter />
</Box>

}


const mapStateToProps = state => {
      
      return {
         checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
         isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
         islogindata: _.get(state, 'app.account.isLogin'),
         hostProfile: _.get(state, 'app.host.profile.data.details'),
         guestProfile: _.get(state, 'app.guest.profile.data.details')
   
      }
    }
   
    const mapDispatchToProps = dispatch => ({
      isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
      hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
      guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
      list: details => dispatch(global.redux.action.categories.list(details)),
      hostUpdateProfile: details => dispatch(global.redux.action.host.update(details)),
      guestUpdateProfile: details => dispatch(global.redux.action.guest.update(details)),
      updatePhone: details => dispatch(global.redux.action.guest.updatePhone(details)),
      request: details => dispatch(global.redux.action.host.request(details)),
      hostCreate: details => dispatch(global.redux.action.host.hostCreate(details)),
      verifyInviteCode: details => dispatch(global.redux.action.host.verifyInviteCode(details)),
      taxList: details => dispatch(global.redux.action.tax.list(details)),
   
   
    
    });
    export default connect(mapStateToProps, mapDispatchToProps)(ClubCoachInfo)

