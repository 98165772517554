import { EditFilled,DeleteFilled, EditOutlined, FileImageOutlined, LoadingOutlined, PlusOutlined, UpOutlined, PlusSquareFilled, ArrowLeftOutlined, SaveFilled, ClockCircleFilled, CalendarFilled, MailFilled, ArrowRightOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input/Input'
import React,{useState,useEffect} from 'react'
import {Button, Collapse, Form, Select,Spin,Upload,notification, InputNumber,Modal, Table, Image, Checkbox, Tag} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { geocodeByAddress } from 'react-places-autocomplete';
import _, { set } from 'lodash';
import { connect } from 'react-redux'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ViewRegisteredInfo from '../view-registered-screen'
import '../../onboarding-screen/style.css'
import '../style.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import  ExportJsonCsv  from '../../export-csv/intex';
import {AiOutlineMessage} from "react-icons/ai";
import ReactQuill from 'react-quill';

import chatKittyInstance from '../../../core_units/chat-system/chatkitty/instance';
// import { parseFloat } from 'core-js/core/number'

const { Panel } = Collapse;
const {confirm} =Modal
const { Option } = Select;

const TemplateList =(props)=>{
  
    const [form] = Form.useForm();
    let history = useNavigate();
    const [AllTemplateListData, setAllTemplateListData] = useState([])
    const [AllTemplateListMeta, setAllTemplateListMeta] = useState({})
    const [pageLoading, setPageLoading] = useState(false);
    const [completedpageLoading, setcompletedPageLoading] = useState(false)
    const [page, setpage] = useState(1)
    const [usersList, setUsersList] = useState([])
    const [completedusersList, setcompletedUsersList] = useState([])
    const [UsersListMeta, setUsersListMeta] = useState({})
    const [scheduleModal, setScheduleModal] = useState(true)
    const [scheduleSelectId, setScheduleSelectId] = useState('')
    const [refundModal, setRefundModal] = useState(false)
const [showPreviewRefundModal, setShowPreviewRefundModal] = useState(false)
const [viewModal, setViewModal] = useState(false)
const [completedviewModal, setcompletedViewModal] = useState(false)
const [removeAllowLate, setRemoveAllowLate] = useState('1')
const [amountType, setAmountType] = useState('1')
const [refundAmount, setRefundAmount] = useState('')
const [UsersDetails, setUsersDetails] = useState({});
const [regn_details, set_regn_details] = useState([]);
const [regn_person, set_regn_person] = useState({})
const [completedUsersDetails, setcompletedUsersDetails] = useState({})
const [singleOrderDetails, setSingleOrderDetails] = useState({});
const [completedsingleOrderDetails, setcompletedSingleOrderDetails] = useState({});

const [isModalOpen, setIsModalOpen] = useState(false);
const [getPurchaseDetails, setPurchaseDetails] = useState({});

const [isNewRefundModal, setIsNewRefundModal] = useState(false);
const [confirmRefundIsModalOpen, setConfirmRefundIsModalOpen] = useState(false);
const [successRefundIsModalOpen, setSuccessRefundIsModalOpen] = useState(false);
const [confirmCancelRefunfIsModalOpen, setConfirmCancelRefunfIsModalOpen] = useState(false);
const [successCancelIsModalOpen, setSuccessCancelIsModalOpen] = useState(false);
const [getUserTranscationTable, setUserTranscationTable] = useState([]);
const [getNewModalTitleName, setNewModalTitleName] = useState({})
const [getRefundableAmount, setRefundableAmount] = useState()
const[cancelAndRefund, setCancelAndRefund] = useState(false)
const[getComputedRefundData, setComputedRefundData] = useState({});
const[amountValue, setValue] = useState('')
const [getDataArray, setDataArray] = useState([])
const [searchParams, setSearchParams] = useSearchParams();
const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [getSelectOptions, setSelectOptions] = useState([]);
    const [getExpId, setExpId] = useState();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("")

const labels = getDataArray.map((item) =>
item.registration_info.map((info) => {
  // Wrap the value in double quotes if it contains commas
  if (info.label.includes(',')) {
    return `"${info.label}"`;
  }
  return info.label;
})
);
// const clauses = getDataArray.map((item) => item.clauses.map((clause) => clause.name));
const clauses = getDataArray.map((item) =>
item.clauses.map((info) => {
  // Wrap the value in double quotes if it contains commas
  if (info.name.includes(',')) {
    return `"${info.name}", Accepted Time`;
  }
  return `${info.name}, Accepted Time`;
})
);

// 
function convertToCSV(data, labels, clauses) {
  const header = [
    "Order ID",
    "Template ID",
    "Program Title",
    "Program Start Date",
    "Program Start Time",
    "Program End Time",
    "Order Status",
    "Refunded",
    "Price Club",
    "Communiti Fee",
    "Communiti Tax",
    "Tax",
    "Discount",
    "Total Paid",
    'Registration First Name', 
    'Registration Last Name', 
    "Registrant Date of Birth",
    "Registrant Gender",
    "Contact First Name",
    "Contact Last Name",
    "Contact Email",
    "Contact Address",
    ...labels[0].flat(),
    ...clauses[0].flat(),
  ].join(',');

  const csvData = data.map((item) => {

    const answers = item.registration_info.map((info, index) => {
      // Remove newline characters and wrap the value in double quotes if it contains commas
      const sanitizedAnswer = info.answer.replace(/\n/g, ' ');
      if (sanitizedAnswer.includes(',')) {
        return `"${sanitizedAnswer}"`;
      }
      return `"${sanitizedAnswer}"`; // Wrap sanitized answer in double quotes
    });
    return[
      item.id,
    _.get(item, 'experience.template.id'),
    _.get(item,'experience.template.title'),
    moment(_.get(item, 'experience.experience_date')),
    moment.tz(_.get(item,'experience.experience_date'), !_.isEmpty(_.get(item,'experience.meta_details.timezone')) ? _.get(item,'experience.meta_details.timezone') : "America/Toronto").format("hh:mm A"),
    moment.tz(_.get(item,'experience.experience_end_date'), !_.isEmpty(_.get(item,'experience.meta_details.timezone')) ? _.get(item,'experience.meta_details.timezone') : "America/Toronto").format("hh:mm A"),
    _.get(item,'paid') === 1 ? "PURCHASED": '-',
    _.get(item,'refund') === 0 ? "NO": 'YES',
    _.get(item,'experience.template.price'),
    _.get(item,'base_platform_amount'),
    _.get(item,'base_platform_tax_amount'),
    _.get(item,'tax_amount'),
    _.get(item,'discount_amount'),
    _.get(item,'total'),
    item.profile.first_name,
    item.profile.last_name,
    !_.isEmpty(_.get(item, 'profile.date_of_birth')) ? _.get(item, 'profile.date_of_birth') : "-",
    _.get(item, 'profile.gender') == "male" || _.get(item, 'profile.gender') == "female" ? _.get(item, 'profile.gender') :`Self Identified (${_.get(item, 'profile.gender')})`,
   _.get(item, 'guest.first_name'),
   _.get(item, 'guest.last_name'),
   _.get(item, 'guest.contact_details.email'),
   !_.isEmpty(_.get(item, 'guest.address')) ? _.get(item, 'guest.address').replace(/['",]/g, '') : "-",
    ...answers,
    ...item.clauses.map((clause) => clause.description ? `${clause.description.replace(/['",]/g, '')},${moment(_.get(item, 'created_at')).format('MMMM Do YYYY h:mm A')}` : `${clause.link},${moment(_.get(item, 'created_at')).format('MMMM Do YYYY h:mm A')}`),
  //  !_.isEmpty(_.get(item, 'clauses')) ? moment(_.get(item, 'created_at')).format('MMMM Do YYYY h:mm A') : "",
  ]
});

  const rows = csvData.map((data) => data.join(','));

  return [header, ...rows].join('\n');
};

function downloadCSV() {
  const csvContent = convertToCSV(getDataArray, labels, clauses);
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = 'data.csv';

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
}

let column = [
    {
title:'First Name',
key:'first_name',
dataIndex:'first_name',},
{
title:'Last Name',
    key:'last_name',
    dataIndex:'last_name',
},  
{
 title:'Date Of Birth',
 width:'18%',
key:'date_of_birth',
dataIndex:'date_of_birth',
},
{
    title:'Status',
   key:'status',
   dataIndex:'status',
   },
{
    title:'Action',
    width:'60%',
    key:'action',
    dataIndex:'action',
    render: (text, item) => {
      
      return(
        <div className="agent-list-action-btns" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <div style={{display:"flex", flexDirection: "row"}}><div   style={{alignItems:'center',marginRight:'20px',cursor:'pointer'}} onClick={() => messageToHost(item)}><AiOutlineMessage style={{marginRight:'12px'}} /><span>Message</span></div> <div onClick={() => showEmailModal(_.get(item, 'order_details'))}><MailFilled style={{marginRight: "12px", cursor:"pointer"}}/>Email</div></div>
            <div aria-disabled={true} onClick={()=>{newRefundShowModal(item); setRefundAmount(_.get(item,'price_amount'));setSingleOrderDetails(_.get(item,'order_details'))}}  style={{alignItems:'center',marginRight:'20px', pointerEvents: _.get(item, 'action_status') === 4 ? "none":""}}><DeleteFilled color='#a7a7a7' style={{marginRight:'12px'}} /><span>Remove / Refund</span></div>
            <div onClick={async()=>{await setUsersDetails({..._.get(item,'order_details.guest'),relations:_.get(item,'relations')});setSingleOrderDetails(_.get(item,'order_details'));setViewModal(true); set_regn_details(_.get(item, 'order_details.registration_info')); set_regn_person(item, 'regestration_person')}}><EyeOutlined style={{marginRight:"7px"}}/><span><span style={{cursor:'pointer'}}>View</span></span></div>
            {_.get(item, 'order_details.experience.template.type') == 'open' ? <div onClick={() => showModal(item)} style={{marginLeft: "20px"}}><span><span style={{cursor:'pointer'}}><Image width={20} className='mark-complete-img' src={require('../../../assets/img/tick-mark.png')}/>Mark as Complete</span></span></div>:''}
            
        </div>
      )
    },
    
    },
];

const showEmailModal = (value) => {
  
  setExpId(value)
  // getEmailList(value)
  setIsEmailModalOpen(true);
  form.setFieldsValue({
    emails: _.get(value, 'guest.contact_details.email')
  })
};

const handleEmailOk = () => {
  setExpId()
  setIsEmailModalOpen(false);
  setLoading(true);
  setErrorMessage('')
  form.setFieldsValue({
      title:'',
      message:'',
      emails:''
  })
};

const handleEmailCancel = () => {
  setExpId()
  setIsEmailModalOpen(false);
  setLoading(true);
  setErrorMessage('');
  form.setFieldsValue({
      title:'',
      message:'',
      emails:''
  })
};

let newRefundColumn = [
  {
    title: "Date",
    key: "date",
    dataIndex: "date"
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description"
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount"
  },
];

let newRefundDataSource = [
  {
    key: '1',
    date: 'December 1, 2022',
    description: 'Original Purchase',
    amount: '$100.00'
  },
];

let completedColumn = [
  {
title:'First Name',
key:'first_name',
dataIndex:'first_name',},
{
title:'Last Name',
  key:'last_name',
  dataIndex:'last_name',
},  
{
title:'Date Of Birth',
key:'date_of_birth',
dataIndex:'date_of_birth',
},
// {
//     title:'Status',
//    key:'status',
//    dataIndex:'status',
//    },
{
  title:'Action',
  // width:'60%',
  key:'action',
  dataIndex:'action',
  render: (text, item) => {
    
    return(
      <div className="agent-list-action-btns" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <div   style={{alignItems:'center',marginRight:'20px',cursor:'pointer'}} onClick={() => messageToHost(item)}><MailFilled color='#a7a7a7' style={{marginRight:'12px'}} /><span>Message</span></div>
          {/* <div onClick={()=>{setRefundModal(true); setRefundAmount(_.get(item,'price_amount'));setSingleOrderDetails(_.get(item,'order_details'))}}  style={{alignItems:'center',marginRight:'20px',cursor:_.get(item,'refund')==0? 'pointer':'not-allowed'}}><DeleteFilled color='#a7a7a7' style={{marginRight:'12px'}} /><span>Remove / Refund</span></div> */}
          <div onClick={async()=>{await setcompletedUsersDetails({..._.get(item,'order_details.guest'),relations:_.get(item,'relations')});setcompletedSingleOrderDetails(_.get(item,'order_details'));setcompletedViewModal(true);}}><span><span style={{cursor:'pointer'}}><EyeOutlined style={{marginRight:"7px"}}/>View</span></span></div>
          {/* <div onClick={() => showModal(item)} style={{marginLeft: "20px"}}><span><span style={{cursor:'pointer'}}>Mark as Complete</span></span></div> */}
          
      </div>
    )
  },
  
  },
]


const headers = [
  {
name:'Order ID',
key:'order_id',
},
{
name:'Template ID',
  key:'template_id',
},  
{
name:'Program Title',
key:'program_title',
},
{
name:'Program Start Date',
 key:'program_start_date',
 },
 {
  name: 'Program Start Time',
  key: 'program_start_time'
 },
 {
  name: 'Program End Time',
  key: 'program_end_time'
 },
 {
  name: 'Order Status',
  key: 'order_status'
 },
 {
  name: 'Refunded',
  key: 'order_refunded'
 },
 {
  name: "Price Club",
  key: "price_club"
 },
 {
  name: "Communiti Fee",
  key: "communiti_fee"
 },
 {
  name: "Communiti Tax",
  key: "communiti_tax"
 },
 {
  name: 'Tax',
  key: 'club_tax'
 },
 {
  name: 'Discount',
  key: 'program_discount'
 },
 {
  name: 'Total Paid',
  key: 'program_total_amount'
 },



 {
  name: 'Registration First Name',
  key: 'program_regn_first_name'
 },
 {
  name: 'Registration Last Name',
  key: 'program_regn_last_name'
 },
 {
  name: 'Registrant Date of Birth',
  key: 'program_regn_dob'
 },
 {
  name: 'Registrant Gender',
  key: 'program_regn_gender'
 },
 {
  name: 'Contact First Name',
  key: 'parent_first_name'
 },
 {
  name: 'Contact Last Name',
  key: 'parent_last_name'
 },
 {
  name: 'Contact Email',
  key: 'parent_email'
 },
 {
  name: 'Contact Address',
  key: 'parent_address'
 },
 {
  name: 'Phone Number',
  key: 'regn_phone'
 },
 {
  name: 'Question',
  key: 'program_question'
 },
 {
  name: 'Answer',
  key: 'program_answer'
 },
 {
  name: 'Clause Name',
  key: 'program_clauses_name'
 },
 {
  name: 'Clause Link',
  key: 'program_clauses_link'
 },
 {
  name: 'Clause Description',
  key: 'program_clauses_description'
 },

]


 
useEffect(() => {
if(_.get(props,'exp_id')){
    orderList(_.get(props,'exp_id'))
    completedorderList(_.get(props,'exp_id'))
}
}, []);

const showModal = (value) => {
  setIsModalOpen(true);
  setPurchaseDetails(value)
};

const handleOk = () => {
  props.markAsComplete({
    body:{
        id: _.get(getPurchaseDetails, 'order_details.id'),
        is_completed:true,
        completed_at: moment().toISOString()
    }, 
    callback: (response, data) => {
        if(response){
            notification.success({
                message:"Success",
                description:"Marked as Complete"
            })
            if(_.get(props,'exp_id')){
              orderList(_.get(props,'exp_id'))
              completedorderList(_.get(props,'exp_id'))
            }
           
        }
    }
})
  setIsModalOpen(false);

};

const handleCancel = () => {
  setIsModalOpen(false);
};

const messageToHost = async(value) => {

  

  let guest_chat_id = _.get(value, 'order_details.guest.chatkitty_user_id');

  if(_.isEmpty(guest_chat_id)){
    notification.error({
      message: "The guest has not yet registered for the chat or else could not find the guest chat ID."
    })
  } else {
    notification.success({
      message: "Redirecting to one to one conversation"
    });
    const result = await chatKittyInstance.createChannel({
      type: "DIRECT",
      members: [{ id: guest_chat_id }],
  });
    
  if (result.succeeded) {
      const channel = result.channel; // Handle channel
      
      history('/communiti/chats-notification', {state:{
        data: channel
      }})
  }
    
  }

}

const finalCallback=(response, data)=>{
  // callback(response, data);
  
  if(response){
      
  }
};



const orderList =(id)=>{
  setPageLoading(true)
    props.orderList({
      params:{experience:id,user_type:'1',expand:'guest,experience,experience_template,profile',paid:1, is_completed:0, limit: 300},
      callback:(res,data)=>{
        if(res==true){
          setRegisterList(_.get(data,'data.items',[]))
          setDataArray(_.get(data,'data.items',[]))
          
        }
        setPageLoading(false)
      }
    })
  }

  const completedorderList =(id)=>{
    setcompletedPageLoading(true)
      props.orderList({
        params:{experience:id,user_type:'1',expand:'guest,experience,experience_template,profile',paid:1, is_completed:1, limit: 300},
        callback:(res,data)=>{
          if(res==true){
            completedsetRegisterList(_.get(data,'data.items',[]))
            
          }
          setcompletedPageLoading(false)
        }
      })
    }

  const setRegisterList =(data)=>{
    let a =[]
    _.map(data,(value,index)=>{
      

      a.push({
        key:_.get(value,'id'),
        first_name:_.startCase(_.get(value,'profile.first_name')), //have to give profile here
        last_name:_.startCase(_.get(value,'profile.last_name')), //have to give profile here
        date_of_birth: !_.isEmpty(_.get(value, 'profile.date_of_birth')) ? moment(_.get(value, 'profile.date_of_birth')).format("MMM, DD YYYY") : '-', //have to give profile here
        status:_.get(value, 'status') === 1 ? <span className='table-paid-status'>Active</span> :  _.get(value, 'status') === 4 ? <span className='table-status-cancelled'>Cancelled</span> : '--',
        refund:_.get(value,'refund'),
        details:_.get(value,'total'),
        price_amount:_.get(value,'total'),
        refund_amount:_.get(value,'refund'),
        order_details:value,
        order_id: _.get(value,'id'),
        template_id: _.get(value,'experience.template.id'),
        program_title: _.startCase(_.get(value,'experience.template.title')),
        program_start_date: moment(_.get(value, 'experience.experience_date')).format('MMM DD, YYYY'),
        program_start_time: moment.tz(_.get(value,'experience.experience_date'), !_.isEmpty(_.get(value,'experience.meta_details.timezone')) ? _.get(value,'experience.meta_details.timezone') : "America/Toronto").format("hh:mm A"), // moment.tz(_.get(value,'experience_date'), "America/Toronto").format("hh:mm A")
        program_end_time: moment.tz(_.get(value,'experience.experience_end_date'), !_.isEmpty(_.get(value,'experience.meta_details.timezone')) ? _.get(value,'experience.meta_details.timezone') : "America/Toronto").format("hh:mm A"),
        order_status: _.get(value,'paid') === 1 ? "PURCHASED": '-',
        order_refunded: _.get(value,'refund') === 0 ? "NO": 'YES',
        club_tax: _.get(value,'tax_amount'),
        price_club: _.get(value,'experience.template.price'),
        communiti_fee: _.get(value,'base_platform_amount'),
        communiti_tax: _.get(value,'base_platform_tax_amount'),
        program_discount: _.get(value,'discount_amount'),
        program_total_amount: _.get(value,'total'),
        program_clauses_name:  _.get(value,'clauses').map((val, index) => {return `${index+1}. ${val.name}`}).join("\n"),
        program_clauses_link:  _.get(value,'clauses').map((val, index) => {  return !_.isEmpty(val.link) ? `${index+1}. ${val.link}`: "-"}).join("\n"),
        program_clauses_description:  _.get(value,'clauses').map((val, index) => {return !_.isEmpty(val.description) ? `${index+1}. ${val.description}`: "-"}).join("\n"),
        program_question: _.get(value, 'registration_info').map((val, index) => {return !_.isEmpty(val.label) ? `${index+1}. ${val.label}` : "-"}).join("\n"),
        program_answer: _.get(value, 'registration_info').map((val, index) => {return !_.isEmpty(val.answer) ? `${index+1}. ${val.answer}` : "-"}).join("\n"),
        program_regn_first_name: _.startCase(_.get(value, 'profile.first_name')), //have to give profile here
        program_regn_last_name: _.startCase(_.get(value, 'profile.last_name')), //have to give profile here
        program_regn_dob: !_.isEmpty(_.get(value, 'profile.date_of_birth')) ? _.get(value, 'profile.date_of_birth') : "-", //have to give profile here
        program_regn_gender: _.get(value, 'profile.gender') == "male" || _.get(value, 'profile.gender') == "female" ? _.get(value, 'profile.gender') :`Self Identified (${_.get(value, 'profile.gender')})`, //have to give profile here
        parent_first_name: _.startCase(_.get(value, 'guest.first_name')), //have to give profile here
        parent_last_name: _.startCase(_.get(value, 'guest.last_name')), //have to give profile here
        parent_email: _.get(value, 'guest.contact_details.email'), //have to give profile here
        parent_address: !_.isEmpty(_.get(value, 'guest.address')) ? _.get(value, 'guest.address') : "-",
        regn_phone: "-",
        regestration_person: _.get(value, 'profile'),
        regn_address: !_.isEmpty(_.get(value, 'profile.address')) ? _.get(value, 'profile.address') : '', //have to give profile here
        action_status: _.get(value, 'status'),
        relations:_.size(_.get(value,'relations')) && _.isArray(_.get(value,'relations')) ?_.head(_.get(value,'relations')):{}

      })
    });
    setUsersList(a)
  }

const loadTemplateList=()=>{
    setPageLoading(true)
    props.templateList({
        params:{ sort: 'created_at.desc', limit: 300},
        callback:(res,data)=>{

if(res==true && _.get(data,'data.items[0]')){
setAllTemplateListData(_.get(data,'data.items'))
setAllTemplateListMeta(_.get(data,'data.meta'))
}
setPageLoading(false)
        }
    })
}


const onContinue =()=>{
    if(scheduleSelectId){
history(`/communiti/schedule?id=${scheduleSelectId}`)
    }
}

const refundsubmit =()=>{
  let details ={refund:true,
    refund_amount:refundAmount,
    id:_.get(singleOrderDetails,'id')
  }
  if(removeAllowLate==1){
    details.status=4
    details.cancellation_glossary='Canncelled by host'

  }

  props.refund({
    body:details,
    callback:(res,data)=>{
      
      setShowPreviewRefundModal(false)
    }
  })
}
const openChat =async(id)=>{
  if(id){
      const result = await chatKittyInstance.retrieveChannel(id);
      if (result.succeeded) {
          const channel = result.channel; // Handle channel
          
          history('/communiti/chats-notification', {state:{
            data: channel
          }})
      }
  }
  
}

const completedsetRegisterList =(data)=>{
  let a =[]
  _.map(data,(value,index)=>{
    

a.push({
  key:_.get(value,'id'),
  first_name:_.startCase(_.get(value,'relations[0].first_name')),
  last_name:_.startCase(_.get(value,'relations[0].last_name')),
  date_of_birth:_.get(value,'relations[0].dob')!="Invalid date"?moment(_.get(value,'relations[0].dob')).format('MMMM D, YYYY'):'--',
  status:_.get(value, 'status') === 1 ? <span className='table-paid-status'>Active</span> :  _.get(value, 'status') === 4 ? <span className='table-status-cancelled'>Cancelled</span> : '--',
  refund:_.get(value,'refund'),
  details:_.get(value,'total'),
  price_amount:_.get(value,'total'),
  refund_amount:_.get(value,'refund'),
  order_details:value,
  relations:_.size(_.get(value,'relations')) && _.isArray(_.get(value,'relations')) ?_.head(_.get(value,'relations')):{}

})
  })
  setcompletedUsersList(a)
}

const newRefundShowModal = (value) => {
  
  props.computeRefundAPI({
    body:{
      order: _.get(value, 'order_details.id')
    },
    callback: (res, val) => {
      
      
      if(res){
        setComputedRefundData(val)
      }
    }
  })
  setNewModalTitleName(value)
  setIsNewRefundModal(true);
  props.getOrderTranscationList({
    params:{
      order: _.get(value, 'order_details.id'),
      user_type: 1,
      limit: 300
    },
     callback: (response, data) => {
      
      
      if(response){
        userTransactionTable(_.get(data, 'data.items'))
      }
     }
  })

};

const userTransactionTable = (data) => {
  
let a = [];
let b = [];
_.map(data,(value, index) => {
  
  a.push({
    key: _.get(value, 'id'),
    date: moment(_.get(value, 'created_at')).format("MMMM DD, YYYY"),
    description: _.get(value, 'label'),
    amount: _.get(value, 'amount')
  })
  if(!_.isEmpty(_.get(value, 'refund_payment_objects'))){
    _.map(_.get(value, 'refund_payment_objects'), (val, ind) => {
      b.push({
        key: _.get(val, 'id'),
        date: moment.unix(_.get(val, 'created')).format("MMMM DD, YYYY"),
        description: 'Refunded Amount',
        amount: _.get(val, 'amount')/100
      })
    })
  }
})
if(!_.isEmpty(_.get(data, '[0].refund_payment_objects'))){
  setUserTranscationTable(a.concat(b))
} else{
  setUserTranscationTable(a)
}

}

const proceedRefund = () => {
  setIsNewRefundModal(false);
  props.proceedRefundAPI({

  })
};

const handleRefundCancel = () => {
  setIsNewRefundModal(false);
 
};

const mainRefundCancelModal = () => {
 confirmCancelRefundShow()
}

const onChangeRefundCheck = (e) => {
  setCancelAndRefund(e.target.checked)
  
};

const confirmRefundShowModal = () => {
  setConfirmRefundIsModalOpen(true);
};

const confirmProceedRefund = () => {
  // 
  if(!cancelAndRefund){
    props.proceedRefundAPI({
      body:{
        refund: true,
        id: _.get(getNewModalTitleName, 'order_details.id'),
        refund_amount: getRefundableAmount
      },
      callback: (response, data) => {
        
        
        if(response){
          setConfirmRefundIsModalOpen(false)
          successRefundShowModal();
        }
      }
    })
  } else {
    props.proceedRefundAPI({
      body:{
        refund: true,
        id: _.get(getNewModalTitleName, 'order_details.id'),
        refund_amount: getRefundableAmount,
        status: 4,
        cancellation_glossary: 'refunded and cancelled'
      },
      callback: (response, data) => {
        
        
        if(response){
          successRefundShowModal();
        }
      }
    })
  }
  
  // setConfirmRefundIsModalOpen(false);
};

const hideCancelRefund = () => {
  setConfirmRefundIsModalOpen(false);
};

const successRefundShowModal = () => {
  setSuccessRefundIsModalOpen(true);
};

const successRefundHandleOk = () => {
  setSuccessRefundIsModalOpen(false);
  setIsNewRefundModal(false)
};

const successRefundHandleCancel = () => {
  setSuccessRefundIsModalOpen(false);
};

const confirmCancelRefundShow = () => {
  setConfirmCancelRefunfIsModalOpen(true);
};

const cancelConfirmRefundOk = () => {
  //getNewModalTitleName
  props.proceedRefundAPI({
    body:{
      id: _.get(getNewModalTitleName, 'order_details.id'),
      refund: false,
      status: 4,
      cancellation_glossary: 'cancelled.'
    },
    callback: (res, data) => {
      
      
      showSuccessCancelModal();
    }
  })
  setConfirmCancelRefunfIsModalOpen(false);
  
};

const cancelModalRefund = () => {
  setConfirmCancelRefunfIsModalOpen(false);
};

const showSuccessCancelModal = () => {
  setSuccessCancelIsModalOpen(true);
};

const successCancelOk = () => {
  setSuccessCancelIsModalOpen(false);
};

const handleSuccessCancel = () => {
  setSuccessCancelIsModalOpen(false);
};

const enterRefundAmount = (e, amount) => {
  
  const newValue = e.target.value;
    if (!isNaN(newValue) && newValue <= amount) {
      setValue(newValue);
      setRefundableAmount(newValue)
    };
  
}

const onFinish = (values) => {
  
props.sendEmailAPI({
  body:{
      experience: searchParams.get("exp_id"),
      subject: values.title,
      message: values.message,
      guest_ids: _.get(getExpId, 'guest.id')
  },
  callback: (res, val) => {
      
      if(_.get(val, 'success')){
        notification.success({
          message: "Success",
          description: "Email has been sent."
        })
          setIsEmailModalOpen(false);
          form.setFieldsValue({
              title:'',
              message:'',
              emails:''
          })
      }
  }
})
};
const onFinishFailed = (errorInfo) => {
  
};

    const render =()=>{
      
      
      
      let locale = {
        emptyText: 'You currently have no registrations for this session.',
      };
        return (
        <div className="coach-full-conatiner">
            <ViewRegisteredInfo is_start={_.get(props,'is_start')} extraDetails={singleOrderDetails} regn_det={regn_details} regis_pers={regn_person} usersDetails={UsersDetails}  viewModal={viewModal} onStateChange={(e)=>setViewModal(e)}/>
            <ViewRegisteredInfo is_start={_.get(props,'is_start')} extraDetails={completedsingleOrderDetails} usersDetails={completedUsersDetails}  viewModal={completedviewModal} onStateChange={(e)=>setcompletedViewModal(e)}/>
              <Modal centered={true} maskClosable
                 onCancel={()=>setRefundModal(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={refundModal}>
                  <div style={{    display: 'flex',
    flexDirection: 'column',marginBottom:'4%',
    alignItems: 'center',}}>
                  <span style={{    fontSize: '29px',fontWeight: '700',marginBottom:'2%',}}>Please confirm the amount you wish to refund</span>
                <InputNumber max={parseFloat(_.get(singleOrderDetails,'total'))} disabled={amountType==1?true:false} value={refundAmount} onChange={(e)=>{setRefundAmount(e)}} className='refund-modal-input' placeholder='Enter Amount' />
                  
                  </div>
                  <div style={{ display: 'flex',
    justifyContent: 'center',marginBottom:'2%',
    marginTop: '32px'}}>   
    <Button  onClick={()=>{setAmountType('1'); setRefundAmount(_.get(singleOrderDetails,'total'))}}  className='unsaved-btn-cancel' style={{backgroundColor:amountType=="1"?'#e85f25':"#a7a7a7",marginRight:23,width:'23%'}}
             >Full Amount</Button>
                  {_.get(props,'is_start')==true &&<Button onClick={()=>{setAmountType('2'); 
                  setRefundAmount(parseFloat(_.get(singleOrderDetails,'total')) - parseFloat(_.get(singleOrderDetails,'refund')))}}   className='unsaved-btn' style={{backgroundColor:amountType=="2"?'#e85f25':"#a7a7a7",width:'23% !important'}}
             >Custom</Button>}
             </div>
             <div style={{display:'flex',flexDirection:'column'}}>
                    <span style={{fontWeight:'700',fontSize:'25px'}}>Do you also wish to remove their registration?</span>
                   
                </div>
                <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
    <Button onClick={()=>setRemoveAllowLate('1')}  className='unsaved-btn-cancel' style={{backgroundColor: removeAllowLate=="1"?'#e85f25':"#a7a7a7",marginRight:23,width:'23%'}}
             >Yes</Button>
                  <Button onClick={()=>setRemoveAllowLate('2')}  className='unsaved-btn' style={{backgroundColor:removeAllowLate=="2"?'#e85f25':"#a7a7a7",width:'23% !important'}}
             >No</Button></div>
                

<div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
    <Button onClick={()=>setRefundModal(false)} className='unsaved-btn-cancel' style={{backgroundColor:'#a7a7a7',marginRight:23,width:'23%'}}
             ><ArrowLeftOutlined />Back</Button>
                  <Button  onClick={()=>{setRefundModal(false); setShowPreviewRefundModal(true)}} className='form-btn-4 unsaved-btn' style={{backgroundColor:'#e85f25',width:'23% !important'}}
             >Continue<ArrowRightOutlined /> </Button></div>
                  </Modal>

                    <Modal centered={true} maskClosable
                 onCancel={()=>setShowPreviewRefundModal(false)} className='success-save-modal' style={{padding:'68px 18px',width:'836px'}} footer={null} visible={showPreviewRefundModal}>
                  <div style={{    display: 'flex',
    flexDirection: 'column',marginBottom:'4%',
    alignItems: 'center',}}>
                  <span style={{    fontSize: '29px',fontWeight: '700',marginBottom:'2%',}}>Please confirm the amount you wish to refund</span>
                  <div  className='preview-selected-date-refund'><span  className='preview-selected-date-name-refund'>${refundAmount}</span></div>
                  
                  </div>
             <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <span style={{fontWeight:'700',fontSize:'25px'}}>Do you also wish to remove their registration?</span>
                   
                </div>
                <div style={{ display: 'flex',
    justifyContent: 'center',
    marginTop: '32px'}}>   
    <Button   className='unsaved-btn-cancel-refund' style={{backgroundColor: removeAllowLate=="1"?"#e85f25":"#a7a7a7",marginRight:23,width:'23%',color:removeAllowLate=="1"?"white":'#a7a7a7'}}
             >Yes</Button>
                  <Button   className='unsaved-btn' style={{backgroundColor:removeAllowLate=="2"?"#e85f25":"#a7a7a7",width:'23% !important',color:removeAllowLate=="2"?"white":'#a7a7a7'}}
             >No</Button></div>
                

<div style={{ display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '32px'}}>   
    <Button onClick={()=>setShowPreviewRefundModal(false)} className='unsaved-btn-cancel' style={{backgroundColor:'#a7a7a7',marginRight:23,width:'23%'}}
             ><ArrowLeftOutlined />Back</Button>
                  <Button  onClick={()=>{ refundsubmit()}} className='form-btn-4 unsaved-btn' style={{backgroundColor:'#e85f25',width:'23% !important'}}
             >Refund<ArrowRightOutlined /> </Button></div>
                  </Modal>
<div className='template-list-title'>
    <span style={{fontSize:'22px',fontWeight:'600'}}>Registrations</span>

    {_.get(props, 'temp_details.type') !== 'open' ? _.size(usersList)>0 &&<div className='manage-active-tab-btn'>
    <Button 
    onClick={()=>openChat(_.get(props,'chatkitty_channel_id'))} 
    className='form-btn-4-csv'><MailFilled style={{marginTop:'-5px'}} /> Message All</Button>
    {/* <ExportJsonCsv style={{border:'none'}} className='form-btn-4-csv' headers={headers} items={usersList}><DownloadOutlined style={{marginTop:'-3px'}} /> Export to .csv</ExportJsonCsv> */}
    <Button className='form-btn-4-csv' onClick={downloadCSV}>Download CSV</Button>
    </div>:""}
    
</div>
<div className='template-list-table' >
<Table locale={locale} loading={pageLoading} columns={column} dataSource={usersList} pagination={false} /></div>
{searchParams.get('template_type') == "open" ?
<div>
<div className='template-list-title' style={{width:'100%', marginTop:"30px"}}>
    <span style={{fontSize:'22px',fontWeight:'600'}}>Completed Registrations</span>

    {_.get(props, 'temp_details.type') !== 'open' ? _.size(completedusersList)>0 &&<div className='manage-active-tab-btn'>
    <Button 
    onClick={()=>openChat(_.get(props,'chatkitty_channel_id'))} 
    className='form-btn-4-csv'><MailFilled style={{marginTop:'-5px'}} /> Message All</Button>
    <ExportJsonCsv style={{border:'none'}} className='form-btn-4-csv' headers={headers} items={completedusersList}><DownloadOutlined style={{marginTop:'-3px'}} /> Export to .csv</ExportJsonCsv>
    </div>:""}
    
</div> 

<div className='template-list-table' >
<Table locale={locale} loading={completedpageLoading} columns={completedColumn} dataSource={completedusersList} pagination={false} /></div>
</div>

 : ""
}




{/* <div> */}
  <Modal centered={true} className='purchase-modal' title="Please confirm you wish to mark the registration as complete" 
open={isModalOpen}
 onOk={handleOk} 
 onCancel={handleCancel}
 footer={[
  <Button style={{borderRadius:"20px", backgroundColor:"#e85f25", color:"#fff"}} key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button style={{borderRadius:"20px", backgroundColor:"#e85f25"}}  key="submit" type="primary" onClick={handleOk}>
            Mark as Complete
          </Button>
 ]}
 >
        <p>You are marking the following registration as complete:</p>
            <div>
        <div  style={{backgroundColor:'#e9e9e9', paddingBottom:"70px", paddingTop:"10px"}}>
        <div style={{marginTop:"10px"}}>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>First Name:</p>
          <p style={{marginLeft:"10px"}}>{_.get(getPurchaseDetails, 'first_name')}</p>
         </div>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>Last Name:</p>
          <p style={{marginLeft:"10px"}}>{_.get(getPurchaseDetails, 'last_name')}</p>
         </div>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>Session Purchased:</p>
          <p style={{marginLeft:"10px"}}>{_.get(getPurchaseDetails, 'order_details.experience.template.title')}</p>
         </div>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>Date of Birth:</p>
          <p style={{marginLeft:"10px"}}>{_.get(getPurchaseDetails, 'date_of_birth')}</p>
         </div>
         <div style={{display:"flex", flexDirection:"row"}}>
          <p style={{marginLeft:"10px"}}>Date of Purchase:</p>
          <p style={{marginLeft:"10px"}}>{moment(_.get(getPurchaseDetails, 'order_details.created_at')).format('LL')}</p>
         </div>
         </div>
        </div>
        </div>
      </Modal>

        <Modal centered={true} 
      className='purchase-modal' 
      width="50%" 
      title={`Cancel Registration & Refund for ${_.startCase(_.get(getNewModalTitleName, 'first_name'))}`}
      open={isNewRefundModal} 
      // onOk={proceedRefund} 
      onCancel={handleRefundCancel}
      footer={[
        <Button onClick={() => mainRefundCancelModal()} style={{borderRadius:"6px"}}>Cancel Registration Only</Button>,
        //  <Button className='download-invoice' onClick={print}>Download Purchase Invoice</Button>,
        <Button onClick={() => confirmRefundShowModal()} style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}}>Refund</Button>
      ]}
      >
        <p>Below is a history of what the registrant paid, and any refunds you have already </p>
        <p style={{marginTop:"-11px"}}>issued. If you wish to cancel a person’s registration without a refund, press the</p>
        <p style={{marginTop:"-11px"}}>“Cancel Registration Only” button.</p>
        <p>Registration history</p>
        <div className='template-list-table' >
            <Table columns={newRefundColumn} pagination={false} dataSource={getUserTranscationTable} />
          </div>
          <p style={{marginBottom: "1px", marginTop:"2px", fontSize:"12px", marginLeft:"436px"}}>Minimum Refund Amount: $0.01</p>
          <p style={{fontSize:"12px", marginLeft:"436px"}}>Maximum Refund Amount: ${_.get(getComputedRefundData, 'details.max_refund')}</p>
          <p>Enter a refund amount</p>
          <p style={{marginLeft:"254px", marginBottom:"5px"}}>Refund amount</p>
          <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
            <Input onChange={(e) => enterRefundAmount(e, parseFloat(_.get(getComputedRefundData, 'details.max_refund')))} type="number" value={amountValue} placeholder='Enter a $ amount' style={{width:"150px"}}/>
          </div>
          <div style={{marginTop:"25px"}}>
          <Checkbox onChange={onChangeRefundCheck}><p style={{fontSize:"12px"}}>Also set this person’s registration to <Tag style={{borderRadius:"12px", marginLeft:"6px"}} color="default">Cancelled</Tag></p></Checkbox>
          </div>
      </Modal>

        <Modal centered={true} 
       className='purchase-modal' 
      title={`Refund $${getRefundableAmount}?` }
      open={confirmRefundIsModalOpen} 
      onOk={confirmProceedRefund} 
      onCancel={hideCancelRefund}
      footer={[
        <Button style={{borderRadius:"6px"}} onClick={() => hideCancelRefund()}>Cancel</Button>,
        <Button style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}} onClick={() => confirmProceedRefund()}>Confirm Refund</Button>
      ]}
      >
        <p>Once you have confirmed the refund, it</p>
        <p style={{marginTop:"-11px"}}>cannot be reversed.</p>
      </Modal>

        <Modal centered={true} 
       className='purchase-modal' 
      title="Success" 
      open={successRefundIsModalOpen} 
      onOk={successRefundHandleOk} 
      onCancel={successRefundHandleCancel}
      footer={[
        <Button style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}} onClick={() => successRefundHandleOk()}>Continue</Button>,
      ]}
      >
        <p>The refund was processed sucessfully.</p>
        <p style={{marginTop:"-11px"}}>cannot be reversed.</p>
      </Modal>

        <Modal centered={true} className='purchase-modal' 
       title="Are you sure?" 
       open={confirmCancelRefunfIsModalOpen} 
       onOk={cancelConfirmRefundOk} 
       onCancel={cancelModalRefund}
       footer={[
        <Button onClick={() => cancelModalRefund()} style={{borderRadius:"6px"}}>Cancel</Button>,
        <Button onClick={() => cancelConfirmRefundOk()} style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}}>Cancel Registration</Button>
       ]}
       >
        <p>Once you have cancel this person’s registration, it cannot be reversed.</p>
        <p style={{marginTop:"-11px"}}>registration, it cannot be reversed.</p>
      </Modal>

        <Modal centered={true} 
       className='purchase-modal' 
      title="Success" 
      open={successCancelIsModalOpen} 
      onOk={successCancelOk} 
      onCancel={handleSuccessCancel}
      footer={[
        // <Button onClick={() => handleSuccessCancel()}>Cancel</Button>,
        <Button style={{borderRadius:"6px", backgroundColor:"#e85f25", color:"#fff"}} onClick={() => successCancelOk()}>Continue</Button>,
      ]}
      >
        <p>The registration was cancelled</p>
        <p style={{marginTop:"-11px"}}>successfully.</p>
      </Modal>
      <div>
  <Modal centered={true} title="Email" footer={null} open={isEmailModalOpen} onOk={handleEmailOk} onCancel={handleEmailCancel}>
<Form
    name="basic"
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    form={form}
    layout='vertical'
  >
    <div style={{marginBottom: "10px"}}>{_.get(getExpId, 'experience.template.title')}</div>
    <Form.Item
      label="Email"
      name="emails"
    >
     <Input disabled/>
      
    </Form.Item>
    
    <Form.Item
      label="Title"
      name="title"
      rules={[
        {
          required: true,
          message: 'Please input your title!',
        },
      ]}
    >
      <Input/>
    </Form.Item>

    <Form.Item
      label="Message"
      name="message"
      rules={[
        {
          required: true,
          message: 'Please input your message!',
        },
      ]}
    >
      {/* <Input.TextArea rows={7} disabled={errorMessage ? true : false} /> */}
      <ReactQuill theme="snow"/>
    </Form.Item>
    <Form.Item
    >
      <Button style={{width: "100%", backgroundColor: "#e85d04", color: "#fff", marginTop: "50px"}} type="primary" htmlType="submit">
    Send
      </Button>
    </Form.Item>
  </Form>
      </Modal>
</div>
{/* </div> */}
        </div>)
    }

    return render()
}
const mapStateToProps = state => {
    
    return {
       checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
       isLoginLoadingInProgress: _.get(state, 'app.account.isLoginLoadingInProgress'),
       islogindata: _.get(state, 'app.account.isLogin'),
       userProfile: _.get(state, 'app.host.profile.data.details'),

    }
  }
  
  const mapDispatchToProps = dispatch => ({
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
    templateList: details => dispatch(global.redux.action.template.list(details)),
    list: details => dispatch(global.redux.action.experience.list(details)),
    orderList: details => dispatch(global.redux.action.experience.orderList(details)),
    refund: details => dispatch(global.redux.action.experience.refund(details)), 
    markAsComplete: details => dispatch(global.redux.action.experience.completeOrder(details)),
    getOrderTranscationList: details => dispatch(global.redux.action.experience.transList(details)),
    proceedRefundAPI: details => dispatch(global.redux.action.experience.newRefund(details)),
    computeRefundAPI: details => dispatch(global.redux.action.experience.computeRefund(details)),
    sendEmailAPI: details => dispatch(global.redux.action.sendEmail.postEmails(details)),
    
   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(TemplateList)