import React from 'react';
import { Card, CardContent, CardMedia, Typography, Skeleton, Box } from '@mui/material';

const CardSkeleton = () => {
  return (
       <Box sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
              <Box sx={{width:{
              xs: "90%", // mobile
              sm: "80%", // tablet
              md: "70%", // small desktop
              lg: "65%",
       },display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',height:'100%'}}>
    <Card  style={{ width: "100%", height:'100%' }}>
      <Skeleton variant="rectangular" height={140} />
      <CardContent>
        <Typography variant="h5">
          <Skeleton />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
      </CardContent>
    </Card></Box>
       </Box>
  );
};

export default CardSkeleton;